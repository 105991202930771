import IconsStyle from '../../styles/iconStyle';
import colors from 'constants/colors';

export default {
    content: {
        fontSize: 13,
        boxSizing: 'border-box',
        position: 'relative',
        whiteSpace: 'nowrap',
        background: '#f8f8f8',
        borderRadius: '20px!important',
        padding: '5px 10px 5px 5px',
    },
    contentNoBackground: {
        fontSize: 13,
        boxSizing: 'border-box',
        position: 'relative',
        whiteSpace: 'nowrap',
        padding: '5px 10px 5px 5px',
        background: 'transparent',
    },
    contentDivText: {
        display: 'inline-block',
        verticalAlign: 'top',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    contentRight: {
        fontSize: 13,
        boxSizing: 'border-box',
        position: 'relative',
        whiteSpace: 'nowrap',
        background: '#f8f8f8',
        borderRadius: '20px!important',
        padding: '5px 0px 5px 5px',
        textAlign: 'right',
    },
    contentDivTextRight: {
        display: 'inline-block',
        verticalAlign: 'top',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    imgTextError: (size) => ({
        ...size,
        textTransform: 'uppercase',
        borderRadius: '50%',
        display: 'inline-block',
        backgroundColor: colors.grey[50],
        textAlign: 'center',
        color: colors.primary.white,
        float: 'left',
    }),
    imgLogo: (size) => ({
        ...size,
        borderRadius: '50%',
        display: 'inline-block',
        marginTop: 1,
        float: 'left',
    }),
    iconFolder: {
        ...IconsStyle.icon_md,
        top: -1,
        backgroundColor: colors.primary.white,
        borderRadius: '50%',
        padding: 6,
        position: 'relative',
    },
    small: {
        width: 20,
        height: 20,
        lineHeight: '22px',
        fontSize: 8,
    },
    normal: {
        width: 30,
        height: 30,
        lineHeight: 2.3,
    },
    medium: {
        width: 35,
        height: 35,
        lineHeight: 2.55,
    },
    big: {
        width: 40,
        height: 40,
        lineHeight: 2.8,
    },
    extrabig: {
        width: 50,
        height: 50,
        lineHeight: 3,
        fontSize: 16,
    },
    extrabig2: {
        width: 100,
        height: 100,
        lineHeight: 3,
        fontSize: 16,
    },
    none: {
        width: 0,
        height: 0,
        lineHeight: 15,
        fontSize: 8,
    },
    borderRadius: {
        borderRadius: '50%',
    },
};
