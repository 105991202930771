import React from 'react';
import colors from 'constants/colors';

const ThickEnabledDefault = ({ color = colors.actionMinor[500] }) => (
    <svg viewBox={`0 0 18 18`} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9 0a9 9 0 0 0-9 9c0 4.97 4.02 9 9 9A9 9 0 0 0 9 0zm0 16c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7zm-1.46-5.47L5.41 8.4 4.35 9.46l3.18 3.18 6-6-1.06-1.06-4.93 4.95z"
            fill={color || '#335B70'}
            fillRule="evenodd"
        />
    </svg>
);

export default ThickEnabledDefault;
