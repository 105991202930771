import React from 'react';
import colors from 'constants/colors';

const PieChart = ({ color = colors.actionMinor[500] }) => (
    <svg viewBox={`0 0 18 18`} xmlns="http://www.w3.org/2000/svg">
        <g fill={color || '#335B70'} fillRule="evenodd">
            <path d="M9 1v8h8a8 8 0 1 1-8-8z" />
            <path d="M18 8a8 8 0 0 0-8-8v8h8z" />
        </g>
    </svg>
);

export default PieChart;
