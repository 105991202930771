import React, { memo } from 'react';
import { Text, useTheme } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';

import './styles.scss';

const BetaBadge = memo(() => {
    const theme = useTheme();
    return (
        <div className="dana-beta-badge">
            <Text type="badges" color={theme.colors.primary.white}>
                {getLiteral('label_beta')}
            </Text>
        </div>
    );
});

export default BetaBadge;
