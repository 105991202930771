import React, { memo, useMemo } from 'react';
import { Icon, Text, useTheme } from 'hoi-poi-ui';
import Scrollbars from 'components/ScrollBar';

import './detailField.scss';

const DetailField = memo(
    ({
        value = null,
        textProps,
        iconProps,
        iconPosition,
        icon,
        component = null,
        postComponent = null,
        useDivider,
        useScroll,
        scrollOptions = {},
        className,
        isTitle,
        onClick,
    }) => {
        const theme = useTheme();
        const defaultScrollOptions = useMemo(() => {
            return {
                autoHide: true,
                autoHeight: true,
                autoHeightMin: 0,
                autoHeightMax: 200,
                ...scrollOptions,
            };
        }, [scrollOptions]);

        const defaultTextProps = useMemo(() => {
            return {
                type: !isTitle ? 'body' : 'h6',
                color: theme.colors.utility.textPrimary,
                isTruncated: !useScroll && true,
                ...textProps,
            };
        }, [textProps, useScroll, isTitle, theme]);

        const defaultIconProps = useMemo(() => {
            return {
                iconSize: 'medium',
                iconColor: theme.colors.grey[500],
                ...iconProps,
            };
        }, [iconProps, theme]);

        const renderValue = useMemo(() => {
            return (
                <div className="fm-entity-detail-field__value">
                    {value && <Text {...defaultTextProps}>{value}</Text>}
                    {component}
                </div>
            );
        }, [component, value, defaultTextProps]);

        const classNames = useMemo(() => {
            let classes = ['fm-entity-detail-field'];
            if (className) classes.push(className);
            if (isTitle) classes.push('fm-entity-detail-field__isTitle');
            if (onClick) classes.push('fm-entity-detail-field--clickable');
            return classes;
        }, [className, isTitle, onClick]);

        const iconClassNames = useMemo(() => {
            let classes = ['fm-entity-detail-field__icon'];
            if (!iconPosition) return classes;
            switch (iconPosition) {
                case 'bottom':
                    classes.push('fm-entity-detail-field__icon--bottom');
                    break;
                case 'center':
                    classes.push('fm-entity-detail-field__icon--center');
                    break;
                case 'top':
                default:
                    classes.push('fm-entity-detail-field__icon--top');
                    break;
            }
            return classes;
        }, [iconPosition]);

        return (
            <div className={classNames.join(' ')} onClick={onClick}>
                {useDivider && <div className="fm-entity-detail-field__divider" />}
                <div className="fm-entity-detail-field__content">
                    {(defaultIconProps.name || icon) && (
                        <div className={iconClassNames.join(' ')}>
                            {defaultIconProps.name && (
                                <Icon color={defaultIconProps.iconColor} {...defaultIconProps} />
                            )}
                            {icon && icon}
                        </div>
                    )}
                    {useScroll && <Scrollbars {...defaultScrollOptions}>{renderValue}</Scrollbars>}
                    {!useScroll && renderValue}
                    {postComponent && (
                        <div className="fm-entity-detail-field__postcomponent">{postComponent}</div>
                    )}
                </div>
            </div>
        );
    },
);

export default DetailField;
