import React, { memo, useState, useCallback } from 'react';
import { useTheme } from 'hoi-poi-ui';
import { RichText } from '@web/web5';
import ClearButton from '../ClearButton';
import SpeechButton from '../SpeechButton';
import { getLiteral } from 'utils/getLiteral';

import './styles.scss';

const ChatToolbar = memo(
    ({
        conversation,
        isDanaWritting,
        onClear,
        getQuestionRef,
        onSubmit,
        isLoadingAnswer,
        placeholder,
    }) => {
        const [isListening, setIsListening] = useState(false);
        const [editor, setEditor] = useState(null);
        const theme = useTheme();

        const canSubmit = useCallback((editorContent) => {
            if (!editorContent?.text) return false;
            return true;
        }, []);

        const getEditorRef = useCallback((ref) => {
            if (ref?.commands?.focus) ref?.commands?.focus();
            setEditor(ref);
        }, []);

        return (
            <div className="dana-chat-toolbar">
                {Object.keys(conversation || {})?.length > 0 && (
                    <ClearButton onClick={!isDanaWritting && onClear} />
                )}
                <RichText
                    isBasic
                    isFullWidth
                    placeholder={placeholder || getLiteral('placeholder_dana_chat_input')}
                    toolbar={[]}
                    overrides={{
                        editorWrapper: {
                            style: {
                                backgroundColor: theme.colors.background.utility025,
                            },
                        },
                    }}
                    onChange={(value) => getQuestionRef?.(value.text)}
                    onSubmit={onSubmit}
                    loading={isDanaWritting}
                    canSubmit={isListening ? () => false : canSubmit}
                    autofocus={!isLoadingAnswer}
                    getEditorRef={getEditorRef}
                    isSubmitDisabled={isListening}
                    chatbox
                />
                <SpeechButton
                    editor={editor}
                    isDisabled={isDanaWritting}
                    setIsListening={setIsListening}
                    isListening={isListening}
                />
            </div>
        );
    },
);

export default ChatToolbar;
