import React, { memo, useRef, useMemo } from 'react';

import { TitleDivider, Divider } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';

import ProductCarousel from './components/ProductCarousel';
import ProductInfo from './components/ProductInfo';
import ProductDetailLoader from './components/ProductDetailLoader';
import ProductDetailTabs from './components/ProductDetailTabs';

import './styles.scss';

const ProductsDetail = memo(({ data, loading }) => {
    const domDetailInfo = useRef(null);
    return (
        <div className="fm-products-detail">
            <TitleDivider overrides={{ Text: { medium: true } }}>
                {getLiteral('label_product_detail')}
            </TitleDivider>
            <div className="fm-products-detail__content" ref={domDetailInfo}>
                {loading && (
                    <ProductDetailLoader
                        width={domDetailInfo.current?.offsetWidth - 48}
                        height={domDetailInfo.current?.offsetHeight}
                    />
                )}
                {!loading && (
                    <>
                        <div className="fm-products-detail__top-content">
                            <ProductCarousel ids={data?.productImageList?.long} />
                            <ProductInfo data={data} />
                        </div>
                        <Divider className="fm-products-detail__divider" />
                        <ProductDetailTabs data={data} />
                    </>
                )}
            </div>
        </div>
    );
});

export default ProductsDetail;
