import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Text, Switch, Popover, Icon, useTheme } from 'hoi-poi-ui';
import { ACTIVITIES } from 'constants/Entities';
import { getLiteral } from 'utils/getLiteral';

import './styles.scss';

const MaxDistanceFilter = memo(({ entity, onClick, hint }) => {
    const theme = useTheme();

    const { isSuspiciousCheckIn } = useSelector((state) => {
        const filters =
            entity.entity === ACTIVITIES.entity
                ? state?.entityFilters?.[ACTIVITIES.entity]?.filters || {}
                : state?.entityFilters?.[entity.entity]?.crossFilters?.[ACTIVITIES.entity] || {};
        return {
            isSuspiciousCheckIn: filters?.isSuspiciousCheckIn?.value || false,
        };
    });

    const handleOnClick = useCallback(() => {
        onClick && onClick(entity);
    }, [entity, onClick]);

    return (
        <div className="activities-max-distance-filter">
            <div className="activities-max-distance-filter__label">
                <Text color={theme.colors.utility.textPrimary}>
                    {getLiteral('label_suspicious_checkin')}
                </Text>
                {hint && (
                    <Popover
                        trigger={['hover']}
                        content={
                            <div className="activities-max-distance-filter__hint-content">
                                {hint.title && (
                                    <Text type="subtitle" color={theme.colors.utility.textPrimary}>
                                        {hint.title}
                                    </Text>
                                )}
                                {hint.description && (
                                    <Text color={theme.colors.utility.textSecondary}>
                                        {hint.description}
                                    </Text>
                                )}
                            </div>
                        }
                    >
                        <span className="activities-max-distance-filter__hint">
                            <Icon name="helpOutline" />
                        </span>
                    </Popover>
                )}
            </div>

            <Switch
                checked={isSuspiciousCheckIn}
                onClick={handleOnClick}
                onChange={handleOnClick}
            />
        </div>
    );
});

export default MaxDistanceFilter;
