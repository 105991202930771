import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Modal, Text, useTheme } from 'hoi-poi-ui';
import Map from './components/Map';
import {
    CHECKIN_SVG_TEXT,
    COMPANY_CHECKIN_SVG_TEXT,
    OPPORTUNITY_CHECKIN_SVG_TEXT,
    FAST_CHECKIN_SVG_TEXT,
} from 'constants/Images';
import { getVar } from 'utils/color';

import { getLiteral } from 'utils/getLiteral';
import { parseServerDecimal } from 'utils/fm';

import './styles.scss';

const getCoordinates = (Latitude, Longitude) => {
    if (!Latitude && !Longitude) return null;
    const lat = parseServerDecimal(Latitude);
    const lng = parseServerDecimal(Longitude);
    return { lat, lng };
};

const ActivitiesRouteMap = memo(
    ({ checkins = [], user, date, dataDate, userId, onRequestClose }) => {
        const theme = useTheme();
        const oldCheckins = useRef(null);
        const [markers, setMarkers] = useState([]);

        const onLoadMap = useCallback(() => {
            const mapMarkers = checkins.reverse().map((checkin, index) => {
                let icon;
                const { Id, IDTipoCheckin, CompanyName, Latitude, Longitude } = checkin;

                switch (IDTipoCheckin) {
                    case '1':
                        icon = COMPANY_CHECKIN_SVG_TEXT(
                            index + 1,
                            getVar(theme.colors.actionMajor[500]),
                            getVar(theme.colors.primary.white),
                        );

                        break;
                    case '2':
                        icon = OPPORTUNITY_CHECKIN_SVG_TEXT(
                            index + 1,
                            getVar(theme.colors.actionMajor[500]),
                            getVar(theme.colors.primary.white),
                        );
                        break;
                    case '3':
                        icon = FAST_CHECKIN_SVG_TEXT(
                            index + 1,
                            getVar(theme.colors.actionMajor[500]),
                            getVar(theme.colors.primary.white),
                        );
                        break;
                    default:
                        icon = CHECKIN_SVG_TEXT(
                            index + 1,
                            getVar(theme.colors.actionMajor[500]),
                            getVar(theme.colors.primary.white),
                        );
                        break;
                }

                return {
                    key: Id,
                    title: CompanyName,
                    icon,
                    coordinates: getCoordinates(Latitude, Longitude),
                };
            });

            setMarkers(mapMarkers);
        }, [checkins, theme.colors.actionMajor, theme.colors.primary.white]);

        useEffect(() => {
            if (checkins?.length && checkins !== oldCheckins.current) {
                // This way we force to reload the map with the right markers of each activity
                onLoadMap();
                oldCheckins.current = checkins;
            }
        }, [checkins, date, onLoadMap, user]);

        const title = useMemo(
            () => (
                <div className="fm-routemap__header">
                    <Text type="h6" medium>{`${getLiteral('label_routeof')} ${user}`}</Text>
                    <Text type="body">{date}</Text>
                </div>
            ),
            [user, date],
        );

        const showMap = useMemo(() => checkins && checkins.length > 0, [checkins]);

        const modalProps = useMemo(
            () => ({
                isOpen: showMap,
                title,
                onRequestClose,
                size: 'full',
                classes: {
                    content: 'fm-routemap__map__content',
                },
            }),
            [onRequestClose, title, showMap],
        );

        return (
            <Modal {...modalProps}>
                <div className="fm-routemap__map">
                    {showMap && <Map mapPoints={markers} dataDate={dataDate} userId={userId} />}
                </div>
            </Modal>
        );
    },
);

export default ActivitiesRouteMap;
