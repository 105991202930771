import React from 'react';
import colors from 'constants/colors';

const OrderedList = ({ color = colors.utility.textPrimary }) => (
    <svg className="fm-close-icon" viewBox={`0 0 18 18`} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6 4h12v1H6V4zm0 10h12v1H6v-1zm0-5h12v1H6V9zM1.54 1.514V1.32c.376-.123.65-.218.824-.285.173-.068.347-.143.523-.227l.152.1c-.025.247-.044.538-.057.872a57.731 57.731 0 0 0-.007 2.698c.005.108.027.18.064.216.037.036.161.072.372.107l.129.026v.205C3.17 5.011 2.849 5 2.573 5c-.285 0-.61.01-.975.032v-.205c.275-.043.437-.084.486-.123.033-.025.055-.074.064-.146.024-.182.036-.667.036-1.456 0-.647-.002-1.06-.006-1.24-.004-.18-.023-.29-.057-.332-.035-.042-.087-.063-.157-.063-.094 0-.235.015-.425.047zm.052 6.545h-.29c.105-.323.16-.618.167-.885.177-.123.356-.215.536-.276.18-.06.36-.09.539-.09.332 0 .602.1.81.3.208.2.312.444.312.73 0 .218-.045.43-.136.64a3.06 3.06 0 0 1-.47.711c-.096.116-.462.492-1.1 1.128h1.026c.16 0 .267-.008.32-.025a.338.338 0 0 0 .146-.098c.045-.048.104-.148.176-.298h.281c-.187.449-.33.828-.43 1.136a22.675 22.675 0 0 0-2.42 0v-.255c.285-.273.61-.633.978-1.08.227-.278.396-.507.508-.688a2.33 2.33 0 0 0 .242-.495c.049-.15.073-.297.073-.444 0-.207-.055-.369-.165-.486a.536.536 0 0 0-.406-.176.603.603 0 0 0-.391.14c-.114.092-.216.263-.306.51zm.076 5.806H1.39c.056-.174.112-.42.167-.738.16-.107.321-.188.485-.24.163-.053.328-.08.496-.08.31 0 .55.078.716.234.167.155.25.355.25.599a.88.88 0 0 1-.12.455c-.08.136-.225.283-.436.441.246.068.438.195.574.381.137.186.206.408.206.668 0 .344-.12.657-.36.94-.329.385-.782.578-1.36.578-.268 0-.542-.05-.823-.147.039-.164.066-.296.083-.397.017-.1.036-.273.057-.517h.258c.037.264.114.453.231.567a.597.597 0 0 0 .434.171.584.584 0 0 0 .466-.23c.127-.153.19-.376.19-.67 0-.333-.092-.58-.278-.74-.135-.116-.344-.175-.627-.18l.009-.251c.263-.072.456-.18.578-.32a.718.718 0 0 0 .184-.483.507.507 0 0 0-.14-.37.473.473 0 0 0-.353-.142.597.597 0 0 0-.356.11.784.784 0 0 0-.253.361z"
            fill={color || '#335B70'}
            fillRule="nonzero"
        />
    </svg>
);

export default OrderedList;
