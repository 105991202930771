import React from 'react';
import { components } from 'react-select';
import DropdownIndicator from 'components/Select/DropdownIndicator';
import PadlockIndicator from 'components/Select/PadlockIndicator';
import { Icon } from 'hoi-poi-ui';
import PopoverHoi from 'components/PopoverHoi';
import colors from 'constants/colors';

import './styles.scss';

const SelectIndicatorContainer = (props) => {
    const renderComponent = !props.isDisabled ? (
        <DropdownIndicator {...props} />
    ) : (
        <PadlockIndicator {...props} />
    );

    return (
        components.IndicatorsContainer && (
            <components.IndicatorsContainer {...props}>
                {props.isDisabled && (
                    <div className="fm-select__goal-type-field-select-tooltip">
                        <PopoverHoi
                            className="fm-select__goal-type-field-select-tooltip__popover"
                            placement="top"
                            content={props.selectProps.selectIndicatorContainerContent || ''}
                            trigger={['hover']}
                        >
                            <span>
                                <Icon name="info" size="small" color={colors.actionMinor[200]} />
                            </span>
                        </PopoverHoi>
                    </div>
                )}
                {renderComponent}
            </components.IndicatorsContainer>
        )
    );
};

export default SelectIndicatorContainer;
