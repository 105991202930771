import React, { Component } from 'react';

import { ExtensionIcon } from 'components/SvgIcons';
import CircularPercentage from 'components/CircularPercentage';
import { ProgressError, ProgressCancel } from 'components/SvgIcons';

import colors from 'constants/colors';

class UploadDocumentsDialogRow extends Component {
    state = {};

    render() {
        const {
            name,
            description,
            isFolder,
            isLink,
            progress,
            state,
            readyToUpload,
            onCancelRequest,
        } = this.props;

        //files once uploaded have a number as name, and the description is the actual name with the extension
        const extension =
            description?.split?.('.')?.length > 1
                ? description?.split?.('.')?.[1]
                : name?.split?.('.')?.[1];

        let classRow = 'row-finished';
        if (state === 0 && readyToUpload) {
            classRow = 'row-uploading';
        }

        return (
            <div className={`fm-documents-upload-dialog__row ${classRow}`}>
                <div className="fm-documents-upload-dialog__row__extension">
                    <ExtensionIcon extension={extension} isFolder={isFolder} isLink={isLink} />
                </div>
                <div className="fm-documents-upload-dialog__row__name">
                    {description ? description : name}
                </div>
                <div className="fm-documents-upload-dialog__row__progress">
                    {state !== -1 && (
                        <div className="fm-documents-upload-progress-cancel">
                            <CircularPercentage
                                percentage={progress}
                                classForPercentage={'circle-blue'}
                                rootClassName={'fm-documents-upload-progress'}
                                strokeWidth={6}
                            >
                                <div className="fm-documents-upload-progress__text">
                                    <span>{`${progress}%`}</span>
                                </div>
                            </CircularPercentage>
                            <div className="fm-documents-upload-cancel" onClick={onCancelRequest}>
                                <ProgressCancel />
                            </div>
                        </div>
                    )}
                    {/* {state === 1 && <ProgressCheck color={colors.semantic.positiveCustom300} />} */}
                    {state === -1 && <ProgressError color={colors.semantic.negative500} />}
                </div>
            </div>
        );
    }
}

export default UploadDocumentsDialogRow;
