import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import DropZone from 'components/DropZone';
import { ClearIcon } from 'components/icons';
import { Link } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';

export const Shapes = {
    SQUARE: 'picture-shape picture-square',
    CIRCLE: 'picture-shape picture-circle',
};

const dropZoneStyles = {
    border: 'none',
    width: 90,
    height: 90,
    margin: '0 auto',
};

const propTypes = {
    styles: PropTypes.string.isRequired,
    type: PropTypes.object,
    src: PropTypes.string,
    withLabel: PropTypes.bool,
    onChange: PropTypes.func,
    onRejected: PropTypes.func,
};

export class Picture extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            hover: false,
            dropOpen: false,
        };
    }

    onClick = () => {
        this.setState({ dropOpen: true });
    };

    onClose = () => {
        this.setState({ dropOpen: false });
    };

    onDrop = (droppedFiles) => {
        this.props.onChange(droppedFiles[0]);
        this.onDragLeave();
    };

    onDragEnter = () => {
        this.setState({ hover: true });
    };

    onDragLeave = () => {
        this.setState({ hover: false });
    };

    render() {
        let { src, type, onRejected, onRemoveImage, withLabel = true, canChange } = this.props;
        const { hover, dropOpen } = this.state;
        let shape = type ? type : Shapes.CIRCLE;
        let img = src ? <img src={src} /> : null;
        return (
            <div className="fm-picture">
                {canChange && (
                    <DropZone
                        className="drop-zone"
                        onDragEnter={this.onDragEnter}
                        onDragLeave={this.onDragLeave}
                        onDrop={this.onDrop}
                        onDropRejected={onRejected}
                        onClose={this.onClose}
                        accept="image/*"
                        open={dropOpen}
                    >
                        {src && <ClearIcon className="remove-image-icon" onClick={onRemoveImage} />}
                        <div className={shape}>{img}</div>
                        {hover && <div className="test drop-zone" />}
                    </DropZone>
                )}
                {!canChange && <div className={shape}>{img}</div>}
                {withLabel && canChange && (
                    <Link type="body" variation="primary" onClick={this.onClick}>
                        {getLiteral('label_change')}
                    </Link>
                )}
            </div>
        );
    }
}

Picture.propTypes = propTypes;
