import React, { memo, useMemo, Fragment } from 'react';
import { connect } from 'react-redux';
import { Icon } from 'hoi-poi-ui';
import { COMPANIES, OPPORTUNITIES, SALESORDERS, CONTACTS } from 'constants/Entities';
import useEntityDetail from 'containers/components/EntityDetail/hooks/useEntityDetail';
import { WidgetLayout, WidgetContentLayout } from 'containers/components/widgets/Layouts';
import RowDetailWidget from 'containers/components/widgets/components/RowDetailWidget';
import { UserChip, CompanyChip, ContactChip, OpportunityChip } from 'containers/components/chips';
import PopoverHoi from 'components/PopoverHoi';
import WidgetDetailHeader from 'containers/components/widgets/components/WidgetDetailHeader';
import { getLiteral } from 'utils/getLiteral';
import { getLocalDate } from 'utils/dates';
import { isBackendFalsy } from 'utils/fm';
import { formatAddress } from 'utils/addresses';

import colors from 'constants/colors';

import './styles.scss';

const mapStateToProps = (state) => {
    const permissions = state.config.permission;
    const documentPermissions = state.config.permission.crud_permission.DOCUMENT;
    return {
        standardFieldsConfiguration:
            state.config.standardFieldsSchemaMap[SALESORDERS.extraFieldName] || null,
        enableSalesOrdersTypes: state.config.userData.enableSalesOrdersTypes,
        permissions: {
            company: permissions.empresas || false,
            contact: permissions.contactos || false,
            opportunity: permissions.expedientes || false,
            viewWarningsInSalesOrders: permissions.viewWarningsInSalesOrders,
            warnings: permissions.warnings,
        },
        canCreateDocument: documentPermissions ? documentPermissions.create : false,
    };
};

const SalesOrderDetailWidget = memo(
    ({
        data,
        setSize,
        standardFieldsConfiguration,
        permissions,
        enableSalesOrdersTypes,
        canCreateDocument,
    }) => {
        const { hasTabs: withTabs, openTab } = useEntityDetail();

        const {
            showType,
            showReference,
            showExpectedClose,
            showCloseDate,
            showOwner,
            showEnvironment,
            showRate,
            showCurrency,
            showCompany,
            showContact,
            showOpportunity,
            showAddress,
        } = useMemo(() => {
            return {
                showType:
                    (!standardFieldsConfiguration?.salesOrderType?.isHidden &&
                        enableSalesOrdersTypes) ||
                    false,
                showReference: !standardFieldsConfiguration?.reference?.isHidden || false,
                showExpectedClose:
                    !standardFieldsConfiguration?.expectedCloseDate?.isHidden || false,
                showCloseDate: !standardFieldsConfiguration?.CloseDate?.isHidden || false,
                showOwner: !standardFieldsConfiguration?.idComercial?.isHidden || false,
                showEnvironment: !standardFieldsConfiguration?.idSucursal?.isHidden || false,
                showRate: !standardFieldsConfiguration?.idTarifa?.isHidden || false,
                showCurrency: !standardFieldsConfiguration?.idCurrency?.isHidden || false,
                showAddress: !standardFieldsConfiguration?.idCompanyAddress?.isHidden || false,
                showCompany:
                    (permissions.company && !standardFieldsConfiguration?.idEmpresa?.isHidden) ||
                    false,
                showContact:
                    (permissions.contact && !standardFieldsConfiguration?.idContacto?.isHidden) ||
                    false,
                showOpportunity:
                    (permissions.opportunity &&
                        !standardFieldsConfiguration?.idExpediente?.isHidden) ||
                    false,
            };
        }, [
            standardFieldsConfiguration?.salesOrderType?.isHidden,
            standardFieldsConfiguration?.reference?.isHidden,
            standardFieldsConfiguration?.expectedCloseDate?.isHidden,
            standardFieldsConfiguration?.CloseDate?.isHidden,
            standardFieldsConfiguration?.idComercial?.isHidden,
            standardFieldsConfiguration?.idSucursal?.isHidden,
            standardFieldsConfiguration?.idTarifa?.isHidden,
            standardFieldsConfiguration?.idCurrency?.isHidden,
            standardFieldsConfiguration?.idCompanyAddress?.isHidden,
            standardFieldsConfiguration?.idEmpresa?.isHidden,
            standardFieldsConfiguration?.idContacto?.isHidden,
            standardFieldsConfiguration?.idExpediente?.isHidden,
            enableSalesOrdersTypes,
            permissions.company,
            permissions.contact,
            permissions.opportunity,
        ]);

        const signaturePopover = useMemo(() => {
            if (!data.signature) return null;
            return (
                <PopoverHoi
                    className="sales-order-detail-widget__signature__popover"
                    placement="right"
                    content={
                        <div className="sales-order-detail-widget__signature__popover__content">
                            <img src={`data:image/png;base64, ${data.signature}`} />
                        </div>
                    }
                    trigger={['click']}
                >
                    <span>{getLiteral('action_view_signature')}</span>
                </PopoverHoi>
            );
        }, [data]);

        const companyLabel = useMemo(() => {
            if (!permissions.warnings || !permissions.viewWarningsInSalesOrders || !data.warning) {
                return <Fragment>{getLiteral('label_account')}</Fragment>;
            }
            // TODO change the icon for an SVG icon
            return (
                <Fragment>
                    <span>{getLiteral('label_account')}</span>
                </Fragment>
            );
        }, [permissions, data.warning]);

        const crudActions = useMemo(() => {
            let finalActions = [];

            if (canCreateDocument) {
                finalActions.push({
                    name: 'createDocument',
                    iconName: 'document',
                    label: getLiteral('label_document'),
                });
            }

            return finalActions;
        }, [canCreateDocument]);

        const address = useMemo(() => {
            if (data.withCompanyAddress) return data.address;
            if (!data?.address || !data.idCountry) return '';
            return formatAddress(
                data.address,
                data.address2,
                data.city,
                data.province,
                data.postCode,
                data.country,
                data.idCountry,
            );
        }, [data]);

        return (
            <WidgetLayout
                className="sales-order-detail-widget"
                isDynamic={true}
                setSize={setSize}
                type="generalInfo"
                data={data}
            >
                <WidgetDetailHeader
                    entity={SALESORDERS}
                    leftItem={
                        <div className="sales-order-detail-widget__header-icon">
                            <Icon
                                name="salesOrders"
                                size="large"
                                color={colors.utility.textDisabled}
                            />
                            <div className="sales-order-detail-widget__header-icon--status">
                                {data.win && (
                                    <Icon
                                        name="thumbUp"
                                        color={colors.semantic.positiveCustom300}
                                    />
                                )}
                                {data.lost && (
                                    <Icon name="thumbDown" color={colors.semantic.negative500} />
                                )}
                            </div>
                        </div>
                    }
                    title={
                        showReference
                            ? data.reference || getLiteral('label_salesorder_details')
                            : getLiteral('label_salesorder_details')
                    }
                    subtitle={data.description}
                    value={data.total}
                    metadata={{
                        id: data.id,
                        extid: data.extId || data.extid,
                        fCreado: getLocalDate(data.createdDate, 'LT', 'DD/MM/YYYY HH:mm'),
                        fModificado: getLocalDate(
                            data.lastModifiedDate || data.fModificado,
                            'LT',
                            'DD/MM/YYYY HH:mm',
                        ),
                        userModificado: data.userModified || data.usermodificado,
                    }}
                    withoutDash
                    crudActions={crudActions}
                />
                <WidgetContentLayout>
                    <div className="sales-order-detail-widget__content">
                        {showType && (
                            <RowDetailWidget
                                className="sales-order-detail-widget__type"
                                label="label_salesorder_type"
                                value={data.salesOrderTypeDescription}
                                noEllipsis={true}
                            />
                        )}
                        {showExpectedClose && (
                            <RowDetailWidget
                                className="sales-order-detail-widget__expected-close"
                                label="label_valid_until"
                                value={
                                    getLocalDate(
                                        data.expectedCloseDate,
                                        'DD/MM/YYYY HH:mm:ss',
                                        'L',
                                        false,
                                    ) || ''
                                }
                                noEllipsis={true}
                            />
                        )}
                        {showCloseDate && (
                            <RowDetailWidget
                                className="sales-order-detail-widget__close-date"
                                label="label_salesorders_close"
                                value={
                                    getLocalDate(
                                        data.closeDate,
                                        'DD/MM/YYYY HH:mm:ss',
                                        'L',
                                        false,
                                    ) || ''
                                }
                                noEllipsis={true}
                            />
                        )}
                        {showOwner && (
                            <RowDetailWidget
                                className="sales-order-detail-widget__owner"
                                label="label_owner"
                                value={
                                    !isBackendFalsy(data.idResponsible) && (
                                        <UserChip
                                            idUser={data.idResponsible}
                                            username={data.responsible}
                                            remotePopover={true}
                                        />
                                    )
                                }
                                noEllipsis={true}
                            />
                        )}
                        {showEnvironment && (
                            <RowDetailWidget
                                className="sales-order-detail-widget__environment"
                                label="label_environment"
                                value={data.branch || ''}
                                noEllipsis={true}
                            />
                        )}
                        {showCompany && (
                            <RowDetailWidget
                                className="sales-order-detail-widget__company"
                                label="label_account"
                                customLabel={companyLabel}
                                value={
                                    !isBackendFalsy(data.idCompany) && (
                                        <CompanyChip
                                            nameCompany={data.company}
                                            idCompany={data.idCompany}
                                            remotePopover={true}
                                            onClickPopover={
                                                withTabs
                                                    ? () =>
                                                          openTab({
                                                              entity: COMPANIES,
                                                              id: data.idCompany,
                                                              hasCrudInDetail: true,
                                                          })
                                                    : null
                                            }
                                        />
                                    )
                                }
                                noEllipsis={true}
                            />
                        )}
                        {showAddress && address && (
                            <RowDetailWidget
                                className="sales-order-detail-widget__address"
                                label="label_shipping_address_salesorders"
                                value={address}
                                noEllipsis={true}
                            />
                        )}
                        {showRate && data.rate && (
                            <RowDetailWidget
                                className="sales-order-detail-widget__rate"
                                label="label_rate"
                                value={data.rate || ''}
                                noEllipsis={true}
                            />
                        )}
                        {showCurrency && data.currencyDescription && (
                            <RowDetailWidget
                                className="sales-order-detail-widget__currency"
                                label="label_currency"
                                value={data.currencyDescription || ''}
                                noEllipsis={true}
                            />
                        )}
                        {showContact && (
                            <RowDetailWidget
                                className="sales-order-detail-widget__contact"
                                label="label_contact"
                                value={
                                    !isBackendFalsy(data.idContact) && (
                                        <ContactChip
                                            idContact={data.idContact}
                                            nameContact={data.contact}
                                            remotePopover={true}
                                            onClickPopover={
                                                withTabs
                                                    ? () =>
                                                          openTab({
                                                              entity: CONTACTS,
                                                              id: data.idContact,
                                                          })
                                                    : null
                                            }
                                        />
                                    )
                                }
                                noEllipsis={true}
                            />
                        )}
                        {showOpportunity && (
                            <RowDetailWidget
                                className="sales-order-detail-widget__opportunity"
                                label="title_opportunity"
                                value={
                                    !isBackendFalsy(data.idOpportunity) && (
                                        <OpportunityChip
                                            idOpportunity={data.idOpportunity}
                                            nameOpportunity={data.opportunity}
                                            remotePopover={true}
                                            onClickPopover={
                                                withTabs
                                                    ? () =>
                                                          openTab({
                                                              entity: OPPORTUNITIES,
                                                              id: data.idOpportunity,
                                                              hasCrudInDetail: true,
                                                          })
                                                    : null
                                            }
                                        />
                                    )
                                }
                                noEllipsis={true}
                            />
                        )}
                        {data.signature && (
                            <RowDetailWidget
                                className="sales-order-detail-widget__signature"
                                label="label_signed_from_mobile"
                                value={signaturePopover || ''}
                                noEllipsis={true}
                            />
                        )}
                    </div>
                </WidgetContentLayout>
            </WidgetLayout>
        );
    },
);

export default connect(mapStateToProps)(SalesOrderDetailWidget);
