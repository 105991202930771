import React from 'react';
import colors from 'constants/colors';

const SmallArrow = ({ color = colors.actionMinor[500] }) => (
    <svg viewBox={`0 0 18 18`} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14 7.9514L12.825 7 9 10.0904 5.175 7 4 7.9514 9 12z"
            fill={color || '#335B70'}
            fillRule="evenodd"
        />
    </svg>
);

export default SmallArrow;
