import React, { memo, useMemo } from 'react';
import { URL_IMAGE, PATH_IMAGE } from 'constants/Environment';
import Context from 'managers/Context';
import { Carousel } from 'hoi-poi-ui';

import './styles.scss';

function getImageUrls(id) {
    let config = {
        token: Context?.config?.token,
        strCellPhoneNumber: Context?.config?.userData?.user,
    };

    return {
        original: `${URL_IMAGE}GetImage.ashx?userKey=JWT ${config.token}&strCellPhoneNumber=${config.strCellPhoneNumber}&getbytes=true&idImage=${id}&height=550`,
        fullscreen: `${URL_IMAGE}GetImage.ashx?userKey=JWT ${config.token}&strCellPhoneNumber=${config.strCellPhoneNumber}&getbytes=true&idImage=${id}`,
        thumbnail: `${URL_IMAGE}GetImage.ashx?userKey=JWT ${config.token}&strCellPhoneNumber=${config.strCellPhoneNumber}&getbytes=true&idImage=${id}&width=200&height=200`,
    };
}

const ProductCarousel = memo(({ ids }) => {
    const images = useMemo(() => ids?.reverse?.().map(getImageUrls), [ids]);
    return (
        <div className="fm-products-detail__carousel">
            {ids && <Carousel placeholder={`${PATH_IMAGE}error-image.png`} images={images} />}
            {!ids && (
                <img
                    className="fm-products-detail__carousel-no-image"
                    src={`${PATH_IMAGE}no-image.svg`}
                    alt="No images"
                />
            )}
        </div>
    );
});

export default ProductCarousel;
