import { memo } from 'react';
import { Text, Icon, Button, useTheme } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';

const AutomationBuilderSidePanel = memo(
    ({ title, subtitle, onClose, children, onDone, onDelete, deleteBtnText, isDoneDisabled }) => {
        const theme = useTheme();
        return (
            <div className="fm-automation-builder__side-panel">
                <div className="fm-automation-builder__side-panel__header">
                    <div className="fm-automation-builder__side-panel__title">
                        <Text type="h6">{title}</Text>
                        <Icon name="close" size="large" onClick={onClose} />
                    </div>
                    <Text color={theme.colors.utility.textSecondary}>{subtitle}</Text>
                </div>
                <div className="fm-automation-builder__side-panel__content">{children}</div>
                <div className="fm-automation-builder__side-panel__footer">
                    {onDelete && (
                        <Button onClick={onDelete} type="terciary">
                            {deleteBtnText}
                        </Button>
                    )}
                    {onDone && (
                        <Button onClick={onDone} isDisabled={isDoneDisabled}>
                            {getLiteral('action_done')}
                        </Button>
                    )}
                </div>
            </div>
        );
    },
);

export default AutomationBuilderSidePanel;
