import React from 'react';
import colors from 'constants/colors';

const VideoCheckIn = ({ color = colors.actionMinor[500], color2 = colors.actionMajor[500] }) => (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
            <path
                d="M16 6c.55 0 1 .45 1 1v3.5l4-4v11l-4-4V17c0 .55-.45 1-1 1h-5.94l.092-.191c.494-1.096.79-2.133.84-3.122L11 14.39C11 10.85 8.083 8 4.5 8c-.517 0-1.02.06-1.501.171L3 7c0-.55.45-1 1-1z"
                fill={color || '#335B70'}
            />
            <path
                d="M4.618 10c-2.488 0-4.5 2.034-4.5 4.55 0 3.412 4.5 8.45 4.5 8.45s4.5-5.038 4.5-8.45c0-2.516-2.012-4.55-4.5-4.55zm-.15 5.881c-.745 0-1.35-.624-1.35-1.393s.605-1.393 1.35-1.393c.745 0 1.35.624 1.35 1.393s-.605 1.393-1.35 1.393z"
                fill={color2 || '#FF8C00'}
            />
        </g>
    </svg>
);

export default VideoCheckIn;
