import ConstantsColors from 'constants/colors';

export const colors = [
    ConstantsColors.semantic.focus500,
    ConstantsColors.semantic.positiveCustom300,
    ConstantsColors.semantic.info500,
    ConstantsColors.temp.purple500,
    '#02A8BD',
    '#A9D039',
    '#ff8c00',
    '#FFCE2E',
    '#EF6A99',
    '#7527CF',
    '#B265A5',
    '#F8333C',
    '#216DB3',
    '#90A4AE',
    '#2AB34B',
    '#5F6365',
    '#55DDE0',
    '#7678ED',
    '#9D7C70',
];
