import React, { memo, useMemo, useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Tooltip, Text, useTheme } from 'hoi-poi-ui';
import { AGENDA, TASKS } from 'constants/Entities';
import { CALENDAR_VIEWS } from 'constants/Constants';
import { TasksActions, EntityListActions } from 'actions';
import { getBackendBoolean } from 'utils/fm';
import { getDateMomentFromBackendTimestamp, getDateToTimezone } from 'utils/dates';
import Context from 'managers/Context';
import EntityDetailPopover from 'containers/components/EntityDetailPopover';
import AgendaSmallDetail from 'containers/agenda/components/AgendaSmallDetail';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { publish } from 'lib/EventBuser';
import { REFRESH_TABLE } from 'lib/events';
import { PAGINATION_TABLE_AGENDA } from 'constants/Environment';

import EventItem from './EventItem';

import '../styles.scss';

const mapStateToProps = (state) => {
    const agendaState = state.agenda;
    const agendaFilters = state.entityFilters?.[AGENDA.entity] || null;
    const filtersColor = agendaState?.filtersColor || null;
    const idUserColors = filtersColor?.filters?.idusuario?.colors;
    const permissions = state.config.permission.crud_permission.AGENDA;
    const canEdit = permissions.update;
    const canDelete = permissions.delete;

    const calendarView = agendaFilters?.outlierFilters?.view?.value || null;

    return {
        idUserColors,
        canEdit,
        canDelete,
        timezone: agendaState.timezone || {},
        calendarView,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeTaskCompleted: bindActionCreators(TasksActions, dispatch).changeTaskCompleted,
        init: bindActionCreators(EntityListActions, dispatch).init,
    };
};

const Event = memo(
    ({
        data,
        idUserColors,
        changeTaskCompleted,
        onEditEvent,
        canEdit,
        canDelete,
        timezone,
        getIsSmallDetailOpen,
        init,
        calendarView,
    }) => {
        const [isDetailVisible, setIsDetailVisible] = useState(false);
        const [hover, setHover] = useState(false);
        const popoverCallbacksRef = useRef({});
        const theme = useTheme();
        const colors = theme.colors;

        const getBullets = useCallback(
            (event) => {
                if (!event.usersAttendees?.length || !idUserColors) return null;

                const bullets = event.usersAttendees.reduce((arr, current, index) => {
                    const color = idUserColors[current.value] || null;
                    if (color) {
                        arr.push(
                            <div
                                key={index}
                                className="agenda__items-bullet"
                                style={{ backgroundColor: color.default }}
                            ></div>,
                        );
                    }
                    return arr;
                }, []);

                if (!bullets.length) return null;
                return <div className="agenda__item-bullets">{bullets}</div>;
            },
            [idUserColors],
        );

        const getIsPastEvent = useCallback(
            (event) => {
                const endMoment = getDateToTimezone({
                    date: event.hfin,
                    outputFormat: 'YYYY-MM-DDTHH:mm',
                    timezone: timezone.idianazone,
                    returnMoment: true,
                });

                const isPast = moment(endMoment, 'YYYY-MM-DDTHH:mm').isBefore(moment(), 'minute');
                return isPast;
            },
            [timezone],
        );

        const getColor = useCallback(
            (data, isPastEvent, isHover) => {
                const { primary, textLight, actionMajor } = colors;
                const userColors = idUserColors?.[data.IdUsuario] || null;

                let color;

                if (isHover) {
                    color = primary.white;
                } else if (isPastEvent) {
                    color = userColors ? userColors.pastText : actionMajor[150];
                } else {
                    color = userColors ? userColors.defaultText : textLight.jadeAccessible;
                }

                // Return the color object with textColor2 always included
                return {
                    checkColor: color,
                    iconColor: color,
                    textColor: color,
                    textColor2: color,
                };
            },
            [idUserColors, colors],
        );

        // const getColor = useCallback(
        //     (data, isPastEvent, isHover) => {
        //         const defaultColors = {
        //             checkColor: colors.primary.white,
        //             iconColor: colors.primary.white,
        //             textColor: colors.primary.white,
        //         };

        //         const userColors = idUserColors?.[data.IdUsuario] || null;

        //         if (!isPastEvent) {
        //             if (userColors) {
        //                 const color = isHover ? colors.primary.white : userColors.defaultText;
        //                 return {
        //                     ...defaultColors,
        //                     checkColor: color,
        //                     iconColor: color,
        //                     textColor: color,
        //                 };
        //             }

        //             const color = isHover ? colors.primary.white : colors.textLight.jadeAccessible;
        //             return {
        //                 ...defaultColors,
        //                 checkColor: color,
        //                 iconColor: color,
        //                 textColor: color,
        //                 textColor2: color,
        //             };
        //         }

        //         if (userColors) {
        //             const color = userColors.pastText;
        //             return {
        //                 ...defaultColors,
        //                 checkColor: color,
        //                 iconColor: color,
        //                 textColor: color,
        //             };
        //         }

        //         const color = isHover ? colors.primary.white : colors.actionMajor[150];
        //         return {
        //             ...defaultColors,
        //             checkColor: color,
        //             iconColor: color,
        //             textColor: color,
        //             textColor2: color,
        //         };
        //     },
        //     [idUserColors, colors],
        // );

        const onCheckTask = useCallback(
            (task, isCompleted) => {
                changeTaskCompleted(AGENDA, task, isCompleted);
            },
            [changeTaskCompleted],
        );

        const onPopupVisibleChange = useCallback(
            (isVisible) => {
                setIsDetailVisible(isVisible);
                if (popoverCallbacksRef?.current?.onPopupVisibleChange) {
                    popoverCallbacksRef?.current?.onPopupVisibleChange(isVisible);
                }
                if (isVisible) {
                    getIsSmallDetailOpen(true);
                } else {
                    // We add a timeout to prevent opening the crud when clicking into a
                    // grid date cell to close the popover detail
                    setTimeout(() => {
                        getIsSmallDetailOpen(false);
                    }, 500);
                }
            },
            [getIsSmallDetailOpen],
        );

        const canEditItem = useMemo(() => {
            const extendedProps = data.event.extendedProps;
            return getBackendBoolean(extendedProps.CanEdit);
        }, [data]);

        const canDeleteItem = useMemo(() => {
            const extendedProps = data.event.extendedProps;
            return getBackendBoolean(extendedProps.CanDelete);
        }, [data]);

        const isEventEditable = useMemo(() => {
            const extendedProps = data.event.extendedProps;
            if (
                !extendedProps.g_recurrence &&
                canEdit &&
                canEditItem &&
                !extendedProps.isReadOnly
            ) {
                return true;
            } else return false;
        }, [canEdit, data, canEditItem]);

        const isEventDeletable = useMemo(() => {
            const extendedProps = data.event.extendedProps;
            if (
                !extendedProps.g_recurrence &&
                canDelete &&
                canEditItem &&
                canDeleteItem &&
                !extendedProps.isReadOnly
            ) {
                return true;
            } else return false;
        }, [data.event.extendedProps, canDelete, canEditItem, canDeleteItem]);

        const onDeleteEvent = useCallback(
            (extendedProps) => {
                return new Promise((resolve, reject) => {
                    const isTask = getBackendBoolean(extendedProps.isTarea);
                    const entity = isTask ? TASKS : AGENDA;
                    const id = extendedProps.Id;
                    Context.domainManager.deleteEntity(
                        entity.entity,
                        id,
                        (result) => {
                            getIsSmallDetailOpen(false);
                            init(AGENDA, true, PAGINATION_TABLE_AGENDA);
                            publish(`${REFRESH_TABLE}_${TASKS.entity}`);
                            resolve();
                        },
                        (error) => {
                            reject();
                        },
                    );
                });
            },
            [init, getIsSmallDetailOpen],
        );

        const isLongerThanHour = useMemo(() => {
            const event = data.event;
            const extendedProps = event.extendedProps;
            if (calendarView.value === CALENDAR_VIEWS.month || extendedProps.isTodoDia) {
                return false;
            }

            if (extendedProps.isTarea) return true;
            const startDate = moment(extendedProps.fini);
            const endDate = moment(extendedProps.hfin);
            const durationMinutes = endDate.diff(startDate, 'minutes');
            if (Math.abs(durationMinutes) >= 60) return true;
            return false;
        }, [calendarView, data]);

        const tooltipContent = useMemo(() => {
            const event = data.event;
            const extendedProps = event.extendedProps;

            const startTime = getDateToTimezone({
                date: extendedProps.hini,
                outputFormat: 'HH:mm',
                timezone: timezone.idianazone,
            });

            const endTime = getDateToTimezone({
                date: extendedProps.hfin,
                outputFormat: 'HH:mm',
                timezone: timezone.idianazone,
            });

            let text = `${getLiteral('label_from')} ${startTime}`;
            if (!extendedProps.isTodoDia) {
                text = `${text} ${getLiteral('label_until').toLowerCase()} ${endTime}`;
            } else {
                text = getLiteral('placeholder_allday');
            }

            if (extendedProps.isTarea) {
                text = `${getLiteral('label_calendar_at')} ${startTime}`;
            }

            let upperText = '';
            if (extendedProps.Asunto) upperText = extendedProps.Asunto;
            if (extendedProps.account?.label) {
                upperText = `${upperText} - ${extendedProps.account.label}`;
            }

            return (
                <>
                    {upperText && <Text color={theme.colors.primary.white}>{upperText}</Text>}
                    <Text color={theme.colors.primary.white} type="caption">
                        {text}
                    </Text>
                </>
            );
        }, [data, timezone, theme]);

        const renderEventContent = useMemo(() => {
            const view = data.view.type;
            const event = data.event;

            const extendedProps = event.extendedProps;

            const startDate = getDateToTimezone({
                date: extendedProps.hini,
                timezone: timezone.idianazone,
            });

            const isTask = getBackendBoolean(extendedProps.isTarea);
            const isCompleted = getBackendBoolean(extendedProps.finalizada);
            const isTodoDia = getBackendBoolean(extendedProps.isTodoDia);
            const isTaskOverdue = isTask && !isCompleted && new Date() > startDate;

            const itemClasses = ['calendar__item'];

            let itemClassName = [itemClasses];

            const isPastEvent = getIsPastEvent(extendedProps);

            if (isPastEvent && idUserColors) {
                itemClassName.push('calendar__item-past');
                const idUser =
                    extendedProps?.IdUsuario && extendedProps?.IdUsuario !== '-1'
                        ? extendedProps.IdUsuario
                        : '';

                if (idUser && idUserColors?.[idUser]) {
                    let color = idUserColors?.[idUser].name || '';
                    let colorClass = `calendar-${color.trim()}--past`;
                    itemClassName.push(colorClass);
                }
            }

            if (!isPastEvent && idUserColors) {
                if (idUserColors?.[extendedProps.IdUsuario]) {
                    let color = idUserColors?.[extendedProps.IdUsuario].name || '';
                    let colorClass = `calendar-${color.trim()}`;
                    itemClassName.push(colorClass);
                } else {
                    itemClassName.push('calendar__item-todo');
                }
            }

            if (!data.isEndResizable) {
                itemClassName.push('calendar__item--not-end-resizable');
            }

            if (isTask && isCompleted) itemClassName.push('calendar__item-task--completed');

            const { checkColor, iconColor, textColor, textColor2 } = getColor(
                extendedProps,
                isPastEvent,
                hover,
            );

            const numberAttendees = extendedProps?.attendees?.length || 0;
            let showDate = false;

            switch (view) {
                case CALENDAR_VIEWS.day:
                    itemClassName.push('calendar__item-day');
                    break;
                case CALENDAR_VIEWS.week:
                    itemClassName.push('calendar__item-week');
                    break;
                case CALENDAR_VIEWS.month:
                default:
                    itemClassName.push('calendar__item-month');
                    if (!isTodoDia) showDate = true;
                    break;
            }

            const commonProps = {
                data: extendedProps,
                checkColor,
                iconColor,
                textColor,
                textColor2,
                numberAttendees,
                isTask,
                onCheckTask,
                getBullets,
                isTaskOverdue,
                showDate,
                isTodoDia,
                timezone,
                isLongerThanHour,
            };

            let popoverCustomProps = {};

            if (isEventEditable) {
                popoverCustomProps = {
                    onEdit: () => {
                        onEditEvent(extendedProps);
                        if (document.querySelector('.fc-more-popover'))
                            document.querySelector('.fc-popover-close')?.click();

                        if (document.querySelector('.fm-entity-detail-popover__close-icon'))
                            document
                                .querySelector('.fm-entity-detail-popover__close-icon')
                                ?.click();
                    },
                    titleDelete: getBackendBoolean(extendedProps.isTarea)
                        ? getLiteral('title_delete_task')
                        : getLiteral('title_delete_event'),
                    subtitleDelete: getLiteralWithParameters('confirm_delete', [
                        `"${extendedProps.Asunto}"`,
                    ]),
                    getTooltipContainer: document.querySelector('.fc-more-popover')
                        ? () => document.querySelector('.fc-more-popover')
                        : null,
                };
            } else {
                let labelCantEdit = '';

                if (!canEdit || !canEditItem || extendedProps.isReadOnly) {
                    labelCantEdit = getLiteral('label_calendar_no_edit_no_permission');
                }
                if (extendedProps.g_recurrence) {
                    labelCantEdit = getLiteral('label_calendar_no_edit_recurrent');
                }

                popoverCustomProps = { labelCantEdit };
            }

            if (isEventDeletable) {
                popoverCustomProps.onDelete = () => onDeleteEvent(extendedProps);
            }

            return (
                <Tooltip placement="topLeft" content={tooltipContent}>
                    <EntityDetailPopover
                        {...popoverCustomProps}
                        content={
                            <AgendaSmallDetail
                                data={extendedProps}
                                popoverCallbacks={popoverCallbacksRef.current}
                                isDetailVisible={isDetailVisible}
                            />
                        }
                        onPopupVisibleChange={onPopupVisibleChange}
                        entity={AGENDA}
                        entityId={extendedProps?.Id}
                    >
                        <div
                            className={itemClassName.join(' ')}
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                        >
                            <EventItem {...commonProps} />
                        </div>
                    </EntityDetailPopover>
                </Tooltip>
            );
        }, [
            data,
            idUserColors,
            getBullets,
            getIsPastEvent,
            getColor,
            onCheckTask,
            onEditEvent,
            timezone,
            onPopupVisibleChange,
            isEventEditable,
            isEventDeletable,
            onDeleteEvent,
            isLongerThanHour,
            tooltipContent,
            canEdit,
            canEditItem,
            isDetailVisible,
            hover,
        ]);

        if (!data?.event?.extendedProps?.Id) return null;
        return renderEventContent;
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(Event);
