import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import { Button, Text, useTheme } from 'hoi-poi-ui';
import { Usage, ModalDisableAddOn, SettingsIllustrations } from '@web/web5';
import { COMPANIES, CONTACTS, OPPORTUNITIES, SALESORDERS } from 'constants/Entities';
import { USAGE_LIMITS_DELETE_ITEMS, USAGE_LIMITS_MANAGE_DUPLICATES } from 'constants/ActionTypes';
import { SettingsService } from 'services';
import {
    getLiteral,
    getLiteralWithParameters,
    getLiteralWithParametersHtml,
} from 'utils/getLiteral';
import { getCookieDomainName, ensureRoute } from 'utils/routes';
import { logEvent } from 'utils/tracking';
import useSettings from '../hooks/useSettings';
import { COOKIE_USAGE_LIMITS } from './constants';
import { successToast } from 'utils/toast';

import './styles.scss';

const { getLimits } = SettingsService;
const { ManageEntity } = SettingsIllustrations;

const ICONS = {
    lim_api_max_request_min: 'cloud',
    lim_max_data_storage_imp: 'storage',
    lim_max_users: 'group',
    lim_max_scans: 'scanCard',
    lim_max_geoloc_initial: 'geolocation',
    lim_max_geoloc_month: 'geolocation',
    lim_max_custom_fields: 'fields',
    lim_max_accounts: 'accounts',
    lim_max_contacts: 'contacts',
    lim_max_opportunities: 'opportunities',
    lim_max_salesorders: 'salesOrders',
};

const mapStateToProps = (state) => {
    const config = state.config || {};
    const locale = config.userData?.locale;
    return {
        locale,
    };
};

const LimitsManagement = ({ locale }) => {
    const [loading, setLoading] = useState(true);
    const [schema, setSchema] = useState([]);
    const [modal, setModal] = useState(null);
    const theme = useTheme();

    const {
        handleContactRequest,
        handleUpgradePlan,
        planName,
        renderContactModal,
        renderUpgradeModal,
        showComparePlans,
        renderSupportChat,
    } = useSettings({ submodule: 'limits' });

    const handleContactUs = useCallback(
        (params) => {
            handleContactRequest(params);
        },
        [handleContactRequest],
    );

    const handleManageUsers = useCallback(() => {
        logEvent({
            event: 'settings',
            submodule: 'limits',
            functionality: 'manageUsers',
        });
        ensureRoute('/settings/users');
    }, []);

    const handleManageEntity = useCallback((entity) => {
        logEvent({
            event: 'settings',
            submodule: 'limits',
            functionality: entity.trueName,
        });
        setModal(entity.entity);
    }, []);

    const handleManageEntityActions = useCallback((entity, action) => {
        const functionality = {
            [USAGE_LIMITS_DELETE_ITEMS]: 'delete',
            [USAGE_LIMITS_MANAGE_DUPLICATES]: 'duplicates',
        };

        logEvent({
            event: 'settings',
            submodule: 'limits',
            functionality: functionality[action],
        });

        setModal(null);

        Cookies.set(COOKIE_USAGE_LIMITS, action, {
            domain: getCookieDomainName(window.location.hostname),
            expires: new Date(new Date().getTime() + 1 * 60 * 1000),
        });

        ensureRoute(entity.route);
    }, []);

    const onSuccess = useCallback(
        () =>
            successToast({
                title: getLiteral('label_addon_contact_us'),
                text: getLiteral('label_addon_contact_us_desc'),
            }),
        [],
    );

    const handleData = useCallback(
        (data) => {
            const newSchema = data
                .reduce((arr, item) => {
                    let {
                        itemCode,
                        labelName: title,
                        labelHelpText,
                        limitUsed: value,
                        value: max,
                        isHidden,
                    } = item;

                    if (isHidden) return arr;

                    value = value ? parseInt(value, 10) : 0;
                    max = max ? parseInt(max, 10) : 0;

                    const icon = ICONS[itemCode];
                    const percentage = (value / max) * 100;

                    let altCTA = {
                        type: 'secondary',
                        text: getLiteral('action_contct_us'),
                        onClick: () =>
                            handleContactUs({ relatedItemLabel: getLiteral(title), onSuccess }),
                    };

                    let progressCustomFormat = null;

                    switch (itemCode) {
                        case 'lim_max_users':
                            altCTA = {
                                type: 'secondary',
                                text: getLiteral('action_manage_users'),
                                onClick: handleManageUsers,
                            };
                            break;
                        case 'lim_max_accounts':
                            altCTA = {
                                type: 'secondary',
                                text: getLiteral('action_manage_accounts'),
                                onClick: () => handleManageEntity(COMPANIES),
                            };
                            break;
                        case 'lim_max_contacts':
                            altCTA = {
                                type: 'secondary',
                                text: getLiteral('action_manage_contacts'),
                                onClick: () => handleManageEntity(CONTACTS),
                            };
                            break;
                        case 'lim_max_opportunities':
                            altCTA = {
                                type: 'secondary',
                                text: getLiteral('action_manage_opportunities'),
                                onClick: () => handleManageEntity(OPPORTUNITIES),
                            };
                            break;
                        case 'lim_max_salesorders':
                            altCTA = {
                                type: 'secondary',
                                text: getLiteral('action_manage_salesorders'),
                                onClick: () => handleManageEntity(SALESORDERS),
                            };
                            break;
                        case 'lim_max_data_storage_imp':
                            progressCustomFormat = (value) => `${value} GB`;
                            break;
                        default:
                            break;
                    }

                    const newItem = {
                        itemCode,
                        title: getLiteral(title),
                        icon,
                        progress: {
                            value,
                            max,
                        },
                        percentage,
                        info: {
                            text: getLiteral(labelHelpText),
                            cta: [
                                {
                                    type: 'primary',
                                    text: getLiteral('action_upgrade_plan'),
                                    onClick: handleUpgradePlan,
                                },
                                altCTA,
                            ],
                        },
                        progressCustomFormat,
                    };

                    return [...arr, newItem];
                }, [])
                .sort((a, b) => b.percentage - a.percentage);

            setSchema(newSchema);
        },
        [handleContactUs, handleManageEntity, handleManageUsers, handleUpgradePlan, onSuccess],
    );

    const handleHover = useCallback(() => {
        logEvent({
            event: 'settings',
            submodule: 'limits',
            functionality: 'hover',
        });
    }, []);

    useEffect(() => {
        getLimits()
            .then((data) => {
                handleData(data);
            })
            .catch((error) => console.log(error))
            .finally(() => {
                setLoading(false);
            });
    }, [handleData]);

    const renderManageEntityModal = useMemo(() => {
        const customProps = {};

        switch (modal) {
            case COMPANIES.entity:
                customProps.middleButtonText = getLiteral('action_delete_accounts');
                customProps.onMiddleButton = () =>
                    handleManageEntityActions(COMPANIES, USAGE_LIMITS_DELETE_ITEMS);
                customProps.confirmText = getLiteral('action_manage_duplicates');
                customProps.onConfirm = () =>
                    handleManageEntityActions(COMPANIES, USAGE_LIMITS_MANAGE_DUPLICATES);
                break;
            case CONTACTS.entity:
                customProps.confirmText = getLiteral('action_delete_contacts');
                customProps.onConfirm = () =>
                    handleManageEntityActions(CONTACTS, USAGE_LIMITS_DELETE_ITEMS);
                break;
            case OPPORTUNITIES.entity:
                customProps.confirmText = getLiteral('action_delete_opportunities');
                customProps.onConfirm = () =>
                    handleManageEntityActions(OPPORTUNITIES, USAGE_LIMITS_DELETE_ITEMS);
                break;
            case SALESORDERS.entity:
                customProps.confirmText = getLiteral('action_delete_salesorders');
                customProps.onConfirm = () =>
                    handleManageEntityActions(SALESORDERS, USAGE_LIMITS_DELETE_ITEMS);
                break;
            default:
                break;
        }

        const finalProps = {
            isOpen: !!modal,
            title: getLiteral('title_free_space'),
            customImage: ManageEntity,
            content: [getLiteral('label_free_space_desc')],
            onRequestClose: () => setModal(null),
            ...customProps,
        };

        return <ModalDisableAddOn {...finalProps} />;
    }, [handleManageEntityActions, modal]);

    const getHeader = useMemo(
        () => (
            <div className="fm-lm__header">
                <div className="fm-lm__header__content">
                    <Text type="h5">{getLiteral('title_usage')}</Text>
                    <Text type="body" color={theme.colors.utility.textSecondary}>
                        {getLiteralWithParametersHtml(
                            'label_manage_your_plan_description',
                            [planName],
                            (text) => (
                                <strong>{text}</strong>
                            ),
                        )}
                    </Text>
                    {renderSupportChat}
                </div>
                {showComparePlans && (
                    <Button type="primary" onClick={handleUpgradePlan}>
                        {getLiteral('action_compare_your_plan')}
                    </Button>
                )}
            </div>
        ),
        [planName, showComparePlans, handleUpgradePlan, renderSupportChat, theme],
    );

    const usageProps = useMemo(
        () => ({ schema, locale, onHover: handleHover }),
        [schema, locale, handleHover],
    );

    return !loading ? (
        <div className="fm-lm">
            {getHeader}
            <div className="fm-lm__grid">
                <Usage {...usageProps} />
            </div>
            {renderContactModal}
            {renderUpgradeModal}
            {renderManageEntityModal}
        </div>
    ) : null;
};

export default memo(connect(mapStateToProps)(LimitsManagement));
