import { memo } from 'react';
import { LottieGif } from '@web/web5';
import { getPartner } from 'lib/partners';

import './styles.scss';

const DanaLoader = memo(() => {
    const { danaLoaderLottie } = getPartner();
    return (
        <div className="dana-chat-loader">
            <LottieGif src={danaLoaderLottie} />
        </div>
    );
});

export default DanaLoader;
