import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { GoalsService } from 'services';
import { KpisActions, EntityFiltersActions } from 'actions';
import NewEntityList from 'containers/components/NewEntityList';
import { PATH_IMAGE } from 'constants/Environment';
import { ensureRoute } from 'utils/routes';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { sort } from 'utils/arrays';
import CONFIG from './TableConfig';
import './styles.scss';

const mapStateToProps = (state) => {
    return {
        loggedUser: state.config?.userData?.idUsuario,
        selectedUser: state.users?.usersTree?.selectedUser,
        isPremium: state.config?.permission?.kpisPremium || false,
        dragGroups: state.kpis?.dragGroups || null,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        initCrud: bindActionCreators(KpisActions, dispatch).initCrud,
        changeFilter: bindActionCreators(EntityFiltersActions, dispatch).changeFilter,
        getTeamGoals: bindActionCreators(KpisActions, dispatch).getTeamGoals,
        updateOrder: bindActionCreators(KpisActions, dispatch).updateOrder,
    };
};

const Content = memo(
    ({ loggedUser, selectedUser, getTeamGoals, isPremium, dragGroups, updateOrder }) => {
        const [showModalFields, setShowModalFields] = useState(false);
        const isChildrenRow = useRef(false);

        const onClickRow = useCallback(
            (event) => {
                let id = event.data.id;
                if (event.data.isChildren) {
                    isChildrenRow.current = true;
                } else isChildrenRow.current = false;
                ensureRoute(`/kpis/${id}`);
            },
            [isChildrenRow],
        );

        const cancelTeamGoalsRequest = useCallback(() => {
            GoalsService.cancelTeamGoalsRequest();
        }, []);

        const tableParams = useMemo(() => {
            return {
                onRequest: (id) => {
                    return new Promise((resolve, reject) => {
                        getTeamGoals(id)
                            .then((data) => {
                                resolve(data);
                            })
                            .catch(reject);
                    });
                },
                onRowDrag: (params) => {
                    const isCreatorMyself = loggedUser === String(params.data.creator);
                    return !params.data.isChildren && isCreatorMyself;
                },
            };
        }, [getTeamGoals, loggedUser]);

        const config = useMemo(() => {
            let actions = [];
            const config = CONFIG(tableParams);
            const groupedColumns = [
                {
                    label: '',
                    fields: sort(
                        config.columnDefs.map((column) => {
                            return {
                                colId: column.colId,
                                headerName: column.headerName,
                                field: column.field,
                                suppressFromFieldSelector: !!column.suppressFromFieldSelector,
                            };
                        }),
                        'headerName',
                    ),
                },
            ];

            actions.push({
                label: getLiteral('action_select_visible_columns'),
                icon: 'columns',
                id: 'columns',
                onClick: () => setShowModalFields(true),
            });

            return { ...config, actions, groupedColumns };
        }, [tableParams]);

        const fieldSelectorProps = useMemo(() => {
            return {
                ...config.fieldSelectorProps,
                actions: config.actions,
                groupedColumns: config.groupedColumns,
            };
        }, [config]);

        const EmptyIcon = useMemo(() => {
            return <img src={`${PATH_IMAGE}illustration_viewKPI.svg`} />;
        }, []);

        const emptyViewProps = useMemo(() => {
            let emptyTitleLiteral = '';

            if (selectedUser) {
                if (selectedUser.id === loggedUser)
                    emptyTitleLiteral = getLiteral('title_kpi_setup');
                else
                    emptyTitleLiteral = getLiteralWithParameters('title_kpi_setup_assigned', [
                        selectedUser.name,
                    ]);
            }

            let emptySubtitleLiteral =
                selectedUser && selectedUser.id === loggedUser
                    ? getLiteral('helptext_kpi_setup')
                    : getLiteral('label_kpi_no_kpi_other_user');

            return {
                icon: EmptyIcon,
                iconFirstTime: EmptyIcon,
                iconType: 'image',
                titleFirstTime: emptyTitleLiteral,
                subtitleFirstTime: emptySubtitleLiteral,
                canCreate: isPremium,
            };
        }, [selectedUser, loggedUser, isPremium, EmptyIcon]);

        const onDragEnd = (event) => {
            const offset = event.api.getFirstDisplayedRow();
            return updateOrder(event.node.data, event.overIndex + offset);
        };

        const getCustomRowNodeId = useCallback((data) => {
            if (data.isChildren) return `${data.id}_children`;
            else return data.id;
        }, []);

        const getCustomActiveRowId = useCallback(
            (activeRowId) => {
                const newActiveRowId = isChildrenRow?.current
                    ? `${activeRowId}_children`
                    : activeRowId;
                return newActiveRowId;
            },
            [isChildrenRow],
        );

        const modalOptionsProps = useCallback(
            () => ({
                advice: getLiteral('label_visible_fields_explanation'),
                isOpen: showModalFields,
                confirmText: getLiteral('action_save'),
                cancelText: getLiteral('action_cancel'),
                placeholderSearch: getLiteral('label_search_field'),
                title: getLiteral('label_visible_fields'),
                onCancel: setShowModalFields,
            }),
            [showModalFields],
        );

        return (
            <div className="kpis-content">
                <NewEntityList
                    id="fm-grid-kpis"
                    entity={config.entity}
                    config={config}
                    initOnReady={true}
                    useDragRows={true}
                    onClickRow={onClickRow}
                    onCancelGroupRequests={cancelTeamGoalsRequest}
                    emptyViewProps={emptyViewProps}
                    dragGroups={dragGroups}
                    onDragEnd={onDragEnd}
                    useCache={true}
                    useSort={false}
                    useDragColumns={true}
                    useFieldSelector={true}
                    fieldSelectorProps={fieldSelectorProps}
                    getCustomActiveRowId={getCustomActiveRowId}
                    getCustomRowNodeId={getCustomRowNodeId}
                    modalOptionsProps={modalOptionsProps()}
                />
            </div>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(Content);
