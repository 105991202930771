import { memo, useCallback, useEffect, useState } from 'react';

const ThemeSwitcher = () => {
    const [theme, setTheme] = useState('default');

    const applyTheme = useCallback((themeMode) => {
        if (themeMode === 'system') {
            const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
            document.documentElement.setAttribute('data-theme', isDarkMode ? 'dark' : 'light');
        } else {
            document.documentElement.setAttribute(
                'data-theme',
                themeMode === 'default' ? 'light' : themeMode,
            );
        }
    }, []);

    useEffect(() => {
        const savedTheme = localStorage.getItem('theme') || 'default';
        setTheme(savedTheme);
        applyTheme(savedTheme);
    }, [applyTheme]);

    const cycleTheme = useCallback(() => {
        const newTheme = theme === 'default' ? 'dark' : theme === 'dark' ? 'system' : 'default';
        setTheme(newTheme);
        localStorage.setItem('theme', newTheme);
        applyTheme(newTheme);
    }, [theme, applyTheme]);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.ctrlKey && e.altKey && e.key === 't') {
                cycleTheme();
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [cycleTheme]);

    useEffect(() => {
        if (theme === 'system') {
            const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
            const handleChange = () => applyTheme('system');
            mediaQuery.addEventListener('change', handleChange);

            return () => {
                mediaQuery.removeEventListener('change', handleChange);
            };
        }
    }, [applyTheme, theme]);

    return null;
};

export default memo(ThemeSwitcher);
