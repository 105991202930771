import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Text, useTheme } from 'hoi-poi-ui';
import { COMPANIES } from 'constants/Entities';
import useEntityDetail from 'containers/components/EntityDetail/hooks/useEntityDetail';
import { CompanyChip } from 'containers/components/chips';
import { getLiteral } from 'utils/getLiteral';
import { getMomentFromDateBackend, getDifferenceDays } from 'utils/dates';
import { formatAddress } from 'utils/addresses';

import './accountRow.scss';

const propTypes = {
    label: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    address: PropTypes.string,
};

const AccountRow = ({ account, label, id, name }) => {
    const theme = useTheme();
    const { hasTabs: withTabs, openTab } = useEntityDetail();

    const getInactivityDays = useCallback(() => {
        let inactivityDays = 0;
        if (account?.lastActivityDate) {
            let date = getMomentFromDateBackend(account.lastActivityDate);
            inactivityDays = -getDifferenceDays(date);
        }
        let inactivityDaysLiteral =
            inactivityDays === 1
                ? getLiteral('label_inactivity_day')
                : getLiteral('label_inactivity_days');

        return `${inactivityDays} ${inactivityDaysLiteral}`;
    }, [account]);

    const getCompleteAddress = useCallback(() => {
        return formatAddress(
            account.address,
            account.address2,
            account.city,
            account.region,
            account.postcode,
            account.country,
            account.idCountry,
        );
    }, [account]);

    const onClickCompany = useCallback(
        (e) => {
            e.preventDefault();
            openTab({
                entity: COMPANIES,
                id,
                hasCrudInDetail: true,
            });
        },
        [id, openTab],
    );

    if (!id || !name || name.length === 0) return null;

    return (
        <div className="related-accounts-row">
            <div className="related-accounts-row__header">
                <Text type="subtitle" color={theme.colors.utility.textPrimary}>
                    {getLiteral(label)}
                </Text>
            </div>
            <CompanyChip
                idCompany={id}
                nameCompany={name}
                remotePopover={true}
                address={getCompleteAddress()}
                inactivityDays={getInactivityDays()}
                onClickPopover={withTabs ? onClickCompany : null}
            />
        </div>
    );
};

AccountRow.propTypes = propTypes;

export default memo(AccountRow);
