import React, { memo, useState, useCallback, useEffect, useMemo } from 'react';
import { Text, useTheme } from 'hoi-poi-ui';
import { RichText } from '@web/web5';
import { getLiteral } from 'utils/getLiteral';
import { getEditorToolbar, defaultFrequentlyUsedEmoji } from '../../../utils/timeline';

const MessageInput = memo(
    ({
        onSubmit,
        avatar,
        editingValue,
        autofocus,
        customPlaceholder,
        isDisabled,
        canSubmit,
        onEsc,
        onChangeFocus,
        fetchSuggestions,
        emojiCache,
        setConfigWeb,
        storedMessage,
        handleNewMessage,
    }) => {
        const [value, setValue] = useState(storedMessage || {});
        const [focus, setFocus] = useState(false);
        const theme = useTheme();

        useEffect(() => {
            if (editingValue) setValue({ html: editingValue });
        }, [editingValue]);

        const onChange = useCallback(
            (value) => {
                if (Object.entries(value).length) {
                    setValue(value);
                    handleNewMessage(value);
                } else {
                    setValue({});
                }
            },
            [setValue, handleNewMessage],
        );

        const onEnter = useCallback(() => {
            const trimmedMessage = value?.text?.trim();
            if (!trimmedMessage) return;
            onSubmit(value.html).then(() => {
                setValue({});
                handleNewMessage(null);
            });
        }, [value, onSubmit, handleNewMessage]);

        const handleFocus = useCallback(
            (focus) => {
                setFocus(focus);
                onChangeFocus && onChangeFocus(focus);
            },
            [onChangeFocus],
        );

        const editorProps = useMemo(
            () => ({
                compactMode: true,
                isFullWidth: true,
                isReadOnly: isDisabled,
                placeholder: customPlaceholder,
                onChange,
                onSubmit: onEnter,
                onEsc,
                mention: {
                    fetchSuggestions,
                    tooltip: getLiteral('label_mention'),
                    texts: {
                        advice: getLiteral('label_conversation_mention_advice'),
                        noResults: getLiteral('label_no_results'),
                    },
                    maxVisibleItems: 7,
                    hideNoResultsPopover: true,
                },
                emoji: {
                    tooltip: getLiteral('label_emoji'),
                    texts: {
                        noResults: getLiteral('label_no_results'),
                        search_placeholder: getLiteral('label_search'),
                        frequently_used_emoji: getLiteral('label_frequently_used'),
                    },
                    maxVisibleItems: 7,
                    cache: emojiCache,
                    saveCache: (payload) => setConfigWeb('emoji', payload),
                    defaultFrequentlyUsed: defaultFrequentlyUsedEmoji,
                },
                overrides: {
                    editorWrapper: {
                        style: {
                            backgroundColor: theme.colors.primary.white,
                        },
                    },
                },
                value: value?.html,
                onChangeFocus: handleFocus,
                autofocus: !!storedMessage || autofocus,
                canSubmit,
                toolbar: getEditorToolbar(),
                submitTooltip: getLiteral('label_send_now'),
                chatbox: true,
            }),
            [
                isDisabled,
                customPlaceholder,
                onChange,
                onEnter,
                onEsc,
                fetchSuggestions,
                emojiCache,
                theme.colors.primary.white,
                value?.html,
                handleFocus,
                storedMessage,
                autofocus,
                canSubmit,
                setConfigWeb,
            ],
        );

        return (
            <div className="fm-timeline-textarea__wrapper">
                {avatar}
                <div className="fm-timeline-textarea__container">
                    <RichText {...editorProps} />
                    {focus && value?.text?.length > 3 && (
                        <div className="fm-timeline-textarea__caption">
                            <Text color={theme.colors.utility.textSecondary} type="caption" bold>
                                {`${getLiteral('label_shift_plus_enter')} `}
                            </Text>
                            <Text
                                className="fm-timeline-textarea__caption__text"
                                color={theme.colors.utility.textSecondary}
                                type="caption"
                            >
                                {getLiteral('label_to_add_new_line')}
                            </Text>
                        </div>
                    )}
                </div>
            </div>
        );
    },
);

export default MessageInput;
