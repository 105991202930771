import React, { useEffect, createContext, useReducer, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { getLiteral } from 'utils/getLiteral';
import { getAutomationActions } from 'services/AutomationsService';
import { errorToast } from 'utils/toast';

const initialState = {
    selected: null,
    nextSelected: null,
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'select':
            return { ...state, selected: action.node, nextSelected: null };
        case 'nextSelect':
            return { ...state, nextSelected: action.node };
        case 'close':
        default:
            return { ...initialState };
    }
};

const AutomationBuilderCanvasContext = createContext();

const AutomationBuilderCanvasProvider = ({ defaultNode, data, onChange, children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const firstTime = useRef(true);
    const actions = useRef([]);

    const getActions = useCallback(() => {
        if (actions.current?.length > 0) return Promise.resolve(actions.current);
        return getAutomationActions()
            .then((result) => {
                actions.current = result.map((item) => ({
                    label: getLiteral(item.description),
                    value: item.name,
                    iconType: item.icon,
                }));
                return actions.current;
            })
            .catch((e) => {
                console.error(e);
                errorToast({ text: getLiteral('error_generalerror') });
            });
    }, []);

    useEffect(() => {
        if (!firstTime.current) return;
        firstTime.current = false;
        if (defaultNode) dispatch({ type: 'select', node: defaultNode });
    }, [data, defaultNode, state]);

    return (
        <AutomationBuilderCanvasContext.Provider
            value={{
                state,
                dispatch,
                data,
                getActions,
                onChange,
            }}
        >
            {children}
        </AutomationBuilderCanvasContext.Provider>
    );
};

export { AutomationBuilderCanvasContext, AutomationBuilderCanvasProvider };

AutomationBuilderCanvasProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
