import React from 'react';
import colors from 'constants/colors';

const Signers = ({ color = colors.actionMinor[500] }) => (
    <svg viewBox={`0 0 18 18`}>
        <g fill={color || '#335B70'} fillRule="evenodd">
            <path d="M7.768 7.218c2.223 0 4.004-1.612 4.004-3.609C11.772 1.612 9.992 0 7.768 0 5.545 0 3.75 1.612 3.75 3.609c0 1.997 1.795 3.609 4.018 3.609zM7.5 8.782c-2.496 0-7.5 1.407-7.5 4.21V16h2.583c3.39-3.377 5.725-5.704 7.006-6.983-.77-.157-1.5-.235-2.089-.235zM12.83 9.37l.628.63a1 1 0 0 1 0 1.413L7.37 17.501a1 1 0 0 1-.671.292l-.667.025a.986.986 0 0 1-1.021-1.022l.024-.667a1 1 0 0 1 .292-.67l6.088-6.088a1 1 0 0 1 1.414 0zm2.562-2.562l.628.628a1 1 0 0 1 0 1.415l-.628.628a1 1 0 0 1-1.414 0l-.629-.628a1 1 0 0 1 0-1.415l.629-.628a1 1 0 0 1 1.414 0zm-3.179 8.179c-.888.84-2.18 2.207-2.722 2.844a.361.361 0 0 1-.55-.468 47.836 47.836 0 0 1 2.776-2.9c.61-.578 1.065-.953 1.363-1.099.792-.387.926.274.558 1.054a36.04 36.04 0 0 1-.754 1.5l-.309.58a21.995 21.995 0 0 0-.302.588c.189-.105.463-.266.837-.495l.033-.02c.665-.406.981-.583 1.256-.676.665-.225.95.27.605.922-.288.545-.366.503.556.062a.361.361 0 0 1 .312.651c-1.331.638-2.086.334-1.642-.761-.177.097-.405.232-.71.418l-.033.02c-1.026.627-1.254.751-1.539.751-.357 0-.576-.257-.53-.607.022-.179.108-.39.276-.725.063-.125.135-.263.247-.472l.305-.573c.216-.41.404-.777.587-1.154-.18.152-.388.34-.62.56z" />
        </g>
    </svg>
);

export default Signers;
