import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Menus, Icon, Text, useTheme } from 'hoi-poi-ui';

import './styles.scss';

const propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
};

const MenuHoi = memo(({ className, children, icon, items }) => {
    const theme = useTheme();

    const finalItems = useMemo(
        () =>
            items.map((item) => {
                let icon = null;
                if (item.icon) icon = <Icon name={item.icon} color={item.color} />;
                if (item.customIcon) icon = item.customIcon;
                return {
                    onClick: item.onClick,
                    title: (
                        <Text className="fm-menu-hoi__title" type="body" color={item.color}>
                            {item.title}
                        </Text>
                    ),
                    description: item.description ? (
                        <Text
                            className="fm-menu-hoi__description"
                            color={theme.colors.utility.textSecondary}
                            type="caption"
                        >
                            {item.description}
                        </Text>
                    ) : undefined,
                    icon,
                    className: item.className || '',
                };
            }),
        [items, theme],
    );

    const classes = useMemo(
        () => ({
            subMenu: classnames('fm-menu__sub-menu', className),
        }),
        [className],
    );

    return <Menus classes={classes} title={children} icon={icon} items={finalItems} />;
});

MenuHoi.propTypes = propTypes;

export default MenuHoi;
