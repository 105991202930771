import { memo, useCallback, useEffect, useMemo, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ConfigActions } from 'actions';
import { Text, Button, Icon, useTheme } from 'hoi-poi-ui';
import { SynchronizeService } from 'services';
import { AGENDA, ACTIVITIES } from 'constants/Entities';
import { PATH_IMAGE } from 'constants/Environment';
import { logEvent } from 'utils/tracking';
import { ensureRoute } from 'utils/routes';

import './styles.scss';

const mapStateToProps = (state) => ({
    email: state?.config?.userData?.email,
    userId: state?.config?.userData?.idUsuario,
    implId: state?.config?.userData?.idImplementacion,
    syncCalendarWithNylas: state?.config?.userData?.syncCalendarWithNylas,
    subscriptionModeCode: state?.config?.subscriptionModeCode,
    productModeCode: state?.config?.productModeCode,
    activitiesList: state?.entityList?.[ACTIVITIES.trueName],
    activitiesFilters: state?.entityFilters?.[ACTIVITIES.trueName]?.filters || {},
});

const mapDispatchToProps = (dispatch) => ({
    setConfigWeb: bindActionCreators(ConfigActions, dispatch).setConfigWeb,
});

const useSyncPopover = (entity, userId, implId) => {
    const hidden = 'hidden';
    const sessionKey = `fm-SyncPopover__${entity?.trueName}`;
    const [showPopover, setShowPopover] = useState(false);

    const hidePopover = useCallback(() => {
        const popoverLocalStorage = JSON.parse(localStorage.getItem(sessionKey)) || {};
        const popoversInfo = JSON.stringify({
            ...popoverLocalStorage,
            [`${userId}-${implId}`]: hidden,
        });
        localStorage.setItem(sessionKey, popoversInfo);
        setShowPopover(false);
    }, [implId, sessionKey, userId]);

    useEffect(() => {
        const popoverLocalStorage = JSON.parse(localStorage.getItem(sessionKey));
        const hiddenForUser = popoverLocalStorage
            ? popoverLocalStorage[`${userId}-${implId}`]
            : null;
        setShowPopover(hiddenForUser !== hidden);
    }, [implId, sessionKey, userId]);

    return [showPopover, hidePopover];
};

const SyncPopover = ({
    email,
    syncCalendarWithNylas,
    onClose,
    productModeCode,
    subscriptionModeCode,
    popover = {},
    entity,
    activitiesList,
    activitiesFilters,
    userId,
    implId,
}) => {
    const theme = useTheme();
    const [isSync, setIsSync] = useState(true);
    const [showPopover, hidePopover] = useSyncPopover(entity, userId, implId);

    const { image, title, text, buttonTxt } = popover;

    useEffect(() => {
        SynchronizeService.getAccount(email)
            .then((data) => {
                if (data.account.status > 1) {
                    setIsSync(true);
                }
            })
            .catch((e) => {
                console.error('error: ', e);
                if (e?.data?.code === 405) {
                    setIsSync(false);
                }
            });
    }, [email]);

    const handleClickSync = useCallback(() => {
        logEvent({
            event: entity?.trueName,
            submodule: 'suggestionMessage',
            functionality: 'goSyncSettings',
        });
        ensureRoute('/settings/nylas');
    }, [entity]);

    const handleClickClose = useCallback(() => {
        hidePopover();
        onClose && onClose();
    }, [hidePopover, onClose]);

    const renderPopover = useMemo(() => {
        switch (entity?.trueName) {
            case AGENDA.trueName:
                return (
                    showPopover &&
                    (syncCalendarWithNylas || !isSync) &&
                    (['starter', 'essential'].includes(productModeCode) ||
                        subscriptionModeCode === 'free_trial')
                );
            case ACTIVITIES.trueName:
                return (
                    (showPopover && syncCalendarWithNylas && !isSync) ||
                    (showPopover &&
                        syncCalendarWithNylas &&
                        isSync &&
                        !activitiesList?.loading &&
                        activitiesList?.total === 0 &&
                        Object.entries(activitiesFilters).length === 0)
                );
            default:
                return false;
        }
    }, [
        entity?.trueName,
        showPopover,
        syncCalendarWithNylas,
        isSync,
        productModeCode,
        subscriptionModeCode,
        activitiesList,
        activitiesFilters,
    ]);

    return (
        <Fragment>
            {renderPopover && (
                <div className="sync-popover__container">
                    <div className="sync-popover__image">
                        <img src={`${PATH_IMAGE}${image}`} />
                        <Icon
                            className="sync-popover__close-icon"
                            name="cancel"
                            size="large"
                            color={theme.colors.actionMinor[50]}
                            onClick={handleClickClose}
                        />
                    </div>
                    <div className="sync-popover__content">
                        {title && <Text type="subtitle1">{title}</Text>}
                        {text && (
                            <Text type="caption" color={theme.colors.utility.textSecondary}>
                                {text}
                            </Text>
                        )}
                        <Button type="secondary" size="small" onClick={handleClickSync}>
                            {buttonTxt}
                        </Button>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(SyncPopover));
