import React, { memo, useMemo, useCallback, useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { Text, useTheme } from 'hoi-poi-ui';
import Context from 'managers/Context';
import { PAGINATION_TABLE_NOTIFICATIONS } from 'constants/Environment';
import { getLiteral } from 'utils/getLiteral';
import { getElapsedTimeSimple } from 'utils/dates';
import BellNotificationsRow from './BellNotificationsRow';

const BellNotificationsPopover = memo(
    ({ onRef, idUser, onGetNotifications, onSelectNotification }) => {
        const [loading, setLoading] = useState(false);
        const [notifications, setNotifications] = useState([]);
        const [noMoreRows, setNoMoreRows] = useState(false);
        const popoverRef = useRef();
        const theme = useTheme();

        useEffect(() => {
            if (!loading && !noMoreRows && notifications.length === 0) {
                getNotificationsWeb();
            }
            if (onRef && popoverRef?.current) {
                onRef(popoverRef.current);
            }
        }, [loading, noMoreRows, notifications, getNotificationsWeb, onRef]);

        const getNotificationsWeb = useCallback(() => {
            setLoading(true);
            const notificationsLength = notifications.length;
            Context.domainManager
                .getNotificationsWeb(notificationsLength, PAGINATION_TABLE_NOTIFICATIONS, idUser)
                .then((result) => {
                    setNotifications([...notifications, ...result]);
                    if (result.length < PAGINATION_TABLE_NOTIFICATIONS) {
                        setNoMoreRows(true);
                    }
                    setLoading(false);
                    onGetNotifications && onGetNotifications();
                })
                .catch((err) => {
                    console.error('error fetching notifications:', err);
                });
        }, [notifications, idUser, onGetNotifications]);

        const groupNotificationsByDays = useMemo(() => {
            let days = [];
            let notificationsByDays = {};
            notifications.forEach((notification) => {
                const dateMoment = moment(notification.ActionDate, 'DD/MM/YYYY');
                let key = getElapsedTimeSimple(dateMoment);
                if (!key) return;
                if (!notificationsByDays[key]) {
                    days.push(key);
                    notificationsByDays[key] = [];
                }
                notificationsByDays[key].push(notification);
            });
            return { days, notificationsByDays };
        }, [notifications]);

        const renderRow = useCallback(
            (notifications) => {
                return notifications.map((row, index) => (
                    <BellNotificationsRow
                        key={index}
                        data={row}
                        onSelectNotification={onSelectNotification}
                    />
                ));
            },
            [onSelectNotification],
        );

        const renderNotifications = useCallback(() => {
            if (notifications.length === 0)
                return (
                    <div className="fm-bell-notifications__popover__rows">
                        <div className="fm-bell-notifications__popover__empty">
                            <Text type="caption" color={theme.colors.utility.textDisabled}>
                                {getLiteral('helptext_empty_notifications')}
                            </Text>
                        </div>
                    </div>
                );
            return (
                <div className="fm-bell-notifications__popover__rows">
                    {renderNotificationsRows()}
                    <div className="fm-bell-notifications__popover__see-more">
                        <div
                            className="fm-bell-notifications__popover__see-more__button"
                            onClick={getNotificationsWeb}
                        >
                            <Text type="caption" color={theme.colors.utility.textDisabled}>
                                {loading
                                    ? getLiteral('wait_waiting')
                                    : getLiteral('label_see_more')}
                            </Text>
                        </div>
                    </div>
                </div>
            );
        }, [getNotificationsWeb, loading, notifications, renderNotificationsRows, theme]);

        const renderNotificationsRows = useCallback(() => {
            const { days, notificationsByDays } = groupNotificationsByDays;
            return days.map((day, index) => (
                <div key={index}>
                    <Text
                        className="fm-bell-notifications__popover__rows__days"
                        type="subtitle"
                        color={theme.colors.utility.textSecondary}
                    >
                        {getLiteral(day)}
                    </Text>
                    {renderRow(notificationsByDays[day])}
                </div>
            ));
        }, [groupNotificationsByDays, renderRow, theme]);

        return (
            <div className="fm-bell-notifications__popover__content" ref={popoverRef}>
                <div className="fm-bell-notifications__popover__title">
                    <Text color={theme.colors.utility.textPrimary} bold={true}>
                        {getLiteral('label_notifications')}
                    </Text>
                </div>
                {renderNotifications()}
            </div>
        );
    },
);

export default BellNotificationsPopover;
