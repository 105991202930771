import React from 'react';
import colors from 'constants/colors';

const Documents = ({ color = colors.actionMinor[500] }) => (
    <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <g id="IconDocuments" fill="none" fillRule="evenodd">
            <rect
                id="Rectangle-43"
                stroke={color || '#335B70'}
                fill={color || '#335B70'}
                opacity="0"
                x="0.5"
                y="0.5"
                width="17"
                height="17"
            />
            <g id="Group" transform="translate(1)" fill={color || '#335B70'}>
                <path
                    d="M11.7,0 L11.7,4.05 C11.7,4.29795 11.8838199,4.5 12.1093985,4.5 L16.2,4.5 L16.2,11.7 C16.2,12.6941125 15.3941125,13.5 14.4,13.5 L10.8,13.5 L10.8,7.65 L6.3,3.15 L6.3,1.8 C6.3,0.80588745 7.10588745,0 8.1,0 L11.7,0 Z M12.6,3.15 L12.6,0 C12.7084906,0 12.8124778,0.0477 12.8894447,0.13185 L16.0800462,3.28185 C16.1566038,3.36645 16.2,3.48075 16.2,3.6 L13.0093985,3.6 C12.7838199,3.6 12.6,3.39795 12.6,3.15 Z"
                    id="Combined-Shape"
                />
                <path
                    d="M5.4,4.5 L5.4,8.55 C5.4,8.79795 5.58381992,9 5.8093985,9 L9.9,9 L9.9,16.2 C9.9,17.1941125 9.09411255,18 8.1,18 L1.8,18 C0.80588745,18 0,17.1941125 0,16.2 L0,6.3 C0,5.30588745 0.80588745,4.5 1.8,4.5 L5.4,4.5 Z M6.3,7.65 L6.3,4.5 C6.4084906,4.5 6.51247782,4.5477 6.58944474,4.63185 L9.78004624,7.78185 C9.85660376,7.86645 9.9,7.98075 9.9,8.1 L6.7093985,8.1 C6.48381992,8.1 6.3,7.89795 6.3,7.65 Z"
                    id="Combined-Shape"
                />
            </g>
        </g>
    </svg>
);

export default Documents;
