import { useCallback } from 'react';
import { OPPORTUNITIES } from 'constants/Entities';
import Context from 'managers/Context';
import { getActiveCrud } from 'utils/crud';
import { getLiteral } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';

const useOpportunitiesCrud = () => {
    const getCanDelete = useCallback(() => {
        const state = Context.store.getState();
        const active = getActiveCrud(state);
        const opportunityId = active?.id;
        const crudPermissions =
            state.config.permission.crud_permission[OPPORTUNITIES.permission] || null;
        return opportunityId && crudPermissions && crudPermissions.delete;
    }, []);

    const onSave = useCallback((data) => {
        let toastLiteral = 'succes_entitycreatedsuccessfully';
        if (data && data.hasOwnProperty('Updated') && data.Updated.num === '1')
            toastLiteral = 'succes_entityupdatedsuccessfully';

        successToast({
            text: getLiteral(toastLiteral),
        });
    }, []);

    const onErrorSave = useCallback((data) => {
        let toastLiteral = 'label_failed_insert_opportunity_salesforce';
        if (data.id) toastLiteral = 'label_failed_update_opportunity_salesforce';
        errorToast({
            text: getLiteral(toastLiteral),
        });
    }, []);

    const onDelete = useCallback(() => {
        successToast({
            text: getLiteral('succes_entitydeletedsuccessfully'),
        });
    }, []);

    const onErrorDelete = useCallback(() => {
        errorToast({
            text: getLiteral('label_failed_delete_opportunity_salesforce'),
        });
    }, []);

    return {
        entity: OPPORTUNITIES,
        width: '600px',
        className: 'opportunities-crud',
        literalNewEntity: 'page_title_opportunities_create',
        literalEditEntity: 'label_edit_opportunity',
        getNewEntityLiteral: null,
        getEditEntityLiteral: null,
        literalsBulk: {
            labelTitleBulkCrud: 'title_num_opportunities',
            labelTitleBulkCrudSingular: 'title_num_opportunities_singular',
        },
        onSave,
        onErrorSave,
        onDelete,
        onErrorDelete,
        hasCrudInDetail: true,
        getCanDelete,
        deleteProps: {
            buttonText: getLiteral('action_delete_opportunity'),
        },
    };
};

export default useOpportunitiesCrud;
