import React from 'react';
import colors from 'constants/colors';

const Sign = ({ color = colors.actionMinor[500] }) => (
    <svg
        viewBox={`0 0 18 18`}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <path
                d="M0 14.25V18h3.75L14.807 6.942l-3.75-3.75L0 14.251zM17.708 4.043l-1.83 1.83-3.75-3.75 1.83-1.83a.996.996 0 0 1 1.41 0l2.34 2.34c.39.39.39 1.02 0 1.41zm.086 13.03c.23.153.538 1.152-.768.999h-.538c-.46-.077-.691-.308-.691-.769h-.154c-.23 0-.461.077-.691.077-.308.077-.692.154-.922.23-.154 0-.461.077-.615.154-.23.077-.46.077-.615.077-.307 0-.46-.23-.46-.46 0-.155.076-.462.153-.692l.077-.077c-.538.23-.999.538-1.537.691-.307.23-.691.308-.922.384-.153.077-.307.077-.384.077-.384 0-.537-.46-.384-.998.154-.231.308-.538.384-.692.077 0 .077-.077.154-.154-.384.385-.845.692-1.306 1.076l-.23.23-.385.385c-.076 0-.076.076-.153.153l-.077.077c-.154.154-.384.23-.538.154h-.077c-.153-.077-.153-.461 0-.692l.077-.077c.23-.307.461-.537.692-.768 1.229-1.152 2.15-2.074 3.073-2.689.307-.23.691-.46.921-.614h.154c.154-.077.384-.154.538-.154.307 0 .46.23.46.461 0 .154-.076.46-.153.691a2.335 2.335 0 0 0-.384.538c-.154.23-.384.538-.538.692-.23.153-.307.384-.46.537.23-.153.537-.307.768-.384.384-.153.691-.384 1.075-.538.23-.076.461-.076.615-.153h.077c.23-.077.307 0 .384 0 .153.077.23.307.23.46.077.154 0 .462-.077.615-.153.23-.23.385-.384.615v.077c.154 0 .308-.077.384-.077.154-.077.385-.077.615-.23.538-.154.922-.23 1.306-.23s.538.23.615.46c.077.077.077.307.077.461v.077h.614z"
                id="aSign"
            />
        </defs>
        <use fill={color || '#335B70'} fillRule="nonzero" xlinkHref="#aSign" />
    </svg>
);

export default Sign;
