import React from 'react';
import colors from 'constants/colors';

const PlaceholderEnvironmentChip = ({
    color = colors.actionMinor[500],
    color2 = colors.primary.white,
}) => (
    <svg viewBox={`0 0 20 20`} xmlns="http://www.w3.org/2000/svg">
        <defs>
            <path
                d="M2.4 10.2a2.4 2.4 0 1 1 0-4.8 2.4 2.4 0 0 1 0 4.8zM8.1 12a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm.3-4.8a3.6 3.6 0 1 1 0-7.2 3.6 3.6 0 0 1 0 7.2z"
                id="environmentchip-a"
            />
        </defs>
        <g fill="none" fillRule="evenodd">
            <path fill={color || '#335B70'} d="M0 0h20v20H0z" />
            <g transform="translate(4 4)">
                <mask id="environmentchip-b" fill="#fff">
                    <use xlinkHref="#environmentchip-a" />
                </mask>
                <use fill={color || '#335B70'} fillRule="nonzero" xlinkHref="#environmentchip-a" />
                <g mask="url(#environmentchip-b)" fill={color2 || '#fff'}>
                    <path d="M0 0h12v12H0z" />
                </g>
            </g>
        </g>
    </svg>
);

export default PlaceholderEnvironmentChip;
