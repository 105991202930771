import React, { memo, useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Text, Link, Button, useTheme } from 'hoi-poi-ui';
import classnames from 'classnames';

import { REG_EX_EMAIL } from 'constants/Constants';

import Context from 'managers/Context';

import { logEvent } from 'utils/tracking';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';
import { getNumberAsCurrency } from 'utils/currency';

import { getRecurlyURL } from './pricing';
import PricingSwitch from './PricingSwitch';
import { PURCHASE_PLAN, CURRENCY_SYMBOLS } from '../../PurchaseModal/constants';

const PricingPlansBox = memo(
    ({
        id,
        prefix,
        title,
        subtitle,
        description,
        prices,
        codes,
        canUpgrade,
        withContact,
        withSwitch,
        currentPlan,
        userData,
        currency,
        events,
        isModal,
        withDiscount,
        discountCode,
        discountLiteral,
        handleModalPurchase,
        infoLicense,
        forceUpgradePlan,
    }) => {
        const [disabled, setDisabled] = useState(() =>
            sessionStorage.getItem(`pricing-contact-sales_${id}`),
        );
        const [loading, setLoading] = useState(false);
        const [periodicity, setPeriodicity] = useState(
            currentPlan === codes.monthly ? 'monthly' : 'annual',
        );

        const theme = useTheme();

        const price = prices.onlyAnnual ? prices[currency].annual : prices[currency][periodicity];
        const priceWithDiscount = prices.onlyAnnual
            ? prices[currency].annual_discount
            : prices[currency][`${periodicity}_discount`];

        const isCurrent = currentPlan.startsWith(prefix);
        const withButton = !isCurrent;

        const disableContactSales = useCallback(() => {
            setDisabled(true);
            sessionStorage.setItem(`pricing-contact-sales_${id}`, true);
        }, [id]);

        const seeFeaturesEvent = useCallback(() => {
            logEvent({
                event: 'licenses',
                submodule: isModal ? 'modal' : 'settings',
                functionality: events.features,
            });
        }, [events.features, isModal]);

        const hasSinglePlan = useMemo(() => {
            return (
                (!infoLicense?.annual || !infoLicense?.monthly) &&
                ['essential', 'starter'].includes(id)
            );
        }, [id, infoLicense]);

        const onUpgrade = useCallback(() => {
            logEvent({
                event: 'licenses',
                submodule: isModal ? 'modal' : 'settings',
                functionality: events.buy,
            });
            if (handleModalPurchase) {
                handleModalPurchase({
                    type: PURCHASE_PLAN,
                    payload: {
                        infoLicense,
                        periodicity,
                        prices,
                        hasSinglePlan,
                    },
                });
            } else {
                const finalDiscount = discountCode ? discountCode[periodicity] : '';
                setLoading(true);
                Context.settingsManager
                    .startUpgrade(userData.idImplementacion, codes[periodicity])
                    .then((data) => {
                        if (data.State === '1') {
                            const isValidEmail = !!userData.email.trim().match(REG_EX_EMAIL);
                            let tmpLink = getRecurlyURL();

                            tmpLink += `/${codes[periodicity]}/${userData.idImplementacion}`;

                            if (isValidEmail)
                                tmpLink += `?quantity=${userData.usedLicenses}&email=${userData.email}&first_name=${userData.nic}&currency=${currency}&subscription[coupon_code]=${finalDiscount}`;
                            else
                                tmpLink += `?quantity=${userData.usedLicenses}&first_name=${userData.nic}&currency=${currency}&subscription[coupon_code]=${finalDiscount}`;

                            window.location.href = tmpLink;
                        } else {
                            errorToast({
                                text: getLiteral('error_generalerror'),
                            });
                        }
                    })
                    .catch((e) => {
                        setLoading(false);
                        console.error(e);
                        errorToast({
                            text: getLiteral('error_loading'),
                        });
                    });
            }
        }, [
            isModal,
            events.buy,
            handleModalPurchase,
            infoLicense,
            periodicity,
            prices,
            discountCode,
            userData.idImplementacion,
            userData.email,
            userData.usedLicenses,
            userData.nic,
            codes,
            currency,
            hasSinglePlan,
        ]);

        useEffect(() => {
            forceUpgradePlan &&
                forceUpgradePlan?.id === infoLicense?.id &&
                onUpgrade(forceUpgradePlan);
        }, [forceUpgradePlan, infoLicense?.id, onUpgrade]);

        const onContact = useCallback(() => {
            logEvent({
                event: 'licenses',
                submodule: isModal ? 'modal' : 'settings',
                functionality: events.contact,
            });
            setLoading(true);
            Context.settingsManager
                .sendEmailType({ type: 'upgrade_request', plan: id })
                .then((data) => {
                    data = JSON.parse(data);
                    if (data.State === '1') {
                        successToast({
                            text: getLiteral('label_notification_license_contact_me_desc'),
                            title: getLiteral('label_notification_license_contact_me'),
                        });
                        disableContactSales();
                    } else {
                        errorToast({
                            text: getLiteral('error_generalerror'),
                        });
                    }
                })
                .catch((e) => {
                    console.error(e);
                    errorToast({
                        text: getLiteral('error_loading'),
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        }, [disableContactSales, events.contact, id, isModal]);

        const rootClass = classnames('pricing-plans__box', {
            'pricing-plans__box--current': isCurrent,
            'not-offer': !withDiscount,
        });

        const formatedPrice = useCallback(
            (price) => {
                return typeof price === 'number'
                    ? getNumberAsCurrency(price, CURRENCY_SYMBOLS[currency]).replace(' ', '')
                    : price;
            },
            [currency],
        );

        const getDiscountBand = useMemo(() => {
            let discountBand;

            if (!infoLicense?.[periodicity]) return null;

            const { discount } = infoLicense[periodicity];
            const gotDiscountFromBE = typeof discount === 'number' && discount > 0;

            switch (true) {
                case !isCurrent && withDiscount && !!discountLiteral && gotDiscountFromBE:
                    const periodStr =
                        periodicity === 'annual'
                            ? getLiteral('label_one_year')
                            : getLiteralWithParameters('label_months', [infoLicense.months || 3]);
                    discountBand = `${getLiteralWithParameters('label_discount_with_price', [
                        `${discount}%`,
                    ])} ${periodStr}`;
                    break;
                case !isCurrent && withDiscount && !!discountLiteral:
                    discountBand = getLiteral(discountLiteral);
                    break;
                default:
                    return null;
            }

            return (
                <span className="pricing-plans-box__label">
                    <Text type="caption" color={theme.colors.primary.white}>
                        {discountBand.toUpperCase()}
                    </Text>
                </span>
            );
        }, [discountLiteral, infoLicense, isCurrent, periodicity, withDiscount, theme]);

        return (
            <div className="pricing-plan-box-container">
                {getDiscountBand}
                <div className={rootClass}>
                    {isCurrent && (
                        <Text
                            className="pricing-plans__box__current-label"
                            color={theme.colors.primary.white}
                        >
                            {getLiteral('label_license_current_plan').toUpperCase()}
                        </Text>
                    )}
                    <div className="pricing-plans__box__content">
                        <div className="pricing-plans__box__header fm-b-4">
                            <Text className="fm-r-2" type="h6">
                                {title}
                            </Text>
                            <Text type="caption" color={theme.colors.grey[700]}>
                                {subtitle}
                            </Text>
                        </div>
                        {withDiscount && (
                            <div className="pricing-plans__box__old-pricing fm-b-4">
                                <Text type="caption" color={theme.colors.textLight.jadeAccessible}>
                                    {formatedPrice(price)}
                                </Text>
                            </div>
                        )}
                        <div className="pricing-plans__box__pricing fm-b-7">
                            <Text className="fm-r-2" type="h5" bold>
                                {withDiscount
                                    ? formatedPrice(priceWithDiscount)
                                    : formatedPrice(price)}
                            </Text>
                            <Text type="caption" color={theme.colors.grey[700]}>
                                {getLiteral('label_license_explanation_price')}
                            </Text>
                        </div>
                        <Text>{description}</Text>
                        <Link
                            className="pricing-plans__box__pricing-url fm-t-4"
                            onClick={seeFeaturesEvent}
                            href={getLiteral('label_license_see_features_url')}
                            target="_blank"
                        >
                            {getLiteral('label_license_see_features')}
                        </Link>
                    </div>
                    <div className="pricing-plans__box__footer">
                        {withSwitch && !hasSinglePlan && (
                            <div className="pricing-plans__box__footer__switch fm-t-7">
                                <PricingSwitch onChange={setPeriodicity} value={periodicity} />
                            </div>
                        )}
                        {hasSinglePlan && (
                            <Text
                                type="caption"
                                color={theme.colors.utility.textDisabled}
                                className="pricing-plans__box__onlyYearly fm-t-7"
                            >
                                {getLiteral('label_essential_only_annual_plan')}
                            </Text>
                        )}
                        {withButton && canUpgrade && (
                            <Button
                                className="fm-t-4"
                                type="primary"
                                size="big"
                                isFullWidth
                                onClick={onUpgrade}
                                isLoading={loading}
                            >
                                {getLiteral('action_license_upgrade_plan')}
                            </Button>
                        )}
                        {withButton && withContact && (
                            <Button
                                className="fm-t-4"
                                type="secondary"
                                size="big"
                                isFullWidth
                                onClick={onContact}
                                isLoading={loading}
                                isDisabled={disabled}
                            >
                                {getLiteral('label_contact_sales')}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        );
    },
);

PricingPlansBox.propTypes = {
    id: PropTypes.any,
    isActive: PropTypes.bool,
    withSwitch: PropTypes.bool,
    canUpgrade: PropTypes.bool,
    withContact: PropTypes.bool,
    title: PropTypes.string,
    sutitle: PropTypes.string,
    description: PropTypes.any,
    prices: PropTypes.shape({
        monthly: PropTypes.any,
        annual: PropTypes.any,
    }),
};

export default PricingPlansBox;
