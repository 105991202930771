import { memo, useState, useCallback, useEffect, useMemo, useContext } from 'react';
import { SectionForm } from 'hoi-poi-ui';
import SelectHoi from 'components/SelectHoi';
import { getLiteral } from 'utils/getLiteral';
import { AutomationBuilderCanvasContext } from '../../AutomationBuilderCanvasContext';
import AutomationBuilderSidePanel from '../AutomationBuilderSidePanel';

import UpdateEntityRelatedAction from './actions//UpdateEntityRelatedAction';

const ACTION_MAP = {
    update_entity_related: UpdateEntityRelatedAction,
};

const AutomationBuilderActionSidePanel = memo(
    ({ node, data, nodePath, onChange, onClose, isTouched, setTouched }) => {
        const { getActions } = useContext(AutomationBuilderCanvasContext);
        const [actions, setActions] = useState();
        const [form, setForm] = useState(() => {
            let newNode = JSON.parse(JSON.stringify(node));
            if (newNode.name) newNode = ACTION_MAP[newNode.name].getInitialData(newNode);
            return newNode;
        });

        useEffect(() => {
            // Getting actions and select lists when updating
            if (actions?.length > 0) return;

            getActions().then((result) => {
                setActions(result);
            });
        }, [actions?.length, getActions]);

        const changeEvent = useCallback(
            (value) => {
                setTouched(true);
                setForm(() => {
                    const newObj = {
                        name: value,
                        parameters: {},
                    };
                    return newObj;
                });
            },
            [setTouched],
        );

        const onChangeAction = useCallback(
            (cb) => {
                setTouched(true);
                setForm(cb);
            },
            [setTouched],
        );

        const onDone = useCallback(() => {
            onChange(nodePath, {
                name: form.name,
                parameters: ACTION_MAP[form?.name].getSaveData(form),
                type: 'action',
            });
            setTouched(false);
        }, [setTouched, onChange, nodePath, form]);

        const isDoneDisabled = useMemo(() => {
            return !isTouched || !form?.fields?.length;
        }, [form, isTouched]);

        const ActionComponent = useMemo(() => ACTION_MAP[form?.name] || null, [form.name]);

        return (
            <AutomationBuilderSidePanel
                title={getLiteral('label_action')}
                subtitle={getLiteral('label_action_desc')}
                onClose={onClose}
                isDoneDisabled={isDoneDisabled}
                onDone={onDone}
            >
                <SectionForm title={getLiteral('label_action_event')} isExpandable={false}>
                    <SelectHoi
                        placeholder={getLiteral('label_placeholder_event')}
                        options={actions}
                        onChange={changeEvent}
                        value={form.name}
                        useMenuPortal={false}
                        isFullWidth
                        usePlainValue
                    />
                </SectionForm>
                {ActionComponent && (
                    <ActionComponent onChange={onChangeAction} data={data} form={form} />
                )}
            </AutomationBuilderSidePanel>
        );
    },
);

export default AutomationBuilderActionSidePanel;
