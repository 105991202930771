import React, { memo } from 'react';

const Detail = (props) => (
    <svg width="100%" height="100%" fill="none" viewBox="0 0 168 144" {...props}>
        <g mask="url(#a)">
            <path
                fill="#303030"
                d="m68.97 90.964-38.015 5.333a1.024 1.024 0 0 1-1.167-1.013V12.6c0-.62.55-1.098 1.167-1.013L68.97 16.92c.505.07.882.503.882 1.013v72.019c0 .51-.376.941-.882 1.013v-.001Z"
            />
            <path
                fill="#000"
                d="m48.725 25.907-14.428-1.265V22.46l14.428 1.265v2.182ZM48.725 69.389l-14.428 1.826v-2.18l14.428-1.827v2.18Z"
            />
            <path
                fill="#474747"
                d="M79.41 78.66 49.884 82.6a1.024 1.024 0 0 1-1.16-1.014V17.337c0-.618.547-1.096 1.16-1.014l29.524 3.941c.51.068.888.502.888 1.015v56.368c0 .512-.38.945-.888 1.014v-.001Z"
            />
            <path fill="#000" d="m63.65 27.37-11.372-.984v-1.72l11.372.984v1.72Z" />
            <path
                fill="#5E5E5E"
                d="m89.807 66.338-20.957 2.56c-.611.075-1.149-.4-1.149-1.016V22.196c0-.614.538-1.09 1.149-1.016l20.957 2.56c.514.064.9.5.9 1.016v40.568c0 .517-.385.952-.9 1.016v-.002Z"
            />
            <path
                fill="#000"
                d="m78.576 28.94-8.287-.702v-1.252l8.287.703v1.251ZM78.576 53.91l-8.287.703v-1.254l8.287-.702v1.253Z"
            />
            <path
                fill="#ABABAB"
                d="M100.265 54.031 87.724 55.24a1.024 1.024 0 0 1-1.123-1.02V26.789c0-.604.52-1.076 1.123-1.019l12.541 1.209c.525.05.926.491.926 1.018v25.018c0 .527-.401.968-.926 1.018Z"
            />
            <path
                fill="#000"
                d="m93.498 30.295-5.255-.422v-.795l5.255.422v.795ZM93.498 46.13l-5.255.422v-.796l5.255-.42v.794ZM63.65 61.637 52.278 62.9v-1.72l11.372-1.264v1.72Z"
            />
            <path
                fill="#919191"
                d="M122.502 53.826a4.063 4.063 0 0 1-2.843-1.155l-8.825-8.59a4.062 4.062 0 0 1-.073-5.75 4.076 4.076 0 0 1 5.759-.074l3.216 3.13.09-.14.636-.942a6.1 6.1 0 0 1 4.477-2.7c3.611-.386 6.648 2.225 7.493 3.034a4.066 4.066 0 0 1 1.253 3.032 4.068 4.068 0 0 1-1.396 2.97l-7.112 6.184c-.768.67-1.722 1-2.675 1Z"
            />
            <path
                fill="#5E5E5E"
                d="M132.93 129.793a5.273 5.273 0 0 1-5.267-5.019l-2.767-58.298c-2.636 8.928-5.808 21.152-5.808 25.674 0 7.307 2.39 29.817 2.414 30.043a5.273 5.273 0 0 1-4.689 5.798 5.275 5.275 0 0 1-5.806-4.682c-.101-.947-2.472-23.295-2.472-31.158 0-8.578 6.709-30.288 7.56-33.01a9.797 9.797 0 0 1 3.724-5.211 9.803 9.803 0 0 1 6.24-1.779c4.883.297 8.817 4.194 9.151 9.067l.007.111 2.989 62.946a5.272 5.272 0 0 1-5.276 5.518Z"
            />
            <path
                fill="#919191"
                d="M124.269 57.932a4.28 4.28 0 0 0 4.283-4.277 4.28 4.28 0 0 0-4.283-4.277 4.28 4.28 0 0 0-4.284 4.277 4.28 4.28 0 0 0 4.284 4.277Z"
            />
            <path
                fill="#919191"
                d="M118.219 44.009a5.697 5.697 0 0 0 5.915 5.47 5.695 5.695 0 0 0 5.478-5.907c-.121-3.142-2.669-2.347-5.815-2.226-3.146.12-5.698-.48-5.578 2.661v.002Z"
            />
            <path
                fill="#919191"
                d="m120.064 54.47 8.468-.402 1.074-9.926-11.337.429 1.795 9.899Z"
            />
            <path
                fill="#919191"
                d="M126.122 54.016a4.069 4.069 0 0 1-3.282-1.656l-3.22-4.364a7.139 7.139 0 0 1-.627-7.5 6.86 6.86 0 0 1 6.149-3.781 4.07 4.07 0 0 1 4.072 4.066 4.069 4.069 0 0 1-2.154 3.589l2.337 3.168a4.063 4.063 0 0 1-.864 5.686 4.056 4.056 0 0 1-2.412.793l.001-.001Z"
            />
            <path
                fill="#B26E3D"
                d="M117.541 54.77c-.233 0-.469-.042-.698-.13l-15.22-5.832a1.943 1.943 0 0 1-1.12-2.512 1.946 1.946 0 0 1 2.516-1.118l15.22 5.832a1.944 1.944 0 0 1-.7 3.761l.002-.001Z"
            />
            <path
                fill="#5E5E5E"
                d="M129.592 132.706a.906.906 0 0 1-.485-1.674l5.638-3.556a.909.909 0 0 1 1.252.283.906.906 0 0 1-.282 1.25l-5.639 3.556a.897.897 0 0 1-.484.141ZM110.338 129.322a.908.908 0 0 1-.888-.72.908.908 0 0 1 .704-1.074l6.942-1.451a.908.908 0 1 1 .372 1.776l-6.943 1.451a.922.922 0 0 1-.187.02v-.002Z"
            />
            <path
                fill="#919191"
                d="M125.976 59.153c-.68 0-1.364-.106-2.027-.32l-13.135-4.264a4.066 4.066 0 0 1-2.615-5.124 4.074 4.074 0 0 1 5.132-2.61l11.377 3.692.957-5.7a.737.737 0 0 0-.134.004 4.071 4.071 0 0 1-4.442-3.66 4.07 4.07 0 0 1 3.665-4.436c2.677-.255 5.228.68 7.002 2.568 1.655 1.76 2.391 4.092 2.021 6.398l-.004.027-1.337 7.966a6.55 6.55 0 0 1-6.46 5.46v-.001Z"
            />
            <path
                fill="#00D639"
                d="m108.133 46.677-9.111 1.18a.866.866 0 0 1-.978-.857V27.814c0-.521.459-.925.978-.857l9.111 1.18a.864.864 0 0 1 .754.857V45.82a.864.864 0 0 1-.754.857Z"
            />
            <path fill="#fff" d="m107.886 44.836-8.842.843V28.121l8.842 1.125v15.59Z" />
            <path fill="#000" d="M103.465 46.8a.48.48 0 1 0 0-.962.48.48 0 0 0 0 .961Z" />
            <path
                fill="#B26E3D"
                d="M102.365 45.867a.697.697 0 0 1 .982.056l1.634 1.83a.222.222 0 0 1-.018.315l-.705.629a.224.224 0 0 1-.316-.018l-1.635-1.83a.693.693 0 0 1 .056-.98l.002-.002Z"
            />
            <path
                fill="#000"
                d="m103.208 31.197-3.195-.421v-.484l3.195.422v.483ZM103.208 40.828l-3.195.28v-.481l3.195-.281v.482Z"
            />
            <path
                fill="#B26E3D"
                d="M112.073 41.207c-.516 0-1.03-.204-1.414-.607l-2.676-2.819v-.63a1.946 1.946 0 0 1-1.488-2.276 1.946 1.946 0 0 1 2.288-1.53c1.705.336 2.664 1.542 2.978 2.764l1.724 1.816c.74.78.707 2.01-.074 2.75a1.941 1.941 0 0 1-1.339.533l.001-.001ZM123.372 34.689a1.928 1.928 0 0 0 1.929-1.927 1.928 1.928 0 0 0-1.929-1.926c-1.066 0-1.93.862-1.93 1.926s.864 1.927 1.93 1.927Z"
            />
        </g>
    </svg>
);

export default memo(Detail);
