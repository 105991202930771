import React from 'react';
import colors from 'constants/colors';

const SalesorderWon = ({ color = colors.actionMinor[500] }) => (
    <svg viewBox={`0 0 32 32`} xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
            <path
                d="M10.5 10.4l2.1 6.6 12.9-1.6v-5h-15zM7.4 23.6c-.4-.2-.7-.6-.7-1.1 0-1.8 1.4-4 3.3-4.9l-3.4-11H5.3C4.6 6.5 4 6 4 5.3 4 4.6 4.6 4 5.3 4h2.3c.6 0 1 .4 1.2.9l.9 3h17c.7 0 1.3.6 1.3 1.3v7.4c0 .6-.5 1.2-1.1 1.3l-15.1 1.8h-.1c-.8.1-1.6.8-2 1.5h11.808c.795.675-.508 2.6-1.468 2.6H10.5c.3.4.6.9.6 1.4 0 1.2-1 2.2-2.2 2.2-1.2 0-2.2-1-2.2-2.2 0-.6.3-1.2.7-1.6zm1.2 1.6c0 .2.2.4.4.4s.4-.2.4-.4-.2-.4-.4-.4c-.3.1-.4.2-.4.4z"
                fill={color || '#e1e4e7'}
            />
            <path
                d="M30.23 29.217c0 .541-.445.98-.993.98h-.661c.548 0 .992.44.992.98 0 .543-.444.981-.992.981 0 0-2.647.015-4.3 0-1.233-.01-2.57-1.274-4.922-1.307 0-.327-2.354-5.883-2.354-5.883h1.985c2.315 0 4.96-2.106 4.96-5.23 0-1.033 1.985-1.163 1.985.797 0 1.307-.662 3.78-.662 3.78h5.292c.548 0 .992.438.992.98 0 .541-.444.98-.992.98H29.9c.547 0 .992.439.992.98 0 .542-.445.981-.992.981h-.662c.548 0 .992.439.992.98z"
                fill="#86AD20"
            />
        </g>
    </svg>
);

export default SalesorderWon;
