import React, { memo, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { Icon, useTheme } from 'hoi-poi-ui';

import './styles.scss';

const Follow = memo(({ followingItem, onClick, loading }) => {
    const theme = useTheme();

    const handleClick = useCallback(
        (event) => {
            event.stopPropagation();
            onClick && onClick();
        },
        [onClick],
    );

    const iconProps = useMemo(
        () => ({
            name: followingItem ? 'flag' : 'flagOutline',
            color: followingItem ? theme.colors.actionMajor[500] : theme.colors.actionMinor[500],
            onClick: handleClick,
        }),
        [followingItem, theme, handleClick],
    );

    return (
        <div
            className={classNames('fm-activities-follow', {
                'fm-activities-follow--active': followingItem,
                'fm-activities-follow--disabled': loading,
            })}
        >
            <Icon {...iconProps} />
        </div>
    );
});

export default Follow;
