import React, { memo, useMemo, useState, useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ServerListActions, EntityModalActions } from 'actions';
import { Icon, Text, Loader, Button, useTheme } from 'hoi-poi-ui';
import DetailField from 'containers/components/EntityDetailPopover/DetailField';
import { ACTIVITIES, AGENDA } from 'constants/Entities';
import { VideoCallsService } from 'services';
import { getLiteral } from 'utils/getLiteral';
import copyToClipboard from 'utils/copy';
import { logEvent } from 'utils/tracking';
import { successToast } from 'utils/toast';

const mapDispatchToProps = (dispatch) => {
    return {
        getList: bindActionCreators(ServerListActions, dispatch).getList,
        cancelModal: bindActionCreators(EntityModalActions, dispatch).cancel,
        modalInit: bindActionCreators(EntityModalActions, dispatch).init,
    };
};

const VideoCallField = memo(
    ({ data, getList, cancelModal, modalInit, popoverCallbacks, withCopy = true }) => {
        const isFirstRenderRef = useRef(true);
        const [videoCallProvider, setVideoCallProvider] = useState(null);
        const [isLoadingProvider, setIsLoadingProvider] = useState(false);
        const [isLoadingJoin, setIsLoadingJoin] = useState(false);
        const [isCopied, setIsCopied] = useState(false);
        const theme = useTheme();

        useEffect(() => {
            if (isFirstRenderRef?.current) {
                if (popoverCallbacks) {
                    popoverCallbacks.onPopupVisibleChange = (isVisible) => {
                        if (!isVisible) setIsCopied(false);
                    };
                }

                if (data.videoCallProviderId) {
                    setIsLoadingProvider(true);
                    getList('tblVideoCallProviders')
                        .then((providers) => {
                            setIsLoadingProvider(false);
                            if (providers.length) {
                                const provider = providers.reduce((obj, current) => {
                                    if (current.value === data.videoCallProviderId) obj = current;
                                    return obj;
                                }, {});
                                setVideoCallProvider(provider);
                            }
                        })
                        .catch(() => setIsLoadingProvider(false));
                }

                isFirstRenderRef.current = false;
            }
        }, [data.videoCallProviderId, getList, popoverCallbacks]);

        const handleOnClickJoinMeeting = useCallback(() => {
            if (data.videoCallProviderId && data.videoCallMeetingDetails?.id) {
                setIsLoadingJoin(true);
                VideoCallsService.getMeetingRoom(
                    data.videoCallProviderId,
                    data.videoCallMeetingDetails.id,
                )
                    .then((meetingRoomData) => {
                        if (
                            meetingRoomData &&
                            meetingRoomData.startUrl &&
                            meetingRoomData.idGestion
                        ) {
                            logEvent({
                                event: AGENDA.trueName,
                                submodule: 'popoverDetail',
                                functionality: 'joinMeeting',
                            });
                            window.open(meetingRoomData.startUrl, '_blank');
                            cancelModal();

                            if (meetingRoomData.isOwner) {
                                modalInit({
                                    entity: ACTIVITIES,
                                    id: meetingRoomData.idGestion,
                                    labels: {
                                        title: getLiteral('action_updatemanagement'),
                                        success: getLiteral('succes_entityupdatedsuccessfully'),
                                        error: getLiteral('label_failed_update_contact_salesforce'),
                                        deleteModalTitle: getLiteral('title_delete_activities'),
                                        successDelete: getLiteral(
                                            'succes_entitydeletedsuccessfully',
                                        ),
                                    },
                                });
                            }
                        }
                    })
                    .finally(() => {
                        setIsLoadingJoin(false);
                    });
            }
        }, [data, cancelModal, modalInit]);

        const handleCopyToClipboard = useCallback(() => {
            if (!data?.videoCallMeetingDetails?.joinUrl) return;
            copyToClipboard(data.videoCallMeetingDetails.joinUrl);
            successToast({
                text: getLiteral('label_copied_to_clipboard'),
            });
            setIsCopied(true);
        }, [data]);

        const renderVideoCall = useMemo(() => {
            if (!data?.videoCallProviderId) return null;

            if (isLoadingProvider) {
                return <DetailField iconType="videoCamera" component={<Loader />} />;
            } else if (!isLoadingProvider && videoCallProvider) {
                const component = (
                    <div className="agenda-small-detail__videocall">
                        <Button
                            className="agenda-small-detail__videocall__button"
                            onClick={handleOnClickJoinMeeting}
                            isLoading={isLoadingJoin}
                            icon="videoCamera"
                        >
                            {getLiteral('action_join_meeting')}
                        </Button>
                        {withCopy && (
                            <div
                                className="agenda-small-detail__videocall__room__link"
                                onClick={handleCopyToClipboard}
                            >
                                <Icon name="contentLink" />
                                <Text
                                    className="agenda-small-detail__videocall__room__link-text"
                                    isTruncated={true}
                                >
                                    {isCopied
                                        ? getLiteral('label_copied_to_clipboard')
                                        : getLiteral('action_copy_link')}
                                </Text>
                            </div>
                        )}
                    </div>
                );
                return <DetailField component={component} />;
            } else return null;
        }, [
            data?.videoCallProviderId,
            isLoadingProvider,
            videoCallProvider,
            handleOnClickJoinMeeting,
            isLoadingJoin,
            withCopy,
            handleCopyToClipboard,
            isCopied,
        ]);

        return renderVideoCall;
    },
);

export default connect(null, mapDispatchToProps)(VideoCallField);
