import { memo, useState, useEffect, useCallback } from 'react';
import { Text, Chip } from 'hoi-poi-ui';
import { getEntityValue } from 'services/Fuzzy';
import { formatDate } from 'utils/dates';
import { getLiteral } from 'utils/getLiteral';
import { FuzzyMap } from 'utils/fuzzy';

const ActionBoxValue = memo(({ item }) => {
    const getValueType = useCallback(
        (value) => {
            switch (item?.field?.type) {
                case 'percentage':
                    return `${value}%`;
                case 'datetime':
                    return formatDate(value);
                case 'bit':
                    return value ? getLiteral('cfm_label_yes') : getLiteral('cfm_label_no');
                default:
                    return value;
            }
        },
        [item?.field?.type],
    );

    const [innerValue, setInnerValue] = useState(
        ['list', 'multivalue'].includes(item?.field?.type) ? (
            ''
        ) : (
            <Text type="subtitle" isTruncated useTooltip>
                {getValueType(item?.value?.value)}
            </Text>
        ),
    );

    useEffect(() => {
        const value = item?.value?.value;
        if (!value) {
            setInnerValue();
            return;
        }
        if (!['list', 'multivalue'].includes(item?.field?.type)) {
            setInnerValue(
                <Text type="subtitle" isTruncated useTooltip>
                    {getValueType(value)}
                </Text>,
            );
            return;
        }

        const valueArray = Array.isArray(item?.value?.value)
            ? item?.value?.value
            : [item?.value?.value];

        const list =
            FuzzyMap[item?.field?.table?.table?.toLowerCase()]?.list || item?.field?.table?.table;
        getEntityValue(list, valueArray)
            .then((result) => {
                if (!result?.data) return;
                const resultValue = valueArray?.map((v) => ({
                    label: result?.data?.[v]?.description,
                    value: result?.data?.[v]?.id,
                }));

                setInnerValue(
                    <div className="fm-automation-builder__action-box__chips">
                        {resultValue.map((v) => (
                            <Chip isFilled>{v.label}</Chip>
                        ))}
                    </div>,
                );
            })
            .catch((e) => {
                console.error(e);
                setInnerValue();
            });
    }, [getValueType, item?.field?.table?.table, item?.field?.type, item?.value?.value]);

    return innerValue;
});

export default ActionBoxValue;
