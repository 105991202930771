import React, { memo } from 'react';
import ContentLoader from 'react-content-loader';
import { useTheme } from 'hoi-poi-ui';

import './styles.scss';

const MessagesPlaceholder = memo(({ width = 700, ...props }) => {
    const theme = useTheme();
    const spaceBetweenRows = width * 0.7;
    const thirdRow = width * 0.7;

    return (
        <div className="fm-messages-list__placeholder">
            <ContentLoader
                height={1000}
                width={width}
                speed={2}
                backgroundColor={theme.colors.grey[50]}
                foregroundColor={theme.colors.grey[100]}
                {...props}
            >
                <circle cx="25" cy="30" rx="3" ry="3" r="18" opacity="0.2" />
                <rect x="55" y="20" rx="1" ry="1" width="200" height="20" />
                <rect x={spaceBetweenRows} y="20" rx="1" ry="1" width={500} height="20" />
                <rect x="55" y="50" rx="1" ry="1" width={width} height="20" />
                <rect x="55" y="80" rx="1" ry="1" width={thirdRow} height="20" />

                <circle cx="25" cy="150" rx="3" ry="3" r="18" opacity="0.2" />
                <rect x="55" y="140" rx="1" ry="1" width="200" height="20" />
                <rect x={spaceBetweenRows} y="140" rx="1" ry="1" width={500} height="20" />
                <rect x="55" y="170" rx="1" ry="1" width={width} height="20" />
                <rect x="55" y="200" rx="1" ry="1" width={thirdRow} height="20" />
            </ContentLoader>
        </div>
    );
});

export default MessagesPlaceholder;
