import { memo } from 'react';

const DeleteAccountPic = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={168} height={144} fill="none" {...props}>
            <mask
                id="a"
                width={99}
                height={128}
                x={35}
                y={8}
                maskUnits="userSpaceOnUse"
                style={{
                    maskType: 'luminance',
                }}
            >
                <path fill="#fff" d="M133.13 8.47H35.153v127.342h97.977V8.471Z" />
            </mask>
            <g mask="url(#a)">
                <path
                    fill="#919191"
                    d="M82.13 50.886c-2.915 0-5.415-2.202-5.715-5.154-.321-3.15 1.983-5.96 5.142-6.28 3.397-.343 11.779-1.19 16.202-3.006a147.121 147.121 0 0 1 3.06-7.203c3.565-7.751 6.552-12.238 11.273-12.238 3.176 0 5.752 2.566 5.752 5.73a5.732 5.732 0 0 1-3.306 5.189c-1.492 2.008-4.51 8.454-6.797 14.595a5.726 5.726 0 0 1-2.295 2.834c-4.965 3.165-12.678 4.489-22.73 5.503-.197.021-.393.03-.587.03h.002Z"
                />
                <path
                    fill="#474747"
                    d="M117.617 16.34a4.186 4.186 0 0 1-5.614-1.83 4.152 4.152 0 0 1 1.838-5.59 4.177 4.177 0 0 1 3.748-.011 4.15 4.15 0 0 1 .028 7.43Z"
                />
                <path
                    fill="#474747"
                    d="m119.5 10.85-7.447 3.763 1.346 2.645 7.447-3.762-1.346-2.646Z"
                />
                <path
                    fill="#474747"
                    d="M119.013 19.082a4.185 4.185 0 0 1-5.613-1.829 4.152 4.152 0 0 1 1.837-5.59 4.18 4.18 0 0 1 3.749-.012 4.154 4.154 0 0 1 .027 7.43Z"
                />
                <path
                    fill="#E84D4F"
                    d="M118.18 12.978a2.4 2.4 0 0 1-2.404 2.395 2.398 2.398 0 0 1-2.402-2.395c0-.785.381-1.483.97-1.92a2.406 2.406 0 0 1 3.627.943c.134.3.209.628.209.977Z"
                />
                <path
                    fill="#474747"
                    d="M118.716 12.556c-2.606-.177-4.858-1.1-4.858-1.1l.989-1.324 2.981.898 1.214 1.084-.324.44-.002.002Z"
                />
                <path
                    fill="#E84D4F"
                    d="M71.575 48.443a2.461 2.461 0 0 1-2.272-1.507 2.449 2.449 0 0 1 1.32-3.21c3.678-1.532 7.884-.716 8.35-.619a2.451 2.451 0 0 1 1.901 2.907 2.462 2.462 0 0 1-2.91 1.895c-.865-.176-3.527-.455-5.442.342-.31.13-.631.192-.949.192h.002Z"
                />
                <path
                    fill="#5E5E5E"
                    d="M99.92 128.834c-.324 0-.65-.022-.98-.067-3.946-.533-6.71-4.155-6.174-8.087l10.437-76.495c.536-3.93 4.171-6.685 8.119-6.151 3.945.533 6.71 4.155 6.174 8.088l-10.438 76.494c-.491 3.603-3.585 6.218-7.136 6.218h-.001Z"
                />
                <path
                    fill="#5E5E5E"
                    d="M125.908 131.072c-3.467 0-6.525-2.498-7.107-6.016l-12.948-78.224c-.647-3.916 2.012-7.613 5.943-8.258 3.93-.644 7.641 2.005 8.288 5.92l12.948 78.225c.648 3.915-2.012 7.612-5.943 8.257a7.294 7.294 0 0 1-1.181.098v-.002Z"
                />
                <path
                    fill="#5E5E5E"
                    d="M124.813 135.812a1.142 1.142 0 0 1-1.116-1.415l1.29-5.279c.149-.614.771-.99 1.387-.84.617.149.994.768.843 1.382l-1.289 5.279a1.147 1.147 0 0 1-1.115.874v-.001ZM90.207 130.031c-.476 0-.93-.268-1.146-.727a1.257 1.257 0 0 1 .612-1.673l8.22-3.823a1.265 1.265 0 0 1 1.679.609c.295.63.022 1.379-.612 1.673l-8.22 3.823a1.257 1.257 0 0 1-.533.118Z"
                />
                <path
                    fill="#919191"
                    d="M118.35 62.771a5.78 5.78 0 0 1-1.671-.248c-3.041-.918-4.758-4.117-3.836-7.147 1.156-3.796 1.836-12.21 1.585-19.571-.099-2.888-1.693-6.521-2.699-7.37-3.007-.186-5.388-2.675-5.388-5.718s2.575-5.73 5.752-5.73c2.605 0 7.5 1.027 11.02 7.907 1.621 3.169 2.699 7.2 2.812 10.522.253 7.372-.291 17.432-2.072 23.286-.752 2.475-3.034 4.07-5.503 4.07Z"
                />
                <path
                    fill="#919191"
                    d="M113.736 42.423c-.767.043-3.113.004-5.022-.674-6.784-2.407-3.275-12.951-3.439-15.833l12.168-.943h2.028l1.597 3.592c-.474 12.548-4.272 13.684-7.332 13.857v.001Z"
                />
                <path
                    fill="#E84D4F"
                    d="M114.543 66.846a2.458 2.458 0 0 1-1.901-.893 2.446 2.446 0 0 1 .335-3.452c1.425-1.17 1.738-2.64 1.741-2.654a2.461 2.461 0 0 1 2.821-2.033 2.455 2.455 0 0 1 2.042 2.81c-.053.332-.605 3.309-3.472 5.662a2.458 2.458 0 0 1-1.564.56h-.002Z"
                />
                <path
                    fill="#B26E3D"
                    d="M72.704 49.33h-.002l-8.15-.006a2.773 2.773 0 0 1-2.776-2.771 2.773 2.773 0 0 1 2.778-2.767h.002l8.15.007a2.774 2.774 0 0 1 2.776 2.772 2.773 2.773 0 0 1-2.778 2.766Z"
                />
                <path
                    fill="#B26E3D"
                    d="M68.091 45.037a.622.622 0 0 1-.237-1.2c2.308-.94 3.263-1.02 4.858-.408a.621.621 0 0 1 .358.804.625.625 0 0 1-.807.358c-1.128-.433-1.722-.503-3.937.4a.643.643 0 0 1-.237.046h.002Z"
                />
                <path
                    fill="#E84D4F"
                    d="M70.16 45.44a.625.625 0 0 1-.469-1.034c.067-.074 1.66-1.814 4.823-1.504a.626.626 0 0 1 .562.68.62.62 0 0 1-.683.56c-2.504-.244-3.721 1.042-3.773 1.097a.631.631 0 0 1-.463.202h.003Z"
                />
                <path
                    fill="#00D639"
                    d="M44.33 75.445c5.07 0 9.178-14.853 9.178-33.176 0-18.322-4.108-33.176-9.177-33.176s-9.178 14.854-9.178 33.176c0 18.323 4.11 33.176 9.178 33.176Z"
                />
                <path
                    fill="#C6C6C6"
                    d="M67.542 45.899a5.546 5.546 0 0 0-6.21-4.791c-.104.012-10.445 1.238-17.927-2.1-2.245-1.004-5.154-3.085-7.94-5.332-.204 2.741-.312 5.622-.312 8.593 0 1.663.035 3.298.1 4.896 1.238.765 2.455 1.432 3.61 1.948 6.088 2.717 13.066 3.295 17.9 3.295 3.4 0 5.74-.286 5.966-.315 3.044-.386 5.203-3.16 4.815-6.192l-.002-.002Z"
                />
            </g>
        </svg>
    );
};

export default memo(DeleteAccountPic);
