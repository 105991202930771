import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Icon } from 'hoi-poi-ui';
import { DocumentActions } from 'actions';
import { DOCUMENTS } from 'constants/Entities';
import { ExtensionIcon } from 'components/SvgIcons';
import TooltipHoi from 'components/TooltipHoi';
import { getLiteral } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';
import { DocumentModel } from 'models';
import UtilFormat from 'utils/UtilFormat';
import { logEvent } from 'utils/tracking';
import { completeUrl } from 'utils/links/checkUrls';
import { getCanPreview } from 'components/ViewerModal/viewerUtils';
import TruncatedTooltip from 'components/TruncatedTooltip';
import { withEmailEditor } from 'containers/components/EmailEditor/hooks/useEmailEditor';
import colors from 'constants/colors';

const propTypes = {
    document: PropTypes.object,
    entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    entityType: PropTypes.object,
    currentFolder: PropTypes.object,
    handleOnClickRow: PropTypes.func,
    fetchData: PropTypes.func,
};

const mapStateToProps = (state) => {
    const permission = state.config.permission;
    const documentManagement = permission.documentManagement;

    //use 'allow' word because will be a second validation in the render()
    const allowSign = documentManagement && permission.signatureDocumentos && permission.signature;
    const crudPermission = state.config.permission.crud_permission.DOCUMENT;
    const canDelete = documentManagement && crudPermission.delete;
    const allowUpdate = documentManagement && crudPermission.update;

    return {
        allowSign,
        allowUpdate,
        canDelete,
        documentManagement,
    };
};

const mapDispatchToProps = (dispatch) => {
    const {
        showLinkAction,
        showCreateFolder,
        showDeleteFolder,
        downloadDocument,
        downloadDocumentLink,
        openDialogSignDocument,
        hideDialogSignDocument,
    } = bindActionCreators(DocumentActions, dispatch);

    return {
        showLinkAction,
        downloadDocument,
        downloadDocumentLink,
        showCreateFolder,
        showDeleteFolder,
        openDialogSignDocument,
        hideDialogSignDocument,
    };
};

@connect(mapStateToProps, mapDispatchToProps)
class DocumentsRow extends PureComponent {
    state = {};

    handleSignDocument = (event, canSign) => {
        event.stopPropagation();
        if (!canSign) return;

        const { document, entityId, entityType, openDialogSignDocument, hideDialogSignDocument } =
            this.props;

        switch (document.signatureStatus) {
            case DocumentModel.SignatureStatus.DELIVERED:
            case DocumentModel.SignatureStatus.SENT:
            case DocumentModel.SignatureStatus.COMPLETED:
                break;
            case DocumentModel.SignatureStatus.NOTHING:
            default:
                openDialogSignDocument(
                    entityType.entity,
                    true,
                    hideDialogSignDocument,
                    document,
                    this.onFinishFormReportDialog,
                    entityId,
                );
                logEvent({
                    event: entityType.trueName,
                    submodule: 'documentsWidget',
                    functionality: 'signature',
                });
                break;
        }
    };

    onFinishFormReportDialog = (result, form, errorCode) => {
        const { hideDialogSignDocument, openSignatureErrorDialog, fetchData, currentFolder } =
            this.props;

        const description = form.signers.map((signer) => {
            return signer.email;
        });
        fetchData(currentFolder.id);

        hideDialogSignDocument();

        if (result) {
            successToast({
                title: getLiteral('confirmation_signature_process_success'),
                text: description.join(' and '),
            });
        } else {
            if (errorCode && errorCode === '-302') {
                openSignatureErrorDialog && openSignatureErrorDialog();
            } else {
                errorToast({
                    text: `${getLiteral('error_signature_process_failed')} ${description.join(
                        ' and ',
                    )}`,
                });
            }
        }
    };

    handleDownloadDocument = (event) => {
        const { document, downloadDocument, entityType } = this.props;

        event.stopPropagation();
        downloadDocument(document);
        logEvent({
            event: entityType.trueName,
            submodule: 'documentsWidget',
            functionality: 'open',
        });
    };

    handleShareDocument = (event, canDownload, sendMail) => {
        const { document, downloadDocumentLink, entityType } = this.props;

        event.stopPropagation();
        if (!canDownload) return;

        downloadDocumentLink(document, sendMail);

        logEvent({
            event: entityType.trueName,
            submodule: 'documentsWidget',
            functionality: 'share',
        });
    };

    handleUpdateDocument = (event, canUpdate) => {
        const { document, showCreateFolder } = this.props;

        event.stopPropagation();
        if (!canUpdate) return;

        showCreateFolder(true, document.node_id, DOCUMENTS, document);
    };

    handleDeleteDocument = (event, canDelete) => {
        const { document, showDeleteFolder } = this.props;

        event.stopPropagation();
        if (!canDelete) return;

        showDeleteFolder(true, DOCUMENTS, document);
    };

    handleOpenLinkInNewTab = (document) => {
        const { entityType } = this.props;
        if (document.is_link === '1' && document.link !== '') {
            logEvent({
                event: entityType.trueName,
                submodule: 'documentsWidget',
                functionality: 'open',
            });
            const link = completeUrl(document.link);
            if (link) window.open(link, '_blank');
        }
    };

    handleOnClickRow = (event, canDownload) => {
        const { document, handleOnClickRow, handleOpenPreview } = this.props;
        if (document.is_folder === '1') {
            handleOnClickRow(document);
        } else if (document.is_folder === '0' && document.is_link === '0') {
            if (!canDownload) return;
            if (getCanPreview(document?.format?.toLowerCase(), document.size)) {
                handleOpenPreview(document);
            } else this.handleDownloadDocument(event);
        } else {
            this.handleOpenLinkInNewTab(document);
        }
    };

    render() {
        const { canUseEmail, document, allowSign, allowUpdate, canDelete, documentManagement } =
            this.props;

        let description = (
            <TruncatedTooltip content={document.description}>
                {document.description}
            </TruncatedTooltip>
        );
        const extension = document.name.split('.')[1],
            isFolder = document.is_folder,
            isLink = document.is_link;
        const date = document.fmodificado !== '' ? document.fmodificado : document.fcreado;
        const dateFormatted = UtilFormat.getStringChangeFormat(date, 'DD/MM/YYYY hh:mm:ss', 'L');

        const documentModel = new DocumentModel(document);
        let canDownload = false;
        let canUpdate =
            allowUpdate && documentModel.signatureStatus === DocumentModel.SignatureStatus.NOTHING;
        let canSign = allowSign && documentModel.canSign;

        let signLiteral = getLiteral('action_sign');
        if (!canSign) signLiteral = getLiteral('label_cannot_sign');

        const shareLiteral = getLiteral('action_share'),
            editLiteral = getLiteral('action_edit'),
            deleteLiteral = getLiteral('action_delete');

        if (documentModel.canDownload && documentModel.id !== '-1') {
            if (
                documentModel.signatureStatus === DocumentModel.SignatureStatus.COMPLETED ||
                documentModel.signatureStatus === DocumentModel.SignatureStatus.NOTHING
            ) {
                if (documentModel.name) {
                    canDownload = true;
                }
            }
        }

        const showPointer =
            isFolder === '1' || isLink === '1' || canDownload ? 'cursor-pointer' : '';
        const signIconClassName =
            documentManagement && canSign ? 'ic-sign' : 'ic-sign option-disabled';
        const shareIconClassName = canDownload
            ? 'ic-share-filled'
            : 'ic-share-filled option-disabled';
        const updateIconClassName =
            documentManagement && canUpdate ? 'ic-edit-filled' : 'ic-edit-filled option-disabled';
        const deleteIconClassName =
            documentManagement && canDelete
                ? 'ic-trash-filled-18px'
                : 'ic-trash-filled-18px option-disabled';

        const textClass = ['documents-widget-documents-list-row-text', showPointer];
        if (isFolder === '0' && isLink === '0' && !canDownload) {
            textClass.push('row-text__not-downloadable');
        }

        return (
            <div className="documents-widget-documents-list-row-wrapper">
                <div className="documents-widget-documents-list-row">
                    <div className="documents-widget-documents-list-row-left row-documents-left-selected">
                        <div className="documents-widget-documents-list-row-icon-container">
                            <ExtensionIcon
                                color={colors.actionMinor[200]}
                                extension={extension}
                                isFolder={isFolder}
                                isLink={isLink}
                            />
                        </div>
                        <div
                            className={textClass.join(' ')}
                            onClick={(event) => this.handleOnClickRow(event, canDownload)}
                        >
                            {description}
                        </div>
                    </div>
                    <div className="documents-widget-documents-list-row-right row-documents-right-unselected">
                        {dateFormatted}
                    </div>
                    <div className="documents-widget-documents-list-row-right row-documents-right-selected">
                        <div className="documents-widget-documents-list-row-selected-options">
                            {isFolder === '0' && isLink === '0' && (
                                <TooltipHoi content={signLiteral} placement="top">
                                    <i
                                        className={signIconClassName}
                                        onClick={(event) => this.handleSignDocument(event, canSign)}
                                    />
                                </TooltipHoi>
                            )}
                            {isFolder === '0' && isLink === '0' && canUseEmail && (
                                <TooltipHoi content={shareLiteral} placement="top">
                                    <span>
                                        <Icon
                                            className={!canDownload ? 'option-disabled' : undefined}
                                            name="email"
                                            onClick={(event) =>
                                                this.handleShareDocument(event, canDownload, true)
                                            }
                                        />
                                    </span>
                                </TooltipHoi>
                            )}
                            {isFolder === '0' && isLink === '0' && (
                                <TooltipHoi content={shareLiteral} placement="top">
                                    <i
                                        className={shareIconClassName}
                                        onClick={(event) =>
                                            this.handleShareDocument(event, canDownload)
                                        }
                                    />
                                </TooltipHoi>
                            )}
                            <TooltipHoi content={editLiteral} placement="top">
                                <i
                                    className={updateIconClassName}
                                    onClick={(event) => this.handleUpdateDocument(event, canUpdate)}
                                />
                            </TooltipHoi>
                            <TooltipHoi content={deleteLiteral} placement="top">
                                <i
                                    className={deleteIconClassName}
                                    onClick={(event) => this.handleDeleteDocument(event, canDelete)}
                                />
                            </TooltipHoi>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

DocumentsRow.propTypes = propTypes;

export default withEmailEditor(DocumentsRow);
