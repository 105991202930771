import { getLiteral } from 'utils/getLiteral';
import { PAGINATION_TABLE_OPPORTUNITIES } from 'constants/Environment';
import {
    ACTIVITIES,
    COMPANIES,
    CONTACTS,
    OPPORTUNITIES,
    SALESORDERS,
    USERS,
} from 'constants/Entities';
import Context from 'managers/Context';

export default (params) => ({
    pagination: PAGINATION_TABLE_OPPORTUNITIES,
    pageSize: PAGINATION_TABLE_OPPORTUNITIES, //this is for w5 table
    entity: OPPORTUNITIES,
    useFiltersDrawer: true,
    useViewsInFilters: true,
    columnSelectorSections: [
        {
            label: 'title_pipeline',
            fields: [
                'IdTypeExpediente',
                'OpportunityType',
                'win',
                'lost',
                'amount',
                'currency',
                'probability',
                'expectedclose',
                'fechacierre',
            ],
        },
        {
            label: 'label_info',
            fields: [
                'environment',
                'followingitem',
                'comentarios',
                'comercial',
                'responsible2',
                'responsible3',
                'responsible4',
                'responsible5',
                'contact1',
                'contact2',
                'contact3',
                'contact4',
                'contact5',
                'fcreado',
                'fmodificado',
                'fModificadoidEstadoObra',
                'extid',
            ],
        },
        {
            label: 'title_relations',
            fields: ['company', 'companyintermediate', 'companyother', 'referencia'],
        },
        {
            label: 'label_address',
            fields: [
                'address',
                'city',
                'province',
                'cp',
                'country',
                'isgeocoded',
                'latitude',
                'longitude',
            ],
        },
        {
            label: 'label_opportunity_address_customer',
            fields: [
                'addresscompany',
                'citycompany',
                'provincecompany',
                'postalcodecompany',
                'countrycompany',
            ],
        },
        {
            label: 'label_intermediary_address',
            fields: [
                'addresscompanyintermediate',
                'citycompanyintermediate',
                'provincecompanyintermediate',
                'postalcodecompanyintermediate',
                'countrycompanyintermediate',
            ],
        },
        {
            label: 'label_intermediary2_address',
            fields: [
                'addresscompanyother',
                'citycompanyother',
                'provincecompanyother',
                'postalcodecompanyother',
                'countrycompanyother',
            ],
        },
        {
            label: 'label_others',
        },
    ],
    useQuickView: true,
    quickFilters: ['state', 'type', 'idSucursal', 'owner1'],
    defaultFilters: [
        'type',
        'state',
        'dblvalor',
        'ratio',
        'dtprevfechaventa',
        'companies',
        'idSucursal',
        'users',
        'followingItem',
    ],
    defaultCrossFilters: {
        [COMPANIES.entity]: ['hasOpportunity', 'dtprevfechaventa'],
        [CONTACTS.entity]: ['hasOpportunity', 'dblvalor'],
        [ACTIVITIES.entity]: ['hasOpportunity', 'dblvalor'],
        [SALESORDERS.entity]: ['hasOpportunity', 'state'],
    },
    filtersAddButtonSchema: [
        {
            label: getLiteral('label_standard_filters_section_title'),
            options: [
                'hasOpportunity',
                'type',
                'state',
                'dblvalor',
                'ratio',
                'dtprevfechaventa',
                'companies',
                'idSucursal',
                'users',
                'followingItem',
            ],
        },
        {
            label: getLiteral('label_owners'),
            options: ['owner1', 'owner2', 'owner3', 'owner4', 'owner5'],
        },
        {
            label: getLiteral('label_info'),
            options: ['fcreado', 'fechacierre', 'fmodificado', 'fModificadoidEstadoObra'],
        },
        {
            label: getLiteral('label_address'),
            options: ['strpoblacion', 'strprovincia', 'cp', 'idcountry'],
        },
    ],
    columnDefs: [
        {
            colId: 'referencia',
            headerName: getLiteral('label_name'),
            field: 'Reference',
            sortField: 'referencia',
            fieldConfiguration: 'referencia',
            headerComponent: 'headerTextCell',
            headerComponentParams: {
                showTotal: true,
                checkbox: {
                    onChange: params?.reference?.onClickCheckbox || null,
                },
            },
            cellRenderer: 'avatarCell',
            cellRendererParams: {
                bold: true,
                hoverLink: true,
                isSquared: false,
                getUrl:
                    params?.getUrl?.({
                        idKey: 'Id',
                        entity: OPPORTUNITIES,
                    }) || null,
                checkbox: {
                    onChange: params?.reference?.onClickCheckbox || null,
                    isAlwaysVisible: true,
                },
                innerMethods: {
                    getInfoForTag: params?.reference?.getInfoForTag || null,
                    getAvatarSvg: params?.reference?.getAvatarSvg || null,
                },
            },
            width: 350,
            pinned: 'left',
            lockPosition: true,
            suppressMovable: true,
            suppressSizeToFit: true,
            suppressFromFieldSelector: true,
            forceExport: true,
        },
        {
            colId: 'followingitem',
            headerName: getLiteral('label_following'),
            field: 'isFollowing',
            sortField: 'followingitem',
            headerComponent: 'headerIconCell',
            headerComponentParams: {
                innerMethods: {
                    getSvg: params?.follow?.getHeaderSvg || null,
                },
            },
            cellRenderer: 'toggleCell',
            cellRendererParams: {
                innerMethods: {
                    getSvg: params?.follow?.getSvg || null,
                    onClick: params?.follow?.onClick || null,
                },
                highlightOnHover: true,
                useTooltip: true,
                tooltipValue: params?.follow?.getTooltip || null,
            },
            width: 120,
            suppressSizeToFit: true,
        },
        {
            colId: 'company',
            headerName: getLiteral('label_account_client'),
            field: 'Company',
            sortField: 'company',
            fieldConfiguration: 'idinstalador',
            cellRenderer: 'avatarCell',
            cellRendererParams: {
                withEmptyPlaceholder: true,
                getUrl:
                    params?.getUrl?.({
                        idKey: 'IdCompany',
                        entity: COMPANIES,
                    }) || null,
                forceUrl: true,
                onClick: params?.onCompanyClick ? (args) => params?.onCompanyClick(args) : null,
                otherFields: {
                    src: 'srcCompany',
                    placeholder: 'placeholderCompany',
                },
            },
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'comercial',
            headerName: getLiteral('label_responsible_1'),
            field: 'Comercial',
            sortField: 'comercial',
            fieldConfiguration: 'idcomercial',
            cellRenderer: 'avatarCell',
            cellRendererParams: {
                getUrl:
                    params?.getUrl?.({
                        idKey: 'IdComercial',
                        entity: USERS,
                    }) || null,
                otherFields: {
                    src: 'srcComercial',
                    placeholder: 'placeholderComercial',
                },
            },
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'IdTypeExpediente',
            headerName: params?.hasPipeline
                ? getLiteral('label_opportunity_pipeline')
                : getLiteral('label_opportunity_type'),
            field: 'TypeExpediente',
            sortField: 'IdTypeExpediente',
            fieldConfiguration: 'idtipo',
            hide: true,
            cellRenderer: 'textCell',
            width: 160,
            suppressSizeToFit: true,
        },
        {
            colId: 'OpportunityType',
            headerName: getLiteral('label_opportunity_status'),
            field: 'OpportunityType',
            sortField: 'OpportunityType',
            fieldConfiguration: 'idestadoobra',
            cellRenderer: 'textCell',
            width: 160,
            suppressSizeToFit: true,
        },
        {
            colId: 'probability',
            headerName: getLiteral('label_probability'),
            field: 'probability',
            sortField: 'probability',
            fieldConfiguration: 'ratio',
            cellRenderer: 'percentageCell',
            width: 160,
            suppressSizeToFit: true,
        },
        {
            colId: 'amount',
            headerName: getLiteral('label_value'),
            field: 'Amount',
            sortField: 'amount',
            fieldConfiguration: 'dblvalor',
            cellRenderer: 'currencyCell',
            cellRendererParams: {
                align: 'right',
                otherFields: {
                    symbol: 'SymbolCurrency',
                },
            },
            width: 160,
            suppressSizeToFit: true,
        },
        {
            colId: 'currency',
            headerName: getLiteral('label_currency'),
            field: 'currency',
            fieldConfiguration: 'idcurrency',
            suppressFromFieldSelector: true,
            showInExport: true,
            forceExport: true,
        },
        {
            colId: 'expectedclose',
            headerName: getLiteral('label_sales_forecast'),
            field: 'ExpectedClose',
            sortField: 'expectedclose',
            fieldConfiguration: 'dtprevfechaventa',
            cellRenderer: 'dateCell',
            cellRendererParams: {
                locale: params?.locale?.getLocale() || '',
                outputFormat: 'P',
            },
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'country',
            headerName: getLiteral('label_country'),
            field: 'Country',
            sortField: 'country',
            fieldConfiguration: 'idcountry',
            cellRenderer: 'textCell',
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'environment',
            headerName: getLiteral('label_environment'),
            field: 'Environment',
            sortField: 'environment',
            fieldConfiguration: 'idsucursal',
            cellRenderer: 'textCell',
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'responsible2',
            headerName: getLiteral('label_opportunity_responsible_2'),
            field: 'ownerName2',
            sortField: 'responsible2',
            fieldConfiguration: 'responsible2',
            permission: 'opportunityMultipleOwners',
            hide: true,
            cellRenderer: 'avatarCell',
            cellRendererParams: {
                getUrl:
                    params?.getUrl?.({
                        idKey: 'ownerId2',
                        entity: USERS,
                    }) || null,
                otherFields: {
                    src: 'ownerSrc2',
                    placeholder: 'ownerPlaceholder2',
                },
            },
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'responsible3',
            headerName: getLiteral('label_opportunity_responsible_3'),
            field: 'ownerName3',
            sortField: 'responsible3',
            fieldConfiguration: 'responsible3',
            permission: 'opportunityMultipleOwners',
            hide: true,
            cellRenderer: 'avatarCell',
            cellRendererParams: {
                getUrl:
                    params?.getUrl?.({
                        idKey: 'ownerId3',
                        entity: USERS,
                    }) || null,
                otherFields: {
                    src: 'ownerSrc3',
                    placeholder: 'ownerPlaceholder3',
                },
            },
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'responsible4',
            headerName: getLiteral('label_opportunity_responsible_4'),
            field: 'ownerName4',
            sortField: 'responsible4',
            fieldConfiguration: 'responsible4',
            permission: 'opportunityMultipleOwners',
            hide: true,
            cellRenderer: 'avatarCell',
            cellRendererParams: {
                getUrl:
                    params?.getUrl?.({
                        idKey: 'ownerId4',
                        entity: USERS,
                    }) || null,
                otherFields: {
                    src: 'ownerSrc4',
                    placeholder: 'ownerPlaceholder4',
                },
            },
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'responsible5',
            headerName: getLiteral('label_opportunity_responsible_5'),
            field: 'ownerName5',
            sortField: 'responsible5',
            fieldConfiguration: 'responsible5',
            permission: 'opportunityMultipleOwners',
            hide: true,
            cellRenderer: 'avatarCell',
            cellRendererParams: {
                getUrl:
                    params?.getUrl?.({
                        idKey: 'ownerId5',
                        entity: USERS,
                    }) || null,
                otherFields: {
                    src: 'ownerSrc5',
                    placeholder: 'ownerPlaceholder5',
                },
            },
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'contact1',
            headerName: getLiteral('label_contact'),
            field: 'contactName1',
            sortable: false,
            permission: 'opportunityMultipleContacts',
            hide: true,
            cellRenderer: 'avatarCell',
            cellRendererParams: {
                getUrl:
                    params?.getUrl?.({
                        idKey: 'contactId1',
                        entity: CONTACTS,
                    }) || null,
                otherFields: {
                    src: 'contactSrc1',
                    placeholder: 'contactPlaceholder1',
                },
                forceUrl: true,
                onClick: params?.onContactClick ? (args) => params?.onContactClick(args, 1) : null,
            },
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'contact2',
            headerName: getLiteral('label_contact_2'),
            field: 'contactName2',
            sortable: false,
            permission: 'opportunityMultipleContacts',
            hide: true,
            cellRenderer: 'avatarCell',
            cellRendererParams: {
                getUrl:
                    params?.getUrl?.({
                        idKey: 'contactId2',
                        entity: CONTACTS,
                    }) || null,
                otherFields: {
                    src: 'contactSrc2',
                    placeholder: 'contactPlaceholder2',
                },
                forceUrl: true,
                onClick: params?.onContactClick ? (args) => params?.onContactClick(args, 2) : null,
            },
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'contact3',
            headerName: getLiteral('label_contact_3'),
            field: 'contactName3',
            sortable: false,
            permission: 'opportunityMultipleContacts',
            hide: true,
            cellRenderer: 'avatarCell',
            cellRendererParams: {
                getUrl:
                    params?.getUrl?.({
                        idKey: 'contactId3',
                        entity: CONTACTS,
                    }) || null,
                otherFields: {
                    src: 'contactSrc3',
                    placeholder: 'contactPlaceholder3',
                },
                forceUrl: true,
                onClick: params?.onContactClick ? (args) => params?.onContactClick(args, 3) : null,
            },
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'contact4',
            headerName: getLiteral('label_contact_4'),
            field: 'contactName4',
            sortable: false,
            permission: 'opportunityMultipleContacts',
            hide: true,
            cellRenderer: 'avatarCell',
            cellRendererParams: {
                getUrl:
                    params?.getUrl?.({
                        idKey: 'contactId4',
                        entity: CONTACTS,
                    }) || null,
                otherFields: {
                    src: 'contactSrc4',
                    placeholder: 'contactPlaceholder4',
                },
                forceUrl: true,
                onClick: params?.onContactClick ? (args) => params?.onContactClick(args, 4) : null,
            },
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'contact5',
            headerName: getLiteral('label_contact_5'),
            field: 'contactName5',
            sortable: false,
            permission: 'opportunityMultipleContacts',
            hide: true,
            cellRenderer: 'avatarCell',
            cellRendererParams: {
                getUrl:
                    params?.getUrl?.({
                        idKey: 'contactId5',
                        entity: CONTACTS,
                    }) || null,
                otherFields: {
                    src: 'contactSrc5',
                    placeholder: 'contactPlaceholder5',
                },
                forceUrl: true,
                onClick: params?.onContactClick ? (args) => params?.onContactClick(args, 5) : null,
            },
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'comentarios',
            headerName: getLiteral('label_comment_opportunities'),
            field: 'Comments',
            sortField: 'observaciones',
            fieldConfiguration: 'observaciones',
            hide: true,
            cellRenderer: 'textCell',
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'fcreado',
            headerName: getLiteral('label_created'),
            field: 'Created',
            sortField: 'fcreado',
            hide: true,
            cellRenderer: 'dateCell',
            cellRendererParams: {
                locale: params?.locale?.getLocale() || '',
                offset: Context?.config?.userData?.userOffsetFromServer,
                outputFormat: 'P',
            },
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'fechacierre',
            headerName: getLiteral('label_opportunity_close'),
            field: 'Close',
            sortField: 'fechacierre',
            fieldConfiguration: 'fechacierre',
            hide: true,
            cellRenderer: 'dateCell',
            cellRendererParams: {
                locale: params?.locale?.getLocale() || '',
                outputFormat: 'P',
            },
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'fmodificado',
            headerName: getLiteral('label_modified'),
            field: 'Modified',
            sortField: 'fmodificado',
            hide: true,
            cellRenderer: 'dateCell',
            cellRendererParams: {
                locale: params?.locale?.getLocale() || '',
                offset: Context?.config?.userData?.userOffsetFromServer,
                outputFormat: 'P',
            },
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'fModificadoidEstadoObra',
            headerName: getLiteral('label_status_date_modified'),
            field: 'fModificadoidEstadoObra',
            sortField: 'fModificadoidEstadoObra',
            hide: true,
            cellRenderer: 'dateCell',
            cellRendererParams: {
                locale: params?.locale?.getLocale() || '',
                outputFormat: 'P',
            },
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'win',
            headerName: getLiteral('label_main_status_won'),
            field: 'IsWin',
            sortField: 'win',
            fieldConfiguration: 'win', // not used in standard fields schema,
            hide: true,
            cellRenderer: 'tagCell',
            cellRendererParams: {
                innerMethods: {
                    getInfoForTag: params?.win?.getInfoForTag || null,
                    isSvgElement: true,
                },
            },
            width: 160,
            suppressSizeToFit: true,
        },
        {
            colId: 'lost',
            headerName: getLiteral('placeholder_lost_female_sg'),
            field: 'IsLost',
            sortField: 'lost',
            fieldConfiguration: 'lost', // not used in standard fields schema,
            hide: true,
            cellRenderer: 'tagCell',
            cellRendererParams: {
                innerMethods: {
                    getInfoForTag: params?.lost?.getInfoForTag || null,
                },
            },
            width: 160,
            suppressSizeToFit: true,
        },
        {
            colId: 'extid',
            headerName: getLiteral('placeholder_external'),
            field: 'ExtId',
            sortField: 'extid',
            fieldConfiguration: 'extid',
            hide: true,
            cellRenderer: 'textCell',
            width: 160,
            suppressSizeToFit: true,
            suppressFromFieldSelector: true,
            forceExport: true,
        },
        {
            colId: 'companyintermediate',
            headerName: getLiteral('label_account_middleman'),
            field: 'CompanyIntermediate',
            sortField: 'companyintermediate',
            fieldConfiguration: 'idingenieria',
            hide: true,
            cellRenderer: 'avatarCell',
            cellRendererParams: {
                getUrl:
                    params?.getUrl?.({
                        idKey: 'IdCompanyIntermediate',
                        entity: COMPANIES,
                    }) || null,
                otherFields: {
                    src: 'srcCompanyIntermediate',
                    placeholder: 'placeholderCompanyIntermediate',
                },
            },
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'companyother',
            headerName: getLiteral('label_intermediary2'),
            field: 'CompanyOther',
            sortField: 'companyother',
            fieldConfiguration: 'idconstructora',
            hide: true,
            cellRenderer: 'avatarCell',
            cellRendererParams: {
                getUrl:
                    params?.getUrl?.({
                        idKey: 'IdCompanyOther',
                        entity: COMPANIES,
                    }) || null,
                otherFields: {
                    src: 'srcCompanyOther',
                    placeholder: 'placeholderCompanyOther',
                },
            },
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'cp',
            headerName: getLiteral('label_post_code'),
            field: 'CP',
            sortField: 'cp',
            fieldConfiguration: 'cp',
            hide: true,
            cellRenderer: 'textCell',
            width: 160,
            suppressSizeToFit: true,
        },
        {
            colId: 'address',
            headerName: getLiteral('label_address'),
            field: 'Address',
            sortField: 'address',
            fieldConfiguration: 'direccion',
            hide: true,
            cellRenderer: 'textCell',
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'isgeocoded',
            headerName: getLiteral('label_opportunity_geolocation'),
            field: 'isGeoCoded',
            sortField: 'isgeocoded',
            fieldConfiguration: 'blngeocoded',
            headerComponent: 'headerIconCell',
            headerComponentParams: {
                innerMethods: {
                    getSvg: params?.geolocation?.getHeaderSvg || null,
                },
            },
            hide: true,
            cellRenderer: 'booleanIconCell',
            cellRendererParams: {
                innerMethods: {
                    getSvg: params?.geolocation?.getSvg || null,
                },
            },
            width: 100,
            suppressSizeToFit: true,
        },
        {
            colId: 'latitude',
            headerName: getLiteral('label_latitude'),
            field: 'Latitude',
            sortField: 'latitude',
            fieldConfiguration: 'geocodelat',
            hide: true,
            cellRenderer: 'textCell',
            width: 160,
            suppressSizeToFit: true,
        },
        {
            colId: 'longitude',
            headerName: getLiteral('label_longitude'),
            field: 'Longitude',
            sortField: 'longitude',
            fieldConfiguration: 'geocodelon',
            hide: true,
            cellRenderer: 'textCell',
            width: 160,
            suppressSizeToFit: true,
        },
        {
            colId: 'city',
            headerName: getLiteral('label_city'),
            field: 'City',
            sortField: 'city',
            fieldConfiguration: 'strpoblacion',
            hide: true,
            cellRenderer: 'textCell',
            width: 160,
            suppressSizeToFit: true,
        },
        {
            colId: 'province',
            headerName: getLiteral('label_provinceregion'),
            field: 'Province',
            sortField: 'province',
            fieldConfiguration: 'strprovincia',
            hide: true,
            cellRenderer: 'textCell',
            width: 160,
            suppressSizeToFit: true,
        },
        {
            colId: 'postalcodecompany',
            headerName: getLiteral('label_opportunity_post_code_customer'),
            field: 'PostalCodeCompany',
            sortField: 'postalcodecompany',
            hide: true,
            cellRenderer: 'textCell',
            width: 160,
            suppressSizeToFit: true,
        },
        {
            colId: 'addresscompany',
            headerName: getLiteral('label_opportunity_address_customer'),
            field: 'AddressCompany',
            sortField: 'addresscompany',
            hide: true,
            cellRenderer: 'textCell',
            width: 160,
            suppressSizeToFit: true,
        },
        {
            colId: 'countrycompany',
            headerName: getLiteral('label_opportunity_country_customer'),
            field: 'CountryCompany',
            sortField: 'countrycompany',
            hide: true,
            cellRenderer: 'textCell',
            width: 160,
            suppressSizeToFit: true,
        },
        {
            colId: 'citycompany',
            headerName: getLiteral('label_opportunity_city_town_customer'),
            field: 'CityCompany',
            sortField: 'citycompany',
            hide: true,
            cellRenderer: 'textCell',
            width: 160,
            suppressSizeToFit: true,
        },
        {
            colId: 'provincecompany',
            headerName: getLiteral('label_opportunity_region_state_customer'),
            field: 'ProvinceCompany',
            sortField: 'provincecompany',
            hide: true,
            cellRenderer: 'textCell',
            width: 160,
            suppressSizeToFit: true,
        },
        {
            colId: 'postalcodecompanyintermediate',
            headerName: getLiteral('label_opportunity_post_code_intermediary'),
            field: 'PostalCodeCompanyIntermediate',
            sortField: 'postalcodecompanyintermediate',
            hide: true,
            cellRenderer: 'textCell',
            width: 160,
            suppressSizeToFit: true,
        },
        {
            colId: 'addresscompanyintermediate',
            headerName: getLiteral('label_opportunity_address_intermediary'),
            field: 'AddressCompanyIntermediate',
            sortField: 'addresscompanyintermediate',
            hide: true,
            cellRenderer: 'textCell',
            width: 160,
            suppressSizeToFit: true,
        },
        {
            colId: 'countrycompanyintermediate',
            headerName: getLiteral('label_opportunity_country_intermediary'),
            field: 'CountryCompanyIntermediate',
            sortField: 'countrycompanyintermediate',
            hide: true,
            cellRenderer: 'textCell',
            width: 160,
            suppressSizeToFit: true,
        },
        {
            colId: 'citycompanyintermediate',
            headerName: getLiteral('label_opportunity_city_town_intermediary'),
            field: 'CityCompanyIntermediate',
            sortField: 'citycompanyintermediate',
            hide: true,
            cellRenderer: 'textCell',
            width: 160,
            suppressSizeToFit: true,
        },
        {
            colId: 'provincecompanyintermediate',
            headerName: getLiteral('label_opportunity_region_state_intermediary'),
            field: 'ProvinceCompanyIntermediate',
            sortField: 'provincecompanyintermediate',
            hide: true,
            cellRenderer: 'textCell',
            width: 160,
            suppressSizeToFit: true,
        },
        {
            colId: 'postalcodecompanyother',
            headerName: getLiteral('label_opportunity_region_post_code_intermediary_2'),
            field: 'PostalCodeCompanyOther',
            sortField: 'postalcodecompanyother',
            hide: true,
            cellRenderer: 'textCell',
            width: 160,
            suppressSizeToFit: true,
        },
        {
            colId: 'addresscompanyother',
            headerName: getLiteral('label_opportunity_address_intermediary_2'),
            field: 'AddressCompanyOther',
            sortField: 'addresscompanyother',
            hide: true,
            cellRenderer: 'textCell',
            width: 160,
            suppressSizeToFit: true,
        },
        {
            colId: 'countrycompanyother',
            headerName: getLiteral('label_opportunity_country_intermediary_2'),
            field: 'CountryCompanyOther',
            sortField: 'countrycompanyother',
            hide: true,
            cellRenderer: 'textCell',
            width: 160,
            suppressSizeToFit: true,
        },
        {
            colId: 'citycompanyother',
            headerName: getLiteral('label_opportunity_city_town_intermediary_2'),
            field: 'CityCompanyOther',
            sortField: 'citycompanyother',
            hide: true,
            cellRenderer: 'textCell',
            width: 160,
            suppressSizeToFit: true,
        },
        {
            colId: 'provincecompanyother',
            headerName: getLiteral('label_opportunity_region_state_intermediary_2'),
            field: 'ProvinceCompanyOther',
            sortField: 'provincecompanyother',
            hide: true,
            cellRenderer: 'textCell',
            width: 160,
            suppressSizeToFit: true,
        },
    ],
    forceExport: ['id', 'extid', 'referencia'],
    help: [
        {
            label: 'label_link_help_what_opp_is',
            link: 'link_help_what_opp_is',
            permissions: ['permission.expedientes'],
        },
        {
            label: 'label_link_help_opp_fields',
            link: 'link_help_opp_fields',
            permissions: ['permission.expedientes'],
        },
        {
            label: 'label_link_help_how_to_find_opp',
            link: 'link_help_how_to_find_opp',
            permissions: ['permission.expedientes'],
        },
        {
            label: 'label_link_help_export_opp',
            link: 'link_help_export_opp',
            permissions: ['permission.exportExpedientes'],
        },
    ],
});
