import moment from 'moment';
import { GoalsService, EntityCrudService } from 'services';
import Context from 'managers/Context';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { KPIS } from 'constants/Entities';
import { FuzzyMap } from 'utils/fuzzy';
import { sort } from 'utils/arrays';
import { isEmptyObject } from 'utils/objects';
import { EntitiesDb } from 'constants/Entities';
import CustomSelectIndicatorContainer from 'containers/kpis/components/KpisCrud/CustomSelectIndicatorContainer';
import {
    mapToJsonLogic,
    validateConditions,
    transformDataConditions,
} from '@web/web5/dist/components/Conditions';
import { findFieldConfiguration } from 'utils/fm';
import { getActiveCrud } from 'utils/crud';

export default class KpisManager {
    getSchema = (defaultInputSchema) => [
        {
            title: getLiteral('label_goal_owner_and_assignment'),
            blockId: 'ownerAndAssignment',
            show: true,
            fields: [
                {
                    ...defaultInputSchema,
                    id: 'owner',
                    fieldConfiguration: 'owner',
                    mandatory: true,
                    type: 'singleValueList',
                    label: getLiteral('label_owner'),
                    inputAttrs: {
                        ...FuzzyMap.users,
                        withChip: true,
                        beforeRenderCrudField: this.beforeRenderCrudField,
                        // options: [
                        //     //Populated with getOwnerOptions in beforeRenderCrudField
                        // ],
                    },
                },
                {
                    ...defaultInputSchema,
                    id: 'assignment',
                    fieldConfiguration: 'assignment',
                    mandatory: true,
                    label: getLiteral('label_assignment'),
                    type: 'singleValueList',
                    dependenceFilter: this.dependenceFilter,
                    isCustom: true,
                    inputAttrs: {
                        list: 'goalAssignments',
                        parentField: 'owner',
                        isClearable: false,
                        beforeRenderCrudField: this.beforeRenderCrudField,
                    },
                },
                {
                    ...defaultInputSchema,
                    id: 'isTeamVisible',
                    serverId: 'visible',
                    fieldConfiguration: 'isTeamVisible',
                    type: 'bool',
                    label: getLiteral('action_goal_visible_to_all'),
                    inputAttrs: {
                        shouldRenderField: this.shouldRenderField,
                    },
                },
            ],
        },
        {
            title: getLiteral('label_definition_of_the_goal'),
            blockId: 'goalDefinition',
            show: true,
            fields: [
                {
                    ...defaultInputSchema,
                    id: 'kpiId',
                    serverId: 'kpi',
                    fieldConfiguration: 'kpiId',
                    mandatory: true,
                    type: 'singleValueList',
                    label: getLiteral('label_category'),
                    inputAttrs: {
                        list: 'kpis',
                        isClearable: false,
                        beforeRenderCrudField: this.beforeRenderCrudField,
                        components: {
                            IndicatorsContainer: CustomSelectIndicatorContainer,
                        },
                    },
                },
                {
                    ...defaultInputSchema,
                    id: 'type',
                    mandatory: false,
                    readOnly: false,
                    type: 'singleValueList',
                    label: getLiteral('label_type'),
                    isCustom: true,
                    inputAttrs: {
                        parentField: 'kpiId',
                        beforeRenderCrudField: this.beforeRenderCrudField,
                    },
                },
                {
                    ...defaultInputSchema,
                    id: 'goalName',
                    serverId: 'name',
                    fieldConfiguration: 'goalName',
                    mandatory: true,
                    type: 'text',
                    label: getLiteral('label_goal_name'),
                    dataLength: 20,
                    inputAttrs: {
                        parentField: 'kpiId',
                    },
                },
                {
                    id: 'periodGroup',
                    isCustom: true,
                    type: 'multipleCustom',
                    inputAttrs: {
                        beforeRenderCrudField: this.beforeRenderCrudField,
                    },
                    inputs: [
                        {
                            ...defaultInputSchema,
                            id: 'period',
                            serverId: 'frequency',
                            mandatory: true,
                            type: 'singleValueList',
                            label: getLiteral('label_periodicity'),
                            inputAttrs: {
                                list: 'goalPeriods',
                                isClearable: false,
                            },
                        },
                        {
                            ...defaultInputSchema,
                            id: 'begins',
                            serverId: 'startDate',
                            mandatory: false,
                            label: getLiteral('label_beginning_in'),
                            inputAttrs: {
                                beforeRenderCrudField: this.beforeRenderCrudField,
                                // options: [
                                //     //Populated with getBeginningOptions in beforeRenderCrudField
                                // ],
                            },
                        },
                    ],
                },
            ],
        },
        {
            title: getLiteral('label_goal_to_be_achived'),
            blockId: 'goalToAchieve',
            show: true,
            fields: [
                {
                    id: 'teamAssignment',
                    //idTwin is the actual key that should be modified on change this field
                    //assignment field and teamAssignment modify the same key of the crud
                    idTwin: 'assignment',
                    isCustom: true,
                    inputAttrs: {
                        beforeRenderCrudField: this.beforeRenderCrudField,
                    },
                },
                {
                    id: 'goalFieldGroup',
                    //    type: 'goalField',
                    isCustom: true,
                    type: 'multipleCustom',
                    inputAttrs: {
                        beforeRenderCrudField: this.beforeRenderCrudField,
                    },
                    inputs: [
                        {
                            ...defaultInputSchema,
                            id: 'goal',
                            fieldConfiguration: 'goal',
                            mandatory: true,
                            type: 'number',
                            isSafeNumber: true,
                            placeholder: getLiteral('placeholder_numeric'),
                        },
                        {
                            id: 'goalToggle',
                            fieldConfiguration: 'goalToggle',
                        },
                        {
                            id: 'valuesDefined',
                            fieldConfiguration: 'valuesDefined',
                            fields: [
                                // Populated with:
                                // getValuesDefinedFields in beforeRenderCrudField
                                // or other depending the casuistic
                                // the field valuesDefined can be different types of fields
                            ],
                        },
                    ],
                },
                {
                    id: 'participants',
                    fieldConfiguration: 'participants',
                    type: 'participants',
                    isCustom: true,
                },
            ],
        },
    ];

    checkFieldError = (field, value) => {
        let error = null;
        if (field.id === 'goal') {
            if (value === '0' || value === '-0') error = 'forbidden-zero';
        }
        return error;
    };

    checkCustomErrors = (errors, schema, data) => {
        let newErrors = {};
        let firstErrorField;

        if (data.valuesDefined) {
            const goalToAchieveTab = schema.find((tab) => tab.blockId === 'goalToAchieve');
            const goalFieldGroupField = goalToAchieveTab.fields.find(
                (field) => field.id === 'goalFieldGroup',
            );
            const valuesDefinedField = goalFieldGroupField.inputs.find(
                (field) => field.id === 'valuesDefined',
            );

            valuesDefinedField.fields.forEach((current) => {
                if (!data.valuesDefined[current.id] && data.valuesDefined[current.id] !== '0')
                    newErrors[current.id] = 'mandatory';
            });

            if (!isEmptyObject(newErrors)) firstErrorField = 'valuesDefined';
        }

        if (data.condition && !isEmptyObject(data.condition)) {
            if (!validateConditions(data.condition)) {
                newErrors.condition = 'mandatory';
                if (!firstErrorField) firstErrorField = 'condition';
            }
        }

        return {
            ...errors,
            ...newErrors,
            firstErrorField,
        };
    };

    getDefaultOwner() {
        const state = Context.store.getState();
        const selectedUser = state.users?.usersTree?.selectedUser;

        return { value: selectedUser.id, label: selectedUser.name };
    }

    checkIfCanOpenCrud = (data) => {
        if (!data?.id) return true;
        return !data?.isReadOnly;
    };

    whileInitCrud = (data, mappedSchema) => {
        return new Promise((resolve) => {
            //Set default CRUD values
            if (!data?.kpiId) data.kpiId = mappedSchema.kpiId.inputAttrs.options[0]?.value || '';
            if (!data?.assignment)
                data.assignment = mappedSchema.assignment.inputAttrs.options[0]?.value || '';
            if (!data?.period) {
                data.period = mappedSchema.period.inputAttrs.options[0]?.value || '';
                data.begins = this.calculateWhenBegins(data.period);
            }
            if (!data?.owner) {
                data.owner = this.getDefaultOwner();
            }
            if (!data.goalName && data.kpiId) {
                const kpiIdOptions = mappedSchema.kpiId.inputAttrs.options;
                const currentKpiIdName =
                    (kpiIdOptions.length > 0 &&
                        kpiIdOptions.reduce((str, current) => {
                            if (current.value === data.kpiId) {
                                str = current.label;
                                return str;
                            }
                            return str;
                        }, '')) ||
                    '';

                const finalName =
                    currentKpiIdName.length > 20
                        ? currentKpiIdName.substring(0, 20)
                        : currentKpiIdName;

                data.goalName = finalName;

                if (!data.id) {
                    data.isTeamVisible = false;
                }
            }

            if (!data.goalToggle) data.goalToggle = false;

            if (!data.valuesDefined) data.valuesDefined = {};
            if (!data.isPeriodCustom) data.isPeriodCustom = false;

            resolve(data);
        });
    };

    beforeRenderCrudField = (field) => {
        const state = Context.store.getState();
        const activeCrud = getActiveCrud(state);
        const activeCrudData = (activeCrud && activeCrud.data) || null;
        const mappedSchema = activeCrud?.mappedSchema;
        const ownerId = activeCrudData?.owner?.value || '';
        const usersTreeData = state.users.usersTree.data || null;
        const hasTeam = ownerId && usersTreeData[ownerId]?.children?.length > 0 ? true : false;

        let newField = { ...field };

        if (
            activeCrud?.id &&
            ['kpiId', 'owner', 'assignment', 'type', 'teamAssignment'].includes(field.id)
        ) {
            newField.readOnly = true;
        }

        if (field.id === 'owner') {
            newField.inputAttrs.options = getOwnerOptions();
        }

        if (field.id === 'assignment') {
            if (!hasTeam || !ownerId) newField.readOnly = true;
        }

        if (field.id === 'type') {
            const selectedKpi = mappedSchema?.kpiId?.inputAttrs?.options.find(
                (k) => k.id === activeCrudData?.kpiId,
            );
            if (selectedKpi?.editable && !selectedKpi?.defaultType) {
                newField.hidden = true;
            } else {
                newField.hidden = false;
            }
        }

        if (field.id === 'periodGroup') {
            newField.inputs.forEach((current) => {
                if (current.id === 'period') {
                    if (activeCrud?.id) {
                        newField.readOnly = true;
                        current.readOnly = true;
                    }

                    current.inputAttrs.footer = this.getPeriodFooter(
                        activeCrudData?.period,
                        activeCrudData?.begins,
                    );
                } else if (current.id === 'begins') {
                    const {
                        options,
                        optionsTwo,
                        onlyValue,
                        description,
                        defaultValue,
                        defaultValueTwo,
                        footer,
                    } = getBeginningOptions();

                    current.inputAttrs.options = options;
                    current.inputAttrs.onlyValue = onlyValue;
                    current.inputAttrs.optionsTwo = optionsTwo || [];
                    current.inputAttrs.footer = footer;
                    current.description = description;
                    current.defaultValue = defaultValue;
                    current.defaultValueTwo = defaultValueTwo;
                }
            });
        }

        if (field.id === 'teamAssignment') {
            if (!activeCrudData?.assignment || activeCrudData?.assignment === 'user') {
                newField.hidden = true;
            } else {
                newField.hidden = false;
            }
        }

        if (field.id === 'goalFieldGroup') {
            newField.inputs.forEach((current) => {
                if (current.id === 'goal') {
                    if (activeCrudData?.goalToggle) current.mandatory = false;
                    else current.mandatory = true;
                }
                if (current.id === 'valuesDefined') {
                    if (
                        activeCrudData?.assignment === 'teamaggregated' &&
                        activeCrudData?.goalToggle
                    ) {
                        const { fieldsUsers, fieldsPeriods } =
                            getValuesDefinedFields(activeCrudData);
                        current.fields = [];
                        current.fieldsTable = { fieldsUsers, fieldsPeriods };
                    } else {
                        current.fields = getValuesDefinedFields(activeCrudData);
                        delete current.fieldsTable;
                    }
                }
            });
        }

        return newField;
    };

    dependenceFilter = (field) => {
        return (option) => {
            const state = Context.store.getState();
            const activeCrud = getActiveCrud(state);
            const crudData = activeCrud?.data;

            if (
                field.inputAttrs &&
                field.inputAttrs.parentField &&
                field.inputAttrs.parentField === 'owner'
            ) {
                const ownerId = crudData?.owner?.value;
                const usersTreeData = state.users.usersTree.data || null;
                const ownerTeam = ownerId && usersTreeData && usersTreeData[ownerId]?.children;

                if (ownerTeam && ownerTeam.length === 0 && option.value === 'user') {
                    return option;
                } else if (ownerTeam && ownerTeam.length > 0) {
                    return option;
                }
            }
        };
    };

    shouldRenderField = (fieldId) => {
        const state = Context.store.getState();
        const activeCrud = getActiveCrud(state);
        const assignment = activeCrud?.data?.assignment;
        if (fieldId === 'isTeamVisible') {
            return !(!assignment || assignment === 'user');
        }
    };

    afterGetSchema = ({ schema, dependencyMap, dynamicMap }) => {
        return new Promise((resolve, reject) => {
            const getGoalPeriods = new Promise((res, rej) => {
                Context.serverListManager
                    .getList('goalPeriods')
                    .then((result) => {
                        res(result);
                    })
                    .catch(rej);
            });

            const getGoalAssignment = new Promise((res, rej) => {
                Context.serverListManager
                    .getList('goalAssignments')
                    .then((result) => {
                        res(result);
                    })
                    .catch(rej);
            });

            Promise.all([
                this.getKpiList(),
                this.getUsersDetailCrud(),
                getGoalPeriods,
                getGoalAssignment,
            ])
                .then((result) => ({
                    kpis: result[0],
                    goalPeriods: result[2],
                    goalAssignments: result[3],
                }))
                .then(({ kpis, goalPeriods, goalAssignments }) => {
                    const ownerAndAssignmentTab = schema.find(
                        (tab) => tab.blockId === 'ownerAndAssignment',
                    );
                    const goalDefinitionTab = schema.find(
                        (tab) => tab.blockId === 'goalDefinition',
                    );

                    // assignment field options
                    const assignmentField = ownerAndAssignmentTab.fields.find(
                        (field) => field.id === 'assignment',
                    );
                    assignmentField.inputAttrs.options = goalAssignments;

                    // kpis options
                    const kpiIdField = goalDefinitionTab.fields.find(
                        (field) => field.id === 'kpiId',
                    );
                    kpiIdField.inputAttrs.options = kpis;

                    // period options
                    const goalPeriodField = goalDefinitionTab.fields.find(
                        (field) => field.id === 'periodGroup',
                    );
                    const periodField = goalPeriodField.inputs.find(
                        (field) => field.id === 'period',
                    );
                    periodField.inputAttrs.options = goalPeriods;

                    resolve({
                        schema,
                        dependencyMap,
                        dynamicMap,
                    });
                })
                .catch(reject);
        });
    };

    changeFields = (fields) => {
        const state = Context.store.getState();
        const activeCrud = getActiveCrud(state);
        const dataCrud = activeCrud?.data;
        const mappedSchema = activeCrud?.mappedSchema;
        const isEdit = !!dataCrud.id;
        let newFields = { ...fields };

        if (newFields.owner && !newFields.assignment) newFields.assignment = 'user';
        if (newFields.assignment === 'user' || newFields.owner === null) {
            newFields.isTeamVisible = false;
        }

        if (
            newFields.assignment &&
            (newFields.assignment === 'team' || newFields.assignment === 'teamaggregated') &&
            dataCrud?.assignment === 'user'
        ) {
            // only force isTeamVisible to true if we come from assignment user
            newFields.isTeamVisible = true;
        }

        if (newFields.kpiId) {
            const kpiIdLabel =
                mappedSchema.kpiId.inputAttrs.options.find(
                    (option) => option.value === newFields.kpiId,
                ).label || '';

            newFields.goalName = kpiIdLabel.length > 20 ? kpiIdLabel.substring(0, 20) : kpiIdLabel;
            if (dataCrud.condition) newFields.condition = '';
            if (dataCrud.conditionSimple) newFields.conditionSimple = {};
        }

        if (newFields.type) {
            const { conditionSimple } = newFields.type;
            const kpiIdLabel =
                mappedSchema.kpiId.inputAttrs.options.find(
                    (option) => option.value === dataCrud.kpiId,
                ).label || '';

            const newGoalName = `${kpiIdLabel} ${newFields.type.label}`;
            newFields.type = newFields.type.value;
            newFields.goalName =
                newGoalName.length > 20 ? newGoalName.substring(0, 20) : newGoalName;

            newFields.conditionSimple = conditionSimple;
        }

        if (newFields.hasOwnProperty('activateCustomType')) {
            if (newFields.activateCustomType) {
                newFields.goalName = '';
            } else {
                const kpi = mappedSchema.kpiId.inputAttrs.options.find(
                    (option) => option.value === dataCrud.kpiId,
                );
                let typeLabel = '';
                if (kpi) {
                    const entityConstant = EntitiesDb[kpi.entity.toLowerCase()];
                    if (entityConstant) {
                        const { configField } = findFieldConfiguration(
                            kpi.defaultType,
                            entityConstant,
                        );
                        if (configField && configField.valueListName) {
                            const serverList =
                                state.serverList[configField.valueListName]?.data || [];
                            const selectedType = serverList.find(
                                (value) => value.value === parseInt(dataCrud.type, 10),
                            );
                            if (selectedType) {
                                typeLabel = selectedType.label;
                            }
                        }
                    }
                }

                const newGoalName = `${kpi?.label} ${typeLabel}`;
                newFields.goalName =
                    newGoalName.length > 20 ? newGoalName.substring(0, 20) : newGoalName;
            }
        }

        if (newFields.owner || newFields.owner === null) {
            newFields.valuesDefined = {};
        }

        if (newFields.isPeriodCustom && !dataCrud.begins) {
            const momentYear = moment().format('YYYY');
            newFields.begins = `${momentYear}-1-1`;
        }

        if (newFields.period) {
            newFields.begins = this.calculateWhenBegins(newFields.period);
            if (newFields.period === 'day') {
                newFields.isPeriodCustom = false;
            }
        }

        if (newFields.owner === null) newFields.goal = '';

        //populate fields when changing team fields or team aggregated
        if (
            newFields.valuesDefined &&
            newFields.valuesDefined.hasOwnProperty('idUser') &&
            newFields.valuesDefined.hasOwnProperty('field') &&
            newFields.valuesDefined.hasOwnProperty('value')
        ) {
            // we are changing the table for team aggregated
            const currentValuesDefined = dataCrud.valuesDefined;
            const { idUser, field, value } = newFields.valuesDefined;
            if (idUser && field) {
                const newValue = Number(value) || 0;
                if (idUser !== -1) {
                    newFields.valuesDefined = {
                        ...currentValuesDefined,
                        [idUser]: {
                            ...currentValuesDefined[idUser],
                            [field]: value.toString(), // accept empty string
                        },
                    };
                } else {
                    // share the new value between all users
                    let usersToCalculate = { ...currentValuesDefined };
                    if (isEdit) {
                        usersToCalculate = Object.keys(usersToCalculate).reduce((obj, key) => {
                            obj = { ...obj, [key]: { ...usersToCalculate[key] } };
                            return obj;
                        }, {});
                    }

                    if (!value && value !== '0') {
                        newFields.valuesDefined = Object.keys(usersToCalculate).reduce(
                            (obj, user) => {
                                return {
                                    ...obj,
                                    [user]: {
                                        ...usersToCalculate[user],
                                        [field]: '',
                                    },
                                };
                            },
                            {},
                        );
                    } else {
                        const numUsers = Object.keys(usersToCalculate).length;
                        const extra = newValue % numUsers;
                        const finalValue = extra
                            ? (newValue - extra) / numUsers
                            : newValue / numUsers;

                        // get the first user of the table
                        const ownerId = dataCrud?.owner?.value || null;
                        const usersTree = ownerId && state?.users?.usersTree?.data?.[ownerId];
                        const treeFields = [];
                        getUserFieldsFromTree(usersTree, treeFields);
                        const firstIdOfTable = parseInt(treeFields?.[0].id || 0, 10);

                        newFields.valuesDefined = Object.keys(usersToCalculate).reduce(
                            (obj, user) => {
                                let valueToAssign = finalValue;
                                if (extra && parseInt(user, 10) === firstIdOfTable) {
                                    valueToAssign = finalValue + extra;
                                }
                                return {
                                    ...obj,
                                    [user]: {
                                        ...usersToCalculate[user],
                                        [field]: valueToAssign.toString(),
                                    },
                                };
                            },
                            {},
                        );
                    }

                    if (isEdit) {
                        newFields.valuesDefined = {
                            ...(dataCrud?.valuesDefined || {}),
                            ...newFields.valuesDefined,
                        };
                    }
                }
                newFields.goal = Object.keys(newFields.valuesDefined).reduce((acc, userKey) => {
                    const values = newFields.valuesDefined[userKey];
                    acc += Object.keys(values).reduce(
                        (sum, key) => sum + parseInt(values[key], 10),
                        0,
                    );
                    return acc;
                }, 0);
            } else {
                newFields.valuesDefined = {};
            }
        } else if (
            newFields.valuesDefined &&
            newFields.valuesDefined.newValuesDefined &&
            newFields.valuesDefined.teamAggregatedUserChange
        ) {
            newFields.valuesDefined = { ...newFields.valuesDefined.newValuesDefined };
            newFields.goal =
                Object.keys(newFields.valuesDefined)
                    .reduce((acc, currentKey) => {
                        let value = newFields.valuesDefined[currentKey];
                        if (isNaN(value)) return acc;
                        return (acc += Number(value));
                    }, 0)
                    .toString() || '';
        } else if (
            newFields.hasOwnProperty('goal') ||
            newFields.assignment ||
            newFields.hasOwnProperty('goalToggle') ||
            newFields.period
        ) {
            const assignment = newFields.hasOwnProperty('assignment')
                ? newFields.assignment
                : dataCrud.assignment;
            const goalToggle = newFields.hasOwnProperty('goalToggle')
                ? newFields.goalToggle
                : dataCrud.goalToggle;

            if (assignment !== 'teamaggregated' && goalToggle) {
                const newPeriods = populatePeriods(newFields);
                if (Object.keys(newPeriods).length > 0) newFields['valuesDefined'] = newPeriods;
            } else if (assignment === 'teamaggregated' && !goalToggle) {
                if (newFields.valuesDefined && !isEmptyObject(newFields.valuesDefined)) {
                    // update of a value of the user
                    newFields.goal = populateGoal(newFields);
                } else {
                    const newUsers = populateUsers(newFields, isEdit);
                    if (Object.keys(newUsers).length > 0) newFields['valuesDefined'] = newUsers;
                }
            } else if (assignment === 'teamaggregated' && goalToggle) {
                const newValuesDefinedMatrix = populateUsersAndPeriods(newFields, isEdit);
                if (!isEmptyObject(newValuesDefinedMatrix))
                    newFields.valuesDefined = newValuesDefinedMatrix;
            }
        }

        return newFields;
    };

    getPeriodFooter = (period, start) => {
        if (!period || !start) return '';

        const beginMoment = moment(start, 'YYYY-MM-DD HH:mm:ss');
        switch (period) {
            case 'day':
                return '';
            case 'week':
                const dayWeek = beginMoment.isoWeekday() - 1;
                const dayOfWeek = periodLabels.week[dayWeek];
                return getLiteralWithParameters('label_custom_periodicity_starts', [
                    getLiteral(dayOfWeek),
                ]);
            case 'month':
                const dayOfTheMonth = beginMoment.format('Do');
                return getLiteralWithParameters('label_custom_periodicity_starts', [dayOfTheMonth]);
            default:
                const dayAndMonth = beginMoment.format('DD MMM');
                return getLiteralWithParameters('label_custom_periodicity_starts', [
                    `${dayAndMonth}`,
                ]);
        }
    };

    beforeList = () => {
        return new Promise((resolve, reject) => {
            return this.getKpiList()
                .then((kpis) => {
                    let defaultTypes = [];
                    const defaultValueList = [];
                    const getListPromises = kpis?.reduce((arr, kpi) => {
                        const entityConstant = EntitiesDb[kpi.entity.toLowerCase()];

                        if (entityConstant) {
                            const { configField } = findFieldConfiguration(
                                kpi.defaultType,
                                entityConstant,
                            );
                            if (
                                configField?.valueListName &&
                                !defaultValueList?.includes(configField.valueListName)
                            ) {
                                arr.push(
                                    Context.serverListManager.getList(configField.valueListName),
                                );
                                defaultValueList.push(configField?.valueListName);
                                defaultTypes.push(
                                    `${configField.id?.toLowerCase()}-${entityConstant?.dbName?.toLowerCase()}`,
                                );
                            }
                            return arr;
                        } else return arr;
                    }, []);

                    Promise.all(getListPromises)
                        .then((result) => {
                            const typesByDefaultType = result?.reduce((obj, current, index) => {
                                if (defaultTypes?.[index]) {
                                    obj[defaultTypes[index]] = current.reduce((obj2, item) => {
                                        obj2[item.value] = item;
                                        return obj2;
                                    }, {});
                                }
                                return obj;
                            }, {});

                            resolve(typesByDefaultType);
                        })
                        .catch(reject);
                })
                .catch(reject);
        });
    };

    beforeDetail = () =>
        new Promise((resolve, reject) => {
            const locale = Context.store.getState().config.userData.locale;

            Promise.all([this.getUsersDetailCrud(), this.getKpiList()])
                .then(([users, kpis]) => {
                    resolve({
                        extraData: {
                            locale,
                            dateFormat: 'yyyy-MM-dd HH:mm:ss',
                            kpiList: kpis,
                            users,
                        },
                    });
                })
                .catch(reject);
        });

    beforeCrud = (id) =>
        new Promise((resolve) => {
            if (id) {
                const locale = Context.store.getState().config.userData.locale;

                Promise.all([this.getKpiList()])
                    .then(([kpis]) => {
                        resolve({
                            extraData: {
                                locale,
                                dateFormat: 'yyyy-MM-dd HH:mm:ss',
                                kpiList: kpis,
                            },
                        });
                    })
                    .catch(console.error);
            } else {
                resolve();
            }
        });

    getKpiList = () =>
        new Promise((resolve, reject) => {
            Context.serverListManager
                .getList('kpis')
                .then((result) => {
                    resolve(result);
                })
                .catch(reject);
        });

    calculateGetEntityParameters = (id) => {
        // maybe we want the user again tomorrow
        // const owner = this.getDefaultOwner();
        // if (!owner || !owner.value) return;
        // return { goalId: id, userId: owner.value };
        return { goalId: id };
    };

    calculateDeleteEntityParameters = (id) => {
        return { goalId: id };
    };

    afterDetail = (data) =>
        new Promise((resolve) => {
            if (!data) resolve(data);
            data = this.prepareDataForCrud(data);
            resolve(data);
        });

    afterGetEntity = (data) =>
        new Promise((resolve) => {
            if (!data) {
                resolve(data);
                return;
            }
            data = this.prepareDataForCrud(data);
            if (data.assignment === 'teamaggregated') {
                this.getTeamGoals(data.id, true)
                    .then((teamGoals) => {
                        resolve({ ...data, teamGoals });
                    })
                    .catch(() => {
                        console.error('Error getting team goals');
                        resolve({ ...data, teamGoals: [] });
                    });
            } else {
                resolve(data);
            }
        });

    afterGetList = (data) => {
        if (data?.length > 0) {
            let newDragGroups = data.reduce((obj, current) => {
                if (!obj.groups) obj.groups = {};
                if (!obj?.groups?.[current?.creator]) obj.groups[current.creator] = [];
                obj.groups[current.creator].push(current.id);
                return obj;
            }, {});
            newDragGroups.groupsKey = 'creator';
            Context.store.dispatch(Context.actions.KpisActions.setDragGroups(newDragGroups));
        } else return;
    };

    prepareDataForCrud = (data) => {
        if (Array.isArray(data)) data = { ...data[0] };
        return data;
    };

    checkIfIsValidEntity = (data, id) => {
        if (!data) return false;
        if (data?.id !== parseInt(id, 10)) return false;
        return true;
    };

    getUsersDetailCrud = () =>
        new Promise((resolve, reject) => {
            // here we are sharing the same method that ContentNavigator of kpis
            // use to load the tree. We need to synchronize the call of the tree
            // due the possibility to load the CRUD before getting the user's tree
            const state = Context.store.getState();
            if (state?.users?.usersTree?.data) resolve();
            else {
                // we don't have the tree loaded, so wait for it
                Context.store
                    .dispatch(Context.actions.UsersActions.getUsersTree(null, true))
                    .then(resolve)
                    .catch(reject);
            }
        });

    beforeSave = (schema, data) => {
        // Sending robust data to backend
        if (!data) return data;

        const state = Context.store.getState();
        const kpisList = state?.serverList?.kpis?.data || [];
        const selectedKpi = kpisList.find((k) => k.value === data.kpi);

        if (data.owner && !isNaN(data.owner)) {
            data.owner = Number(data.owner);
        }

        // patch for backend. If we are editing and the kpi is not editable,
        // send empty string to conditionSimple and condition
        // otherwise, if we are creating, and the selected kpi is not editable,
        // do the same
        if (data.id) {
            if (!data.kpiEditable) {
                data.conditionSimple = {};
                data.condition = {};
            }

            delete data.kpiEditable;
        } else {
            if (selectedKpi && !selectedKpi.editable) {
                data.conditionSimple = {};
                data.condition = {};
            }

            // order
            const userGroup = state.kpis?.dragGroups?.groups[state.config.userData.idUsuario] || [];
            const entityList = state.entityList?.kpis?.data || [];
            data.order = 0;
            if (userGroup.length > 0 && entityList.length > 0) {
                const userLastId = userGroup[userGroup.length - 1];
                const entity = entityList.find((e) => e.id === userLastId);
                if (entity) {
                    data.order = entity.order + 1;
                }
            }
        }

        if (!data.condition) {
            data.condition = {};
        }

        if (!isEmptyObject(data.condition)) {
            const entity = selectedKpi?.entity || '';
            data.condition = transformDataConditions(
                { ...data.condition },
                {
                    fieldPrefix: entity,
                    dateFormat: 'yyyy-MM-dd HH:mm:ss', // format of dates in web5
                    locale: Context?.config?.userData?.locale,
                },
                true,
            );
            data.condition = mapToJsonLogic(data.condition);
            delete data.conditionSimple;
        }

        let usersValuesDefined;
        switch (data.assignment) {
            case 'user':
            case 'team':
                data.valuesDefined = this.transformPeriodsForServer(
                    !data.goalToggle ? data.goal : null,
                    data.goalToggle ? data.valuesDefined : null,
                    data.frequency,
                );
                break;
            case 'teamaggregated':
                if (!data.goalToggle) {
                    // simple one
                    const teamValuesDefined = this.transformPeriodsForServer(
                        data.goal,
                        null,
                        data.frequency,
                    );
                    usersValuesDefined = Object.keys(data.valuesDefined).reduce((obj, user) => {
                        user = parseInt(user, 10);
                        obj[user] = this.transformPeriodsForServer(
                            data.valuesDefined[user],
                            null,
                            data.frequency,
                        );
                        return obj;
                    }, {});
                    data.valuesDefined = teamValuesDefined;
                } else {
                    // non simple one, we have a matrix or similar structure
                    let teamValuesDefined = [];
                    usersValuesDefined = Object.keys(data.valuesDefined).reduce((obj, user) => {
                        const valuesDefined = this.transformPeriodsForServer(
                            null,
                            data.valuesDefined[user],
                            data.frequency,
                        );
                        if (teamValuesDefined.length === 0) {
                            teamValuesDefined = [...valuesDefined];
                        } else {
                            valuesDefined.forEach((value, index) => {
                                teamValuesDefined[index] =
                                    Number(teamValuesDefined[index]) + Number(value);
                            });
                        }
                        user = parseInt(user, 10);
                        obj[user] = valuesDefined;
                        return obj;
                    }, {});
                    data.valuesDefined = teamValuesDefined;
                }
                break;
            default:
                data.valuesDefined = [];
                break;
        }

        // delete unnecessary fields for backend, needed to print the crud
        delete data.goal;
        delete data.goalToggle;
        delete data.participants;
        delete data.isPeriodCustom;
        delete data.teamAssignment;
        delete data.type;
        delete data.activateCustomType;

        // delete custom key `teamGoals` to prevent errors, but keep a copy
        // for the case we need it to update correctly team goals
        let copyTeamGoals = [];
        if (data.teamGoals) {
            copyTeamGoals = [...data.teamGoals];
            delete data.teamGoals; // only present when editing, and not always
        }

        // for an individual goal we don't need more staff than return as an array
        // but for a team goal we need to duplicate it for all the users inside the team
        let finalData = [data];
        if (data.assignment === 'teamaggregated' && usersValuesDefined) {
            Object.keys(usersValuesDefined).map((idUser) => {
                // filtering the individual goals because if
                // every goal is 0 we shouldn't send to backend
                // Commented because https://fmanager.atlassian.net/browse/GOKU-2008
                //if (!usersValuesDefined[idUser].every((value) => value === 0)) {
                idUser = parseInt(idUser, 10);

                let teamId;
                const teamGoal = copyTeamGoals.find((goal) => goal.owner === idUser);
                teamId = teamGoal?.id || null;

                finalData.push({
                    ...data,
                    id: teamId,
                    assignment: 'user',
                    owner: idUser,
                    valuesDefined: usersValuesDefined[idUser],
                });
                //}
            });
        }
        return finalData;
    };

    transformPeriodsForServer = (goal, values, period) => {
        if (!goal && !values) return [];
        if (!period) return [];
        if (!periodLabels[period]) return [parseInt(goal, 10)];
        if (!values || (isEmptyObject(values) && goal)) {
            return [parseInt(goal, 10)];
        } else {
            return periodLabels[period].reduce((obj, current) => {
                if (values[current]) {
                    obj.push(parseInt(values[current], 10));
                } else obj.push(0); // default 0
                return obj;
            }, []);
        }
    };

    transformPeriodsFromServer = (values, period) => {
        if (!values) return {};
        if (!period) return {};
        if (!periodLabels[period]) return [...values];
        return periodLabels[period].reduce((obj, current, index) => {
            obj[current] = values?.[index]?.toString();
            return obj;
        }, {});
    };

    calculateWhenBegins = (period, date) => {
        // if we are editing a goal, we are going to receive from the model
        // the startDate, to allow us to select the correct day and/or month
        let beginYear = moment().format('YYYY');
        let beginDay = '01';
        let beginMonth = '01';
        if (date) {
            const beginMoment = moment(date, 'YYYY-MM-DD HH:mm:ss');
            beginYear = beginMoment.format('YYYY');
            beginMonth = beginMoment.format('MM');
            beginDay = beginMoment.format('DD');
        }
        switch (period) {
            case 'day':
                return moment().startOf('day').format('YYYY-MM-DD HH:mm:ss');
            case 'week':
                let momentWeek = moment(`${beginYear}-${beginMonth}-${beginDay}`, 'YYYY-MM-DD');
                if (!date) momentWeek = momentWeek.day('Monday');
                return momentWeek.startOf('day').format('YYYY-MM-DD HH:mm:ss');
            case 'month':
                // in this case we select the day of the month, and backend
                // wants to identify that the 30th is end of the month, of
                // any month, so we can get any date of, ie, April, because
                // this month have 30 days
                if (!date) beginMonth = '04';
                return moment(`${beginYear}-${beginMonth}-${beginDay}`, 'YYYY-MM-DD')
                    .startOf('day')
                    .format('YYYY-MM-DD HH:mm:ss');
            default:
                return moment(`${beginYear}-${beginMonth}-${beginDay}`, 'YYYY-MM-DD')
                    .startOf('day')
                    .format('YYYY-MM-DD HH:mm:ss');
        }
    };

    checkKpiTypesFields = (entityString, data) => {
        const store = Context.store.getState();
        const entityConstant = EntitiesDb[entityString.toLowerCase()];
        if (!entityConstant) return [...data];
        const { permission: permissions } = store.config;

        let finalData = [...data];
        finalData = finalData
            .filter((field) => {
                if (field.literalKey === '') return false;

                // accept currency fields - 02/03/2020 PM ask for this
                // multivalue is not supported
                if (
                    (field.type === 'list' && field.table && !field.fuzzySearchValueList) ||
                    field.type === 'currency'
                )
                    return true;

                return false;
            })
            .reduce((obj, field) => {
                if (entityConstant.entity === 'companies') {
                    if (
                        (field.name === 'nif' || field.realFieldName === 'nif') &&
                        !permissions.ShowVATNumber
                    ) {
                        return obj;
                    }
                    if (
                        (field.name === 'fax' || field.realFieldName === 'fax') &&
                        !permissions.ShowFAX
                    ) {
                        return obj;
                    }
                } else if (entityConstant.entity === 'opportunities') {
                    const addressFields = [
                        'direccion',
                        'direccion2',
                        'strpoblacion',
                        'strprovincia',
                        'cp',
                        'idcountry',
                    ];
                    if (
                        !permissions.opportunitiesGeolocation &&
                        (addressFields.includes(field.name) ||
                            addressFields.includes(field.realFieldName))
                    ) {
                        return obj;
                    }
                }

                obj.push({
                    label: field.isExtraField ? field.literalKey : getLiteral(field.literalKey),
                    value: field.realFieldName,
                    table: field.table || '',
                    type: field.type,
                    isExtra: field.isExtraField,
                    conditions: field.conditions,
                });
                return obj;
            }, []);
        return finalData;
    };

    manageResultSave = (schema, serverData, result) => {
        const goalData = result && result[0] ? result[0] : serverData[0];
        const goalId = String(goalData.id);

        if (goalData) {
            let newResult = { id: goalId };
            if (serverData[0].id) {
                // was an update
                newResult.Updated = { num: '1' };
            } else {
                // was an insert
                newResult.Insert = { num: '1' };
            }
            return newResult;
        }

        return result;
    };

    getTeamGoals = (id, showEmptyValues) => GoalsService.getTeamGoals(id, showEmptyValues);

    getTeamGoalsList = (id) => {
        const model = Context.entityManager.getModel(KPIS);
        return new Promise((resolve, reject) => {
            Promise.all([this.getTeamGoals(id), this.beforeList()])
                .then((result) => {
                    const data = result[0];
                    const beforeListData = result[1];
                    resolve(model.toList({ data, beforeListData, parentId: id }));
                })
                .catch(reject);
        });
    };

    updateOrder = (dragged, targetIndex) => {
        return new Promise((resolve, reject) => {
            const state = Context.store.getState();
            let data = state?.entityList?.[KPIS.entity]?.data || [];
            const updatedList = [...data];

            if (!dragged?.creator) {
                reject();
                return;
            }

            const creatorId = dragged.creator;
            const updatedDragGroup = [];
            const sortedListToUpdate = [];

            // Resort list
            const oldIndex = updatedList.findIndex((item) => item.id === dragged.id);
            const [updatedRow] = updatedList.splice(oldIndex, 1);
            updatedList.splice(targetIndex, 0, updatedRow);

            // Update order prop values in rows & drag group
            updatedList.forEach((item, index) => {
                item.order = index;
                item.rawData.order = index;
                sortedListToUpdate.push({ id: item.id, order: index });
                if (item.creator === creatorId) updatedDragGroup.push(item.id);
            });

            EntityCrudService.update(KPIS, sortedListToUpdate)
                .then(() => {
                    Context.store.dispatch(
                        Context.actions.KpisActions.updateDragGroups({
                            [creatorId]: updatedDragGroup,
                        }),
                    );
                    Context.store.dispatch(
                        Context.actions.EntityListActions.updateAllRowsLocally(KPIS, updatedList),
                    );
                    resolve();
                })
                .catch(reject);
        });
    };

    getUsersTree = (ownerId) => {
        if (!ownerId) return [];

        const state = Context.store.getState();
        const usersTree = ownerId && state?.users?.usersTree?.data?.[ownerId];

        const users = [];
        getUserFieldsFromTree(usersTree, users);

        return users;
    };

    preloadFilters() {
        const state = Context.store.getState();
        const selectedUser = state.users?.usersTree?.selectedUser || '';
        const filtersToLoad = [
            {
                entity: KPIS,
                filter: {
                    id: 'userId',
                    hideForCount: true,
                },
                values: selectedUser.id,
            },
        ];
        return filtersToLoad;
    }
}

const periodLabels = {
    week: [
        'common_monday',
        'common_tuesday',
        'common_wednesday',
        'common_thursday',
        'common_friday',
        'common_saturday',
        'common_sunday',
    ],
    month: [
        'common_january',
        'common_february',
        'common_march',
        'common_april',
        'common_may',
        'common_june',
        'common_july',
        'common_august',
        'common_september',
        'common_october',
        'common_november',
        'common_december',
    ],
    quarter: [
        'label_first_quarter',
        'label_second_quarter',
        'label_third_quarter',
        'label_fourth_quarter',
    ],
    fourMonthly: ['label_first_third', 'label_second_third', 'label_third_third'],
    semester: ['label_first_semester', 'label_second_semester'],
};

const getValuesDefinedFields = (data) => {
    if (!data) return [];

    let fields = [];
    const { assignment } = data;
    if ((assignment === 'user' || assignment === 'team') && data.goalToggle) {
        fields = getFieldsPeriodsLabels(data.period);
    } else if (assignment === 'teamaggregated') {
        const state = Context.store.getState();
        const ownerId = data.owner.value;
        const usersTree = ownerId && state?.users?.usersTree?.data?.[ownerId];
        if (!data.goalToggle) {
            getUserFieldsFromTree(usersTree, fields);
        } else {
            let fieldsUsers = [];
            getUserFieldsFromTree(usersTree, fieldsUsers);
            let fieldsPeriods = getFieldsPeriodsLabels(data.period);
            return {
                fieldsUsers,
                fieldsPeriods,
            };
        }
    }
    return fields;
};

const getFieldsPeriodsLabels = (frequency) => {
    if (!frequency || !periodLabels[frequency]) return [];
    return periodLabels[frequency].reduce((arr, current) => {
        const obj = {
            id: current,
            label: getLiteral(current),
            mandatory: true,
        };
        arr.push(obj);
        return arr;
    }, []);
};

const getUserFieldsFromTree = (baseData, fields) => {
    if (!baseData) return;

    fields.push({
        id: baseData.id,
        name: baseData.name,
        mandatory: true,
    });

    if (baseData.children && baseData.children.length > 0) {
        baseData.children.forEach((current) => {
            getUserFieldsFromTree(current, fields);
        });
    }
};

const getOwnerOptions = () => {
    const state = Context.store.getState();
    const usersTreeData = state.users.usersTree.data || null;
    const selectedOwnerId = state?.users?.usersTree?.selectedUser?.id;
    if (!usersTreeData || !selectedOwnerId) return [];

    let options = [];
    if (usersTreeData && Object.keys(usersTreeData).length > 0) {
        options = Object.keys(usersTreeData).map((key) => {
            return {
                value: usersTreeData[key].id,
                label: usersTreeData[key].name,
            };
        });
    }
    const sortedOptions = sort(options, 'label');
    return sortedOptions;
};

const getBeginningOptions = () => {
    const state = Context.store.getState();
    const activeCrud = getActiveCrud(state);
    const dataCrud = activeCrud?.data;

    let options = [];
    let onlyValue = '';
    let optionsTwo = [];
    let description = '';
    let defaultValue = null;
    let defaultValueTwo = null;

    if (!dataCrud?.period || dataCrud.period === 'day')
        return { options, optionsTwo, onlyValue, description, defaultValue, defaultValueTwo };

    if (dataCrud.period === 'week') {
        options = periodLabels[dataCrud.period].map((current, index) => {
            const value = getLiteral(current);
            return {
                label: value,
                value: index + 1,
            };
        });
    } else if (dataCrud.period === 'month') {
        onlyValue = getLiteral('label_beginning_in_month');
        for (let i = 1; i < 31; i++) {
            optionsTwo.push({ label: String(i), value: String(i) });
        }
        defaultValueTwo = { label: optionsTwo[0].label, value: optionsTwo[0].value };
    } else if (
        dataCrud.period === 'quarter' ||
        dataCrud.period === 'fourMonthly' ||
        dataCrud.period === 'semester' ||
        dataCrud.period === 'year'
    ) {
        options = periodLabels['month'].map((current, index) => {
            const value = getLiteral(current);
            return {
                label: value,
                value: index + 1,
            };
        });
        defaultValue = { label: options[0].label, value: options[0].value };
        const momentToday = moment().format('YYYY-MM-DD HH:mm:ss');
        const crudMoment = moment(dataCrud.begins || momentToday, 'YYYY-MM-DD HH:mm:ss');
        let daysInMonth = crudMoment.daysInMonth();
        // backend ask to suppress the 29th of february in the case we have it
        if (crudMoment.month() === 1 && daysInMonth === 29) {
            daysInMonth = 28;
        }
        for (let i = 1; i <= daysInMonth; i++) {
            optionsTwo.push({ label: String(i), value: String(i) });
        }

        defaultValueTwo = { label: optionsTwo[0].label, value: optionsTwo[0].value };
    }

    const labelsDescription = {
        week: 'helptext_beginning_in_week',
        month: 'helptext_beginning_in_month',
        quarter: 'helptext_beginning_in_quarter',
        semester: 'helptext_beginning_in_semester',
        year: 'helptext_beginning_in_year',
    };

    const labelsFooter = {
        week: 'label_beginning_in_week',
        month: 'label_beginning_in_month',
        quarter: 'label_beginning_in_quarter',
        semester: 'label_beginning_in_semester',
        year: 'label_beginning_in_year',
    };

    return {
        options,
        optionsTwo,
        onlyValue,
        description: getLiteral(labelsDescription[dataCrud.period]),
        defaultValue,
        defaultValueTwo,
        footer: getLiteral(labelsFooter[dataCrud.period]),
    };
};

const populateGoal = (newFields) => {
    const state = Context.store.getState();
    const activeCrud = getActiveCrud(state);
    const dataCrud = activeCrud?.data;

    let finalGoal = 0;
    if (
        dataCrud?.assignment === 'teamaggregated' &&
        newFields['valuesDefined'] &&
        Object.keys(newFields['valuesDefined']).length > 0 &&
        !dataCrud?.goalToggle &&
        !newFields.goalToggle
    ) {
        finalGoal = Object.keys(newFields['valuesDefined']).reduce((sum, current) => {
            const newValue = parseInt(newFields['valuesDefined'][current], 10);
            if (isNaN(newValue)) return sum;
            sum += newValue;
            return sum;
        }, 0);
    }
    if (!finalGoal) return;

    return String(finalGoal);
};

const populatePeriods = () => {
    const state = Context.store.getState();
    const activeCrud = getActiveCrud(state);
    const dataCrud = activeCrud?.data;
    if (
        !dataCrud ||
        !dataCrud.period ||
        !periodLabels[dataCrud.period] ||
        !dataCrud.goal ||
        dataCrud.goal === '0'
    )
        return {};

    const values = periodLabels[dataCrud.period].reduce((obj, current) => {
        obj = {
            ...obj,
            [current]: dataCrud.goal,
        };
        return obj;
    }, {});

    return values;
};

const populateUsers = (newFields, isEdition = false) => {
    const state = Context.store.getState();
    const activeCrud = getActiveCrud(state);
    const dataCrud = activeCrud?.data;
    const ownerId = dataCrud?.owner?.value || null;
    const usersTree = ownerId && state?.users?.usersTree?.data?.[ownerId];

    if (!dataCrud) return {};

    let fields = [];
    getUserFieldsFromTree(usersTree, fields);

    const usersWithoutGoal = {};
    if (isEdition) {
        const teamUserIdGoals =
            dataCrud?.teamGoals.reduce((acc, goal) => {
                acc.push(parseInt(goal.owner, 10));
                return acc;
            }, []) || [];

        if (teamUserIdGoals && teamUserIdGoals.length > 0) {
            fields = fields.filter((f) => {
                if (!teamUserIdGoals.includes(parseInt(f.id, 10))) {
                    usersWithoutGoal[f.id] = '0';
                    return false;
                }
                return true;
            });
        }
    }

    let goal = (newFields.hasOwnProperty('goal') ? newFields.goal : dataCrud.goal) || '';
    let value;
    let extra = 0;
    if (goal || goal === '0' || goal === 0) {
        const integerGoal = isNaN(goal) ? 0 : parseInt(goal, 10);
        const usersNum = fields.length;
        extra = integerGoal % usersNum;
        value = extra ? (integerGoal - extra) / usersNum : integerGoal / usersNum;
    } else {
        value = '';
    }

    let values = fields.reduce((obj, current, index) => {
        let finalValue = value;
        if (index === 0 && extra) finalValue += extra;
        obj = {
            ...obj,
            [current.id]: finalValue.toString(),
        };
        return obj;
    }, {});

    if (isEdition) {
        values = { ...values, ...usersWithoutGoal };
    }

    return values;
};

const populateUsersAndPeriods = (newFields, isEdition) => {
    const state = Context.store.getState();
    const activeCrud = getActiveCrud(state);
    const dataCrud = activeCrud?.data;
    const ownerId = dataCrud?.owner?.value || newFields?.owner?.value || null;
    const usersTree = ownerId && state?.users?.usersTree?.data?.[ownerId];

    let goal = dataCrud?.goal || '';

    let users = [];
    getUserFieldsFromTree(usersTree, users);

    const usersWithoutGoal = {};
    if (isEdition) {
        const teamUserIdGoals =
            dataCrud?.teamGoals.reduce((acc, goal) => {
                acc.push(parseInt(goal.owner, 10));
                return acc;
            }, []) || [];

        if (teamUserIdGoals && teamUserIdGoals.length > 0) {
            users = users.filter((f) => {
                if (!teamUserIdGoals.includes(parseInt(f.id, 10))) {
                    usersWithoutGoal[f.id] = true;
                    return false;
                }
                return true;
            });
        }
    }

    let value = 0;
    let extra = 0;
    if (goal || goal === '0' || goal === 0) {
        const integerGoal = isNaN(goal) ? 0 : parseInt(goal, 10);
        const usersNum = users.length;
        extra = integerGoal % usersNum;
        value = extra ? (integerGoal - extra) / usersNum : integerGoal / usersNum;
    } else {
        value = '';
    }

    const period = dataCrud?.period || newFields?.period || null;
    if (!period) return {};
    const periods = periodLabels[period].reduce((obj, current) => {
        obj = {
            ...obj,
            [current]: value,
        };
        return obj;
    }, {});

    let values = users.reduce((obj, user, index) => {
        if (index === 0 && extra) {
            let newPeriods = Object.keys(periods).reduce((obj, keyPeriod) => {
                obj[keyPeriod] = periods[keyPeriod] + extra;
                return obj;
            }, {});
            obj[user.id] = newPeriods;
        } else {
            obj[user.id] = periods;
        }
        return obj;
    }, {});

    if (isEdition) {
        const emptyPeriodObject = Object.keys(periods).reduce((obj, keyPeriod) => {
            obj[keyPeriod] = 0;
            return obj;
        }, {});
        Object.keys(usersWithoutGoal).map((key) => {
            usersWithoutGoal[key] = { ...emptyPeriodObject };
        });
        values = { ...values, ...usersWithoutGoal };
    }

    return values;
};
