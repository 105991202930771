import React from 'react';
import colors from 'constants/colors';

const Italic = ({ color = colors.utility.textPrimary }) => (
    <svg className="fm-close-icon" viewBox={`0 0 18 18`} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.523 3.188l-.148.476a.424.424 0 0 1-.086.02 17.14 17.14 0 0 1-.144.023.442.442 0 0 0-.09.02c-.506.093-.836.183-.992.27a.809.809 0 0 0-.352.386c-.14.354-.34.944-.598 1.77-.258.825-.517 1.69-.777 2.593-.26.904-.464 1.652-.61 2.246-.005.026-.09.367-.257 1.024-.167.656-.25 1.007-.25 1.054 0 .078.031.144.094.196.041.036.424.138 1.148.304l.258.047-.14.461c-.25-.016-.56-.031-.93-.047-.511-.02-.98-.031-1.407-.031-.693 0-1.476.026-2.351.078l.14-.46a1.385 1.385 0 0 1 .18-.035 3.27 3.27 0 0 0 .156-.028c.469-.089.774-.167.914-.235a.92.92 0 0 0 .352-.297c.12-.182.36-.885.719-2.109.015-.062.11-.396.285-1 .174-.604.282-.982.324-1.133l.355-1.254c.128-.445.223-.79.286-1.035.28-1.01.46-1.698.539-2.062a.694.694 0 0 0 .039-.22c0-.103-.045-.174-.133-.21-.052-.026-.257-.082-.613-.168-.357-.086-.6-.142-.73-.168l.124-.477c.844.063 1.607.094 2.29.094.937 0 1.739-.031 2.405-.094z"
            fill={color || '#335B70'}
            fillRule="nonzero"
        />
    </svg>
);

export default Italic;
