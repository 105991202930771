import React, { memo } from 'react';

const Conversations = memo((props) => {
    return (
        <svg
            width="100%"
            height="100%"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 168 144"
            {...props}
        >
            <mask
                id="a"
                width={104}
                height={124}
                x={32}
                y={10}
                maskUnits="userSpaceOnUse"
                style={{
                    maskType: 'luminance',
                }}
            >
                <path fill="#fff" d="M135.953 10.73H32.047v122.823h103.906V10.729Z" />
            </mask>
            <g mask="url(#a)">
                <path
                    fill="#474747"
                    d="M131.281 104.29a4.641 4.641 0 0 1-5.107-4.117 4.635 4.635 0 0 1 4.124-5.096 4.628 4.628 0 0 1 3.898 1.465 4.584 4.584 0 0 1 1.205 2.647 4.632 4.632 0 0 1-4.12 5.099v.002Z"
                />
                <path
                    fill="#474747"
                    d="m135.412 99.315-9.229.983.351 3.285 9.229-.983-.351-3.285Z"
                />
                <path
                    fill="#474747"
                    d="M131.646 107.695a4.64 4.64 0 0 1-5.108-4.116 4.636 4.636 0 0 1 4.124-5.097 4.632 4.632 0 0 1 3.899 1.465 4.597 4.597 0 0 1 1.205 2.648 4.635 4.635 0 0 1-4.12 5.098v.002Z"
                />
                <path
                    fill="#DDB89E"
                    d="M132.55 100.738a2.672 2.672 0 0 1-3.446 1.546 2.663 2.663 0 0 1 .222-5.055 2.67 2.67 0 0 1 3.395 2.41c.021.365-.033.737-.171 1.099Z"
                />
                <path
                    fill="#474747"
                    d="M132.602 100.877c-2.638-1.21-4.613-3.059-4.613-3.059l1.552-.989 2.818.188.754 3.53-.513.33h.002Z"
                />
                <path
                    fill="#DDB89E"
                    d="M101.579 122.127c-.775 0-1.55-.284-2.158-.857l-7.035-6.626a3.138 3.138 0 0 1-.13-4.441 3.151 3.151 0 0 1 4.45-.129l7.035 6.626a3.139 3.139 0 0 1 .129 4.441 3.138 3.138 0 0 1-2.289.986h-.002Z"
                />
                <path
                    fill="#C6C6C6"
                    d="m96.703 116.43-4.472 2.404-3.9-14.773 5.049-.195 3.323 12.564Z"
                />
                <path fill="#ABABAB" d="m88.33 104.062-.986.307 3.996 14.616.89-.151-3.9-14.772Z" />
                <path
                    fill="#DDB89E"
                    d="M97.254 115.87a.898.898 0 0 1-.865-.657c-.599-2.156-1.847-2.761-1.899-2.786a.89.89 0 0 1-.466-1.176.906.906 0 0 1 1.187-.468c.096.041.606.276 1.207.879.771.776 1.361 1.839 1.705 3.072a.899.899 0 0 1-.867 1.138l-.002-.002Z"
                />
                <path
                    fill="#00D639"
                    d="M119.124 54.191c0-24.004-19.493-43.462-43.539-43.462-23.986 0-43.538 19.518-43.538 43.462 0 24.004 19.492 43.462 43.538 43.462a43.409 43.409 0 0 0 27.733-9.956 6.89 6.89 0 0 1 9.251.44l4.069 4.062c.917.915 2.486.267 2.486-1.029V54.191Z"
                />
                <path
                    fill="#fff"
                    d="M59.332 61.616c7.093 0 12.844-5.74 12.844-12.821 0-7.064-5.767-12.822-12.844-12.822-7.093 0-12.844 5.74-12.844 12.822a12.75 12.75 0 0 0 2.942 8.166c.668.804.61 1.986-.129 2.723l-1.2 1.198a.43.43 0 0 0 .305.732h10.928l-.002.002Z"
                />
                <path
                    fill="#919191"
                    d="M51.16 79.431c-1.014 0-1.858-.15-2.585-.448-2.483-1.017-3.794-3.259-4.85-5.061-.296-.506-.714-1.219-1.017-1.61h-.056a3.591 3.591 0 0 1-.619-7.127c1.444-.253 3.586-.14 5.579 1.843.987.982 1.697 2.198 2.325 3.27.356.61.928 1.583 1.297 1.956 1.31-.01 4.934-.951 7.986-2.093a3.601 3.601 0 0 1 4.63 2.102 3.587 3.587 0 0 1-2.103 4.621c-.09.034-2.232.833-4.703 1.52-2.47.686-4.368 1.024-5.884 1.024v.004Z"
                />
                <path
                    fill="#5E5E5E"
                    d="M75.608 97.656a4.31 4.31 0 0 1-4.309-4.619l.757-10.462-17.615 5.561c-2.117.805-4.291.661-6.303-.418-1.396-.749-2.414-1.791-3.158-2.553a4.302 4.302 0 0 1-.34-5.622 4.312 4.312 0 0 1 3.289-1.691 6.638 6.638 0 0 1 3.25-1.391l24.979-3.62a4.322 4.322 0 0 1 3.56 1.11 4.307 4.307 0 0 1 1.366 3.465l-.04.554 11.625 9.3a4.304 4.304 0 0 1 .669 6.056 4.322 4.322 0 0 1-6.068.668L80.29 88.41l-.38 5.251a4.313 4.313 0 0 1-4.3 3.998l-.001-.002Z"
                />
                <path
                    fill="#5E5E5E"
                    d="M91.37 94.646c-.118 0-.21-.043-.326-.13l-.299-.22.049-.367c.048-.367.345-.484.473-.534.02-.008.037-.015.057-.02.27-.108 1.002-.406 2.045-.882 1.122-.511 2.694-1.362 2.71-1.371a.629.629 0 1 1 .602 1.104c-.066.036-1.625.881-2.789 1.411-.728.332-1.293.573-1.64.716-.23.095-.405.17-.543.217l-.036.015c-.124.04-.22.06-.3.06h-.002ZM76.305 97.65h-.86a.63.63 0 0 1-.629-.627.63.63 0 0 1 .63-.629h.86c1.075 0 2.16-.057 3.23-.172l1.07-.115a.629.629 0 1 1 .134 1.25l-1.072.115c-1.113.118-2.244.179-3.363.179Z"
                />
                <path
                    fill="#919191"
                    d="M48.637 83.263a4.163 4.163 0 0 0 4.167-4.16 4.163 4.163 0 0 0-4.167-4.16 4.163 4.163 0 0 0-4.166 4.16 4.163 4.163 0 0 0 4.166 4.16Z"
                />
                <path
                    fill="#919191"
                    d="M47.018 68.9c1.527 2.65 2.223 5.171-.431 6.695a5.55 5.55 0 0 1-7.572-2.039c-1.526-2.65.553-5.617 3.207-7.141s3.27-.165 4.796 2.486Z"
                />
                <path
                    fill="#919191"
                    d="m52.247 77.03-6.91 4.613-6.024-7.62 7.705-5.122 5.23 8.129Z"
                />
                <path
                    fill="#E84D4F"
                    d="M59.958 83.083a1.795 1.795 0 0 1-.797-3.404c2.052-1.012 2.374-1.434 2.716-1.88.327-.428.735-.962 1.712-1.612a1.795 1.795 0 1 1 1.996 2.987c-.477.319-.608.49-.847.803-.665.87-1.381 1.64-3.983 2.92a1.797 1.797 0 0 1-.795.188l-.002-.002ZM66.852 74.879c-.185 0-.374-.029-.559-.09-1.058-.345-1.694-.23-2.5-.084-.45.08-.957.174-1.523.174a1.797 1.797 0 0 1-1.798-1.795c0-.991.805-1.796 1.798-1.796.243 0 .54-.053.881-.114.986-.178 2.336-.424 4.259.204a1.795 1.795 0 0 1-.558 3.503v-.002Z"
                />
                <path
                    fill="#919191"
                    d="M50.449 87.659c-.926 0-1.71-.144-2.401-.425-1.045-.428-3.849-1.991-7.309-7.251-1.897-2.885-4.003-6.998-3.27-10.222.506-2.231 1.98-3.822 4.144-4.476 1.901-.574 3.91.5 4.484 2.399a3.587 3.587 0 0 1-1.491 4.055c.232.853.904 2.461 2.322 4.563 1.615 2.395 3.059 3.754 3.677 4.18 1.295-.056 4.903-1.181 7.917-2.486a3.6 3.6 0 0 1 4.731 1.863 3.587 3.587 0 0 1-1.865 4.723c-.09.04-2.235.968-4.708 1.783-2.638.87-4.641 1.294-6.231 1.294Z"
                />
                <path
                    fill="#E84D4F"
                    d="M40.516 61.616a1.771 1.771 0 0 0 1.77 1.768 1.77 1.77 0 0 0 1.718-2.183 1.748 1.748 0 0 0-.663-1 1.722 1.722 0 0 0-.782-.326 1.799 1.799 0 0 0-.738.04 1.777 1.777 0 0 0-1.305 1.705v-.004Z"
                />
                <path
                    fill="#474747"
                    d="M40.516 61.616c0 .053.002.11.007.163a5.194 5.194 0 0 0 1.742-1.476c.511.403 1.1.71 1.74.896a1.747 1.747 0 0 0-.664-1 1.726 1.726 0 0 0-.782-.326 1.799 1.799 0 0 0-.738.04 1.777 1.777 0 0 0-1.305 1.705v-.002ZM40.658 59.983a.352.352 0 1 0 0-.705.352.352 0 0 0 0 .705ZM43.506 59.892a.352.352 0 1 0 0-.704.352.352 0 0 0 0 .704Z"
                />
                <path
                    fill="#DDB89E"
                    d="M109.733 115.762a.9.9 0 0 1-.885-.741l-.676-3.84a.9.9 0 0 1 1.771-.31l.677 3.839a.898.898 0 0 1-.887 1.054v-.002Z"
                />
                <path
                    fill="#474747"
                    d="m111.742 112.63-18.683-4.463a.456.456 0 1 1 .22-.887l18.683 4.463-.22.887Z"
                />
                <path
                    fill="#DDB89E"
                    d="M108.636 120.656a3.14 3.14 0 0 1-2.125-.825c-1.047-.959-1.576-2.318-2.088-3.632-.236-.607-.617-1.587-.894-1.966a3.14 3.14 0 0 1-1.743-3.853 3.15 3.15 0 0 1 3.966-2.017c2.717.881 3.771 3.587 4.539 5.563.178.458.44 1.133.595 1.391a3.136 3.136 0 0 1 .072 4.321 3.144 3.144 0 0 1-2.32 1.018h-.002Z"
                />
                <path
                    fill="#777"
                    d="M135.952 108.254a6.301 6.301 0 0 0-9.531-2.873c-3.095 2.203-5.329 6.355-7.692 10.752-.395.735-.895 1.664-1.402 2.568-.243-.29-.477-.583-.689-.865a6.302 6.302 0 0 0-8.81-1.242 6.275 6.275 0 0 0-1.248 8.798c.145.194 1.48 1.951 3.168 3.679 1.18 1.206 4.383 4.482 8.39 4.482a7.35 7.35 0 0 0 2.377-.397 8.847 8.847 0 0 0 2.8-1.58c2.41-12.104 3.985-20.553 12.639-23.322h-.002Z"
                />
                <path
                    fill="#777"
                    d="M135.949 108.255a6.299 6.299 0 0 0-8.53-3.454c-2.618 1.214-3.729 3.582-5.017 6.323-1.083 2.303-2.847 6.062-4.926 7.423-.653-.052-2.494-.352-6.143-1.966-2.777-1.228-5.054-2.542-5.149-2.596-3.001-1.748-6.855-.739-8.608 2.255a6.28 6.28 0 0 0 2.258 8.596c.14.082 3.487 2.026 7.465 3.694 4.135 1.732 7.553 2.59 10.424 2.59 1.825 0 3.429-.348 4.852-1.039.4-.194.787-.404 1.163-.626 2.199-10.966 4.077-18.591 12.213-21.198l-.002-.002Z"
                />
            </g>
        </svg>
    );
});

export default Conversations;
