import React, { memo, useMemo } from 'react';
import { Text, Advice, useTheme } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';

import './emailImagesAdviceStyles.scss';

const EmailImagesAdvice = memo(
    ({
        text,
        loadingEmailWithImages,
        prevLoadingImagesRef,
        emailWithImages,
        emailHasImages: emailHasImagesProp,
        getEmailWithImages,
    }) => {
        const theme = useTheme();

        const emailHasImages = useMemo(() => {
            if (typeof emailHasImagesProp !== 'boolean') {
                if (!text) return false;
                let imgMatch = text.match(/<img.+?>/gm);
                if (!imgMatch?.length) return false;
                return true;
            }
            return emailHasImagesProp;
        }, [emailHasImagesProp, text]);

        const shouldRender = useMemo(() => {
            if (!emailWithImages && prevLoadingImagesRef) return false;
            if (text && emailHasImages && !loadingEmailWithImages && !emailWithImages) return true;
        }, [text, emailHasImages, loadingEmailWithImages, emailWithImages, prevLoadingImagesRef]);

        if (!shouldRender) return null;

        return (
            <Advice className="email-images__advice" type="info" showIcon showCollapse={true}>
                <span className="email-images__advice-text">
                    {getLiteral('label_not_downloaded_email_images')}
                </span>
                <Text
                    color={theme.colors.semantic.info600}
                    type="button"
                    className="email-images__advice-button"
                    onClick={getEmailWithImages}
                >
                    {getLiteral('action_download_email_images')}
                </Text>
            </Advice>
        );
    },
);

export default EmailImagesAdvice;
