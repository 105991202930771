import React, { memo, useMemo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation, Route, Routes } from 'react-router-dom';
import { FiltersProvider } from '@web/web5';

import { EntityCrudActions, EntityActions, EntityDetailActions } from 'actions';
import { ACTIVITIES } from 'constants/Entities';
import ContentLayout from 'containers/components/layouts/ContentLayout';
import DetailLayout from 'containers/components/layouts/ContentDetailLayout';
import FiltersLayout from 'containers/components/layouts/FiltersLayout';
import SyncPopover from 'containers/components/SyncPopover';
import EntityDetail from 'containers/components/EntityDetail';
import ActivitiesHeader from './ActivitiesHeader';
import Menu from './Menu';
import Content from './Content';
import ActivitiesCrud from './ActivitiesCrud';
import CONFIG from './EntityConfig';
import { getLiteral } from 'utils/getLiteral';
import { RouteOnMount } from 'utils/routes';
import { logEvent } from 'utils/tracking';
import { getEnableTimeLine } from './utils/timeline';
import { inConversations } from './utils/conversations';
import { ensureRoute } from '../../utils/routes';

const mapStateToProps = (state) => {
    return {
        login: state.config.login,
        genericPermission: state.config.permission.gestiones,
        permissions: state.config.permission.crud_permission[ACTIVITIES.permission],
        enableTimeLine: getEnableTimeLine(),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        openCrud: bindActionCreators(EntityCrudActions, dispatch).init,
        isPageForbidden: bindActionCreators(EntityActions, dispatch).isPageForbidden,
        openDetail: bindActionCreators(EntityDetailActions, dispatch).open,
        resetDetail: bindActionCreators(EntityDetailActions, dispatch).reset,
    };
};

const Main = memo(
    ({
        login,
        openCrud,
        permissions,
        isPageForbidden,
        openDetail,
        resetDetail,
        genericPermission,
        enableTimeLine,
    }) => {
        const isFirstRender = useRef(true);
        const location = useLocation();

        useEffect(() => {
            if (isFirstRender.current) {
                logEvent({
                    event: ACTIVITIES.trueName,
                    functionality: 'feed',
                });
                isFirstRender.current = false;
            }

            // Guard for /conversations routes
            if (!enableTimeLine && inConversations(location)) ensureRoute(ACTIVITIES.route);
        }, [enableTimeLine, location]);

        const filtersConfig = useMemo(() => {
            return {
                ...CONFIG(),
                hiddenFilters: [],
            };
        }, []);
        const syncPopoverProps = useMemo(
            () => ({
                entity: ACTIVITIES,
                popover: {
                    image: 'illustration_emailSyncBanner.svg',
                    title: getLiteral('label_empty_screen_activities_toast'),
                    text: getLiteral('label_empty_screen_activities_toast_desc'),
                    buttonTxt: getLiteral('action_sync_email'),
                },
            }),
            [],
        );

        if (!login) return null;
        if (isPageForbidden(['gestiones'])) return null;

        return (
            <FiltersProvider>
                <ContentLayout header={<ActivitiesHeader />}>
                    <DetailLayout
                        header={<Menu config={CONFIG()} canCreate={permissions?.create} />}
                    >
                        <FiltersLayout config={filtersConfig}>
                            <Content />
                            <SyncPopover {...syncPopoverProps} />
                        </FiltersLayout>
                    </DetailLayout>
                    <ActivitiesCrud />
                    <EntityDetail />
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <RouteOnMount
                                    title="page_title_activities"
                                    onMount={() => resetDetail()}
                                />
                            }
                        />
                        <Route
                            path="/new"
                            element={
                                <RouteOnMount
                                    title="title_add_activity"
                                    onMount={() => {
                                        if (!permissions || !permissions.create) return;
                                        openCrud({ entity: ACTIVITIES });
                                    }}
                                />
                            }
                        />
                        <Route
                            path="/new/:activityType"
                            element={
                                <RouteOnMount
                                    title="title_add_activity"
                                    onMount={(params) => {
                                        if (
                                            !permissions ||
                                            !permissions.create ||
                                            !params.activityType
                                        )
                                            return;
                                        openCrud({
                                            entity: ACTIVITIES,
                                            id: null,
                                            extraId: null,
                                            isBulkAction: false,
                                            isFromDetail: false,
                                            data: null,
                                            isFromWorkFlow: false,
                                            isModal: false,
                                            crudTab: null,
                                            extraInfo: { activityType: params.activityType },
                                        });
                                    }}
                                />
                            }
                        />
                        <Route
                            path="/:id/:activityType/edit"
                            element={
                                <RouteOnMount
                                    title="page_title_activities_edit"
                                    onMount={(params) => {
                                        if (
                                            !params.id ||
                                            !permissions ||
                                            !permissions.update ||
                                            !params.activityType
                                        )
                                            return;
                                        openCrud({
                                            entity: ACTIVITIES,
                                            id: params.id,
                                            extraId: null,
                                            isBulkAction: false,
                                            isFromDetail: false,
                                            data: null,
                                            isFromWorkFlow: false,
                                            isModal: false,
                                            crudTab: null,
                                            extraInfo: { activityType: params.activityType },
                                        });
                                    }}
                                />
                            }
                        />
                        <Route
                            path="/:id/:activityType/:tab?/:tabItemId?"
                            element={
                                <RouteOnMount
                                    title="page_title_activities"
                                    onMount={(params) => {
                                        if (!genericPermission || !params.id) return;
                                        openDetail(
                                            ACTIVITIES,
                                            params.id,
                                            false,
                                            { tab: params.tab, tabItemId: params.tabItemId },
                                            true,
                                            true,
                                            params,
                                        );
                                    }}
                                />
                            }
                        />
                    </Routes>
                </ContentLayout>
            </FiltersProvider>
        );
    },
);

Main.propTypes = {
    login: PropTypes.bool,
    openCrud: PropTypes.func,
    permissions: PropTypes.object,
    isPageForbidden: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
