import React, { memo, useMemo } from 'react';
import colors from 'constants/colors';
import { Icon } from 'hoi-poi-ui';

import { getBackendBoolean } from 'utils/fm';
import { getFileSvgIcon } from 'components/SvgIcons/getIcons/getFileSvgIcon';

import DragPreview from '../DragPreview';

const FolderDragPreview = memo(({ data, previewKey }) => {
    const icon = useMemo(() => {
        if (!data) return null;
        let finalSvg;
        if (getBackendBoolean(data.is_folder)) {
            finalSvg = <Icon name="folder" color={colors.primary.white} />;
        } else {
            const ExtensionSvg = getFileSvgIcon(data.format, getBackendBoolean(data.is_link));
            finalSvg = (
                <div className="fm-r-2">
                    <ExtensionSvg />
                </div>
            );
        }
        return finalSvg;
    }, [data]);

    const text = useMemo(() => {
        if (previewKey && data?.[previewKey]) {
            return data?.[previewKey];
        } else if (data?.name) {
            return data.name;
        } else return null;
    }, [data, previewKey]);

    return <DragPreview icon={icon} text={text} />;
});

export default FolderDragPreview;
