import React, { memo, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EntityCrud from 'containers/components/EntityCrud';
import { AGENDA, TASKS } from 'constants/Entities';
import { EntityListActions } from 'actions';
import { getLiteral } from 'utils/getLiteral';
import { publish } from 'lib/EventBuser';
import { REFRESH_TABLE } from 'lib/events';
import { PAGINATION_TABLE_AGENDA } from 'constants/Environment';
import { getActiveCrudName } from 'utils/crud';
import { successToast, errorToast } from 'utils/toast';

const mapStateToProps = (state) => {
    const crudPermissions =
        state.config.permission &&
        state.config.permission.crud_permission &&
        state.config.permission.crud_permission.AGENDA;
    const active = getActiveCrudName(state);
    const activeEntity = state.entityCrud?.[active] || null;
    const id = activeEntity?.id || null;
    return {
        isTask: activeEntity?.entity === TASKS.entity,
        crudPermissions,
        id,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        init: bindActionCreators(EntityListActions, dispatch).init,
    };
};

const AgendaCrud = memo(({ crudPermissions, id, init, isTask }) => {
    const onSave = useCallback((data) => {
        let toastLiteral = 'succes_entitycreatedsuccessfully';
        if (data && data.hasOwnProperty('Updated') && data.Updated.num === '1')
            toastLiteral = 'succes_entityupdatedsuccessfully';
        successToast({
            text: getLiteral(toastLiteral),
        });
    }, []);

    const onErrorSave = useCallback((data) => {
        let toastLiteral = 'label_failed_save';
        if (data && data.id) toastLiteral = 'label_failed_update';
        errorToast({
            text: getLiteral(toastLiteral),
        });
    }, []);

    const onDelete = useCallback(() => {
        init(AGENDA, true, PAGINATION_TABLE_AGENDA);
        publish(`${REFRESH_TABLE}_${TASKS.entity}`);
        successToast({
            text: getLiteral('succes_entitydeletedsuccessfully'),
        });
    }, [init]);

    const onErrorDelete = useCallback(() => {
        errorToast({
            text: getLiteral('label_failed_delete'),
        });
    }, []);

    const getNewEntityLiteral = useCallback(() => {
        let literalNewEntity = isTask
            ? getLiteral('action_create_task')
            : getLiteral('title_create_event');
        return literalNewEntity;
    }, [isTask]);

    const getEditEntityLiteral = useCallback(() => {
        let literalEditEntity = isTask ? getLiteral('action_task') : getLiteral('label_event');
        return literalEditEntity;
    }, [isTask]);

    const getDeleteEntityLiteral = useCallback(() => {
        let literalEditEntity = isTask
            ? getLiteral('title_delete_task')
            : getLiteral('action_delete_event');
        return literalEditEntity;
    }, [isTask]);

    const canDelete = useMemo(() => {
        return id && crudPermissions && crudPermissions.delete;
    }, [id, crudPermissions]);

    return (
        <EntityCrud
            entity={AGENDA}
            secondaryEntity={TASKS}
            width="600px"
            className="agenda-crud"
            getNewEntityLiteral={getNewEntityLiteral}
            getEditEntityLiteral={getEditEntityLiteral}
            onSave={onSave}
            onErrorSave={onErrorSave}
            onDelete={onDelete}
            onErrorDelete={onErrorDelete}
            hasCrudInDetail={false}
            canDelete={canDelete}
            deleteProps={{
                getDeleteEntityLiteral,
            }}
        />
    );
});

export default connect(mapStateToProps, mapDispatchToProps)(AgendaCrud);
