import React from 'react';
import colors from 'constants/colors';

//Has fixed height and width because shouldn't be editable
const ChevronLeft10px = ({ color = colors.actionMinor[500] }) => (
    <svg width="10" height="10" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.414 5l3.212 3.67a.5.5 0 1 1-.752.66L2.378 5.334a.499.499 0 0 1 0-.67L5.874.671a.5.5 0 1 1 .752.658L3.414 5z"
            fill={color || '#335B70'}
            fillRule="evenodd"
        />
    </svg>
);

export default ChevronLeft10px;
