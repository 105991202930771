import React from 'react';
import colors from 'constants/colors';

const Agenda = ({ color = colors.actionMinor[500] }) => (
    <svg viewBox={`0 0 18 18`} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5 13H3.5a.5.5 0 1 0 0 1H5v1.5a.5.5 0 1 0 1 0V14h6v1.5a.5.5 0 1 0 1 0V14h1.5a.5.5 0 1 0 0-1H13v-3h1.5a.5.5 0 1 0 0-1H13V7.5a.5.5 0 1 0-1 0V9H6V7.5a.5.5 0 0 0-1 0V9H3.5a.5.5 0 0 0 0 1H5v3zm1 0v-3h6v3H6zm12-9H0V1.6C0 .718.807 0 1.8 0h14.4c.993 0 1.8.718 1.8 1.6V4zM0 5h18v11.143C18 17.167 17.193 18 16.2 18H1.8C.807 18 0 17.167 0 16.143V5z"
            fill={color || '#335B70'}
            fillRule="evenodd"
        />
    </svg>
);

export default Agenda;
