import { memo } from 'react';

const Products = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={168} height={144} fill="none" {...props}>
        <g mask="url(#a)">
            <path
                fill="#777"
                d="M84.745 11.294H39.014A3.016 3.016 0 0 0 36 14.314V114.67a3.017 3.017 0 0 0 3.014 3.019h45.73a3.017 3.017 0 0 0 3.015-3.019V14.313a3.016 3.016 0 0 0-3.014-3.019Z"
            />
            <path fill="#5E5E5E" d="M83.605 109.722h-43.45V15.816l43.45 1.033v92.873Z" />
            <path
                fill="#474747"
                d="M61.88 115.119c.877 0 1.587-.712 1.587-1.591a1.589 1.589 0 1 0-3.175 0 1.59 1.59 0 0 0 1.588 1.591ZM51.578 31.32v3.623c0 2 1.619 3.622 3.616 3.622a3.619 3.619 0 0 0 3.615-3.622V31.32h-7.23Z"
            />
            <path
                fill="#C6C6C6"
                d="M58.809 31.32v3.623c0 2 1.618 3.622 3.615 3.622a3.618 3.618 0 0 0 3.616-3.622V31.32H58.809Z"
            />
            <path
                fill="#474747"
                d="M66.053 31.32v3.623c0 2 1.618 3.622 3.615 3.622a3.619 3.619 0 0 0 3.616-3.622V31.32H66.053Z"
            />
            <path
                fill="#C6C6C6"
                d="M73.285 31.32v3.623c0 2 1.619 3.622 3.616 3.622a3.619 3.619 0 0 0 3.615-3.622V31.32h-7.23Z"
            />
            <path fill="#ABABAB" d="M80.513 31.32h-7.23L61.86 15.817h7.23L80.513 31.32Z" />
            <path fill="#303030" d="M73.286 31.32h-7.23L54.631 15.817h7.231L73.286 31.32Z" />
            <path fill="#ABABAB" d="M66.052 31.32h-7.23L47.398 15.817h7.232L66.052 31.32Z" />
            <path fill="#303030" d="M58.81 31.32h-7.232L40.154 15.817h7.23L58.809 31.32Z" />
            <path fill="#ABABAB" d="M94.994 31.32h-7.23L76.339 15.817h7.23L94.993 31.32Z" />
            <path fill="#303030" d="M87.749 31.32h-7.23L69.096 15.817h7.23L87.748 31.32Z" />
            <path
                fill="#474747"
                d="M80.519 31.32v3.623c0 2 1.618 3.622 3.615 3.622a3.619 3.619 0 0 0 3.616-3.622V31.32H80.519Z"
            />
            <path
                fill="#C6C6C6"
                d="M87.748 31.32v3.623c0 2 1.619 3.622 3.616 3.622a3.619 3.619 0 0 0 3.616-3.622V31.32h-7.232Z"
            />
            <path
                fill="#474747"
                d="M67.817 58.775H56.361a.479.479 0 0 1-.478-.48V46.82c0-.265.213-.479.478-.479h11.456c.263 0 .478.214.478.48v11.475c0 .266-.213.48-.478.48Zm-10.978-.958h10.502V47.298H56.839v10.519ZM67.817 78.284H56.361a.478.478 0 0 1-.478-.479V66.33c0-.265.213-.479.478-.479h11.456c.263 0 .478.214.478.479v11.477a.477.477 0 0 1-.478.478Zm-10.978-.958h10.502V66.808H56.839v10.518ZM67.817 99.714H56.361a.479.479 0 0 1-.478-.48V87.759c0-.265.213-.479.478-.479h11.456c.263 0 .478.214.478.48v11.476c0 .265-.213.479-.478.479Zm-10.978-.958h10.502V88.237H56.839v10.519Z"
            />
            <path
                fill="#00D041"
                d="M60.254 56.864a1.4 1.4 0 0 1-1.067-.491l-3.893-4.543a1.408 1.408 0 0 1 .152-1.985 1.403 1.403 0 0 1 1.982.152l3.011 3.515 15.858-12.584a1.406 1.406 0 0 1 1.975.229 1.41 1.41 0 0 1-.229 1.978L61.127 56.56a1.398 1.398 0 0 1-.872.305l-.002-.001Z"
            />
            <path fill="#00D639" d="M105.279 127.567h-43.4l27.002 5.139h43.401l-27.003-5.139Z" />
            <path
                fill="#B26E3D"
                d="M69.93 60.083a1.52 1.52 0 0 1-1.038-.409l-3.324-3.1a1.527 1.527 0 0 1-.077-2.156 1.52 1.52 0 0 1 2.152-.077l3.323 3.1a1.527 1.527 0 0 1-1.037 2.642Z"
            />
            <path
                fill="#C6C6C6"
                d="M86.03 69.12c-2.35 0-4.774-.251-6.925-1.04-4.772-1.748-9.49-5.718-9.688-5.885a2.8 2.8 0 0 1-.329-3.944 2.79 2.79 0 0 1 3.938-.331c.041.034 4.171 3.501 7.998 4.902 3.219 1.179 8.062.598 11.269.214 1.336-.16 2.392-.288 3.225-.288a2.798 2.798 0 0 1 2.795 2.8c0 1.548-1.25 2.8-2.795 2.8-.499 0-1.5.121-2.561.247-1.939.233-4.39.526-6.925.526h-.002Z"
            />
            <path
                fill="#B26E3D"
                d="M98.294 79.267a1.52 1.52 0 0 1-1.245-.646c-.485-.69-.32-1.64.366-2.127l3.867-2.73a1.523 1.523 0 0 1 2.123.368c.485.687.32 1.64-.366 2.126l-3.867 2.73a1.519 1.519 0 0 1-.876.279h-.002Z"
            />
            <path
                fill="#C6C6C6"
                d="M102.161 77.81a2.79 2.79 0 0 1-2.411-1.381 2.804 2.804 0 0 1 .987-3.83 8.579 8.579 0 0 0 1.242-.912 12.346 12.346 0 0 0-1.237-1.6c-1.355-1.376-3.693-1.74-4.746-1.74a2.798 2.798 0 0 1-2.795-2.8c0-1.548 1.25-2.8 2.796-2.8.904 0 5.579.167 8.774 3.458l.039.041c1.462 1.57 3.167 3.885 2.987 5.997-.119 1.377-.929 2.668-2.479 3.946a14.49 14.49 0 0 1-1.745 1.232 2.77 2.77 0 0 1-1.412.386v.004Z"
            />
            <path
                fill="#4E4E4E"
                d="M103.219 130.805a3.728 3.728 0 0 1-3.691-3.265l-5.042-39.959-1.634 39.645a3.728 3.728 0 0 1-3.877 3.576 3.73 3.73 0 0 1-3.57-3.884l1.851-44.901c.205-3.727 3.284-6.648 7.012-6.648a7.006 7.006 0 0 1 6.925 5.869l.022.15 5.706 45.216a3.73 3.73 0 0 1-3.7 4.201h-.002Z"
            />
            <path
                fill="#C6C6C6"
                d="M94.943 78.626a3.352 3.352 0 0 0 3.349-3.355c0-1.853-1.5-3.355-3.349-3.355a3.352 3.352 0 0 0-3.35 3.355c0 1.853 1.5 3.355 3.35 3.355Z"
            />
            <path
                fill="#C6C6C6"
                d="M90.913 67.31A4.462 4.462 0 0 0 95.135 72a4.46 4.46 0 0 0 4.682-4.229c.127-2.462-1.915-2.02-4.373-2.148-2.458-.128-4.403-.778-4.53 1.687Z"
            />
            <path fill="#C6C6C6" d="m91.612 75.612 6.623.282 1.536-7.678-8.859-.465.7 7.86Z" />
            <path
                fill="#4E4E4E"
                d="M102.56 130.722a.698.698 0 0 1-.206-.029l-3.49-1.011a.75.75 0 0 1-.51-.926.745.745 0 0 1 .922-.51l3.49 1.012a.745.745 0 0 1-.206 1.464ZM88.465 130.722a.758.758 0 0 1-.193-.025l-3.784-1.012a.746.746 0 0 1 .384-1.442l3.784 1.011a.747.747 0 0 1-.19 1.468Z"
            />
            <path
                fill="#B26E3D"
                d="M97.58 76.753a.376.376 0 0 1-.143-.72l1.454-.597a.375.375 0 0 1 .487.203.376.376 0 0 1-.203.488l-1.454.596a.374.374 0 0 1-.14.028v.002ZM97.355 60.894a1.451 1.451 0 0 1-2.093.21 1.467 1.467 0 0 1-.235-1.95 1.448 1.448 0 0 1 1.214-.609c.301.005.604.107.86.305a1.46 1.46 0 0 1 .254 2.042v.002Z"
            />
            <path
                fill="#474747"
                d="M95.831 58.594c.088.217.255.613.697 1.235.213.3.566.779.868 1.015.109-.153.184-.319.226-.491a1.46 1.46 0 0 0-1.79-1.758v-.001Z"
            />
            <path
                fill="#474747"
                d="M100.307 59.824a1.609 1.609 0 0 1-2.327.227 1.626 1.626 0 0 1-.31-2.074 1.605 1.605 0 0 1 2.353-.417 1.619 1.619 0 0 1 .284 2.264Z"
            />
        </g>
    </svg>
);

export default memo(Products);
