import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';

import { WidgetLayout, WidgetHeaderLayout } from 'containers/components/widgets/Layouts';
import Content from './Content';
import Context from 'managers/Context';
import { getLiteral } from 'utils/getLiteral';
import { REPORTS } from 'constants/Entities';
import { METABASE_REPORT } from 'constants/Constants';
import { FORCEBI } from 'constants/Features';
import { WidgetContentLayout } from 'containers/components/widgets/Layouts';
import './styles.scss';

const propTypes = {
    hardcodedHeight: PropTypes.number,
    entityType: PropTypes.object,
    entityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const mapStateToProps = (state) => {
    return {
        analyticsPro: state.config?.permission?.analyticsPro,
        analyticsEnabled:
            state.config?.permission?.analyticsPro && state.config?.userData?.lookerAnalyticsViewer,
        hasFORCEBI: state.config.flagsmith?.[FORCEBI] || false,
    };
};

class ReportsWidget extends PureComponent {
    state = {
        data: [],
        loading: true,
        error: false,
        firstLoading: true,
    };

    componentDidMount() {
        const { entityType, entityId, analyticsEnabled } = this.props;
        const { firstLoading } = this.state;

        if (!entityId || !entityType) {
            this.setState({ firstLoading: true });
        } else if (firstLoading && entityType && entityId) {
            this.setState({ firstLoading: false });
            Context.entityManager.getEntitiesManager(REPORTS).getReports2Entity(
                entityType,
                entityId,
                (reports) => {
                    const finalReports = reports.filter((current) => {
                        if (!analyticsEnabled && current.idProvider === METABASE_REPORT) {
                            return false;
                        }
                        return true;
                    });

                    this.setState({
                        data: finalReports,
                        loading: false,
                        firstLoading: false,
                        error: false,
                    });
                },
                (error) => {
                    this.setState({ loading: false, firstLoading: false, error: error });
                },
            );
        }
    }

    componentDidUpdate(prevProps) {
        const { entityType, entityId, analyticsEnabled } = this.props;
        const { firstLoading } = this.state;

        if (!entityId || !entityType || entityId !== prevProps.entityId) {
            this.setState({ firstLoading: true });
        } else if (firstLoading && entityType && entityId) {
            this.setState({ firstLoading: false });
            Context.entityManager.getEntitiesManager(REPORTS).getReports2Entity(
                entityType,
                entityId,
                (reports) => {
                    const finalReports = reports.filter((current) => {
                        if (!analyticsEnabled && current.idProvider === METABASE_REPORT) {
                            return false;
                        }
                        return true;
                    });

                    this.setState({
                        data: finalReports,
                        loading: false,
                        error: false,
                        firstLoading: false,
                    });
                },
                (error) => {
                    this.setState({ loading: false, error: error, firstLoading: false });
                },
            );
        }
    }

    render() {
        let { hardcodedHeight, entityType, entityId, entity, type } = this.props;
        let { data, loading, error, firstLoading } = this.state;
        const widgetTabs = [
            {
                title: getLiteral('label_reports'),
                count: data.length || '0',
            },
        ];

        const rootClassName = classnames('fm-widget-reports', {
            'fm-widget-reports--bottom-tabs': type === 'bottomTabs',
        });

        return (
            <WidgetLayout
                loading={firstLoading}
                data={entityType && entityId}
                className={rootClassName}
            >
                <WidgetHeaderLayout className="fm-widget-reports__header" content={widgetTabs} />
                <WidgetContentLayout>
                    <div className="fm-widget-reports__content" style={{ height: hardcodedHeight }}>
                        <Content
                            entity={entityType}
                            idEntity={entityId}
                            entityName={entity?.name}
                            loading={loading}
                            error={error}
                            data={data}
                        />
                    </div>
                </WidgetContentLayout>
            </WidgetLayout>
        );
    }
}

ReportsWidget.propTypes = propTypes;

export default connect(mapStateToProps)(ReportsWidget);
