import { PATH_IMAGE } from './Environment';

export const CHECKIN_ICON = `${PATH_IMAGE}icon-map-checkin.svg`;
export const COMPANY_ICON = `${PATH_IMAGE}icon-map-company.svg`;
export const OPPORTUNITY_ICON = `${PATH_IMAGE}icon-map-opportunity.svg`;
export const COMPANY_CHECKIN_ICON = `${PATH_IMAGE}icon-map-company-checkin.svg`;
export const OPPORTUNITY_CHECKIN_ICON = `${PATH_IMAGE}icon-map-opportunity-checkin.svg`;
export const FAST_CHECKIN_ICON = `${PATH_IMAGE}icon-map-fast-checkin.svg`;
export const DOCUMENT_PLACEHOLDER_ICON = `${PATH_IMAGE}generic_doc.png`;
export const MAP_PIN = `${PATH_IMAGE}img-pin-on.svg`;
export const EVENT_ICON_PIN = `${PATH_IMAGE}icon-map-event.svg`;

// DYNAMIC SVG's

export const CHECKIN_SVG_TEXT = (index, color = '#007e45', strokeColor = '#fff') => {
    const hasTwoDigits = String(index)?.length > 1;
    return (
        'data:image/svg+xml,' +
        encodeURIComponent(
            `<svg width="44" height="68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#a)">
        <path
            d="m26.685 42.509-.281.064-.204.204-3.861 3.863-4.597-3.917-.187-.16-.24-.054a21.002 21.002 0 0 1-11.794-7.464A20.933 20.933 0 0 1 1 22.017c0-5.805 2.35-11.059 6.153-14.863A20.923 20.923 0 0 1 22 1c5.798 0 11.046 2.35 14.847 6.154A20.956 20.956 0 0 1 43 22.017c0 4.922-1.69 9.446-4.52 13.028a21.002 21.002 0 0 1-11.795 7.464Z"
            fill="${color}" stroke="${strokeColor}" stroke-width="2" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M22 12C18.13 12 15 15.13 15 19C15 24.25 22 32 22 32C22 32 29 24.25 29 19C29 15.13 25.87 12 22 12ZM22 21.5C20.62 21.5 19.5 20.38 19.5 19C19.5 17.62 20.62 16.5 22 16.5C23.38 16.5 24.5 17.62 24.5 19C24.5 20.38 23.38 21.5 22 21.5Z"
            fill="white" />
    </g>
    <rect x="14" y="52" width="16" height="16" rx="8" fill="#000000" /><text x="${hasTwoDigits ? 16 : 19}" y="63"
        fill="#ffffffe6"
        style="font:10px &quot;Sage Text&quot;,&quot;Helvetica Neue&quot;,Helvetica,Arial,sans-serif">${index}</text>
    <defs>
        <clipPath id="a">
            <path fill="#fff" d="M0 0h44v48H0z" />
        </clipPath>
    </defs>
</svg>`,
        )
    );
};

export const COMPANY_CHECKIN_SVG_TEXT = (index, color = '#007e45', strokeColor = '#fff') => {
    const hasTwoDigits = String(index)?.length > 1;
    return (
        'data:image/svg+xml,' +
        encodeURIComponent(
            `<svg width="44" height="68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#a)">
        <path
            d="m26.685 42.509-.281.064-.204.204-3.861 3.863-4.597-3.917-.187-.16-.24-.054a21.002 21.002 0 0 1-11.794-7.464A20.933 20.933 0 0 1 1 22.017c0-5.805 2.35-11.059 6.153-14.863A20.923 20.923 0 0 1 22 1c5.798 0 11.046 2.35 14.847 6.154A20.956 20.956 0 0 1 43 22.017c0 4.922-1.69 9.446-4.52 13.028a21.002 21.002 0 0 1-11.795 7.464Z"
            fill="${color}" stroke="${strokeColor}" stroke-width="2" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M25.71 14.605a.438.438 0 0 0-.442-.434h-1.77a.438.438 0 0 0-.442.434v1.737c0 .24.198.434.443.434h1.769a.438.438 0 0 0 .442-.434v-1.737Zm0 4.342a.438.438 0 0 0-.442-.434h-1.77a.438.438 0 0 0-.442.434v1.672a6.388 6.388 0 0 0-1.78 2.572.44.44 0 0 0-.43-.337h-1.77a.438.438 0 0 0-.442.434v1.737c0 .24.197.434.442.434h1.77l.036-.001c.007.563.096 1.142.258 1.738h-2.948a.877.877 0 0 0-.885.868v3.474c0 .24-.198.434-.442.434h-3.096c-.976 0-1.769-.779-1.769-1.737V13.737c0-.958.793-1.737 1.769-1.737h12.383c.976 0 1.77.779 1.77 1.737v5.285a6.685 6.685 0 0 0-2.212.191v-.266Zm-2.135 13.025a23.853 23.853 0 0 1-.96-1.481v1.047c0 .24.197.434.441.434h.519Zm-2.288-17.367a.438.438 0 0 0-.442-.434h-1.769a.438.438 0 0 0-.442.434v1.737c0 .24.197.434.442.434h1.77a.438.438 0 0 0 .441-.434v-1.737Zm0 4.342a.438.438 0 0 0-.442-.434h-1.769a.438.438 0 0 0-.442.434v1.736c0 .24.197.435.442.435h1.77a.438.438 0 0 0 .441-.435v-1.736Zm-4.864-4.776c.244 0 .442.194.442.434v1.737c0 .24-.198.434-.442.434h-1.77a.438.438 0 0 1-.442-.434v-1.737c0-.24.198-.434.442-.434h1.77Zm0 4.342c.244 0 .442.194.442.434v1.736c0 .24-.198.435-.442.435h-1.77a.438.438 0 0 1-.442-.435v-1.736c0-.24.198-.434.442-.434h1.77Zm0 4.341c.244 0 .442.195.442.434v1.737c0 .24-.198.434-.442.434h-1.77a.438.438 0 0 1-.442-.434V23.29c0-.24.198-.435.442-.435h1.77ZM21.73 28.5v3.039c0 .24-.198.434-.443.434h-2.653a.438.438 0 0 1-.442-.434v-3.04c0-.24.197-.434.442-.434h2.653c.245 0 .443.195.443.434Z"
            fill="#fff" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M27.5 21c-2.488 0-4.5 2.035-4.5 4.55 0 3.412 4.5 8.45 4.5 8.45s4.5-5.038 4.5-8.45c0-2.515-2.012-4.55-4.5-4.55Zm-.15 5.881c-.745 0-1.35-.624-1.35-1.393s.605-1.393 1.35-1.393c.745 0 1.35.624 1.35 1.393s-.605 1.393-1.35 1.393Z"
            fill="#fff" />
    </g>
    <rect x="14" y="52" width="16" height="16" rx="8" fill="#000000" /><text x="${hasTwoDigits ? 16 : 19}" y="63"
        fill="#ffffffe6"
        style="font:10px &quot;Sage Text&quot;,&quot;Helvetica Neue&quot;,Helvetica,Arial,sans-serif">${index}</text>
    <defs>
        <clipPath id="a">
            <path fill="#fff" d="M0 0h44v48H0z" />
        </clipPath>
    </defs>
</svg>`,
        )
    );
};

export const OPPORTUNITY_CHECKIN_SVG_TEXT = (index, color = '#007e45', strokeColor = '#fff') => {
    const hasTwoDigits = String(index)?.length > 1;
    return (
        'data:image/svg+xml,' +
        encodeURIComponent(
            `<svg width="44" height="68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#a)">
        <path
            d="m26.685 42.509-.281.064-.204.204-3.861 3.863-4.597-3.917-.187-.16-.24-.054a21.002 21.002 0 0 1-11.794-7.464A20.933 20.933 0 0 1 1 22.017c0-5.805 2.35-11.059 6.153-14.863A20.923 20.923 0 0 1 22 1c5.798 0 11.046 2.35 14.847 6.154A20.956 20.956 0 0 1 43 22.017c0 4.922-1.69 9.446-4.52 13.028a21.002 21.002 0 0 1-11.795 7.464Z"
            fill="${color}" stroke="${strokeColor}" stroke-width="2" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M18 16H14C12.9 16 12 16.9 12 18V21C12 22.11 12.89 23 14 23H17V21H19V23H21.351C22.3142 20.6504 24.6549 19.0001 27.3819 19.0001C29.186 19.0001 30.8211 19.7224 32 20.8917V18C32 16.9 31.1 16 30 16H26V15C26 13.89 25.11 13 24 13H20C18.89 13 18 13.89 18 15V16ZM21.0359 24C20.935 24.4477 20.8819 24.9132 20.8819 25.3914C20.8819 26.4696 21.1867 27.6034 21.7303 28.809C22.053 29.5249 22.4567 30.2569 22.927 31H15C13.89 31 13 30.11 13 29L13.01 24H17V26H19V24H21.0359ZM20 16V15H24V16H20Z"
            fill="white" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M27.5 21C25.0121 21 23 23.0345 23 25.55C23 28.9625 27.5 34 27.5 34C27.5 34 32 28.9625 32 25.55C32 23.0345 29.9879 21 27.5 21ZM27.35 26.881C26.6048 26.881 26 26.257 26 25.4881C26 24.7192 26.6048 24.0952 27.35 24.0952C28.0952 24.0952 28.7 24.7192 28.7 25.4881C28.7 26.257 28.0952 26.881 27.35 26.881Z"
            fill="white" />
    </g>
    <rect x="14" y="52" width="16" height="16" rx="8" fill="#000000" /><text x="${hasTwoDigits ? 16 : 19}" y="63"
        fill="#ffffffe6"
        style="font:10px &quot;Sage Text&quot;,&quot;Helvetica Neue&quot;,Helvetica,Arial,sans-serif">${index}</text>
    <defs>
        <clipPath id="a">
            <path fill="#fff" d="M0 0h44v48H0z" />
        </clipPath>
    </defs>
</svg>`,
        )
    );
};

export const FAST_CHECKIN_SVG_TEXT = (index, color = '#007e45', strokeColor = '#fff') => {
    const hasTwoDigits = String(index)?.length > 1;
    return (
        'data:image/svg+xml,' +
        encodeURIComponent(
            `<svg width="44" height="68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#a)">
        <path
            d="m26.685 42.509-.281.064-.204.204-3.861 3.863-4.597-3.917-.187-.16-.24-.054a21.002 21.002 0 0 1-11.794-7.464A20.933 20.933 0 0 1 1 22.017c0-5.805 2.35-11.059 6.153-14.863A20.923 20.923 0 0 1 22 1c5.798 0 11.046 2.35 14.847 6.154A20.956 20.956 0 0 1 43 22.017c0 4.922-1.69 9.446-4.52 13.028a21.002 21.002 0 0 1-11.795 7.464Z"
            fill="${color}" stroke="${strokeColor}" stroke-width="2" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M18.9982 31.4074C18.9982 31.4074 16.7514 21.744 19.1694 17.3819C20.9518 14.1663 24.994 13.0072 28.2096 14.7896C31.4251 16.572 32.5842 20.6143 30.8018 23.8298C28.3838 28.192 18.9982 31.4074 18.9982 31.4074ZM22.5295 20.89C22.5518 22.1712 23.6098 23.1929 24.8911 23.1706C26.1723 23.1482 27.194 22.0902 27.1716 20.809C27.1493 19.5277 26.0913 18.5061 24.81 18.5284C23.5288 18.5508 22.5071 19.6088 22.5295 20.89ZM17.8743 16.0023C18.3223 15.237 18.8726 14.5677 19.4979 14.0023H12V16.0023H17.8743ZM16.5921 20.0023C16.697 19.3034 16.8393 18.6314 17.0262 18.0023H12V20.0023H16.5921ZM16.3905 22.0023C16.3527 22.663 16.3395 23.3335 16.3456 24.0023H12V22.0023H16.3905Z"
            fill="white" />
    </g>
    <rect x="14" y="52" width="16" height="16" rx="8" fill="#000000" /><text x="${hasTwoDigits ? 16 : 19}" y="63"
        fill="#ffffffe6"
        style="font:10px &quot;Sage Text&quot;,&quot;Helvetica Neue&quot;,Helvetica,Arial,sans-serif">${index}</text>
    <defs>
        <clipPath id="a">
            <path fill="#fff" d="M0 0h44v48H0z" />
        </clipPath>
    </defs>
</svg>`,
        )
    );
};

export const EVENT_SVG_TEXT = (index, color = '#007e45', strokeColor = '#fff') => {
    const hasTwoDigits = String(index)?.length > 1;
    return (
        'data:image/svg+xml,' +
        encodeURIComponent(
            `<svg width="44" height="68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#a)">
        <path
            d="m26.685 42.509-.281.064-.204.204-3.861 3.863-4.597-3.917-.187-.16-.24-.054a21.002 21.002 0 0 1-11.794-7.464A20.933 20.933 0 0 1 1 22.017c0-5.805 2.35-11.059 6.153-14.863A20.923 20.923 0 0 1 22 1c5.798 0 11.046 2.35 14.847 6.154A20.956 20.956 0 0 1 43 22.017c0 4.922-1.69 9.446-4.52 13.028a21.002 21.002 0 0 1-11.795 7.464Z"
            fill="${color}" stroke="${strokeColor}" stroke-width="2" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M27 22h-5v5h5v-5Zm-1-11v2h-8v-2h-2v2h-1c-1.11 0-1.99.9-1.99 2L13 29a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V15c0-1.1-.9-2-2-2h-1v-2h-2Zm3 18H15V18h14v11Z"
            fill="#fff" />
    </g>
    <rect x="14" y="52" width="16" height="16" rx="8" fill="#000000" /><text x="${hasTwoDigits ? 16 : 19}" y="63"
        fill="#ffffffe6"
        style="font:10px &quot;Sage Text&quot;,&quot;Helvetica Neue&quot;,Helvetica,Arial,sans-serif">${index}</text>
    <defs>
        <clipPath id="a">
            <path fill="#fff" d="M0 0h44v48H0z" />
        </clipPath>
    </defs>
</svg>`,
        )
    );
};
