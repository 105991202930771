import React, { memo, useEffect, useMemo, useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ServerListActions, EntityModalActions, SettingsActions, ConfigActions } from 'actions';
import Base from 'components/Fields/Base';
import Select from 'components/Select';
import { Switch, useTheme } from 'hoi-poi-ui';
import { Link, Text, Icon, Avatar, Loader, Tooltip, Button, Advice } from 'hoi-poi-ui';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { successToast } from 'utils/toast';
import { VideoCallsService } from 'services';
import moment from 'moment';
import copyToClipboard from 'utils/copy';
import { logEvent } from 'utils/tracking';

import './styles.scss';
import { ACTIVITIES, AGENDA } from 'constants/Entities';

const mapDispatchToProps = (dispatch) => {
    return {
        getList: bindActionCreators(ServerListActions, dispatch).getList,
        cancelModal: bindActionCreators(EntityModalActions, dispatch).cancel,
        modalInit: bindActionCreators(EntityModalActions, dispatch).init,
        getVideoCallProvidersInformation: bindActionCreators(SettingsActions, dispatch)
            .getVideoCallProvidersInformation,
        updateSpecificPermissions: bindActionCreators(ConfigActions, dispatch)
            .updateSpecificPermissions,
    };
};

const TEAMS_ID = 28;

const VideocallField = memo(
    ({
        changeField,
        data,
        errors,
        field,
        fisrtErrorField,
        getError,
        getList,
        cancelModal,
        modalInit,
        getVideoCallProvidersInformation,
        updateSpecificPermissions,
    }) => {
        const [isLoadingRoom, setIsLoadingRoom] = useState(false);
        const [isCopied, setIsCopied] = useState(false);
        const [failedRoomData, setFailedRoomData] = useState(false);
        const [isLoadingOptions, setIsLoadingOptions] = useState(false);
        const [options, setOptions] = useState([]);
        const [providers, setProviders] = useState([]);
        const [unfilteredOptions, setUnfilteredOptions] = useState([]);
        const [isLoadingJoin, setIsLoadingJoin] = useState(false);
        const [isRoomCreation, setIsRoomCreation] = useState(false);
        const areOptionsLoaded = useRef(false);
        const [loadingActivation, setLoadingActivation] = useState(false);
        const [disableActivationButton, setDisableActivationButton] = useState(false);
        const popUpWindow = useRef({});
        const intervalWindow = useRef({});
        const theme = useTheme();

        const {
            id,
            label,
            mandatory,
            readOnly,
            description,
            labelMode,
            isBulkAction,
            inputAttrs,
            disabled,
            isClearable,
        } = field;

        useEffect(() => {
            if (!areOptionsLoaded.current) {
                setIsLoadingOptions(true);
                Promise.all([
                    getList(inputAttrs.list),
                    VideoCallsService.getVideoCallProvidersInformation(),
                ])
                    .then(([list, providers]) => {
                        setUnfilteredOptions(list);
                        setProviders(providers);
                        if (list.length && providers.length) {
                            const providersMap = providers.reduce(function (obj, provider) {
                                obj[provider.providerId] = provider;
                                return obj;
                            }, {});

                            const filteredList = list.filter(function (element) {
                                return (
                                    element.extprovidercfgid &&
                                    providersMap[element.extprovidercfgid] &&
                                    providersMap[element.extprovidercfgid].isActive
                                );
                            });
                            setOptions(filteredList);
                        } else if (list.length) {
                            setOptions(list);
                        }
                        setIsLoadingOptions(false);
                    })
                    .catch(() => {
                        setIsLoadingOptions(false);
                        console.error('error loading options');
                    });
            }
        }, [getList, inputAttrs]);

        const selectValue = useMemo(() => {
            return data[id] || null;
        }, [data, id]);

        const getMeetingProviderValues = useCallback(
            (providerId) => {
                if (!providerId) return null;

                let iconUrl;
                let title;

                const actualProvider = unfilteredOptions.filter(
                    (current) => current.value === providerId,
                );

                if (!actualProvider || actualProvider.length === 0 || !actualProvider[0]?.label) {
                    return null;
                }

                switch (actualProvider[0].label.toUpperCase()) {
                    case 'ZOOM':
                        iconUrl = `/img/icons/ic_zoom_cam_white.svg`;
                        title = getLiteral('label_online_meeting_details');
                        if (title && title.indexOf('%@') > -1) {
                            title = title.replace('%@', getLiteral('label_zoom'));
                        }
                        return {
                            name: 'zoom',
                            title,
                            iconUrl,
                            providerId,
                        };
                    case 'TEAMS':
                        iconUrl = `/img/icons/ic_microsoft_teams_white.svg`;
                        title = getLiteral('label_online_meeting_details');
                        if (title && title.indexOf('%@') > -1) {
                            title = title.replace('%@', getLiteral('label_microsoft_teams'));
                        }
                        return {
                            name: 'teams',
                            title,
                            iconUrl,
                            providerId,
                        };
                    default:
                        return null;
                }
            },
            [unfilteredOptions],
        );

        const handleOnChange = useCallback(
            (value) => {
                if (!data.fini) return;
                let startDate = moment(data.fini).utc().format();
                startDate = startDate.slice(0, -1);
                changeField('videoCallProviderId')(value.value);
                setFailedRoomData(false);

                setIsLoadingRoom(true);
                VideoCallsService.createMeetingRoom(value.value, startDate)
                    .then((data) => {
                        setIsLoadingRoom(false);
                        setIsRoomCreation(true);
                        changeField('videoCallMeetingDetails')(data);
                    })
                    .catch(() => {
                        setIsLoadingRoom(false);
                        setFailedRoomData(true);
                    });
            },
            [changeField, data.fini],
        );

        const handleOnRemoveRoom = useCallback(() => {
            setIsRoomCreation(false);
            changeField('videoCallProviderId')(null);
            changeField('videoCallMeetingDetails')(null);
        }, [changeField]);

        const handleCopyToClipboard = useCallback(() => {
            if (!data?.videoCallMeetingDetails?.joinUrl) return;
            copyToClipboard(data.videoCallMeetingDetails.joinUrl);
            successToast({
                text: getLiteral('label_copied_to_clipboard'),
            });
            setIsCopied(true);
        }, [data]);

        const handleOnClickJoinMeeting = useCallback(() => {
            if (isRoomCreation) return;
            if (data.videoCallProviderId && data.videoCallMeetingDetails?.id) {
                setIsLoadingJoin(true);
                VideoCallsService.getMeetingRoom(
                    data.videoCallProviderId,
                    data.videoCallMeetingDetails.id,
                )
                    .then((meetingRoomData) => {
                        if (
                            meetingRoomData &&
                            meetingRoomData.startUrl &&
                            meetingRoomData.idGestion
                        ) {
                            window.open(meetingRoomData.startUrl, '_blank');
                            cancelModal();

                            if (meetingRoomData.isOwner) {
                                modalInit({
                                    entity: ACTIVITIES,
                                    id: meetingRoomData.idGestion,
                                    labels: {
                                        title: getLiteral('action_updatemanagement'),
                                        success: getLiteral('succes_entityupdatedsuccessfully'),
                                        error: getLiteral('label_failed_update_contact_salesforce'),
                                        deleteModalTitle: getLiteral('title_delete_activities'),
                                        successDelete: getLiteral(
                                            'succes_entitydeletedsuccessfully',
                                        ),
                                    },
                                });
                            }
                        }
                    })
                    .catch(() => {
                        setIsLoadingJoin(false);
                    });
            }
        }, [data, cancelModal, isRoomCreation, modalInit]);

        const renderRoomBox = useMemo(() => {
            if (!data?.videoCallProviderId || !data.fini || !options.length) return null;
            const provider = getMeetingProviderValues(data.videoCallProviderId);
            if (!provider) return null;

            const clipBoardText = isCopied
                ? getLiteral('label_copied_to_clipboard')
                : getLiteral('action_copy_to_clipboard');

            let providerClass = '';

            if (provider.name === 'zoom') providerClass = 'videocall-field__room-zoom';
            else if (provider.name === 'teams') providerClass = 'videocall-field__room-teams';

            const buttonClasses = ['video-field-provider__room__button'];
            if (isRoomCreation) {
                buttonClasses.push('video-field-provider__room__button--disabled');
            }
            if (providerClass) buttonClasses.push(providerClass);

            const isDisabledButton = isRoomCreation ? true : false;

            return (
                <div className="videocall-field__room">
                    {isLoadingRoom && !failedRoomData && (
                        <div className="videocall-field__room__loader">
                            <Loader className="" size="large" />
                        </div>
                    )}
                    {!isLoadingRoom && data.videoCallMeetingDetails && (
                        <>
                            <div className="videocall-field__room__header">
                                <div className="videocall-field__room__header-content">
                                    <div className="videocall-field__room__header-info">
                                        <Avatar
                                            className={`videocall-field__room__header-icon ${providerClass}`}
                                            src={provider.iconUrl}
                                            alt="providerIcon"
                                            size="small"
                                        />
                                        <Text
                                            className="videocall-field__room__header-title"
                                            type="caption"
                                            isTruncated
                                        >
                                            {provider.title}
                                        </Text>
                                    </div>
                                    {isRoomCreation && (
                                        <Icon
                                            className="videocall-field__room__header-close"
                                            name="close"
                                            onClick={handleOnRemoveRoom}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="videocall-field__room__content">
                                <Text
                                    className="video-field-provider__room__content-label"
                                    type="caption"
                                >
                                    {getLiteral('label_onlinemeeting_link_share')}
                                </Text>
                                <div className="video-field-provider__room__content">
                                    {!isRoomCreation && (
                                        <Link
                                            className="video-field-provider__room__content-value"
                                            href={data.videoCallMeetingDetails.joinUrl}
                                            target="_blank"
                                            type="caption"
                                        >
                                            {data.videoCallMeetingDetails.joinUrl}
                                        </Link>
                                    )}
                                    {isRoomCreation && (
                                        <Text
                                            className="video-field-provider__room__content-value video-field-provider__room__content-value--disabled"
                                            type="caption"
                                        >
                                            {data.videoCallMeetingDetails.joinUrl}
                                        </Text>
                                    )}

                                    <Tooltip placement="top" content={<span>{clipBoardText}</span>}>
                                        <Icon
                                            className="video-field-provider__room__content-url__clipboard"
                                            name="contentCopy"
                                            onClick={handleCopyToClipboard}
                                        />
                                    </Tooltip>
                                </div>
                                <Text
                                    className="video-field-provider__room__content-meeting-id"
                                    type="caption"
                                    isTruncated
                                >
                                    {getLiteralWithParameters('label_online_meeting_id', [
                                        data.videoCallMeetingDetails.id,
                                    ])}
                                </Text>
                            </div>
                            <Button
                                className={buttonClasses.join(' ')}
                                onClick={handleOnClickJoinMeeting}
                                isDisabled={isDisabledButton}
                                isLoading={isLoadingJoin}
                            >
                                <Avatar
                                    className="videocall-field__room__header-icon"
                                    src={provider.iconUrl}
                                    alt="providerIcon"
                                    size="small"
                                />
                                <Text
                                    className="video-field-provider__room__button-text"
                                    color={theme.colors.primary.white}
                                >
                                    {getLiteral('action_join_meeting')}
                                </Text>
                            </Button>
                        </>
                    )}
                </div>
            );
        }, [
            data,
            getMeetingProviderValues,
            handleOnRemoveRoom,
            isLoadingRoom,
            isCopied,
            handleCopyToClipboard,
            failedRoomData,
            handleOnClickJoinMeeting,
            options,
            isLoadingJoin,
            isRoomCreation,
            theme,
        ]);

        const updateProviderAndPermissions = useCallback(
            (providerSelected) => {
                return new Promise((resolve, reject) => {
                    const provider = providers.reduce((obj, current) => {
                        if (current.providerId === providerSelected.providerId) obj = current;
                        return obj;
                    }, {});

                    getVideoCallProvidersInformation()
                        .then((newProviders) => {
                            const providerIndex = newProviders.findIndex(
                                (d) => d.providerId === provider.providerId,
                            );

                            const finalProvider =
                                providerIndex !== -1 && newProviders[providerIndex];

                            if (
                                finalProvider?.isActive !== provider.isActive ||
                                finalProvider?.accountId !== provider.accountId
                            ) {
                                // after that, update permissions
                                updateSpecificPermissions([{ key: 'VideoCalls', value: true }]);

                                if (unfilteredOptions.length && newProviders.length) {
                                    const providersMap = newProviders.reduce((obj, provider) => {
                                        obj[provider.providerId] = provider;
                                        return obj;
                                    }, {});

                                    const filteredList = unfilteredOptions.filter((element) => {
                                        return (
                                            element.extprovidercfgid &&
                                            providersMap[element.extprovidercfgid] &&
                                            providersMap[element.extprovidercfgid].isActive
                                        );
                                    });

                                    const finalValue = filteredList.reduce((obj, current) => {
                                        if (current.value === finalProvider.id.toString()) {
                                            obj = current;
                                        }
                                        return obj;
                                    }, {});

                                    if (
                                        !filteredList.length ||
                                        !finalValue ||
                                        !Object.keys(finalValue).length
                                    ) {
                                        resolve();
                                        return;
                                    }

                                    setOptions(filteredList);
                                    handleOnChange(finalValue);

                                    successToast({
                                        text: getLiteral('label_credentials_added'),
                                    });
                                }
                                resolve();
                            } else {
                                resolve();
                            }
                        })
                        .catch(reject);
                });
            },
            [
                providers,
                updateSpecificPermissions,
                getVideoCallProvidersInformation,
                unfilteredOptions,
                handleOnChange,
            ],
        );

        const authenticateToProvider = useCallback(() => {
            if (providers.length === 0) return;
            let provider = null;
            if (providers.length === 1) provider = providers[0];
            else {
                provider = providers.reduce((obj, current) => {
                    if (current?.providerId === TEAMS_ID) {
                        obj = current;
                    }
                    return obj;
                }, {});
            }
            setDisableActivationButton(true);
            const strWindowFeatures =
                'toolbar=no,menubar=no,location=no,status=no,top=100,left=100,width=600,height=700';
            if (
                popUpWindow.current &&
                popUpWindow.current[provider.providerId] &&
                !popUpWindow.current[provider.providerId].closed
            ) {
                popUpWindow.current[provider.providerId].focus();
            } else {
                popUpWindow.current[provider.providerId] = window.open(
                    provider.authLink,
                    provider.providerName,
                    strWindowFeatures,
                );
                if (intervalWindow.current[provider.providerId])
                    clearInterval(intervalWindow.current[provider.providerId]);

                intervalWindow.current[provider.providerId] = setInterval(() => {
                    if (popUpWindow.current[provider.providerId].closed) {
                        clearInterval(intervalWindow.current[provider.providerId]);
                        setLoadingActivation(true);

                        updateProviderAndPermissions(provider).finally(() => {
                            logEvent({
                                event: AGENDA.trueName,
                                functionality: 'activateTeamsIntegration',
                            });
                            setLoadingActivation(false);
                            setDisableActivationButton(false);
                        });
                    }
                }, 500);
            }
        }, [providers, updateProviderAndPermissions]);

        const onActivateTeams = useCallback(() => {
            authenticateToProvider();
        }, [authenticateToProvider]);

        const onChangeToggle = useCallback(
            (bool) => {
                if (
                    !bool &&
                    (isRoomCreation || data?.videoCallProviderId || data?.videoCallMeetingDetails)
                ) {
                    handleOnRemoveRoom();
                    return;
                }
                if (options.length === 1) {
                    handleOnChange(options[0]);
                    return;
                }
            },
            [handleOnRemoveRoom, isRoomCreation, data, handleOnChange, options],
        );

        const renderButton = useMemo(() => {
            return (
                <Button
                    className="videocall-field__activation-button"
                    size="small"
                    isDisabled={disableActivationButton}
                    isLoading={loadingActivation}
                    onClick={onActivateTeams}
                >
                    {getLiteral('label_teams_promote_integration_calendar')}
                </Button>
            );
        }, [onActivateTeams, disableActivationButton, loadingActivation]);

        const renderToggle = useMemo(() => {
            return (
                <div className="videocall-field__switch">
                    <Switch checked={!!selectValue} onChange={onChangeToggle} />
                </div>
            );
        }, [onChangeToggle, selectValue]);

        const renderError = useMemo(() => {
            return (
                <Advice type="error" showCollapse={false}>
                    <Text>{getLiteral('error_accessing_online_provider')}</Text>
                </Advice>
            );
        }, []);

        const shouldRenderSelect = useMemo(() => {
            if (data?.videoCallProviderId && !failedRoomData) return false;
            return true;
        }, [data.videoCallProviderId, failedRoomData]);

        const shouldRenderRoom = useMemo(() => {
            if (options.length && !shouldRenderSelect && !failedRoomData) return true;
            return false;
        }, [shouldRenderSelect, failedRoomData, options]);

        const shouldRenderError = useMemo(() => {
            if (!isLoadingRoom && failedRoomData) return true;
            return false;
        }, [isLoadingRoom, failedRoomData]);

        if (
            inputAttrs?.shouldRenderField &&
            !inputAttrs?.shouldRenderField('videoCallProviderId')
        ) {
            failedRoomData && setFailedRoomData(false);
            return null;
        }

        if (!providers.length) return null;

        return (
            <Base
                className="videocall-field-container"
                label={label}
                mandatory={mandatory}
                description={description}
                labelMode={labelMode}
                isBulkAction={isBulkAction}
            >
                {shouldRenderSelect && (
                    <>
                        {options.length === 0 && renderButton}
                        {options.length === 1 && renderToggle}
                        {options.length > 1 && (
                            <Select
                                value={selectValue}
                                readOnly={readOnly}
                                disabled={disabled}
                                isLoading={isLoadingOptions}
                                isClearable={isClearable}
                                options={options}
                                onChange={handleOnChange}
                            />
                        )}
                    </>
                )}
                {shouldRenderRoom && <div>{renderRoomBox}</div>}
                {shouldRenderError && renderError}
            </Base>
        );
    },
);

VideocallField.propTypes = {
    changeField: PropTypes.func,
    data: PropTypes.obj,
    field: PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
        mandatory: PropTypes.bool,
        readOnly: PropTypes.bool,
        description: PropTypes.string,
        labelMode: PropTypes.oneOf(['vertical', 'horizontal']),
        isBulkAction: PropTypes.bool,
        inputAttrs: PropTypes.shape({
            list: PropTypes.string,
            shouldRenderField: PropTypes.func,
        }),
        disabled: PropTypes.bool,
        isClearable: PropTypes.bool,
    }),
    getList: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(VideocallField);
