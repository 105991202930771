import React, {
    memo,
    useCallback,
    useEffect,
    useMemo,
    useState,
    useRef,
    Fragment,
    act,
} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import classnames from 'classnames';
import { useTheme, Link, Text } from 'hoi-poi-ui';
import VirtualizedList from 'components/VirtualizedList';
import { EntityListActions, ServerListActions } from 'actions';
import { getDateFromBackendISO, getElapsedTimeSimple } from 'utils/dates';
import { ListLoader } from '../Loaders';
import { ACTIVITIES } from 'constants/Entities';
import {
    ACTIVITY_TYPE_CALL,
    ACTIVITY_TYPE_EMAIL,
    ACTIVITY_TYPE_ANNOTATION,
    ACTIVITY_TYPE_CHECKIN,
    ACTIVITY_TYPE_OPPORTUNITY_CHECKIN,
    ACTIVITY_TYPE_FAST_CHECKIN,
} from 'models/ActivityModel';
import { PAGINATION_TABLE_ACTIVITIES } from 'constants/Environment';
import ActivitiesRouteMap from '../ActivitiesRouteMap';
import ActivityListRow from './ActivityListRow';
import { getEnableTimeLine, getActivitiesWithUnreadMessages } from '../../utils/timeline';
import { getLiteral } from 'utils/getLiteral';
import { isEqual } from 'utils/objects';

import './styles.scss';

const mapStateToProps = (state, ownProps) => {
    const entityList = state?.entityList;
    const activityList = entityList[ACTIVITIES.trueName] || null;
    let activities = [];
    if (ownProps.activitiesWidget) activities = ownProps.activitiesWidget;
    else activities = activityList?.data || [];

    let loading = activityList?.loading;
    if (ownProps.hasOwnProperty('loadingWidget')) loading = ownProps.loadingWidget;
    else activityList?.loading || false;

    const usersFilterValue = state?.entityFilters?.[ACTIVITIES.trueName]?.filters?.users?.value;
    return {
        activities,
        loading,
        enableShowMap: usersFilterValue && usersFilterValue.length === 1,
        unreadMessages: state?.messages?.unread?.all?.entities || {},
        enableTimeLine: getEnableTimeLine(),
        offset: activityList?.offset || 0,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateEntityList: bindActionCreators(EntityListActions, dispatch).updateRowFields,
        init: bindActionCreators(EntityListActions, dispatch).init,
        setUseLazyLoad: bindActionCreators(EntityListActions, dispatch).setUseLazyLoad,
        setOffset: bindActionCreators(EntityListActions, dispatch).setOffset,
        getList: bindActionCreators(ServerListActions, dispatch).getList,
    };
};

const ActivitiesList = memo(
    ({
        activities,
        init,
        setUseLazyLoad,
        loading,
        enableShowMap,
        unreadMessages,
        updateEntityList,
        setUnreadMessages,
        enableTimeLine,
        offset,
        setOffset,
        getList,
        getActivitiesWidget,
        onOpenDetailWidget,
        onClickConversationFromWidget,
        isChangeFilterLoading,
        updateFollowWidget,
        onDeleteFromWidget,
        entity,
        activitiesWidget,
    }) => {
        const theme = useTheme();
        const domContent = useRef(null);
        const unreadMessagesRef = useRef({});

        const [workFlowTables, setWorkFlowTables] = useState([]);
        const [routeMapInfo, setRouteMapInfo] = useState({});
        const isFirstRender = useRef(true);

        const handleUnreadMessages = useCallback(
            (unreadMessages) => {
                activities.forEach((activity) => {
                    const { ActivityType, Id, TimeLineUnreadMessages } = activity;
                    // Because BE: For the unread messages endpoint everything that is not a phone call or email
                    // is an "activity"
                    const type = [ACTIVITY_TYPE_CALL, ACTIVITY_TYPE_EMAIL].includes(ActivityType)
                        ? ActivityType
                        : ACTIVITY_TYPE_ANNOTATION;
                    const updatedActivitiesObj = unreadMessages[type] || {};
                    const updatedActivitiesIds = Object.keys(updatedActivitiesObj);
                    if (
                        updatedActivitiesIds.includes(Id) &&
                        parseInt(TimeLineUnreadMessages, 10) !== updatedActivitiesObj[Id]
                    ) {
                        updateEntityList(ACTIVITIES, Id, {
                            TimeLineUnreadMessages: updatedActivitiesObj[Id],
                        });
                    }
                });
            },
            [activities, updateEntityList],
        );

        useEffect(() => {
            if (
                !enableTimeLine ||
                isEqual(unreadMessages, unreadMessagesRef.current) ||
                loading ||
                activities?.length === 0
            )
                return;

            unreadMessagesRef.current = unreadMessages;
            const activitiesWithUnreadMessages = getActivitiesWithUnreadMessages(unreadMessages);

            if (Object.entries(activitiesWithUnreadMessages).length > 0) {
                handleUnreadMessages(activitiesWithUnreadMessages);
                setUnreadMessages(activitiesWithUnreadMessages);
            } else {
                setUnreadMessages({});
            }
        }, [
            unreadMessages,
            handleUnreadMessages,
            setUnreadMessages,
            loading,
            activities,
            enableTimeLine,
        ]);

        useEffect(() => {
            if (isFirstRender.current) {
                isFirstRender.current = false;
                setUseLazyLoad(ACTIVITIES, true);
            }
            return () => {
                domContent.current = null;
            };
        }, [setUseLazyLoad]);

        const getActivities = useCallback(
            (props) => {
                let newOffset = offset;
                // We need to reset the offset when we want to get the fisrt elements in the list
                if (props?.resetOffset) newOffset = 0;

                if (getActivitiesWidget) {
                    return getActivitiesWidget(props);
                } else {
                    return init(
                        ACTIVITIES,
                        true,
                        PAGINATION_TABLE_ACTIVITIES,
                        newOffset,
                        null,
                        null,
                        null,
                        null,
                        true,
                    )
                        .then(({ entities }) => {
                            if (entities.length) {
                                setOffset(ACTIVITIES, newOffset + PAGINATION_TABLE_ACTIVITIES);
                            }
                            return entities;
                        })
                        .catch((e) => {
                            console.error('error: ', e);
                        });
                }
            },
            [init, offset, setOffset, getActivitiesWidget],
        );

        const getListValues = useCallback(
            (tables) => {
                if (tables.length === 0) return Promise.resolve();
                let promises = [];
                tables.map((table) => {
                    promises.push(
                        getList(table).then((values) => {
                            const finalValues = values.reduce((obj, value) => {
                                obj[value.value] = value;
                                return obj;
                            }, {});
                            setWorkFlowTables({
                                ...workFlowTables,
                                [table]: finalValues,
                            });
                        }),
                    );
                });
                return Promise.all(promises);
            },
            [workFlowTables, getList],
        );

        const activitiesWithDays = useMemo(() => {
            if (!activities?.length) return [];
            let days = [];
            let byDays = {};
            let list = [];
            let listDaysIndex = {};
            let workFlowTablesNew = [];

            activities.forEach((activity, index) => {
                let pattern = 'DD/MM/YYYY';
                let date = getDateFromBackendISO(activity.ActivityDate, pattern);
                const dateMoment = moment(date, pattern);
                let key = getElapsedTimeSimple(dateMoment, enableShowMap);
                if (!key) return;

                if (!byDays[key]) {
                    byDays[key] = [];
                    days.push(key);
                    list.push({
                        Id: activity.Id,
                        ActivityDate: activity.ActivityDate,
                        isDayRow: true,
                        date: key,
                        checkins: [],
                    });
                    listDaysIndex[key] = list.length ? list.length - 1 : 0;
                }

                const ActivityType = parseInt(activity.ActivityType, 10);
                const hasCheckin = [
                    ACTIVITY_TYPE_CHECKIN,
                    ACTIVITY_TYPE_OPPORTUNITY_CHECKIN,
                    ACTIVITY_TYPE_FAST_CHECKIN,
                ].includes(ActivityType);

                if (
                    hasCheckin &&
                    listDaysIndex.hasOwnProperty(key) &&
                    list?.[listDaysIndex[key]]?.isDayRow
                ) {
                    list[listDaysIndex[key]].checkins.push(activity);
                }

                list.push(activity);

                if (parseInt(activity.ActivityType, 10) === 11) {
                    let workFlow = activity.Workflow;
                    if (
                        workFlow &&
                        workFlow.table &&
                        !workFlowTables[workFlow.table] &&
                        !workFlowTablesNew.includes(workFlow.table)
                    ) {
                        workFlowTablesNew.push(workFlow.table);
                    }
                }
            });

            //What sould this promise do? What are workflows for?
            getListValues(workFlowTablesNew);

            return list;
        }, [activities, enableShowMap, getListValues, workFlowTables]);

        const handleShowMap = useCallback(({ checkins, date, dataDate }) => {
            setRouteMapInfo({
                checkins,
                date,
                user: checkins[0].UserName,
                dataDate,
                userId: checkins[0].IDUser,
            });
        }, []);

        const renderRow = useCallback(
            ({ index, data }) => {
                let dateClassName = 'fm-activities-list__day';
                if (index === 0) {
                    dateClassName = classnames(dateClassName, `${dateClassName}-first`);
                }
                if (data?.isDayRow) {
                    return (
                        <div className={dateClassName} key={`day-${index}`}>
                            <Text
                                type={!!activitiesWidget ? 'subtitle' : 'h6'}
                                medium
                                color={theme.colors.utility.textSecondary}
                            >
                                {getLiteral(data.date)}
                            </Text>
                            {enableShowMap && data.checkins.length > 0 && (
                                <Link
                                    type="caption"
                                    onClick={() =>
                                        handleShowMap({
                                            checkins: data.checkins,
                                            date: data.date,
                                            dataDate: data.ActivityDate,
                                        })
                                    }
                                >
                                    {getLiteral('action_compare_route')}
                                </Link>
                            )}
                        </div>
                    );
                } else {
                    const { ActivityType, Id } = data;
                    const type = [ACTIVITY_TYPE_CALL, ACTIVITY_TYPE_EMAIL].includes(ActivityType)
                        ? ActivityType
                        : ACTIVITY_TYPE_ANNOTATION;
                    const updatedActivitiesObj =
                        getActivitiesWithUnreadMessages(unreadMessages)?.[type] || {};
                    data.TimeLineUnreadMessages = updatedActivitiesObj?.[Id] || 0;
                    return (
                        <ActivityListRow
                            key={data.Id}
                            activity={data}
                            enableTimeLine={enableTimeLine}
                            onOpenDetailWidget={onOpenDetailWidget}
                            updateFollowWidget={updateFollowWidget}
                            onDeleteFromWidget={onDeleteFromWidget}
                            onClickConversationFromWidget={onClickConversationFromWidget}
                            entity={entity}
                        />
                    );
                }
            },
            [
                activitiesWidget,
                enableShowMap,
                handleShowMap,
                unreadMessages,
                enableTimeLine,
                onOpenDetailWidget,
                updateFollowWidget,
                onDeleteFromWidget,
                onClickConversationFromWidget,
                entity,
                theme,
            ],
        );

        const loaderBox = useMemo(() => {
            return (
                <div className="fm-activities-list__loader-box">
                    <Text
                        className="fm-activities-list__loader-box-text"
                        type="subtitle1"
                        color={theme.colors.primary.white}
                    >
                        {getLiteral('wait_loading')}
                    </Text>
                </div>
            );
        }, [theme]);

        const defaultLoaderWidth = useMemo(() => {
            const body = document.querySelector('body');
            const bodyWidth = body.offsetWidth;
            const navigationEl = document.querySelector('.react-navigation-layout');
            const navigationWidth = navigationEl?.offsetWidth || 0;
            const detailEl = document.querySelector('.fm-activities__detail');
            const detailWidth = detailEl?.offsetWidth || 0;

            const staticNavigationWidth = 224;
            const staticDetailWidth = (bodyWidth - navigationWidth) * 0.5;

            if (navigationWidth && detailWidth) return bodyWidth - navigationWidth - detailWidth;
            return bodyWidth - staticNavigationWidth - staticDetailWidth;
        }, []);

        const defaultLoaderHeight = useMemo(() => {
            const body = document.querySelector('body');
            const bodyHeight = body.offsetHeight;
            const headersHeight = 64;
            return bodyHeight - headersHeight * 2;
        }, []);

        const mapProps = useMemo(
            () => ({
                onRequestClose: () => setRouteMapInfo({}),
                ...routeMapInfo,
            }),
            [routeMapInfo],
        );

        const shouldRenderListLoader = useMemo(() => {
            if (getActivitiesWidget && isChangeFilterLoading) {
                return true;
            } else {
                return !activities?.length || (isFirstRender.current && loading);
            }
        }, [activities?.length, loading, getActivitiesWidget, isChangeFilterLoading]);

        return (
            <Fragment>
                <div className="fm-activities-list" ref={domContent}>
                    {shouldRenderListLoader && (
                        <ListLoader
                            width={domContent.current?.offsetWidth || defaultLoaderWidth}
                            height={domContent.current?.offsetHeight || defaultLoaderHeight}
                        />
                    )}
                    {loading && loaderBox}
                    <VirtualizedList
                        entity={ACTIVITIES}
                        containerKey="fm-activities-list"
                        renderRow={renderRow}
                        total={activitiesWithDays.length}
                        onLoad={getActivities}
                        data={activitiesWithDays}
                    />
                </div>
                <ActivitiesRouteMap {...mapProps} />
            </Fragment>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesList);
