import React, { memo, useCallback, useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Icon, Modal, Text, useTheme } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import { isEmptyObject } from 'utils/objects';
import { logEvent } from 'utils/tracking';

import {
    VisualCoordinatesDialogContext,
    VisualCoordinatesDialogProvider,
} from './components/VisualCoordinatesDialogContext';
import Signers from './components/Signers';
import VisualCoordinatesEditor from './components/VisualCoordinatesEditor';

import './styles.scss';

const VisualCoordinates = memo(
    ({ form, onCancel, open, onConfirm, fullScreenEventPayload, addSignatureEventPayload }) => {
        const { state, dispatch } = useContext(VisualCoordinatesDialogContext);
        const theme = useTheme();

        useEffect(() => {
            checkCompleted();
        }, [checkCompleted, state.pdfViewerState]);

        const checkCompleted = useCallback(() => {
            // Check that every signer has one signature at least
            const completedSigners = form.signers.filter(
                (signer) =>
                    signer.require_signature_in_coordinates &&
                    signer.require_signature_in_coordinates.length ===
                        state.pdfViewerState.numPages &&
                    signer.require_signature_in_coordinates.filter(
                        (signature) => !isEmptyObject(signature),
                    ).length !== 0,
            );

            dispatch({
                type: 'completed',
                payload: form.signers.length === completedSigners.length,
            });
        }, [dispatch, form.signers, state.pdfViewerState.numPages]);

        const saveSignatures = useCallback(() => {
            const { currentSignatures, isEditing } = state;
            const currentSigner = form.signers[isEditing];
            currentSigner.require_signature_in_coordinates = currentSignatures;
            dispatch({ type: 'edit', payload: false });
            checkCompleted();
        }, [checkCompleted, dispatch, form.signers, state]);

        const handleAddSignature = useCallback(() => {
            const { currentSignatures, pdfViewerState } = state;

            const newSignature = {
                top: 45,
                left: 37.5,
                height: 10,
                width: 25,
            };

            const signatures =
                currentSignatures.length === 0
                    ? Array(pdfViewerState.numPages).fill({})
                    : [...currentSignatures];

            signatures[pdfViewerState.page - 1] = newSignature;

            dispatch({ type: 'loadSignatures', payload: signatures });
            addSignatureEventPayload && logEvent(addSignatureEventPayload);
        }, [addSignatureEventPayload, dispatch, state]);

        const handleClose = useCallback(() => {
            onCancel(false);
        }, [onCancel]);

        const handleConfirm = useCallback(() => {
            onConfirm();
            dispatch({ type: 'send' });
        }, [dispatch, onConfirm]);

        const modalTitle = useMemo(
            () => (
                <div className="fm-vc-dialog__header">
                    <div className="fm-vc-dialog__title">
                        <Icon
                            name="arrowBack"
                            size="large"
                            color={theme.colors.actionMinor[500]}
                            onClick={handleClose}
                        />
                        <Text type="h6">{form?.document?.description}</Text>
                    </div>
                    {form?.emailSubject && (
                        <Text className="fm-vc-dialog__subtitle" type="body">
                            {form?.emailSubject}
                        </Text>
                    )}
                </div>
            ),
            [
                theme.colors.actionMinor,
                handleClose,
                form?.document?.description,
                form?.emailSubject,
            ],
        );

        const modalProps = useMemo(
            () => ({
                confirmText: getLiteral('action_signature_send_to_sign'),
                isOpen: open,
                onRequestClose: handleClose,
                onConfirm: handleConfirm,
                isConfirmDisabled: state.isLoading || !state.isComplete,
                isConfirmLoading: state.isSending,
                overrides: {
                    content: {
                        style: {
                            backgroundColor: theme.colors.grey[50],
                            borderRadius: 6,
                        },
                    },
                },
                title: modalTitle,
                width: '1200px',
                useContentStaticHeight: true,
                useCornerClose: false,
            }),
            [
                handleClose,
                handleConfirm,
                modalTitle,
                open,
                state.isComplete,
                state.isLoading,
                state.isSending,
                theme.colors.grey,
            ],
        );

        if (!form && !form?.signers && !form?.document) return null;

        return (
            <Modal {...modalProps}>
                <div className="fm-vc-dialog__content">
                    <Signers signers={form?.signers} />
                    <VisualCoordinatesEditor
                        document={form?.document}
                        signers={form?.signers}
                        onConfirm={saveSignatures}
                        addSignature={handleAddSignature}
                        fullScreenEventPayload={fullScreenEventPayload}
                    />
                </div>
            </Modal>
        );
    },
);

const VisualCoordinatesDialog = memo((props) => (
    <VisualCoordinatesDialogProvider>
        <VisualCoordinates {...props} />
    </VisualCoordinatesDialogProvider>
));

VisualCoordinates.propTypes = {
    form: PropTypes.object,
    onCancel: PropTypes.func,
    open: PropTypes.bool,
    onConfirm: PropTypes.func,
    addSignatureEventPayload: PropTypes.shape({
        event: PropTypes.string,
        submodule: PropTypes.string,
        functionality: PropTypes.string,
    }),
    fullScreenEventPayload: PropTypes.shape({
        event: PropTypes.string,
        submodule: PropTypes.string,
        functionality: PropTypes.string,
    }),
};

export default VisualCoordinatesDialog;
