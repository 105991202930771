import { memo, useMemo, useCallback } from 'react';
import { Icon, Input, Slider, DatePicker } from 'hoi-poi-ui';
import { useSelector } from 'react-redux';
import { getLiteral } from 'utils/getLiteral';
import { FuzzyMap } from 'utils/fuzzy';
import { formatDate } from 'utils/dates';
import { CONDITION_OPERATOR_LOCALES, getOperatorConditionValues } from '../../utils';
import SelectHoi from 'components/SelectHoi';
import MultiSelect from 'containers/components/Fields/MultiSelect';
import MultiSelectFuzzy from 'containers/components/Fields/MultiSelectFuzzy';
import Select from 'containers/components/Fields/Select';
import SelectFuzzy from 'containers/components/Fields/SelectFuzzy';

const TYPE_DEFAULTS = {
    text: 'in',
    int: '==',
    decimal: '==',
    currency: '==',
    percentage: '==',
    bit: '==',
    list: 'in',
    multivalue: 'in',
    datetime: '==',
};

const AutomationBuilderCondition = memo(({ condition, onChange, onRemove, fields, operators }) => {
    const lang = useSelector((state) => state.config?.userData?.langISOInterface);
    const boolOptions = useMemo(
        () => [
            {
                label: getLiteral('cfm_label_yes'),
                value: true,
            },
            {
                label: getLiteral('cfm_label_no'),
                value: false,
            },
        ],
        [],
    );

    const field = useMemo(() => {
        const conditionField = getOperatorConditionValues(condition)?.variable?.var;
        let fField;

        for (const field of fields) {
            const option = field.options?.find((option) => option.value === conditionField);
            if (option) {
                fField = option;
                break;
            }
        }

        return fField;
    }, [condition, fields]);

    const operatorOptions = useMemo(() => {
        if (!operators) return [];

        if (!field?.type) return [];

        return (
            operators?.[field?.type]
                ?.filter((operator) => CONDITION_OPERATOR_LOCALES[operator])
                ?.map((operator) => ({
                    label: getLiteral(CONDITION_OPERATOR_LOCALES[operator]),
                    value: operator,
                })) || []
        );
    }, [field, operators]);

    const operatorValue = useMemo(() => Object.keys(condition || {})?.[0], [condition]);
    const fieldsValue = useMemo(
        () => getOperatorConditionValues(condition)?.variable?.var,
        [condition],
    );

    const onChangeField = useCallback(
        (value, valueObj) => {
            if (!value) {
                onChange({});
            } else {
                let operator = Object.keys(condition || {})?.[0];
                operator = TYPE_DEFAULTS[valueObj?.type] || operators[valueObj?.type]?.[0];

                const { conditionPair } = getOperatorConditionValues(
                    {
                        [operator]: condition[operator],
                    },
                    {
                        variable: { var: value },
                        value: null,
                    },
                );

                onChange({
                    [operator]: conditionPair,
                });
            }
        },
        [condition, onChange, operators],
    );

    const onChangeOperator = useCallback(
        (value) => {
            let operator = Object.keys(condition || {})?.[0];
            // Transforming condition format by operator
            const oldConditionObj = getOperatorConditionValues(condition);
            const { conditionPair } = getOperatorConditionValues(
                {
                    [value]: condition[operator],
                },
                {
                    variable: oldConditionObj?.variable,
                    value: oldConditionObj?.value,
                },
            );
            onChange({
                [value]: conditionPair,
            });
        },
        [condition, onChange],
    );

    const onChangeValue = useCallback(
        (value) => {
            let operator = Object.keys(condition || {})?.[0];
            const { conditionPair } = getOperatorConditionValues(condition, {
                value: value === '' || value === undefined ? null : value,
            });
            onChange({
                [operator]: conditionPair,
            });
        },
        [condition, onChange],
    );

    const typeInputEl = useMemo(() => {
        const conditionValues = getOperatorConditionValues(condition);
        const value = conditionValues?.value ?? '';
        const operator = conditionValues?.operator;
        if (!field?.type) return null;

        let list, listProps, Component;
        switch (field.type) {
            case 'text':
                return (
                    <Input
                        key={field.key}
                        onChange={onChangeValue}
                        value={value}
                        placeholder={getLiteral('placeholder_text_field')}
                        isFullWidth
                    />
                );
            case 'int':
                return (
                    <Input
                        key={field.key}
                        onChange={onChangeValue}
                        value={value}
                        type="integer"
                        placeholder={getLiteral('placeholder_numeric')}
                        isFullWidth
                    />
                );
            case 'currency':
            case 'decimal':
                return (
                    <Input
                        key={field.key}
                        onChange={onChangeValue}
                        value={value}
                        type="decimal"
                        placeholder={getLiteral('placeholder_numeric')}
                        isFullWidth
                    />
                );
            case 'percentage':
                return (
                    <Slider
                        key={field.key}
                        onChange={onChangeValue}
                        value={value}
                        isPercentage
                        isFullWidth
                    />
                );
            case 'bit':
                return (
                    <SelectHoi
                        key={field.key}
                        onChange={onChangeValue}
                        options={boolOptions}
                        value={value}
                        usePlainValue
                        isFullWidth
                        useMenuPortal={false}
                    />
                );
            case 'datetime':
                return (
                    <DatePicker
                        key={field.key}
                        onChange={onChangeValue}
                        value={value}
                        lang={lang}
                        calendarButtonLabel={getLiteral('label_today')}
                        placeholder={getLiteral('label_selectone')}
                        formatDate={(date) => formatDate(date, 'L')}
                        isFullWidth
                    />
                );
            case 'list':
                list = field.tableRelated?.table;
                listProps = FuzzyMap[list?.toLowerCase()] || list || {};
                switch (operator) {
                    case '!=':
                    case '==':
                        Component = listProps?.list ? SelectFuzzy : Select;
                        break;
                    default:
                        Component = listProps?.list ? MultiSelectFuzzy : MultiSelect;
                        break;
                }

                return (
                    <Component
                        key={field.key}
                        onChange={onChangeValue}
                        list={field.tableRelated.table}
                        {...listProps}
                        value={value}
                        useMenuPortal={false}
                        usePlainValue
                    />
                );
            case 'multivalue':
            case 'list':
                list = field.tableRelated?.table;
                listProps = FuzzyMap[list?.toLowerCase()] || list || {};
                Component = listProps?.list ? MultiSelectFuzzy : MultiSelect;
                return (
                    <Component
                        key={field.key}
                        onChange={onChangeValue}
                        list={field.tableRelated.table}
                        {...listProps}
                        value={value}
                        useMenuPortal={false}
                        usePlainValue
                    />
                );
        }
    }, [boolOptions, condition, field, lang, onChangeValue]);

    return (
        <div className="fm-automation-builder__condition">
            <div className="fm-automation-builder__condition-form">
                {fields?.length > 0 && (
                    <SelectHoi
                        placeholder={getLiteral('label_placeholder_field')}
                        onChange={onChangeField}
                        options={fields}
                        value={fieldsValue}
                        isFullWidth
                        useMenuPortal={false}
                        usePlainValue
                    />
                )}
                {fields?.length > 0 && (
                    <SelectHoi
                        options={operatorOptions}
                        onChange={onChangeOperator}
                        value={operatorValue}
                        isFullWidth
                        useMenuPortal={false}
                        isReadOnly={!operatorOptions?.length}
                        usePlainValue
                        isClearable={false}
                    />
                )}
                {typeInputEl}
            </div>
            <Icon
                className="fm-automation-builder__condition-form-icon"
                name="less"
                size="large"
                onClick={onRemove}
                options={operatorOptions}
            />
        </div>
    );
});

export default AutomationBuilderCondition;
