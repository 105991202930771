import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { Icon, useTheme } from 'hoi-poi-ui';
import { isEmptyObject } from 'utils/objects';

import './styles.scss';

const SignatureLocation = memo(({ signatureState, signatureDispatch }) => {
    const theme = useTheme();
    const [controlledPosition, setControlledPosition] = useState(null);
    const { currentSignatures, pdfViewerState } = signatureState;
    const { getSize, page } = pdfViewerState;

    useEffect(() => {
        const activeSignature = currentSignatures[page - 1];
        const userHasNoSignatures = currentSignaturesLength() === 0;

        if (!isEmptyObject(activeSignature)) {
            const newPosition = {
                x: activeSignature.left,
                y: activeSignature.top,
            };
            updatePosition(newPosition);
        } else {
            if (userHasNoSignatures) {
                handleEdit({ x: 37.5, y: 45 });
            }
        }
    }, [currentSignatures, currentSignaturesLength, handleEdit, page, updatePosition]);

    const currentSignaturesLength = useCallback(
        () => currentSignatures.filter((signature) => !isEmptyObject(signature)).length,
        [currentSignatures],
    );

    const handleEdit = useCallback(
        (obj) => {
            const topPosition = obj.y;
            const leftPosition = obj.x;
            const newSignatures = [...currentSignatures];
            newSignatures[page - 1] = {
                top: topPosition,
                left: leftPosition,
                height: 10,
                width: 25,
            };
            signatureDispatch({
                type: 'loadSignatures',
                payload: newSignatures,
            });
        },
        [currentSignatures, page, signatureDispatch],
    );

    const handleRemove = useCallback(() => {
        const newSignatures = [...currentSignatures];
        newSignatures[page - 1] = {};
        signatureDispatch({
            type: 'loadSignatures',
            payload: newSignatures,
        });
    }, [currentSignatures, page, signatureDispatch]);

    const handleStop = useCallback(
        (e, { x, y }) => {
            const obj = {
                x: (x / getSize().width) * 100,
                y: (y / getSize().height) * 100,
            };
            updatePosition(obj);
            handleEdit(obj);
        },
        [getSize, handleEdit, updatePosition],
    );

    const updatePosition = useCallback((obj) => {
        setControlledPosition(obj);
    }, []);

    const signatureProps = useMemo(() => {
        const pos = controlledPosition
            ? {
                  x: (controlledPosition.x * getSize().width) / 100,
                  y: (controlledPosition.y * getSize().height) / 100,
              }
            : null;

        return {
            bounds: 'parent',
            defaultPosition: pos || { x: 0, y: 0 },
            position: pos,
            onStop: handleStop,
        };
    }, [controlledPosition, getSize, handleStop]);

    return currentSignaturesLength() > 0 && !isEmptyObject(currentSignatures[page - 1]) ? (
        <Draggable {...signatureProps}>
            <div className="fm-pdf-viewer-draggable">
                {currentSignaturesLength() > 1 && (
                    <Icon
                        name="thickDisabled"
                        className="fm-pdf-viewer-draggable__close"
                        color={theme.colors.primary.white}
                        size="medium"
                        onClick={handleRemove}
                    />
                )}
                <span className="fm-pdf-viewer-draggable__tl"></span>
                <span className="fm-pdf-viewer-draggable__tr"></span>
                <span className="fm-pdf-viewer-draggable__bl"></span>
                <span className="fm-pdf-viewer-draggable__br"></span>
            </div>
        </Draggable>
    ) : (
        <div className="noSignature" />
    );
});

export default SignatureLocation;
