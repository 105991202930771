import { useMemo, useCallback } from 'react';
import { CONTACTS } from 'constants/Entities';
import Context from 'managers/Context';
import { getLiteral } from 'utils/getLiteral';
import { getActiveCrud } from 'utils/crud';
import { successToast, errorToast } from 'utils/toast';

const useContactsCrud = () => {
    const state = Context.store.getState();
    const permission = state.config.permission;

    const getCanDelete = useCallback(() => {
        const state = Context.store.getState();
        const activeCrud = getActiveCrud(state);
        const id = activeCrud ? activeCrud.id : null;
        const crudPermissions =
            state.config?.permission?.crud_permission?.[CONTACTS.permission] || null;
        return id && crudPermissions && crudPermissions.delete;
    }, []);

    const literalsBulk = {
        labelTitleBulkCrud: 'title_num_contacts',
        labelTitleBulkCrudSingular: 'title_num_contacts_singular',
    };

    const onSave = useCallback((data) => {
        let toastLiteral = 'succes_entitycreatedsuccessfully';
        if (data && data.hasOwnProperty('Updated') && data.Updated.num === '1')
            toastLiteral = 'succes_entityupdatedsuccessfully';

        successToast({
            text: getLiteral(toastLiteral),
        });
    }, []);

    const onDelete = useCallback(() => {
        successToast({
            text: getLiteral('succes_entitydeletedsuccessfully'),
        });
    }, []);

    const onErrorSave = useCallback((data) => {
        let toastLiteral = 'label_failed_insert_contact_salesforce';
        if (data.id) toastLiteral = 'label_failed_update_contact_salesforce';
        errorToast({
            text: getLiteral(toastLiteral),
        });
    }, []);

    const onErrorDelete = useCallback(() => {
        errorToast({
            text: getLiteral('label_failed_delete_contact_salesforce'),
        });
    }, []);

    const deleteProps = useMemo(
        () => ({
            buttonText: getLiteral('action_delete'),
        }),
        [],
    );

    return {
        entity: CONTACTS,
        width: '600px',
        className: 'contacts-crud',
        onSave,
        onErrorSave,
        onDelete,
        onErrorDelete,
        hasCrudInDetail: true,
        getCanDelete,
        canMerge: permission.mergeContacts,
        deleteProps,
        literalNewEntity: 'page_title_contacts_create',
        literalEditEntity: 'title_edit_contacts',
        literalsBulk,
    };
};

export default useContactsCrud;
