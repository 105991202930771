import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import colors from 'constants/colors';
import Tooltip from 'components/Tooltip';
import TooltipHoi from 'components/TooltipHoi';
import { getLiteral } from 'utils/getLiteral';
import { Info, ChevronUp } from 'components/SvgIcons';
import './style.scss';

const propTypes = {
    label: PropTypes.any,
    mandatory: PropTypes.bool,
    className: PropTypes.string,
    parentClassName: PropTypes.string,
    description: PropTypes.string,
    labelMode: PropTypes.string,
    isBulkAction: PropTypes.bool,
};

class Base extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            bulkEditable: false,
            isToggled: props.canToggle,
        };
    }

    openBulkFieldsWrapper = () => {
        const { isBulkFieldsWrapper, onClickBulkEditable } = this.props;

        if (isBulkFieldsWrapper) {
            this.onClickBulkEditable();
            onClickBulkEditable && onClickBulkEditable(); // custom onClick for fields with additional stuff
        } else return this.onClickBulkEditable;
    };

    onClickBulkEditable = () => {
        const { onClickBulkEditable } = this.props;

        this.setState({ bulkEditable: true });

        onClickBulkEditable && onClickBulkEditable(); // custom onClick for fields with additional stuff
    };

    onReset = () => {
        const { onReset } = this.props;
        onReset && onReset(null);
        this.setState({ bulkEditable: false });
    };

    toggleField = () => {
        this.setState({ isToggled: !this.state.isToggled });
    };

    render() {
        let {
            label,
            mandatory,
            children,
            className,
            parentClassName,
            description,
            labelMode,
            isBulkAction,
            isHighlighted,
            isBulkFieldsWrapper,
            isDuplicate,
            canToggle,
            fieldExtraValue,
        } = this.props;

        const { bulkEditable } = this.state;

        let classes = ['fm-field-container', parentClassName, labelMode || 'horizontal'];
        if (isHighlighted) classes.push('fm-field-container--highlighted');

        let classesToggle = ['fm-field-toggle'];
        if (this.state.isToggled) classesToggle.push('fm-field-toggle--collapsed');

        let fieldInputClasses = ['fm-field-input'];
        if (className) fieldInputClasses.push(className);
        if (isDuplicate) fieldInputClasses.push('fm-field-input__duplicate');
        if (canToggle) fieldInputClasses.push('fm-field-input__can-toggle');
        if (canToggle && this.state.isToggled) fieldInputClasses.push('fm-field-input__collapsed');

        return (
            <div className={classes.join(' ')}>
                {(label || label === '') && (
                    <div className="fm-field-label">
                        {label}
                        {!isBulkAction && mandatory && (
                            <span className="fm-asterisk-mandatory">*</span>
                        )}
                        {description && (
                            <span className="fm-field-description">
                                <Tooltip
                                    containerClassName="fm-field-description-tooltip"
                                    title={description}
                                    position="top"
                                >
                                    <div className="fm-field-description-icon">
                                        <Info color={colors.grey[500]} />
                                    </div>
                                </Tooltip>
                            </span>
                        )}
                        {fieldExtraValue && (
                            <span className="fm-field-extra-value">
                                <TooltipHoi
                                    className="fm-field-extra-value-tooltip"
                                    content={fieldExtraValue}
                                    placement="right"
                                >
                                    <div className="fm-field-extra-value-icon">
                                        <Info color={colors.semantic.negative450} />
                                    </div>
                                </TooltipHoi>
                            </span>
                        )}
                        {!isBulkAction && canToggle && (
                            <span className={classesToggle.join(' ')} onClick={this.toggleField}>
                                <ChevronUp />
                            </span>
                        )}
                    </div>
                )}
                <div className={fieldInputClasses.join(' ')}>
                    {isBulkAction && !bulkEditable && (
                        <span className="fm-field-bulk" onClick={this.openBulkFieldsWrapper()}>
                            {getLiteral('placeholder_click_to_edit')}
                        </span>
                    )}
                    {(!isBulkAction || bulkEditable) && children}
                    {isBulkAction && bulkEditable && !isBulkFieldsWrapper && (
                        <div className="fm-field-input-bulk-cancel" onClick={this.onReset}>
                            {getLiteral('action_cancel')}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

Base.propTypes = propTypes;

export default Base;
