import { memo, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { EntityModalActions } from 'actions';
import { ANALYTICS_REPORT } from 'constants/Entities';
import { FORCEBI } from 'constants/Features';
import { getLiteral } from 'utils/getLiteral';
import EntityMenu from 'containers/components/EntityMenu';
import SearchMetrics from './SearchMetrics';

const MetricsMenu = ({ onChange, pathItemSelected, pathItems, onCreateFolder, onSearch }) => {
    const dispatch = useDispatch();
    const analyticsPro = useSelector((state) => state.config?.permission?.analyticsPro);
    const analyticsProCreateDashboard = useSelector(
        (state) => state.config?.permission?.analyticsProCreateDashboard || false,
    );
    const hasFORCEBI = useSelector((state) => state.config.flagsmith?.[FORCEBI] || false);

    const canCreateDashboard = useMemo(() => {
        return analyticsPro && analyticsProCreateDashboard && hasFORCEBI;
    }, [analyticsPro, analyticsProCreateDashboard, hasFORCEBI]);

    const addOptions = useMemo(() => {
        let addButtonOptions = [];
        if (onCreateFolder)
            addButtonOptions.push({
                onClick: onCreateFolder,
                title: getLiteral('action_add_folder'),
                description: getLiteral('label_analytics_pro_new_folder_desc'),
                icon: 'newFolder',
            });

        if (canCreateDashboard)
            addButtonOptions.push({
                onClick: () =>
                    dispatch(
                        EntityModalActions.init({
                            entity: ANALYTICS_REPORT,
                            trackingSubmodule: 'forceBI',
                            data: {
                                folderId: pathItemSelected?.id,
                            },
                            labels: {
                                title: getLiteral('label_create_dashboard'),
                                success: getLiteral('succes_entitycreatedsuccessfully'),
                                error: getLiteral('label_failed_create'),
                            },
                            version: 2,
                        }),
                    ),
                title: getLiteral('label_looker_new_report'),
                description: getLiteral('label_analytics_pro_new_report_desc'),
                icon: 'newReport',
            });

        if (!addButtonOptions.length) return null;

        return {
            addButtonOptions,
        };
    }, [canCreateDashboard, dispatch, onCreateFolder, pathItemSelected?.id]);

    return (
        <div className="fm-metrics">
            <EntityMenu
                withBreadcrumb
                canCreate={!!onCreateFolder || canCreateDashboard}
                folderSelected={pathItemSelected}
                folderSelectedArr={pathItems}
                onClickBreadcrumb={onChange}
                searchComponent={<SearchMetrics onSearch={onSearch} />}
                {...addOptions}
            />
        </div>
    );
};

export default memo(MetricsMenu);
