import { FUZZY_INIT, FUZZY_INIT_SUCCESS, FUZZY_CLEAR } from 'constants/ActionTypes';
import Context from 'managers/Context';
import { getSrcUserCircleAvatar } from 'utils/getSrcAvatar';

export function getEntityCustomization(item, list) {
    // TODO Add more entities
    switch (list) {
        case 'usuarios':
            const { src } = getSrcUserCircleAvatar(item.id || item.Id || item.value);
            return {
                src,
            };
        default:
            return {};
    }
}

function transformData({ data = [], list }) {
    return data.map((item) => ({
        ...item,
        value: item.Id,
        label: item.MatchingInfo,
        ...getEntityCustomization(item, list),
    }));
}

export function clearFuzzy(entity, field) {
    return function (dispatch) {
        dispatch({
            type: FUZZY_CLEAR,
            entity,
            field,
        });
    };
}

export function getFuzzy({ list, field, text, feature, parentField, parentValue, idParent }) {
    return function (dispatch, getState) {
        // Check if we have value already
        let fuzzyName = list;
        if (field) fuzzyName += `_${field}`;
        if (parentField && parentValue) {
            fuzzyName += `_${parentField}_${parentValue}`;
        }
        if (idParent) fuzzyName += `_idParent-${idParent}`;
        const state = getState().fuzzy;
        if (
            state &&
            state[fuzzyName] &&
            state[fuzzyName].data.length > 0 &&
            state[fuzzyName].text === text
        ) {
            return Promise.resolve(state[fuzzyName].data);
        }

        return new Promise((resolve, reject) => {
            dispatch({
                type: FUZZY_INIT,
                text,
                list,
                field,
                feature,
                parentField,
                parentValue,
                idParent,
            });
            Context.domainManager.autoCompleteSearch(
                list,
                field,
                text,
                feature,
                parentField,
                parentValue,
                idParent,
                (data) => {
                    const finalData = transformData({ data, list });
                    dispatch({
                        type: FUZZY_INIT_SUCCESS,
                        data: finalData,
                        text,
                        list,
                        field,
                        feature,
                        parentField,
                        parentValue,
                        idParent,
                    });
                    resolve(finalData);
                },
                (err) => {
                    console.error(err);
                    reject(err);
                },
            );
        });
    };
}

export function getFuzzyCompaniesCampaigns({ text, idCampaign }) {
    return function () {
        return new Promise((resolve, reject) => {
            Context.domainManager.autoCompleteSearchCompaniesCampaign(
                text,
                idCampaign,
                (data) => {
                    const finalData = transformData({ data, list: 'empresas' });
                    resolve(finalData);
                },
                (err) => {
                    console.error(err);
                    reject(err);
                },
            );
        });
    };
}
