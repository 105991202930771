import { memo } from 'react';

const LinkEmptyImage = (props) => (
    <svg
        width={168}
        height={144}
        viewBox="0 0 168 144"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M77.7146 47.1066C76.8799 47.1066 76.0614 46.7017 75.5659 45.9538C74.7798 44.7685 75.1039 43.1706 76.2901 42.3846L79.1503 40.4883C80.3357 39.7022 81.9338 40.0263 82.7198 41.2117C83.5059 42.3967 83.1818 43.9948 81.9965 44.7809L79.1362 46.6769C78.6989 46.9666 78.2045 47.1055 77.7154 47.1055L77.7146 47.1066Z"
            fill="#474747"
        ></path>
        <path
            d="M80.5732 44.1298C81.3988 44.1298 82.0682 43.4606 82.0682 42.635C82.0682 41.8094 81.3988 41.14 80.5732 41.14C79.7476 41.14 79.0781 41.8094 79.0781 42.635C79.0781 43.4606 79.7476 44.1298 80.5732 44.1298Z"
            fill="#B26E3D"
        ></path>
        <path
            d="M81.3213 41.1399C81.3004 42.4052 80.0149 43.9677 78.4952 43.8392C76.9756 43.7105 78.3885 41.5268 78.3885 41.5268L80.6789 40.6701L81.3213 41.1388V41.1399Z"
            fill="#474747"
        ></path>
        <path
            d="M63.4238 63.6635L75.2205 48.2239L78.8166 48.0096L80.5725 51.478L78.1525 65.6044L63.4238 63.6635Z"
            fill="#777777"
        ></path>
        <path
            d="M81.0031 55.3655C80.8944 55.3655 80.7848 55.3587 80.6744 55.3443C79.2632 55.1642 78.2657 53.8752 78.4458 52.464C78.7039 50.4373 78.3323 50.2145 77.8616 49.9324C77.4186 49.6665 76.9717 49.5597 76.2353 49.9C75.5712 50.2069 74.8117 50.8185 73.979 51.7189C73.0128 52.7633 71.3845 52.826 70.3403 51.8609C69.2959 50.8947 69.2329 49.2664 70.1983 48.2222C71.5093 46.8043 72.7784 45.8239 74.0761 45.2245C76.2726 44.2097 78.4964 44.3099 80.5067 45.5133C83.852 47.5152 83.8481 50.8108 83.5547 53.1148C83.389 54.4154 82.2807 55.3643 81.0039 55.3643L81.0031 55.3655Z"
            fill="#777777"
        ></path>
        <path
            d="M107.994 49.5047L113.968 66.0799L95.5605 64.8268L95.8731 53.841L102.555 48.1556L107.994 49.5047Z"
            fill="#C6C6C6"
        ></path>
        <path
            d="M104.525 41.0922C105.346 41.0922 106.012 40.4264 106.012 39.6051C106.012 38.7834 105.346 38.1176 104.525 38.1176C103.703 38.1176 103.037 38.7834 103.037 39.6051C103.037 40.4264 103.703 41.0922 104.525 41.0922Z"
            fill="#E84D4F"
        ></path>
        <path
            d="M104.524 38.1176C104.305 38.1176 104.098 38.1662 103.91 38.2509C104.381 39.1115 105.008 39.7392 106.01 39.6164C106.01 39.6127 106.01 39.6088 106.01 39.6051C106.01 38.7837 105.344 38.1176 104.523 38.1176H104.524Z"
            fill="#474747"
        ></path>
        <path
            d="M110.123 73.1554C108.315 73.1554 106.751 71.8032 106.531 69.9634C106.3 68.0329 104.272 51.0901 104.145 49.5312C104.145 49.5284 104.143 49.5256 104.142 49.5227C104.095 49.5064 103.98 49.477 103.765 49.477C103.45 49.477 103.123 49.477 102.583 50.889C101.867 52.7574 99.7728 53.6911 97.9044 52.9768C96.0358 52.261 95.1012 50.1668 95.8167 48.2985C96.4178 46.728 97.1717 45.4989 98.1196 44.5415C99.6155 43.0312 101.568 42.2327 103.764 42.2327C106.589 42.2327 108.363 43.4541 109.352 44.4793C110.518 45.6858 111.232 47.2721 111.366 48.9473C111.447 49.9686 112.821 61.5594 113.724 69.1019C113.962 71.0886 112.544 72.8914 110.558 73.1297C110.412 73.1466 110.266 73.1554 110.122 73.1554H110.123Z"
            fill="#C6C6C6"
        ></path>
        <path
            d="M152.221 100.235H15.8378C15.2156 100.235 14.8774 99.5074 15.2804 99.0319L41.7494 67.7777C42.1478 67.3079 42.732 67.0374 43.3475 67.0374H124.712C125.327 67.0374 125.912 67.3079 126.31 67.7777L152.779 99.0319C153.181 99.5062 152.844 100.235 152.221 100.235H152.221Z"
            fill="#00D639"
        ></path>
        <path
            d="M149.32 97.9801C113.752 95.9763 97.9365 97.5208 84.158 98.6002C70.3803 97.5208 54.5646 95.9763 18.9961 97.9801L45.3986 66.6915C60.7693 62.4601 72.8978 64.8697 84.1571 68.8057C95.4165 64.8709 107.545 62.4601 122.915 66.6915L149.318 97.9801H149.32Z"
            fill="#474747"
        ></path>
        <path
            d="M121.632 66.0531C106.262 61.8217 95.4177 63.6463 84.1583 67.5815C72.8989 63.6463 62.0548 61.8217 46.6841 66.0531L20.1445 97.4798C45.7761 93.7694 63.0849 94.5337 84.1591 97.0797C105.234 94.5337 122.541 93.7694 148.174 97.4798L121.634 66.0531H121.632Z"
            fill="#777777"
        ></path>
        <path
            d="M84.2832 65.0316V96.5002C101.777 91.1482 124.041 91.9475 146.115 96.1961L119.51 65.0316C107.297 61.1964 95.5426 61.0965 84.2832 65.0316Z"
            fill="#E2E2E2"
        ></path>
        <path
            d="M84.158 96.5878L83.0625 95.551V65.71L84.158 65.1193L88.5353 78.1081L84.158 96.5878Z"
            fill="#E2E2E2"
        ></path>
        <path
            d="M84.158 65.1193V96.5878C66.6643 91.2358 44.3999 92.0352 22.3262 96.2837L48.9316 65.1193C61.1437 61.2841 72.8986 61.1842 84.158 65.1193Z"
            fill="#E2E2E2"
        ></path>
        <path
            d="M84.1583 97.3988C81.7784 97.3816 81.0762 98.668 81.0762 98.668H87.2416C87.2416 98.668 86.5394 97.3816 84.1595 97.3988H84.1583Z"
            fill="#00D639"
        ></path>
        <path
            d="M79.3519 80.7504C79.3186 80.7504 79.2853 80.7476 79.252 80.7408C70.8099 79.0735 67.8706 78.9877 58.9638 79.3488C58.6789 79.3592 58.4397 79.1393 58.4281 78.8552C58.4169 78.5712 58.6379 78.3312 58.9217 78.3196C67.9268 77.9548 70.9005 78.0415 79.451 79.73C79.7303 79.785 79.9113 80.0558 79.857 80.335C79.8085 80.5798 79.593 80.7504 79.3519 80.7504Z"
            fill="#ABABAB"
        ></path>
        <path
            d="M78.5212 83.8975C78.4802 83.8975 78.4373 83.8927 78.3952 83.8831C68.7215 81.6918 57.2668 82.4767 48.3684 83.4068C48.0578 83.4393 47.7794 83.2134 47.7472 82.9028C47.7148 82.5922 47.9407 82.3138 48.2512 82.2816C57.2352 81.3431 68.8099 80.5522 78.6448 82.7797C78.9498 82.8483 79.1404 83.1515 79.0718 83.4562C79.0128 83.7193 78.7792 83.8975 78.5212 83.8975Z"
            fill="#ABABAB"
        ></path>
        <path
            d="M58.8334 69.77C58.8334 68.6824 57.9516 67.8006 56.8639 67.8006C55.7763 67.8006 54.8945 68.6824 54.8945 69.77V71.7281C54.8945 72.8157 55.7763 73.6975 56.8639 73.6975C57.9516 73.6975 58.8334 72.8157 58.8334 71.7281V69.77Z"
            fill="#474747"
        ></path>
        <path
            d="M58.8339 71.834C57.9648 71.834 57.2578 71.127 57.2578 70.2582C57.2578 69.3891 57.9648 68.6821 58.8339 68.6821C59.7027 68.6821 60.4097 69.3891 60.4097 70.2582C60.4097 71.127 59.7027 71.834 58.8339 71.834ZM58.8339 69.4549C58.3909 69.4549 58.0306 69.8149 58.0306 70.2582C58.0306 70.7012 58.3909 71.0612 58.8339 71.0612C59.2769 71.0612 59.6369 70.7012 59.6369 70.2582C59.6369 69.8149 59.2769 69.4549 58.8339 69.4549Z"
            fill="#474747"
        ></path>
        <path
            d="M110.384 69.5328H94.7615C94.4376 69.5328 94.1491 69.7395 94.0452 70.0464L93.4353 71.8365C93.2687 72.3284 93.6335 72.838 94.1519 72.838H110.993C111.512 72.838 111.877 72.3284 111.709 71.8365L111.1 70.0464C110.995 69.7395 110.707 69.5328 110.383 69.5328H110.384Z"
            fill="#5E5E5E"
        ></path>
        <path
            d="M88.1367 84.7835L90.5988 78.1082H98.6482L97.2494 84.8987L88.1367 84.7835Z"
            fill="#E2E2E2"
        ></path>
        <path
            d="M95.5959 82.0184C95.3988 82.0184 95.2099 81.9041 95.1244 81.7115C95.0089 81.4515 95.1272 81.1477 95.3872 81.0322L100.585 78.7341C100.845 78.6189 101.149 78.7369 101.264 78.997C101.38 79.2573 101.261 79.5611 101.001 79.6763L95.8037 81.9747C95.7359 82.004 95.6656 82.0184 95.5959 82.0184Z"
            fill="#474747"
        ></path>
        <path
            d="M94.452 69.3329C93.9376 69.3329 93.4144 69.2222 92.9169 68.9898L90.7933 67.9943C88.9953 67.1517 88.2112 65.0194 89.0334 63.2129C89.0904 63.0872 94.7722 50.6092 95.8937 48.1098C96.712 46.2844 98.8559 45.4687 100.682 46.2881C102.507 47.1066 103.323 49.2503 102.503 51.076C101.715 52.8319 98.7949 59.2529 97.0209 63.1519C98.065 64.2 98.3985 65.8295 97.7344 67.2463C97.1188 68.5601 95.8135 69.3318 94.452 69.3318V69.3329Z"
            fill="#C6C6C6"
        ></path>
        <path
            d="M98.1535 69.3193C97.9421 69.3193 97.7266 69.2764 97.52 69.1858L95.7801 68.4246C94.9796 68.0739 94.6148 67.1413 94.9652 66.3399C95.3159 65.5386 96.2488 65.1747 97.0501 65.5251L98.79 66.2866C99.5904 66.637 99.9552 67.5699 99.6046 68.3712C99.3445 68.9647 98.7631 69.3193 98.1544 69.3193H98.1535Z"
            fill="#E84D4F"
        ></path>
        <path
            d="M99.0177 71.0937C98.8814 71.0937 98.7526 71.0126 98.6984 70.8782L98.1495 69.5178L97.0464 68.9156C96.8795 68.8241 96.8185 68.6154 96.9091 68.4486C97.0006 68.282 97.2093 68.221 97.3759 68.3114L98.5869 68.9726C98.6566 69.0107 98.7108 69.0717 98.7413 69.146L99.3368 70.621C99.4082 70.7975 99.3224 70.9974 99.1462 71.0688C99.1044 71.086 99.0604 71.0937 99.0177 71.0937Z"
            fill="#E84D4F"
        ></path>
        <path
            d="M99.7165 70.7153C99.5728 70.7153 99.4384 70.6238 99.3899 70.479L98.9372 69.1081L98.0673 68.5353C97.9081 68.4305 97.8643 68.217 97.9691 68.0589C98.0738 67.9008 98.2873 67.857 98.4457 67.961L99.4156 68.5993C99.4802 68.642 99.5288 68.7049 99.5528 68.7784L100.044 70.2627C100.103 70.4428 100.005 70.6374 99.8253 70.6972C99.7891 70.7088 99.7527 70.7145 99.7177 70.7145L99.7165 70.7153Z"
            fill="#E84D4F"
        ></path>
        <path
            d="M100.206 70.3341C100.05 70.3341 99.9082 70.2266 99.872 70.0673L99.4318 68.1589L98.3352 67.6207C98.1646 67.5369 98.094 67.331 98.1779 67.1605C98.2618 66.99 98.4676 66.9194 98.6381 67.0032L99.8816 67.614C99.974 67.6597 100.042 67.7444 100.065 67.8455L100.543 69.9132C100.586 70.0978 100.47 70.2828 100.285 70.3257C100.259 70.3313 100.232 70.3341 100.207 70.3341H100.206Z"
            fill="#E84D4F"
        ></path>
        <path
            d="M100.665 69.0059C100.518 69.0059 100.383 68.9113 100.336 68.7637L99.9548 67.5414L98.2835 66.8171C98.1093 66.742 98.0291 66.539 98.1045 66.3645C98.1796 66.1903 98.3826 66.1101 98.5571 66.1855L100.373 66.9724C100.464 67.0125 100.535 67.0907 100.565 67.1859L100.992 68.559C101.049 68.74 100.948 68.9334 100.767 68.9896C100.732 69 100.698 69.0048 100.665 69.0048V69.0059Z"
            fill="#E84D4F"
        ></path>
        <path
            d="M73.2059 66.1646C73.1249 66.1646 73.0419 66.159 72.9591 66.1457L66.1426 65.0976C65.2642 64.9623 64.661 64.141 64.7963 63.2623C64.9315 62.3839 65.7529 61.7816 66.6313 61.916L73.4479 62.9641C74.3263 63.0994 74.9294 63.9207 74.7941 64.7991C74.6722 65.5948 73.9863 66.1646 73.2059 66.1646Z"
            fill="#B26E3D"
        ></path>
        <path
            d="M65.9958 65.8769C65.8357 65.8769 65.6739 65.8616 65.511 65.8312L61.1586 65.0022C60.2658 64.8328 59.5282 64.2037 59.2185 63.349C58.9088 62.4943 59.0728 61.5397 59.6502 60.8375C60.0142 60.3944 68.5819 49.9688 70.197 48.2231C71.1629 47.1789 72.7912 47.116 73.8356 48.0813C74.8798 49.0472 74.9427 50.6759 73.9777 51.72C73.0657 52.7051 69.3163 57.2157 66.3922 60.7556L66.4753 60.7717C67.8721 61.0376 68.7894 62.3859 68.5226 63.7827C68.2874 65.0166 67.208 65.876 65.9958 65.8769Z"
            fill="#777777"
        ></path>
        <path
            d="M77.851 69.2668C77.3156 69.2668 76.7752 69.1002 76.3121 68.7552C75.1726 67.9045 74.9382 66.2914 75.7892 65.1518L77.5596 62.7792C77.7197 60.7098 78.1731 54.9498 78.438 52.5126C78.5913 51.0988 79.8624 50.0764 81.2753 50.2306C82.6894 50.3842 83.7109 51.6542 83.5573 53.0682C83.2478 55.9217 82.6428 63.8377 82.6371 63.9179C82.5998 64.4047 82.4256 64.8706 82.1331 65.2622L79.9158 68.2331C79.4098 68.9105 78.6353 69.268 77.8501 69.268L77.851 69.2668Z"
            fill="#777777"
        ></path>
        <path
            d="M69.7975 76.2511C69.7718 76.2511 69.745 76.2491 69.7185 76.2443C69.4734 76.2017 69.3096 75.9674 69.3525 75.7223C69.4649 75.0867 69.6928 73.97 69.9613 73.6405C70.1956 73.3528 71.0371 72.3075 71.0371 72.3075L71.7393 72.8725C71.7393 72.8725 70.9066 73.9062 70.6655 74.2026C70.5579 74.3752 70.3586 75.205 70.2405 75.8776C70.2024 76.0958 70.0118 76.2503 69.7975 76.2503V76.2511Z"
            fill="#B26E3D"
        ></path>
        <path
            d="M72.0867 75.177C71.7216 75.177 71.3549 75.0542 71.0539 74.8006C70.3726 74.23 70.2839 73.2144 70.8545 72.5331L76.6202 65.6575C77.1908 64.9765 78.2064 64.8878 78.8877 65.4584C79.5691 66.0291 79.6577 67.045 79.0868 67.726L73.3214 74.6016C73.0032 74.9808 72.5469 75.177 72.0876 75.177H72.0867Z"
            fill="#B26E3D"
        ></path>
        <path
            d="M141.378 51.4171H103.237C101.892 51.4171 100.801 52.5078 100.801 53.8532V76.3197C100.801 77.6651 101.892 78.7559 103.237 78.7559H141.378C142.724 78.7559 143.814 77.6651 143.814 76.3197V53.8532C143.814 52.5078 142.724 51.4171 141.378 51.4171Z"
            fill="#474747"
        ></path>
        <path
            d="M124.23 72.0014C124.23 70.9406 123.37 70.0806 122.309 70.0806C121.248 70.0806 120.389 70.9406 120.389 72.0014V82.9607C120.389 84.0215 121.248 84.8815 122.309 84.8815C123.37 84.8815 124.23 84.0215 124.23 82.9607V72.0014Z"
            fill="#474747"
        ></path>
    </svg>
);
const Memo = memo(LinkEmptyImage);
export default Memo;
