import { useCallback, useMemo, useState } from 'react';
import { Text, Link, useTheme } from 'hoi-poi-ui';
import { PRODUCTS, SALESORDERS, SALESORDERSLINES } from 'constants/Entities';
import { PRODUCT_MODE_CODES } from 'constants/Constants';
import { getPartner } from 'lib/partners';
import Context from 'managers/Context';
import TrialExpiredModal from 'containers/components/TrialExpiredModal';
import { BillingService } from 'services';
import { formatDate } from 'utils/dates';
import { isFreeTrial } from 'utils/fm';
import {
    getLiteral,
    getLiteralWithParameters,
    getLiteralWithParametersHtml,
} from 'utils/getLiteral';
import { logEvent } from 'utils/tracking';
import { trackEvent } from 'utils/intercom';
import * as Userflow from 'utils/userflow';
import { errorToast } from 'utils/toast';
import { ADDON_SALESORDERS, INTERCOM_METADATA, PERMISSIONS } from '../AddOnsManagement/constants';
import ContactUs from '../ContactUs';
import plansInfo from './plansInfo';
import PurchaseModal from '../PurchaseModal';

const { purchaseAddon } = BillingService;

export const EMAIL_TYPES = {
    CONTACT_US: 'CONTACT_US',
    TRY_ADDON: 'TRY_ADDON',
    DISABLE_ADDON: 'DISABLE_ADDON',
    PURCHASE_ADDON: 'PURCHASE_ADDON',
};

export const checkURLContentType = (url) => {
    return new Promise((resolve) => {
        const extension = url.split('.').pop();
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];

        if (imageExtensions.includes(extension)) {
            resolve('image');
        } else {
            resolve('video');
        }
    });
};

const useSettings = ({ submodule } = {}) => {
    const { dispatch, getState } = Context.store;
    const { ConfigActions } = Context.actions;
    const state = getState();
    const config = state.config;

    const theme = useTheme();

    const { billingPlatformPlanCode, permission = {}, productModeCode } = config;
    const {
        canConfigImplementation,
        viewAddonsConfiguration,
        viewLimitsConfiguration,
        crud_permission,
        enableIntercomOption,
    } = permission;

    const [contactAction, setContactAction] = useState(null);
    const [showContactUs, setShowContactUs] = useState(false);
    const [trialModalOpen, setTrialModalOpen] = useState(false);
    const [modalPurchase, setModalPurchase] = useState(null);
    const [forceUpgradePlan, setForceUpgradePlan] = useState(null);

    const isAdmin = useMemo(() => !!canConfigImplementation, [canConfigImplementation]);

    const isKitDigital = useMemo(
        () => productModeCode === PRODUCT_MODE_CODES.KITDIGITAL,
        [productModeCode],
    );

    const isSage = useMemo(() => productModeCode === PRODUCT_MODE_CODES.SAGE, [productModeCode]);

    const hasLimits = useMemo(
        () => productModeCode !== 'business' && viewLimitsConfiguration,
        [productModeCode, viewLimitsConfiguration],
    );

    const hasLimitsAndAddons = useMemo(
        () => hasLimits && viewAddonsConfiguration,
        [hasLimits, viewAddonsConfiguration],
    );

    const dismissModal = useCallback(() => {
        setShowContactUs(false);
        setTrialModalOpen(false);
        setModalPurchase(null);
    }, []);

    const handleComparePlans = useCallback(() => {
        logEvent({
            event: 'settings',
            submodule,
            functionality: 'comparePlan',
        });
        window.open('https://www.forcemanager.com/pricing/', '_blank');
    }, [submodule]);

    const handleAddOnPurchase = useCallback((itemCode) => {
        purchaseAddon(itemCode)
            .then(() => {
                if (isFreeTrial()) {
                    window.Intercom && window.Intercom('showNewMessage');
                } else {
                    setContactAction(EMAIL_TYPES.PURCHASE_ADDON);
                    setShowContactUs(true);
                }
                trackEvent('buyAddon', INTERCOM_METADATA[itemCode]);
                Userflow.track('addon_purchased', { code: itemCode });
            })
            .catch(() => {
                errorToast({
                    text: getLiteral('error_an_error_occurred'),
                });
            });
    }, []);

    const handleContactRequest = useCallback(
        ({ onSuccess, onError, action, relatedItemLabel } = {}) => {
            let emailData;
            const userData = config.userData;
            const { email, entorno, idImplementacion, nic } = userData;

            const context = {
                limits: getLiteral('label_limit'),
                addons: getLiteral('label_addon'),
                integrations: getLiteral('label_integration'),
            }[submodule];

            const date = formatDate(new Date(), 'DD/MM/YYYY');
            const license = config.license;

            switch (action) {
                case EMAIL_TYPES.CONTACT_US:
                    break;
                case EMAIL_TYPES.TRY_ADDON:
                    emailData = {
                        email: isFreeTrial()
                            ? getLiteral('email_try_addon_free_trial')
                            : getLiteral('email_try_addon'),
                        subject: getLiteralWithParameters('label_subject_email_addon_try', [
                            entorno,
                        ]),
                        body: getLiteralWithParameters('label_body_email_addon_try', [
                            nic,
                            email,
                            relatedItemLabel,
                            date,
                            idImplementacion,
                            license,
                        ]),
                    };
                    break;
                case EMAIL_TYPES.DISABLE_ADDON:
                    emailData = {
                        email: isFreeTrial()
                            ? getLiteral('email_disable_addon_free_trial')
                            : getLiteral('email_disable_addon'),
                        subject: getLiteralWithParameters('label_subject_email_addon_disable', [
                            entorno,
                        ]),
                        body: getLiteralWithParameters('label_body_email_addon_disable', [
                            nic,
                            email,
                            relatedItemLabel,
                            date,
                            idImplementacion,
                            license,
                        ]),
                    };
                    break;
                default:
                    emailData = {
                        email: isFreeTrial()
                            ? getLiteral('email_contact_us_addon_free_trial')
                            : getLiteral('email_contact_us_addon'),
                        subject: getLiteralWithParameters('label_subject_email_addon_contact_us', [
                            entorno,
                        ]),
                        body: getLiteralWithParameters('label_body_email_addon_contact_us', [
                            nic,
                            email,
                            context,
                            relatedItemLabel,
                            date,
                            idImplementacion,
                            license,
                        ]),
                    };
                    break;
            }

            if (emailData) {
                Context.domainManager.sendMailSimple(
                    emailData,
                    () => {
                        if (action !== EMAIL_TYPES.DISABLE_ADDON) {
                            if (isFreeTrial() || !action) {
                                window.Intercom && window.Intercom('showNewMessage');
                            } else {
                                setContactAction(action || null);
                                setShowContactUs(true);
                            }
                        }
                        onSuccess && onSuccess();
                    },
                    () => {
                        errorToast({
                            text: getLiteral('error_an_error_occurred'),
                        });
                        onError && onError();
                    },
                );
            } else {
                window.Intercom && window.Intercom('showNewMessage');
                onSuccess && onSuccess();
            }

            logEvent({
                event: 'settings',
                submodule,
                functionality: 'contactUs',
            });
        },
        [config, submodule],
    );

    const handleUpgradePlan = useCallback(
        (plan) => {
            plan && setForceUpgradePlan(plan);
            setTrialModalOpen(true);
            logEvent({
                event: 'settings',
                submodule,
                functionality: 'Upgrade',
            });
        },
        [submodule],
    );

    const handleUpdatePermissions = useCallback(
        (addOn) => {
            const newPermissions = PERMISSIONS[addOn];

            if (!newPermissions) return;

            if (addOn === ADDON_SALESORDERS) {
                const entities = [
                    PRODUCTS.permission,
                    SALESORDERS.permission,
                    SALESORDERSLINES.permission,
                ];

                const newCrudPermission = { ...crud_permission };

                entities.forEach((entity) => {
                    newCrudPermission[entity] = {
                        entity,
                        create: true,
                        delete: true,
                        update: true,
                    };
                });

                newPermissions.push({
                    key: 'crud_permission',
                    value: newCrudPermission,
                });
            }

            dispatch(ConfigActions.updateSpecificPermissions(newPermissions));
        },
        [ConfigActions, crud_permission, dispatch],
    );

    const planName = useMemo(() => {
        return plansInfo[billingPlatformPlanCode]?.name;
    }, [billingPlatformPlanCode]);

    const renderUpgradeModal = useMemo(() => {
        const props = {
            forceOpen: trialModalOpen,
            setModalOpen: setTrialModalOpen,
            onComparePlans: handleComparePlans,
            forceUpgradePlan,
        };
        return <TrialExpiredModal {...props} />;
    }, [forceUpgradePlan, handleComparePlans, trialModalOpen]);

    const renderContactModal = useMemo(() => {
        const props = {
            isOpen: showContactUs,
            action: contactAction,
            onClose: dismissModal,
            onConfirm: dismissModal,
        };
        return <ContactUs {...props} />;
    }, [contactAction, dismissModal, showContactUs]);

    const renderPurchaseModal = useMemo(() => {
        if (!modalPurchase) return null;

        const { type, payload } = modalPurchase;
        const modalProps = { type, modalMeta: payload, onCancel: dismissModal };

        return <PurchaseModal {...modalProps} />;
    }, [dismissModal, modalPurchase]);

    const renderSupportChat = useMemo(() => {
        return (
            enableIntercomOption && (
                <Text type="body" color={theme.colors.utility.textSecondary}>
                    {getLiteralWithParametersHtml(
                        'label_description_header_support',
                        [getLiteral('action_support_chat')],
                        (text) => (
                            <Link
                                variation="primary"
                                onClick={() => window.Intercom && window.Intercom('showNewMessage')}
                            >
                                {text}
                            </Link>
                        ),
                    )}
                </Text>
            )
        );
    }, [enableIntercomOption, theme]);

    return {
        checkURLContentType,
        dismissModal,
        handleAddOnPurchase,
        handleContactRequest,
        handleComparePlans,
        handleModalPurchase: setModalPurchase,
        handleUpdatePermissions,
        handleUpgradePlan,
        hasLimits,
        hasLimitsAndAddons,
        isAdmin,
        isKitDigital,
        isSage,
        planName,
        renderContactModal,
        renderUpgradeModal,
        renderPurchaseModal,
        showComparePlans: getPartner()?.settings?.showComparePlans,
        renderSupportChat,
    };
};

export default useSettings;
