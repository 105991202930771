import React from 'react';
import colors from 'constants/colors';

const Campaigns = ({ color = colors.actionMinor[500] }) => (
    <svg viewBox={`0 0 18 18`} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9 5.143c.218 0 .425.017.632.052a.856.856 0 1 0 .287-1.69A5.578 5.578 0 0 0 3.43 9c0 3.072 2.499 5.571 5.57 5.571a5.578 5.578 0 0 0 5.496-6.49.857.857 0 1 0-1.69.286A3.862 3.862 0 0 1 9 12.857 3.862 3.862 0 0 1 5.143 9 3.862 3.862 0 0 1 9 5.143m8.493.874a.858.858 0 0 0-1.618.566c.273.778.41 1.59.41 2.417 0 4.017-3.268 7.286-7.285 7.286S1.714 13.017 1.714 9c0-4.017 3.269-7.286 7.286-7.286.826 0 1.64.138 2.417.41a.856.856 0 1 0 .566-1.617A9.009 9.009 0 0 0 9 0C4.037 0 0 4.037 0 9s4.037 9 9 9 9-4.037 9-9c0-1.02-.17-2.023-.507-2.983m-7.396 1.152l3.228-3.521.83-3.324A.429.429 0 0 1 15 .428V1.74l1.509-.83a.428.428 0 0 1 .582.582L16.26 3h1.311a.429.429 0 0 1 .104.844l-3.323.831-3.521 3.228c.193.322.312.695.312 1.097A2.145 2.145 0 0 1 9 11.143 2.145 2.145 0 0 1 6.857 9c0-1.182.961-2.143 2.143-2.143.402 0 .775.118 1.097.312"
            fill={color || '#335B70'}
            fillRule="evenodd"
        />
    </svg>
);

export default Campaigns;
