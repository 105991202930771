import React from 'react';
import colors from 'constants/colors';

const ExtraFieldsIcon = ({ color = colors.actionMinor[500] }) => (
    <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.065 12.123A6.09 6.09 0 0 0 3.082 14H2.7C1.209 14 0 12.88 0 11.5v-5C0 5.12 1.209 4 2.7 4h12.6C16.791 4 18 5.12 18 6.5v5c0 1.38-1.209 2.5-2.7 2.5h-.384a6.08 6.08 0 0 0 .022-1.878c.67-.146 1.162-.638 1.162-1.222V7.1c0-.7-.706-1.267-1.578-1.267H3.478C2.606 5.833 1.9 6.4 1.9 7.1v3.8c0 .585.494 1.077 1.165 1.223zm6.392 1.32h2.056c.184 0 .332-.186.332-.416 0-.229-.148-.414-.332-.414H9.457v-2.095c0-.183-.188-.331-.42-.331-.232 0-.42.148-.42.331v2.095H6.532c-.183 0-.332.185-.332.414 0 .23.149.415.332.415h2.085v2.048c0 .183.188.332.42.332.232 0 .42-.149.42-.332v-2.048zm3.079 3.093c-1.964 1.955-5.13 1.95-7.072 0-1.955-1.964-1.949-5.13 0-7.072 1.964-1.955 5.13-1.95 7.072 0 1.955 1.964 1.95 5.13 0 7.072z"
            fill={colors[color]}
            fillRule="evenodd"
        />
    </svg>
);

export default ExtraFieldsIcon;
