import React, { memo, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { EntityFiltersActions } from 'actions';
import { Text, useTheme } from 'hoi-poi-ui';

import './styles.scss';

const mapStateToProps = (state, ownProps) => {
    const entity = ownProps.entity?.entity;

    const matchingName =
        state.entityFilters &&
        state.entityFilters[entity] &&
        state.entityFilters[entity].filters &&
        state.entityFilters[entity].filters.matchingName
            ? state.entityFilters[entity].filters.matchingName.value
            : '';

    return {
        matchingName,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeFilter: bindActionCreators(EntityFiltersActions, dispatch).changeFilter,
    };
};

const SearchRow = memo(
    ({
        className,
        changeFilter,
        entity,
        matchingName,
        onClick,
        title,
        avatar,
        subtitles,
        getMatchingCharacters,
    }) => {
        const theme = useTheme();

        let classes = ['fm-row-search-custom'];
        if (className) classes.push(className);

        const getHighlightedText = useCallback(
            (label) => {
                const matchingCharacters = getMatchingCharacters && getMatchingCharacters(label);
                if (!matchingCharacters) return label;
                const replaceable = `<br/>${matchingCharacters}<br/>`;
                const newLabel = label;
                const labelReplaced = newLabel.replace(matchingCharacters, replaceable);
                const arr = labelReplaced.split('<br/>').filter((current) => current !== '');

                return (
                    <div>
                        {arr.map((current) => {
                            if (current === matchingCharacters)
                                return (
                                    <span className="fm-row-search-custom__title__highlighted">
                                        {matchingCharacters}
                                    </span>
                                );
                            else return current;
                        })}
                    </div>
                );
            },
            [getMatchingCharacters],
        );

        let finalSubtitles = useMemo(() => {
            if (!subtitles || subtitles.length === 0) return null;
            return subtitles.map((current, index) => (
                <Text
                    key={index}
                    className="fm-row-search-custom__subtitle"
                    type="caption"
                    isTruncated
                    color={theme.colors.grey[700]}
                >
                    {current}
                </Text>
            ));
        }, [subtitles, theme.colors.grey]);

        const handleClick = useCallback(() => {
            matchingName &&
                changeFilter({
                    entity,
                    filter: {
                        id: 'matchingName',
                        dataType: 'search',
                        hideForCount: true,
                    },
                    value: null,
                });
            onClick && onClick();
        }, [changeFilter, entity, matchingName, onClick]);

        return (
            <div className={classes.join(' ')} onClick={handleClick}>
                {avatar && <div className="fm-row-search-custom__avatar">{avatar}</div>}
                <div className="fm-row-search-custom__main-info">
                    <Text
                        className="fm-row-search-custom__title"
                        color={theme.colors.grey[700]}
                        isTruncated
                    >
                        {getHighlightedText(title)}
                    </Text>
                    {finalSubtitles}
                </div>
            </div>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(SearchRow);
