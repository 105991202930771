import { memo, useCallback, useMemo, useState } from 'react';
import { useTheme, CheckboxGroup, Icon, Markdown, Modal, Text } from 'hoi-poi-ui';
import { useSelector } from 'react-redux';
import {
    getLiteral,
    getLiteralWithParameters,
    getLiteralWithParametersHtml,
} from 'utils/getLiteral';
import DeletedAccountPic from './DeletedAccountPic';

import './styles.scss';

const TERMS_AND_POLICY = 'TERMS_AND_POLICY';
// const CONTACT_REQUEST = 'CONTACT_REQUEST';

const initialState = {
    checkboxes: {
        [TERMS_AND_POLICY]: false,
        // [CONTACT_REQUEST]: true,
    },
};

const DeletedAccountModal = ({ isOpen, periodEnds, remainingDays, onConfirm, onDownload }) => {
    const { licenseEditionCode } = useSelector((state) => ({
        licenseEditionCode: state.config?.licenseEditionCode,
    }));
    const [state, setState] = useState(initialState);
    const theme = useTheme();

    const onChangeCheckboxes = useCallback((checkboxes) => {
        setState({ checkboxes });
    }, []);

    const modalProps = useMemo(() => {
        return {
            isOpen,
            useCornerClose: false,
            title: getLiteral('title_churn_thank_you'),
            confirmText: getLiteral('action_done'),
            onConfirm,
            isConfirmDisabled:
                !licenseEditionCode?.includes('sage50fr') && !state.checkboxes[TERMS_AND_POLICY],
        };
    }, [isOpen, onConfirm, state.checkboxes, licenseEditionCode]);

    const modalContent = useMemo(() => {
        const checkboxGroupOptions = {
            options: [
                {
                    label: (
                        <Markdown
                            content={getLiteral('label_churn_terms_and_policy')}
                            overrides={{
                                a: {
                                    type: 'caption',
                                    onClick: (e) => e.stopPropagation(),
                                },
                                p: { color: theme.colors.utility.textSecondary, type: 'caption' },
                            }}
                        />
                    ),
                    value: TERMS_AND_POLICY,
                },
                // {
                //     label: (
                //         <Text type="caption" color="{theme.colors.utility.textSecondary}">
                //             {getLiteral('label_churn_any_questions')}
                //         </Text>
                //     ),
                //     value: CONTACT_REQUEST,
                // },
            ],
            isFullWidth: true,
            value: state.checkboxes,
            onChange: onChangeCheckboxes,
            color: 'actionMajor',
            overrides: {
                root: {
                    style: {
                        padding: 0,
                    },
                },
            },
        };

        const withSimpleFlow = licenseEditionCode?.includes('sage50fr');

        return (
            <div className="fm-dam">
                <DeletedAccountPic className="fm-dam__pic" />
                {withSimpleFlow && (
                    <Text>{getLiteral('label_contact_administrator_to_proceed_churn')}</Text>
                )}
                {!withSimpleFlow && (
                    <>
                        <Text>{getLiteral('label_churn_ok_cancelled_desc')}</Text>
                        <ul className="fm-dam__list">
                            <li className="fm-dam__listItem">
                                <Icon
                                    name="check"
                                    color={theme.colors.primary.white}
                                    className="fm-dam__bullet fm-dam__bullet--checked"
                                />
                                <Text className="fm-dam__listItem__text">
                                    {remainingDays
                                        ? getLiteralWithParametersHtml(
                                              'label_churn_step_1',
                                              [remainingDays],
                                              (text) => <strong>{text}</strong>,
                                          )
                                        : getLiteral('label_churn_step_1_no_date')}
                                </Text>
                            </li>
                            <li className="fm-dam__listItem">
                                <Text
                                    color={theme.colors.utility.textSecondary}
                                    className="fm-dam__bullet"
                                >
                                    2
                                </Text>
                                <Text
                                    color={theme.colors.utility.textSecondary}
                                    className="fm-dam__listItem__text"
                                >
                                    {/* {getLiteralWithParametersHtml(
                                'label_churn_step_2',
                                [periodEnds, getLiteral('action_download')],
                                (text, index) =>
                                    index === 0 ? (
                                        text
                                    ) : (
                                        <Link variation="primary" onClick={onDownload}>
                                            {text}
                                        </Link>
                                    ),
                            )} */}
                                    {periodEnds
                                        ? getLiteralWithParameters('label_churn_step_2', [
                                              periodEnds,
                                              getLiteral('action_download'),
                                          ])
                                        : getLiteral('label_churn_step_2_no_date')}
                                </Text>
                            </li>
                            <li className="fm-dam__listItem">
                                <Text
                                    color={theme.colors.utility.textSecondary}
                                    className="fm-dam__bullet"
                                >
                                    3
                                </Text>
                                <Text color={theme.colors.utility.textSecondary}>
                                    {getLiteral('label_churn_step_3')}
                                </Text>
                            </li>
                        </ul>
                        <hr className="fm-dam__hr" />
                        <CheckboxGroup {...checkboxGroupOptions} />
                    </>
                )}
            </div>
        );
    }, [
        theme.colors.utility.textSecondary,
        theme.colors.primary.white,
        state.checkboxes,
        onChangeCheckboxes,
        licenseEditionCode,
        remainingDays,
        periodEnds,
    ]);

    return <Modal {...modalProps}>{modalContent}</Modal>;
};

export default memo(DeletedAccountModal);
