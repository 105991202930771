import { memo, useMemo } from 'react';
import { Text, useTheme } from 'hoi-poi-ui';
import useLoginLiterals from 'utils/literals/useLoginLiterals';

import './styles.scss';

const ForceBIMobile = memo(() => {
    const { loading, getLiteral } = useLoginLiterals();
    const theme = useTheme();

    const illustrationForceBI = useMemo(
        () => (
            <svg viewBox="0 0 168 144" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask
                    id="mask0_2421_18208"
                    style={{ maskType: 'luminance' }}
                    maskUnits="userSpaceOnUse"
                    x="20"
                    y="25"
                    width="128"
                    height="94"
                >
                    <path d="M147.53 25.4118H20.4707V118.871H147.53V25.4118Z" fill="white"></path>
                </mask>
                <g mask="url(#mask0_2421_18208)">
                    <path
                        d="M60.3785 100.498H49.2656V118.871H60.3785V100.498Z"
                        fill="#00D639"
                    ></path>
                    <path
                        d="M78.8352 90.3389H67.7227V118.87H78.8352V90.3389Z"
                        fill="#00D639"
                    ></path>
                    <path
                        d="M97.2942 84.4391H86.1816V118.871H97.2942V84.4391Z"
                        fill="#00D639"
                    ></path>
                    <path
                        d="M116.455 78.4035H105.342V118.869H116.455V78.4035Z"
                        fill="#00D639"
                    ></path>
                    <path
                        d="M136.429 67.6414H125.316V118.871H136.429V67.6414Z"
                        fill="#00D639"
                    ></path>
                    <path
                        d="M72.504 90.3275C72.2897 90.3275 72.0722 90.3018 71.8551 90.2479C70.4258 89.8922 69.557 88.4499 69.9142 87.0271C71.3014 81.5001 72.0008 79.0552 72.6773 76.691C73.0241 75.4797 73.351 74.3345 73.7672 72.794C74.5781 69.7912 75.0991 66.9083 75.5585 64.3649C76.0111 61.8641 76.4007 59.7038 76.9598 58.0721C77.3361 56.9746 77.7659 56.1662 78.3117 55.5278C79.1559 54.5421 80.2955 53.9995 81.5226 53.9995H81.5407C83.5138 54.0079 85.4707 55.5222 87.358 58.5002C89.0044 61.0975 90.4658 64.5953 91.5845 68.6169C93.107 74.0897 93.0564 81.4289 93.0536 81.7389C93.0403 83.1973 91.8485 84.3707 90.3868 84.3707C90.3783 84.3707 90.3698 84.3707 90.3611 84.3707C88.8883 84.3575 87.705 83.1563 87.7183 81.6898C87.7183 81.5777 87.7584 74.7654 86.4426 70.036C85.3507 66.1104 84.1265 63.5413 83.2918 62.0784C82.7649 61.1554 82.2971 60.5009 81.9247 60.052C81.8569 60.2702 81.7826 60.5311 81.7044 60.8403C81.4015 62.0366 81.1273 63.5529 80.8099 65.3088C80.3316 67.9564 79.7887 70.9562 78.9196 74.1761C78.4929 75.7556 78.1451 76.972 77.8088 78.1491C77.1439 80.4726 76.4569 82.8757 75.0906 88.318C74.7876 89.5248 73.6997 90.3312 72.5048 90.3312L72.504 90.3275Z"
                        fill="#4E4E4E"
                    ></path>
                    <path
                        d="M79.6771 47.9023C78.9226 48.4772 78.4805 49.3689 78.4805 50.3139V52.2833C78.4805 54.0043 79.8459 55.3996 81.5302 55.3996C83.2147 55.3996 84.5799 54.0043 84.5799 52.2833V50.3139C84.5799 49.368 84.138 48.4763 83.3832 47.9023C82.2897 47.0694 80.7718 47.0694 79.6782 47.9023H79.6771Z"
                        fill="#C6C6C6"
                    ></path>
                    <path
                        d="M94.5215 84.3781H90.1684C89.8341 84.3781 89.5625 84.1079 89.5625 83.7747C89.5625 83.4418 89.8341 83.1713 90.1684 83.1713H94.5215C94.8561 83.1713 95.1274 83.4418 95.1274 83.7747C95.1274 84.1079 94.8561 84.3781 94.5215 84.3781Z"
                        fill="#4E4E4E"
                    ></path>
                    <path
                        d="M76.6545 90.2925H71.9375C71.5716 90.2925 71.2754 89.9975 71.2754 89.633C71.2754 89.2687 71.5716 88.9737 71.9375 88.9737H76.6545C77.0204 88.9737 77.3166 89.2687 77.3166 89.633C77.3166 89.9975 77.0204 90.2925 76.6545 90.2925Z"
                        fill="#4E4E4E"
                    ></path>
                    <path
                        d="M82.5654 45.1909C82.5654 44.6225 82.1024 44.1614 81.5317 44.1614C80.9611 44.1614 80.498 44.6225 80.498 45.1909C80.498 45.759 80.9611 46.2201 81.5317 46.2201C82.1024 46.2201 82.5654 45.759 82.5654 45.1909Z"
                        fill="#E84D4F"
                    ></path>
                    <path
                        d="M95.1733 58.1179C95.0703 58.1179 94.9664 58.1074 94.8628 58.0865C94.7236 58.058 91.4183 57.3693 87.6447 55.0724C84.5055 53.1617 81.5041 50.3879 81.3784 50.2701C80.7536 49.6905 80.72 48.7164 81.3022 48.094C81.8844 47.4717 82.8627 47.4384 83.4879 48.0181C83.5164 48.0446 86.3907 50.6999 89.2578 52.4445C92.5427 54.4436 95.4551 55.0639 95.4848 55.0696C96.3194 55.2432 96.8576 56.0564 96.6853 56.8882C96.5346 57.6158 95.8916 58.1179 95.1742 58.1179H95.1733Z"
                        fill="#C6C6C6"
                    ></path>
                    <path
                        d="M97.5271 56.7832L97.5274 56.7823C97.6008 56.2323 97.2128 55.7272 96.6606 55.6538L95.615 55.5151C95.0627 55.4417 94.5553 55.8283 94.4816 56.3783V56.3791C94.4079 56.9292 94.7959 57.4346 95.3485 57.5077L96.3937 57.6463C96.9463 57.7198 97.4537 57.3332 97.5271 56.7832Z"
                        fill="#E84D4F"
                    ></path>
                    <path
                        d="M74.2803 65.2111C73.4849 65.2111 72.8092 64.6049 72.7418 63.8022C72.7254 63.6124 72.3662 59.1004 74.0651 54.7697C76.0936 49.5967 79.8387 47.7411 79.9977 47.6643L81.347 50.4353L81.3575 50.4297C81.242 50.4876 78.5116 51.8962 76.9463 55.8892C75.5162 59.5361 75.8211 63.5054 75.8239 63.5452C75.8934 64.3915 75.2606 65.1352 74.411 65.2055C74.367 65.2092 74.3243 65.2111 74.2814 65.2111H74.2803Z"
                        fill="#C6C6C6"
                    ></path>
                    <path
                        d="M74.224 63.0568L74.2228 63.0571C73.6895 63.2177 73.3879 63.7785 73.5494 64.3096L73.8546 65.3148C74.0161 65.8459 74.5792 66.1463 75.1125 65.9856L75.1134 65.9853C75.6467 65.8247 75.9486 65.2639 75.7871 64.7328L75.4818 63.7276C75.3203 63.1965 74.7573 62.8961 74.224 63.0568Z"
                        fill="#E84D4F"
                    ></path>
                    <path
                        d="M20.508 85.0851H52.4534C52.4534 85.0851 53.4854 80.6197 49.8828 78.3581C46.7665 76.4019 44.5218 77.8459 44.5218 77.8459C44.5218 77.8459 41.3702 71.4799 34.1732 72.7229C26.9761 73.9658 26.515 81.4253 26.515 81.4253C26.515 81.4253 24.9182 79.8977 22.7107 80.9896C20.003 82.3302 20.508 85.0851 20.508 85.0851Z"
                        fill="#ABABAB"
                    ></path>
                    <path
                        d="M35.9067 47.3899H62.5424C62.5424 47.3899 62.9845 44.0166 60.7121 42.3818C58.4401 40.7472 56.7355 41.9416 56.7355 41.9416C56.7355 41.9416 55.0312 38.422 50.8018 38.6733C46.5725 38.9249 46.0038 42.0051 46.0038 42.0051C46.0038 42.0051 43.3486 39.9123 40.0063 40.6857C36.4716 41.5025 34.7748 44.2269 35.9067 47.391V47.3899Z"
                        fill="#ABABAB"
                    ></path>
                    <path
                        d="M94.0829 34.2952H120.932C120.932 34.2952 121.937 32.688 120.114 31.6388C119.016 31.0069 117.821 31.9624 117.821 31.9624C117.821 31.9624 116.477 25.7304 110.372 25.424C104.267 25.1176 102.462 30.2036 102.462 30.2036C102.462 30.2036 99.6591 27.7179 97.0869 28.9418C94.5143 30.1655 93.3815 33.1861 94.0829 34.2943V34.2952Z"
                        fill="#ABABAB"
                    ></path>
                    <path
                        d="M119.365 60.0141H147.529C147.529 60.0141 147.529 50.147 138.454 50.147C131.205 50.147 129.432 55.5998 129.432 55.5998C129.432 55.5998 127.503 54.2985 125.364 55.0792C123.226 55.8599 122.651 57.8332 122.651 57.8332C122.651 57.8332 121.713 57.2098 120.513 57.6254C119.314 58.0408 119.366 60.0141 119.366 60.0141H119.365Z"
                        fill="#ABABAB"
                    ></path>
                </g>
            </svg>
        ),
        [],
    );

    if (loading) return null;

    return (
        <div className="forcebi-mobile">
            <div className="forcebi-mobile__container">
                <div className="forcebi-mobile__image">{illustrationForceBI}</div>
                <Text type="subtitle1">{getLiteral('label_forcebi_only_visible_web_title')}</Text>
                <Text type="caption" color={theme.colors.textLight.secondary}>
                    {getLiteral('label_forcebi_only_visible_web_description')}
                </Text>
            </div>
        </div>
    );
});

export default ForceBIMobile;
