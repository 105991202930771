import React, { memo } from 'react';
import { useTheme } from 'hoi-poi-ui';
import ContentLoader from 'react-content-loader';

import './styles.scss';

const ProductDetailLoader = memo(({ width, height, noAnimation }) => {
    const theme = useTheme();

    return (
        <div className="fm-products-detail__loader">
            <ContentLoader
                height={height}
                width={width}
                speed={noAnimation ? 0 : 2}
                backgroundColor={theme.colors.grey[50]}
                foregroundColor={theme.colors.grey[100]}
            >
                <rect x={0} y={0} rx="6" ry="6" width={width / 3} height={275} />;
                <rect
                    x={width / 3 + 16}
                    y={0}
                    rx="6"
                    ry="6"
                    width={(width / 3) * 2 - 16}
                    height={275}
                />
                <rect x={0} y={275 + 32} rx="6" ry="6" width={width / 2 - 16} height={48} />
                <rect x={width / 2} y={275 + 32} rx="6" ry="6" width={width / 2} height={48} />
                <rect
                    x={0}
                    y={275 + 32 + 48 + 16}
                    rx="6"
                    ry="6"
                    width={width / 2 - 16}
                    height={48}
                />
                <rect
                    x={width / 2}
                    y={275 + 32 + 48 + 16}
                    rx="6"
                    ry="6"
                    width={width / 2}
                    height={48}
                />
                <rect
                    x={0}
                    y={275 + 32 + (48 + 16) * 2}
                    rx="6"
                    ry="6"
                    width={width / 2 - 16}
                    height={48}
                />
                <rect
                    x={width / 2}
                    y={275 + 32 + (48 + 16) * 2}
                    rx="6"
                    ry="6"
                    width={width / 2}
                    height={48}
                />
            </ContentLoader>
        </div>
    );
});

export default ProductDetailLoader;
