import React from 'react';
import { getLiteral } from 'utils/getLiteral';
import { Icon, Tooltip, useTheme } from 'hoi-poi-ui';
import './styles.scss';

const ButtonWidgetViewDetails = (props) => {
    const theme = useTheme();
    return (
        <Tooltip placement="top" content={getLiteral('action_showmore_widgets')}>
            <div className="fm-button__view-details-widget" onClick={props.onClick}>
                <div className="fm-button__view-details-widget__icon">
                    <Icon name="launch" color={theme.colors.actionMinor[500]} />
                </div>
            </div>
        </Tooltip>
    );
};

export default ButtonWidgetViewDetails;
