import React, { memo, useMemo } from 'react';
import { Text, useTheme } from 'hoi-poi-ui';
import { getMomentFromDateBackend, getActivityDateFormat } from 'utils/dates';
import TimezonePopover from '../../components/TimezonePopover';

import './styles.scss';

const TitleRow = memo(({ icon, date, content, follow, timezoneInfo }) => {
    const theme = useTheme();

    const dateText = useMemo(() => {
        if (!date) return '';
        const dateMoment = getMomentFromDateBackend(date, null, true);
        return getActivityDateFormat(dateMoment, true);
    }, [date]);

    if (!content) return null;

    return (
        <div className="title-row__container">
            {icon && <div className="title-row__icon">{icon}</div>}
            <div className="title-row__info">
                <div className="title-row--left">{content}</div>
                <div className="title-row--right">
                    {date && (
                        <Text
                            className="title-row__date"
                            type="caption"
                            color={theme.colors.utility.textSecondary}
                            isTruncated
                        >
                            {dateText}
                        </Text>
                    )}
                    {timezoneInfo && <TimezonePopover timezoneInfo={timezoneInfo} />}
                    {follow}
                </div>
            </div>
        </div>
    );
});

export default TitleRow;
