export default ({ text }) => ({
    title: {
        ...text.text_md,
        marginRight: 16,
        width: '150px',
        display: 'inline-block',
        marginTop: '16px',
        boxSizing: 'border-box',
        color: 'var(--ssm-textPrimary)',
        fontSize: 14,
    },
    inputStyle: {
        ...text.text_md,
        width: 'auto',
        color: 'var(--ssm-textSecondary)',
        fontSize: 14,
    },
    inputStyleBig: {
        ...text.text_lg,
        fontWeight: 500,
        width: 'auto',
        color: 'rgba(0,0,0,1)',
    },
    hintStyle: {
        ...text.text_md_hint,
    },
    valueListTextMenu: {
        ...text.text_md,
    },

    fieldContainer: {
        minHeight: '44px',
        margin: '0 20px',
    },
    chip: {
        ...text.text_md,
        display: 'inline-block',
        padding: '0px 5px 5px 0px',
        borderRadius: 16,
        color: 'white',
        backgroundColor: '#97a9b9',
        whiteSpace: 'nowrap',
        marginRight: 5,
        marginBottom: 5,
        // width:262,
        marginTop: 12,
        verticalAlign: 'top',
        height: 20,
    },

    clearStyle: {
        margin: '1px 3px 0px 10px',
    },
    titleStyle: {
        maxWidth: 200,
        display: 'inline-block',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        marginTop: -5,
        marginLeft: 0,
    },
    text: {
        ...text.text_md,
        color: 'rgb(143, 143, 143)',
        marginLeft: '-10px',
    },
});
