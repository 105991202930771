import React, { memo, useRef, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Text, useTheme } from 'hoi-poi-ui';

import PaymentSection from './PaymentSection';
import BillingHistory from './BillingHistory';
import { logEvent } from 'utils/tracking';
import {
    getLiteral,
    getLiteralWithParametersHtml,
    getLiteralWithParameters,
} from 'utils/getLiteral';
import useSettings from '../hooks/useSettings';

import './styles.scss';

const mapStateToProps = (state) => {
    const canConfigImplementation = state?.config?.permission.canConfigImplementation || false;
    const productModeCode = state?.config?.productModeCode || '';
    return {
        shouldRender:
            canConfigImplementation &&
            productModeCode &&
            productModeCode.toLowerCase() !== 'business',
    };
};

const Billing = memo(({ shouldRender }) => {
    const theme = useTheme();
    const isFirstRender = useRef(true);

    const { renderSupportChat, planName } = useSettings({
        submodule: 'addons',
    });

    const getHeader = useMemo(
        () => (
            <div className="billing-container__header">
                <div className="billing-container__header__content">
                    <Text type="h5">{getLiteral('title_billing_management')}</Text>
                    <Text type="body" color={theme.colors.utility.textSecondary}>
                        {getLiteralWithParametersHtml(
                            'label_manage_your_billing_info_description',
                            [planName],
                            (text) => (
                                <strong>{text}</strong>
                            ),
                        )}
                    </Text>

                    {renderSupportChat}
                </div>
            </div>
        ),
        [planName, renderSupportChat, theme],
    );

    useEffect(() => {
        if (shouldRender && isFirstRender?.current) {
            isFirstRender.current = false;
            logEvent({
                event: 'settings',
                submodule: 'billingManagement',
                functionality: 'listview',
            });
        }
    }, [shouldRender]);

    if (!shouldRender) return null;

    return (
        <div className="billing-container">
            {getHeader}
            <div className="billing-central-container">
                <PaymentSection />
                <BillingHistory />
            </div>
        </div>
    );
});

export default connect(mapStateToProps)(Billing);
