import { memo, useCallback, useState } from 'react';
import { Text, Switch, Badge } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import { errorToast } from 'utils/toast';
import { isDateWithinLastWeek } from 'utils/dates';
import { capitalize } from 'utils/strings';
import { enableAutomation } from 'services/AutomationsService';
import { BaseReactCell } from '@web/web5';

const EnabledCell = memo(({ data, value, reload, open, ...props }) => {
    const [enabled, setEnabled] = useState(data.enabled);

    const onChange = useCallback(() => {
        setEnabled(!enabled);
        enableAutomation(data.id, !enabled)
            .then(() => {
                reload();
            })
            .catch((e) => {
                console.error(e);
                errorToast({ text: getLiteral('error_generalerror') });
                setEnabled(enabled);
            });
    }, [data?.id, enabled, reload]);

    const onClick = useCallback(() => open(data.id), [data.id, open]);

    const showBadge = isDateWithinLastWeek(data.updateTime);

    return (
        <BaseReactCell data={data} {...props}>
            <div className="fm-automation-settings__enabled-cell">
                <div className="fm-automation-settings__enabled-cell__name">
                    <Switch size="small" checked={enabled} onChange={onChange} />
                    <Text
                        className="fm-automation-settings__enabled-cell__text"
                        type="subtitle"
                        bold
                        isTruncated
                        useTooltip
                        onClick={onClick}
                    >
                        {value}
                    </Text>
                </div>
                {showBadge && (
                    <Badge type="success">{capitalize(getLiteral('label_updated'))}</Badge>
                )}
            </div>
        </BaseReactCell>
    );
});

export default EnabledCell;
