import React, { memo, useCallback, useEffect, useMemo, useState, Fragment } from 'react';
import classNames from 'classnames';
import { useTheme, Icon, Link, Text } from 'hoi-poi-ui';
import { SettingsService } from 'services';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { logEvent } from 'utils/tracking';
import useSettings from 'containers/settings/hooks/useSettings';
import {
    USAGE_LIMITS,
    SHOW_WARNING_USAGE_LIMIT_THRESHOLD,
} from 'containers/settings/LimitsManagement/constants';

import './styles.scss';

const { getLimits } = SettingsService;

const UsageLimitWarning = ({ entity, value }) => {
    const [visible, setVisible] = useState(false);
    const [usage, setUsage] = useState(0);
    const theme = useTheme();

    const { handleUpgradePlan, hasLimits, isAdmin, renderUpgradeModal, showComparePlans } =
        useSettings({
            submodule: 'limits',
        });

    const limitKey = useMemo(() => USAGE_LIMITS[entity?.entity], [entity]);

    const handleUpdate = useCallback((value) => {
        setUsage(value);
        setVisible(value >= SHOW_WARNING_USAGE_LIMIT_THRESHOLD);
    }, []);

    useEffect(() => {
        if (value || !limitKey || !hasLimits) {
            if (value) handleUpdate(value);
            return;
        }
        getLimits(limitKey)
            .then((data) => {
                const { limitUsed, value } = data;
                const percentage = Math.round((limitUsed / value) * 100);
                handleUpdate(percentage);
            })
            .catch((error) => console.log(error));
    }, [value, hasLimits, limitKey, handleUpdate]);

    const handleDismiss = useCallback(() => {
        setVisible(false);
        logEvent({
            event: entity.trueName,
            submodule: 'limits',
            functionality: 'Dismiss',
        });
    }, [entity]);

    const showWarning = useMemo(
        () =>
            limitKey &&
            hasLimits &&
            Object.keys(USAGE_LIMITS).includes(entity?.entity) &&
            usage >= SHOW_WARNING_USAGE_LIMIT_THRESHOLD &&
            visible,
        [entity?.entity, hasLimits, limitKey, usage, visible],
    );

    const getWarningText = useMemo(() => {
        return getLiteralWithParameters('label_limit_percentage_achieve_table', [
            `${usage}%`,
            getLiteral(`label_${entity?.trueName}`),
        ]);
    }, [entity, usage]);

    return showWarning ? (
        <Fragment>
            <div className={classNames('fm-ulw', { 'fm-ulw--full': usage >= 100 })}>
                <div className="fm-ulw__content">
                    <Icon name="warningOutline" color={theme.colors.primary.white} />
                    <Text color={theme.colors.primary.white} type="caption">
                        {getWarningText}
                    </Text>
                    {isAdmin && showComparePlans && (
                        <Link type="caption" className="fm-ulw__link" onClick={handleUpgradePlan}>
                            {getLiteral('title_upgrade_now')}
                        </Link>
                    )}
                </div>
                <Icon
                    color={theme.colors.primary.white}
                    className="fm-ulw__close"
                    name="close"
                    onClick={handleDismiss}
                />
            </div>
            {renderUpgradeModal}
        </Fragment>
    ) : null;
};

export default memo(UsageLimitWarning);
