import React, { memo, useMemo, useCallback, useRef } from 'react';
import { Text, Badge, Icon, Menus, useTheme } from 'hoi-poi-ui';
import { useSelector, useDispatch } from 'react-redux';

import classnames from 'classnames';
import { EntityCrudActions } from 'actions';
import { PRODUCTS } from 'constants/Entities';
import { deleteProduct } from 'services/ProductsService';
import { publish } from 'lib/EventBuser';
import { REFRESH_TABLE } from 'lib/events';

import { getNumberAsLocaleNumber } from 'utils/numbers';
import { getNumberAsCurrency } from 'utils/currency';
import { getSrcProductCircleAvatar } from 'utils/getSrcAvatar';
import { getLiteral } from 'utils/getLiteral';
import { getBackendBoolean } from 'utils/fm';

import TruncatedTextPopover from 'components/TruncatedTextPopover';
import DeleteModal from 'components/DeleteModal';
import PlaceholderIcon from '../PlaceholderIcon';

import './styles.scss';

function ProductRow({ data, onChangeFamily }) {
    const dispatch = useDispatch();
    const deleteRef = useRef();
    const product = data;
    const productAvatar = getSrcProductCircleAvatar(product?.IdThumbnail, null, 600, 600);
    const permissions = useSelector(
        (state) => state.config.permission.crud_permission[PRODUCTS.permission],
    );
    const theme = useTheme();

    const onDeleteRef = useCallback((ref) => {
        deleteRef.current = ref;
    }, []);

    const onDelete = useCallback(
        (id) => deleteProduct(id).then(() => publish(`${REFRESH_TABLE}_${PRODUCTS.entity}`)),
        [],
    );

    const dotsMenu = useMemo(() => {
        const items = [];
        if (permissions?.update)
            items.push({
                title: <Text>{getLiteral('action_change_product_family')}</Text>,
                icon: <Icon name="folder" color={theme.colors.actionMinor[500]} />,
                onClick: () => onChangeFamily(product),
            });

        if (permissions?.delete)
            items.push({
                title: <Text>{getLiteral('action_delete')}</Text>,
                icon: <Icon name="delete" />,
                onClick: () =>
                    deleteRef.current.open({
                        id: product?.Id,
                    }),
            });

        return (
            <Menus
                icon={<Icon name="threeDots" color={theme.colors.actionMinor[500]} />}
                items={items}
            />
        );
    }, [onChangeFamily, permissions?.delete, permissions?.update, product, theme]);

    if (!product) return null;

    const isAvailable = getBackendBoolean(product?.Available);
    const hasAllPermissions = permissions?.update || permissions?.delete || permissions?.create;
    let productName = product?.ProductModel;
    const textClassName = classnames('fm-product-grid__title', {
        'fm-entity-placeholder': !productName,
    });
    if (!productName) productName = getLiteral('label_placeholder_unamed_record');

    return (
        <div className="fm-product-grid__card-container">
            <div className="fm-product-grid__card">
                <div className="fm-product-grid__info">
                    <a href={`#/products/grid/${product?.Id}`}>
                        <div className="fm-product-grid__images">
                            {productAvatar.src && <img src={productAvatar.src} />}
                            {!productAvatar.src && <PlaceholderIcon />}

                            <div className="fm-product-grid__images-header">
                                {hasAllPermissions && (
                                    <div className="fm-product-grid__actions">
                                        {permissions?.update && (
                                            <Icon
                                                name="edit"
                                                color={theme.colors.actionMinor[500]}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    dispatch(
                                                        EntityCrudActions.init({
                                                            entity: PRODUCTS,
                                                            id: product?.Id,
                                                            isFromTab: true,
                                                        }),
                                                    );
                                                }}
                                            />
                                        )}
                                        {dotsMenu}
                                    </div>
                                )}
                                {product?.ProductCategory && (
                                    <div className="fm-product-grid__category">
                                        <Badge type="ongoing" isTruncated useTooltip>
                                            {product?.ProductCategory}
                                        </Badge>
                                    </div>
                                )}
                            </div>
                        </div>
                    </a>
                    <div className="fm-product-grid__basic-info">
                        <a href={`#/products/grid/${product?.Id}`}>
                            <Text className={textClassName} type="button" isTruncated useTooltip>
                                {productName}
                            </Text>
                        </a>

                        <div className="fm-product-grid__description">
                            <TruncatedTextPopover
                                color={theme.colors.utility.textSecondary}
                                modalTitle={product?.ProductModel}
                            >
                                {product?.ProductDescription || '  '}
                            </TruncatedTextPopover>
                        </div>
                    </div>
                </div>
                <div className="fm-product-grid__quantities">
                    {isAvailable && product?.Stock !== '-1' && (
                        <div className="fm-product-grid__stock">
                            <Text
                                className="fm-product-grid__stock-text"
                                color={theme.colors.utility.textSecondary}
                                isTruncated
                                useTooltip
                            >
                                {getNumberAsLocaleNumber(product?.Stock)}
                            </Text>
                            <Text
                                className="fm-product-grid__units"
                                color={theme.colors.utility.textSecondary}
                                type="badges"
                            >
                                {getLiteral('label_products_stock_units')}
                            </Text>
                        </div>
                    )}
                    {isAvailable && product?.Stock === '-1' && (
                        <div className="fm-product-grid__empty-stock"></div>
                    )}
                    {!isAvailable && (
                        <Text
                            className="fm-product-grid__available"
                            color={theme.colors.utility.textSecondary}
                            type="badges"
                            isTruncated
                            useTooltip
                        >
                            {getLiteral('label_product_status_inactive')}
                        </Text>
                    )}
                    <Text
                        className="fm-product-grid__price"
                        type="body"
                        color={theme.colors.utility.textPrimary}
                        isTruncated
                        useTooltip
                    >
                        {getNumberAsCurrency(product?.ProductPrice, product?.SymbolCurrency, true)}
                    </Text>
                </div>
                <DeleteModal
                    title={getLiteral('action_delete_product')}
                    onRef={onDeleteRef}
                    onDelete={onDelete}
                />
            </div>
        </div>
    );
}

export default memo(ProductRow);
