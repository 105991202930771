import React from 'react';
import colors from 'constants/colors';

const ChevronDown = ({ color = colors.actionMinor[500] }) => (
    <svg
        viewBox={`0 0 18 18`}
        xmlns="http://www.w3.org/2000/svg"
        transform="rotate(90) translate(3 0)"
    >
        <path
            d="M8.999 8c.014.275-.084.555-.295.765l-6.977 6.94a1.016 1.016 0 0 1-1.43 0 1.003 1.003 0 0 1 0-1.423L6.61 8 .296 1.718a1.003 1.003 0 0 1 0-1.423 1.016 1.016 0 0 1 1.431 0l6.977 6.94c.21.21.31.49.295.765z"
            fill={color || '#335B70'}
            fillRule="nonzero"
        />
    </svg>
);

export default ChevronDown;
