import { memo, useCallback, useMemo, useRef } from 'react';
import { useTheme } from 'hoi-poi-ui';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { IMAGES_FILES_VALID_FORMATS } from 'constants/Constants';
import { errorToast } from 'utils/toast';
import { logEvent } from 'utils/tracking';

import EditorToolbarItem from './EditorToolbarItem';

const IMAGE_LIMIT = 5;
const MAX_FILE_SIZE_MB = 50; // 50MB in bytes

const ImageSelector = ({ editor }) => {
    const theme = useTheme();
    const imageUploader = useRef(null);
    const handleImageUpload = useCallback(
        (e) => {
            const files = e.target.files;
            if (!files?.length) {
                e.target.value = '';
                return;
            }
            if (files?.length > 5) {
                errorToast({
                    text: getLiteralWithParameters('label_fragmented_upload_images', [IMAGE_LIMIT]),
                });
                e.target.value = '';
                return;
            }

            Array.from(files).forEach((file) => {
                // Checking format
                if (!IMAGES_FILES_VALID_FORMATS.includes(file.type.toLowerCase())) {
                    errorToast({
                        title: file.name,
                        text: getLiteral('label_error_format_not_allowed_desc'),
                    });
                    logEvent({
                        event: 'emails',
                        submodule: 'sendEmail',
                        functionality: 'errorAttachingImages',
                    });
                    return;
                }

                // Checking size
                if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
                    errorToast({
                        title: file.name,
                        text: getLiteralWithParameters('label_error_file_too_big_desc', [
                            MAX_FILE_SIZE_MB,
                        ]),
                    });
                    logEvent({
                        event: 'emails',
                        submodule: 'sendEmail',
                        functionality: 'errorAttachingImages',
                    });
                    return;
                }

                editor.chain().focus().setImage({ file }).run();
                logEvent({
                    event: 'emails',
                    submodule: 'sendEmail',
                    functionality: 'embedImage',
                });
            });

            e.target.value = '';
        },
        [editor],
    );

    const editorToolbarItemProps = useMemo(() => {
        return {
            color: theme.colors.actionMinor[500],
            hint: getLiteral('action_upload_image'),
            custom: {
                name: 'image',
                onClick: () => imageUploader.current.click(),
            },
        };
    }, [theme]);

    return editor ? (
        <>
            <input
                className="fm-ee__imageSelector__input"
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                ref={imageUploader}
                multiple
            />
            <EditorToolbarItem {...editorToolbarItemProps} />
        </>
    ) : null;
};

export default memo(ImageSelector);
