import React, { memo, useMemo } from 'react';
import { Popover, Icon, Text, useTheme } from 'hoi-poi-ui';
import { ACTIVITIES } from 'constants/Entities';
import { getLiteral } from 'utils/getLiteral';
import { logEvent } from 'utils/tracking';

import './styles.scss';

const TimezonePopover = memo(({ timezoneInfo }) => {
    const theme = useTheme();

    const dateTimezone = useMemo(() => {
        if (!timezoneInfo) return null;

        return (
            <div className="timezone-container">
                <div className="timezone-container-top">
                    <Text
                        className="timezone-container__title"
                        type="subtitle"
                        color={theme.colors.utility.textPrimary}
                    >
                        {getLiteral('label_other_time_zone')}
                    </Text>
                </div>
                <div className="timezone-container-bottom">
                    <Text
                        color={theme.colors.utility.textSecondary}
                        className="timezone-container-label"
                    >
                        {getLiteral('label_activity_created')}:
                    </Text>
                    <Text className="timezone-container-date" isTruncated>
                        {timezoneInfo}
                    </Text>
                </div>
            </div>
        );
    }, [timezoneInfo, theme]);

    return (
        <Popover
            className="timezone-popover"
            placement="bottom"
            content={dateTimezone}
            trigger={['hover', 'click']}
            onVisibleChange={(isVisible) => {
                if (isVisible) {
                    logEvent({
                        event: ACTIVITIES.trueName,
                        functionality: 'seeTimeZone',
                    });
                }
            }}
            destroyTooltipOnHide={true}
        >
            <div className="popover-background">
                <Icon name="timeZone" color={theme.colors.grey[500]} />
            </div>
        </Popover>
    );
});

export default TimezonePopover;
