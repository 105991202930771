import React, { memo, useState, useMemo } from 'react';
import { Icon, useTheme, Tooltip } from 'hoi-poi-ui';
import classnames from 'classnames';
import { getLiteral } from 'utils/getLiteral';

import './styles.scss';

const ClearButton = memo(({ onClick }) => {
    const [isOver, setIsOver] = useState(false);
    const theme = useTheme();

    const displayClear = useMemo(() => {
        if (isOver && !!onClick) return true;
        return false;
    }, [isOver, onClick]);

    const displayClearOutline = useMemo(() => {
        if (!displayClear || !onClick) return true;
        return false;
    }, [displayClear, onClick]);

    return (
        <Tooltip placement="top" content={getLiteral('action_clear')}>
            <div
                className={classnames('dana-chat-clear', {
                    'dana-chat-clear--disabled': !onClick,
                })}
                onClick={onClick || null}
                onMouseOver={() => setIsOver(true)}
                onMouseOut={() => setIsOver(false)}
            >
                {displayClearOutline && (
                    <Icon
                        name="clearOutline"
                        size="large"
                        color={
                            onClick ? theme.colors.actionMajor[500] : theme.colors.actionMinor[500]
                        }
                    />
                )}
                {displayClear && (
                    <Icon
                        name="clear"
                        size="large"
                        color={
                            onClick ? theme.colors.actionMajor[500] : theme.colors.actionMinor[500]
                        }
                    />
                )}
            </div>
        </Tooltip>
    );
});

export default ClearButton;
