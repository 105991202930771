import React, { memo, useEffect, useMemo, useCallback, useState, useRef, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, useTheme } from 'hoi-poi-ui';

import { PATH_IMAGE } from 'constants/Environment';
import { OPPORTUNITIES, COMPANIES, USERS, CONTACTS } from 'constants/Entities';
import CONFIG from './EntityConfig';
import COMPANIES_CONFIG from 'containers/companies/EntityConfig';
import NewEntityList from 'containers/components/NewEntityList';
import UsageLimitWarning from 'containers/components/UsageLimitWarning';
import Opportunities from 'components/SvgIcons/emptyScreen/Opportunities';
import Pipeline from './components/pipeline';
import CustomEmptyView from 'containers/components/CustomEmptyView';

import {
    EntityDetailActions,
    EntityExtraFieldsActions,
    EntityListActions,
    EntityActions,
    EntityExportActions,
    EntityListSelectActions,
} from 'actions';
import LinkFromWeb3Decorator from 'decorators/LinkFromWeb3Decorator';
import EntityExport from 'containers/components/modals/EntityExport';
import { getPartner } from 'lib/partners';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';
import {
    groupFieldsForFieldSelector,
    processExtraFieldsForList,
    isBackendFalsy,
    canImport,
} from 'utils/fm';
import { logEvent } from 'utils/tracking';
import colors from 'constants/colors';
import {
    getOpportunityDetailUrl,
    getUserSfmUrl,
    getCompanyDetailUrl,
    getContactFormUrl,
} from 'utils/getUrl';
import { ensureRoute } from 'utils/routes';
import { getHasTabsEnabled } from 'containers/components/EntityDetail/hooks/useEntityDetail';
import { isEmptyObject } from 'utils/objects';

const hasSearchFilter = (filters) => {
    return filters && filters.matchingName && filters.matchingName.value;
};

const hasVisibleFilters = (filters) => {
    return (
        !isEmptyObject(filters) &&
        !Object.keys(filters).every((key) => filters[key].invisible || filters[key].isPreload)
    );
};

const mapStateToProps = (state) => {
    const entityFilters = state.entityFilters[OPPORTUNITIES.entity];
    const filters = entityFilters ? entityFilters.filters : null;
    const operators = entityFilters ? entityFilters.operators : null;

    const opportunitiesBulkActions = state.config.permission.opportunitiesBulkActions;
    const enablePipelineOpportunities = state?.config?.userData?.enablePipelineOpportunities;
    const permission = state.config.permission;
    const shouldClearWeb5Checkboxes =
        state?.entityListSelect?.[OPPORTUNITIES.entity]?.shouldClearWeb5Checkboxes;
    const hasDependency =
        state.config &&
        state.config.standardFieldsSchemaMap &&
        state.config.standardFieldsSchemaMap.EXPEDIENTE &&
        state.config.standardFieldsSchemaMap.EXPEDIENTE.idestadoobra &&
        state.config.standardFieldsSchemaMap.EXPEDIENTE.idestadoobra.strParentField === 'idtipo'
            ? true
            : false;

    let hasTypeFilter = filters && filters.type ? true : false;
    const principalFilter = state.config.userData.principalFilter
        ? state.config.userData.principalFilter[OPPORTUNITIES.entity]
        : null;

    const standardFieldsSchemaMap =
        state.config.standardFieldsSchemaMap[OPPORTUNITIES.extraFieldName] || null;

    const visibleFieldsOpportunities =
        state.config?.components?.opportunities_list?.visibles || null;
    const visibleFieldsCompanies = state.config?.components?.companies_list?.visibles || null;

    const total = state.entityList[OPPORTUNITIES.entity]?.total;
    const isAdmin = state.config?.permission?.canConfigImplementation;

    return {
        enableBulkOperations: opportunitiesBulkActions,
        exportExpedientes: state.config.permission.exportExpedientes,
        canCreate: permission?.crud_permission?.EXPEDIENTE?.create || false,
        canConfig: permission?.manageExtraFields && isAdmin,
        enablePipelineOpportunities,
        filters,
        locale: state?.config?.userData?.locale || '',
        shouldClearWeb5Checkboxes,
        visibleFields: {
            [OPPORTUNITIES.entity]:
                (visibleFieldsOpportunities &&
                    Object.keys(visibleFieldsOpportunities).reduce((arr, key) => {
                        if (visibleFieldsOpportunities[key]) arr.push(key);
                        return arr;
                    }, [])) ||
                [],
            [COMPANIES.entity]:
                (visibleFieldsCompanies &&
                    Object.keys(visibleFieldsCompanies).reduce((arr, key) => {
                        if (visibleFieldsCompanies[key]) arr.push(key);
                        return arr;
                    }, [])) ||
                [],
        },
        hasDependency,
        hasTypeFilter,
        principalFilter,
        standardFieldsSchemaMap,
        total,
        operators,
        enabledUserflow: state?.config?.userData?.userFlow,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getExtraFieldSchema: bindActionCreators(EntityExtraFieldsActions, dispatch)
            .getExtraFieldSchema,
        initExport: bindActionCreators(EntityExportActions, dispatch).initExport,
        setUseLazyLoad: bindActionCreators(EntityListActions, dispatch).setUseLazyLoad,
        standardFieldsConfiguration: bindActionCreators(EntityListActions, dispatch)
            .standardFieldsConfiguration,
        followEntity: bindActionCreators(EntityActions, dispatch).followEntity,
        onEntitySelect: bindActionCreators(EntityListSelectActions, dispatch).onEntitySelect,
        onEntitySelectAll: bindActionCreators(EntityListSelectActions, dispatch).onEntitySelectAll,
        setShouldClearWeb5Checkboxes: bindActionCreators(EntityListSelectActions, dispatch)
            .setShouldClearWeb5Checkboxes,
        open: bindActionCreators(EntityDetailActions, dispatch).open,
        getDataForChip: bindActionCreators(EntityActions, dispatch).getDataForChip,
    };
};

const Content = memo(
    ({
        getExtraFieldSchema,
        standardFieldsConfiguration,
        enableBulkOperations,
        enablePipelineOpportunities,
        exportExpedientes,
        filters,
        initExport,
        followEntity,
        locale,
        canCreate,
        canConfig,
        onEntitySelect,
        onEntitySelectAll,
        setUseLazyLoad,
        shouldClearWeb5Checkboxes,
        setShouldClearWeb5Checkboxes,
        visibleFields,
        hasDependency,
        hasTypeFilter,
        principalFilter,
        standardFieldsSchemaMap,
        total,
        operators,
        enabledUserflow,
        open,
        getDataForChip,
    }) => {
        const [config, setConfig] = useState(null);
        const [companiesConfig, setCompaniesConfig] = useState(null);
        const [entityExportAction, setEntityExportAction] = useState(null);
        const [showModalFields, setShowModalFields] = useState(false);
        const [showPipeline, setShowPipeline] = useState(false);
        const [isEmptyPipeline, setIsEmptyPipeline] = useState(false);
        const prevHasTypeFilterRef = useRef(false);
        const isFirstLoad = useRef(true);
        const tableRef = useRef(null);
        const theme = useTheme();

        const openTab = useCallback(
            (entity, id) => {
                if (getHasTabsEnabled()) {
                    getDataForChip(entity.redux, id).then((data) => {
                        !data?.deleted &&
                            open(
                                entity,
                                id,
                                true,
                                null,
                                false,
                                false,
                                null,
                                false, // toTab
                            );
                    });
                } else {
                    // We keep the standard link behaviour if nav tabs are not enabled
                    ensureRoute(`${entity.route}/${id}`);
                }
            },
            [getDataForChip, open],
        );

        const tableParams = useMemo(() => {
            let newTableParams = {
                getUrl: ({ idKey, entity }) => {
                    return (data) => {
                        if (!idKey || !entity) return;
                        const id = data[idKey];
                        if (!id) return;
                        let url = '';

                        switch (entity) {
                            case OPPORTUNITIES:
                                url = getOpportunityDetailUrl(id) || '';
                                return url;
                            case COMPANIES:
                                url = getCompanyDetailUrl(id) || '';
                                return url;
                            case CONTACTS:
                                url = getContactFormUrl(id) || '';
                                return url;
                            case USERS:
                                url = getUserSfmUrl(id) || '';
                                return url;
                        }
                    };
                },
                reference: {
                    getAvatarSvg: (data) => {
                        const svgDefault = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M23 18h2.5a.5.5 0 01.5.5v8a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-8a.5.5 0 01.5-.5H9v1.58c0 .232.224.42.5.42s.5-.188.5-.42V18h12v1.58c0 .232.224.42.5.42s.5-.188.5-.42V18zm-1-1H10v-1.58c0-.232-.224-.42-.5-.42s-.5.188-.5.42V17H5.5a.5.5 0 01-.5-.5v-7a.5.5 0 01.5-.5h21a.5.5 0 01.5.5v7a.5.5 0 01-.5.5H23v-1.58c0-.232-.224-.42-.5-.42s-.5.188-.5.42V17zm-9-8.987a.5.5 0 11-1 0C12 6.901 12.901 6 14.013 6h3.891c1.112 0 2.014.901 2.014 2.013a.5.5 0 11-1 0c0-.56-.454-1.013-1.014-1.013h-3.89C13.453 7 13 7.454 13 8.013z" fill="var(--grey-500)" fill-rule="evenodd"/></svg>`;
                        const svgExpired = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g fill="none" fill-rule="evenodd"><path d="M23.722 18c-.11.115-.207.25-.285.403L23 19.26V18h.722zm-4.672 9H6.5a.5.5 0 01-.5-.5v-8a.5.5 0 01.5-.5H9v1.58c0 .232.224.42.5.42s.5-.188.5-.42V18h12v1.58c0 .232.224.42.5.42a.59.59 0 00.13-.014L19.05 27zM22 17H10v-1.58c0-.232-.224-.42-.5-.42s-.5.188-.5.42V17H5.5a.5.5 0 01-.5-.5v-7a.5.5 0 01.5-.5h21a.5.5 0 01.5.5v7a.5.5 0 01-.5.5H23v-1.58c0-.232-.224-.42-.5-.42s-.5.188-.5.42V17zm-9-8.987a.5.5 0 11-1 0C12 6.901 12.901 6 14.013 6h3.891c1.112 0 2.014.901 2.014 2.013a.5.5 0 11-1 0c0-.56-.454-1.013-1.014-1.013h-3.89C13.453 7 13 7.454 13 8.013z" fill="var(--grey-500)"/><path d="M18.41 30.453l5.917-11.595a.66.66 0 011.176 0l5.917 11.595a.66.66 0 01-.588.96H18.998a.66.66 0 01-.588-.96zm6.468-1.662a.532.532 0 00-.538.527c0 .291.24.527.538.527a.532.532 0 00.538-.527.532.532 0 00-.538-.527zm.495-5.114c0-.66-.99-.66-.99 0v3.583c0 .66.99.66.99 0v-3.583z" fill="var(--semantic-negative500)"/></g></svg>`;
                        const svgExpiring = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g fill="none" fill-rule="evenodd"><path d="M23.722 18c-.11.115-.207.25-.285.403L23 19.26V18h.722zm-4.672 9H6.5a.5.5 0 01-.5-.5v-8a.5.5 0 01.5-.5H9v1.58c0 .232.224.42.5.42s.5-.188.5-.42V18h12v1.58c0 .232.224.42.5.42a.59.59 0 00.13-.014L19.05 27zM22 17H10v-1.58c0-.232-.224-.42-.5-.42s-.5.188-.5.42V17H5.5a.5.5 0 01-.5-.5v-7a.5.5 0 01.5-.5h21a.5.5 0 01.5.5v7a.5.5 0 01-.5.5H23v-1.58c0-.232-.224-.42-.5-.42s-.5.188-.5.42V17zm-9-8.987a.5.5 0 11-1 0C12 6.901 12.901 6 14.013 6h3.891c1.112 0 2.014.901 2.014 2.013a.5.5 0 11-1 0c0-.56-.454-1.013-1.014-1.013h-3.89C13.453 7 13 7.454 13 8.013z" fill="var(--grey-500)"/><path d="M18.41 30.453l5.917-11.595a.66.66 0 011.176 0l5.917 11.595a.66.66 0 01-.588.96H18.998a.66.66 0 01-.588-.96zm6.468-1.662a.532.532 0 00-.538.527c0 .291.24.527.538.527a.532.532 0 00.538-.527.532.532 0 00-.538-.527zm.495-5.114c0-.66-.99-.66-.99 0v3.583c0 .66.99.66.99 0v-3.583z" fill="var(--semantic-caution500)" fill-rule="nonzero"/></g></svg>`;
                        const svgWin = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g fill="none" fill-rule="evenodd"><path d="M23.442 18a2.132 2.132 0 00-.442.685V18h.442zm-7.566 9H6.5a.5.5 0 01-.5-.5v-8a.5.5 0 01.5-.5H9v1.58c0 .232.224.42.5.42s.5-.188.5-.42V18h12v1.58c0 .232.224.42.5.42a.557.557 0 00.323-.1c-.194 1.892-1.825 3.37-3.456 3.37h-3.49V27zM22 17H10v-1.58c0-.232-.224-.42-.5-.42s-.5.188-.5.42V17H5.5a.5.5 0 01-.5-.5v-7a.5.5 0 01.5-.5h21a.5.5 0 01.5.5v7a.5.5 0 01-.5.5H23v-1.58c0-.232-.224-.42-.5-.42s-.5.188-.5.42V17zm-9-8.987a.5.5 0 11-1 0C12 6.901 12.901 6 14.013 6h3.891c1.112 0 2.014.901 2.014 2.013a.5.5 0 11-1 0c0-.56-.454-1.013-1.014-1.013h-3.89C13.453 7 13 7.454 13 8.013z" fill="var(--grey-500)"/><path d="M29.65 28.048c.55 0 .996.44.996.983a.989.989 0 01-.995.983h-.664c.55 0 .995.44.995.984a.989.989 0 01-.995.983h-4.312c-2.655 0-1.991-1.311-7.299-1.311v-5.9h1.99c2.323 0 4.977-2.113 4.977-5.246 0-1.037 1.99-1.168 1.99.799 0 1.311-.663 3.79-.663 3.79h5.308c.549 0 .995.441.995.984a.99.99 0 01-.995.984h-.664c.55 0 .995.44.995.983a.99.99 0 01-.995.984h-.663" fill="var(--semantic-positive500)"/></g></svg>`;
                        const svgLost = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g fill="none" fill-rule="evenodd"><path d="M19.544 27H6.5a.5.5 0 01-.5-.5v-8a.5.5 0 01.5-.5H9v1.58c0 .232.224.42.5.42s.5-.188.5-.42V18h12v.365l-.347.144c-.996.393-2.13.585-4.277.585h-1v7.901h2.99c.06 0 .119.002.178.005zM22 17H10v-1.58c0-.232-.224-.42-.5-.42s-.5.188-.5.42V17H5.5a.5.5 0 01-.5-.5v-7a.5.5 0 01.5-.5h21a.5.5 0 01.5.5v7a.5.5 0 01-.5.5H23v-1.58c0-.232-.224-.42-.5-.42s-.5.188-.5.42V17zm-9-8.987a.5.5 0 11-1 0C12 6.901 12.901 6 14.013 6h3.891c1.112 0 2.014.901 2.014 2.013a.5.5 0 11-1 0c0-.56-.454-1.013-1.014-1.013h-3.89C13.453 7 13 7.454 13 8.013z" fill="var(--grey-500)"/><path d="M30.646 21.734a.989.989 0 00-.995-.984h-.664a.99.99 0 00.995-.984.989.989 0 00-.995-.983h-4.312c-2.655 0-1.991 1.312-7.299 1.312v5.9h1.99c2.323 0 4.977 2.112 4.977 5.245 0 1.037 1.99 1.168 1.99-.798 0-1.312-.663-3.791-.663-3.791h5.308a.99.99 0 00.995-.983.99.99 0 00-.995-.984h-.664a.99.99 0 00.995-.983.99.99 0 00-.995-.984h-.663c.55 0 .995-.44.995-.983z" fill="var(--semantic-negative500)"/></g></svg>`;

                        const expirationDays = data.expirationDays;
                        if (data.isWin) return svgWin;
                        else if (data.isLost) return svgLost;
                        else {
                            if (expirationDays && expirationDays <= 7) {
                                if (expirationDays <= -100 || expirationDays <= -1) {
                                    return svgExpired;
                                } else if (expirationDays >= 0) {
                                    return svgExpiring;
                                }
                            }

                            return svgDefault;
                        }
                    },
                    getInfoForTag: (data) => {
                        const expirationDays = data.expirationDays;
                        let type = '';
                        let label = '';

                        if (
                            !isNaN(expirationDays) &&
                            expirationDays !== null &&
                            expirationDays <= 7
                        ) {
                            if (expirationDays <= -100) {
                                type = 'error';
                                label = getLiteral('placeholder_expired_female');
                            } else if (expirationDays < -1) {
                                type = 'error';
                                label = getLiteralWithParameters(
                                    'label_expired_opportunity_plural',
                                    [-expirationDays],
                                );
                            } else if (expirationDays === -1) {
                                type = 'error';
                                label = getLiteral('label_expired_opportunity_yesterday');
                            } else if (expirationDays === 0) {
                                type = 'warning';
                                label = getLiteral('label_opportunity_expires_today');
                            } else if (expirationDays === 1) {
                                type = 'warning';
                                label = getLiteral('label_opportunity_expires_tomorrow');
                            } else {
                                type = 'warning';
                                label = getLiteralWithParameters(
                                    'label_opportunity_expires_in_plural',
                                    [expirationDays],
                                );
                            }
                        }

                        if (!data.isWin && !data.isLost && label) {
                            return {
                                value: label,
                                type,
                            };
                        }
                    },
                },
                follow: {
                    onClick: (value, data, event) => {
                        followEntity({
                            entityType: OPPORTUNITIES,
                            entityId: data?.Id,
                            follow: value,
                        })
                            .then((bool) => {
                                successToast({
                                    text: bool
                                        ? getLiteral('succes_following')
                                        : getLiteral('succes_unfollowing'),
                                });
                            })
                            .catch((err) => {
                                console.error(err);
                                errorToast();
                            });
                    },
                    getSvg: (value) => {
                        const activeColor = colors.actionMajor[500];
                        const inactiveColor = colors.utility.textSecondary;
                        const svgActive = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" >
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.4 6 14 4H5v17h2v-7h5.6l.4 2h7V6h-5.6z" fill="${activeColor}"/>
                            </svg>`;
                        const svgInactive = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" >
                            <path fill-rule="evenodd" clip-rule="evenodd" d="m12.36 6 .4 2H18v6h-3.36l-.4-2H7V6h5.36M14 4H5v17h2v-7h5.6l.4 2h7V6h-5.6" fill="${inactiveColor}"/>
                            </svg>`;

                        if (value) return svgActive;
                        else return svgInactive;
                    },
                    getHeaderSvg: () => {
                        const color = colors.utility.textSecondary;
                        const geolocated = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none" >
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.4 6 14 4H5v17h2v-7h5.6l.4 2h7V6h-5.6z" fill="${color}"/>
                        </svg>`;
                        return geolocated;
                    },
                    getTooltip: (value) => {
                        if (value) return getLiteral('label_following');
                        else return getLiteral('action_follow');
                    },
                },
                locale: {
                    getLocale: () => locale,
                },
                win: {
                    getInfoForTag: (data) => {
                        if (data?.isWin)
                            return {
                                value: getLiteral('label_main_status_won').toUpperCase(),
                                styles: {
                                    container: {
                                        backgroundColor: colors.semantic.positiveCustom100,
                                        width: '56px',
                                    },
                                    content: {
                                        color: colors.semantic.positive400,
                                    },
                                },
                            };
                        else return null;
                    },
                },
                lost: {
                    getInfoForTag: (data) => {
                        if (data?.isLost)
                            return {
                                value: getLiteral('label_main_status_lost').toUpperCase(),
                                styles: {
                                    container: {
                                        backgroundColor: colors.semantic.negativeCustom100,
                                        width: '56px',
                                    },
                                    content: {
                                        color: colors.semantic.negative500,
                                    },
                                },
                            };
                        else return null;
                    },
                },
                geolocation: {
                    getSvg: (value) => {
                        const geolocatedColor = colors.actionMajor[500];
                        const notGeolocatedColor = colors.grey[100];
                        const geolocated = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"><path d="M9.429 0C5.874 0 3 2.817 3 6.3 3 11.025 9.429 18 9.429 18s6.428-6.975 6.428-11.7c0-3.483-2.874-6.3-6.428-6.3zm-.215 8.143a1.93 1.93 0 11.002-3.859 1.93 1.93 0 01-.002 3.859z" fill="${geolocatedColor}" fill-rule="evenodd"/></svg>`;
                        const notGeolocated = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"><path d="M9.429 0C5.874 0 3 2.817 3 6.3 3 11.025 9.429 18 9.429 18s6.428-6.975 6.428-11.7c0-3.483-2.874-6.3-6.428-6.3zm-.215 8.143a1.93 1.93 0 11.002-3.859 1.93 1.93 0 01-.002 3.859z" fill="${notGeolocatedColor}" fill-rule="evenodd"/></svg>`;
                        if (value) return geolocated;
                        else return notGeolocated;
                    },
                    getHeaderSvg: () => {
                        const geolocatedColor = colors.utility.textPrimary;
                        const geolocated = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"><path d="M9.429 0C5.874 0 3 2.817 3 6.3 3 11.025 9.429 18 9.429 18s6.428-6.975 6.428-11.7c0-3.483-2.874-6.3-6.428-6.3zm-.215 8.143a1.93 1.93 0 11.002-3.859 1.93 1.93 0 01-.002 3.859z" fill="${geolocatedColor}" fill-rule="evenodd"/></svg>`;
                        return geolocated;
                    },
                },
                onCompanyClick: ({ data }) => {
                    !isBackendFalsy(data?.IdCompany) && openTab(COMPANIES, data.IdCompany);
                },
                onContactClick: ({ data }, numContact) => {
                    const idContact = data?.[`contactId${numContact}`] || '';
                    idContact && !isBackendFalsy(data?.IdCompany) && openTab(CONTACTS, idContact);
                },
            };

            if (enableBulkOperations) {
                newTableParams.reference.onClickCheckbox = (result) => {
                    const { id, isColumn, value, checked, data, rowIndex, total } = result;
                    if (isColumn) {
                        onEntitySelectAll(OPPORTUNITIES, value, checked, total);
                    } else {
                        onEntitySelect(OPPORTUNITIES, id, value, data, rowIndex, checked);
                    }
                };
            }

            return newTableParams;
        }, [
            enableBulkOperations,
            followEntity,
            locale,
            onEntitySelectAll,
            onEntitySelect,
            openTab,
        ]);

        const getShowPipeline = useCallback(() => {
            if (standardFieldsSchemaMap) {
                if (!principalFilter) {
                    setShowPipeline(true);
                } else if (principalFilter === 'idestadoobra') {
                    setShowPipeline(false);
                } else if (
                    standardFieldsSchemaMap['idestadoobra'] &&
                    !standardFieldsSchemaMap['idestadoobra'].isHidden &&
                    principalFilter === 'idView'
                ) {
                    setShowPipeline(true);
                } else if (
                    standardFieldsSchemaMap['idestadoobra'] &&
                    !standardFieldsSchemaMap['idestadoobra'].isHidden &&
                    principalFilter === 'idtipo'
                ) {
                    if (!hasDependency) {
                        setShowPipeline(true);
                    } else if (hasTypeFilter && hasDependency) {
                        setShowPipeline(true);
                    }
                }
            }
        }, [
            standardFieldsSchemaMap,
            hasTypeFilter,
            hasDependency,
            principalFilter,
            setShowPipeline,
        ]);

        const getEntitySchema = useCallback(
            (configProp, callback) => {
                getExtraFieldSchema(configProp.entity, (extraFieldTabs) => {
                    let extraFields = [];
                    const groupedExtraFields = extraFieldTabs.map((tab) => {
                        let group = {
                            label:
                                typeof tab.descripcion !== 'string'
                                    ? 'label_customized_fields'
                                    : tab.descripcion,
                            fields: [],
                        };

                        group.fields = processExtraFieldsForList(tab.tabFields);
                        extraFields = [...extraFields, ...group.fields];

                        return group;
                    });

                    standardFieldsConfiguration(OPPORTUNITIES, configProp.columnDefs)
                        .then((columnDefs) => {
                            const newColumnDefs = [...columnDefs, ...extraFields];
                            const groupedStandardFields = [
                                ...groupFieldsForFieldSelector(configProp, columnDefs, true),
                            ];
                            const groupedFields = [...groupedStandardFields, ...groupedExtraFields];

                            configProp = {
                                ...configProp,
                                columnDefs: newColumnDefs,
                                groupedFields,
                                groupedStandardFields,
                            };
                            callback && callback(configProp);
                        })
                        .catch(() => {
                            console.error('List configuration error');
                        });
                });
            },
            [getExtraFieldSchema, standardFieldsConfiguration],
        );

        useEffect(() => {
            if (isFirstLoad.current) {
                prevHasTypeFilterRef.current = hasTypeFilter;
                return;
            }

            if (
                prevHasTypeFilterRef.current !== hasTypeFilter &&
                principalFilter === 'idtipo' &&
                standardFieldsSchemaMap &&
                standardFieldsSchemaMap['idestadoobra'] &&
                !standardFieldsSchemaMap['idestadoobra'].isHidden
            ) {
                if (!hasDependency) {
                    setShowPipeline(true);
                } else if (hasTypeFilter && hasDependency) {
                    setShowPipeline(hasTypeFilter);
                } else if (!hasTypeFilter) {
                    setShowPipeline(false);
                }
            }
            prevHasTypeFilterRef.current = hasTypeFilter;
        }, [standardFieldsSchemaMap, hasTypeFilter, hasDependency, principalFilter]);

        useEffect(() => {
            setUseLazyLoad(OPPORTUNITIES, true);

            const newTableParams = {
                ...tableParams,
                hasPipeline: enablePipelineOpportunities,
            };

            if (!isFirstLoad?.current) return;

            let newConfig = CONFIG(newTableParams);
            newConfig = {
                ...newConfig,
                withEntitySelection: false,
            };

            if (enableBulkOperations) {
                newConfig = {
                    ...newConfig,
                    withEntitySelection: true,
                    selectionFor: OPPORTUNITIES.entity,
                    isLocalSelection: true,
                };
            }

            getEntitySchema(newConfig, setConfig);

            const newCompaniesConfig = COMPANIES_CONFIG();
            getEntitySchema(newCompaniesConfig, setCompaniesConfig);

            getShowPipeline();

            isFirstLoad.current = false;
        }, [
            tableParams,
            enableBulkOperations,
            config,
            setConfig,
            setUseLazyLoad,
            enablePipelineOpportunities,
            getShowPipeline,
            getEntitySchema,
        ]);

        useEffect(() => {
            if (shouldClearWeb5Checkboxes) {
                if (tableRef?.current?.clearCheckboxes) tableRef.current.clearCheckboxes();
                setShouldClearWeb5Checkboxes(OPPORTUNITIES, false);
            }
        }, [setShouldClearWeb5Checkboxes, shouldClearWeb5Checkboxes]);

        const handleOnRef = useCallback((ref) => {
            tableRef.current = ref;
        }, []);

        const onInitExport = useCallback(
            (action) => {
                initExport(OPPORTUNITIES, filters, operators);
                setEntityExportAction(action);
            },
            [initExport, filters, operators],
        );

        const actions = useMemo(() => {
            let newActions = [];

            newActions.push({
                type: 'title',
                label: getLiteral('label_customize'),
            });

            newActions.push({
                label: getLiteral('action_select_visible_columns'),
                icon: 'columns',
                id: 'columns',
                onClick: () => {
                    setShowModalFields(true);
                    logEvent({
                        event: OPPORTUNITIES.trueName,
                        functionality: 'visibleFields',
                        checkDuplicate: true,
                    });
                },
            });

            if (canConfig) {
                newActions.push({
                    label: getLiteral('action_add_custom_field'),
                    icon: 'plus',
                    id: 'add_custom_field',
                    color: theme.colors.actionMajor[500],
                    onClick: () => {
                        ensureRoute('/settings/fields', '?tab=Expedientes&showCrud=true');
                        logEvent({
                            event: OPPORTUNITIES.trueName,
                            submodule: 'threeDots',
                            functionality: 'createField',
                        });
                    },
                });
            }

            if (exportExpedientes) {
                newActions.push({
                    type: 'divider',
                });

                newActions.push({
                    type: 'title',
                    label: getLiteral('title_excel_export_email'),
                });

                newActions.push({
                    label: getLiteral('label_export_excel'),
                    icon: 'downloadAlt',
                    id: 'excel',
                    onClick: () => onInitExport('excel'),
                    disabled: !total || total === 0,
                });
            }

            return newActions;
        }, [onInitExport, canConfig, exportExpedientes, total, theme]);

        const getFieldSelectorProps = useMemo(() => {
            if (!config?.groupedFields) return null;
            return {
                actions,
                title: getLiteral('label_showHideColumns'),
                groupedColumns: config.groupedFields,
            };
        }, [actions, config]);

        const emptyIcon = useMemo(() => {
            if (locale.includes('es-'))
                return <img src={`${PATH_IMAGE}illustration_video_opportunities_ES.png`} />;
            return <img src={`${PATH_IMAGE}illustration_video_opportunities_EN.png`} />;
        }, [locale]);

        const onClickImport = useCallback(() => {
            logEvent({
                event: OPPORTUNITIES.trueName,
                functionality: 'import',
                submodule: 'emptyScreen',
            });
            ensureRoute('/settings/import', `?tab=${OPPORTUNITIES.importExport}`);
        }, []);

        const customImportButton = useMemo(() => {
            if (hasVisibleFilters(filters) || hasSearchFilter(filters) || !canImport()) return;
            return (
                <Button type="secondary" onClick={onClickImport}>
                    {getLiteral('action_import_opportunities')}
                </Button>
            );
        }, [filters, onClickImport]);

        const emptyViewProps = useMemo(() => {
            // Both title and subtitle are used in empty and no data screen.
            const title = getLiteral('label_empty_screen_opportunities');
            const subtitle = getLiteral('label_empty_screen_opportunities_desc');

            if (!getPartner()?.showEmptyScreenVideo || enabledUserflow === 'False')
                return {
                    canCreate,
                    iconFirstTime: <Opportunities />,
                    icon: <Opportunities />,
                    iconType: 'icon',
                    title,
                    titleFirstTime: title,
                    subtitle,
                    subtitleFirstTime: subtitle,
                    customButtonLiteral: getLiteral('action_add_opportunity'),
                    others: {
                        callsToAction: [customImportButton],
                    },
                };

            return {
                className: 'fm-empty-screen__container-opportunities',
                canCreate,
                iconFirstTime: emptyIcon,
                icon: <Opportunities />,
                iconType: 'video',
                onTracking: () => {
                    logEvent({
                        event: OPPORTUNITIES.trueName,
                        submodule: 'emptyScreen',
                        functionality: 'video',
                    });
                },
                title,
                titleFirstTime: title,
                subtitle,
                subtitleFirstTime: subtitle,
                customButtonLiteral: getLiteral('action_add_opportunity'),
                others: {
                    callsToAction: [customImportButton],
                },
            };
        }, [canCreate, customImportButton, emptyIcon, enabledUserflow]);

        const getRowNodeId = useCallback((data) => data.Id, []);

        const modalOptionsProps = useCallback(
            () => ({
                advice: getLiteral('label_visible_fields_explanation'),
                isOpen: showModalFields,
                confirmText: getLiteral('action_save'),
                cancelText: getLiteral('action_cancel'),
                placeholderSearch: getLiteral('label_search_field'),
                title: getLiteral('label_visible_fields'),
                onCancel: setShowModalFields,
            }),
            [showModalFields],
        );

        const getMultiSchemaData = useCallback(() => {
            let multiSchema = [];
            let multiSchemaSelected = {};
            if (!config?.groupedFields || !companiesConfig?.groupedFields)
                return { multiSchema, multiSchemaSelected };
            multiSchema = [
                {
                    label: getLiteral('label_opportunity_singular'),
                    id: config.entity.trueName,
                    idEntity: config.entity.entity,
                    schema: config.groupedFields.reduce((arr, item) => {
                        return [
                            ...arr,
                            {
                                label: item.label,
                                id: item.label,
                                options: item.fields.reduce(
                                    (
                                        optionsArr,
                                        {
                                            headerName,
                                            colId,
                                            suppressFromFieldSelector,
                                            showInExport,
                                        },
                                    ) => {
                                        return suppressFromFieldSelector && !showInExport
                                            ? optionsArr
                                            : [
                                                  ...optionsArr,
                                                  {
                                                      label: headerName,
                                                      id: colId,
                                                  },
                                              ];
                                    },
                                    [],
                                ),
                            },
                        ];
                    }, []),
                    forceExport: config.forceExport,
                },
                {
                    label: getLiteral('label_related_accounts'),
                    id: companiesConfig.entity.trueName,
                    idEntity: companiesConfig.entity.entity,
                    defaultTypeState: false,
                    schema: companiesConfig.groupedFields.reduce((arr, item) => {
                        return [
                            ...arr,
                            {
                                label: item.label,
                                id: item.label,
                                options: item.fields.reduce(
                                    (
                                        optionsArr,
                                        { headerName, colId, suppressFromFieldSelector },
                                    ) => {
                                        return suppressFromFieldSelector
                                            ? optionsArr
                                            : [
                                                  ...optionsArr,
                                                  {
                                                      label: headerName,
                                                      id: colId,
                                                  },
                                              ];
                                    },
                                    [],
                                ),
                            },
                        ];
                    }, []),
                },
            ];

            const groupedStandardFields = {
                [OPPORTUNITIES.trueName]: config.groupedStandardFields,
                [COMPANIES.trueName]: companiesConfig.groupedStandardFields,
            };

            multiSchemaSelected = multiSchema.reduce((obj, current) => {
                obj[current.id] = {
                    isExpanded: true,
                    isSelected: current.idEntity === OPPORTUNITIES.entity ? true : false,
                    options: groupedStandardFields[current.id].reduce((obj2, { label, fields }) => {
                        return {
                            ...obj2,
                            [label]: {
                                isSelected: false,
                                options: fields.reduce((arr, item) => {
                                    if (
                                        visibleFields[current.idEntity] &&
                                        visibleFields[current.idEntity].includes(item.colId)
                                    )
                                        arr.push(item.colId);
                                    return arr;
                                }, []),
                            },
                        };
                    }, {}),
                };

                return obj;
            }, {});

            return { multiSchema, multiSchemaSelected };
        }, [config, companiesConfig, visibleFields]);

        const entityExportProps = useMemo(() => {
            const { multiSchema, multiSchemaSelected } = getMultiSchemaData();
            return {
                action: entityExportAction,
                multiSchema,
                multiSchemaSelected,
                fields: {
                    download: true,
                    emails: true,
                    freeEmails: false,
                    freeSingleEmail: true,
                    users: false,
                },
                entity: OPPORTUNITIES,
                relatedGroups: [COMPANIES.trueName],
            };
        }, [entityExportAction, getMultiSchemaData]);

        const classes = useMemo(() => {
            const className = ['opportunities-table'];
            if (showPipeline && !isEmptyPipeline)
                className.push('opportunities-table__with-pipeline');
            return className;
        }, [showPipeline, isEmptyPipeline]);

        const getIsEmptyPipeline = useCallback((bool) => {
            setIsEmptyPipeline(bool);
        }, []);

        return (
            <Fragment>
                <UsageLimitWarning entity={OPPORTUNITIES} />
                <div className="opportunities-content">
                    {showPipeline && (
                        <Pipeline
                            hasDependency={hasDependency}
                            getIsEmptyPipeline={getIsEmptyPipeline}
                        />
                    )}
                    {config && (
                        <div className={classes.join(' ')}>
                            <NewEntityList
                                id="fm-grid-opportunities"
                                entity={OPPORTUNITIES}
                                config={config}
                                initOnReady={true}
                                useLazyLoad={true}
                                useCache={true}
                                useSort={true}
                                useDragColumns={true}
                                useFieldSelector={true}
                                fieldSelectorProps={getFieldSelectorProps}
                                getRowNodeId={getRowNodeId}
                                emptyViewProps={emptyViewProps}
                                customEmptyViewComponent={CustomEmptyView}
                                useSelectColumns={true}
                                handleOnRef={handleOnRef}
                                discardCount={true}
                                modalOptionsProps={modalOptionsProps()}
                            />
                        </div>
                    )}
                    <EntityExport {...entityExportProps} />
                </div>
            </Fragment>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(LinkFromWeb3Decorator(Content));
