import React from 'react';
import colors from 'constants/colors';

const Accounts = ({ color = colors.actionMinor[500] }) => (
    <svg viewBox={`0 0 18 18`} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1 .997C1 .447 1.445 0 2 0h14a1 1 0 0 1 1 .997v16.077c0 .55-.445.997-1 .997H2a1 1 0 0 1-1-.997V.997zM7.359 14H7V18.071h4V14H7.359zm-3.342-3h3.36c.299 0 .542-.224.542-.5s-.243-.5-.542-.5h-3.36c-.299 0-.541.224-.541.5s.242.5.541.5zm6.522 0h3.348c.3 0 .542-.224.542-.5s-.243-.5-.542-.5H10.54c-.3 0-.542.224-.542.5s.243.5.542.5zM4.017 8h3.36c.299 0 .542-.224.542-.5S7.676 7 7.377 7h-3.36c-.299 0-.541.224-.541.5s.242.5.541.5zm6.522 0h3.348c.3 0 .542-.224.542-.5s-.243-.5-.542-.5H10.54c-.3 0-.542.224-.542.5s.243.5.542.5zm3.348-3c.3 0 .542-.224.542-.5s-.243-.5-.542-.5H10.54c-.3 0-.542.224-.542.5s.243.5.542.5h3.348zm-9.87 0h3.36c.299 0 .542-.224.542-.5S7.676 4 7.377 4h-3.36c-.299 0-.541.224-.541.5s.242.5.541.5z"
            fill={color || '#335B70'}
            fillRule="evenodd"
        />
    </svg>
);

export default Accounts;
