import React, { useRef, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Text, Popover, useTheme } from 'hoi-poi-ui';
import classnames from 'classnames';

import './styles.scss';

function TruncatedTextPopover({ children, className, type, color, modalTitle }) {
    const theme = useTheme();
    const [isEllipsisActive, setEllipsisActive] = useState(false);
    let classes = classnames('fm-truncated-text', {
        [className]: className,
    });

    const textEl = useRef();

    useLayoutEffect(() => {
        const el = textEl.current;
        setTimeout(() => setEllipsisActive(el.offsetWidth < el.scrollWidth));
        const handleResize = () => setEllipsisActive(el.offsetWidth < el.scrollWidth);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [textEl, children, setEllipsisActive]);

    return (
        <>
            <Text type={type} color={color}>
                <span className={classes} ref={textEl}>
                    {children}
                </span>
                {isEllipsisActive && (
                    <Popover
                        placement="top"
                        content={
                            <div>
                                {modalTitle && (
                                    <Text
                                        className="fm-truncated-text__modal-title"
                                        type="subtitle"
                                        color={theme.colors.utility.textPrimary}
                                        medium
                                    >
                                        {modalTitle}
                                    </Text>
                                )}
                                <Text
                                    className="fm-truncated-text__content"
                                    type="body"
                                    color={theme.colors.utility.textSecondary}
                                >
                                    {children}
                                </Text>
                            </div>
                        }
                    >
                        <Icon
                            className="fm-truncated-text__icon"
                            name="visibilityOn"
                            color="currentColor"
                        />
                    </Popover>
                )}
            </Text>
        </>
    );
}

TruncatedTextPopover.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    type: PropTypes.string,
    color: PropTypes.string,
    modalTitle: PropTypes.string,
};

export default TruncatedTextPopover;
