import { memo } from 'react';

const EmptyImage = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 168 144" fill="none" {...props}>
        <g mask="url(#a)">
            <path
                fill="#B26E3D"
                d="M76.59 66.575a2.414 2.414 0 0 1-1.653-.65l-5.29-4.93a2.429 2.429 0 0 1-.122-3.43 2.423 2.423 0 0 1 3.427-.123l5.29 4.93a2.429 2.429 0 0 1-1.653 4.203ZM134.403 115.33a2.425 2.425 0 0 1-2.402-2.128l-.813-6.549a2.426 2.426 0 1 1 4.812-.598l.813 6.548a2.427 2.427 0 0 1-2.41 2.727Z"
            />
            <path
                fill="#474747"
                d="m115.616 111.911 1.078 10.826h11.921l-2.204-22.121-.019-.198-.033-.196a11.149 11.149 0 0 0-11.025-9.334c-5.932 0-10.835 4.645-11.161 10.574v.042l-.878 21.234h11.874l.447-10.826v-.001Z"
            />
            <path
                fill="#ABABAB"
                d="M133.592 110.806a4.45 4.45 0 0 1-4.448-4.436c-.027-7.085-.479-11.927-1.419-15.24-.813-2.862-1.954-4.594-3.683-7.217l-.359-.546c-1.163-1.768-2.267-2.934-3.196-3.37-.572-.269-1.143-.282-2.185-.278h-.208a4.45 4.45 0 0 1-4.448-4.453 4.45 4.45 0 0 1 4.448-4.452h.194c1.425-.003 3.581-.006 5.984 1.124 2.483 1.168 4.722 3.306 6.846 6.536l.352.536c4.277 6.487 6.51 10.648 6.575 27.326a4.45 4.45 0 0 1-4.432 4.47H133.592Z"
            />
            <path
                fill="#ABABAB"
                d="M116.413 96.07a5.333 5.333 0 0 0 5.331-5.336 5.334 5.334 0 0 0-5.331-5.336 5.334 5.334 0 0 0-5.331 5.336 5.333 5.333 0 0 0 5.331 5.335Z"
            />
            <path
                fill="#ABABAB"
                d="M109.995 78.07a7.1 7.1 0 0 0 6.72 7.46c3.913.204 7.25-2.808 7.454-6.725.203-3.918-3.048-3.216-6.961-3.418-3.914-.203-7.011-1.235-7.213 2.683Z"
            />
            <path
                fill="#ABABAB"
                d="m111.109 91.273 10.544.452 2.445-12.213-14.104-.74 1.115 12.501Z"
            />
            <path
                fill="#B26E3D"
                d="M120.253 67.866a2.315 2.315 0 0 1-3.334.336 2.335 2.335 0 0 1-.375-3.104 2.308 2.308 0 0 1 1.932-.968c.481.01.961.169 1.37.487a2.32 2.32 0 0 1 .827 2.38 2.298 2.298 0 0 1-.422.87h.002Z"
            />
            <path
                fill="#474747"
                d="M117.825 64.205c.142.345.406.974 1.11 1.964.341.478.9 1.239 1.382 1.614.172-.243.293-.506.36-.781a2.325 2.325 0 0 0-.829-2.386 2.293 2.293 0 0 0-1.372-.487c-.22-.005-.438.02-.649.078l-.002-.002Z"
            />
            <path
                fill="#474747"
                d="M124.95 66.165a2.565 2.565 0 0 1-3.706.362 2.583 2.583 0 0 1-.493-3.3 2.56 2.56 0 0 1 3.747-.663 2.575 2.575 0 0 1 .918 2.637c-.083.341-.237.67-.466.964Z"
            />
            <path
                fill="#ABABAB"
                d="M83.122 64.403c-.972-.74-1.563-1.238-1.601-1.268a4.448 4.448 0 0 0-6.27.526 4.455 4.455 0 0 0 .524 6.275c.138.117 1.668 1.403 3.982 3.043l1.886-.589 1.478-7.987h.001ZM117.326 70.814c-1.323 0-3.004.2-5.133.457-4.915.587-12.247 1.464-17.358-.144l-.15 9.14c2.454.5 5.021.684 7.539.684 4.036 0 7.941-.469 11.026-.838 1.689-.202 3.284-.393 4.076-.393a4.45 4.45 0 0 0 4.448-4.452 4.45 4.45 0 0 0-4.448-4.453Z"
            />
            <path
                fill="#00D639"
                d="M140.281 126.776H97.847a5.844 5.844 0 0 1-5.835-5.84V59.203c0-3.22 2.617-5.84 5.835-5.84h42.436c3.217 0 5.835 2.62 5.835 5.84v61.733c0 3.22-2.618 5.84-5.835 5.84h-.002ZM97.847 57.403c-.993 0-1.8.809-1.8 1.802v61.733c0 .993.807 1.802 1.8 1.802h42.436c.993 0 1.8-.809 1.8-1.802V59.203c0-.992-.807-1.801-1.8-1.801H97.847v.001Z"
            />
            <path
                fill="#303030"
                d="M135.5 114.501a.957.957 0 1 0-1.903.211l.89 8.026h1.93l-.913-8.236-.004-.001Z"
            />
            <path
                fill="#00D639"
                d="M77.296 75.013H30.55c-4.625 0-8.386-3.766-8.386-8.393V34.902c0-4.629 3.763-8.394 8.386-8.394h46.745c4.624 0 8.386 3.767 8.386 8.394V66.62c0 4.629-3.763 8.393-8.386 8.393ZM30.55 30.548a4.357 4.357 0 0 0-4.35 4.354V66.62c0 2.4 1.952 4.353 4.35 4.353h46.745a4.357 4.357 0 0 0 4.35-4.353V34.902c0-2.4-1.952-4.354-4.35-4.354H30.55Z"
            />
            <path
                fill="#474747"
                d="M49.313 59.832c-6.62-.742-11.404-6.738-10.663-13.364.741-6.627 6.732-11.415 13.353-10.673 6.62.741 11.404 6.738 10.663 13.364-.74 6.627-6.732 11.415-13.352 10.672v.001Zm2.494-22.286c-5.657-.634-10.775 3.456-11.408 9.118-.633 5.661 3.453 10.784 9.11 11.417 5.657.634 10.775-3.455 11.408-9.117.633-5.662-3.453-10.785-9.11-11.418Z"
            />
            <path
                fill="#474747"
                d="M62.105 52.184a1.438 1.438 0 0 0-1.551 2.42l11.715 7.523a1.438 1.438 0 0 0 1.551-2.42l-11.715-7.523Z"
            />
            <path
                fill="#00D639"
                d="M137.55 47.897h-36.248c-3.846 0-6.976-3.13-6.976-6.981v-16.71c0-3.85 3.129-6.982 6.976-6.982h36.248c3.845 0 6.975 3.13 6.975 6.981v16.71c0 3.85-3.128 6.982-6.975 6.982Zm-36.248-26.633a2.944 2.944 0 0 0-2.94 2.941v16.71a2.944 2.944 0 0 0 2.94 2.942h36.248c1.62 0 2.939-1.32 2.939-2.941v-16.71a2.944 2.944 0 0 0-2.939-2.942h-36.248Z"
            />
            <path fill="#919191" d="M119.414 36.858h8.59v-8.597h-8.59v8.597Z" />
            <path fill="#ABABAB" d="M110.824 36.858h8.59v-8.597h-8.59v8.597Z" />
            <path fill="#C6C6C6" d="M102.236 36.858h8.59v-8.597h-8.59v8.597Z" />
            <path fill="#777" d="M128.003 36.858h8.59v-8.597h-8.59v8.597Z" />
            <path
                fill="#00D639"
                d="M78.212 118.886H34.858a4.563 4.563 0 0 1-4.555-4.559V87.735a4.562 4.562 0 0 1 4.555-4.56h43.354a4.562 4.562 0 0 1 4.554 4.56v26.592a4.563 4.563 0 0 1-4.555 4.559Zm-43.354-31.67a.519.519 0 0 0-.519.519v26.592c0 .287.232.519.519.519h43.354a.518.518 0 0 0 .518-.519V87.735a.519.519 0 0 0-.519-.52H34.858Z"
            />
            <path
                fill="#5E5E5E"
                d="M49.16 96.03v9.762l7.71 4.724V91.463l-7.71 4.567ZM63.38 108.422a.866.866 0 0 1-.7-1.372c.028-.038 1.967-2.775 2.038-6.218.072-3.45-1.803-6.32-1.82-6.35a.863.863 0 0 1 .241-1.197.862.862 0 0 1 1.196.24c.089.133 2.195 3.336 2.112 7.344-.083 4.006-2.273 7.069-2.367 7.196a.862.862 0 0 1-.7.357ZM60.55 105.924a.863.863 0 0 1-.754-1.283c.011-.02.877-1.617.918-3.626.041-2.014-.784-3.688-.792-3.704a.867.867 0 0 1 .383-1.16.861.861 0 0 1 1.158.38c.041.083 1.028 2.061.977 4.519-.051 2.458-1.091 4.35-1.134 4.43a.862.862 0 0 1-.756.444Z"
            />
            <path
                fill="#5E5E5E"
                d="M66.304 111.319a.866.866 0 0 1-.676-1.404c.037-.046 3.08-3.94 3.18-8.846.103-4.91-2.843-8.992-2.871-9.033a.866.866 0 0 1 .184-1.208.862.862 0 0 1 1.205.182c.136.185 3.323 4.58 3.208 10.094-.114 5.512-3.417 9.712-3.557 9.888a.86.86 0 0 1-.676.325l.003.002ZM43.09 95.811h3.574v9.546H43.09a2.08 2.08 0 0 1-2.078-2.08v-5.385c0-1.15.932-2.08 2.078-2.08Z"
            />
            <path
                fill="#B26E3D"
                d="M72.331 62.324h-.05a.823.823 0 0 1-.77-.871c.035-.59.017-2.345-.48-3.375a.822.822 0 0 1 1.478-.716c.79 1.63.648 4.088.641 4.191a.822.822 0 0 1-.819.772v-.002Z"
            />
            <path
                fill="#B26E3D"
                d="M73.933 63.64h-.051a.823.823 0 0 1-.77-.871c.035-.59.018-2.345-.48-3.374a.823.823 0 0 1 1.479-.717c.789 1.63.647 4.088.64 4.192a.822.822 0 0 1-.818.772v-.002Z"
            />
            <path fill="#B26E3D" d="m75.017 61.392-2.465-.466-.24 2.004 1.346.282 1.359-1.82Z" />
        </g>
    </svg>
);

export default memo(EmptyImage);
