import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTheme, Chip, Icon, Popover, Text } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';

const ColorSelector = ({ editor }) => {
    const theme = useTheme();
    const [color, setColor] = useState(theme.colors.textLight.primary);
    const [visible, setVisible] = useState(false);
    const contentRef = useRef(null);

    const onClickOutside = useCallback((e) => {
        if (!contentRef.current?.contains(e.target)) {
            setVisible(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', onClickOutside);
        return () => {
            document.removeEventListener('mousedown', onClickOutside);
        };
    }, [onClickOutside]);

    const colors = useMemo(
        () => [
            {
                label: getLiteral('label_default'),
                value: 'default',
            },
            { value: '#000000' },
            { value: '#202020' },
            { value: '#404040' },
            { value: '#606060' },
            { value: '#808080' },
            { value: '#a0a0a0' },
            { value: '#c0c0c0' },
            { value: '#e0e0e0' },
            { value: '#bf2f2f' },
            { value: '#bf412f' },
            { value: '#bf532f' },
            { value: '#bf652f' },
            { value: '#bf772f' },
            { value: '#bf892f' },
            { value: '#bf9b2f' },
            { value: '#bfad2f' },
            { value: '#bfbf2f' },
            { value: '#adbf2f' },
            { value: '#9bbf2f' },
            { value: '#89bf2f' },
            { value: '#77bf2f' },
            { value: '#65bf2f' },
            { value: '#53bf2f' },
            { value: '#41bf2f' },
            { value: '#2fbf2f' },
            { value: '#2fbf41' },
            { value: '#2fbf53' },
            { value: '#2fbf65' },
            { value: '#2fbf77' },
            { value: '#2fbf89' },
            { value: '#2fbf9b' },
            { value: '#2fbfad' },
            { value: '#2fbfbf' },
            { value: '#2fadbf' },
            { value: '#2f9bbf' },
            { value: '#2f89bf' },
            { value: '#2f77bf' },
            { value: '#2f65bf' },
            { value: '#2f53bf' },
            { value: '#2f41bf' },
            { value: '#2f2fbf' },
            { value: '#412fbf' },
            { value: '#532fbf' },
            { value: '#652fbf' },
            { value: '#772fbf' },
            { value: '#892fbf' },
            { value: '#9b2fbf' },
            { value: '#ad2fbf' },
            { value: '#bf2fbf' },
            { value: '#bf2fad' },
            { value: '#bf2f9b' },
            { value: '#bf2f89' },
            { value: '#bf2f77' },
            { value: '#bf2f65' },
            { value: '#bf2f53' },
            { value: '#bf2f41' },
        ],
        [],
    );

    const chipProps = useMemo(
        () => ({
            isUnfolded: true,
            className: 'fm-ee__editorToolbarChip',
            onClick: () => setVisible(true),
        }),
        [],
    );

    const onChange = useCallback(
        (color) => {
            if (color !== 'default') {
                editor.chain().focus().setColor(color).run();
                setColor(color);
            } else {
                editor.chain().focus().unsetColor().run();
                setColor(theme.colors.textLight.secondary);
            }
            setVisible(false);
        },
        [editor, theme],
    );

    const popoverProps = useMemo(
        () => ({
            placement: 'bottomLeft',
            content: (
                <div className="fm-ee__colorPicker" ref={(ref) => (contentRef.current = ref)}>
                    {colors.reduce((arr, color) => {
                        const { label, value } = color;
                        const newColor =
                            color.value === 'default' ? (
                                <div
                                    className="fm-ee__colorPickerItem fm-ee__colorPickerItem--default"
                                    key={color.value}
                                    onClick={() => onChange(value)}
                                >
                                    <Text type="caption">{label}</Text>
                                </div>
                            ) : (
                                <div
                                    className="fm-ee__colorPickerItem"
                                    key={color.value}
                                    style={{ backgroundColor: value }}
                                    onClick={() => onChange(value)}
                                />
                            );

                        arr.push(newColor);

                        return arr;
                    }, [])}
                </div>
            ),
            className: 'fm-ee__colorPickerPopover',
            overrides: {
                root: {
                    visible,
                },
            },
        }),
        [colors, onChange, visible],
    );

    const iconProps = useMemo(
        () => ({
            name: 'fontColor',
            color: color,
        }),
        [color],
    );

    return (
        <Popover {...popoverProps}>
            <Chip {...chipProps}>
                <Icon {...iconProps} />
            </Chip>
        </Popover>
    );
};

export default memo(ColorSelector);
