import React from 'react';
import colors from 'constants/colors';

const UnorderedList = ({ color = colors.utility.textPrimary }) => (
    <svg className="fm-close-icon" viewBox={`0 0 18 18`} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6 4h12v1H6V4zm0 10h12v1H6v-1zm0-5h12v1H6V9zM1 3h3v3H1V3zm0 5h3v3H1V8zm0 5h3v3H1v-3z"
            fill={color || '#335B70'}
            fillRule="nonzero"
        />
    </svg>
);

export default UnorderedList;
