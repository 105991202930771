import React, { memo, useMemo, useCallback, useEffect, useState } from 'react';
import { Loader } from 'hoi-poi-ui';
import { SectionList } from '@web/web5';
import CONFIG from 'containers/campaigns/TableConfig';
import {
    WidgetLayout,
    WidgetHeaderLayout,
    WidgetContentLayout,
    WidgetEmpty,
} from 'containers/components/widgets/Layouts';
import { getLiteral } from 'utils/getLiteral';
import { ensureRoute } from 'utils/routes';
import { EntityListService } from 'services';
import { CAMPAIGNS } from 'constants/Entities';
import { Campaigns } from 'components/SvgIcons';
import Context from 'managers/Context';
import colors from 'constants/colors';

import './styles.scss';

const CampaignsWidget = memo(({ id, setSize, hardcodedHeight }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const tabs = useMemo(() => {
        return [
            {
                title: getLiteral('title_campaigns'),
                count: data?.length || '0',
            },
        ];
    }, [data]);

    const styleHeight = useMemo(() => {
        if (!hardcodedHeight) return null;
        const finalHeight = hardcodedHeight - 40;
        return { height: `${finalHeight}px` };
    }, [hardcodedHeight]);

    const columnsDef = useMemo(() => {
        // we want a specific order for columns
        const config = CONFIG({
            getInfoForTag: (data) => {
                let type = '';
                if (data.statusId === 1) type = 'pending';
                else if (data.statusId === 2) type = 'ongoing';
                else if (data.statusId === 4) {
                    if (data.percentageCompleted === 100) {
                        type = 'success';
                    } else {
                        type = 'error';
                    }
                } else if (data.statusId === 3) type = 'info';

                return {
                    value: data.statusDescription,
                    type,
                };
            },
        });

        const columns = config.columnDefs.reduce((arr, column) => {
            switch (column.colId) {
                case 'title':
                    delete column.pinned;
                    delete column?.headerComponentParams?.showTotal;
                    arr[0] = column;
                    break;
                case 'statusId':
                    arr[1] = column;
                    break;
                case 'campaignStartDate':
                    arr[2] = column;
                    break;
                case 'typeId':
                    arr[3] = column;
                    break;
                default:
                    break;
            }
            return arr;
        }, new Array(4));

        return columns;
    }, []);

    const onClickSeeAll = useCallback(() => {
        ensureRoute(`/campaigns`);
    }, []);

    const onClickAdd = useCallback(() => {
        ensureRoute(`/campaigns/new`);
    }, []);

    useEffect(() => {
        EntityListService.get(CAMPAIGNS, {
            accountId: parseInt(id, 10),
            statusId: [2, 4],
            admin: true,
        })
            .then((data) => {
                const model = Context.entityManager.getModel(CAMPAIGNS);
                if (data && model && model.toList) {
                    setData(model.toList({ data, ignoreSegments: true }));
                }
                setLoading(false);
            })
            .catch((err) => {
                console.error('error getting campaigns', err);
                setLoading(false);
            });
    }, [id]);

    useEffect(() => {
        if (setSize && !data?.length) {
            setSize({ height: 120 });
        }
    }, [setSize, data]);

    return (
        <WidgetLayout loading={loading} className="fm-widget-campaigns">
            <WidgetHeaderLayout
                className="fm-widget-campaigns__header"
                content={tabs}
                onClickSeeAll={onClickSeeAll}
                onClickAdd={onClickAdd}
            />
            <WidgetContentLayout>
                <div className="fm-widget-campaigns__content" style={styleHeight}>
                    {loading && <WidgetEmpty icon={<Loader />} />}
                    {!loading && data.length === 0 && (
                        <WidgetEmpty
                            icon={<Campaigns color={colors.grey[100]} />}
                            text={getLiteral('label_campaigns_account_not_associated_campaign')}
                        />
                    )}
                    {!loading && data.length > 0 && (
                        <SectionList
                            className="fm-widget-campaigns__content--table"
                            isLoading={loading}
                            columns={columnsDef}
                            rows={data}
                        />
                    )}
                </div>
            </WidgetContentLayout>
        </WidgetLayout>
    );
});

export default CampaignsWidget;
