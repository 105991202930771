const PermissionsPic = (props) => (
    <svg
        width={332}
        height={219}
        viewBox="0 0 332 219"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M1 24.6498H0.75V24.8998V205.936C0.75 212.701 6.23452 218.186 13 218.186H319C325.766 218.186 331.25 212.701 331.25 205.936V24.8998V24.6498H331H1Z"
            fill="#F1F1F1"
            stroke="#F2F5F6"
            stroke-width="0.5"
        ></path>
        <mask
            id="mask0_2515_37903"
            style="mask-type:luminance"
            maskUnits="userSpaceOnUse"
            x="100"
            y="59"
            width="132"
            height="102"
        >
            <path d="M231.506 59.1765H100.494V160.824H231.506V59.1765Z" fill="white"></path>
        </mask>
        <g mask="url(#mask0_2515_37903)">
            <path
                d="M141.371 129.796C140.283 129.796 139.203 129.338 138.442 128.453L127.759 116.031C126.371 114.417 126.557 111.987 128.173 110.601C129.789 109.216 132.223 109.401 133.611 111.015L141.879 120.63L185.393 86.2172C187.063 84.8967 189.488 85.1779 190.811 86.8449C192.134 88.5116 191.852 90.9331 190.182 92.2536L143.764 128.963C143.056 129.522 142.211 129.794 141.371 129.794V129.796Z"
                fill="#00D041"
            ></path>
            <path
                d="M207.67 90.7581C205.352 90.7581 203.444 88.9163 203.379 86.5891C203.313 84.2224 205.181 82.2505 207.553 82.1853L209.25 82.1389L211.179 73.4651C212.191 68.9152 216.157 65.7394 220.825 65.7394C223.197 65.7394 225.119 67.6585 225.119 70.0266C225.119 72.3949 223.197 74.3138 220.825 74.3138C220.215 74.3138 219.696 74.7289 219.564 75.3238L216.91 87.2631C216.484 89.182 214.804 90.5641 212.837 90.6191L207.792 90.7597C207.75 90.7597 207.711 90.7597 207.67 90.7597V90.7581Z"
                fill="#C6C6C6"
            ></path>
            <path
                d="M115.006 127.493H168.524C171.532 127.493 173.975 129.932 173.975 132.934V136.042H109.556V132.934C109.556 129.932 111.998 127.493 115.006 127.493Z"
                fill="#919191"
            ></path>
            <path
                d="M179.979 132.989H103.552C101.863 132.989 100.494 134.356 100.494 136.041C100.494 137.726 101.863 139.093 103.552 139.093H179.979C181.668 139.093 183.036 137.726 183.036 136.041C183.036 134.356 181.668 132.989 179.979 132.989Z"
                fill="#474747"
            ></path>
            <path
                d="M225.835 158.707C222.764 158.707 220.241 156.259 220.17 153.177L218.961 100.023L205.45 152.36C204.791 154.914 202.489 156.608 199.965 156.608C199.496 156.608 199.021 156.55 198.546 156.428C195.515 155.648 193.69 152.563 194.472 149.536L208.889 93.6888L208.908 93.6219C209.582 91.2518 211.062 89.1613 213.076 87.7363C215.089 86.3112 217.554 85.6082 220.017 85.759C222.74 85.9236 225.282 87.095 227.175 89.0534C229.068 91.0118 230.149 93.5909 230.217 96.3125L231.506 152.919C231.576 156.044 229.098 158.635 225.968 158.706C225.925 158.706 225.88 158.706 225.837 158.706L225.835 158.707Z"
                fill="#5E5E5E"
            ></path>
            <path
                d="M219.516 160.824C219.054 160.824 218.628 160.525 218.487 160.061C218.315 159.493 218.636 158.895 219.205 158.721L226.992 156.368C227.56 156.197 228.16 156.518 228.331 157.085C228.503 157.653 228.182 158.251 227.613 158.425L219.827 160.777C219.724 160.808 219.619 160.824 219.514 160.824H219.516Z"
                fill="#5E5E5E"
            ></path>
            <path
                d="M199.376 156.542H191.869C191.275 156.542 190.794 156.061 190.794 155.468C190.794 154.875 191.275 154.394 191.869 154.394H199.376C199.971 154.394 200.452 154.875 200.452 155.468C200.452 156.061 199.971 156.542 199.376 156.542Z"
                fill="#5E5E5E"
            ></path>
            <path
                d="M209.27 108.908C208.763 108.908 208.254 108.73 207.845 108.368C206.959 107.583 206.878 106.228 207.665 105.341L218.391 93.2757C219.178 92.3908 220.535 92.3101 221.423 93.0956C222.309 93.8811 222.39 95.2358 221.603 96.1224L210.878 108.188C210.453 108.665 209.864 108.908 209.271 108.908H209.27Z"
                fill="#E84D4F"
            ></path>
            <path
                d="M226.424 88.6042C226.424 88.6042 219.592 91.463 212.899 87.2477L218.194 83.2073L226.245 87.2477L226.424 88.6042Z"
                fill="#C6C6C6"
            ></path>
            <path
                d="M219.994 98.9861C219.329 98.9861 218.655 98.8316 218.023 98.5061C215.917 97.4187 215.093 94.831 216.182 92.7286C218.425 88.3984 220.999 82.849 221.287 81.4186L221.847 77.2446C222.064 75.636 221.699 74.7305 221.476 74.4767C221.44 74.4358 221.332 74.3121 220.823 74.3121C218.451 74.3121 216.529 72.3932 216.529 70.0248C216.529 67.6567 218.451 65.7377 220.823 65.7377C223.665 65.7377 226.193 66.8368 227.942 68.8313C230.012 71.1926 230.871 74.5865 230.361 78.3833L229.789 82.6466C229.783 82.6824 229.778 82.7186 229.773 82.7564C229.266 85.91 225.436 93.5361 223.812 96.6694C223.05 98.1424 221.548 98.9878 219.995 98.9878L219.994 98.9861Z"
                fill="#C6C6C6"
            ></path>
            <path d="M225.837 73.0895H215.838V85.1572H225.837V73.0895Z" fill="#C6C6C6"></path>
            <path
                d="M222.505 60.6137C222.899 61.7335 222.306 62.9596 221.186 63.3524C220.064 63.745 218.838 63.1534 218.444 62.0336C218.211 61.3682 218.326 60.6651 218.694 60.1215C218.943 59.7511 219.31 59.4561 219.764 59.2983C220.59 59.0102 221.475 59.2537 222.04 59.8488C222.243 60.0615 222.402 60.3187 222.507 60.6137H222.505Z"
                fill="#E84D4F"
            ></path>
            <path
                d="M220.309 59.7425C220.632 59.6293 220.934 59.4835 221.216 59.3103C220.764 59.144 220.254 59.1268 219.763 59.2983C219.359 59.4389 219.024 59.6893 218.78 60.0031C219.287 60.0031 219.804 59.9208 220.309 59.7425Z"
                fill="#474747"
            ></path>
            <path
                d="M189.757 89.4495C188.623 89.4495 187.676 88.5646 187.614 87.4208C187.551 86.2392 188.46 85.2292 189.643 85.1657L205.038 84.3406C206.22 84.2774 207.233 85.1846 207.297 86.366C207.361 87.5476 206.452 88.5576 205.268 88.6211L189.873 89.4459C189.834 89.4478 189.795 89.4495 189.757 89.4495Z"
                fill="#E84D4F"
            ></path>
            <path
                d="M191.934 87.4087H189.452C188.883 87.4087 188.421 86.9477 188.421 86.3798C188.421 85.8123 188.883 85.351 189.452 85.351H191.934C192.503 85.351 192.965 85.8123 192.965 86.3798C192.965 86.9477 192.503 87.4087 191.934 87.4087Z"
                fill="#E84D4F"
            ></path>
        </g>
        <path
            d="M1 9.9624C1 5.0126 4.96634 1 9.85906 1H322.141C327.034 1 331 5.0126 331 9.9624V24.8997H1V9.9624Z"
            fill="#FEFFFF"
        ></path>
        <path
            d="M17.2416 12.5764C17.2416 14.0201 16.0848 15.1905 14.6577 15.1905C13.2307 15.1905 12.0738 14.0201 12.0738 12.5764C12.0738 11.1327 13.2307 9.9624 14.6577 9.9624C16.0848 9.9624 17.2416 11.1327 17.2416 12.5764Z"
            fill="#E1242F"
        ></path>
        <path
            d="M24.6242 12.5764C24.6242 14.0201 23.4673 15.1905 22.0403 15.1905C20.6132 15.1905 19.4564 14.0201 19.4564 12.5764C19.4564 11.1327 20.6132 9.9624 22.0403 9.9624C23.4673 9.9624 24.6242 11.1327 24.6242 12.5764Z"
            fill="#EE7B2A"
        ></path>
        <path
            d="M32.0067 12.5764C32.0067 14.0201 30.8499 15.1905 29.4228 15.1905C27.9958 15.1905 26.8389 14.0201 26.8389 12.5764C26.8389 11.1327 27.9958 9.9624 29.4228 9.9624C30.8499 9.9624 32.0067 11.1327 32.0067 12.5764Z"
            fill="#43DD33"
        ></path>
        <path
            d="M38.651 11.9896V16.6842H41.4933V14.9771H43.9295V16.6842H46.7718V11.9896L42.7114 7.7218L38.651 11.9896Z"
            fill="#9D9D9D"
        ></path>
        <path
            d="M310.329 9.9624C310.329 9.54991 310.659 9.21553 311.067 9.21553H319.188C319.596 9.21553 319.926 9.54991 319.926 9.9624C319.926 10.3749 319.596 10.7093 319.188 10.7093H311.067C310.659 10.7093 310.329 10.3749 310.329 9.9624Z"
            fill="#9D9D9D"
        ></path>
        <path
            d="M310.329 12.203C310.329 11.7905 310.659 11.4561 311.067 11.4561H319.188C319.596 11.4561 319.926 11.7905 319.926 12.203C319.926 12.6155 319.596 12.9499 319.188 12.9499H311.067C310.659 12.9499 310.329 12.6155 310.329 12.203Z"
            fill="#9D9D9D"
        ></path>
        <path
            d="M310.329 14.4436C310.329 14.0311 310.659 13.6967 311.067 13.6967H319.188C319.596 13.6967 319.926 14.0311 319.926 14.4436C319.926 14.8561 319.596 15.1905 319.188 15.1905H311.067C310.659 15.1905 310.329 14.8561 310.329 14.4436Z"
            fill="#9D9D9D"
        ></path>
        <path
            d="M52.6779 12.5764C52.6779 9.48281 55.1568 6.97493 58.2148 6.97493H298.886C301.944 6.97493 304.423 9.48281 304.423 12.5764C304.423 15.67 301.944 18.1779 298.886 18.1779H58.2148C55.1568 18.1779 52.6779 15.67 52.6779 12.5764Z"
            fill="#F1F1F1"
        ></path>
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M61.2491 13.6721H60.999L60.9104 13.5856C61.2206 13.2205 61.4074 12.7465 61.4074 12.2309C61.4074 11.0811 60.4862 10.1491 59.3497 10.1491C58.2132 10.1491 57.2919 11.0811 57.2919 12.2309C57.2919 13.3806 58.2132 14.3126 59.3497 14.3126C59.8594 14.3126 60.3279 14.1236 60.6888 13.8098L60.7743 13.8995V14.1525L62.3572 15.7506L62.8289 15.2734L61.2491 13.6721ZM59.3497 13.6721C58.5614 13.6721 57.9251 13.0283 57.9251 12.2309C57.9251 11.4334 58.5614 10.7896 59.3497 10.7896C60.138 10.7896 60.7743 11.4334 60.7743 12.2309C60.7743 13.0283 60.138 13.6721 59.3497 13.6721Z"
            fill="black"
            fill-opacity="0.647059"
        ></path>
        <path
            d="M291.134 9.9624V15.3942H293.595V14.9415H291.582V11.3203H296.28V12.2256H296.727V9.9624H291.134Z"
            fill="black"
            fill-opacity="0.647059"
        ></path>
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M297.305 12.892L296.963 12.5463L295.608 13.9168L294.254 12.5463L293.912 12.892L295.267 14.2625L293.912 15.6331L294.254 15.9788L295.608 14.6082L296.963 15.9788L297.305 15.6331L295.95 14.2625L297.305 12.892Z"
            fill="#E1242F"
        ></path>
        <path
            d="M331 25.1497H331.25V24.8997V9.9624C331.25 4.87727 327.174 0.75 322.141 0.75H9.85906C4.82554 0.75 0.75 4.87727 0.75 9.9624V24.8997V25.1497H1H331Z"
            stroke="#F2F5F6"
            stroke-width="0.5"
        ></path>
        <path
            d="M178 168.5C178 168.5 204.5 210.5 258 184C311.5 157.5 297 24.5001 297 24.5001"
            stroke="#00D639"
            stroke-width="2"
            stroke-dasharray="4 6"
        ></path>
        <circle cx="294.5" cy="12.5001" r="10.5" stroke="#00D639" stroke-width="2"></circle>
    </svg>
);

export default PermissionsPic;
