import React, { memo, useMemo } from 'react';
import { getSrcCompanyCircleAvatar } from 'utils/getSrcAvatar';
import { Text, Icon, Avatar, Spacer } from 'hoi-poi-ui';

function SortableEntity({ entity, index }) {
    const { src, fallbackSrc } = useMemo(
        () => getSrcCompanyCircleAvatar(entity.AccountId),
        [entity],
    );
    return (
        <div className="fm-schedule-sorting-map-modal__entity">
            <Icon name="dragAndDrop" className="fm-schedule-sorting-map-modal__icon" />
            <Text className="fm-schedule-sorting-map-modal__entity-index" medium>
                {index + 1}
            </Text>
            <Avatar
                className="fm-schedule-sorting-map-modal__entity-avatar"
                src={src}
                size="small"
                placeholder={fallbackSrc}
            />
            <Spacer x={1} />
            <Text
                className="fm-schedule-sorting-map-modal__entity-name"
                type="subtitle"
                isTruncated
                useTooltip
                medium
            >
                {entity.AccountName}
            </Text>
        </div>
    );
}

export default memo(SortableEntity);
