import { memo } from 'react';
import { useTheme } from 'hoi-poi-ui';
import TruncatedTextPopover from 'components/TruncatedTextPopover';
import { BaseReactCell } from '@web/web5';

const TextPopoverCell = memo(({ value, ...props }) => {
    const theme = useTheme();
    return (
        <BaseReactCell data={value} {...props}>
            <div className="fm-automation-settings__text-popover">
                <TruncatedTextPopover color={theme.colors.utility.textPrimary}>
                    {value || '-'}
                </TruncatedTextPopover>
            </div>
        </BaseReactCell>
    );
});

export default TextPopoverCell;
