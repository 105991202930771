import React, { memo, useRef, useCallback, useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { rrulestr } from 'rrule';
import moment from 'moment';
import { Loader, Text, Icon, useTheme } from 'hoi-poi-ui';
import Calendar from 'components/Calendar';
import { AgendaActions, EntityListActions, EntityFiltersActions } from 'actions';
import { ensureRoute } from 'utils/routes';
import { AGENDA, TASKS } from 'constants/Entities';
import { publish } from 'lib/EventBuser';
import { REFRESH_TABLE } from 'lib/events';
import Event from './Event';
import { CALENDAR_VIEWS } from 'constants/Constants';
import { getBackendBoolean } from 'utils/fm';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { errorToast } from 'utils/toast';
import { getDateToTimezone } from 'utils/dates';
import colors from 'constants/colors';
import { getViewOptions, translateViewForCalendar } from '../../utils';
import { getMonthNames, getMonthNamesShort, getDayNames, getDayNamesShort } from 'utils/dates';
import { logEvent } from 'utils/tracking';
import { getScrollTime } from 'containers/agenda/utils';
import { getNewStrRuleWithOffset } from 'utils/rrule';

import './styles.scss';

const propTypes = {
    dataRange: PropTypes.object,
    calendarData: PropTypes.array,
    usersFilterIds: PropTypes.array,
    langISOInterface: PropTypes.string,
    locale: PropTypes.string,
    getCalendarApi: PropTypes.object,
    changeOutlierFilters: PropTypes.func,
    updateEventFromCalendar: PropTypes.func,
    updateRowFields: PropTypes.func,
    canEdit: PropTypes.bool,
    changeDateRangeFilter: PropTypes.func,
    calendarView: PropTypes.string,
    timezone: PropTypes.object,
    loading: PropTypes.bool,
};

const mapStateToProps = (state) => {
    const agendaFilters = state.entityFilters?.[AGENDA.entity] || null;

    const userData = state.config.userData;
    const permissions = state.config.permission.crud_permission.AGENDA;
    const canEdit = permissions.update;
    const canCreate = permissions.canCreate;
    const agendaList = state.entityList?.[AGENDA.entity];
    const tasksList = state.entityList?.[TASKS.entity];
    const calendarData = agendaList?.data || [];
    const loading = agendaList?.loading || false;
    const tasksLoading = tasksList?.loading || false;
    const agendaState = state[AGENDA.entity];

    const fini = agendaFilters?.filters?.fini?.value || null;
    const ffin = agendaFilters?.filters?.ffin?.value || null;

    const calendarView = agendaFilters?.outlierFilters?.view?.value || null;

    return {
        calendarData,
        langISOInterface: userData.langISOInterface,
        locale: userData.locale,
        canEdit,
        canCreate,
        calendarView,
        timezone: agendaState.timezone,
        loading,
        tasksLoading,
        fini,
        ffin,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeDateRangeFilter: bindActionCreators(AgendaActions, dispatch).changeDateRangeFilter,
        changeTaskFlag: bindActionCreators(AgendaActions, dispatch).changeTaskFlag,
        changeOutlierFilter: bindActionCreators(EntityFiltersActions, dispatch).changeOutlierFilter,
        updateEventFromCalendar: bindActionCreators(AgendaActions, dispatch)
            .updateEventFromCalendar,
        updateRowFields: bindActionCreators(EntityListActions, dispatch).updateRowFields,
    };
};

const AgendaCalendar = memo(
    ({
        calendarData,
        langISOInterface,
        locale,
        getCalendarApi,
        changeOutlierFilter,
        updateEventFromCalendar,
        updateRowFields,
        canEdit,
        changeDateRangeFilter,
        calendarView,
        timezone,
        loading,
        tasksLoading,
        fini,
        ffin,
    }) => {
        const theme = useTheme();
        const calendarRef = useRef(null);
        const calendarApiRef = useRef(null);
        const eventsStartDateMap = useRef({});
        const isFirstRenderRef = useRef(true);
        const [showTimezoneBox, setShowTimezoneBox] = useState(false);
        const prevTimezoneRef = useRef(timezone);
        const timezoneTimeoutRef = useRef(null);
        const isSmallDetailOpen = useRef(false);
        const currentlyUpdating = useRef([]);
        let dateRange = null;

        if (fini && ffin) {
            dateRange = {
                startDate: moment.utc(fini, 'YYYY-MM-DDTHH:mm:ss').milliseconds(0).toDate(),
                endDate: moment.utc(ffin, 'YYYY-MM-DDTHH:mm:ss').milliseconds(0).toDate(),
            };
        }

        const activateShowTimezoneBox = useCallback(() => {
            setShowTimezoneBox(true);
            timezoneTimeoutRef.current = setTimeout(() => {
                setShowTimezoneBox(false);
            }, [3000]);
        }, []);

        useEffect(() => {
            if (
                prevTimezoneRef?.current &&
                timezone &&
                prevTimezoneRef?.current?.value !== timezone.value
            ) {
                if (timezoneTimeoutRef.current) clearTimeout(timezoneTimeoutRef.current);
                activateShowTimezoneBox();
            }
        }, [timezone, activateShowTimezoneBox]);

        useEffect(() => {
            if (
                dateRange?.startDate &&
                calendarApiRef.current &&
                dateRange?.startDate.getTime() !== calendarApiRef.current.view.activeStart.getTime()
            ) {
                calendarApiRef.current.gotoDate(dateRange.startDate);
            }
        }, [dateRange]);

        useEffect(() => {
            // Control that dateRange and view in calendar match when refreshing and when changing sections
            if (
                isFirstRenderRef.current &&
                dateRange?.startDate &&
                calendarApiRef.current &&
                calendarView
            ) {
                const startMoment = moment(dateRange.startDate);
                const endMoment = moment(dateRange.endDate);
                const diffDays = Math.abs(startMoment.diff(endMoment, 'days'));
                const currentViewType = calendarApiRef.current.view.type;

                let initialView;
                const calendarViewOptions = getViewOptions();

                if (
                    diffDays === 1 &&
                    (calendarView.value !== CALENDAR_VIEWS.day ||
                        calendarView.value !== currentViewType)
                ) {
                    initialView = calendarViewOptions.reduce((obj, current) => {
                        if (current.value === CALENDAR_VIEWS.day) obj = current;
                        return obj;
                    }, {});
                } else if (
                    diffDays > 1 &&
                    diffDays < 7 &&
                    (calendarView.value !== CALENDAR_VIEWS.workweek ||
                        translateViewForCalendar(calendarView) !== currentViewType)
                ) {
                    initialView = calendarViewOptions.reduce((obj, current) => {
                        if (current.value === CALENDAR_VIEWS.workweek) obj = current;
                        return obj;
                    }, {});
                } else if (
                    diffDays === 7 &&
                    (calendarView.value !== CALENDAR_VIEWS.week ||
                        calendarView.value !== currentViewType)
                ) {
                    initialView = calendarViewOptions.reduce((obj, current) => {
                        if (current.value === CALENDAR_VIEWS.week) obj = current;
                        return obj;
                    }, {});
                } else if (
                    diffDays > 7 &&
                    (calendarView.value !== CALENDAR_VIEWS.month ||
                        calendarView.value !== currentViewType)
                ) {
                    initialView = calendarViewOptions.reduce((obj, current) => {
                        if (current.value === CALENDAR_VIEWS.month) obj = current;
                        return obj;
                    }, {});
                }

                if (initialView) {
                    changeOutlierFilter({
                        entity: AGENDA,
                        filter: { id: 'view' },
                        value: initialView,
                    });
                    calendarApiRef.current.changeView(initialView.value);
                }

                isFirstRenderRef.current = false;
            }
        }, [calendarView, dateRange, changeOutlierFilter]);

        const getIsSmallDetailOpen = useCallback((isOpen) => {
            isSmallDetailOpen.current = isOpen;
        }, []);

        const onDateClick = useCallback((info) => {
            if (isSmallDetailOpen?.current) return;
            logEvent({
                event: AGENDA.trueName,
                submodule: 'eventPanel',
                functionality: 'accessCrud',
            });
            const date = moment(info.date).format('YYYY-MM-DDTHH:mm');
            let params = `date=${date}`;

            if (info.allDay) {
                params = `${params}&isAllDay=true`;
            }
            ensureRoute(`${AGENDA.route}/event/new?${params}`);
        }, []);

        const eventsArray = useMemo(() => {
            if (!dateRange?.startDate || !dateRange?.endDate || calendarData.length === 0) {
                return [];
            }

            const { startDate, endDate } = dateRange;
            const events = calendarData.reduce((arr, item) => {
                if (!item.g_recurrence || item.g_recurrence === '') {
                    let obj = {
                        title: item.Asunto,
                        start: getDateToTimezone({
                            date: item.fini,
                            inputFormat: 'YYYY-MM-DD HH:mm',
                            outputFormat: 'YYYY-MM-DDTHH:mm',
                            timezone: timezone?.idianazone,
                        }),
                        end: getDateToTimezone({
                            date: item.hfin,
                            inputFormat: 'YYYY-MM-DD HH:mm',
                            outputFormat: 'YYYY-MM-DDTHH:mm',
                            timezone: timezone?.idianazone,
                        }),
                        allDay: item.isTodoDia === 'True' || item.isTodoDia === true,
                        extendedProps: {
                            ...item,
                        },
                    };
                    const canEditItem = getBackendBoolean(item.CanEdit);
                    if (!canEdit || !(canEditItem && !item.isReadOnly)) {
                        obj.editable = false;
                        obj.startEditable = false;
                    } else if (getBackendBoolean(item.isTarea)) {
                        obj.durationEditable = false;
                    }

                    eventsStartDateMap.current = {
                        ...eventsStartDateMap.current,
                        [item.Fini]: {
                            ...(eventsStartDateMap.current[item.Fini] || {}),
                            [item.Id]: { ...item },
                        },
                    };
                    arr.push(obj);
                } else {
                    let initDateString = item.Fini;
                    let initDateFormat = 'YYYY-MM-DD';
                    if (item.Hini) {
                        initDateString = initDateString + ' ' + item.Hini;
                        initDateFormat = initDateFormat + ' ' + 'HH:mm';
                    }
                    const initDate = moment.utc(initDateString, initDateFormat).toDate();
                    let g_recurrence =
                        item.g_recurrence?.replace('RRULE:RRULE:FREQ', 'FREQ') || item.g_recurrence;
                    g_recurrence = g_recurrence?.replace('RRULE:FREQ', 'FREQ');
                    const newGRecurrence = getNewStrRuleWithOffset(g_recurrence);
                    let rule = rrulestr(newGRecurrence, {
                        dtstart: initDate,
                    });

                    let excludeDates = [];
                    if (item.g_recurrence_exception) {
                        let newGRecurrence2 = newGRecurrence;
                        newGRecurrence2 += `\n${item.g_recurrence_exception}`;
                        let rule2 = rrulestr(newGRecurrence2, {
                            dtstart: initDate,
                            forceset: true,
                            cache: false,
                        });

                        excludeDates = rule2.exdates();
                    }

                    if (startDate && endDate) {
                        const dates = rule.between(startDate, endDate);
                        const day0 = moment(0, 'X');

                        for (let i = 0; i < dates.length; i++) {
                            let recurrent = {};
                            recurrent.title = item.Asunto + i;
                            if (excludeDates?.length) {
                                const excluded = excludeDates.find(
                                    (date) => date.getTime() === dates[i]?.getTime(),
                                );
                                if (excluded) continue;
                            }

                            let dateYearMonthDay = moment(dates[i]).format('YYYY-MM-DD');
                            let startTime = moment(item.fini).format('HH:mm');
                            let endTime = moment(item.hfin).format('HH:mm');
                            const startMoment = getDateToTimezone({
                                date: `${dateYearMonthDay} ${startTime}`,
                                inputFormat: 'YYYY-MM-DD HH:mm',
                                timezone: timezone?.idianazone,
                                returnMoment: true,
                            });
                            recurrent.start = startMoment.format('YYYY-MM-DDTHH:mm');

                            const endMoment = getDateToTimezone({
                                date: `${dateYearMonthDay} ${endTime}`,
                                inputFormat: 'YYYY-MM-DD HH:mm',
                                timezone: timezone?.idianazone,
                                returnMoment: true,
                            });
                            recurrent.end = endMoment.format('YYYY-MM-DDTHH:mm');
                            recurrent.allDay = item.isTodoDia === 'True' || item.isTodoDia === true;
                            recurrent.editable = false;
                            recurrent.startEditable = false;
                            const dateFormat = moment(dates[i]).format('DD/MM/YYYY');
                            let newDateIni;
                            let newDateFin;
                            if (recurrent.Hini && recurrent.Hini !== '') {
                                newDateIni = moment(
                                    dateFormat + ' ' + recurrent.Hini,
                                    'DD/MM/YYYY HH:mm',
                                );
                            } else {
                                newDateIni = moment(dateFormat, 'DD/MM/YYYY');
                            }
                            if (recurrent.Hfin && recurrent.Hfin !== '') {
                                newDateFin = moment(
                                    dateFormat + ' ' + recurrent.Hfin,
                                    'DD/MM/YYYY HH:mm',
                                );
                            } else {
                                newDateFin = moment(dateFormat, 'DD/MM/YYYY');
                            }

                            const extendedProps = {
                                ...item,
                                fini: startMoment.toDate(),
                                hini: startMoment.toDate(),
                                hfin: endMoment.toDate(),
                                Fini: newDateIni.format('YYYY-MM-DD'),
                                Fini_timestamp: newDateIni.diff(day0, 'seconds').toString(),
                                Ffin: newDateFin.format('YYYY-MM-DD'),
                                Ffin_timestamp: newDateFin.diff(day0, 'seconds').toString(),
                            };
                            recurrent.extendedProps = { ...extendedProps };
                            eventsStartDateMap.current = {
                                ...eventsStartDateMap.current,
                                [extendedProps.Fini]: {
                                    ...(eventsStartDateMap.current[extendedProps.Fini] || {}),
                                    [extendedProps.Id]: { ...extendedProps },
                                },
                            };
                            arr.push(recurrent);
                        }
                    }
                }
                return arr;
            }, []);

            return events;
        }, [calendarData, canEdit, dateRange, timezone]);

        const onMoreLinkClick = useCallback(
            (info) => {
                if (!calendarApiRef.current) return;
                const calendarViewOptions = getViewOptions();
                const dayView = calendarViewOptions.reduce((obj, current) => {
                    if (current.value === CALENDAR_VIEWS.day) obj = current;
                    return obj;
                }, {});
                changeOutlierFilter({ entity: AGENDA, filter: { id: 'view' }, value: dayView });
                calendarApiRef.current.changeView(dayView.value);
                const newStartDate = moment(info.date)
                    .hours(0)
                    .minutes(0)
                    .seconds(0)
                    .milliseconds(0)
                    .utc()
                    .toDate();
                const newEndDate = moment(newStartDate).add(1, 'days').utc().toDate();
                calendarApiRef.current.gotoDate(newStartDate);
                const dateRange = { startDate: newStartDate, endDate: newEndDate };
                changeDateRangeFilter(dateRange, true);
            },
            [changeOutlierFilter, changeDateRangeFilter],
        );

        const isUSA = useMemo(() => {
            if (locale === 'en-US') return true;
            return false;
        }, [locale]);

        const firstDay = useMemo(() => {
            if (isUSA) return 0;
            return 1;
        }, [isUSA]);

        const dayFormat = useMemo(() => {
            if (isUSA) return 'ddd M/d';
            return 'ddd d/M';
        }, [isUSA]);

        // const hiddenWeekendDays = useMemo(() => {
        //     if (isUSA) return [6, 7];
        //     return [6, 7];
        // }, [isUSA]);

        const onEditEvent = useCallback(
            (data) => {
                if (!data || data?.g_recurrence) return;
                const canEditItem = getBackendBoolean(data.CanEdit);
                if (!canEdit || !(canEditItem && !data.isReadOnly)) {
                    return;
                }
                logEvent({
                    event: AGENDA.trueName,
                    submodule: 'popoverDetail',
                    functionality: 'update',
                });
                if (getBackendBoolean(data.isTarea)) {
                    ensureRoute(`${AGENDA.route}/task/${data.Id}/edit`);
                } else {
                    ensureRoute(`${AGENDA.route}/event/${data.Id}/edit`);
                }
                // We set it to false, so we will be able to click on calendar
                // grid cell to create an event.
                getIsSmallDetailOpen(false);
            },
            [canEdit, getIsSmallDetailOpen],
        );

        const renderEventContent = useCallback(
            (item) => {
                return (
                    <Event
                        data={item}
                        onEditEvent={onEditEvent}
                        getIsSmallDetailOpen={getIsSmallDetailOpen}
                    />
                );
            },
            [onEditEvent, getIsSmallDetailOpen],
        );

        const getCalendarRef = useCallback(
            (ref) => {
                calendarRef.current = ref;
                const newApi = ref.getApi();
                getCalendarApi(newApi);
                calendarApiRef.current = newApi;
            },
            [getCalendarApi],
        );

        const onEventResize = useCallback(
            (info) => {
                const { event, endDelta, revert } = info;
                const { milliseconds } = endDelta;
                const backupExtendedProps = { ...event.extendedProps };
                let data = { ...event.extendedProps };
                if (
                    data.g_recurrence ||
                    !canEdit ||
                    !(getBackendBoolean(data.CanEdit) && !data.isReadOnly) ||
                    getBackendBoolean(data.isTarea) ||
                    currentlyUpdating.current.includes(data.id)
                ) {
                    revert();
                    return;
                }
                currentlyUpdating.current.push(data.id);
                const dateUpdated = moment(data.hfin).add(milliseconds, 'milliseconds');

                const newFfin = dateUpdated.format('YYYY/MM/DD');
                const newHfin = dateUpdated.format('HH:mm');
                const newFfinTimestampSeconds = dateUpdated.valueOf() / 1000;

                const nFfin_timestamp = newFfinTimestampSeconds.toString();

                data.hfin = dateUpdated.toDate();

                data.Ffin = newFfin;
                data.Hfin = newHfin;
                data.Ffin_timestamp = nFfin_timestamp;

                const newFields = {
                    hfin: dateUpdated.toDate(),
                    Ffin: newFfin,
                    Hfin: newHfin,
                    Ffin_timestamp: nFfin_timestamp,
                };

                const oldFields = {
                    hfin: backupExtendedProps.hfin,
                    Ffin: backupExtendedProps.Ffin,
                    Hfin: backupExtendedProps.Hfin,
                    Ffin_timestamp: backupExtendedProps.Ffin_timestamp,
                };
                // From on side we update the inner data of the calendar
                event.setProp('extendedProps', data);
                // Later we update the data from AGENDA store
                updateRowFields(AGENDA, data.Id, newFields);

                updateEventFromCalendar(data)
                    .then(() => {
                        logEvent({
                            event: AGENDA.trueName,
                            submodule: 'eventPanel',
                            functionality: 'update',
                        });
                    })
                    .catch(() => {
                        errorToast({ text: getLiteral('error_tryitagain') });
                        // Reset item data from calendar to a previous state
                        event.setProp('extendedProps', backupExtendedProps);
                        // Revert movement
                        info.revert();
                        // Reset item from the AGENDA store to a previous state
                        updateRowFields(AGENDA, data.Id, oldFields);
                    })
                    .finally(() => {
                        currentlyUpdating.current = currentlyUpdating.current.filter(
                            (current) => current !== data.id,
                        );
                    });
            },
            [updateEventFromCalendar, canEdit, updateRowFields],
        );

        const updateDropDates = useCallback(
            ({ data, startMoment, endMoment, days, milliseconds }) => {
                let startUpdated = startMoment.add(milliseconds, 'milliseconds');
                let endUpdated = endMoment.add(milliseconds, 'milliseconds');

                if (days) {
                    startUpdated.add(days, 'days');
                    endUpdated.add(days, 'days');
                }

                const newFiniTimestampSeconds = startUpdated.valueOf() / 1000;
                const newFfinTimestampSeconds = endUpdated.valueOf() / 1000;

                data.fini = startUpdated.toDate();
                data.hini = startUpdated.toDate();
                data.hfin = endUpdated.toDate();

                data.Fini = startUpdated.format('YYYY/MM/DD');
                data.Hini = startUpdated.format('HH:mm');
                data.Fini_timestamp = newFiniTimestampSeconds.toString();

                data.Ffin = endUpdated.format('YYYY/MM/DD');
                data.Hfin = endUpdated.format('HH:mm');
                data.Ffin_timestamp = newFfinTimestampSeconds.toString();
            },
            [],
        );

        const onEventDrop = useCallback(
            (info) => {
                const { delta, event, oldEvent, revert } = info;

                const { milliseconds, days } = delta;
                const backupExtendedProps = { ...event.extendedProps };
                let data = { ...event.extendedProps };
                if (
                    data.g_recurrence ||
                    !canEdit ||
                    !(getBackendBoolean(data.CanEdit) && !data.isReadOnly) ||
                    currentlyUpdating.current.includes(data.id)
                ) {
                    revert();
                    return;
                }

                if (getBackendBoolean(data.isTarea) && tasksLoading) {
                    revert();
                    return;
                }

                currentlyUpdating.current.push(data.id);
                let startMoment = moment(data.fini);

                if (event.allDay !== oldEvent.allDay) {
                    if (getBackendBoolean(data.isTarea)) {
                        revert();
                        return;
                    }

                    data.isTodoDia = event.allDay ? true : false;

                    if (event.allDay) {
                        startMoment.hours(0).minutes(0).seconds(0);
                        let endMoment = startMoment.clone().hours(23).minutes(59);
                        updateDropDates({ data, startMoment, endMoment, days, milliseconds });
                    } else {
                        startMoment.hours(0).minutes(0).seconds(0);
                        let endMoment = startMoment.clone().hours(1);

                        updateDropDates({ data, startMoment, endMoment, days, milliseconds });
                    }
                } else {
                    let endMoment = moment(data.hfin);

                    updateDropDates({ data, startMoment, endMoment, days, milliseconds });
                }

                const newFields = {
                    fini: data.fini,
                    hini: data.hini,
                    hfin: data.hfin,
                    Fini: data.Fini,
                    Hini: data.Hini,
                    Fini_timestamp: data.Fini_timestamp,

                    Ffin: data.Ffin,
                    Hfin: data.Hfin,
                    Ffin_timestamp: data.Ffin_timestamp,
                    isTodoDia: data.isTodoDia,
                };

                const oldFields = {
                    fini: backupExtendedProps.fini,
                    hini: backupExtendedProps.hini,
                    hfin: backupExtendedProps.hfin,
                    Fini: backupExtendedProps.Fini,
                    Hini: backupExtendedProps.Hini,
                    Fini_timestamp: backupExtendedProps.Fini_timestamp,

                    Ffin: backupExtendedProps.Ffin,
                    Hfin: backupExtendedProps.Hfin,
                    Ffin_timestamp: backupExtendedProps.Ffin_timestamp,
                    isTodoDia: backupExtendedProps.isTodoDia,
                };
                // First we update the inner data of the calendar
                event.setProp('extendedProps', data);
                // Later we update the data from AGENDA store
                updateRowFields(AGENDA, data.Id, newFields);

                updateEventFromCalendar(data)
                    .then(() => {
                        if (data.isTarea) {
                            publish(`${REFRESH_TABLE}_${TASKS.entity}`);
                        }
                        logEvent({
                            event: AGENDA.trueName,
                            submodule: 'eventPanel',
                            functionality: 'update',
                        });
                    })
                    .catch(() => {
                        errorToast({ text: getLiteral('error_tryitagain') });
                        // Reset item data from calendar to a previous state
                        event.setProp('extendedProps', backupExtendedProps);
                        // Revert movement
                        revert();
                        // Reset item from the AGENDA store to a previous state
                        updateRowFields(AGENDA, data.Id, oldFields);
                    })
                    .finally(() => {
                        currentlyUpdating.current = currentlyUpdating.current.filter(
                            (current) => current !== data.id,
                        );
                    });
            },
            [updateEventFromCalendar, canEdit, updateDropDates, updateRowFields, tasksLoading],
        );

        const loaderBox = useMemo(() => {
            return (
                <div className="fm-agenda-calendar__loader">
                    <Loader size="tiny" color="white" />
                    <Text
                        className="fm-agenda-calendar__loader-text"
                        type="subtitle1"
                        color={theme.colors.primary.white}
                    >
                        {getLiteral('label_new_calendar_loading_grid')}
                    </Text>
                </div>
            );
        }, [theme]);

        const timezoneBox = useMemo(() => {
            return (
                <div className="fm-agenda-calendar__timezone__info">
                    <Icon name="timeZone" size="large" color={colors.primary.white} />
                    <Text
                        className="fm-agenda-calendar__timezone__info-text"
                        type="subtitle1"
                        color={theme.colors.primary.white}
                    >
                        {getLiteralWithParameters('label_calendar_changed_timezone', [
                            timezone?.fulldescription,
                        ])}
                    </Text>
                </div>
            );
        }, [timezone, theme]);

        const timeLabels = {
            monthNames: getMonthNames(),
            monthNamesShort: getMonthNamesShort(),
            dayNames: getDayNames(),
            dayNamesShort: getDayNamesShort(),
        };

        if (!dateRange?.startDate) return null;

        return (
            <div className="fm-agenda-calendar">
                <Calendar
                    className="fm-agenda-calendar__grid"
                    defaultDate={dateRange?.startDate || null}
                    headerToolbar={false}
                    events={eventsArray}
                    renderEventContent={renderEventContent}
                    onDateClick={onDateClick}
                    locale={langISOInterface}
                    firstDay={firstDay}
                    // I wouldn't use hiddenDays because when changing the Timezone it can cause
                    // not showing some events that fall in weekend
                    // hiddenDays={hiddenWeekendDays}
                    columnFormat={{
                        agendaWeek: dayFormat,
                        agendaDay: dayFormat,
                    }}
                    getCalendarRef={getCalendarRef}
                    dayMaxEvents={true}
                    moreLinkClick={onMoreLinkClick}
                    weekMode="liquid" // or fixed
                    fixedWeekCount={false} // if fixed this must be true
                    selectable={true}
                    eventStartEditable={true}
                    eventResize={onEventResize}
                    eventDrop={onEventDrop}
                    weekends={calendarView?.showWeekends || true}
                    {...timeLabels}
                    scrollTime={getScrollTime()}
                />
                {loading && loaderBox}
                {showTimezoneBox && timezoneBox}
            </div>
        );
    },
);

AgendaCalendar.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(AgendaCalendar);
