import React, { memo, useCallback, useMemo, useState } from 'react';
import classnames from 'classnames';
import { Icon, useTheme } from 'hoi-poi-ui';
import PopoverHoi from 'components/PopoverHoi';
import './styles.scss';

const OptionsMenu = memo(({ children, menuSchema }) => {
    const theme = useTheme();
    const [visible, setVisible] = useState(false);

    const onToggleMenu = useCallback(() => setVisible(!visible), [visible]);

    const onClick = useCallback(
        (actionOnClick) => {
            onToggleMenu();
            actionOnClick();
        },
        [onToggleMenu],
    );

    const popoverOverride = useMemo(
        () => ({
            root: { visible, onVisibleChange: onToggleMenu },
        }),
        [visible, onToggleMenu],
    );

    const MenuItem = useCallback(
        (item) => {
            const { type, icon, id, label, onClick: onClickProp } = item;

            const itemClassNames = classnames('options-menu__item', {
                [`options-menu__item--${id}`]: id,
            });

            switch (type) {
                case 'divider':
                    return <div className="options-menu__divider" />;
                case 'title':
                    return <div className="options-menu__title">{label}</div>;
                default:
                    return (
                        <div className={itemClassNames} onClick={() => onClick(onClickProp)}>
                            {icon && (
                                <Icon
                                    className="options-menu__item__icon"
                                    name={icon}
                                    color="currentColor"
                                />
                            )}
                            <span className="options-menu__item__label">{label}</span>
                        </div>
                    );
            }
        },
        [onClick],
    );

    const MenuContent = useMemo(
        () => (
            <div className="options-menu__dropdown">
                {menuSchema.map((current, index) => {
                    const Item = () => MenuItem(current);
                    return <Item key={`options-menu-item-${index}`} />;
                })}
            </div>
        ),
        [MenuItem, menuSchema],
    );

    if (!menuSchema || menuSchema?.length === 0) return null;

    return (
        <PopoverHoi
            className="options-menu"
            content={MenuContent}
            placement="bottomLeft"
            overrides={popoverOverride}
        >
            {children ? (
                <div className="options-menu__toggle" onClick={onToggleMenu}>
                    {children}
                </div>
            ) : (
                <Icon
                    className="options-menu__toggle"
                    name="threeDots"
                    color={theme.colors.actionMinor[500]}
                    onClick={onToggleMenu}
                />
            )}
        </PopoverHoi>
    );
});

export default OptionsMenu;
