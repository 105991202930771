import React, { memo, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { Text, useTheme } from 'hoi-poi-ui';

import { getLiteral } from 'utils/getLiteral';

import './styles.scss';

const PricingSwitch = memo(({ value = 'monthly', onChange }) => {
    const theme = useTheme();
    const onClick = useCallback((newValue) => () => onChange(newValue), [onChange]);
    const OPTIONS = useMemo(
        () => [
            { label: getLiteral('action_license_toggle_monthly'), value: 'monthly' },
            {
                label: getLiteral('action_license_toggle_annual'),
                value: 'annual',
            },
        ],
        [],
    );

    return (
        <div className="pricing-switch">
            {OPTIONS.map((option) => (
                <button
                    key={option.value}
                    className={classNames('pricing-switch__button', {
                        'pricing-switch__button--active': option.value === value,
                    })}
                    onClick={onClick(option.value)}
                >
                    <Text type="caption" color={theme.colors.utility.textSecondary}>
                        {option.label}
                    </Text>
                </button>
            ))}
        </div>
    );
});

export default PricingSwitch;
