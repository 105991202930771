import { memo, useMemo, useState, useCallback } from 'react';
import { Text, Popover, Button, useTheme } from 'hoi-poi-ui';
import { DEV_ENVIRONMENTS, setDevEnvironment, getDevEnvironment } from './environments';
import './styles.scss';

const DevPopUp = memo(() => {
    const [selected, setSelected] = useState(getDevEnvironment());
    const theme = useTheme();

    const setEnvironment = useCallback(
        (name) => {
            if (name === selected) {
                setSelected('');
                setDevEnvironment('');
                window.location.reload();
            } else {
                setSelected(name);
                setDevEnvironment(name);
            }

            window.location.reload();
        },
        [selected],
    );

    const PopUpContent = useMemo(() => {
        return (
            <div className="fm-dev__popup__buttons">
                {DEV_ENVIRONMENTS.map((env, index) => (
                    <Button
                        key={index}
                        size="small"
                        type={env.name === selected ? 'primary' : 'secondary'}
                        onClick={() => setEnvironment(env.name)}
                    >
                        {env.name}
                    </Button>
                ))}
            </div>
        );
    }, [selected, setEnvironment]);

    if (process.env.NODE_ENV !== 'development') return null;

    return (
        <div className="fm-dev__popup">
            <Popover placement="top" content={PopUpContent}>
                <div className="fm-dev__popup__tag">
                    <Text color={theme.colors.primary.white}>ENVIRONMENTS</Text>
                </div>
            </Popover>
        </div>
    );
});

export default memo(DevPopUp);
