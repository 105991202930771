import React, { memo, useCallback, useMemo, useRef, useState, useEffect, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';
import { ToastContainer, useTheme } from 'hoi-poi-ui';
import { ServerListActions, EntityModalActions } from 'actions';
import { NotificationsService } from 'services';
import { getMomentFromDateBackend, getDifferenceBetweenDates, getTodayMoment } from 'utils/dates';
import useClickOutside from 'utils/hooks/useClickOutside';
import { Calendar, VideoCam, VideoCheckIn, EventPending } from 'components/SvgIcons';
import PopoverHoi from 'components/PopoverHoi';
import CalendarNotificationsPopover from './CalendarNotificationsPopover';
import CalendarNotificationsToast from './CalendarNotificationsToast';
import { isEqual } from 'utils/objects';
import { calendarToast, clearToast, CALENDAR_NOTIFICATIONS_TOAST } from 'utils/toast';

import './styles.scss';

const NEXT_TOAST_TIME_LAPSE_MINUTES = 15;
const NOTIFICATIONS_POLLING_TIME_MINUTES = 10;
const NOTIFICATIONS_POLLING_TIME = NOTIFICATIONS_POLLING_TIME_MINUTES * 60 * 1000;
const CLOSED_NOTIFICATIONS = 'closed_notifications';

const mapStateToProps = (state, props) => {
    return {
        hideCalendarNotificationToast: state.config?.userData?.hideCalendarNotificationToast,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getList: bindActionCreators(ServerListActions, dispatch).getList,
        modalInit: bindActionCreators(EntityModalActions, dispatch).init,
    };
};

const CalendarNotifications = ({
    getList,
    hideCalendarNotificationToast,
    setActiveNotifications,
    modalInit,
}) => {
    const [focused, setFocused] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [activityTypes, setActivityTypes] = useState({});
    const popoverRef = useRef();
    const pollingInterval = useRef();
    const notificationsWithToast = useRef({});
    const theme = useTheme();

    useClickOutside((evt) => {
        if (popoverRef.current && !popoverRef.current.contains(evt.target)) {
            setFocused(false);
        }
    }, []);

    const getCalendarNotifications = useCallback(() => {
        NotificationsService.getCalendarNotifications()
            .then((result) => {
                const todayMoment = getTodayMoment();
                setNotifications(
                    result.filter((notification) => {
                        const startDate = getMomentFromDateBackend(
                            notification.startDate,
                            'YYYY-MM-DDTHH:mm:ss[Z]',
                            true,
                        );
                        const minutesFromNow = getDifferenceBetweenDates(
                            startDate,
                            todayMoment,
                            'minutes',
                        );
                        if (minutesFromNow > -10) return true;
                    }),
                );
            })
            .catch((error) => {
                console.error('getCalendarNotifications error:', error);
            });
    }, []);

    useEffect(() => {
        window.getCalendarNotifications = getCalendarNotifications;
    }, [getCalendarNotifications]);

    const className = useMemo(() => {
        return classNames('fm-calendar-notifications', {
            'fm-calendar-notifications__focused': focused,
        });
    }, [focused]);

    const toggleButton = useCallback(() => {
        setFocused(!focused);
    }, [focused]);

    const contentOnRef = useCallback((ref) => {
        popoverRef.current = ref;
    }, []);

    const contentPopover = useMemo(() => {
        return (
            <CalendarNotificationsPopover
                onRef={contentOnRef}
                notifications={notifications}
                activityTypes={activityTypes}
            />
        );
    }, [contentOnRef, notifications, activityTypes]);

    const closedNotifications = useMemo(() => {
        let closedNotifications =
            JSON.parse(window.sessionStorage.getItem(CLOSED_NOTIFICATIONS)) || {};
        return closedNotifications;
    }, []);

    const handleToastsAfterDeleteOrUpdate = useCallback((notifications) => {
        //Removes unnecessary toasts when those have been deleted or edited
        if (Object.keys(notificationsWithToast.current).length === 0) return;
        if (notifications.length === 0 && Object.keys(notificationsWithToast.current).length > 0) {
            Object.keys(notificationsWithToast.current).forEach((key) => {
                const toastInfo = notificationsWithToast.current[key];
                clearToast(toastInfo.toastId);
            });
            notificationsWithToast.current = {};
            return;
        }

        const mappedNotifications = notifications.reduce((obj, item) => {
            obj[item.id] = item;
            return obj;
        }, {});

        let toastsToRemove = [];

        Object.keys(notificationsWithToast.current).forEach((key) => {
            const toastInfo = notificationsWithToast.current[key];
            if (!mappedNotifications[toastInfo.notification.id]) {
                toastsToRemove.push({ id: toastInfo.notification.id, toastId: toastInfo.toastId });
            } else {
                if (
                    !isEqual(toastInfo.notification, mappedNotifications[toastInfo.notification.id])
                ) {
                    toastsToRemove.push({
                        id: toastInfo.notification.id,
                        toastId: toastInfo.toastId,
                    });
                }
            }
        });

        if (toastsToRemove.length > 0) {
            toastsToRemove.forEach((item) => {
                delete notificationsWithToast.current[item.id];
                clearToast(item.toastId);
            });
        }
    }, []);

    const handleOnClose = useCallback(
        (id) => {
            return new Promise((resolve, reject) => {
                closedNotifications[id] = true;
                window.sessionStorage.setItem(
                    CLOSED_NOTIFICATIONS,
                    JSON.stringify(closedNotifications),
                );
                delete notificationsWithToast.current[id];
                setActiveNotifications &&
                    setActiveNotifications((state) => ({ ...state, calendar: false }));
                resolve();
            });
        },
        [closedNotifications, setActiveNotifications],
    );

    const getEventIcon = useCallback((idMeeting, type) => {
        switch (type) {
            case 12:
                if (!idMeeting) return <Calendar />;
                return <VideoCam />;
            case 13:
                if (!idMeeting) return <Calendar />;
                return <VideoCheckIn />;
            default:
                return <Calendar />;
        }
    }, []);

    const showToastNotification = useCallback(
        ({ notification, startDate, minutesToReminder, isStarted }) => {
            if (
                notification &&
                notification?.reminder &&
                notification?.reminderMinutes > -1 &&
                !notificationsWithToast.current[notification.id] &&
                !closedNotifications?.[notification.id]
            ) {
                const toastId = calendarToast({
                    title: notification.subject,
                    icon: getEventIcon(notification.meetingId, notification.activityType),
                    content: (
                        <CalendarNotificationsToast
                            id={notification.id}
                            title={notification.subject}
                            subtitle={notification.companyName}
                            startDate={startDate}
                            isStarted={isStarted}
                            minutesToReminder={minutesToReminder}
                            type={notification.activityType}
                            idMeeting={notification.meetingId}
                            idProvider={notification.providerId}
                            handleOnClose={handleOnClose}
                            isTask={notification.isTask}
                            modalInit={modalInit}
                        />
                    ),
                    className: 'fm-calendar-notifications__toast',
                    onClose: () => handleOnClose(notification),
                    useDefaultCloseButton: true,
                    containerId: CALENDAR_NOTIFICATIONS_TOAST,
                });

                notificationsWithToast.current[notification.id] = {
                    toastId,
                    notification,
                };
                setActiveNotifications &&
                    setActiveNotifications((state) => ({ ...state, calendar: true }));
            }
        },
        [closedNotifications, handleOnClose, setActiveNotifications, modalInit, getEventIcon],
    );

    useEffect(() => {
        getCalendarNotifications();
        getList('tblTiposGestionGeneral')
            .then((list) => {
                const mappedList =
                    list?.reduce((obj, value) => {
                        obj[value.value] = value;
                        return obj;
                    }, {}) || {};
                setActivityTypes(mappedList);
            })
            .catch((error) => {
                console.error('error getting activity types:', error);
            });
    }, [getCalendarNotifications, getList]);

    useEffect(() => {
        handleToastsAfterDeleteOrUpdate(notifications);
        if (hideCalendarNotificationToast) return;
        if (notifications.length > 0) {
            const todayMoment = getTodayMoment();
            notifications.map((notification) => {
                const startDate = getMomentFromDateBackend(
                    notification.startDate,
                    'YYYY-MM-DDTHH:mm:ss[Z]',
                    true,
                );

                const reminderDate = moment(startDate).subtract(
                    notification.reminderMinutes,
                    'minutes',
                );

                const minutesToStart = getDifferenceBetweenDates(startDate, todayMoment, 'minutes');

                const minutesToReminder = getDifferenceBetweenDates(
                    reminderDate,
                    todayMoment,
                    'minutes',
                );

                const endDate = getMomentFromDateBackend(
                    notification.endDate,
                    'YYYY-MM-DDTHH:mm:ss[Z]',
                    true,
                );

                const isEventEnded = todayMoment.valueOf() > endDate.valueOf();

                if (isEventEnded) return;

                if (minutesToStart <= 0) {
                    //the event has started
                    showToastNotification({
                        notification,
                        startDate,
                        isStarted: true,
                    });
                } else if (minutesToReminder < 0 && minutesToStart > 0) {
                    //the reminder already passed but the event hasn't started yet
                    showToastNotification({
                        notification,
                        startDate,
                        minutesToReminder: minutesToStart,
                    });
                } else if (minutesToReminder <= 0 && minutesToReminder > -1) {
                    //it's exactly the reminder time
                    showToastNotification({
                        notification,
                        startDate,
                        minutesToReminder: minutesToStart,
                    });
                } else if (minutesToReminder < NEXT_TOAST_TIME_LAPSE_MINUTES) {
                    //X minutes to the reminder
                    const waitUntil = minutesToReminder * 60 * 1000;
                    setTimeout(() => {
                        showToastNotification({
                            notification,
                            startDate,
                            minutesToReminder: NEXT_TOAST_TIME_LAPSE_MINUTES,
                        });
                    }, waitUntil);
                }
            });
        }
    }, [
        notifications,
        showToastNotification,
        handleToastsAfterDeleteOrUpdate,
        hideCalendarNotificationToast,
        handleOnClose,
        modalInit,
    ]);

    useEffect(() => {
        if (!pollingInterval.current) {
            pollingInterval.current = setInterval(() => {
                getCalendarNotifications();
            }, NOTIFICATIONS_POLLING_TIME);
        }

        return () => {
            pollingInterval?.current && clearInterval(pollingInterval.current);
        };
    }, [getCalendarNotifications]);

    const calendarIcon = useMemo(() => {
        const icon =
            notifications?.length > 0 ? (
                <EventPending color={theme.colors.grey[500]} />
            ) : (
                <Calendar color={theme.colors.grey[500]} />
            );
        return icon;
    }, [notifications, theme]);

    if (hideCalendarNotificationToast) return null;

    return (
        <Fragment>
            <PopoverHoi
                className="fm-calendar-notifications__popover"
                placement="bottom"
                content={contentPopover}
            >
                <div className={className} onClick={toggleButton}>
                    {calendarIcon}
                </div>
            </PopoverHoi>
            <ToastContainer
                containerId={CALENDAR_NOTIFICATIONS_TOAST}
                autoClose={6000}
                overrides={{
                    root: { style: { position: 'fixed', zIndex: 2147483002 } },
                    ToastGroup: { style: { zIndex: 2147483002 } }, // Intercom z-index is 2147483001...
                }}
            />
        </Fragment>
    );
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(CalendarNotifications));
