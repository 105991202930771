import { memo } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import { Text, useTheme } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';

import SendingEmail from './sendingEmail.json';

const Sending = ({ text }) => {
    const theme = useTheme();
    return (
        <div className="fm-ee__editorWrapper">
            <div className="fm-ee__sending">
                <Text type="h6" color={theme.colors.actionMajor[500]}>
                    {text || getLiteral('label_sending_email')}
                </Text>
                <Player autoplay loop src={SendingEmail} />
            </div>
        </div>
    );
};

export default memo(Sending);
