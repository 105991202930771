import React from 'react';
import colors from 'constants/colors';

const Activities = ({ color = colors.actionMinor[500] }) => (
    <svg viewBox={`0 0 18 18`} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.1174106,13.0608659 L9.24721548,5.93080981 L12.0690897,8.75268403 L4.93903358,15.8827401 L0,17.9998995 L2.1174106,13.0608659 Z M15.1781258,0 L18,2.82187421 L13.1561846,7.66543841 L10.3345616,4.84356419 L13.1069425,2.07118332 C13.0328281,1.99581275 12.9551964,1.91190018 12.8732937,1.8229629 C12.5087514,1.42701616 12.0954694,0.978310024 11.6286743,0.969768026 C11.3369902,0.958713676 11.0061134,1.14161293 10.6551378,1.49610585 L6.01406917,6.13843062 L5.48119923,5.60556067 L10.1207604,0.964743321 C10.6116741,0.468051252 11.1148982,0.215811071 11.6153588,0.215811071 C11.6241521,0.215811071 11.6329453,0.216062306 11.6419898,0.216062306 C12.4316221,0.230382715 13.0074533,0.855707227 13.4277699,1.31245289 C13.5021355,1.39335064 13.5724814,1.46947492 13.6400636,1.53806214 L15.1781258,0 Z"
            fill={color || '#335B70'}
            fillRule="evenodd"
        />
    </svg>
);

export default Activities;
