import React, { memo, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Text, useTheme } from 'hoi-poi-ui';
import { FiltersProvider } from '@web/web5';
import { Route, Routes } from 'react-router-dom';
import { ORDERS_CONTENT } from 'constants/ActionTypes';

import { OrdersActions, EntityActions, EntityDetailActions, EntityCrudActions } from 'actions';
import { SALESORDERS } from 'constants/Entities';
import { ensureRoute, RouteOnMount } from 'utils/routes';
import { getLiteral } from 'utils/getLiteral';
import { formatLargeAmountCurrency, formatLargeNumber } from 'utils/amount';

import useEntityDetail from 'containers/components/EntityDetail/hooks/useEntityDetail';
import ContentLayout from 'containers/components/layouts/ContentLayout';
import DetailLayout from 'containers/components/layouts/ContentDetailLayout';
import FiltersLayout from 'containers/components/layouts/FiltersLayout';
import EntityTypeSidebar from 'containers/components/EntityTypeSidebar';
import FreeTrialWarning from 'containers/components/FreeTrialWarning';

import Header from './Header';
import Menu from './Menu';
import Content from './Content';
import CONFIG from './TableConfig';
import SalesOrdersDetail from './SalesOrdersDetail';
import SalesOrdersCrud from './SalesOrdersCrud';

import './styles.scss';

const propTypes = {
    login: PropTypes.bool.isRequired,
    section: PropTypes.number,
};

const Main = memo(
    ({
        login,
        section,
        changeSection,
        openDetail,
        isPageForbidden,
        permissions,
        openCrud,
        enableSalesOrdersTypes,
        getSalesRecordsStatistics,
    }) => {
        const theme = useTheme();
        const { hasTabsEnabled } = useEntityDetail();

        const onChangeTab = useCallback((event, value) => {
            if (value === ORDERS_CONTENT.CARDS) {
                ensureRoute('/sales-orders/cards');
            } else {
                ensureRoute('/sales-orders');
            }
        }, []);

        const renderSection = useMemo(() => {
            return section === ORDERS_CONTENT.LIST ? <Content /> : null;
        }, [section]);

        const sidebarProps = useMemo(() => {
            return {
                fieldName: 'salesOrderType',
                list: 'tblTiposSalesOrders',
                getInfo: (value) => {
                    return getSalesRecordsStatistics(value).then((data) =>
                        data
                            ? [
                                  {
                                      label: (
                                          <Text color={theme.colors.utility.textSecondary}>
                                              {getLiteral('label_salesrecords_footer_total_value')}
                                          </Text>
                                      ),
                                      value: formatLargeAmountCurrency(
                                          data.Result.TotalValue || 0,
                                          true,
                                      ),
                                  },
                                  {
                                      label: (
                                          <Text color={theme.colors.utility.textSecondary}>
                                              {getLiteral(
                                                  'label_salesrecords_footer_total_created',
                                              )}
                                          </Text>
                                      ),
                                      value: formatLargeNumber(data.Result.TotalCreated || 0, true),
                                  },
                              ]
                            : null,
                    );
                },
            };
        }, [getSalesRecordsStatistics, theme]);

        if (!login) return null;
        if (isPageForbidden(['salesOrders'])) return null;

        const config = CONFIG();

        return (
            <FiltersProvider>
                <ContentLayout header={<Header onChange={onChangeTab} section={section} />}>
                    <DetailLayout
                        header={<Menu config={config} />}
                        withUsageLimit={
                            <FreeTrialWarning entity={SALESORDERS} addonName="addon_salesorders" />
                        }
                    >
                        <FiltersLayout
                            config={config}
                            showSidebar={enableSalesOrdersTypes}
                            sidebar={<EntityTypeSidebar entity={config.entity} {...sidebarProps} />}
                        >
                            {renderSection}
                        </FiltersLayout>
                    </DetailLayout>
                    <SalesOrdersDetail />
                    <SalesOrdersCrud />
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <RouteOnMount
                                    onMount={() => {
                                        changeSection(ORDERS_CONTENT.LIST);
                                    }}
                                />
                            }
                        />
                        <Route
                            path="/cards"
                            element={
                                <RouteOnMount
                                    onMount={() => {
                                        changeSection(ORDERS_CONTENT.CARDS);
                                    }}
                                />
                            }
                        />
                        <Route
                            path="/new"
                            element={
                                <RouteOnMount
                                    onMount={() => {
                                        if (!permissions || !permissions.create) return;
                                        openCrud({ entity: SALESORDERS });
                                    }}
                                />
                            }
                        />
                        <Route
                            path="/:id/edit"
                            element={
                                <RouteOnMount
                                    onMount={(params) => {
                                        if (!params.id || !permissions || !permissions.update)
                                            return;
                                        openCrud({
                                            entity: SALESORDERS,
                                            id: params.id,
                                            isFromTab: hasTabsEnabled,
                                        });
                                    }}
                                />
                            }
                        />
                        <Route
                            path="/:id"
                            element={
                                <RouteOnMount
                                    onMount={(params) => {
                                        if (!params.id) return;
                                        openDetail(SALESORDERS, params.id, true);
                                    }}
                                />
                            }
                        />
                    </Routes>
                </ContentLayout>
            </FiltersProvider>
        );
    },
);

function mapStateToProps(state) {
    return {
        login: state.config.login,
        section: state.orders.section,
        permissions: state.config.permission.crud_permission[SALESORDERS.permission],
        enableSalesOrdersTypes: state.config.userData.enableSalesOrdersTypes,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeSection: bindActionCreators(OrdersActions, dispatch).changeSection,
        getSalesRecordsStatistics: bindActionCreators(OrdersActions, dispatch)
            .getSalesRecordsStatistics,
        openDetail: bindActionCreators(EntityDetailActions, dispatch).open,
        isPageForbidden: bindActionCreators(EntityActions, dispatch).isPageForbidden,
        openCrud: bindActionCreators(EntityCrudActions, dispatch).init,
    };
};

Main.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(Main);
