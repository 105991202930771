import React from 'react';
import colors from 'constants/colors';

//DO NOT RESIZE THIS ICON

const ArrowDown = ({ color = colors.actionMinor[500] }) => (
    <svg
        width="10"
        height="10"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <path
                d="M5 3.836L8.67.624a.5.5 0 1 1 .66.752L5.334 4.872a.499.499 0 0 1-.67 0L.671 1.376a.5.5 0 0 1 .658-.752L5 3.836z"
                id="arrowdown-a"
            />
        </defs>
        <g transform="translate(0 2)" fill="none" fillRule="evenodd">
            <mask id="arrowdown-b" fill="#fff">
                <use xlinkHref="#arrowdown-a" />
            </mask>
            <use fill={color || '#335B70'} xlinkHref="#arrowdown-a" />
            <g mask="url(#arrowdown-b)" fill={color || '#335B70'}>
                <path d="M0-2h10V8H0z" />
            </g>
        </g>
    </svg>
);

export default ArrowDown;
