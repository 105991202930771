import React, { memo, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { FuzzyActions } from 'actions';

import Select from './Select';

const propTypes = {
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    hint: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onChange: PropTypes.func,
    value: PropTypes.any,
    className: PropTypes.string,
    error: PropTypes.any,
    mandatory: PropTypes.bool,
    readOnly: PropTypes.bool,
    hidden: PropTypes.bool,
    list: PropTypes.string,
    description: PropTypes.string,
    loadOptions: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
    return {
        getFuzzy: bindActionCreators(FuzzyActions, dispatch).getFuzzy,
    };
};

const MultiSelectFuzzy = memo(
    ({
        shouldRenderField,
        hidden,
        options,
        list,
        getFuzzy,
        onFetchOptions,
        field,
        feature,
        parentField,
        parentFieldForBackend,
        parentValue,
        isPureDependency,
        ...props
    }) => {
        const fetchOptions = useCallback(
            (text) => {
                if (onFetchOptions) {
                    return onFetchOptions({
                        list,
                        field,
                        text,
                        feature,
                    });
                } else {
                    // there are two types of dependency here... the ones standard for backend,
                    // who expect for parentField and parentValue (and an extrafieldunit in
                    // whereInfo field of the final XML), and the new available dependency
                    // sending to backend an "IdParent" field inside the same whereInfo
                    // used to filter by the dependant field, which seems that is calculated
                    // by backend in time of processing the request
                    let finalParentField = parentFieldForBackend || parentField;
                    let finalParentValue = parentValue;
                    let finalIdParent;
                    if (isPureDependency) {
                        finalIdParent = parentValue?.value || parentValue;
                        finalParentField = null;
                        finalParentValue = null;
                    }

                    return getFuzzy({
                        list,
                        field,
                        text,
                        feature,
                        parentField: finalParentField,
                        parentValue: finalParentValue,
                        idParent: finalIdParent,
                    });
                }
            },
            [
                getFuzzy,
                onFetchOptions,
                list,
                field,
                feature,
                parentField,
                parentFieldForBackend,
                parentValue,
                isPureDependency,
            ],
        );

        if (hidden) return null;
        if (shouldRenderField && !shouldRenderField(options, list)) return null;

        return (
            <Select
                className="fm-field-multi-server-list"
                loadOptions={list ? fetchOptions : null}
                options={options}
                list={list}
                {...props}
                isMulti
                isFuzzy
            />
        );
    },
);

MultiSelectFuzzy.propTypes = propTypes;

export default connect(null, mapDispatchToProps)(MultiSelectFuzzy);
