import React, { memo, useMemo, useState, useCallback } from 'react';
import { Modal, Button, Text } from 'hoi-poi-ui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ConfigActions } from 'actions';

import Context from 'managers/Context';
import { getLiteral } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';
import './styles.scss';

const mapDispatchToProps = (dispatch) => {
    return {
        updateUserDataConfiguration: bindActionCreators(ConfigActions, dispatch)
            .updateUserDataConfiguration,
    };
};

const DeleteSampleData = memo(({ updateUserDataConfiguration }) => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const showModal = useCallback(() => {
        setOpen(true);
    }, []);

    const onCancel = useCallback(() => setOpen(false), []);

    const onDelete = useCallback(() => {
        setLoading(true);
        Context.domainManager
            .deleteWalkthroughData()
            .then(() => {
                successToast({
                    text: getLiteral('succes_delete_sample_data'),
                });

                updateUserDataConfiguration &&
                    updateUserDataConfiguration({ isDeletedWalkThroughData: true });

                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            })
            .catch((e) => {
                console.error(e);
                errorToast({
                    text: getLiteral('error_delete_sample_data'),
                });
            })
            .finally(() => {
                setLoading(false);
                setOpen(false);
            });
    }, [updateUserDataConfiguration]);

    const overridesModal = useMemo(
        () => ({
            confirmButton: {
                type: 'primary-error',
            },
        }),
        [],
    );

    return (
        <div className="delete-sample-data">
            <svg
                width="168"
                height="144"
                viewBox="0 0 168 144"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <mask
                    id="mask0_2510_26728"
                    style="mask-type:luminance"
                    maskUnits="userSpaceOnUse"
                    x="30"
                    y="8"
                    width="108"
                    height="128"
                >
                    <path d="M137.083 8.47058H30.918V135.529H137.083V8.47058Z" fill="white"></path>
                </mask>
                <g mask="url(#mask0_2510_26728)">
                    <path
                        d="M131.812 67.6083C131.668 67.6083 131.521 67.5606 131.4 67.46C131.124 67.2313 131.084 66.8222 131.312 66.5441C131.847 65.8952 131.343 64.9237 131.337 64.9143C131.17 64.5975 131.288 64.2054 131.603 64.0354C131.917 63.8654 132.308 63.9812 132.479 64.2949C132.518 64.366 133.409 66.0404 132.314 67.3705C132.186 67.5264 131.999 67.6069 131.812 67.6069V67.6083Z"
                        fill="#B26E3D"
                    ></path>
                    <path
                        d="M101.918 54.7652C102.281 55.6566 103.297 56.0843 104.187 55.7215C105.078 55.3584 105.504 54.3419 105.141 53.4505C104.925 52.9209 104.479 52.5546 103.965 52.4219C103.616 52.3307 103.235 52.3477 102.876 52.4945C102.219 52.7616 101.815 53.3842 101.792 54.0486C101.784 54.2863 101.824 54.5289 101.92 54.7638L101.918 54.7652Z"
                        fill="#E9B9A0"
                    ></path>
                    <path
                        d="M101.918 54.7652C102.281 55.6566 103.297 56.0843 104.187 55.7215C105.078 55.3584 105.504 54.3419 105.141 53.4505C104.925 52.9209 104.479 52.5546 103.965 52.4219C103.616 52.3307 103.235 52.3477 102.876 52.4945C102.219 52.7616 101.815 53.3842 101.792 54.0486C101.784 54.2863 101.824 54.5289 101.92 54.7638L101.918 54.7652Z"
                        fill="#B26E3D"
                    ></path>
                    <path
                        d="M101.791 54.0497C102.655 53.6651 103.387 53.103 103.964 52.4231C103.615 52.3321 103.234 52.3491 102.874 52.4959C102.218 52.763 101.814 53.3856 101.791 54.0497Z"
                        fill="#474747"
                    ></path>
                    <path
                        d="M113.106 74.243C113.06 74.243 113.014 74.243 112.967 74.2399C111.738 74.1891 110.636 73.4629 110.101 72.3538C110.092 72.3337 109.104 70.2901 107.803 68.0857C106.98 66.6906 106.315 65.7066 105.8 65.0163C105.054 65.6202 104.054 65.8984 103.042 65.6945C101.236 65.33 100.067 63.5689 100.432 61.7616C101.191 57.9908 103.409 57.1626 104.664 57.0051C107.978 56.5881 110.543 59.8368 112.816 63.4823C113.746 60.901 114.577 57.7313 114.622 54.5845C114.648 52.7401 116.164 51.2697 118.005 51.2942C119.848 51.3205 121.319 52.8359 121.293 54.6805C121.165 63.6954 116.192 72.2411 115.98 72.5994C115.378 73.6204 114.284 74.243 113.106 74.243Z"
                        fill="#ABABAB"
                    ></path>
                    <path
                        d="M126.351 135.529C124.026 135.529 122.071 133.722 121.916 131.368L120.997 117.243C120.473 109.201 118.309 101.503 114.568 94.3666L109.47 84.6423L106.849 92.7044C103.849 101.93 99.5827 110.688 94.1669 118.735L86.1994 130.574C84.8275 132.613 82.0629 133.154 80.0258 131.779C77.9883 130.406 77.4496 127.639 78.8218 125.6L86.789 113.761C91.742 106.4 95.6453 98.3876 98.3897 89.9486L102.81 76.3533C103.514 73.968 105.533 72.2843 107.993 72.0494C110.331 71.8255 113.502 72.9639 115.417 76.8243L122.446 90.2312C126.769 98.4788 129.27 107.372 129.875 116.665L130.794 130.79C130.953 133.243 129.096 135.363 126.646 135.522C126.547 135.528 126.45 135.531 126.352 135.531L126.351 135.529Z"
                        fill="#5E5E5E"
                    ></path>
                    <path
                        d="M118.197 53.9929C118.04 53.9929 117.879 53.9698 117.722 53.9218C116.864 53.6592 116.381 52.7509 116.643 51.892C116.793 51.4038 116.515 50.5141 116.34 50.1682C115.936 49.3663 116.259 48.3885 117.06 47.9839C117.861 47.579 118.838 47.902 119.242 48.7036C119.355 48.9275 120.33 50.9497 119.752 52.8435C119.537 53.5434 118.893 53.9944 118.199 53.9944L118.197 53.9929Z"
                        fill="#B26E3D"
                    ></path>
                    <path
                        d="M113.927 68.1055C116.833 75.2146 114.521 76.1661 108.798 76.1661C103.916 76.1661 103.871 76.0148 101.544 68.6956C99.0172 60.7525 103.251 60.2041 107.426 61.0523C111.105 61.8 111.511 62.1939 113.928 68.1055H113.927Z"
                        fill="#ABABAB"
                    ></path>
                    <path
                        d="M85.8203 135.52C85.669 135.52 85.5162 135.475 85.3804 135.384L80.5463 132.075C80.1913 131.833 80.1004 131.346 80.3427 130.991C80.5849 130.636 81.0711 130.544 81.4261 130.787L86.2602 134.096C86.6151 134.338 86.7063 134.825 86.4641 135.18C86.3127 135.401 86.0688 135.52 85.8203 135.52Z"
                        fill="#5E5E5E"
                    ></path>
                    <path
                        d="M132.357 135.52H126.43C125.999 135.52 125.65 135.171 125.65 134.74C125.65 134.309 125.999 133.96 126.43 133.96H132.357C132.787 133.96 133.136 134.309 133.136 134.74C133.136 135.171 132.787 135.52 132.357 135.52Z"
                        fill="#5E5E5E"
                    ></path>
                    <path
                        d="M136.31 69.0836C136.115 69.0836 135.92 69.011 135.771 68.8642L102.542 36.4709C102.236 36.1728 102.23 35.6846 102.528 35.3788C102.826 35.0727 103.314 35.0668 103.619 35.3647L136.848 67.7582C137.154 68.0564 137.16 68.5445 136.862 68.8503C136.711 69.0065 136.51 69.0836 136.31 69.0836Z"
                        fill="#00D639"
                    ></path>
                    <path
                        d="M130.544 70.3982C129.878 70.3982 129.251 69.984 129.015 69.3182C128.714 68.4734 129.154 67.5419 129.998 67.2407C132.078 66.4975 132.558 64.8881 132.563 64.8709C132.778 63.9998 133.656 63.4653 134.528 63.6799C135.398 63.8948 135.933 64.7737 135.718 65.6465C135.418 66.8682 134.107 69.2256 131.091 70.3022C130.91 70.3671 130.726 70.3982 130.544 70.3982Z"
                        fill="#B26E3D"
                    ></path>
                    <path
                        d="M107.622 77.3743C105.993 77.3743 104.436 76.6266 103.222 75.2581C100.787 72.5144 99.6529 67.1667 100.398 61.9482C100.659 60.1225 102.349 58.8542 104.172 59.1154C105.997 59.3763 107.264 61.0678 107.003 62.8938C106.449 66.7756 107.444 69.7663 108.077 70.6653C111.411 70.3471 121.902 67.7907 128.079 66.1592C129.86 65.6897 131.686 66.7525 132.155 68.5366C132.624 70.3194 131.562 72.1468 129.78 72.6164C126.838 73.3934 111.945 77.2738 107.747 77.3726C107.705 77.3726 107.664 77.3726 107.62 77.3726L107.622 77.3743Z"
                        fill="#ABABAB"
                    ></path>
                    <path
                        d="M117.303 52.0678C116.99 52.0678 116.714 51.8408 116.663 51.5212C116.584 51.033 116.13 50.5942 116.127 50.5897C115.865 50.3455 115.849 49.933 116.093 49.6704C116.337 49.4078 116.749 49.3925 117.011 49.6365C117.092 49.7108 117.797 50.3904 117.947 51.314C118.004 51.6694 117.763 52.0032 117.408 52.0602C117.373 52.0664 117.339 52.0678 117.305 52.0678H117.303Z"
                        fill="#B26E3D"
                    ></path>
                    <path
                        d="M87.8387 14.9561L81.0177 16.5548C79.9105 16.8144 79.223 17.923 79.4825 19.0311L81.0798 25.8581C81.339 26.9662 82.4469 27.6541 83.554 27.3946L90.3751 25.7959C91.4822 25.5364 92.1698 24.4277 91.9106 23.3196L90.313 16.4927C90.0538 15.3845 88.9461 14.6966 87.8387 14.9561Z"
                        fill="#474747"
                    ></path>
                    <path
                        d="M62.9065 43.2421L56.9175 42.2691C55.9448 42.1109 55.0283 42.7719 54.8704 43.7455L53.8983 49.7396C53.7405 50.7131 54.4009 51.6302 55.3736 51.7883L61.3626 52.7613C62.3353 52.9191 63.2518 52.2581 63.4097 51.2846L64.3818 45.2908C64.5396 44.3173 63.8792 43.3999 62.9065 43.2421Z"
                        fill="#ABABAB"
                    ></path>
                    <path
                        d="M52.8426 57.1197L48.0548 59.4023C47.2777 59.7727 46.9477 60.7036 47.3178 61.4815L49.5984 66.2736C49.9688 67.0515 50.8989 67.3818 51.6762 67.0111L56.4641 64.7286C57.2414 64.3581 57.5715 63.4269 57.201 62.649L54.9205 57.8572C54.5503 57.0793 53.6199 56.749 52.8426 57.1197Z"
                        fill="#474747"
                    ></path>
                    <path
                        d="M43.1138 71.7696L40.1182 75.1931C39.6319 75.7488 39.6878 76.5941 40.2431 77.0806L43.6638 80.079C44.2192 80.5657 45.0638 80.5098 45.55 79.9539L48.5457 76.5303C49.0319 75.9744 48.976 75.1293 48.4206 74.6425L44.9999 71.6445C44.4446 71.1577 43.6003 71.2136 43.1138 71.7696Z"
                        fill="#474747"
                    ></path>
                    <path
                        d="M78.0548 33.7412L72.3806 30.4625C71.4593 29.93 70.2811 30.2459 69.7491 31.1681L66.4733 36.8471C65.9413 37.7689 66.257 38.9481 67.1783 39.4806L72.8525 42.7593C73.7738 43.2915 74.9518 42.9755 75.4837 42.0537L78.7598 36.3747C79.2915 35.4525 78.9758 34.2734 78.0548 33.7412Z"
                        fill="#474747"
                    ></path>
                    <path
                        d="M90.38 42.7502C80.937 42.7502 73.2539 35.062 73.2539 25.6096C73.2539 16.1573 80.937 8.47058 90.38 8.47058C94.9547 8.47058 99.2547 10.2532 102.49 13.491C105.725 16.7288 107.506 21.0325 107.506 25.6111C107.506 35.062 99.8242 42.7516 90.38 42.7516V42.7502ZM90.38 10.0153C81.7875 10.0153 74.7972 17.0115 74.7972 25.6111C74.7972 34.2107 81.7875 41.2069 90.38 41.2069C98.9723 41.2069 105.963 34.2107 105.963 25.6111C105.963 21.445 104.342 17.529 101.399 14.5832C98.4553 11.6373 94.5425 10.0153 90.38 10.0153Z"
                        fill="#00D639"
                    ></path>
                    <path
                        d="M68.4018 49.7277C68.0655 49.7277 67.729 49.7153 67.3924 49.6907C64.1002 49.4527 60.9855 48.0579 58.6225 45.7623C55.974 43.1904 54.4722 39.7146 54.392 35.9763C54.3118 32.2241 55.6793 28.702 58.2427 26.059C58.5392 25.7532 59.0285 25.7455 59.334 26.042C59.6398 26.3386 59.6474 26.8283 59.351 27.1342C57.0775 29.4776 55.8645 32.6058 55.9354 35.9424C56.0065 39.2713 57.3415 42.3653 59.6969 44.6532C64.003 48.8363 70.8298 49.3508 75.5772 45.8504C75.92 45.5972 76.4031 45.6711 76.6561 46.0142C76.9094 46.357 76.8354 46.8406 76.4926 47.0939C74.1683 48.8069 71.3281 49.7263 68.4018 49.7263V49.7277Z"
                        fill="#00D639"
                    ></path>
                    <path
                        d="M56.1905 64.4293C54.905 64.4293 53.6191 64.1975 52.4061 63.7358C49.7498 62.7256 47.6445 60.7389 46.4807 58.1438C45.3171 55.5484 45.2304 52.6552 46.2415 49.9951C46.3929 49.5967 46.8387 49.3957 47.2371 49.547C47.6352 49.6986 47.836 50.145 47.6846 50.5434C46.8218 52.8175 46.8943 55.2906 47.8899 57.5088C48.8855 59.727 50.6852 61.4248 52.9556 62.2899C54.8956 63.0282 57.0472 63.0791 59.0135 62.4333C60.9538 61.7969 62.6392 60.5164 63.7627 58.8265C63.999 58.4711 64.4773 58.3739 64.8325 58.6103C65.1874 58.8466 65.2845 59.3255 65.0485 59.6807C63.735 61.6597 61.7625 63.1578 59.4952 63.901C58.4256 64.2517 57.3095 64.4262 56.1922 64.4262L56.1905 64.4293Z"
                        fill="#00D639"
                    ></path>
                    <path
                        d="M47.4232 79.268C43.4239 79.268 40.1719 76.012 40.1719 72.011C40.1719 70.9635 40.3262 70.0724 40.6458 69.2843C40.8062 68.889 41.2554 68.699 41.6504 68.8597C42.0457 69.0203 42.2355 69.4698 42.0748 69.8654C41.8326 70.4646 41.7137 71.1659 41.7137 72.011C41.7137 75.1607 44.2743 77.7236 47.4215 77.7236C49.056 77.7236 50.5686 77.0174 51.6799 75.7369C51.9592 75.4139 52.4471 75.38 52.7681 75.6596C53.0891 75.9394 53.1244 76.4273 52.8452 76.7486C51.436 78.3738 49.5097 79.268 47.4215 79.268H47.4232Z"
                        fill="#00D639"
                    ></path>
                    <path
                        d="M107.707 23.4871H99.9218C98.6578 23.4871 97.6328 24.5128 97.6328 25.778V33.5698C97.6328 34.835 98.6578 35.8605 99.9218 35.8605H107.707C108.971 35.8605 109.996 34.835 109.996 33.5698V25.778C109.996 24.5128 108.971 23.4871 107.707 23.4871Z"
                        fill="#474747"
                    ></path>
                    <path
                        d="M35.3308 86.5454C34.5977 86.5454 33.9387 86.4528 33.3753 86.2676C32.1066 85.8534 31.2886 84.9775 31.0107 83.7371C30.6604 82.1692 31.3225 80.4886 32.6591 79.554C36.7863 76.6684 39.6309 72.7476 41.1126 67.9019C42.0432 64.9067 42.903 61.7878 43.7365 58.7709C46.8387 47.5313 50.046 35.9085 57.7463 26.6493C63.0079 20.288 70.3039 14.6034 77.2648 11.4459C79.2421 10.5484 86.2478 7.70297 93.4216 8.74104C93.8431 8.80127 94.1362 9.19365 94.0747 9.61535C94.0142 10.0371 93.6223 10.3306 93.2011 10.2688C87.8299 9.49179 81.7762 11.0937 77.9023 12.8516C71.1451 15.9179 64.0543 21.4435 58.9333 27.6349C51.4445 36.6406 48.2818 48.0997 45.2228 59.1817C44.3879 62.2094 43.5236 65.3407 42.5865 68.3559C41.0014 73.5371 37.9593 77.7312 33.542 80.819C32.7132 81.3984 32.301 82.4349 32.5156 83.3972C32.6746 84.1064 33.099 84.5511 33.8538 84.7968C34.2628 84.9298 34.7582 84.9975 35.3293 84.9975C36.9792 84.9975 38.8684 84.4292 39.8485 84.0909C41.9938 83.3494 44.1408 82.2373 45.1903 81.321L45.2352 81.2826L45.2847 81.2516C51.0063 77.6261 54.9527 71.9816 59.1586 65.52L59.1773 65.4937C60.1418 64.1404 61.1049 62.7224 62.0372 61.3522C66.6319 54.5938 71.3842 47.6038 78.9657 43.8378L78.9857 43.8285C79.2511 43.7065 79.5137 43.5814 79.7777 43.4561C82.3536 42.2357 85.0176 40.9753 90.0972 41.204C90.5232 41.2224 90.8519 41.5841 90.8333 42.0104C90.8149 42.4368 90.4538 42.7657 90.0277 42.7471C85.3325 42.5339 82.9556 43.6602 80.4382 44.8526C80.1742 44.9777 79.9087 45.103 79.6433 45.2264C72.4292 48.8134 67.7958 55.6303 63.3151 62.2218C62.3814 63.5952 61.4152 65.0163 60.4445 66.3789C56.1521 72.9718 52.1128 78.7383 46.1597 82.5292C44.945 83.5688 42.676 84.7519 40.3534 85.5538C39.0459 86.0064 37.112 86.544 35.3308 86.544V86.5454Z"
                        fill="#00D639"
                    ></path>
                </g>
            </svg>
            <Text className="fm-t-5" type="h6">
                {getLiteral('label_delete_sample_data')}
            </Text>
            <Text className="delete-sample-data__description fm-t-2" type="body">
                {getLiteral('label_delete_sample_data_explanation_1')}
            </Text>
            <Text className="delete-sample-data__description" type="body">
                {getLiteral('label_delete_sample_data_explanation_2')}
            </Text>
            <div className="delete-sample-data__button fm-t-4">
                <Button onClick={showModal} type="primary-error" size="small">
                    {getLiteral('action_delete_all_sample_data')}
                </Button>
            </div>
            <Modal
                isOpen={open}
                onCancel={onCancel}
                onConfirm={onDelete}
                confirmText={getLiteral('action_delete')}
                cancelText={getLiteral('action_cancel')}
                isConfirmLoading={loading}
                isConfirmDisabled={loading}
                title={getLiteral('label_confirm_delete_sample_data')}
                size="small"
                overrides={overridesModal}
            >
                <Text className="fm-t-4 fm-b-4">
                    {getLiteral('label_delete_sample_data_explanation_2')}
                </Text>
            </Modal>
        </div>
    );
});

export default connect(null, mapDispatchToProps)(DeleteSampleData);
