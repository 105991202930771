import React, { memo, useMemo, useEffect, useRef, useState, useCallback } from 'react';
import { Text, Icon, Link, useTheme } from 'hoi-poi-ui';
import classnames from 'classnames';
import moment from 'moment';
import useEmailEditor from 'containers/components/EmailEditor/hooks/useEmailEditor';
import TextWithLinks from 'components/TextWithLinks';
import sanitizeHtml from 'sanitize-html';
import useBuildAnswer from '../../hooks/useBuildAnswer';
import { getLiteral } from 'utils/getLiteral';
import getTextWithLinks from 'utils/getTextWithLinks';
import { getMomentFromDateBackend, isToday } from 'utils/dates';

import './styles.scss';

const Comment = memo(
    ({
        interactionId,
        text,
        isUser,
        avatar,
        isError,
        actionOnError,
        reports,
        tables,
        getChatBoxRef,
        onClick,
        isWidget,
        isProgress,
        onCopy,
        creationDate,
        finished,
        vote,
        onVote,
    }) => {
        const { canUseEmail, mailContent } = useEmailEditor();
        const [isCopied, setIsCopied] = useState(false);
        const boxRef = useRef(null);
        const isHtmlInserted = useRef(false);
        const { processStaticContent } = useBuildAnswer();
        const theme = useTheme();

        // In this component the text is inserted directly in the DOM.
        // this insertion is made from outside this component with the method insertTextInDOM in useBuildAnswer hook.
        // When the text printing is not live, we do it in only one step from this useEffect and processStaticContent.

        useEffect(() => {
            if (text && boxRef.current && !isHtmlInserted.current) {
                const textBox = boxRef.current.querySelector('.dana-chat-comment-text__inner');
                const sanitizeRules = {
                    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['iframe']),
                    allowedAttributes: {
                        '*': [
                            'href',
                            'name',
                            'target',
                            'src',
                            'srcset',
                            'alt',
                            'title',
                            'width',
                            'height',
                            'loading',
                            'style',
                            'align',
                        ],
                    },
                };

                textBox.innerHTML = getTextWithLinks(text, sanitizeRules);

                processStaticContent({ textBox, isWidget, tables, reports });
                isHtmlInserted.current = true;
            }
        }, [text, processStaticContent, reports, tables, isWidget]);

        const commentProps = useMemo(() => {
            let obj = {};
            if (interactionId) {
                obj.id = interactionId;
            }
            return obj;
        }, [interactionId]);

        const handleOnCopy = useCallback(() => {
            onCopy && onCopy();
            setIsCopied(true);
            setTimeout(() => {
                setIsCopied(false);
            }, 3000);
        }, [onCopy]);

        const handleOnEmail = useCallback(() => {
            const textBox = boxRef.current.querySelector('.dana-chat-comment-text__inner');
            mailContent(textBox.innerHTML?.replace(/(?:\r\n|\r|\n)/g, '<br>'));
        }, [mailContent]);

        const date = useMemo(() => {
            if (!creationDate) return '';
            const dateMoment = getMomentFromDateBackend(creationDate, 'DD/MM/YYYY HH:mm:ss', true);
            if (isToday(dateMoment)) return dateMoment.format('HH:mm A');
            else if (dateMoment.isSame(moment(), 'year')) return dateMoment.format('DD/MM HH:mm A');
            else return dateMoment.format('DD/MM/YYYY HH:mm A');
        }, [creationDate]);

        const showThumbUp = useMemo(() => {
            if (isUser || !interactionId || !finished) return false;
            if (!vote || vote === '1') return true;
            return false;
        }, [isUser, interactionId, finished, vote]);

        const showThumbDown = useMemo(() => {
            if (isUser || !interactionId || !finished) return false;
            if (!vote || vote === '0') return true;
            return false;
        }, [isUser, interactionId, finished, vote]);

        return (
            <div
                className={classnames('dana-chat-comment', {
                    'dana-chat-comment--clickable': onClick || false,
                    'dana-chat-comment--copy': (!isCopied && !isWidget && onCopy) || false,
                    'dana-chat-comment--copied': isCopied || false,
                })}
                {...commentProps}
                onClick={onClick}
                ref={(ref) => {
                    boxRef.current = ref;
                    getChatBoxRef && getChatBoxRef({ ref, interactionId });
                }}
            >
                <div className="dana-chat-comment-data">
                    {avatar && <div className="dana-chat-comment-avatar">{avatar}</div>}
                    <div
                        className={classnames('dana-chat-comment-text-container', {
                            'dana-chat-comment-user': isUser,
                        })}
                    >
                        {!isWidget && (
                            <div className="dana-chat-comment-actions">
                                {date && (
                                    <Text
                                        className="dana-chat-comment-date"
                                        type="caption"
                                        color={theme.colors.utility.textSecondary}
                                    >
                                        {date}
                                    </Text>
                                )}
                                {!isProgress && (
                                    <>
                                        {onCopy && (
                                            <div
                                                className="dana-chat-comment-copy__icon"
                                                onClick={!isCopied ? handleOnCopy : undefined}
                                            >
                                                {!isCopied && <Icon name="contentCopy" />}
                                                {isCopied && <Icon name="done" />}
                                            </div>
                                        )}
                                        {canUseEmail && !isUser && (
                                            <Icon
                                                color={theme.colors.actionMinor[500]}
                                                name="email"
                                                onClick={handleOnEmail}
                                            />
                                        )}
                                    </>
                                )}
                                {showThumbUp && (
                                    <div
                                        className={classnames('dana-chat-comment-thumb__icon', {
                                            'dana-chat-comment__voted': vote && vote === '1',
                                        })}
                                        onClick={onVote('1')}
                                    >
                                        <Icon
                                            name="thumbUpOutline"
                                            color={theme.colors.actionMinor[500]}
                                        />
                                    </div>
                                )}
                                {showThumbDown && (
                                    <div
                                        className={classnames('dana-chat-comment-thumb__icon', {
                                            'dana-chat-comment__voted': vote && vote === '0',
                                        })}
                                        onClick={onVote('0')}
                                    >
                                        <Icon
                                            name="thumbDownOutline"
                                            color={theme.colors.actionMinor[500]}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                        <Text
                            className="dana-chat-comment-text"
                            type="body"
                            color={theme.colors.textLight.primary}
                        >
                            <TextWithLinks
                                classNames={[
                                    classnames('dana-chat-comment-text__inner', {
                                        'dana-chat-comment-text__ellipsis': isWidget,
                                    }),
                                ]}
                                text="..." // this text is replaced when we execute insertTextInDOM
                            />
                        </Text>
                    </div>
                </div>
                {isError && (
                    <div className="dana-chat-comment-error">
                        <Icon name="infoOutlined" />
                        <Text type="body" color={theme.colors.utility.textSecondary}>
                            {getLiteral('label_dana_summary_error_not_sent')}
                        </Text>
                        <Link variation="primary" onClick={actionOnError}>
                            {getLiteral('action_dana_chat_error_try_again')}
                        </Link>
                    </div>
                )}
            </div>
        );
    },
);

export default Comment;
