import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'hoi-poi-ui';
import classnames from 'classnames';

import colors from 'constants/colors';

import { getLiteral } from 'utils/getLiteral';

import './styles/size.scss';

const propTypes = {
    label: PropTypes.any,
    onChange: PropTypes.func,
    value: PropTypes.bool,
    className: PropTypes.string,
    error: PropTypes.any,
    mandatory: PropTypes.bool,
    readOnly: PropTypes.bool,
    hidden: PropTypes.bool,
    description: PropTypes.string,
    labelMode: PropTypes.string,
};

const SMALL_ICON = (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none" viewBox="0 0 48 48">
        <path
            fill={colors.utility.textSecondary}
            fillRule="evenodd"
            d="M16.93 14.5h14.14c1.614.006 2.924 1.316 2.93 2.93v14.14c-.006 1.614-1.316 2.924-2.93 2.93H16.93c-1.614-.006-2.924-1.316-2.93-2.93V17.43c.006-1.614 1.316-2.924 2.93-2.93zm14.08 2.99v14.02H16.99V17.49h14.02z"
            clipRule="evenodd"
        />
    </svg>
);

const VERTICAL_ICON = (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none" viewBox="0 0 48 48">
        <path
            fill={colors.utility.textSecondary}
            fillRule="evenodd"
            d="M34 9.43v30.14c-.006 1.614-1.316 2.924-2.93 2.93H16.93c-1.614-.006-2.924-1.316-2.93-2.93V9.43c.006-1.614 1.316-2.924 2.93-2.93h14.14c1.614.006 2.924 1.316 2.93 2.93zm-2.99 30.08H16.99V9.49h14.02v30.02z"
            clipRule="evenodd"
        />
    </svg>
);

const HORIZONTAL_ICON = (
    <svg xmlns="http://www.w3.org/2000/svg" width="49" height="48" fill="none" viewBox="0 0 49 48">
        <path
            fill={colors.utility.textSecondary}
            fillRule="evenodd"
            d="M9.43 14.5h30.14c1.614.006 2.924 1.316 2.93 2.93v14.14c-.006 1.614-1.316 2.924-2.93 2.93H9.43c-1.614-.006-2.924-1.316-2.93-2.93V17.43c.006-1.614 1.316-2.924 2.93-2.93zm30.08 2.99v14.02H9.49V17.49h30.02z"
            clipRule="evenodd"
        />
    </svg>
);

const BIG_ICON = (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none" viewBox="0 0 48 48">
        <path
            fill={colors.utility.textSecondary}
            fillRule="evenodd"
            d="M8.93 6.5h30.14c1.614.006 2.924 1.316 2.93 2.93v30.14c-.006 1.614-1.316 2.924-2.93 2.93H8.93c-1.614-.006-2.924-1.316-2.93-2.93V9.43c.006-1.614 1.316-2.924 2.93-2.93zm30.08 2.99v30.02H8.99V9.49h30.02z"
            clipRule="evenodd"
        />
    </svg>
);

const Size = ({
    fieldId,
    hidden,
    mandatory,
    readOnly,
    label,
    hint,
    description,
    value,
    shouldRenderField,
    onChange,
    error,
}) => {
    const BOXES = useMemo(
        () => [
            {
                label: getLiteral('label_small'),
                value: 'small',
                icon: SMALL_ICON,
            },
            {
                label: getLiteral('label_vertical'),
                value: 'vertical',
                icon: VERTICAL_ICON,
            },
            {
                label: getLiteral('label_horizontal'),
                value: 'horizontal',
                icon: HORIZONTAL_ICON,
            },
            {
                label: getLiteral('label_big'),
                value: 'big',
                icon: BIG_ICON,
            },
        ],
        [],
    );

    const SizeComponent = useMemo(() => {
        return (props) => {
            return BOXES.map((box) => {
                const className = classnames('fm-field-size__box', {
                    'fm-field-size__box--selected': box.value === props.value,
                });
                return (
                    <div
                        key={box.value}
                        className={className}
                        onClick={() => props.onChange({ target: { value: box.value } })}
                    >
                        <div className="fm-field-size__box-icon">{box.icon}</div>
                        <span className="fm-field-size__box-label">{box.label}</span>
                    </div>
                );
            });
        };
    }, [BOXES]);

    const classes = useMemo(
        () => ({
            inputComponents: 'fm-field-size__inputComponent',
        }),
        [],
    );

    if (hidden) return null;
    if (shouldRenderField && !shouldRenderField(fieldId)) return null;

    return (
        <Input
            className="fm-field-size"
            classes={classes}
            label={label}
            hint={description}
            placeholder={hint || getLiteral('placeholder_text_field')}
            isRequired={mandatory}
            isReadOnly={readOnly}
            error={error}
            isFullWidth
            onChange={onChange}
            value={value}
            component={SizeComponent}
            hideClear
        />
    );
};

export default memo(Size);
