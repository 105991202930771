import React from 'react';
import colors from 'constants/colors';

const Copy = ({ color = colors.actionMinor[500] }) => (
    <svg className="fm-copy-icon" viewBox={`0 0 18 18`} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3 2v9a1 1 0 0 1-2 0V1a1 1 0 0 1 1-1h10a1 1 0 0 1 0 2H3zm12 4H7v10h8V6zm2-1v12a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1z"
            fill={color || '#335B70'}
            fillRule="evenodd"
        />
    </svg>
);

export default Copy;
