import React, { memo } from 'react';

const Campaigns = memo((props) => (
    <svg width={168} height={144} fill="none" {...props}>
        <g mask="url(#a)">
            <path
                fill="#777"
                d="m110.819 85.83 1.269-.85a2.406 2.406 0 0 1 1.035-.4 3.173 3.173 0 0 1 3.133 1.549l.49.84c.39.668 1.393.53 1.587-.22l.253-.98a.866.866 0 0 1 1.35-.483l.181.133c.66.482 1.349-.077 1.215-.964-.052-.718.943-1.946 1.892-1.755l.657.223c.668.227 1.16-.633.626-1.094l-6.119-5.284a.642.642 0 0 0-.866.024l-8.304 8.032c-.14.136-.169.34-.124.558.158.769 1.073 1.109 1.725.671ZM70.603 67.689l1.023 8.648c.2 1.689 2.465 2.1 3.245.589l1.627-3.149a.903.903 0 0 1 1.499-.16c.795.962 2.356.512 2.516-.727l.41-1.21c.231-1.786 2.187-2.244 3.403-1.005.085.086.159.181.229.28l2.033 2.398c.924 1.637 2.602 1.217 2.287-.17-.073-.803.862-1.292 1.48-.774l.444.372c1.252 1.05 2.992-.508 2.085-1.868l-6.683-10.026a.864.864 0 0 0-.046-.061l-6.471-8.12a.903.903 0 0 0-1.507.144L70.7 67.164a.905.905 0 0 0-.096.525ZM24.124 90.368l1.75 4.344c.252.897 1.344.967 1.766.449l.232-.286c.41-.503 1.194-.567 1.414.045l.821 2.26c.268.744 1.569.748 1.823 0l.571-1.688a.835.835 0 0 1 1.452-.243c.477.616 1.898.185 1.93-.593l.97-5.96c.01-.257-1.223-1.146-1.42-1.313l-3.66-3.103a.835.835 0 0 0-1.076-.003l-5.703 4.78c-.257.215-.967.99-.87 1.31Z"
            />
            <path
                fill="#00D639"
                d="M50.408 112.377a1.525 1.525 0 0 1-1.278-.691l-17.638-26.99a.399.399 0 0 0-.311-.186.4.4 0 0 0-.337.134L21.01 95.46a1.528 1.528 0 0 1-2.26-2.055l9.834-10.817a3.444 3.444 0 0 1 2.84-1.124 3.443 3.443 0 0 1 2.626 1.56l16.148 24.712L76.5 52.477a3.006 3.006 0 0 1 2.402-1.706 3.008 3.008 0 0 1 2.715 1.146l25.051 32.097 8.557-9.25a2.946 2.946 0 0 1 3.955-.35l6.867 5.202 17.982-45.646a1.527 1.527 0 1 1 2.842 1.12l-18.72 47.52a1.526 1.526 0 0 1-2.344.657l-8.4-6.364-9.716 10.504a1.529 1.529 0 0 1-2.326-.098L79.237 53.834l-27.45 57.673a1.528 1.528 0 0 1-1.38.87Z"
            />
            <path
                fill="#00D639"
                d="M148.441 38.628c-.448 0-.879-.25-1.087-.68l-1.748-3.612-4.09 1.491a1.206 1.206 0 1 1-.827-2.267l5.115-1.865a1.206 1.206 0 0 1 1.499.608l2.223 4.593a1.207 1.207 0 0 1-1.085 1.732Z"
            />
            <path
                fill="#C6C6C6"
                d="M94.378 65.5h-.024l-1.928-.08a.567.567 0 0 1-.422-.216l-7.498-9.475a.568.568 0 1 1 .892-.705l7.335 9.271 1.668.07a.569.569 0 0 1-.023 1.136Z"
            />
            <path
                fill="#fff"
                d="M93.614 54.86a32.884 32.884 0 0 1 2.248-1.242c1.09-.522 2.117-.278 2.558.582.265.517.049 1.24-.53 1.4-1.138.31-2.246.621-3.323.929-.217.062-.485.027-.735-.098-.716-.344-.835-1.212-.218-1.571Z"
            />
            <path
                fill="#777"
                d="M99.511 58.812a.225.225 0 0 1-.165-.072l-7.579-8.244a.225.225 0 1 1 .332-.304l7.578 8.243a.225.225 0 0 1-.166.377Z"
            />
            <path
                fill="#E84D4F"
                d="M99.518 59.18a.326.326 0 0 1-.272-.507c.096-.144.06-.232.013-.282l.228-.234.221-.24c.177.162.422.605.082 1.117a.326.326 0 0 1-.272.146ZM99.54 54.914a.428.428 0 1 0 0-.856.428.428 0 0 0 0 .856Z"
            />
            <path
                fill="#303030"
                d="M96.827 62.043c-.043 0-.085-.003-.128-.008l-7.222-.933a.994.994 0 1 1 .255-1.972l5.288.683-2.04-3.743a.994.994 0 1 1 1.746-.952l2.974 5.455a.994.994 0 0 1-.873 1.47Z"
            />
            <path
                fill="#303030"
                d="M92.091 63.254a.995.995 0 0 1-.33-1.933l3.308-1.163-2.034-4a.994.994 0 1 1 1.772-.901l2.549 5.012a.994.994 0 0 1-.556 1.388l-4.379 1.54a.991.991 0 0 1-.33.057ZM89.539 61.322l-.449-.848a.164.164 0 1 0-.29.153l.448.848a.164.164 0 0 0 .29-.153Z"
            />
            <path
                fill="#303030"
                d="m92.19 63.446-.448-.847a.164.164 0 0 0-.29.153l.448.848a.164.164 0 1 0 .29-.154Z"
            />
            <path
                fill="#fff"
                d="M98.985 59.117a.85.85 0 0 1-.32-.063l-2.619-1.063a.852.852 0 0 1-.414-1.222l1.5-2.547a.852.852 0 0 1 1.468.864l-.998 1.697 1.704.692a.852.852 0 0 1-.321 1.642Z"
            />
        </g>
    </svg>
));

export default Campaigns;
