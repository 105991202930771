import React from 'react';
import colors from 'constants/colors';

const Contacts = ({ color = colors.actionMinor[500] }) => (
    <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
            <circle fill={color || '#335B70'} cx={16} cy={16} r="15.158" />
            <path
                d="M23.882 24.282A12.658 12.658 0 0 1 16.045 27a12.66 12.66 0 0 1-7.822-2.707c-.073-.057-.151-.109-.223-.167l.546-.256c.35-.15.687-.28 1.047-.405.68-.216 1.305-.383 1.913-.513l.574-.119c.236-.043.41-.07.495-.083v-.002c.044-.137.262-.867.341-1.701.073-.779-.016-1.285-.045-1.42-.093-.054-.566-.333-.742-.62l-.037-.06-.025-.066c-.179-.474-.297-2.24-.31-2.44l-.406-.198-.323-.156-.079-.35-.404-1.791-.019-.08v-.829l.186-.211.3-.34a13.447 13.447 0 0 1-.262-2.522c0-1.001.17-1.915.27-2.372l.047-.212.153-.154c.363-.363 1.307-1.255 2.252-1.684A6.453 6.453 0 0 1 15.86 5l.218.006.115-.006h.035c.011 0 1.195.006 2.38.542.942.427 1.889 1.32 2.252 1.684l.154.155.047.213c.082.371.27 1.345.27 2.37 0 1.204-.218 2.312-.262 2.522l.295.335.19.216-.004.289-.01.598-.001.08-.02.077-.385 1.66-.082.346-.32.156-.41.198c-.001.03-.126 1.934-.287 2.391l-.022.063-.031.058c-.185.324-.636.597-.773.673-.028.137-.117.639-.044 1.423.075.788.28 1.5.34 1.7.08.012.25.04.48.08l.588.124c.608.129 1.22.291 1.885.502.368.128.724.266 1.086.42l.565.237c-.073.06-.153.111-.227.17"
                fill="#FFF"
            />
        </g>
    </svg>
);

export default Contacts;
