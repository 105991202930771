import React, { memo, useMemo, useCallback, useRef, useState, useEffect } from 'react';
import { useTheme, Icon, Text, Loader } from 'hoi-poi-ui';
import MapCircle from 'components/Map/MapCircle';
import MapMarkerWithTooltip from 'components/Map/MapMarkerWithTooltip';
import Map from 'components/Map';
import {
    CHECKIN_ICON,
    COMPANY_CHECKIN_ICON,
    OPPORTUNITY_CHECKIN_ICON,
    FAST_CHECKIN_ICON,
    COMPANY_ICON,
    OPPORTUNITY_ICON,
} from 'constants/Images';
import { ACTIVITY_TYPE_OPPORTUNITY_CHECKIN } from 'models/ActivityModel';
import { getFMAddressFromCoordinates, getDistanceInMeters } from 'utils/map';
import { parseServerDecimal } from 'utils/fm';
import { getMomentFromDateBackend, getTimeOfMoment } from 'utils/dates';
import { getLiteral, getLiteralWithParametersHtml } from 'utils/getLiteral';

const CHECKIN_OUT_OF_RANGE_DISTANCE = 350; // in meters

function areCoordinatesValid(lat, lng) {
    return lat !== '0' && lng !== '0' && lat && lng;
}

const DetailMap = memo(({ activity }) => {
    const mapComponentRef = useRef(null);
    const [address, setAddress] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [markers, setMarkers] = useState([]);
    const oldActivityId = useRef(null);
    const [loadMap, setLoadMap] = useState();
    const [distance, setDistance] = useState(0);
    const [showWarning, setShowWarning] = useState(false);
    const theme = useTheme();

    const activityType = useMemo(() => parseInt(activity.ActivityType, 10), [activity]);

    const coordinates = useMemo(() => {
        if (!activity.Latitude || !activity.Longitude) return null;

        const lat = activity.Latitude.replace(',', '.');
        const lng = activity.Longitude.replace(',', '.');
        return {
            lat: parseFloat(lat),
            lng: parseFloat(lng),
        };
    }, [activity.Latitude, activity.Longitude]);

    const mapCenter = useMemo(() => {
        const defaultCoordinates = {
            lat: 41.383032,
            lng: 2.118372,
        };
        if (!coordinates) return defaultCoordinates;
        return coordinates;
    }, [coordinates]);

    const onLoadMap = useCallback(() => {
        setIsLoading(true);
        getFMAddressFromCoordinates(coordinates).then((result) => {
            if (result?.locationName || result?.googleLocationName) {
                const fullAddress = result.locationName || result.googleLocationName;
                setAddress(fullAddress);
            }

            let mapMarkers = [];

            let activityMarker = {
                key: activity.Id,
                coordinates,
                title: getTimeOfMoment(getMomentFromDateBackend(activity.ActivityDate, null, true)),
            };

            switch (activity.IDTipoCheckin) {
                case '1': //company checkin
                    activityMarker.icon = COMPANY_CHECKIN_ICON;
                    activityMarker.title = getLiteral('label_checkin');
                    break;
                case '2': // opportunity checkin
                    activityMarker.icon = OPPORTUNITY_CHECKIN_ICON;
                    activityMarker.title = getLiteral('label_checkin');
                    break;
                case '3':
                    activityMarker.icon = FAST_CHECKIN_ICON;
                    activityMarker.title = getLiteral('label_fast_checkin');
                    break;
                default:
                    activityMarker.icon = CHECKIN_ICON;
                    activityMarker.title = getLiteral('label_activity');
                    break;
            }

            mapMarkers.push(activityMarker);

            const distance =
                activity?.distanceCheckin && isNaN(activity?.distanceCheckin)
                    ? parseInt(activity?.distanceCheckin, 10)
                    : activity?.distanceCheckin;

            // Opportunity marker
            if (
                areCoordinatesValid(activity.OpportunityLatitude, activity.OpportunityLongitude) &&
                activityType === ACTIVITY_TYPE_OPPORTUNITY_CHECKIN
            ) {
                const lat = parseServerDecimal(activity.OpportunityLatitude);
                const lng = parseServerDecimal(activity.OpportunityLongitude);
                if (lat !== coordinates?.lat) {
                    setDistance(distance);

                    if (distance > CHECKIN_OUT_OF_RANGE_DISTANCE) {
                        mapMarkers[0].icon = CHECKIN_ICON;
                        setShowWarning(true);
                    }

                    mapMarkers.push({
                        key: activity.IdOpportunity,
                        title: activity.OpportunityName,
                        icon: OPPORTUNITY_ICON,
                        coordinates: {
                            lat: lat,
                            lng: lng,
                        },
                        hasCircle: true,
                    });
                }
            }

            // Company marker
            else if (areCoordinatesValid(activity.CompanyLatitude, activity.CompanyLongitude)) {
                const lat = parseServerDecimal(activity.CompanyLatitude);
                const lng = parseServerDecimal(activity.CompanyLongitude);
                if (lat !== coordinates?.lat) {
                    setDistance(distance);

                    if (distance > CHECKIN_OUT_OF_RANGE_DISTANCE) {
                        mapMarkers[0].icon = CHECKIN_ICON;
                        setShowWarning(true);
                    }

                    mapMarkers.push({
                        key: activity.IdCompany,
                        title: activity.CompanyName,
                        icon: COMPANY_ICON,
                        coordinates: {
                            lat,
                            lng,
                        },
                        hasCircle: true,
                    });
                }
            }

            setMarkers(mapMarkers);
            setIsLoading(false);
        });
    }, [
        activity.ActivityDate,
        activity.CompanyLatitude,
        activity.CompanyLongitude,
        activity.CompanyName,
        activity.IDTipoCheckin,
        activity.Id,
        activity.IdCompany,
        activity.IdOpportunity,
        activity.OpportunityLatitude,
        activity.OpportunityLongitude,
        activity.OpportunityName,
        activity?.distanceCheckin,
        activityType,
        coordinates,
    ]);

    useEffect(() => {
        if (activity && activity.Id !== oldActivityId.current) {
            // This way we force to reload the map with the right markers of each activity
            setLoadMap(onLoadMap);
            oldActivityId.current = activity.Id;
        }
    }, [activity, onLoadMap]);

    const circleProps = useMemo(() => {
        const markerWithCircle = markers.filter((marker) => marker.hasCircle)[0];
        return markerWithCircle
            ? {
                  radius: CHECKIN_OUT_OF_RANGE_DISTANCE,
                  center: markerWithCircle.coordinates,
                  options: {
                      strokeColor: theme.colors.grey200,
                      strokeOpacity: 0.2,
                      strokeWeight: 2,
                      fillColor: theme.colors.background.black005,
                      fillOpacity: 0.05,
                  },
              }
            : null;
    }, [markers, theme]);

    const getWarning = useMemo(() => {
        if (distance <= CHECKIN_OUT_OF_RANGE_DISTANCE) return null;
        const literal =
            activity.IDTipoCheckin === '1'
                ? 'label_distance_checkin_account'
                : 'label_distance_checkin_opportunity';

        const warningText = getLiteralWithParametersHtml(literal, [distance], (item) => (
            <strong>{`${item}m`}</strong>
        ));

        return showWarning ? (
            <div className="fm-activities__advice">
                <div className="fm-activities__advice__content">
                    <div className="fm-activities__advice__content__container">
                        <div className="fm-activities__advice__content__text">
                            <Icon
                                className="fm-activities__advice__content__icon"
                                name="infoOutlined"
                                color={theme.colors.semantic.focusCustom600}
                            />
                            <Text type="captionMedium">{warningText}</Text>
                        </div>
                        <Icon
                            name="closeRaw"
                            color={theme.colors.semantic.focusCustom600}
                            size="small"
                            className="fm-activities__advice__dismiss"
                            onClick={() => setShowWarning(false)}
                        />
                    </div>
                    <div>
                        <Text type="badges">
                            {`${getLiteral('label_distance_checkin_account_explanation')} `}
                            <a
                                className="fm-activities__advice__content__warning-link"
                                href={getLiteral('label_distance_checkin_account_explanation_url')}
                                target="_blank"
                            >
                                {getLiteral('label_distance_checkin_account_explanation_cta_url')}
                            </a>
                        </Text>
                    </div>
                </div>
            </div>
        ) : null;
    }, [activity, distance, theme, showWarning]);

    return (
        <div className="fm-activities__detail-map">
            <div className="fm-activities__detail-map__location">
                <Map
                    ref={mapComponentRef}
                    onLoadMap={loadMap}
                    center={mapCenter}
                    zoom={17}
                    disableDefaultUI={true}
                    options={{
                        zoomControl: true,
                        mapTypeControl: true,
                        streetViewControl: true,
                        scaleControl: false,
                        rotateControl: false,
                        fullscreenControl: true,
                    }}
                    setIsLoading={setIsLoading}
                    onUnmount={() => setShowWarning(false)}
                >
                    {markers.map((marker) => (
                        <MapMarkerWithTooltip
                            key={marker.key}
                            position={marker.coordinates}
                            title={marker.title}
                            icon={marker.icon}
                        />
                    ))}
                    {circleProps && <MapCircle {...circleProps} />}
                </Map>
                {getWarning}
            </div>
            <div className="fm-activities__detail-map__address">
                {isLoading && <Loader size="tiny" />}
                {!isLoading && address && <Text bold>{address}</Text>}
            </div>
        </div>
    );
});

export default DetailMap;
