import React, { memo, useMemo, useCallback, Fragment, useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation } from 'react-router-dom';

import { BadgeNotification, Text, Icon, Tabs, Tooltip, useTheme } from 'hoi-poi-ui';
import classnames from 'classnames';
import { EntityDetailActions, ActivitiesActions } from 'actions';
import { ACTIVITIES, CONTACTS, COMPANIES, OPPORTUNITIES } from 'constants/Entities';
import {
    ACTIVITY_TYPE_ANNOTATION,
    ACTIVITY_TYPE_CALL,
    ACTIVITY_TYPE_CHECKIN,
    ACTIVITY_TYPE_EMAIL,
    ACTIVITY_TYPE_OPPORTUNITY_CHECKIN,
    ACTIVITY_TYPE_FAST_CHECKIN,
    ACTIVITY_TYPE_WORKFLOW,
    ACTIVITY_TYPE_VIDEO_CALL,
    ACTIVITY_TYPE_VIDEO_CHECKIN,
    ACTIVITY_TYPE_MAILCHIMP,
    ACTIVITY_TYPE_ZENDESK,
    ACTIVITY_TYPE_WHATSAPP,
} from 'models/ActivityModel';
import { EMAIL_STATUS } from 'constants/Constants';
import DetailLoader from '../Loaders/DetailLoader';
import DetailExtraFields from './DetailExtraFields';
import DetailMap from './DetailMap';
import DetailDocument from './DetailDocument';
import DetailFiles from 'containers/components/EntityDetail/components/DetailFiles';
import DetailMessages from './DetailMessages';
import DetailWhatsapp from './DetailWhatsapp';
import DetailTotalViews from './DetailTotalViews';
import DetailTotalLinks from './DetailTotalLinks';

import DetailEmail from './DetailEmail';
import {
    DetailInfoContainer,
    CommentRow,
    InfoRow,
    InfoRowSection,
    TitleRow,
} from 'containers/components/EntityDetailV2';
import EmailSubjectRow from './components/EmailSubjectRow';
import {
    getLiteral,
    getLiteralWithParameters,
    getLiteralWithParametersHtml,
} from 'utils/getLiteral';
import { getActivityDateFormat, getElapsedTime, getMomentFromDateBackend } from 'utils/dates';
import { getBackendBoolean } from 'utils/fm';
import { capitalize } from 'utils/strings';
import { getDurationTextFromDates, getDurationText } from '../../utils/activities';
import { getTimezoneInfo } from '../../utils/timeZone';

import {
    getIsActivityTypeWithExtraFields,
    getIsActivityTypeWithMaps,
} from 'containers/Activities/utils/activities';
import TitleContentDetail from '../components/TitleContentDetail';
import SubtitleContent from '../components/SubtitleContent';
import SubtitleContentMultiple from '../components/SubtitleContentMultiple';
import TitleCommentModal from '../components/TitleCommentModal';
import OpportunityChip from 'containers/components/chips/OpportunityChip';
import CustomEmptyView from 'containers/components/CustomEmptyView';
import Detail from 'components/SvgIcons/emptyScreen/Detail';
import Conversations from 'components/SvgIcons/emptyScreen/Conversations';
import TimezonePopover from '../components/TimezonePopover';
import { logEvent } from 'utils/tracking';
import { isEqual } from 'utils/objects';
import { getEnableTimeLine } from '../../utils/timeline';
import { ActivitiesService } from 'services';
import { isEqual as isEqualArray } from 'utils/arrays';
import { errorToast } from 'utils/toast';
import { inConversations } from '../../utils/conversations';
import useActivitiesDetail from '../../hooks/useActivitiesDetail';
import useEntityDetail from 'containers/components/EntityDetail/hooks/useEntityDetail';

import './styles.scss';

const mapStateToProps = (state, ownProps) => {
    const entityList = state?.entityList;

    const tabsObjEntris = Object.entries(state.entityDetail.tabs);
    const primaryTab = tabsObjEntris.find(([key, value]) => value.primary)?.[0];

    const active = entityList?.active;
    const activityList = entityList?.[active] || null;
    const activities = activityList?.data || [];
    const entityDetail = state?.entityDetail || null;
    const activeDetailKey = tabsObjEntris.length > 1 ? primaryTab : entityDetail?.active;

    let activeDetail = entityDetail?.[activeDetailKey] || null;

    if (ownProps?.widgetActivity) {
        activeDetail = entityDetail?.[`${ACTIVITIES.entity}-${ownProps.widgetActivity.Id}`] || null;
    }
    const loading = activeDetail?.loading || false;
    let activity = activeDetail?.data && activeDetail?.data?.Id ? activeDetail.data : null;

    const userData = state.config.userData || null;
    const extraFields = state.activities?.extraFields?.data || [];
    let tab = activeDetail?.extra?.tab || '';
    const initialTabItem = parseInt(activeDetail?.extra?.tabItemId, 10) || -1;
    const tabsNames = state.activities?.tabsNames || [];
    const matchingName = state?.entityFilters?.activities?.filters?.matchingName?.value;

    if (ownProps?.widgetActivity) {
        activity = ownProps.widgetActivity;
        activity.TimeLineUnreadMessages = activeDetail
            ? activeDetail.data.TimeLineUnreadMessages || 0
            : activity.TimeLineUnreadMessages;
        activity.timeLineMessages = activeDetail
            ? activeDetail.data?.timeLineMessages || 0
            : activity.timeLineMessages;
    }

    const files = activity?.Id ? state.activities.files?.[activity.Id] : {};
    const filesList = files?.list || [];
    const isLoadingFilesList = files?.isLoading || false;

    const showActionsAndMenu =
        Object.entries(entityDetail?.tabs).length === 1 && !ownProps.widgetActivity;

    return {
        loading,
        activity,
        enableTimeLine: getEnableTimeLine(),
        allowEmailAttachments: userData?.allowEmailAttachments || false,
        extraFields,
        extraFieldsLoading: state.activities?.extraFields?.loading || false,
        activities,
        tab,
        initialTabItem,
        filesList,
        isLoadingFilesList,
        tabsNames,
        matchingName,
        showActionsAndMenu,
        canUploadImages: state.config.permission.upload_activity_image || false,
        canDeleteImages: state.config.permission.delete_activity_image || false,
        canCreateDocuments: state?.config?.permission?.crud_permission?.DOCUMENT?.create,
    };
};

const mapDispatchToProps = (dispatch) => {
    const { getActivityFiles, updateTabsNames, removeActivityFile } = bindActionCreators(
        ActivitiesActions,
        dispatch,
    );

    return {
        updateEntityDetail: bindActionCreators(EntityDetailActions, dispatch).updateDetailFields,
        getActivityFiles,
        removeActivityFile,
        updateTabsNames,
        updateDetailExtra: bindActionCreators(EntityDetailActions, dispatch).updateDetailExtra,
    };
};

const shouldRenderTabs = true;

const ActivitiesDetail = memo(
    ({
        widgetActivity,
        activity,
        loading,
        enableTimeLine,
        allowEmailAttachments,
        extraFields,
        extraFieldsLoading,
        activities,
        tab,
        initialTabItem,
        getActivityFiles,
        removeActivityFile,
        filesList,
        isLoadingFilesList,
        unreadMessages = {},
        updateEntityDetail,
        updateTabsNames,
        tabsNames,
        matchingName,
        detailWidth,
        onChangeTabForWidget,
        updateDetailExtra,
        fromWidgetInitialTab,
        showActionsAndMenu,
        canUploadImages,
        canDeleteImages,
        canCreateDocuments,
    }) => {
        const theme = useTheme();
        const [emailWithImages, setEmailWithImages] = useState('');
        const [loadingEmailWithImages, setLoadingEmailWithImages] = useState(false);
        const [isCommentCollapsed, setIsCommentCollapsed] = useState(true);
        const [scrollToMessage, setScrollToMessage] = useState(true);
        const [forceTotalMessages, setForceTotalMessages] = useState(null);
        const prevActivity = useRef(null);
        const domDetailInfo = useRef(null);
        const domDetailTabs = useRef(null);
        const filesListLengthRef = useRef(filesList?.length || 0);
        const unreadMessagesRef = useRef({});
        const previousLocation = useRef(null);
        const newMessagesRef = useRef({});
        const isFromWidgetDetail = !!widgetActivity;
        const prevTab = useRef(null);
        const location = useLocation();
        const shouldOverrideInitialTab = useRef(true);
        const { getHeaderItems } = useActivitiesDetail();
        const { hasTabs, openTab } = useEntityDetail();

        const setTab = useCallback(
            (tab) => {
                if (!activity) return;
                updateDetailExtra(ACTIVITIES, activity.Id, { tab });
            },
            [activity, updateDetailExtra],
        );

        useEffect(() => {
            // Override initial tab if coming from widget with fromWidgetInitialTab
            if (shouldOverrideInitialTab.current && isFromWidgetDetail && fromWidgetInitialTab) {
                setTab(fromWidgetInitialTab);
                shouldOverrideInitialTab.current = false;
            }
        }, [fromWidgetInitialTab, isFromWidgetDetail, setTab]);

        const newMessageUID = useMemo(() => {
            if (!activity) return;
            const { Id, ActivityType } = activity;
            return initialTabItem
                ? `${Id}${ActivityType}${initialTabItem}`
                : `${Id}${ActivityType}`;
        }, [activity, initialTabItem]);

        const handleNewMessage = useCallback(
            (value) => {
                if (!newMessageUID) return;
                newMessagesRef.current = { ...newMessagesRef.current, [newMessageUID]: value };
            },
            [newMessageUID],
        );

        const handleUnreadMessages = useCallback(() => {
            if (!activity) return;
            const { ActivityType, Id, TimeLineUnreadMessages } = activity;
            // Because BE: For the unread messages endpoint everything that is not a phone call or email
            // is an "activity"
            const type = [ACTIVITY_TYPE_CALL, ACTIVITY_TYPE_EMAIL].includes(ActivityType)
                ? ActivityType
                : ACTIVITY_TYPE_ANNOTATION;
            const updatedActivitiesObj = unreadMessages?.[type] || {};
            const updatedActivitiesIds = Object.keys(updatedActivitiesObj);
            if (
                updatedActivitiesIds.includes(Id) &&
                parseInt(TimeLineUnreadMessages, 10) !== updatedActivitiesObj[Id]
            ) {
                updateEntityDetail(ACTIVITIES, Id, {
                    TimeLineUnreadMessages: updatedActivitiesObj[Id],
                });
            }
        }, [activity, unreadMessages, updateEntityDetail]);

        useEffect(() => {
            if (location !== previousLocation.current) {
                previousLocation.current = location;
                setScrollToMessage(true);
            }
        }, [location]);

        useEffect(() => {
            if (isEqual(unreadMessages, unreadMessagesRef.current)) return;
            unreadMessagesRef.current = unreadMessages;
            if (Object.entries(unreadMessages).length > 0) {
                handleUnreadMessages();
            }
        }, [handleUnreadMessages, unreadMessages]);

        const finalWidth = useMemo(
            () => (isFromWidgetDetail ? '100%' : detailWidth),
            [detailWidth, isFromWidgetDetail],
        );

        const getEmailWithImages = useCallback(() => {
            setLoadingEmailWithImages(true);
            ActivitiesService.getEmailWithImages(activity.Id)
                .then((result) => {
                    if (result?.bodyFull) setEmailWithImages(result?.bodyFull);
                    setLoadingEmailWithImages(false);
                })
                .catch((error) => {
                    if (error?.code === 400 && error.error) {
                        logEvent({
                            event: ACTIVITIES.trueName,
                            submodule: 'email',
                            functionality: 'errorDownloadImages',
                        });
                        errorToast({
                            text: getLiteral(error.error),
                        });
                    }
                    setLoadingEmailWithImages(false);
                });
        }, [activity]);

        const isActivityTypeWithExtraFields = useMemo(() => {
            if (!activity) return false;
            return getIsActivityTypeWithExtraFields(activity.ActivityType);
        }, [activity]);

        const isActivityTypeWithMaps = useMemo(() => {
            if (!activity) return false;
            return getIsActivityTypeWithMaps(activity.ActivityType);
        }, [activity]);

        const hasCoordinates = useMemo(() => {
            return (
                activity?.Latitude &&
                activity?.Longitude &&
                activity?.Latitude !== '-1' &&
                activity?.Longitude !== '-1' &&
                activity?.Latitude !== '0' &&
                activity?.Longitude !== '0'
            );
        }, [activity]);

        const hasExtraFields = useMemo(() => {
            if (extraFieldsLoading) return;
            const listExtraFields = extraFields.filter((current) => {
                return current.list.length > 0;
            });
            return listExtraFields.length > 0;
        }, [extraFields, extraFieldsLoading]);

        const isCheckIn = useMemo(() => {
            if (!activity) return false;
            return (
                activity.ActivityType === ACTIVITY_TYPE_CHECKIN ||
                activity.ActivityType === ACTIVITY_TYPE_OPPORTUNITY_CHECKIN ||
                activity.ActivityType === ACTIVITY_TYPE_FAST_CHECKIN
            );
        }, [activity]);

        useEffect(() => {
            if (!isLoadingFilesList) {
                filesListLengthRef.current = filesList?.length || 0;
            }
        }, [filesList, isLoadingFilesList]);

        useEffect(() => {
            if (extraFieldsLoading || !activity) return;

            if (
                (activity && activity.Id !== prevActivity?.current?.Id) ||
                tab !== prevTab.current
            ) {
                prevActivity.current = activity;
                let newTab;

                getActivityFiles(activity?.Id);

                if (emailWithImages) {
                    setEmailWithImages('');
                }

                switch (true) {
                    case !!tab:
                        newTab = tab;
                        break;
                    case isCheckIn && hasCoordinates:
                        newTab = 'map';
                        break;
                    case activity?.ActivityType === ACTIVITY_TYPE_WHATSAPP &&
                        activity?.WhatsappMessage &&
                        activity?.WhatsappMessage !== '':
                        newTab = 'whatsapp';
                        break;
                    case isActivityTypeWithExtraFields && hasExtraFields:
                        newTab = 'extraInfo';
                        break;
                    case allowEmailAttachments && activity?.attachments?.length:
                        newTab = 'attachments';
                        break;
                    case activity?.ActivityType !== ACTIVITY_TYPE_EMAIL &&
                        activity?.ActivityType !== ACTIVITY_TYPE_CALL:
                        newTab = 'files';
                        break;
                    case activity?.ActivityType === ACTIVITY_TYPE_CALL:
                        newTab = 'timeline';
                        break;
                    case activity?.ActivityType === ACTIVITY_TYPE_EMAIL:
                        newTab = 'email';
                        break;
                    default:
                        break;
                }

                setTab(newTab);
                prevTab.current = newTab;
            }
        }, [
            activity,
            allowEmailAttachments,
            emailWithImages,
            enableTimeLine,
            extraFieldsLoading,
            getActivityFiles,
            hasCoordinates,
            hasExtraFields,
            isActivityTypeWithExtraFields,
            isCheckIn,
            tab,
            setTab,
        ]);

        const labels = useMemo(() => {
            return {
                contact: getLiteral('label_contacts'),
                account: getLiteral('label_activity_account_field'),
                role: getLiteral('label_cargoempresa'),
                opportunity: getLiteral('title_opportunity'),
                subject: getLiteral('label_activity_subject_field'),
                duration: getLiteral('label_duration'),
                attachment: getLiteral('label_attachments'),
                in: getLiteral('common_in'),
                from: getLiteral('label_from'),
                to: getLiteral('label_to'),
                with: getLiteral('common_with'),
                by: getLiteral('label_search_by'),
                emailStatus: getLiteral('label_email_status'),
            };
        }, []);

        const getDateText = useCallback((date) => {
            if (!date) return '';
            const dateMoment = getMomentFromDateBackend(date, null, true);
            return getActivityDateFormat(dateMoment, true);
        }, []);

        const getElapsedDateText = useCallback((date) => {
            if (!date) return '';
            const elapsedTime = getElapsedTime(getMomentFromDateBackend(date, null, true));
            return elapsedTime;
        }, []);

        const getIsCommentCollapsed = useCallback(() => {
            setIsCommentCollapsed(!isCommentCollapsed);
        }, [isCommentCollapsed]);

        const renderGeneralDetail = useMemo(() => {
            if (!activity) return null;
            const activityType = parseInt(activity?.ActivityType, 10);

            let durationText, labelFromTo, modalTitle, headerContent, mainContent;

            const timezoneInfo = getTimezoneInfo({
                activityDate: activity.ActivityDate,
                idActivityTimezone: activity.IdTimeZone,
            });

            const rightContent = (
                <Fragment>
                    <Tooltip content={getElapsedDateText(activity.ActivityDate)} placement="left">
                        <Text
                            className="title-row__date"
                            type="body"
                            color={theme.colors.utility.textSecondary}
                            isTruncated
                        >
                            {getDateText(activity.ActivityDate)}
                        </Text>
                    </Tooltip>
                    {timezoneInfo && (
                        <TimezonePopover timezoneInfo={timezoneInfo} placement="left" />
                    )}
                </Fragment>
            );

            const companyContent = activity.IdCompany && activity.CompanyName && (
                <SubtitleContent
                    id={activity.IdCompany}
                    value={activity.CompanyName}
                    entity={COMPANIES}
                />
            );
            let contactContent = null;

            if (activity.IDContact && activity.ContactName) {
                contactContent = (
                    <SubtitleContent
                        key={activity.IDContact}
                        id={activity.IDContact}
                        value={activity.ContactName}
                        entity={CONTACTS}
                    />
                );
            } else if (activity.UserName && activityType === ACTIVITY_TYPE_EMAIL) {
                contactContent = <Text key={activity.IDContact}>{activity.UserName}</Text>;
            }

            let multipleContactsContent = [];

            if (activity?.ContactIdList?.length && activity?.ContactList?.length) {
                let newContactIdList = activity.ContactIdList.split(';');
                newContactIdList.pop();
                let newContactList = activity.ContactList.split(';');
                newContactList.pop();

                multipleContactsContent = newContactIdList.reduce((arr, current, index) => {
                    if (!arr.length) {
                        arr.push(contactContent);
                    }
                    arr.push(
                        <SubtitleContent
                            key={current}
                            id={current}
                            value={newContactList[index]}
                            entity={CONTACTS}
                        />,
                    );
                    return arr;
                }, []);
            }

            const finalContactContent = multipleContactsContent?.length
                ? multipleContactsContent
                : contactContent;

            let emailCompanyContent = null;

            if (activity?.Accounts?.length) {
                if (activity.Accounts.length > 1) {
                    emailCompanyContent = (
                        <SubtitleContentMultiple accounts={activity.Accounts} entity={COMPANIES} />
                    );
                } else {
                    const finalAccount = activity.Accounts[0];
                    let accountName = `${finalAccount.Name}`;
                    if (finalAccount.Surname)
                        accountName = `${accountName} ${finalAccount.Surname}`;
                    emailCompanyContent = (
                        <SubtitleContent
                            id={finalAccount.Id}
                            value={accountName}
                            entity={COMPANIES}
                        />
                    );
                }
            }

            let emailContactContent = null;
            let emailRole = '';

            if (activity?.Contacts?.length) {
                if (activity.Contacts.length > 1) {
                    emailContactContent = (
                        <SubtitleContentMultiple
                            recipients={activity.Recipients}
                            contacts={activity.Contacts}
                            users={activity.Users}
                            entity={CONTACTS}
                        />
                    );
                } else {
                    const finalContact = activity.Contacts[0];
                    emailRole = finalContact.Role;
                    let contactName = `${finalContact.Name}`;
                    if (finalContact.Surname)
                        contactName = `${contactName} ${finalContact.Surname}`;
                    emailContactContent = (
                        <SubtitleContent
                            id={finalContact.Id}
                            value={contactName}
                            entity={CONTACTS}
                        />
                    );
                }
            } else if (activity.UserName && activityType === ACTIVITY_TYPE_EMAIL) {
                emailContactContent = <Text>{activity.UserName}</Text>;
            }

            let leftContent = <TitleContentDetail label={activity.Type} />;

            const hasActivityOwner = activity.IdActivityOwner && activity.IdActivityOwner !== '-1';

            // Opportunity Row
            const idOpportunity = activity.IDExpediente || activity.IdOpportunity;
            const nameOpportunity = activity.Expediente || activity.OpportunityName;

            const opportunityRow = idOpportunity &&
                idOpportunity !== '-1' &&
                idOpportunity !== '0' && (
                    <InfoRow
                        label={labels.opportunity}
                        content={
                            <OpportunityChip
                                idOpportunity={idOpportunity}
                                nameOpportunity={nameOpportunity}
                                remotePopover={true}
                                onClickPopover={
                                    hasTabs
                                        ? () =>
                                              openTab({
                                                  entity: OPPORTUNITIES,
                                                  id: idOpportunity,
                                                  hasCrudInDetail: true,
                                              })
                                        : null
                                }
                            />
                        }
                    />
                );

            let emailOpportunityRow = null;

            if (activity?.Opportunity) {
                emailOpportunityRow = (
                    <InfoRow
                        label={labels.opportunity}
                        content={
                            <OpportunityChip
                                idOpportunity={activity.Opportunity.Id}
                                nameOpportunity={activity.Opportunity.Name}
                                remotePopover={true}
                                onClickPopover={
                                    hasTabs
                                        ? () =>
                                              openTab({
                                                  entity: OPPORTUNITIES,
                                                  id: activity.Opportunity.Id,
                                                  hasCrudInDetail: true,
                                              })
                                        : null
                                }
                            />
                        }
                    />
                );
            }

            const { leftContent: newLeftContent, actionsAndMenu } = getHeaderItems({
                activity,
                isFromWidgetDetail,
            });

            leftContent = newLeftContent || leftContent;

            const titleRow = (
                <TitleRow
                    leftContent={leftContent}
                    rightContent={showActionsAndMenu && actionsAndMenu}
                />
            );

            switch (parseInt(activity.ActivityType, 10)) {
                case ACTIVITY_TYPE_ANNOTATION:
                    headerContent = (
                        <Fragment>
                            {titleRow}
                            <InfoRowSection>
                                <InfoRow
                                    label={labels.account}
                                    value={companyContent}
                                    rightContent={rightContent}
                                />
                                <InfoRow label={labels.contact} value={finalContactContent} />
                                <InfoRow label={labels.role} value={activity.ContactRole} />
                                {opportunityRow}
                            </InfoRowSection>
                        </Fragment>
                    );

                    mainContent = (
                        <Fragment>
                            <InfoRowSection>
                                <InfoRow label={labels.subject} value={activity.Subject} />
                            </InfoRowSection>
                            <CommentRow
                                text={activity.Description}
                                getIsCommentCollapsed={getIsCommentCollapsed}
                            />
                        </Fragment>
                    );

                    return (
                        <DetailInfoContainer
                            avatarId={hasActivityOwner && activity.IdActivityOwner}
                            avatarName={activity.ActivityOwner}
                            content={{
                                right: headerContent,
                                main: mainContent,
                            }}
                            noTabs={!shouldRenderTabs}
                        />
                    );
                case ACTIVITY_TYPE_CHECKIN:
                case ACTIVITY_TYPE_OPPORTUNITY_CHECKIN:
                case ACTIVITY_TYPE_FAST_CHECKIN:
                    const durationInSeconds = parseInt(activity.CheckinMinutes)
                        ? parseInt(activity.CheckinMinutes) * 60
                        : 0;
                    durationText = getDurationText(durationInSeconds);

                    headerContent = (
                        <Fragment>
                            {titleRow}
                            <InfoRowSection>
                                <InfoRow
                                    label={labels.account}
                                    value={companyContent}
                                    rightContent={rightContent}
                                />
                                <InfoRow label={labels.contact} value={finalContactContent} />
                                <InfoRow label={labels.role} value={activity.ContactRole} />
                                {opportunityRow}
                            </InfoRowSection>
                        </Fragment>
                    );

                    mainContent = (
                        <Fragment>
                            <InfoRowSection>
                                <InfoRow label={labels.duration} value={durationText} />
                            </InfoRowSection>
                            <CommentRow
                                text={activity.Description}
                                getIsCommentCollapsed={getIsCommentCollapsed}
                            />
                        </Fragment>
                    );

                    return (
                        <DetailInfoContainer
                            avatarId={hasActivityOwner && activity.IdActivityOwner}
                            avatarName={activity.ActivityOwner}
                            content={{
                                right: headerContent,
                                main: mainContent,
                            }}
                            noTabs={!shouldRenderTabs}
                        />
                    );
                case ACTIVITY_TYPE_CALL:
                    let isReceived = false;

                    if (activity.IsReceived === '0' || activity.IsReceived === 0) isReceived = true;
                    else if (activity.IsReceived === '1' || activity.IsReceived === 1)
                        isReceived = false;

                    labelFromTo = isReceived ? labels.from : labels.to;
                    durationText = getDurationText(activity.Duration);

                    let phoneContactContent = null;

                    if (activity.IDContact && activity.ContactName) {
                        phoneContactContent = (
                            <SubtitleContent
                                id={activity.IDContact}
                                value={activity.ContactName}
                                entity={CONTACTS}
                            />
                        );
                    } else if (activity.PhoneNumber) {
                        phoneContactContent = (
                            <SubtitleContent
                                id={null}
                                value={activity.PhoneNumber}
                                entity={CONTACTS}
                            />
                        );
                    }

                    headerContent = (
                        <Fragment>
                            {titleRow}
                            <InfoRowSection>
                                <InfoRow
                                    label={capitalize(labelFromTo)}
                                    content={phoneContactContent}
                                    rightContent={rightContent}
                                />
                                <InfoRow label={labels.account} content={companyContent} />
                                <InfoRow label={labels.role} value={activity.ContactRole} />
                                {opportunityRow}
                            </InfoRowSection>
                        </Fragment>
                    );

                    mainContent = (
                        <Fragment>
                            <InfoRowSection>
                                <InfoRow label={labels.duration} value={durationText} />
                            </InfoRowSection>
                        </Fragment>
                    );

                    return (
                        <DetailInfoContainer
                            avatarId={hasActivityOwner && activity.IdActivityOwner}
                            avatarName={activity.ActivityOwner}
                            content={{
                                right: headerContent,
                                main: mainContent,
                            }}
                            noTabs={!shouldRenderTabs}
                        />
                    );
                case ACTIVITY_TYPE_EMAIL:
                    const emailStatus = activity?.Tracking?.EmailStatus;
                    const isSent = getBackendBoolean(activity.IsSent);
                    const iconName = isSent ? 'emailSend' : 'emailReceive';
                    let labelEmail = isSent
                        ? getLiteral('label_emailsent')
                        : getLiteral('label_emailreceived');

                    labelFromTo = isSent ? labels.to : labels.from;

                    modalTitle = (
                        <TitleCommentModal
                            activity={activity}
                            label={labelEmail}
                            iconName={iconName}
                            items={[
                                { entity: CONTACTS, label: labelFromTo },
                                { entity: COMPANIES, label: labelFromTo },
                            ]}
                        />
                    );

                    let trackingStatusColor = '';
                    let trackingStatusLabel = '';

                    switch (emailStatus) {
                        case EMAIL_STATUS.PENDING:
                            trackingStatusColor = theme.colors.semantic.caution500;
                            trackingStatusLabel = getLiteral('label_email_status_pending');
                            break;
                        case EMAIL_STATUS.ERROR:
                            trackingStatusColor = theme.colors.semantic.negative500;
                            trackingStatusLabel = getLiteral('label_email_status_error');
                            break;
                        case EMAIL_STATUS.SENT:
                            trackingStatusColor = theme.colors.semantic.positive500;
                            trackingStatusLabel = getLiteral('label_email_status_success');
                            break;
                    }

                    headerContent = (
                        <Fragment>
                            {titleRow}
                            <InfoRowSection>
                                <InfoRow
                                    label={capitalize(labelFromTo)}
                                    value={emailContactContent}
                                    rightContent={rightContent}
                                />
                                <InfoRow label={labels.account} value={emailCompanyContent} />
                                <InfoRow label={labels.role} value={emailRole} />
                                {trackingStatusLabel && (
                                    <InfoRow
                                        label={labels.emailStatus}
                                        value={
                                            <Text color={trackingStatusColor}>
                                                {trackingStatusLabel}
                                            </Text>
                                        }
                                    />
                                )}
                                {emailOpportunityRow}
                            </InfoRowSection>
                        </Fragment>
                    );

                    mainContent = (
                        <Fragment>
                            <InfoRowSection>
                                <EmailSubjectRow
                                    id={activity.Id}
                                    label={labels.subject}
                                    value={activity.Subject}
                                    text={emailWithImages || activity.Body}
                                    modalTitle={modalTitle}
                                    entity={ACTIVITIES}
                                    attachments={activity.attachments}
                                    getEmailWithImages={getEmailWithImages}
                                    loadingEmailWithImages={loadingEmailWithImages}
                                    emailWithImages={emailWithImages}
                                />
                            </InfoRowSection>
                        </Fragment>
                    );

                    return (
                        <DetailInfoContainer
                            avatarId={hasActivityOwner && activity.IdActivityOwner}
                            avatarName={activity.ActivityOwner}
                            content={{
                                right: headerContent,
                                main: mainContent,
                            }}
                            noTabs={!shouldRenderTabs}
                        />
                    );
                case ACTIVITY_TYPE_WORKFLOW:
                    return (
                        <DetailInfoContainer
                            icon={<Icon name="workflow" noTabs={!shouldRenderTabs} />}
                        >
                            workflow{/* {this.renderWorkFlowInformation()} */}
                        </DetailInfoContainer>
                    );
                case ACTIVITY_TYPE_VIDEO_CALL:
                case ACTIVITY_TYPE_VIDEO_CHECKIN:
                    durationText = getDurationTextFromDates(
                        activity.videoCallTimeStart,
                        activity.videoCallTimeEnd,
                    );
                    let videoCallParticipants;
                    try {
                        videoCallParticipants = JSON.parse(activity.videoCallParticipants);
                    } catch (e) {}

                    if (videoCallParticipants)
                        videoCallParticipants = videoCallParticipants
                            .filter((participant) => participant?.name)
                            .map((participant) => participant?.name)
                            .join(', ');

                    let firstInfoRowLabel = labels.contact;

                    if (!finalContactContent && companyContent) {
                        firstInfoRowLabel = labels.account;
                    }

                    headerContent = (
                        <Fragment>
                            {titleRow}
                            <InfoRowSection>
                                <InfoRow
                                    label={labels.account}
                                    content={companyContent}
                                    rightContent={rightContent}
                                />
                                <InfoRow label={firstInfoRowLabel} value={finalContactContent} />
                                {opportunityRow}
                                <InfoRow label={labels.subject} value={activity.Subject} />
                                <InfoRow
                                    label={getLiteral('label_videocall_participants')}
                                    value={videoCallParticipants}
                                />
                            </InfoRowSection>
                        </Fragment>
                    );

                    mainContent = (
                        <Fragment>
                            <InfoRowSection>
                                <InfoRow label={labels.duration} value={durationText} />
                            </InfoRowSection>
                            {activity.Description && (
                                <CommentRow
                                    text={activity.Description}
                                    getIsCommentCollapsed={getIsCommentCollapsed}
                                />
                            )}
                        </Fragment>
                    );

                    return (
                        <DetailInfoContainer
                            avatarId={hasActivityOwner && activity.IdActivityOwner}
                            avatarName={activity.ActivityOwner}
                            content={{
                                right: headerContent,
                                main: mainContent,
                            }}
                        />
                    );
                case ACTIVITY_TYPE_MAILCHIMP:
                case ACTIVITY_TYPE_ZENDESK:
                    headerContent = (
                        <Fragment>
                            {titleRow}
                            <InfoRowSection>
                                <InfoRow
                                    label={labels.contact}
                                    value={finalContactContent}
                                    rightContent={rightContent}
                                />
                            </InfoRowSection>
                        </Fragment>
                    );

                    return (
                        <DetailInfoContainer
                            avatarId={hasActivityOwner && activity.IdActivityOwner}
                            avatarName={activity.ActivityOwner}
                            content={{
                                right: headerContent,
                            }}
                            noTabs={!shouldRenderTabs}
                        />
                    );
                case ACTIVITY_TYPE_WHATSAPP:
                    modalTitle = (
                        <TitleCommentModal
                            activity={activity}
                            label={activity.Type}
                            iconName="whatsapp"
                            items={[
                                { entity: COMPANIES, label: labels.in },
                                { entity: CONTACTS, label: labels.with },
                            ]}
                        />
                    );

                    headerContent = (
                        <Fragment>
                            {titleRow}
                            <InfoRowSection>
                                <InfoRow
                                    label={labels.account}
                                    value={companyContent}
                                    rightContent={rightContent}
                                />
                                <InfoRow label={labels.contact} value={finalContactContent} />
                                <InfoRow label={labels.role} value={activity.ContactRole} />
                                {opportunityRow}
                            </InfoRowSection>
                        </Fragment>
                    );

                    mainContent = activity.Description && (
                        <Fragment>
                            <InfoRowSection />
                            <CommentRow
                                text={activity.Description}
                                noTabs={!shouldRenderTabs}
                                getIsCommentCollapsed={getIsCommentCollapsed}
                            />
                        </Fragment>
                    );

                    return (
                        <DetailInfoContainer
                            avatarId={hasActivityOwner && activity.IdActivityOwner}
                            avatarName={activity.ActivityOwner}
                            content={{
                                right: headerContent,
                                main: mainContent,
                            }}
                            noTabs={!shouldRenderTabs}
                        />
                    );
                default:
                    return null;
            }
        }, [
            activity,
            getElapsedDateText,
            getDateText,
            labels.opportunity,
            labels.account,
            labels.contact,
            labels.role,
            labels.subject,
            labels.duration,
            labels.from,
            labels.to,
            labels.emailStatus,
            labels.in,
            labels.with,
            hasTabs,
            getHeaderItems,
            isFromWidgetDetail,
            showActionsAndMenu,
            openTab,
            getIsCommentCollapsed,
            emailWithImages,
            getEmailWithImages,
            loadingEmailWithImages,
            theme,
        ]);

        const tabs = useMemo(() => {
            let finalTabs = [];

            if (!activity?.ActivityType) return finalTabs;

            if (activity && isActivityTypeWithMaps && hasCoordinates) {
                finalTabs.push({ key: 'map', title: getLiteral('label_map') });
            }

            if (activity && isActivityTypeWithExtraFields && hasExtraFields) {
                finalTabs.push({ key: 'extraInfo', title: getLiteral('label_extra_info') });
            }

            if (
                allowEmailAttachments &&
                activity &&
                activity?.attachments?.length &&
                activity.ActivityType !== ACTIVITY_TYPE_EMAIL
            ) {
                finalTabs.push({ key: 'attachments', title: getLiteral('title_documents') });
            }

            if (
                activity &&
                activity.ActivityType !== ACTIVITY_TYPE_EMAIL &&
                activity.ActivityType !== ACTIVITY_TYPE_CALL
            ) {
                finalTabs.push({
                    key: 'files',
                    title: (
                        <div id="activities-detail-images-tab-title">
                            {isLoadingFilesList
                                ? getLiteralWithParameters('label_files_with_total', [
                                      filesListLengthRef.current,
                                  ])
                                : getLiteralWithParameters('label_files_with_total', [
                                      filesList?.length,
                                  ])}
                        </div>
                    ),
                });
            }

            if (activity && activity.ActivityType === ACTIVITY_TYPE_EMAIL) {
                finalTabs.push({
                    key: 'email',
                    title: getLiteral('label_Email'),
                });
            }

            if (activity?.WhatsappMessage && activity.ActivityType === ACTIVITY_TYPE_WHATSAPP) {
                finalTabs.push({
                    key: 'whatsapp',
                    title: getLiteral('label_whatsapp_message'),
                });
            }

            if (activity && enableTimeLine) {
                const totalMessages = parseInt(activity.timeLineMessages, 10);
                const unreadMessages = parseInt(activity.TimeLineUnreadMessages, 10);

                finalTabs.push({
                    key: 'timeline',
                    title: (
                        <div id="activities-detail-timeline-tab-title">
                            {totalMessages === 0
                                ? getLiteral('label_timeline_tab_title_no_comments')
                                : getLiteralWithParametersHtml(
                                      `${getLiteral('label_timeline_tab_title')} %@`,
                                      [unreadMessages],
                                      (num) =>
                                          forceTotalMessages > 0 || num > 0 ? (
                                              <BadgeNotification
                                                  size="tiny"
                                                  className="badge--unread"
                                              >
                                                  {forceTotalMessages || num}
                                              </BadgeNotification>
                                          ) : null,
                                  )}
                        </div>
                    ),
                });
            }

            // Total views && total link clicks
            if (activity?.ActivityType === ACTIVITY_TYPE_EMAIL && activity?.Tracking) {
                finalTabs.push({
                    key: 'total-views',
                    title: (
                        <div className="fm-tabs__new">
                            {getLiteral('label_total_views')}
                            <Text
                                className="fm-tabs__new-tag"
                                type="caption"
                                color={theme.colors.actionMajor[500]}
                            >
                                {getLiteral('label_promotion_new')}
                            </Text>
                        </div>
                    ),
                });

                finalTabs.push({
                    key: 'total-links',
                    title: (
                        <div className="fm-tabs__new">
                            {getLiteral('label_links_opened')}
                            <Text
                                className="fm-tabs__new-tag"
                                type="caption"
                                color={theme.colors.actionMajor[500]}
                            >
                                {getLiteral('label_promotion_new')}
                            </Text>
                        </div>
                    ),
                });
            }

            let newTabsNames = finalTabs.reduce((arr, current) => {
                arr.push(current.key);
                return arr;
            }, []);

            const areEqual = isEqualArray(tabsNames, newTabsNames);
            if (!areEqual) updateTabsNames(newTabsNames);

            return finalTabs;
        }, [
            activity,
            isActivityTypeWithMaps,
            hasCoordinates,
            isActivityTypeWithExtraFields,
            hasExtraFields,
            allowEmailAttachments,
            enableTimeLine,
            tabsNames,
            updateTabsNames,
            isLoadingFilesList,
            filesList?.length,
            forceTotalMessages,
            theme,
        ]);

        const noDetailProps = useCallback(() => {
            return inConversations(location)
                ? {
                      title: getLiteral('label_empty_screen_my_conversations_detail'),
                      subtitle: getLiteral('label_empty_screen_my_conversations_detail_desc'),
                      icon: <Conversations />,
                      overrides: { title: { type: 'h6' }, description: { type: 'subtitle1' } },
                  }
                : {
                      title: getLiteral('label_no_activity_selected'),
                      subtitle: getLiteral('label_no_activity_selected_desc'),
                      icon: <Detail />,
                      overrides: { title: { type: 'h6' }, description: { type: 'subtitle1' } },
                  };
        }, [location]);

        const forceLoader = useMemo(() => {
            if (!isFromWidgetDetail && activity && !activities.length) {
                return false;
            } else if (!isFromWidgetDetail && !activities.length) {
                return true;
            } else {
                return false;
            }
        }, [activities, isFromWidgetDetail, activity]);

        const defaultLoaderWidth = useMemo(() => {
            const body = document.querySelector('body');
            const bodyWidth = body.offsetWidth;
            const navigationEl = document.querySelector('.react-navigation-layout');
            const navigationWidth = navigationEl?.offsetWidth || 0;
            const listEl = document.querySelector('.fm-activities-list');
            const listWidth = listEl?.offsetWidth || 0;
            const staticNavigationWidth = 224;
            const staticListWidth = (bodyWidth - navigationWidth) * 0.5;

            if (navigationWidth && listWidth) return bodyWidth - navigationWidth - listWidth;
            return bodyWidth - staticNavigationWidth - staticListWidth;
        }, []);

        const defaultLoaderHeight = useMemo(() => {
            const body = document.querySelector('body');
            const bodyHeight = body.offsetHeight;
            const headersHeight = 64;
            return bodyHeight - headersHeight * 2;
        }, []);

        const onChangeTab = useCallback(
            (tab) => {
                const tabNames = {
                    map: 'mapWidget',
                    images: 'imagesWidget',
                    attachments: 'documentsWidget',
                    extraInfo: 'extraFieldsWidget',
                    email: 'emailWidget',
                    'total-views': 'openedEmails',
                    'total-links': 'clickedLinks',
                };

                if (tabNames[tab]) {
                    logEvent({ event: ACTIVITIES.trueName, submodule: tabNames[tab] });
                }

                if (tab === 'timeline') {
                    logEvent({
                        event: ACTIVITIES.trueName,
                        submodule: 'timelineConversations',
                        functionality: 'detailView',
                    });
                }

                if (isFromWidgetDetail && onChangeTabForWidget) {
                    onChangeTabForWidget(tab);
                }
                setTab(tab);
            },
            [isFromWidgetDetail, onChangeTabForWidget, setTab],
        );

        const tabsClassName = classnames('fm-activities__detail-tabs-container', {
            'is-from-widget': isFromWidgetDetail,
        });

        return (
            <div className="fm-activities__detail" style={{ width: finalWidth }}>
                {!activity && !loading && !forceLoader && (
                    <CustomEmptyView emptyViewProps={noDetailProps} />
                )}
                {(activity || loading || forceLoader) && (
                    <div className="fm-activities__detail-info" ref={domDetailInfo}>
                        {(loading || forceLoader) && (
                            <DetailLoader
                                width={domDetailInfo.current?.offsetWidth || defaultLoaderWidth}
                                height={defaultLoaderHeight}
                            />
                        )}
                        {activity && !loading && !forceLoader && renderGeneralDetail}
                    </div>
                )}
                {activity && !loading && !forceLoader && tab && tabs.length > 0 && (
                    <Fragment>
                        <div className="fm-activities__detail-divider" />
                        <Tabs
                            className="fm-activities__detail-tabs"
                            onChange={onChangeTab}
                            activeKey={tab}
                            tabs={tabs}
                        />
                        <div className={tabsClassName} ref={domDetailTabs}>
                            {tab === 'extraInfo' && (
                                <DetailExtraFields
                                    width={domDetailTabs.current?.offsetWidth}
                                    height={domDetailTabs.current?.offsetHeight}
                                    activityId={activity.Id}
                                />
                            )}
                            {tab === 'map' && <DetailMap activity={activity} />}
                            {tab === 'attachments' && <DetailDocument activity={activity} />}
                            {tab === 'files' && (
                                <DetailFiles
                                    entity={ACTIVITIES}
                                    entityId={activity.Id}
                                    getFiles={getActivityFiles}
                                    removeFile={removeActivityFile}
                                    canUploadImages={canUploadImages}
                                    canDeleteImages={canDeleteImages}
                                    canUploadDocuments={canCreateDocuments}
                                    showImages
                                    showDocuments
                                    imagesFileGroupLabel="label_images_with_total"
                                    emptyImagesTitle="label_empty_attachments_activities_title"
                                    emptyImagesDesc="label_empty_attachments_activities_desc"
                                    emptyDocumentsTitle="label_empty_documents_activities_title"
                                    emptyDocumentsDesc="label_empty_documents_activities_desc"
                                    emptyImagesTitlePermission="label_empty_attachments_activities_not_permission_title"
                                    emptyImagesDescPermission="label_empty_attachments_activities_not_permission_desc"
                                    emptyDocumentsTitlePermission="label_empty_documents_activities_not_permission_title"
                                    emptyDocumentsDescPermission="label_empty_documents_activities_not_permission_desc"
                                />
                            )}
                            {tab === 'email' && (
                                <DetailEmail
                                    activity={activity}
                                    labels={labels}
                                    getEmailWithImages={getEmailWithImages}
                                    loadingEmailWithImages={loadingEmailWithImages}
                                    emailWithImages={emailWithImages}
                                />
                            )}
                            {tab === 'whatsapp' && (
                                <DetailWhatsapp
                                    labels={labels}
                                    date={getDateText(activity?.ActivityDate)}
                                    owner={activity?.ActivityOwner}
                                    message={activity?.WhatsappMessage}
                                />
                            )}
                            {tab === 'timeline' && (
                                <DetailMessages
                                    isOpen={true}
                                    shouldForceFocus={false}
                                    activity={activity}
                                    initialTabItem={initialTabItem}
                                    matchingName={matchingName}
                                    scrollToMessage={scrollToMessage}
                                    setScrollToMessage={setScrollToMessage}
                                    storedMessage={newMessagesRef.current[newMessageUID]}
                                    handleNewMessage={handleNewMessage}
                                    setForceTotalMessages={setForceTotalMessages}
                                />
                            )}
                            {tab === 'total-views' && (
                                <DetailTotalViews trackingInfo={activity?.Tracking} />
                            )}
                            {tab === 'total-links' && (
                                <DetailTotalLinks trackingInfo={activity?.Tracking} />
                            )}
                        </div>
                    </Fragment>
                )}
            </div>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesDetail);
