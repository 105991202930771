import React, { memo, useCallback, useEffect, useMemo, useRef, useState, Fragment } from 'react';
import { useTheme, Button, Icon, Popover, Progress, Text } from 'hoi-poi-ui';
import { SettingsService } from 'services';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import useSettings from 'containers/settings/hooks/useSettings';
import { USAGE_LIMITS } from 'containers/settings/LimitsManagement/constants';

import './styles.scss';

const { getLimits } = SettingsService;

const UsageLimitInfo = ({ entity, hideCount, value }) => {
    const popoverRef = useRef();
    const [showPopover, setShowPopover] = useState(false);
    const [usage, setUsage] = useState({
        value: 0,
        max: 0,
        percentage: 0,
    });

    const theme = useTheme();

    const { handleUpgradePlan, hasLimits, isAdmin, renderUpgradeModal, showComparePlans } =
        useSettings({
            submodule: 'limits',
        });

    const limitKey = useMemo(() => USAGE_LIMITS[entity?.entity], [entity]);

    const handleClickOutside = useCallback((event) => {
        if (popoverRef.current && !popoverRef.current.contains(event.target)) {
            setShowPopover(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    useEffect(() => {
        if (value || !limitKey || !hasLimits) {
            if (value) setUsage(value);
            return;
        }
        getLimits(limitKey)
            .then((data) => {
                const { limitUsed, value } = data;
                const percentage = Math.round((limitUsed / value) * 100);
                const payload = { value: limitUsed, max: value, percentage };
                setUsage(payload);
            })
            .catch((error) => console.log(error));
    }, [hasLimits, limitKey, value]);

    const handleUpgrade = useCallback(() => {
        setShowPopover(false);
        handleUpgradePlan();
    }, [handleUpgradePlan]);

    const popoverProps = useMemo(
        () => ({
            placement: 'bottomLeft',
            className: 'fm-uli__popover',
            visible: showPopover,
            content: (
                <div className="fm-uli__popover__content" ref={popoverRef}>
                    <div className="fm-uli__popover__header">
                        <Text color={theme.colors.utility.textPrimary}>
                            {getLiteralWithParameters('label_percentage_achivements', [
                                usage.value,
                                usage.max,
                            ])}
                        </Text>
                        <Text
                            color={theme.colors.utility.textSecondary}
                        >{`${usage.percentage}%`}</Text>
                    </div>
                    <Progress max={usage.max} value={usage.value} />
                    <Text color={theme.colors.utility.textSecondary}>
                        {getLiteralWithParameters('label_percentage_achivements_info', [
                            getLiteral(`label_${entity?.trueName}`),
                        ])}
                    </Text>
                    {isAdmin && showComparePlans && (
                        <Button size="small" className="fm-uli__cta" onClick={handleUpgrade}>
                            {getLiteral('action_upgrade')}
                        </Button>
                    )}
                </div>
            ),
        }),
        [
            entity?.trueName,
            handleUpgrade,
            isAdmin,
            showComparePlans,
            showPopover,
            usage.max,
            usage.percentage,
            usage.value,
            theme,
        ],
    );

    const showInfo = useMemo(
        () => limitKey && hasLimits && Object.keys(USAGE_LIMITS).includes(entity?.entity),
        [entity?.entity, hasLimits, limitKey],
    );

    return showInfo ? (
        <Fragment>
            <Popover {...popoverProps}>
                <div className="fm-uli" onMouseEnter={() => setShowPopover(true)}>
                    <Icon
                        name="info"
                        size="medium"
                        className="fm-uli__icon"
                        color={theme.colors.grey[500]}
                    />
                    {!hideCount && (
                        <Text
                            type="body"
                            color={theme.colors.utility.textSecondary}
                        >{`${usage.value}/${usage.max}`}</Text>
                    )}
                </div>
            </Popover>
            {renderUpgradeModal}
        </Fragment>
    ) : null;
};

export default memo(UsageLimitInfo);
