import { memo, useState, useEffect, useCallback } from 'react';
import { Text, Chip } from 'hoi-poi-ui';
import { getEntityValue } from 'services/Fuzzy';
import { formatDate } from 'utils/dates';
import { getOperatorConditionValues } from '../../utils';
import { getLiteral } from 'utils/getLiteral';
import { FuzzyMap } from 'utils/fuzzy';

const ConditionBoxValue = memo(({ field, condition, operator }) => {
    const getValueType = useCallback(
        (value) => {
            switch (field?.type) {
                case 'percentage':
                    return `${value}%`;
                case 'datetime':
                    return formatDate(value);
                case 'bit':
                    let valueLiteral;
                    switch (value) {
                        case true:
                            valueLiteral = getLiteral('cfm_label_yes');
                            break;
                        case false:
                            valueLiteral = getLiteral('cfm_label_no');
                            break;
                    }
                    return valueLiteral;
                default:
                    return value;
            }
        },
        [field?.type],
    );

    const [innerValue, setInnerValue] = useState(
        ['list', 'multivalue'].includes(field?.type) ? (
            ''
        ) : (
            <Text type="subtitle" isTruncated useTooltip>
                {getValueType(getOperatorConditionValues(condition)?.value)}
            </Text>
        ),
    );

    useEffect(() => {
        const { value } = getOperatorConditionValues(condition);
        if (!['list', 'multivalue'].includes(field?.type)) {
            setInnerValue(
                <Text type="subtitle" isTruncated useTooltip>
                    {getValueType(value)}
                </Text>,
            );
            return;
        }

        const list =
            FuzzyMap[field?.tableRelated?.table?.toLowerCase()]?.list || field?.tableRelated?.table;
        getEntityValue(list, Array.isArray(value) ? value : [value])
            .then((result) => {
                if (!result?.data) return;
                const resultValue = value?.map((v) => ({
                    label: result?.data?.[v]?.description,
                    value: result?.data?.[v]?.id,
                }));

                setInnerValue(
                    <div className="fm-automation-builder__condition-box__chips">
                        {resultValue.map((v) => (
                            <Chip isFilled>{v.label}</Chip>
                        ))}
                    </div>,
                );
            })
            .catch((e) => {
                console.error(e);
                setInnerValue();
            });
    }, [condition, field?.tableRelated?.table, field?.type, getValueType, operator]);

    return innerValue;
});

export default ConditionBoxValue;
