import React, { Fragment, useMemo } from 'react';
import ContentLoader from 'react-content-loader';

import './styles.scss';

const CustomPlaceholder = ({ height = 120, width = 512, theme, ...props }) => {
    const rowHeight = 50;
    const numRows = Math.floor(height / rowHeight);

    const titleWidth = width * 0.23;
    const borderWidth = width * 0.94;
    const nameWidth = width * 0.225;
    const surnameWidth = width * 0.13;
    const dateWidth = width * 0.11;
    const datePosition = width * 0.83;

    const renderRows = useMemo(() => {
        let rows = [];
        let baseY = 38;
        for (let i = 0; i < numRows; i++) {
            rows.push(
                <Fragment key={i}>
                    <rect x="0" y={baseY} rx="3" ry="3" width={nameWidth} height="8" />
                    <rect x="0" y={baseY + 15} rx="3" ry="3" width={surnameWidth} height="8" />
                    <rect
                        x={datePosition}
                        y={baseY + 15}
                        rx="3"
                        ry="3"
                        width={dateWidth}
                        height="8"
                    />
                </Fragment>,
            );

            baseY = baseY + rowHeight;
        }
        return rows;
    }, [datePosition, dateWidth, nameWidth, numRows, surnameWidth]);

    return (
        <div className="fm-custom__placeholder">
            <ContentLoader
                height={height}
                width={width}
                speed={2}
                backgroundColor={theme.colors.grey[50]}
                foregroundColor={theme.colors.grey[100]}
                {...props}
            >
                <Fragment>
                    <rect x="0" y="0" rx="3" ry="3" width={titleWidth} height="8" />
                    <rect x="0" y="22" rx="3" ry="3" width={borderWidth} height="1" />
                    {renderRows}
                </Fragment>
            </ContentLoader>
        </div>
    );
};

export default CustomPlaceholder;
