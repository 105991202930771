import { memo, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Text, Popover, Icon, useTheme } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import SortableEntity from './SortableEntity';
import DisabledEntity from './DisabledEntity';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const ScheduleSortingMapEntities = ({ entities, disabledEntities, handleDragEnd }) => {
    const theme = useTheme();

    const onDragEnd = useCallback(
        (result) => {
            if (!result.destination) return;
            const items = reorder(entities, result.source.index, result.destination.index);
            handleDragEnd(items);
        },
        [entities, handleDragEnd],
    );

    const renderEntity = useCallback(
        (provided, _, rubric) => (
            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                <SortableEntity
                    entity={entities[rubric.source.index]}
                    index={rubric.source.index}
                />
            </div>
        ),
        [entities],
    );

    return (
        <div className="fm-schedule-sorting-map-modal__entities">
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" renderClone={renderEntity}>
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {entities.map((entity, index) => (
                                <Draggable
                                    key={entity.AccountId}
                                    draggableId={String(entity.AccountId)}
                                    index={index}
                                >
                                    {renderEntity}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            {!!disabledEntities?.length && (
                <div className="fm-schedule-sorting-map-modal__disabled-entities">
                    <Text
                        type="subtitle"
                        color={theme.colors.utility.textSecondary}
                        className="fm-schedule-sorting-map-modal__disableds-title"
                    >
                        {getLiteral('label_accounts_not_showing')}
                        <Popover
                            placement="top"
                            content={getLiteral('label_hover_accounts_not_geolocalized')}
                        >
                            <Icon name="helpOutline" />
                        </Popover>
                    </Text>
                    {disabledEntities.map((entity, index) => (
                        <DisabledEntity
                            key={entity.AccountId}
                            startIndex={entities?.length}
                            index={index}
                            entity={entity}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default memo(ScheduleSortingMapEntities);
