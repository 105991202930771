import React, { memo, useCallback, useMemo } from 'react';
import { Icon, Text, Tooltip, Avatar, Link, useTheme } from 'hoi-poi-ui';

import { COMPANIES } from 'constants/Entities';
import LabelPopover from 'components/LabelPopover';

import { UserChip } from 'containers/components/chips';
import useEntityDetail from 'containers/components/EntityDetail/hooks/useEntityDetail';
import CompanyAvatar from 'containers/components/avatars/CompanyAvatar';

import { getCompanyDetailUrl } from 'utils/getUrl';
import { getLiteral } from 'utils/getLiteral';
import { getSrcCompanyChipAvatar } from 'utils/getSrcAvatar';

const RelatedAccountRow = memo(
    ({
        id,
        descripcion,
        idrelation,
        idrelationtype,
        nombre,
        tipoempresa,
        responsable1,
        responsable2,
        responsable3,
        responsable4,
        responsable5,
        responsableId1,
        responsableId2,
        responsableId3,
        responsableId4,
        responsableId5,
        onEdit,
        onDelete,
    }) => {
        const { hasTabs: withTabs, openTab } = useEntityDetail();
        const theme = useTheme();

        const onEditAccount = useCallback(() => {
            onEdit({
                id: idrelation,
                account: {
                    label: nombre,
                    value: id,
                },
                relationType: idrelationtype,
            });
        }, [id, idrelation, idrelationtype, nombre, onEdit]);

        const onDeleteAccount = useCallback(() => {
            onDelete(idrelation);
        }, [idrelation, onDelete]);

        function renderOwnerChip(id, name, number, isOnlyOne) {
            const classes = `fm-widgets__related-accounts__owner fm-widgets__related-accounts__owner${number}`;

            if (isOnlyOne) {
                return (
                    <div key={number} className={classes}>
                        <UserChip idUser={id} username={name} remotePopover={true} />
                    </div>
                );
            } else {
                return (
                    <div key={number} className={classes}>
                        <UserChip idUser={id} remotePopover={true} />
                    </div>
                );
            }
        }

        const owners = useMemo(() => {
            let owners = [];
            let ownersList = [];
            const emptyOwners = '-';

            if (responsable1) owners.push({ id: responsableId1, name: responsable1, number: 1 });
            if (responsable2) owners.push({ id: responsableId2, name: responsable2, number: 2 });
            if (responsable3) owners.push({ id: responsableId3, name: responsable3, number: 3 });
            if (responsable4) owners.push({ id: responsableId4, name: responsable4, number: 4 });
            if (responsable5) owners.push({ id: responsableId5, name: responsable5, number: 5 });

            if (owners.length !== 0) {
                if (owners.length === 1) {
                    ownersList = ownersList = renderOwnerChip(
                        owners[0].id,
                        owners[0].name,
                        owners[0].number,
                        true,
                    );
                } else {
                    ownersList = owners.map((current) => {
                        return renderOwnerChip(current.id, current.name, current.number);
                    });
                }
                return ownersList;
            } else {
                return emptyOwners;
            }
        }, [
            responsable1,
            responsable2,
            responsable3,
            responsable4,
            responsable5,
            responsableId1,
            responsableId2,
            responsableId3,
            responsableId4,
            responsableId5,
        ]);

        const onClickCompany = useCallback(
            (e) => {
                e.preventDefault();
                openTab({
                    entity: COMPANIES,
                    id,
                    hasCrudInDetail: true,
                });
            },
            [id, openTab],
        );

        const urlLogo = useMemo(() => getSrcCompanyChipAvatar(id), [id]);

        const parseDataForPopover = useCallback(
            (id, data) => {
                let title;
                let subtitle;
                let phone;
                let idCompany;
                let deleted;
                let multiplePhones;

                if (data.title) title = data.title;
                else if (data.deleted && !data.title) {
                    title = data.company;
                } else {
                    const props = {
                        onClick: withTabs && onClickCompany,
                        href: getCompanyDetailUrl(id),
                        target: '_blank',
                    };
                    title = (
                        <Link type="subtitle" {...props}>
                            {data.company}
                        </Link>
                    );
                }

                if (data.deleted) deleted = data.deleted;

                if (data.subtitle) subtitle = data.subtitle;
                else {
                    const subtitleText = data.segment
                        ? `${data.companyType}, ${data.segment}`
                        : data.companyType;
                    subtitle = <Text type="caption">{subtitleText}</Text>;
                }
                if (data.idCompany) idCompany = data.idCompany;
                if (data.phone1) phone = data.phone1;
                else if (data.phone2) phone = data.phone2;
                else if (data.mobilePhone) phone = data.mobilePhone;

                if (data.phone1) multiplePhones = data.phone1;
                if (data.phone2)
                    multiplePhones = multiplePhones
                        ? `${multiplePhones}, ${data.phone2}`
                        : data.phone2;
                if (data.mobilePhone)
                    multiplePhones = multiplePhones
                        ? `${multiplePhones}, ${data.mobilePhone}`
                        : data.mobilePhone;

                return {
                    ...data,
                    title,
                    subtitle,
                    phone,
                    idCompany,
                    deleted,
                    multiplePhones,
                };
            },
            [onClickCompany, withTabs],
        );

        return (
            <div className="fm-widgets__related-accounts__account">
                <div onClick={withTabs ? onClickCompany : null}>
                    <Avatar
                        size="medium"
                        src={urlLogo.src || urlLogo.fallbackSrc}
                        onError={(e) => {
                            e.target.src = urlLogo.fallbackSrc;
                        }}
                    />
                </div>
                <div className="fm-widgets__related-accounts__account-row">
                    <div className="fm-widgets__related-accounts__company-info">
                        <LabelPopover
                            image={<CompanyAvatar id={id} size="large" />}
                            id={id}
                            type="company"
                            remotePopover={true}
                            parseDataForPopover={parseDataForPopover}
                        >
                            <Text
                                className="fm-widgets__related-accounts__company-name"
                                type="body"
                                onClick={withTabs ? onClickCompany : null}
                                bold
                            >
                                {nombre}
                            </Text>
                        </LabelPopover>
                    </div>
                    {tipoempresa && (
                        <div className="fm-widgets__related-accounts__type">
                            <Text>{tipoempresa}</Text>
                        </div>
                    )}
                    <div className="fm-widgets__related-accounts__owner-info">
                        <Text
                            color={theme.colors.utility.textSecondary}
                        >{`${getLiteral('label_responsibles')}: `}</Text>
                        <Text className="fm-widgets__related-accounts__owner-avatars">
                            {owners}
                        </Text>
                    </div>
                    {descripcion && (
                        <div className="fm-widgets__related-accounts__type">
                            <Text
                                color={theme.colors.utility.textSecondary}
                            >{`${getLiteral('label_relation_type')}: `}</Text>
                            <Text>{descripcion}</Text>
                        </div>
                    )}
                </div>
                <div className="fm-widgets__related-accounts__actions">
                    <Tooltip content={getLiteral('action_edit')}>
                        <span>
                            <Icon name="edit" onClick={onEditAccount} />
                        </span>
                    </Tooltip>
                    <Tooltip content={getLiteral('action_delete')}>
                        <span>
                            <Icon name="delete" onClick={onDeleteAccount} />
                        </span>
                    </Tooltip>
                </div>
            </div>
        );
    },
);

export default RelatedAccountRow;
