import React from 'react';
import colors from 'constants/colors';

const Dots = ({ color = colors.actionMinor[500], ...props }) => (
    <svg viewBox={`0 0 18 18`} xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M9 4a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 14a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-7a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
            fill={color || '#335B70'}
            fillRule="nonzero"
        />
    </svg>
);

export default Dots;
