import React, { memo, useMemo, useCallback, isValidElement } from 'react';
import { Text, useTheme } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import { getMomentFromDateBackend, formatDateWithTimeOrNot } from 'utils/dates';
import { getNumberAsCurrency } from 'utils/currency';
import { getNumberAsLocaleNumber } from 'utils/numbers';
import { getBackendBoolean } from 'utils/fm';
import TextWithLinks from 'components/TextWithLinks';

import './styles.scss';

const ExtraFieldsList = ({ fields }) => {
    const theme = useTheme();

    const renderRows = useCallback(
        (list) => {
            return list.map((current, index) => {
                if (!current) return;
                const showPlaceholder = current.withEmptyPlaceholder && !current.value;
                const value = showPlaceholder
                    ? getLiteral('label_placeholder_unamed_record')
                    : current.value;
                const valueClassName = showPlaceholder ? 'fm-entity-placeholder' : undefined;
                return (
                    <div key={index} className="fm-detail-extrafields__row">
                        <div className="fm-detail-extrafields__row-field">
                            <Text color={theme.colors.utility.textSecondary}>
                                {current.descripcion}
                            </Text>
                        </div>
                        <div className="fm-detail-extrafields__row-value">
                            {isValidElement(current.value) ? (
                                current.value
                            ) : (
                                <Text className={valueClassName}>{value}</Text>
                            )}
                        </div>
                    </div>
                );
            });
        },
        [theme],
    );

    const renderMultipleValueList = useCallback((value) => {
        if (!value) return null;
        const values = value.trim().split(';').join(', ');
        if (values) {
            return <Text>{values}</Text>;
        }
    }, []);

    const renderSection = useCallback(
        (section, key) => {
            const fields = section.data.map((field) => {
                let value;
                let customClassName;

                if (field.value || field.dataType === 'bool' || field.value === 0) {
                    value = field.value;
                    switch (field.dataType) {
                        case 'text':
                            value = value && <TextWithLinks text={value} />;
                            break;
                        case 'integer':
                        case 'decimal':
                            value = getNumberAsLocaleNumber(value);
                            break;
                        case 'percent':
                            value = `${getNumberAsLocaleNumber(field.value, {
                                useGrouping: false,
                                minimumFractionDigits: 2,
                            })}%`;
                            break;
                        case 'relatedValueList':
                        case 'singleValueList':
                        case 'fuzzySearchSingle':
                            value = field.label;
                            break;
                        case 'multipleValueList':
                        case 'fuzzySearchMultiple':
                            customClassName = 'fm-detail-extrafields__multiple-value-list';
                            value = field.value ? renderMultipleValueList(field.label) : '-';
                            break;
                        case 'bool':
                            value = getBackendBoolean(field.value)
                                ? getLiteral('common_yes')
                                : getLiteral('common_no');
                            break;
                        case 'currency':
                            value = getNumberAsCurrency(field.value);
                            break;
                        case 'date':
                            value = getMomentFromDateBackend(value);
                            value = formatDateWithTimeOrNot(value, field?.includeTime);
                            break;
                    }

                    return {
                        ...field,
                        value,
                    };
                }
            });

            return (
                <div key={key} className="fm-detail-extrafields__section">
                    <div className="fm-detail-extrafields__header">
                        <Text type="subtitle" medium>
                            {section.descripcion}
                        </Text>
                    </div>
                    {renderRows(fields)}
                </div>
            );
        },
        [renderMultipleValueList, renderRows],
    );

    const renderList = useMemo(() => {
        if (!fields?.length) return null;
        return fields.reduce((arr, current, index) => {
            if (!current?.data?.length) return arr;
            arr.push(renderSection(current, index));
            return arr;
        }, []);
    }, [fields, renderSection]);

    return <div className="fm-detail-extrafields">{fields && renderList}</div>;
};

export default memo(ExtraFieldsList);
