import React from 'react';

export const isClassComponent = (component) => {
    return typeof component === 'function' && !!component.prototype.isReactComponent ? true : false;
};

export const isFunctionComponent = (component) => {
    return typeof component === 'function' &&
        String(component).includes('return React.createElement')
        ? true
        : false;
};

export const isReactComponent = (component) => {
    return isClassComponent(component) || isFunctionComponent(component) || isElement(component)
        ? true
        : false;
};

export const isElement = (element) => {
    return React.isValidElement(element);
};

export const isDOMTypeElement = (element) => {
    return isElement(element) && typeof element.type === 'string';
};

export const isCompositeTypeElement = (element) => {
    return isElement(element) && typeof element.type === 'function';
};
