import { useEffect, useMemo, useRef, useState } from 'react';
import { COMPANIES, USERS } from 'constants/Entities';
import CONFIG from '../EntityConfig';
import colors from 'constants/colors';
import {
    groupFieldsForFieldSelector,
    processExtraFieldsForList,
    updateTableOrderAndVisibility,
} from 'utils/fm';
import { getLiteral } from 'utils/getLiteral';
import { getUserSfmUrl, getCompanyDetailUrl } from 'utils/getUrl';
import { successToast, errorToast } from 'utils/toast';
import useEmailEditor from 'containers/components/EmailEditor/hooks/useEmailEditor';

const useCompaniesConfig = ({
    enableBulkOperations,
    followEntity,
    getExtraFieldSchema,
    is5500,
    locale,
    onEntitySelect,
    onEntitySelectAll,
    setUseLazyLoad,
    standardFieldsConfiguration,
}) => {
    const [config, setConfig] = useState(null);
    const isFirstLoad = useRef(true);

    const { canUseEmail, mailToFromTable } = useEmailEditor();

    const tableParams = useMemo(() => {
        let newTableParams = {
            getUrl: ({ idKey, entity }) => {
                return (data) => {
                    if (!idKey || !entity) return;
                    const id = data[idKey];
                    if (!id) return;
                    let url = '';

                    switch (entity) {
                        case COMPANIES:
                            url = getCompanyDetailUrl(id) || '';
                            return url;
                        case USERS:
                            url = getUserSfmUrl(id) || '';
                            return url;
                    }
                };
            },
            account: {
                getSvg: (data) => {
                    const thumbUp = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"><path d="M14.88 13.231c.667 0 1.206.534 1.206 1.192 0 .659-.54 1.192-1.206 1.192h-.804a1.2 1.2 0 011.206 1.193c0 .659-.54 1.192-1.206 1.192H8.848C5.63 18 6.434 16.41 0 16.41V9.257h2.413c2.815 0 6.032-2.56 6.032-6.359 0-1.257 2.413-1.415 2.413.969 0 1.59-.804 4.595-.804 4.595h6.435a1.2 1.2 0 011.206 1.192 1.2 1.2 0 01-1.206 1.193h-.804a1.2 1.2 0 011.206 1.192 1.2 1.2 0 01-1.206 1.192h-.805" fill="#86AD20" fill-rule="evenodd"/></svg>`;
                    const thumbDown = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"><path d="M14.88 6.769c.667 0 1.206-.534 1.206-1.192 0-.659-.54-1.192-1.206-1.192h-.804a1.2 1.2 0 001.206-1.193c0-.659-.54-1.192-1.206-1.192H8.848C5.63 2 6.434 3.59 0 3.59v7.153h2.413c2.815 0 6.032 2.56 6.032 6.359 0 1.257 2.413 1.415 2.413-.969 0-1.59-.804-4.595-.804-4.595h6.435a1.2 1.2 0 001.206-1.192 1.2 1.2 0 00-1.206-1.193h-.804a1.2 1.2 0 001.206-1.192 1.2 1.2 0 00-1.206-1.192h-.805" fill="#F04540" fill-rule="evenodd"/></svg>`;

                    if (data?.win) return thumbUp;
                    else if (data?.lost) return thumbDown;
                    else return null;
                },
            },
            follow: {
                onClick: (value, data, event) => {
                    followEntity({ entityType: COMPANIES, entityId: data?.Id, follow: value })
                        .then((bool) => {
                            successToast({
                                text: bool
                                    ? getLiteral('succes_following')
                                    : getLiteral('succes_unfollowing'),
                            });
                        })
                        .catch((err) => {
                            console.error(err);
                            errorToast();
                        });
                },
                getSvg: (value) => {
                    const activeColor = colors.actionMajor[500];
                    const inactiveColor = colors.utility.textSecondary;
                    const svgActive = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" >
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.4 6 14 4H5v17h2v-7h5.6l.4 2h7V6h-5.6z" fill="${activeColor}"/>
                        </svg>`;
                    const svgInactive = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" >
                        <path fill-rule="evenodd" clip-rule="evenodd" d="m12.36 6 .4 2H18v6h-3.36l-.4-2H7V6h5.36M14 4H5v17h2v-7h5.6l.4 2h7V6h-5.6" fill="${inactiveColor}"/>
                        </svg>`;

                    if (value) return svgActive;
                    else return svgInactive;
                },
                getHeaderSvg: () => {
                    const color = colors.utility.textSecondary;
                    const geolocated = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none" >
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.4 6 14 4H5v17h2v-7h5.6l.4 2h7V6h-5.6z" fill="${color}"/>
                    </svg>`;
                    return geolocated;
                },
                getTooltip: (value) => {
                    if (value) return getLiteral('label_following');
                    else return getLiteral('action_follow');
                },
            },
            date: {
                getLocale: () => locale,
            },
            geolocation: {
                getSvg: (value) => {
                    const geolocatedColor = colors.actionMajor[500];
                    const notGeolocatedColor = colors.grey[100];
                    const geolocated = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"><path d="M9.429 0C5.874 0 3 2.817 3 6.3 3 11.025 9.429 18 9.429 18s6.428-6.975 6.428-11.7c0-3.483-2.874-6.3-6.428-6.3zm-.215 8.143a1.93 1.93 0 11.002-3.859 1.93 1.93 0 01-.002 3.859z" fill="${geolocatedColor}" fill-rule="evenodd"/></svg>`;
                    const notGeolocated = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"><path d="M9.429 0C5.874 0 3 2.817 3 6.3 3 11.025 9.429 18 9.429 18s6.428-6.975 6.428-11.7c0-3.483-2.874-6.3-6.428-6.3zm-.215 8.143a1.93 1.93 0 11.002-3.859 1.93 1.93 0 01-.002 3.859z" fill="${notGeolocatedColor}" fill-rule="evenodd"/></svg>`;
                    if (value) return geolocated;
                    else return notGeolocated;
                },
                getHeaderSvg: () => {
                    const geolocatedColor = colors.utility.textPrimary;
                    const geolocated = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"><path d="M9.429 0C5.874 0 3 2.817 3 6.3 3 11.025 9.429 18 9.429 18s6.428-6.975 6.428-11.7c0-3.483-2.874-6.3-6.428-6.3zm-.215 8.143a1.93 1.93 0 11.002-3.859 1.93 1.93 0 01-.002 3.859z" fill="${geolocatedColor}" fill-rule="evenodd"/></svg>`;
                    return geolocated;
                },
            },
        };

        if (enableBulkOperations) {
            newTableParams.account.onClickCheckbox = (result) => {
                const { id, isColumn, value, checked, data, rowIndex, total } = result;
                if (isColumn) {
                    onEntitySelectAll(COMPANIES, value, checked, total);
                } else {
                    onEntitySelect(COMPANIES, id, value, data, rowIndex, checked);
                }
            };
        }

        if (canUseEmail) {
            newTableParams.email1 = {
                forceUrl: true,
                onClick: (data) => mailToFromTable(data, COMPANIES),
            };
        }

        return newTableParams;
    }, [
        canUseEmail,
        mailToFromTable,
        enableBulkOperations,
        followEntity,
        locale,
        onEntitySelectAll,
        onEntitySelect,
    ]);

    useEffect(() => {
        setUseLazyLoad(COMPANIES, true);

        if (!isFirstLoad?.current) return;

        let newConfig = CONFIG(tableParams);
        newConfig = {
            ...newConfig,
            withEntitySelection: false,
        };

        if (enableBulkOperations) {
            newConfig = {
                ...newConfig,
                withEntitySelection: true,
                selectionFor: COMPANIES.entity,
                isLocalSelection: true,
            };
        }

        getExtraFieldSchema(newConfig.entity, (extraFieldTabs) => {
            let extraFields = [];
            const groupedExtraFields = extraFieldTabs.map((tab) => {
                let group = {
                    label:
                        typeof tab.descripcion !== 'string'
                            ? 'label_customized_fields'
                            : tab.descripcion,
                    fields: [],
                };

                group.fields = processExtraFieldsForList(tab.tabFields);
                extraFields = [...extraFields, ...group.fields];

                return group;
            });

            standardFieldsConfiguration(COMPANIES, newConfig.columnDefs)
                .then((columnDefs) => {
                    const newColumnDefs = [...columnDefs, ...extraFields];
                    const groupedFields = [
                        ...groupFieldsForFieldSelector(newConfig, columnDefs, true),
                        ...groupedExtraFields,
                    ];

                    newConfig = {
                        ...newConfig,
                        columnDefs: newColumnDefs,
                        groupedFields,
                    };

                    // Special 5500 visibility
                    if (is5500) {
                        newConfig.columnDefs = updateTableOrderAndVisibility(
                            [
                                'name',
                                'responsible',
                                'companytype',
                                'calificacion',
                                'phone',
                                'address',
                                'city',
                                'province',
                                'cp',
                                'Z_Business_Code_Category',
                                'Z_PlanType_Multilist',
                                'Z_TotParticipantEOY',
                                'Z_Total_Assets_EOY_Amount',
                                'Z_TotAssetsPerParticipant',
                            ],
                            newColumnDefs,
                        );
                    }

                    setConfig(newConfig);
                })
                .catch(() => {
                    console.error('List configuration error');
                });
        });

        isFirstLoad.current = false;
    }, [
        tableParams,
        enableBulkOperations,
        setConfig,
        getExtraFieldSchema,
        standardFieldsConfiguration,
        setUseLazyLoad,
        is5500,
    ]);

    return config;
};

export default useCompaniesConfig;
