import React, { memo } from 'react';

const Opportunities = (props) => (
    <svg width={168} height={144} fill="none" {...props}>
        <g mask="url(#a)">
            <path
                fill="#5E5E5E"
                d="M78.716 54.744a1.429 1.429 0 0 1-1.383-1.788c2.074-8.036 2.726-9.376 2.94-9.816.705-1.447 5.419-8.863 5.954-9.703a1.428 1.428 0 1 1 2.408 1.536c-2.066 3.247-5.314 8.433-5.793 9.42-.276.568-1.001 2.528-2.743 9.278a1.427 1.427 0 0 1-1.382 1.072l-.001.001Z"
            />
            <path fill="#EF5C54" d="M89.44 28.593a.535.535 0 1 0-.001-1.07.535.535 0 0 0 0 1.07Z" />
            <mask
                id="b"
                width={2}
                height={2}
                x={88}
                y={27}
                maskUnits="userSpaceOnUse"
                style={{
                    maskType: 'luminance',
                }}
            >
                <path
                    fill="#fff"
                    d="M89.44 28.593a.535.535 0 1 0-.001-1.07.535.535 0 0 0 0 1.07Z"
                />
            </mask>
            <g mask="url(#b)">
                <path
                    fill="#454545"
                    d="M90.127 27.638c-.385.16-.822.16-1.288.06l.046-.935 1.396.283-.153.592h-.001Z"
                />
            </g>
            <path
                fill="#5E5E5E"
                d="M78.417 56.517a.28.28 0 0 1-.271-.341l.474-2.083a.28.28 0 0 1 .544.125L78.69 56.3a.28.28 0 0 1-.272.218v-.002ZM89.388 47.354a.278.278 0 0 1-.245-.145l-.764-1.399a.28.28 0 0 1 .49-.27l.764 1.4a.28.28 0 0 1-.244.414h-.001Z"
            />
            <path
                fill="#5E5E5E"
                d="M89.297 46.105a1.43 1.43 0 0 1-1.328-1.954c.973-2.473 1.646-5.22 1.453-5.918-.686-1.24-1.264-2.075-1.596-2.52l-.617-.096a1.428 1.428 0 0 1-1.19-1.632 1.427 1.427 0 0 1 1.63-1.192l1.17.182c.331.051.633.217.852.468.041.046 1.013 1.163 2.27 3.441.37.671.674 1.867-.268 5.185a37.337 37.337 0 0 1-1.048 3.128c-.221.563-.76.906-1.329.906l.001.002Z"
            />
            <path
                fill="#C6C6C6"
                d="M88.928 34.892c-.712 0-1.367-.49-1.647-.733a.99.99 0 0 1 1.006-1.672c-.074-.677-.167-1.319-.256-1.693-.203-.003-.47.006-.686.016-.815.03-1.83.068-2.792-.195-1.722-.471-1.987-1.553-2.023-1.764a.99.99 0 0 1 1.92-.455c.038.046.19.19.623.31.67.183 1.482.152 2.198.126.978-.037 1.75-.065 2.24.496.163.184.596.676.846 4.087.008.1 0 .2-.024.298-.127.549-.476.953-.957 1.11-.15.049-.3.07-.45.07l.002-.001Zm-4.449-6.37v.002-.002Z"
            />
            <path
                fill="#C6C6C6"
                d="M88.262 34.843a1.637 1.637 0 0 0 1.745-1.796l-.233-2.35-1.305-.109-1.25-.493-.477 3.116c0 .858.664 1.57 1.52 1.632Z"
            />
            <path
                fill="#EF5C54"
                d="M83.24 28.897a.538.538 0 0 1-.398-.176c-.039-.042-.951-1.044-1.47-1.735a.539.539 0 0 1 .86-.647c.488.65 1.396 1.645 1.405 1.656a.539.539 0 0 1-.398.902Z"
            />
            <path
                fill="#E2E2E2"
                d="M101.426 102.615H71.162a.857.857 0 0 1-.856-.857v-10.55a.856.856 0 1 1 1.712 0v9.693h29.408a.857.857 0 0 1 0 1.714Z"
            />
            <path
                fill="#E2E2E2"
                d="M91.489 102.615a.857.857 0 0 1-.856-.857V87.723a.857.857 0 1 1 1.712 0v14.035a.857.857 0 0 1-.856.857ZM99.465 116.065H71.162a.857.857 0 0 1 0-1.715h28.303a.857.857 0 0 1 0 1.715Z"
            />
            <path
                fill="#E2E2E2"
                d="M93.41 134.965h-.008a.857.857 0 0 1-.848-.865l.164-18.901a.857.857 0 0 1 .856-.849h.008a.857.857 0 0 1 .849.865l-.165 18.901a.857.857 0 0 1-.856.849ZM81.254 95.597a.856.856 0 0 1-.857-.857V71.428a.857.857 0 1 1 1.713 0V94.74a.856.856 0 0 1-.856.857ZM51.006 120.024H35.261a.857.857 0 0 1 0-1.714h15.745a.857.857 0 0 1 0 1.714ZM122.193 116.065h-9.192a.857.857 0 0 1-.856-.857V99.283a.856.856 0 1 1 1.711 0v15.067h7.481v-4.941a.856.856 0 1 1 1.712 0v5.799a.857.857 0 0 1-.856.857ZM134.538 124.068h-33.112a.857.857 0 0 1 0-1.715h33.112a.857.857 0 0 1 0 1.715Z"
            />
            <path
                fill="#E2E2E2"
                d="M116.284 133.271a.857.857 0 0 1-.856-.858v-9.202a.857.857 0 1 1 1.712 0v9.202a.857.857 0 0 1-.856.858ZM58.736 110.791a.857.857 0 0 1-.856-.857V91.115a.857.857 0 1 1 1.712 0v18.819a.857.857 0 0 1-.856.857ZM78.163 129.738H58.736a.856.856 0 0 1-.856-.857v-8.114a.856.856 0 1 1 1.712 0v7.257h18.571a.857.857 0 0 1 0 1.714Z"
            />
            <path
                fill="#E2E2E2"
                d="M58.736 129.738H45.211a.856.856 0 1 1 0-1.714h13.525a.857.857 0 0 1 0 1.714ZM92.666 76.347a.857.857 0 0 1-.856-.857V57.876a.857.857 0 1 1 1.712 0V75.49a.857.857 0 0 1-.856.857ZM104.596 92.065a.856.856 0 0 1-.856-.858V81.51a.856.856 0 1 1 1.712 0v9.697a.856.856 0 0 1-.856.858Z"
            />
            <path
                fill="#ABABAB"
                d="M28.086 130.57a1.691 1.691 0 0 1-1.413-2.612l27.66-42.521a1.686 1.686 0 0 1 2.662-.215l4.257 4.688 23.515-45.437c.29-.559.865-.911 1.494-.912h.004c.629 0 1.204.35 1.495.905l30.016 57.435 5.658-9.603c.302-.516.855-.83 1.453-.83h.007a1.69 1.69 0 0 1 1.454.843l15.024 26.009a1.69 1.69 0 0 1-.616 2.309 1.687 1.687 0 0 1-2.306-.617l-13.578-23.506-5.732 9.729a1.677 1.677 0 0 1-1.496.831 1.692 1.692 0 0 1-1.453-.906l-29.92-57.252L63.13 93.62a1.688 1.688 0 0 1-2.747.359l-4.417-4.865-26.465 40.689a1.686 1.686 0 0 1-1.416.767Z"
            />
            <path
                fill="#008A21"
                d="M85.912 10.943c4.808 2.666 10.382-3.115 15.302-1.673.075 3.508.146 7.063.213 10.665-4.938-2.72-10.687 3.468-15.703 1.589.06-3.506.123-7.032.188-10.58Z"
            />
            <path
                fill="#00D339"
                d="M85.743 134.833a.725.725 0 0 1-.724-.725v-6.599a4.858 4.858 0 0 0-4.85-4.855H68.85c-4.098 0-7.433-3.338-7.433-7.441s3.335-7.442 7.433-7.442H101.3a4.736 4.736 0 0 0 4.728-4.733v-1.95c0-3.006-1.08-4.53-3.208-4.53h-1.317a3.314 3.314 0 0 1-3.31-3.314v-5.678a4.606 4.606 0 0 0-4.598-4.603h-2.977c-1.922-.005-3.268-.43-4.117-1.297-1.252-1.28-1.217-3.192-1.177-5.406a60.9 60.9 0 0 0 .015-1.217V9.932a.724.724 0 1 1 1.449 0v65.11c0 .427-.008.843-.015 1.244-.034 1.888-.064 3.52.763 4.364.564.577 1.572.859 3.083.863h2.976c3.334 0 6.046 2.714 6.046 6.053v5.678c0 1.027.836 1.864 1.862 1.864h1.317c2.959 0 4.657 2.179 4.657 5.98v1.95c0 3.41-2.771 6.184-6.177 6.184H68.849c-3.3 0-5.984 2.687-5.984 5.991s2.684 5.991 5.984 5.991h11.32c3.474 0 6.299 2.828 6.299 6.305v6.599c0 .4-.324.725-.725.725Z"
            />
            <path
                fill="#EF5C54"
                d="M87.091 33.59a.554.554 0 0 1-.23-.051c-.4-.19-1.854-.964-1.916-.995a.54.54 0 0 1 .506-.952c.014.008 1.492.793 1.871.973a.539.539 0 0 1-.232 1.025h.001Z"
            />
        </g>
    </svg>
);

export default memo(Opportunities);
