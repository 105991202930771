import { ACTIVITIES, COMPANIES, CONTACTS, OPPORTUNITIES, SALESORDERS } from 'constants/Entities';

export const AUTOMATION_ENTITIES = [COMPANIES, ACTIVITIES, OPPORTUNITIES, CONTACTS, SALESORDERS];

export const CONDITION_OPERATOR_LOCALES = {
    '==': 'label_automation_operator_equal_to',
    '!=': 'label_automation_operator_no_equal_to',
    '>': 'label_automation_operator_after_than',
    '>=': 'label_automation_operator_on_or_after_than',
    '<': 'label_automation_operator_before_than',
    '<=': 'label_automation_operator_on_or_before_than',
    in: 'label_automation_operator_contains',
    not_in: 'label_automation_operator_no_contains',
};

export const CONDITION_BOOL_LOGIC_LOCALES = {
    and: 'cfm_label_condition_and',
    or: 'cfm_label_condition_or',
};

export function findNodeByType(node, id) {
    if (node.name === id) return node;
    if (Array.isArray(node.next)) {
        for (const child of node.next) {
            const result = findNodeByType(child, id);
            if (result) return result;
        }
    }

    return null;
}

export function findParentById(tree, targetId) {
    function traverse(node, parent) {
        if (node.name === targetId) {
            return parent;
        }
        if (node.next) {
            for (const child of node.next) {
                const result = traverse(child, node);
                if (result) {
                    return result;
                }
            }
        }
        return null;
    }

    return traverse(tree, tree);
}

export function buildPathTree(obj, path) {
    const keys = path.split('.');
    function walk(current, index) {
        const isFinalNode = index >= keys.length;

        if (isFinalNode) {
            if (Array.isArray(current)) {
                return [...current];
            } else if (current !== null && typeof current === 'object') {
                const copyObj = { ...current };
                delete copyObj.next;
                return copyObj;
            } else {
                return current;
            }
        }
        const key = keys[index];
        if (Array.isArray(current)) {
            const idx = parseInt(key, 10);
            if (isNaN(idx) || idx < 0 || idx >= current.length) {
                return [];
            }
            const copyArr = [...current];
            copyArr[idx] = walk(current[idx], index + 1);
            return copyArr;
        }
        if (current !== null && typeof current === 'object') {
            if (!Object.prototype.hasOwnProperty.call(current, key)) {
                return {};
            }
            const copyObj = { ...current };
            copyObj[key] = walk(current[key], index + 1);
            return copyObj;
        }
        return current;
    }

    return walk(obj, 0);
}

export function getOperatorConditionValues(condition, newPair) {
    let operator = Object.keys(condition || {})?.[0];
    let variable;
    let value;
    let conditionPair;

    switch (operator) {
        case 'not_in':
        case 'in':
            variable =
                newPair?.variable === null ? null : (newPair?.variable ?? condition[operator]?.[1]);
            value = newPair?.value === null ? null : (newPair?.value ?? condition[operator]?.[0]);
            conditionPair = [value, variable];
            break;
        default:
            variable =
                newPair?.variable === null ? null : (newPair?.variable ?? condition[operator]?.[0]);
            value = newPair?.value === null ? null : (newPair?.value ?? condition[operator]?.[1]);
            conditionPair = [variable, value];
            break;
    }

    return {
        conditionPair,
        variable,
        value,
        operator,
    };
}
