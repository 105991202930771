import React, { memo, useMemo } from 'react';
import colors from 'constants/colors';

import { Icon } from 'hoi-poi-ui';
import DragPreview from 'containers/components/DragPreview';

const DashboardDragPreview = memo(({ data }) => {
    const icon = useMemo(() => {
        let name;
        if (data?.isFolder) name = 'folder';
        if (data?.isFolder && !data?.shared) name = 'folderKey';
        if (data?.isReport) name = 'report';
        return <Icon name={name} color={colors.primary.white} />;
    }, [data]);

    return <DragPreview icon={icon} text={data.title} />;
});

export default DashboardDragPreview;
