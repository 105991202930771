import React, { memo, useMemo, useState, Fragment } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { Icon, Text, useTheme } from 'hoi-poi-ui';
import classnames from 'classnames';
import UtilFormat from 'utils/UtilFormat';
import { getFileExtensionIconName } from 'utils/files';
import { getLiteral } from 'utils/getLiteral';

import './uploadFilesDialogRowStyles.scss';

const UploadFilesDialogRow = memo(({ id, data, onCancel, onClear }) => {
    const [isOver, setIsOver] = useState(false);
    const theme = useTheme();

    const fileIconName = useMemo(() => {
        let extension = '';
        if (data?.file?.name) extension = data.file.name.split('.')[1];
        return getFileExtensionIconName(extension);
    }, [data]);

    const progressClassName = classnames('upload-files-dialog-row__progress', {
        'upload-files-dialog-row__progress--hidden': isOver && data.percentage !== 100,
    });

    const rootClassName = classnames('upload-files-dialog-row', {
        'upload-files-dialog-row--no-hover': data.error || data.percentage === 100,
    });

    const textColor = useMemo(() => {
        if (data.error) return theme.colors.semantic.negative500;
        return theme.colors.utility.textPrimary;
    }, [data, theme]);

    const sizeTextColor = useMemo(() => {
        if (data.error) return theme.colors.semantic.negative500;
        return theme.colors.utility.textSecondary;
    }, [data, theme]);

    return (
        <div
            className={rootClassName}
            onMouseEnter={() => setIsOver(true)}
            onMouseLeave={() => setIsOver(false)}
        >
            <div className="upload-files-dialog-row--left">
                <Icon name={fileIconName} size="large" />
                <div className="upload-files-dialog-row__text">
                    <Text type="caption" color={textColor}>
                        {data?.file?.name || ''}
                    </Text>
                    <Text type="caption" color={sizeTextColor}>
                        {UtilFormat.formatFileSize(data?.total || 0)}
                    </Text>
                </div>
            </div>
            <div className="upload-files-dialog-row--right">
                {!data.error && (
                    <div className="upload-files-dialog-row__progress-container">
                        <CircularProgressbar
                            value={data.percentage}
                            strokeWidth={10}
                            className={progressClassName}
                            text={`${data.percentage}%`}
                        />
                        {isOver && data.percentage !== 100 && !data.error && (
                            <Icon
                                className="upload-files-dialog-row__action"
                                name="close"
                                onClick={() => onCancel(id)}
                            />
                        )}
                    </div>
                )}
                {data.error && (
                    <Text type="caption" color={textColor}>
                        {getLiteral('title_error')}
                    </Text>
                )}
            </div>
        </div>
    );
});

export default UploadFilesDialogRow;
