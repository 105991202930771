import React, { memo } from 'react';

const Orders = memo((props) => (
    <svg width={168} height={144} fill="none" {...props}>
        <g mask="url(#a)">
            <path
                fill="#919191"
                d="M104.546 56.243c4.024 0 7.286-3.267 7.286-7.297 0-4.03-3.262-7.296-7.286-7.296-4.024 0-7.286 3.267-7.286 7.296 0 4.03 3.262 7.297 7.286 7.297Z"
            />
            <path
                fill="#C6C6C6"
                d="M87.33 56.243c4.023 0 7.285-3.267 7.285-7.297 0-4.03-3.262-7.296-7.286-7.296-4.024 0-7.286 3.267-7.286 7.296 0 4.03 3.262 7.297 7.286 7.297Z"
            />
            <path
                fill="#777"
                d="M122.473 56.243c4.023 0 7.285-3.267 7.285-7.297 0-4.03-3.262-7.296-7.285-7.296-4.024 0-7.286 3.267-7.286 7.296 0 4.03 3.262 7.297 7.286 7.297Z"
            />
            <path
                fill="#C6C6C6"
                d="M122.473 39.158c4.023 0 7.285-3.267 7.285-7.297 0-4.03-3.262-7.296-7.285-7.296-4.024 0-7.286 3.266-7.286 7.296s3.262 7.297 7.286 7.297ZM97.26 39.158c4.024 0 7.286-3.267 7.286-7.297 0-4.03-3.262-7.296-7.286-7.296-4.024 0-7.286 3.266-7.286 7.296s3.262 7.297 7.286 7.297Z"
            />
            <path
                fill="#777"
                d="M79.312 39.158c4.024 0 7.286-3.267 7.286-7.297 0-4.03-3.262-7.296-7.286-7.296-4.024 0-7.286 3.266-7.286 7.296s3.262 7.297 7.286 7.297Z"
            />
            <path
                fill="#474747"
                d="M131.944 40.338H59.417a.674.674 0 0 1 0-1.35h72.527a.675.675 0 0 1 0 1.35ZM132.681 57.371H59.417a.674.674 0 0 1 0-1.35h73.264a.675.675 0 0 1 0 1.35Z"
            />
            <path
                fill="#C6C6C6"
                d="M56.712 58.014c-.804 0-1.832-.297-2.88-1.4-.452-.478-.897-1.068-1.41-1.752-2.47-3.29-4.803-5.92-8.362-4.797a2.97 2.97 0 0 1-1.782-5.663c2.696-.85 5.376-.696 7.854.44.084-.664.39-1.304.916-1.797a2.96 2.96 0 0 1 4.19.142c4.17 4.466 6.041 8.59 5.272 11.61-.38 1.482-1.386 2.595-2.765 3.05-.265.089-.62.168-1.035.168l.002-.001Z"
            />
            <path
                fill="#474747"
                d="M52.758 119.435a4.009 4.009 0 0 1-3.995-3.752L46.36 78.776a175.546 175.546 0 0 1-8.705 28.989l-2.96 7.444a4.005 4.005 0 0 1-5.205 2.245 4.015 4.015 0 0 1-2.241-5.213l2.96-7.444a167.417 167.417 0 0 0 8.683-29.524l2.649-13.501.036-.137c.486-1.799 2.112-3.703 5.218-3.703h.114c1.126.016 2.23.278 3.036.47.19.044.452.106.54.122-.002 0-.083-.013-.214-.02l-.004.582a5.496 5.496 0 0 1 3.053 3.833l.06.285 3.38 51.959a4.011 4.011 0 0 1-4.002 4.275l-.002-.003Z"
            />
            <path
                fill="#C6C6C6"
                d="M51.63 54.073c1.132 7.267-2.928 8.193-4.622 8.206-4.399.035-6.536-.75-6.536-7.675 0-6.924 2.77-10.004 6.154-10.004 3.382 0 4.118 3.783 5.004 9.473Z"
            />
            <path
                fill="#474747"
                d="M36.775 119.26a.66.66 0 0 1-.17-.022l-5.8-1.448a.704.704 0 0 1 .34-1.366l5.8 1.448a.704.704 0 0 1-.168 1.387l-.002.001ZM58.268 119.434h-5.34a.702.702 0 0 1 0-1.407h5.34a.703.703 0 0 1 0 1.407Z"
            />
            <path
                fill="#B26E3D"
                d="M43.201 41.04a1.513 1.513 0 0 0 1.82 1.121 1.514 1.514 0 0 0-.701-2.945 1.514 1.514 0 0 0-1.12 1.824h.001Z"
            />
            <path
                fill="#474747"
                d="M42.386 39.088a.638.638 0 1 0 0-.001v.001ZM44.32 39.217a1.52 1.52 0 0 1 1.384.369l-2.482 1.527c-.007-.025-.015-.048-.02-.072a1.518 1.518 0 0 1 1.12-1.823h-.001Z"
            />
            <path
                fill="#C6C6C6"
                d="M40.098 70.132a2.987 2.987 0 0 1-1.84-.63c-.466-.362-4.602-3.656-6.032-7.815-.968-2.815-.673-5.883.853-8.874 2.138-4.19 6.365-7.592 9.219-8.46a3.005 3.005 0 0 1 3.75 2.006 3.011 3.011 0 0 1-2.004 3.755c-.926.282-4.052 2.38-5.613 5.438-.776 1.523-.951 2.926-.523 4.176.718 2.09 3.155 4.331 4.032 5.017a3.01 3.01 0 0 1-1.843 5.388l.001-.001Z"
            />
            <path
                fill="#B26E3D"
                d="M42.72 71.144c-.367 0-.735-.137-1.02-.413-.301-.293-1.675-1.588-1.688-1.602a1.467 1.467 0 0 1-.062-2.072 1.462 1.462 0 0 1 2.069-.062c.056.054 1.403 1.324 1.72 1.632a1.466 1.466 0 0 1-1.02 2.517ZM52.098 45.1a1.463 1.463 0 0 1-1.33-.852c-.21-.458-.472-.558-.532-.575a1.467 1.467 0 0 1 .33-2.913c.195.018 1.924.226 2.86 2.26A1.467 1.467 0 0 1 52.1 45.1h-.001Z"
            />
            <path fill="#474747" d="M81.778 72.445H71.72v.81h10.058v-.81Z" />
            <path
                fill="#00D639"
                d="M89.393 100.674c4.024 0 7.286-3.267 7.286-7.297 0-4.03-3.262-7.297-7.286-7.297-4.023 0-7.286 3.267-7.286 7.297 0 4.03 3.263 7.297 7.286 7.297Z"
            />
            <path
                fill="#474747"
                d="M140.912 101.079H82.104a.404.404 0 0 1-.39-.3l-7.448-27.823a.404.404 0 0 1 .39-.51h66.255c.224 0 .404.182.404.406v27.823c0 .224-.18.405-.404.405l.001-.001Zm-58.497-.81h58.093V73.255H75.185l7.23 27.014Z"
            />
            <path
                fill="#474747"
                d="M140.913 116.976H86.426a.405.405 0 0 1-.39-.298l-4.32-15.898.781-.213 4.239 15.599h54.178v.81h-.001ZM141.316 72.85h-.808v27.824h.808V72.85ZM131.152 72.85h-.808v27.824h.808V72.85ZM120.987 72.85h-.808v27.824h.808V72.85ZM110.822 72.85h-.808v27.824h.808V72.85Z"
            />
            <path
                fill="#474747"
                d="M140.913 91.061H79.922v.81h60.991v-.81ZM140.912 81.653H77.1v.81h63.812v-.81Z"
            />
            <path
                fill="#474747"
                d="M100.655 72.85h-.808v27.824h.808V72.85ZM90.491 72.85h-.808v27.824h.808V72.85ZM80.326 72.85h-.808v19.663h.808V72.85Z"
            />
            <path
                fill="#303030"
                d="M89.514 118.731c1.19 0 2.156-.967 2.156-2.16a2.157 2.157 0 1 0-4.313 0c0 1.193.966 2.16 2.157 2.16ZM137.577 118.731a2.159 2.159 0 0 0 2.157-2.16 2.158 2.158 0 1 0-4.313 0c0 1.193.966 2.16 2.156 2.16ZM71.228 74.473c.895 0 1.62-.727 1.62-1.623a1.621 1.621 0 1 0-3.24 0c0 .896.726 1.623 1.62 1.623Z"
            />
        </g>
    </svg>
));

export default Orders;
