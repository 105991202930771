import { memo } from 'react';
import DanaBoxOption from './DanaBoxOption';

const OPTIONS = [
    {
        id: 59,
        label: 'label_option_fix_grammar_and_format',
        trackingLabel: 'spellingFormat',
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none">
                <path
                    fill="currentColor"
                    d="m11.1 19-4.25-4.25 1.4-1.4 2.85 2.85 5.65-5.65 1.4 1.4L11.1 19ZM0 13 4.85 0H7.2l4.85 13h-2.3L8.6 9.7H3.35L2.2 13H0Zm4.05-5.2h3.9l-1.9-5.4h-.1l-1.9 5.4Z"
                />
            </svg>
        ),
    },
    {
        id: 52,
        label: 'label_option_profesional',
        trackingLabel: 'toneProfesional',
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none">
                <path
                    fill="currentColor"
                    d="M3 16.5c-.413 0-.766-.147-1.06-.44A1.445 1.445 0 0 1 1.5 15V6.75c0-.412.147-.766.44-1.06.294-.293.647-.44 1.06-.44h3v-1.5c0-.413.147-.766.44-1.06.294-.293.647-.44 1.06-.44h3c.412 0 .766.147 1.06.44.293.294.44.647.44 1.06v1.5h3c.412 0 .766.147 1.06.44.293.294.44.647.44 1.06V15c0 .412-.147.766-.44 1.06-.294.293-.647.44-1.06.44H3ZM3 15h12V6.75H3V15Zm4.5-9.75h3v-1.5h-3v1.5Z"
                />
            </svg>
        ),
    },
    {
        id: 54,
        label: 'label_option_helpful',
        trackingLabel: 'toneHelpful',
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none">
                <path
                    fill="currentColor"
                    d="M9 13.125c.85 0 1.622-.24 2.316-.722a4.113 4.113 0 0 0 1.509-1.903h-7.65a4.113 4.113 0 0 0 1.51 1.903A3.97 3.97 0 0 0 9 13.125ZM5.85 8.25l.825-.787.788.787.787-.787L6.675 5.85 5.063 7.463l.787.787Zm4.688 0 .787-.787.825.787.787-.787-1.612-1.613L9.75 7.463l.787.787ZM9 16.5a7.304 7.304 0 0 1-2.925-.59 7.575 7.575 0 0 1-2.381-1.604 7.574 7.574 0 0 1-1.603-2.381A7.303 7.303 0 0 1 1.5 9c0-1.037.197-2.013.59-2.925a7.574 7.574 0 0 1 1.604-2.381A7.574 7.574 0 0 1 6.075 2.09 7.303 7.303 0 0 1 9 1.5c1.037 0 2.012.197 2.925.59a7.574 7.574 0 0 1 2.381 1.604 7.575 7.575 0 0 1 1.603 2.381A7.3 7.3 0 0 1 16.5 9a7.304 7.304 0 0 1-.59 2.925 7.575 7.575 0 0 1-1.604 2.381 7.575 7.575 0 0 1-2.381 1.603A7.304 7.304 0 0 1 9 16.5ZM9 15c1.675 0 3.094-.581 4.256-1.744C14.42 12.094 15 10.675 15 9c0-1.675-.581-3.094-1.744-4.256C12.094 3.58 10.675 3 9 3c-1.675 0-3.094.581-4.256 1.744C3.58 5.906 3 7.325 3 9c0 1.675.581 3.094 1.744 4.256C5.906 14.42 7.325 15 9 15Z"
                />
            </svg>
        ),
    },
    {
        id: 56,
        label: 'label_option_friendly',
        trackingLabel: 'toneFriendly',
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none">
                <path
                    fill="currentColor"
                    d="m9 15.75-1.088-.975a84.806 84.806 0 0 1-3.13-2.944c-.826-.825-1.482-1.565-1.97-2.222-.487-.656-.828-1.259-1.021-1.809A5.044 5.044 0 0 1 1.5 6.112c0-1.175.394-2.156 1.181-2.943.788-.788 1.769-1.181 2.944-1.181.65 0 1.269.137 1.856.412A4.447 4.447 0 0 1 9 3.563c.425-.5.931-.888 1.519-1.163a4.327 4.327 0 0 1 1.856-.412c1.175 0 2.156.393 2.944 1.18.787.788 1.181 1.77 1.181 2.944 0 .575-.097 1.138-.29 1.688-.194.55-.535 1.153-1.023 1.81-.487.656-1.143 1.396-1.968 2.221a84.813 84.813 0 0 1-3.131 2.944L9 15.75Zm0-2.025a82.051 82.051 0 0 0 2.963-2.766c.775-.768 1.387-1.437 1.837-2.006.45-.569.762-1.075.938-1.519.175-.443.262-.884.262-1.322 0-.75-.25-1.375-.75-1.875s-1.125-.75-1.875-.75c-.588 0-1.131.166-1.631.497-.5.332-.844.753-1.031 1.266H8.287c-.188-.513-.532-.934-1.032-1.266a2.895 2.895 0 0 0-1.631-.497c-.75 0-1.375.25-1.875.75S3 5.362 3 6.112c0 .438.087.879.263 1.322.175.444.487.95.937 1.52.45.568 1.063 1.237 1.837 2.005.776.77 1.763 1.691 2.963 2.766Z"
                />
            </svg>
        ),
    },
    {
        id: 50,
        label: 'label_option_expand_email',
        trackingLabel: 'expandEmail',
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none">
                <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M3 14.25h4.5v-1.5H3v1.5Zm12-10.5H3v1.5h12v-1.5Zm-2.25 4.5H3v1.5h9.938c.824 0 1.5.675 1.5 1.5s-.676 1.5-1.5 1.5H11.25v-1.5L9 13.5l2.25 2.25v-1.5h1.5a3 3 0 1 0 0-6Z"
                    clipRule="evenodd"
                />
            </svg>
        ),
        limit: 50,
    },
    {
        id: 51,
        label: 'label_option_summarize_email',
        trackingLabel: 'summarizeEmail',
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none">
                <path
                    fill="currentColor"
                    d="M6 6.75c.213 0 .39-.072.534-.216A.726.726 0 0 0 6.75 6a.726.726 0 0 0-.216-.534A.726.726 0 0 0 6 5.25a.726.726 0 0 0-.534.216A.726.726 0 0 0 5.25 6c0 .213.072.39.216.534A.726.726 0 0 0 6 6.75Zm0 3c.213 0 .39-.072.534-.216A.726.726 0 0 0 6.75 9a.726.726 0 0 0-.216-.534A.726.726 0 0 0 6 8.25a.726.726 0 0 0-.534.216A.726.726 0 0 0 5.25 9c0 .213.072.39.216.534A.726.726 0 0 0 6 9.75Zm0 3c.213 0 .39-.072.534-.216A.726.726 0 0 0 6.75 12a.726.726 0 0 0-.216-.534A.726.726 0 0 0 6 11.25a.726.726 0 0 0-.534.216.726.726 0 0 0-.216.534c0 .213.072.39.216.534A.726.726 0 0 0 6 12.75Zm-2.25 3c-.413 0-.766-.147-1.06-.44a1.445 1.445 0 0 1-.44-1.06V3.75c0-.413.147-.766.44-1.06.294-.293.647-.44 1.06-.44H12L15.75 6v8.25c0 .412-.147.766-.44 1.06a1.45 1.45 0 0 1-1.06.44H3.75Zm0-1.5h10.5v-7.5h-3v-3h-7.5v10.5Z"
                />
            </svg>
        ),
        limit: 50,
    },
];

const DanaBoxOptions = ({ textLength, onClick }) => {
    return (
        <div className="fm-ee__dana-box__options">
            {OPTIONS.map((option) => (
                <DanaBoxOption
                    key={option.id}
                    textLength={textLength}
                    option={option}
                    onClick={onClick}
                />
            ))}
        </div>
    );
};

export default memo(DanaBoxOptions);
