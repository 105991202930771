import React, { forwardRef, memo } from 'react';
import { Link, Text, useTheme } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import FeedbackIcon from 'components/SvgIcons/FeedbackIcon';

const FeedbackBanner = forwardRef((props, ref) => {
    const theme = useTheme();

    return (
        <div className="fm-timeline-feedback" ref={ref} {...props}>
            <FeedbackIcon className="fm-timeline-feedback__pic" />
            <div className="fm-timeline-feedback__content">
                <Text color={theme.colors.utility.textSecondary}>
                    {getLiteral('label_send_us_feedback_conversations_in_activities')}
                </Text>
                <Link
                    href={getLiteral('label_send_us_feedback_form_conversations_in_activities')}
                    target="_blank"
                >
                    {getLiteral('action_send_us_feedback')}
                </Link>
            </div>
        </div>
    );
});

export default memo(FeedbackBanner);
