import { memo, useContext, useCallback, useMemo } from 'react';
import classnames from 'classnames';
import { Avatar, Text, Icon } from 'hoi-poi-ui';
import { isEmptyObject } from 'utils/objects';
import { findParentById, findNodeByType } from '../../utils';
import { AutomationBuilderCanvasContext } from '../../AutomationBuilderCanvasContext';
import './styles.scss';

const AutomationBuilderBox = memo(
    ({
        id,
        className,
        title,
        icon,
        emptyPlaceholder,
        textValue,
        children,
        onDelete,
        deleteTooltip,
    }) => {
        const { state, dispatch, data } = useContext(AutomationBuilderCanvasContext);

        const onSelect = useCallback(
            (e) => {
                e.stopPropagation();
                dispatch({
                    type: state.selected ? 'nextSelect' : 'select',
                    node: findNodeByType(data?.scene, id),
                });
            },
            [data?.scene, dispatch, id, state.selected],
        );

        const isDisabled = useMemo(
            () =>
                isEmptyObject(findParentById(data.scene, 'entity_crud')?.parameters || {}) &&
                id !== 'entity_crud',
            [data?.scene, id],
        );

        const showPlaceholder = !textValue && emptyPlaceholder && !children;

        return (
            <div
                className={classnames('fm-automation-builder__box', className, {
                    'fm-automation-builder__box--selected': state.selected?.name === id,
                    'fm-automation-builder__box--disabled': isDisabled,
                })}
                onClick={isDisabled || state.selected?.name === id ? undefined : onSelect}
            >
                <div className="fm-automation-builder__box__header">
                    <div className="fm-automation-builder__box__header-title">
                        <Avatar icon={icon} size="big" />
                        <Text>{title}</Text>
                    </div>
                    {onDelete && (
                        <Icon
                            className="fm-automation-builder__box__header-icon"
                            tooltip={deleteTooltip}
                            onClick={onDelete}
                            name="delete"
                        />
                    )}
                </div>
                <div className="fm-automation-builder__box__content">
                    {textValue && (
                        <Text type="subtitle" bold>
                            {textValue}
                        </Text>
                    )}
                    {showPlaceholder && (
                        <Text type="subtitle" bold>
                            {emptyPlaceholder}
                        </Text>
                    )}
                    {children}
                </div>
            </div>
        );
    },
);

export default AutomationBuilderBox;
