import { memo } from 'react';
import { Text, useTheme } from 'hoi-poi-ui';

const DetailWhatsapp = memo(({ owner, date, message }) => {
    const theme = useTheme();

    const comp = (
        <div className="fm-activities-whatsapp__container">
            <div className="fm-activities-whatsapp__comment__header">
                <Text type="subtitle">{owner}</Text>
                <Text type="captionMedium" color={theme.colors.utility.textSecondary}>
                    {date}
                </Text>
            </div>
            <Text color={theme.colors.utility.textSecondary}>{message}</Text>
        </div>
    );
    return comp;
});

export default DetailWhatsapp;
