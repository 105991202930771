import React, { memo, useState, useMemo } from 'react';
import { Text, Icon, useTheme, SelectWrapper, Select, Chip, Tooltip } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import './styles.scss';

const DashboardWidgetHeader = memo(
    ({
        title,
        // Actions
        onViewMore,
        onAdd,
        addTooltip,
        // Filter
        filterLabel,
        filterOptions,
        filterDefaultValue,
        filterValue,
        onChangeFilter,
        filterIsMulti,
    }) => {
        const theme = useTheme();
        const [isSelectWrapperOpen, setIsSelectWrapperOpen] = useState(false);
        const [filter, setFilter] = useState(filterDefaultValue);

        const widgetFilter = useMemo(() => {
            const defFilters = filterValue || filter;
            if (!filterOptions || !onChangeFilter) return null;
            let inputProps = {
                options: filterOptions || undefined,
                placeholder: getLiteral('label_selectone'),
            };

            let chipLabel = filterLabel;
            if (
                (Array.isArray(defFilters) && defFilters?.length === 1) ||
                (!Array.isArray(defFilters) && defFilters)
            ) {
                chipLabel = defFilters?.[0]?.label || defFilters?.label;
            } else if (Array.isArray(defFilters) && defFilters?.length) {
                chipLabel = `${filterLabel} (${defFilters?.length})`;
            }

            return (
                <SelectWrapper
                    className="fm-dashboard-widget__filter"
                    classes={{ Popover: 'fm-dashboard-widget__filter__popover' }}
                    overlayInnerStyle={{
                        width: '300px',
                        maxHeight: window.innerHeight - 50,
                        overflowY: 'auto',
                    }}
                    getIsOpen={(isOpen) => setIsSelectWrapperOpen(isOpen)}
                    overrides={{
                        Popover: {
                            root: {
                                visible: isSelectWrapperOpen,
                            },
                        },
                    }}
                    customOptions={
                        <Select
                            {...inputProps}
                            value={defFilters}
                            onChange={(value) => {
                                // controlled mode
                                if (!filterValue) setFilter(value);
                                onChangeFilter(value);
                            }}
                            isFullWidth
                            menuPosition="absolute"
                            useMenuPortal={false}
                            forceMenuIsOpen={isSelectWrapperOpen}
                            forceStartFocused={isSelectWrapperOpen}
                            isMulti={filterIsMulti}
                        />
                    }
                >
                    <Chip
                        isUnfolded={true}
                        isFilled={filterIsMulti ? !!defFilters?.length : !!defFilters}
                    >
                        {chipLabel}
                    </Chip>
                </SelectWrapper>
            );
        }, [
            filterValue,
            filter,
            filterOptions,
            onChangeFilter,
            filterLabel,
            isSelectWrapperOpen,
            filterIsMulti,
        ]);

        const showDivider = (onViewMore || onAdd) && !!widgetFilter;
        return (
            <div className="fm-dashboard-widget__header">
                <Text type="subtitle1">{title}</Text>
                <div className="fm-dashboard-widget__header-actions">
                    <div className="fm-dashboard-widget__header-actions-buttons">
                        {onViewMore && (
                            <Tooltip
                                placement="top"
                                content={getLiteral('action_showmore_widgets')}
                            >
                                <div
                                    className="fm-button__view-details-widget"
                                    onClick={onViewMore}
                                >
                                    <div className="fm-button__view-details-widget__icon">
                                        <Icon name="launch" color={theme.colors.actionMinor[500]} />
                                    </div>
                                </div>
                            </Tooltip>
                        )}
                        {onAdd && (
                            <Tooltip content={addTooltip}>
                                <span className="fm-button__add__icon" onClick={onAdd}>
                                    <Icon name="addCircle" color="currentColor" size="large" />
                                </span>
                            </Tooltip>
                        )}
                    </div>

                    {showDivider && <div className="fm-dashboard-widget__divider"></div>}
                    {widgetFilter}
                </div>
            </div>
        );
    },
);

export default DashboardWidgetHeader;
