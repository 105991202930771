import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Icon, useTheme } from 'hoi-poi-ui';

import './styles.scss';

const propTypes = {
    value: PropTypes.bool,
    className: PropTypes.string,
    onChange: PropTypes.func,
    isChecked: PropTypes.bool,
};

const TaskCheckbox = memo(
    ({
        className,
        color,
        onChange,
        defaultValue,
        checkedColor,
        uncheckedColor,
        checkType,
        isChecked,
        size,
    }) => {
        const theme = useTheme();

        const rootClasses = classnames('fm-task-checkbox', className, {
            'fm-task-checkbox--checked': isChecked,
            'fm-task-checkbox--unchecked': !isChecked,
        });

        const onCheck = useCallback(
            (e) => {
                e.preventDefault();
                e.stopPropagation();

                onChange && onChange(!isChecked);
                return false;
            },
            [onChange, isChecked],
        );

        return (
            <div className={rootClasses} onClick={onCheck}>
                {isChecked && (
                    <Icon
                        name={checkType || 'taskCheckedStroke'}
                        color={checkedColor || theme.colors.actionMajor[500]}
                        size={size}
                    />
                )}
                {!isChecked && (
                    <Icon name="taskUnchecked" color={uncheckedColor || ''} size={size} />
                )}
            </div>
        );
    },
);

TaskCheckbox.propTypes = propTypes;

export default TaskCheckbox;
