import React, { memo, useCallback, useEffect, useState, useRef } from 'react';
import { Select } from 'hoi-poi-ui';
import { isMobile } from 'utils/browser';
import { getEntityValue } from 'services/Fuzzy';
import { getEntityCustomization } from 'actions/Fuzzy';

function isEqual(innerValue, externalValue, isMulti) {
    if (isMulti) {
        return innerValue?.every((v, idx) => externalValue?.[idx] === v?.value);
    }
    return innerValue?.value === externalValue;
}

const SelectHoi = memo(
    ({ usePlainValue, options, value, onChange, loadOptions, list, isMulti, ...props }) => {
        const [innerValue, setInnerValue] = useState(null);
        const valueRef = useRef(null);

        useEffect(() => {
            if (!usePlainValue) return;
            if (isEqual(valueRef.current, value, isMulti)) return;
            if (!isEqual(valueRef.current, value, isMulti) && !value) {
                valueRef.current = null;
                setInnerValue();
            } else if (list) {
                getEntityValue(list, isMulti ? value : [value]).then((result) => {
                    if (!result?.data) return;
                    const finalValue = isMulti
                        ? value?.map((v) => ({
                              label: result?.data?.[v]?.description,
                              value: String(result?.data?.[v]?.id),
                              ...getEntityCustomization(result?.data?.[v], list),
                          }))
                        : {
                              label: result?.data?.[value]?.description,
                              value: String(result?.data?.[value]?.id),
                              ...getEntityCustomization(result?.data?.[value], list),
                          };
                    valueRef.current = finalValue;
                    setInnerValue(finalValue);
                });
            } else if (options?.length) {
                let foundOption;
                options?.find((option) => {
                    if (option.value && option.value === value) {
                        foundOption = option;
                        return true;
                    }

                    if (option.options) {
                        foundOption = option.options.find((o) => o.value === value);
                        return true;
                    }

                    return false;
                });

                valueRef.current = foundOption;
                setInnerValue(foundOption);
            }
        }, [isMulti, list, options, usePlainValue, value]);

        const onChangeValue = useCallback(
            (value) => {
                valueRef.current = value;
                setInnerValue(value);
                onChange(
                    usePlainValue
                        ? isMulti
                            ? value?.map((v) => v.value) || []
                            : value?.value
                        : value,
                    value,
                );
            },
            [isMulti, onChange, usePlainValue],
        );

        return (
            <Select
                isSearchable={!isMobile}
                options={options}
                value={usePlainValue ? innerValue : value}
                onChange={onChangeValue}
                loadOptions={loadOptions}
                isMulti={isMulti}
                {...props}
            />
        );
    },
);

export default SelectHoi;
