import React, { Fragment, memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FuzzySearchActions } from 'actions';
import {
    Input,
    Multiplier,
    RadioGroup,
    Section,
    Select,
    Text,
    Textarea,
    useTheme,
} from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';

import './SignForm_styles.scss';

const FIELD_MAP = {
    multiplier: Multiplier,
    radioGroup: RadioGroup,
    select: Select,
    text: Input,
    textarea: Textarea,
};

const mapStateToProps = (state) => ({
    userData: state.config.userData,
});

const mapDispatchToProps = (dispatch) => ({
    clearFuzzySearch: bindActionCreators(FuzzySearchActions, dispatch).clearFuzzySearch,
    getFuzzySearch: bindActionCreators(FuzzySearchActions, dispatch).getFuzzySearch,
});

const SignForm = memo(
    ({ clearFuzzySearch, getFuzzySearch, form, formState, errors, onBlur, onChange, userData }) => {
        const { subject, body, listEmail, mode, signers } = formState;

        const theme = useTheme();

        const loadContacts = useCallback(
            (text) => {
                const defaultContact = {
                    label: userData.nic,
                    value: userData.nic,
                    subLabel: userData.email,
                    email: userData.email,
                };

                return new Promise((resolve, reject) => {
                    getFuzzySearch('contacts', 'contacts', text)
                        .then((contacts) => {
                            let newContacts = contacts.reduce(
                                (arr, contact) => [
                                    ...arr,
                                    {
                                        label: contact.MatchingInfo,
                                        value: contact.MatchingInfo,
                                        subLabel: contact.ExtraInfo,
                                        email: contact.ExtraInfo2 ? contact.ExtraInfo2 : null,
                                    },
                                ],
                                [],
                            );
                            if (defaultContact.value.indexOf(text) !== -1)
                                newContacts = [defaultContact, ...newContacts];
                            if (newContacts.length > 0) {
                                resolve(newContacts);
                            } else {
                                resolve([
                                    {
                                        label: text,
                                        value: text,
                                    },
                                ]);
                            }
                        })
                        .catch(reject);
                });
            },
            [getFuzzySearch, userData.email, userData.nic],
        );

        const sectionProps = useMemo(
            () => ({
                className: 'fm-sign-form-container__section',
                isExpandable: false,
                overrides: {
                    root: {
                        style: {
                            borderRadius: 6,
                            padding: 16,
                        },
                    },
                    header: {
                        style: {
                            borderBottom: `1px solid ${theme.colors.grey[100]}`,
                            marginBottom: 8,
                        },
                    },
                },
            }),
            [theme.colors.grey],
        );

        const modeOptions = useMemo(
            () =>
                form.options &&
                form.options
                    .reduce((arr, option) => {
                        const newOption = {
                            label:
                                option === 'iframe'
                                    ? getLiteral('label_webview_signature')
                                    : getLiteral('label_email_signature'),
                            value: option,
                        };
                        return [...arr, newOption];
                    }, [])
                    .reverse(),
            [form.options],
        );

        const FormHint = useMemo(
            () => (
                <div className="fm-sign-form-popover__content">
                    <Text type="h6">{getLiteral('label_signatures_type_signature')}</Text>
                    <Text type="body">
                        <strong>{getLiteral('label_webview_signature')}</strong>
                        <span>{getLiteral('label_webview_signature_description')}</span>
                    </Text>
                    <Text type="body">
                        <strong>{getLiteral('label_email_signature')}</strong>
                        <span>{getLiteral('label_email_signature_description')}</span>
                    </Text>
                </div>
            ),
            [],
        );

        const handleChange = useCallback(
            (field, value, newValue, index) => {
                onChange(field, value, newValue, index);
                if (field === 'signers') clearFuzzySearch('contacts', 'contacts');
            },
            [clearFuzzySearch, onChange],
        );

        const fieldProps = useMemo(
            () => ({
                labelMode: 'vertical',
                className: 'fm-sign-form-container__field',
            }),
            [],
        );

        const formSchema = useMemo(
            () => [
                {
                    fields: [
                        {
                            ...fieldProps,
                            label: getLiteral('label_subject'),
                            isFullWidth: true,
                            name: 'subject',
                            type: 'text',
                            value: subject,
                            placeholder: getLiteral('placeholder_text_field'),
                            hint: getLiteral('label_signature_subject_explanation'),
                        },
                        {
                            ...fieldProps,
                            label: getLiteral('label_body'),
                            isFullWidth: true,
                            name: 'body',
                            type: 'textarea',
                            value: body,
                            placeholder: getLiteral('placeholder_text_field'),
                            hint: getLiteral('label_signature_body_explanation'),
                        },
                        {
                            ...fieldProps,
                            name: 'listEmail',
                            type: 'multiplier',
                            separator: false,
                            buttonLabel: getLiteral('action_add'),
                            labelMode: 'vertical',
                            value: listEmail,
                            error: errors.touched.listEmail && errors.listEmail,
                            schema: {
                                label: getLiteral('label_signatures_user_cc'),
                                name: 'email',
                                type: 'text',
                                hint: getLiteral('label_signature_cc_explanation'),
                                placeholder: getLiteral('placeholder_text_field'),
                                attrs: {
                                    type: 'email',
                                },
                            },
                        },
                        {
                            ...fieldProps,
                            name: 'mode',
                            type: 'radioGroup',
                            label: getLiteral('label_signature_type'),
                            hint: FormHint,
                            orientation: 'horizontal',
                            options: modeOptions,
                            value: mode,
                        },
                    ],
                    title: getLiteral('label_info'),
                },
                {
                    fields: [
                        {
                            ...fieldProps,
                            name: 'signers',
                            type: 'multiplier',
                            separator: false,
                            buttonLabel: getLiteral('action_add_signer'),
                            labelMode: 'vertical',
                            orientation: 'horizontal',
                            value: signers,
                            error: errors.touched.signers && errors.signers,
                            schema: [
                                {
                                    fields: [
                                        {
                                            label: getLiteral(
                                                form.signers[0].getDescription('fullname'),
                                            ),
                                            name: 'fullname',
                                            type: 'select',
                                            placeholder: getLiteral('action_search_contact'),
                                            loadOptions: loadContacts,
                                            defaultSearch: userData.nic,
                                            isFuzzy: true,
                                            isRequired: true,
                                        },
                                        {
                                            label: getLiteral(
                                                form.signers[0].getDescription('email'),
                                            ),
                                            name: 'email',
                                            type: 'text',
                                            placeholder: getLiteral('placeholder_text_field'),
                                            attrs: {
                                                type: 'email',
                                            },
                                            isRequired: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                    title: getLiteral('action_add_signer'),
                },
            ],
            [
                FormHint,
                body,
                errors.listEmail,
                errors.signers,
                errors.touched.listEmail,
                errors.touched.signers,
                fieldProps,
                form.signers,
                listEmail,
                loadContacts,
                mode,
                modeOptions,
                signers,
                subject,
                userData.nic,
            ],
        );

        return (
            <Fragment>
                {form &&
                    formSchema.map(({ title, fields }, i) => (
                        <Section {...sectionProps} key={`section-${i}`} title={title}>
                            {fields.map(({ name, type, ...otherFieldProps }, j) => {
                                const Field = FIELD_MAP[type];
                                return (
                                    <Field
                                        key={`field-${i}-${j}`}
                                        name={name}
                                        onChange={(value, newValue, index) =>
                                            handleChange(name, value, newValue, index)
                                        }
                                        onBlur={(value) => onBlur(name, value)}
                                        {...otherFieldProps}
                                    />
                                );
                            })}
                        </Section>
                    ))}
            </Fragment>
        );
    },
);

SignForm.propTypes = {
    form: PropTypes.object,
    formState: PropTypes.shape({
        subject: PropTypes.string,
        body: PropTypes.string,
        listEmail: PropTypes.arrayOf(PropTypes.string),
        mode: PropTypes.string,
        signers: PropTypes.arrayOf(PropTypes.object),
    }),
    errors: PropTypes.object,
    onChange: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignForm);
