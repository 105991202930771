import React, { memo, useCallback, useMemo, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTheme, Text } from 'hoi-poi-ui';
import { OPPORTUNITIES } from 'constants/Entities';
import { getNumberAsCurrency } from 'utils/currency';
import { ensureRoute } from 'utils/routes';
import { formatDateWithTimeOrNot, getDateMomentFromBackendTimestamp } from 'utils/dates';
import Context from 'managers/Context';
import Loader from 'components/Loader';
import CircularPercentage from 'components/CircularPercentage';
import { UserChip, CompanyChip } from 'containers/components/chips';
import OpportunityIcon from '../icons/OpportunityIcon';
import OpportunityInfoWindowRow from './OpportunityInfoWindowRow';

import './styles.scss';

const propTypes = {
    loading: PropTypes.bool,
    data: PropTypes.object,
    standardFieldsConfiguration: PropTypes.object,
};

const mapStateToProps = (state) => {
    const mapState = state.entityMap;
    const entityMapState = (mapState && mapState[OPPORTUNITIES.entity]) || {};
    const infoWindowState = entityMapState.infoWindow || null;
    return {
        standardFieldsConfiguration:
            state.config.standardFieldsSchemaMap[OPPORTUNITIES.extraFieldName] || null,
        loading: infoWindowState.loading || false,
        data: infoWindowState.data || null,
    };
};

const OpportunityInfoWindow = ({ loading, data, standardFieldsConfiguration }) => {
    const theme = useTheme();

    const openOpportunity = useCallback(() => {
        if (!data.Id) return;
        ensureRoute(`/opportunities/map/${data.Id}`);
    }, [data]);

    const { icon, percentageValue, percentageClass } = useMemo(() => {
        if (!data) return { icon: null, percentageValue: null, percentageClass: null };
        let won = data.IsWin === '1';
        let lost = data.IsLost === '1';
        let days = Context.utilsFormats.getDifferentDay(data.ExpectedClose);

        let icon = (
            <OpportunityIcon
                won={won}
                lost={lost}
                expirationDays={days}
                className={'opportunity-icon'}
            />
        );

        let percentageValue = data.Probability ? parseInt(data.Probability, 10) * 10 : 0;
        let percentageClass = 'circle-red';
        if (percentageValue >= 25 && percentageValue < 75) {
            percentageClass = 'circle-yellow';
        } else if (percentageValue >= 75) {
            percentageClass = 'circle-green';
        }

        return {
            icon,
            percentageValue,
            percentageClass,
        };
    }, [data]);

    if (!loading && !data) return null;

    const showReference =
        standardFieldsConfiguration && standardFieldsConfiguration.referencia
            ? !standardFieldsConfiguration.referencia.isHidden
            : true;
    const showState =
        standardFieldsConfiguration && standardFieldsConfiguration.idestadoobra
            ? !standardFieldsConfiguration.idestadoobra.isHidden
            : true;
    const showValue =
        standardFieldsConfiguration && standardFieldsConfiguration.dblvalor
            ? !standardFieldsConfiguration.dblvalor.isHidden
            : true;

    const showRatio =
        standardFieldsConfiguration && standardFieldsConfiguration.ratio
            ? !standardFieldsConfiguration.ratio.isHidden
            : true;
    const showForecastDate =
        standardFieldsConfiguration && standardFieldsConfiguration.dtprevfechaventa
            ? !standardFieldsConfiguration.dtprevfechaventa.isHidden
            : true;
    const showOwner =
        standardFieldsConfiguration && standardFieldsConfiguration.idcomercial
            ? !standardFieldsConfiguration.idcomercial.isHidden
            : true;
    const showCompany =
        standardFieldsConfiguration && standardFieldsConfiguration.idsucursal
            ? !standardFieldsConfiguration.idsucursal.isHidden
            : true;

    return (
        <div className="opportunity-info-window">
            {loading && (
                <div className="opportunity-info-window__loader">
                    <Loader type="small" />
                </div>
            )}
            {!loading && (
                <Fragment>
                    <div className="opportunity-info-window-block opportunity-info-window__header">
                        <div className="opportunity-info-window__header-column">
                            <div className="opportunity-info-window__icon">{icon}</div>
                            <div className="opportunity-info-window__basic-info">
                                {showReference && (
                                    <div
                                        className="opportunity-info-window__description"
                                        onClick={openOpportunity}
                                    >
                                        {data.Reference || '-'}
                                    </div>
                                )}
                                {showState && (
                                    <Text
                                        type="caption"
                                        color={theme.colors.utility.textSecondary}
                                        className="opportunity-info-window__state"
                                    >
                                        {data.OpportunityType}
                                    </Text>
                                )}
                            </div>
                        </div>
                        <div className="opportunity-info-window__header-column">
                            {showValue && (
                                <Text
                                    type="caption"
                                    bold
                                    className="opportunity-info-window__amount"
                                >
                                    {getNumberAsCurrency(data.Amount, data.SymbolCurrency)}
                                </Text>
                            )}
                        </div>
                    </div>
                    <div className="opportunity-info-window-block opportunity-info-window__content">
                        {showRatio && (
                            <OpportunityInfoWindowRow
                                className="opportunity-info-window__ratio"
                                label="label_probabilitysale"
                            >
                                <div className="opportunity-info-window__value opportunity-info-window__circular-percent">
                                    <CircularPercentage
                                        rootClassName="opportunity-info-window__circular-percent__root"
                                        percentage={Math.min(percentageValue, 100)}
                                        strokeWidth={6}
                                        classForPercentage={percentageClass}
                                    >
                                        <div className="opportunity-info-window__circular-percent__percentage-text">{`${percentageValue}%`}</div>
                                    </CircularPercentage>
                                </div>
                            </OpportunityInfoWindowRow>
                        )}
                        {showForecastDate && (
                            <OpportunityInfoWindowRow
                                className="opportunity-info-window__forecast-date"
                                label="label_sales_forecast"
                            >
                                <Text>
                                    {data.ExpectedClose_timestamp
                                        ? formatDateWithTimeOrNot(
                                              getDateMomentFromBackendTimestamp(
                                                  data.ExpectedClose_timestamp,
                                              ),
                                          )
                                        : '-'}
                                </Text>
                            </OpportunityInfoWindowRow>
                        )}
                        {showOwner && (
                            <OpportunityInfoWindowRow
                                className="opportunity-info-window__owner"
                                label="label_owner"
                            >
                                <UserChip
                                    idUser={data.IdComercial}
                                    username={data.Comercial}
                                    remotePopover={true}
                                />
                            </OpportunityInfoWindowRow>
                        )}
                        {showCompany && data.IdCompany && data.Company && (
                            <OpportunityInfoWindowRow
                                className="opportunity-info-window__company"
                                label="label_account_client"
                            >
                                <CompanyChip
                                    idCompany={data.IdCompany}
                                    nameCompany={data.Company}
                                    remotePopover={true}
                                />
                            </OpportunityInfoWindowRow>
                        )}
                    </div>
                </Fragment>
            )}
        </div>
    );
};

OpportunityInfoWindow.propTypes = propTypes;

export default memo(connect(mapStateToProps)(OpportunityInfoWindow));
