import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Text } from 'hoi-poi-ui';
import { ServerListActions } from 'actions';
import { OPPORTUNITIES } from 'constants/Entities';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { formatLargeAmount, formatNumberWithSeparator } from 'utils/amount';
import Group from './components/Group';

import './styles.scss';

const propTypes = {
    pipeline: PropTypes.object,
};

const mapStateToProps = (state) => {
    let filters = state.entityFilters[OPPORTUNITIES.entity];
    filters = filters ? filters.filters || {} : {};
    return {
        pipeline: state.opportunities.pipeline || {},
        currencySymbol: state.config.userData.currencySymbol,
        filters,
    };
};

const mapDispatchToProps = (dispatch) => {
    return { getList: bindActionCreators(ServerListActions, dispatch).getList };
};

@connect(mapStateToProps, mapDispatchToProps)
class Footer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { statesList: null };
    }

    componentDidMount() {
        const { getList } = this.props;
        getList('tblEstadoExpediente')
            .then((statesList) => this.setState({ statesList }))
            .catch(console.error);
    }

    getAmountFormatted = (amount) => {
        let { currencySymbol } = this.props;

        if (amount === null || amount === undefined) return '-';

        return formatLargeAmount(amount, currencySymbol, 1);
    };

    render() {
        let { pipeline, filters } = this.props;
        const { statesList } = this.state;

        if (!statesList) return null;

        const totalSelection =
            pipeline.totalOpportunitiesSelection === null ||
            pipeline.totalOpportunitiesSelection === undefined
                ? '-'
                : formatNumberWithSeparator(pipeline.totalOpportunitiesSelection);
        const total =
            pipeline.totalOpportunities === null || pipeline.totalOpportunities === undefined
                ? '-'
                : formatNumberWithSeparator(pipeline.totalOpportunities);

        const valueSelection = this.getAmountFormatted(pipeline.totalValueOpportunitiesSelection);
        const valueTotal = this.getAmountFormatted(pipeline.totalValueOpportunities);
        const averageValueSelection = this.getAmountFormatted(
            pipeline.averageValueOpportunitiesSelection,
        );
        const averageValueTotal = this.getAmountFormatted(pipeline.averageValueOpportunities);
        const weightValueOpportunitySelection = this.getAmountFormatted(
            pipeline.WeightValueByOpportunitySelection,
        );
        const weightValueOpportunity = this.getAmountFormatted(pipeline.weightValueByOpportunity);
        const totalWeightValueSelection = this.getAmountFormatted(
            pipeline.totalWeightValueSelection,
        );
        const totalWeightValue = this.getAmountFormatted(pipeline.totalWeightValue);

        let showTotalByStates = false;
        if (filters && filters.state && filters.state.value && filters.state.value.length > 0) {
            const filteredStates = filters.state.value;
            let openedStates = [];
            let closedStates = [];
            statesList.forEach((state) => {
                if (filteredStates.includes(state.value)) {
                    if (state.blnendstate) closedStates.push(state);
                    else openedStates.push(state);
                }
            });
            if (closedStates.length === 0 && openedStates.length > 0) {
                showTotalByStates = true;
            }
        }
        // const showTotal = pipeline.showTotalValues;
        const showTotal = showTotalByStates;

        const renderPopoverContent = (literalSelected, literalTotal, hideCurrency) => {
            const { currencySymbol } = this.props;
            return (
                <Fragment>
                    <span className="popover-total-content_selected">
                        {getLiteral(literalSelected)}
                    </span>
                    <span className="popover-total-content_total">
                        {` / ${getLiteral(
                            showTotal ? literalTotal : 'label_opp_summary_filter_closed',
                        )}`}
                    </span>
                    {showTotal && !hideCurrency && (
                        <div className="popover-total-content_currency">
                            {getLiteralWithParameters('label_opp_summary_value_normalized', [
                                currencySymbol,
                            ])}
                        </div>
                    )}
                </Fragment>
            );
        };

        const popoverTotal = renderPopoverContent(
            'label_opp_summary_num_opp_filtered',
            'label_opp_summary_num_opp_open',
            true,
        );
        const popoverValue = renderPopoverContent(
            'label_opp_summary_value_filtered',
            'label_opp_summary_value_open',
        );

        const popoverAverage = renderPopoverContent(
            'label_opp_summary_avg_value_filtered',
            'label_opp_summary_avg_value_open',
        );
        const popoverWeight = renderPopoverContent(
            'label_opp_summary_sales_forecast_filtered',
            'label_opp_summary_sales_forecast_open',
        );

        const popoverTotalWeight = renderPopoverContent(
            'label_opp_summary_avg_sales_forecast_filtered',
            'label_opp_summary_avg_sales_forecast_open',
        );

        return (
            <div className="opportunities-footer">
                <Text type="h6" className="opportunities-footer__title" medium>
                    {getLiteral('label_opportunities_footer_summary')}
                </Text>
                <div className="opportunities-footer__group-container">
                    <Group
                        literal="label_opp_summary_num_opp"
                        selection={totalSelection}
                        total={total}
                        showTotal={showTotal}
                        popoverContent={popoverTotal}
                        popoverClassName="popover-total-content"
                    />
                    <Group
                        literal="label_opp_summary_value"
                        selection={valueSelection}
                        total={valueTotal}
                        showTotal={showTotal}
                        popoverContent={popoverValue}
                        popoverClassName="popover-value-content"
                    />
                    <Group
                        literal="label_opp_summary_avg_value"
                        selection={averageValueSelection}
                        total={averageValueTotal}
                        showTotal={showTotal}
                        popoverContent={popoverAverage}
                        popoverClassName="popover-average-content"
                    />
                    <Group
                        literal="label_opp_summary_sales_forecast"
                        selection={totalWeightValueSelection}
                        total={totalWeightValue}
                        showTotal={showTotal}
                        popoverContent={popoverWeight}
                        popoverClassName="popover-weight-content"
                    />
                    {/* <Group
                    literal="label_opp_summary_avg_sales_forecast"
                    selection={weightValueOpportunitySelection}
                    total={weightValueOpportunity}
                    showTotal={showTotal}
                    popoverContent={popoverTotalWeight}
                    popoverClassName="popover-total-weight-content"
                /> */}
                </div>
            </div>
        );
    }
}

Footer.propTypes = propTypes;

export default Footer;
