import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Text } from 'hoi-poi-ui';
import { getNumberAsCurrency } from 'utils/currency';
import { COMPANIES, OPPORTUNITIES, SALESORDERS } from 'constants/Entities';
import Context from 'managers/Context';
import { LabelYellow } from 'components/Label';
import MenuHoi from 'components/MenuHoi';
import { Dots } from 'components/SvgIcons';
import { UserChip, CompanyChip, OpportunityChip } from 'containers/components/chips';
import { SalesorderDefault, SalesorderWon, SalesorderLost } from 'components/SvgIcons';
import { EntityModalActions, OrdersActions } from 'actions';
import { getDetailRoute } from 'utils/fm';
import { getLiteral } from 'utils/getLiteral';
import { logEvent } from 'utils/tracking';
import { withEntityDetail } from 'containers/components/EntityDetail/hooks/useEntityDetail';
import colors from 'constants/colors';

const propTypes = {
    data: PropTypes.object,
    entiyType: PropTypes.object,
    crudPermissions: PropTypes.object,
    refreshSalesOrders: PropTypes.func,
};

const mapStateToProps = (state) => {
    const { crud_permission } = state.config.permission;
    return {
        crudPermissions: crud_permission.SALESORDERS,
    };
};

const mapDispatchToProps = (dispatch) => {
    const EntityModalAction = bindActionCreators(EntityModalActions, dispatch);
    const duplicateEntity = bindActionCreators(OrdersActions, dispatch)?.duplicateEntity;
    return {
        modalInit: EntityModalAction.init,
        duplicateEntity,
    };
};

@connect(mapStateToProps, mapDispatchToProps)
class SalesOrdersWidgetRow extends PureComponent {
    state = {
        withTabs: this.props.hasTabs,
    };

    duplicateOrder = () => {
        const { data, duplicateEntity } = this.props;
        duplicateEntity(data.id).then(
            (data) => (window.location.href = getDetailRoute({ entity: SALESORDERS, id: data.id })),
        );
    };

    setAsPrincipal = () => {
        const { data, entityType, refreshSalesOrders, crudPermissions } = this.props;

        if (!((entityType === OPPORTUNITIES && !data.principal) || crudPermissions.create))
            return null;

        Context.entityManager
            .getEntitiesManager(SALESORDERS)
            .setAsPrincipal(data.id, !data.principal, () => {
                refreshSalesOrders(data.id, !data.principal);
            });
    };

    onClickOpportunity = (e) => {
        e.preventDefault();
        const { data, openTab } = this.props;
        openTab({
            entity: OPPORTUNITIES,
            id: data.idOpportunity,
            hasCrudInDetail: true,
        });
    };

    onClickCompany = (e) => {
        e.preventDefault();
        const { data, openTab } = this.props;
        openTab({
            entity: COMPANIES,
            id: data.idCompany,
            hasCrudInDetail: true,
        });
    };

    onClickSalesOrder = (e) => {
        e.preventDefault();
        const { data, openTab } = this.props;
        openTab({
            entity: SALESORDERS,
            id: data.id,
            hasCrudInDetail: true,
        });
    };

    filterByEntityType = () => {
        const { data, entityType } = this.props;
        const { withTabs } = this.state;

        let orderReference = '-',
            extraEntity = '-',
            externalAnchor,
            externalOnClick = null;

        switch (entityType) {
            case COMPANIES:
                orderReference = data.reference ? data.reference : '-';
                externalOnClick = withTabs ? this.onClickOpportunity : null;
                extraEntity = data.opportunity ? (
                    <OpportunityChip
                        idOpportunity={data.idOpportunity}
                        nameOpportunity={data.opportunity}
                        remotePopover={true}
                        onClickPopover={externalOnClick}
                    />
                ) : (
                    '-'
                );
                externalAnchor =
                    data.idOpportunity !== -1
                        ? getDetailRoute({ entity: OPPORTUNITIES, id: data.idOpportunity })
                        : null;
                externalOnClick = this.onClickOpportunity;
                break;
            case OPPORTUNITIES:
                orderReference = data.reference ? data.reference : '-';
                externalOnClick = withTabs ? this.onClickCompany : null;
                extraEntity = data.company ? (
                    <CompanyChip
                        idCompany={data.idCompany}
                        nameCompany={data.company}
                        remotePopover={true}
                        onClickPopover={externalOnClick}
                    />
                ) : (
                    '-'
                );
                externalAnchor =
                    data.idCompany !== -1
                        ? getDetailRoute({ entity: COMPANIES, id: data.idCompany })
                        : null;
                break;
        }

        return {
            orderReference,
            extraEntity,
            externalAnchor,
            externalOnClick,
        };
    };

    onClickItem = (e) => {
        const { data, entityType, crudPermissions, modalInit } = this.props;
        e.stopPropagation();
        e.preventDefault();
        logEvent({
            event: entityType.trueName,
            submodule: 'salesOrdersWidget',
            functionality: 'edit',
        });
        modalInit({
            entity: SALESORDERS,
            id: data.id,
            labels: {
                title: getLiteral('label_editing_salesorders'),
                success: getLiteral('succes_entityupdatedsuccessfully'),
                error: getLiteral('error_generalerror'),
                deleteModalTitle: getLiteral('success_salesorders_entity_deleted'),
                successDelete: getLiteral('succes_entitydeletedsuccessfully'),
            },
            crudTab: SALESORDERS,
            hideDelete: !crudPermissions.delete,
            disableConfirm: !crudPermissions.update,
            size: 'big',
        });
        return false;
    };

    renderLeft = () => {
        const { data, entityType } = this.props;
        const { withTabs } = this.state;

        const dataFiltered = this.filterByEntityType();

        let icon = <SalesorderDefault color={colors.actionMinor[200]} />;
        if (data.win) icon = <SalesorderWon color={colors.actionMinor[200]} />;
        if (data.lost) icon = <SalesorderLost color={colors.actionMinor[200]} />;

        let extraEntity = dataFiltered.externalAnchor ? (
            <a href={dataFiltered.externalAnchor} onClick={dataFiltered.externalOnClick}>
                {dataFiltered.extraEntity}
            </a>
        ) : (
            <div className="text">{dataFiltered.extraEntity}</div>
        );
        let labelYellow =
            entityType === OPPORTUNITIES && data.principal ? (
                <LabelYellow text="label_principal" />
            ) : null;

        return (
            <div className="sales-orders-widget-row-left">
                <div className="row-icon-box">
                    <div className="row-left__icon">{icon}</div>
                </div>
                <div className="row-info-box">
                    <div className="row-left__name">
                        <a
                            href={getDetailRoute({ entity: SALESORDERS, id: data.id })}
                            target="_blank"
                            onClick={withTabs ? this.onClickSalesOrder : null}
                        >
                            {data.salesOrderNumber ? data.salesOrderNumber : '--'}
                        </a>
                        {labelYellow}
                    </div>
                    <div className="row-left__own-entity">{dataFiltered.orderReference}</div>
                    <div className="row-left__external-entity">{extraEntity}</div>
                </div>
            </div>
        );
    };

    renderMenu = () => {
        const { data, crudPermissions, entityType } = this.props;

        if (!((entityType === OPPORTUNITIES && !data.principal) || crudPermissions.create))
            return null;

        let items = [];

        if (entityType === OPPORTUNITIES && !data.principal && crudPermissions.create) {
            items = [
                {
                    title: <Text>{getLiteral('label_makeprincipal')}</Text>,
                    onClick: this.setAsPrincipal,
                },
                ...items,
            ];
        }

        if (crudPermissions.create) {
            items.push({
                title: <Text>{getLiteral('action_duply')}</Text>,
                onClick: this.duplicateOrder,
            });
        }

        return (
            <MenuHoi items={items}>
                <Dots width="18px" height="18px" />
            </MenuHoi>
        );
    };

    renderRight = () => {
        const { data } = this.props;

        const responsible = data.responsible ? data.responsible : '-';
        const state = data.stateSalesOrder ? data.stateSalesOrder : '-';

        const salesOrderCurrency = data.currencySymbol || '';
        const amount =
            data.amountSalesOrder && getNumberAsCurrency(data.amountSalesOrder, salesOrderCurrency);

        return (
            <div className="sales-orders-widget-row-right">
                <div className="row-right__content">
                    <div className="content-amount">{amount ? amount : '-'}</div>
                    <div className="content-state">{state}</div>
                    <div className="content-responsible">
                        <UserChip
                            idUser={data.idResponsible}
                            username={responsible}
                            remotePopover={true}
                        />
                    </div>
                </div>
                <div className="row-right__actions">{this.renderMenu()}</div>
            </div>
        );
    };

    render() {
        const { data, entityType } = this.props;

        const containerClasses = ['sales-orders-widget-row-container'];
        if (entityType === OPPORTUNITIES && data.principal) containerClasses.push('principal');

        return (
            <div className={containerClasses.join(' ')}>
                <div className="sales-orders-widget-row">
                    {this.renderLeft()}
                    {this.renderRight()}
                </div>
            </div>
        );
    }
}

SalesOrdersWidgetRow.propTypes = propTypes;

export default withEntityDetail(SalesOrdersWidgetRow);
