import { memo, useMemo } from 'react';
import { useTheme, Icon, Markdown, Popover, Text } from 'hoi-poi-ui';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { getNumberAsCurrency } from 'utils/currency';
import {
    BILLING_PERIOD_MONTHLY,
    BILLING_PERIOD_YEARLY,
    PURCHASE_PLAN,
    PURCHASE_SEATS,
} from '../../constants';
import { calculateServiceDetails } from '../../utils';

import './styles.scss';

const Summary = memo(
    ({
        addonsCost,
        seatsCost,
        billingPeriod,
        subscriptionEndDate,
        taxes,
        seats,
        totalUsersPurchased,
        type,
        currencySymbol,
    }) => {
        const theme = useTheme();
        const textProps = useMemo(
            () => ({ type: 'caption', color: theme.colors.utility.textSecondary }),
            [theme],
        );

        const { popoverRows, rows, taxesAmount, total } = useMemo(() => {
            const { monthsUntilRenewal, daysAfterMonths, costForRemainingPeriod, renewalCost } =
                calculateServiceDetails({
                    billingPeriod,
                    seatsCost,
                    addonsCost,
                    expiryDate: subscriptionEndDate,
                });

            let seatsSubtotalLabel, popoverRows;

            const cost = costForRemainingPeriod || renewalCost;
            const rows = [];

            let base = (cost.seats + cost.addons) * seats;

            if (billingPeriod === BILLING_PERIOD_YEARLY && type === PURCHASE_PLAN) {
                base = base * 12;
                cost.seats = cost.seats * 12;
                renewalCost.seats = renewalCost.seats * 12;
            }

            const taxesAmount = (base * taxes) / 100;
            const total = base + taxesAmount;

            if (billingPeriod === BILLING_PERIOD_MONTHLY) {
                seatsSubtotalLabel = getLiteralWithParameters('label_price_per_user', [
                    getNumberAsCurrency(cost.seats, currencySymbol),
                    seats,
                ]);
                rows.push({
                    label: seatsSubtotalLabel,
                    value: seats * cost.seats,
                });
            } else {
                seatsSubtotalLabel = getLiteralWithParameters('label_price_per_user', [
                    getNumberAsCurrency(cost.seats, currencySymbol),
                    seats,
                ]);
                seatsSubtotalLabel += ' x ';
                seatsSubtotalLabel +=
                    daysAfterMonths === 0
                        ? getLiteralWithParameters('label_months', [monthsUntilRenewal])
                        : getLiteralWithParameters('label_months_and_days', [
                              monthsUntilRenewal,
                              daysAfterMonths,
                          ]);

                rows.push({
                    label: seatsSubtotalLabel,
                    value: seats * cost.seats,
                });

                const totalSeats = type === PURCHASE_SEATS ? seats + totalUsersPurchased : seats;

                const baseRenewal = (renewalCost.seats + renewalCost.addons) * totalSeats;
                const taxesRenewal = (baseRenewal * taxes) / 100;
                const totalRenewal = baseRenewal + taxesRenewal;

                popoverRows = [
                    {
                        label: getLiteral('label_total_until_renewal'),
                        value: total,
                    },
                    {
                        label: getLiteral('label_total_next_renewal'),
                        value: totalRenewal,
                    },
                ];
            }

            if (cost.addons > 0) {
                rows.push({
                    label: getLiteralWithParameters('label_price_addon_per_user', [
                        getNumberAsCurrency(cost.addons, currencySymbol),
                    ]),
                    value: cost.addons * seats,
                });
            }

            return {
                popoverRows,
                rows,
                taxesAmount,
                total,
            };
        }, [
            addonsCost,
            billingPeriod,
            seats,
            seatsCost,
            subscriptionEndDate,
            taxes,
            totalUsersPurchased,
            type,
            currencySymbol,
        ]);

        const getPopover = useMemo(() => {
            if (!popoverRows || total === 0) return null;

            const popoverContent = (
                <div className="fm-purchaseSummary-popover">
                    <Icon size="big" name="bill" color={theme.colors.grey[500]} />
                    <div className="fm-purchaseSummary-popover__content">
                        <Text
                            type="subtitle"
                            color={theme.colors.utility.textPrimary}
                            className="fm-purchaseSummary-popover__content__header"
                            medium
                        >
                            {getLiteral('label_payment_details')}
                        </Text>
                        {popoverRows.map(({ label, value }) => (
                            <div className="fm-purchaseSummary__row" key={label}>
                                <Text type="body" color={theme.colors.utility.textSecondary}>
                                    {label}
                                </Text>
                                <Text type="body" color={theme.colors.utility.textPrimary}>
                                    {!taxes
                                        ? `${getNumberAsCurrency(value, currencySymbol, true)}*`
                                        : getNumberAsCurrency(value, currencySymbol, true)}
                                </Text>
                            </div>
                        ))}
                        {!taxes && (
                            <Text {...textProps}>{getLiteral('label_taxes_not_included')}</Text>
                        )}
                    </div>
                </div>
            );

            return (
                <Popover placement="bottom" content={popoverContent}>
                    <Icon name="info" size="small" color={theme.colors.grey[500]} />
                </Popover>
            );
        }, [popoverRows, total, taxes, textProps, currencySymbol, theme]);

        return (
            <div className="fm-purchaseSummary">
                {rows?.length &&
                    rows.map(({ label, value }) => (
                        <div className="fm-purchaseSummary__row" key={label}>
                            <Text {...textProps}>{label}</Text>
                            <Text className="value" {...textProps}>
                                {getNumberAsCurrency(value, currencySymbol, true)}
                            </Text>
                        </div>
                    ))}
                {!!taxes && (
                    <div className="fm-purchaseSummary__row">
                        <Text {...textProps}>
                            {getLiteralWithParameters('label_taxes', [`${taxes}%`])}
                        </Text>
                        <Text {...textProps}>
                            {getNumberAsCurrency(taxesAmount, currencySymbol, true)}
                        </Text>
                    </div>
                )}
                <div className="fm-purchaseSummary__row fm-purchaseSummary__row--total">
                    <Text type="captionMedium" color={theme.colors.utility.textPrimary}>
                        {getLiteral('label_total')}
                    </Text>
                    <Text className="total" type="h3" color={theme.colors.utility.textPrimary}>
                        {!taxes
                            ? `* ${getNumberAsCurrency(total, currencySymbol, true)}`
                            : getNumberAsCurrency(total, currencySymbol, true)}
                    </Text>
                    {getPopover}
                </div>
                {!taxes && (
                    <div className="fm-purchaseSummary__row">
                        <Text {...textProps} color={theme.colors.utility.textDisabled}>
                            {getLiteral('label_taxes_not_included')}
                        </Text>
                    </div>
                )}
                <Markdown
                    content={getLiteral('label_terms_conditions_modal')}
                    overrides={{
                        a: { type: 'caption', target: '_blank' },
                        p: { type: 'caption', color: theme.colors.utility.textSecondary },
                    }}
                    className="fm-purchaseSummary__footer"
                />
            </div>
        );
    },
);

export default Summary;
