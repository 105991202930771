import React, { memo, useEffect, useMemo, useCallback, useState, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { COMPANIES } from 'constants/Entities';
import { logEvent } from 'utils/tracking';
import { getBackendBoolean } from 'utils/fm';
import { groupFieldsForFieldSelector, processExtraFieldsForList } from 'utils/fm';
import CONFIG from 'containers/companies/EntityConfig';
import NewEntityList from 'containers/components/NewEntityList';

import {
    EntityExtraFieldsActions,
    EntityListActions,
    EntityListSelectActions,
    EntityFiltersActions,
} from 'actions';
import LinkFromWeb3Decorator from 'decorators/LinkFromWeb3Decorator';
import { getLiteral } from 'utils/getLiteral';
import colors from 'constants/colors';
import AccountListLayout from './AccountListLayout';
import AccountsIcon from 'components/SvgIcons/entities/Accounts';

import './style.scss';

const mapStateToProps = (state) => {
    const entityListSelect = state?.entityListSelect?.[COMPANIES.entity];
    const checkedWeb5 = entityListSelect?.checkedWeb5 || null;
    const info = entityListSelect?.info;
    const allSelected = entityListSelect?.allSelected;
    const shouldClearWeb5Checkboxes =
        state?.entityListSelect?.[COMPANIES.entity]?.shouldClearWeb5Checkboxes;

    return {
        locale: state?.config?.userData?.locale || '',
        checkedWeb5,
        info,
        shouldClearWeb5Checkboxes,
        allSelected,
        entityListSelect,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getExtraFieldSchema: bindActionCreators(EntityExtraFieldsActions, dispatch)
            .getExtraFieldSchema,
        setUseLazyLoad: bindActionCreators(EntityListActions, dispatch).setUseLazyLoad,
        standardFieldsConfiguration: bindActionCreators(EntityListActions, dispatch)
            .standardFieldsConfiguration,
        onEntitySelect: bindActionCreators(EntityListSelectActions, dispatch).onEntitySelect,
        onEntitySelectAll: bindActionCreators(EntityListSelectActions, dispatch).onEntitySelectAll,
        setPreventSelectionResetOnChangeFilter: bindActionCreators(EntityListActions, dispatch)
            .setPreventSelectionResetOnChangeFilter,
        changeFilter: bindActionCreators(EntityFiltersActions, dispatch).changeFilter,
        toggleFilters: bindActionCreators(EntityFiltersActions, dispatch).toggleFilters,
        setCheckedWeb5: bindActionCreators(EntityListSelectActions, dispatch).setCheckedWeb5,
        setShouldClearWeb5Checkboxes: bindActionCreators(EntityListSelectActions, dispatch)
            .setShouldClearWeb5Checkboxes,
    };
};

const Content = memo(
    ({
        getExtraFieldSchema,
        standardFieldsConfiguration,
        locale,
        onEntitySelect,
        onEntitySelectAll,
        setUseLazyLoad,
        selectionToken,
        changeFilter,
        toggleFilters,
        checkedWeb5,
        info,
        setCheckedWeb5,
        shouldClearWeb5Checkboxes,
        setShouldClearWeb5Checkboxes,
        allSelected,
        setPreventSelectionResetOnChangeFilter,
    }) => {
        const useLazyLoad = true;
        const areAllChecked = useRef(false);
        const [showModalFields, setShowModalFields] = useState(false);

        const tableParams = useMemo(() => {
            let newTableParams = {
                account: {
                    getSvg: (data) => {
                        const thumbUp = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"><path d="M14.88 13.231c.667 0 1.206.534 1.206 1.192 0 .659-.54 1.192-1.206 1.192h-.804a1.2 1.2 0 011.206 1.193c0 .659-.54 1.192-1.206 1.192H8.848C5.63 18 6.434 16.41 0 16.41V9.257h2.413c2.815 0 6.032-2.56 6.032-6.359 0-1.257 2.413-1.415 2.413.969 0 1.59-.804 4.595-.804 4.595h6.435a1.2 1.2 0 011.206 1.192 1.2 1.2 0 01-1.206 1.193h-.804a1.2 1.2 0 011.206 1.192 1.2 1.2 0 01-1.206 1.192h-.805" fill="#86AD20" fill-rule="evenodd"/></svg>`;
                        const thumbDown = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"><path d="M14.88 6.769c.667 0 1.206-.534 1.206-1.192 0-.659-.54-1.192-1.206-1.192h-.804a1.2 1.2 0 001.206-1.193c0-.659-.54-1.192-1.206-1.192H8.848C5.63 2 6.434 3.59 0 3.59v7.153h2.413c2.815 0 6.032 2.56 6.032 6.359 0 1.257 2.413 1.415 2.413-.969 0-1.59-.804-4.595-.804-4.595h6.435a1.2 1.2 0 001.206-1.192 1.2 1.2 0 00-1.206-1.193h-.804a1.2 1.2 0 001.206-1.192 1.2 1.2 0 00-1.206-1.192h-.805" fill="#F04540" fill-rule="evenodd"/></svg>`;

                        if (data?.CompanyState?.toLowerCase() === 'win') return thumbUp;
                        else if (data?.CompanyState?.toLowerCase() === 'lost') return thumbDown;
                        else return null;
                    },
                    onClickCheckbox: (result) => {
                        const { id, colId, isColumn, value, checked, data, rowIndex } = result;
                        if (isColumn) {
                            onEntitySelectAll(COMPANIES, value, checked);
                        } else {
                            onEntitySelect(COMPANIES, id, value, data, rowIndex, checked);
                        }
                    },
                    isDisabled: (_, __, rowNode) => {
                        return rowNode?.data
                            ? getBackendBoolean(rowNode?.data?.oldSelected)
                            : false;
                    },
                },
                follow: {
                    getSvg: (value) => {
                        const activeColor = colors.actionMajor[500];
                        const inactiveColor = colors.grey[100];
                        const svgActive = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"><path d="M5 11.876v5.153c0 .536-.448.971-1 .971s-1-.435-1-.971V1.55C3 .695 3.772 0 4.725 0c.267 0 .531.056.77.164l8.551 3.845c.852.383 1.198 1.314.772 2.08-.142.256-.36.47-.629.622L5 11.876z" fill="${activeColor}" fill-rule="nonzero"/></svg>`;
                        const svgInactive = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"><path d="M5 11.876v5.153c0 .536-.448.971-1 .971s-1-.435-1-.971V1.55C3 .695 3.772 0 4.725 0c.267 0 .531.056.77.164l8.551 3.845c.852.383 1.198 1.314.772 2.08-.142.256-.36.47-.629.622L5 11.876z" fill="${inactiveColor}" fill-rule="nonzero"/></svg>`;

                        if (value) return svgActive;
                        else return svgInactive;
                    },
                },
                date: {
                    getLocale: () => locale,
                },
            };

            return newTableParams;
        }, [onEntitySelectAll, onEntitySelect, locale]);

        const [config, setConfig] = useState(null);
        const isFirstLoad = useRef(true);

        useEffect(() => {
            toggleFilters(COMPANIES, false);
        }, [toggleFilters]);

        useEffect(() => {
            setUseLazyLoad(COMPANIES, true);

            if (isFirstLoad?.current) {
                let newConfig = CONFIG(tableParams);
                newConfig = {
                    ...newConfig,
                    withEntitySelection: true,
                    selectionEntity: 'accounts',
                    selectionFor: 'campaigns',
                    selectionToken: selectionToken,
                };

                changeFilter({
                    entity: COMPANIES,
                    filter: {
                        id: 'entityShowSelected',
                        hideForCount: true,
                    },
                    value: 'campaign',
                    refresh: !selectionToken,
                });

                let editCheckedFields = null;
                if (
                    (!checkedWeb5 || Object.keys(checkedWeb5).length === 0) &&
                    info?.token &&
                    newConfig?.columnDefs?.[0]?.colId
                ) {
                    editCheckedFields = {
                        [newConfig.columnDefs[0].colId]: {
                            rows: info.entitiesId,
                            areAllSelected: false,
                        },
                    };
                }

                if (editCheckedFields) {
                    setCheckedWeb5(newConfig.entity, editCheckedFields);
                }

                getExtraFieldSchema(newConfig.entity, (extraFieldTabs) => {
                    let extraFields = [];
                    const groupedExtraFields = extraFieldTabs.map((tab) => {
                        let group = {
                            label:
                                typeof tab.descripcion !== 'string'
                                    ? 'label_customized_fields'
                                    : tab.descripcion,
                            fields: [],
                        };

                        group.fields = processExtraFieldsForList(tab.tabFields);
                        extraFields = [...extraFields, ...group.fields];

                        return group;
                    });

                    standardFieldsConfiguration(COMPANIES, newConfig.columnDefs)
                        .then((columnDefs) => {
                            const newColumnDefs = [...columnDefs, ...extraFields];
                            const groupedFields = [
                                ...groupFieldsForFieldSelector(newConfig, columnDefs, true),
                                ...groupedExtraFields,
                            ];

                            newConfig = {
                                ...newConfig,
                                columnDefs: newColumnDefs,
                                groupedFields,
                            };
                            setConfig(newConfig);
                        })
                        .catch(() => {
                            console.error('List configuration error');
                        });
                });

                isFirstLoad.current = false;
            }
        }, [
            tableParams,
            config,
            setConfig,
            getExtraFieldSchema,
            standardFieldsConfiguration,
            setUseLazyLoad,
            selectionToken,
            info,
            checkedWeb5,
            setCheckedWeb5,
            toggleFilters,
            changeFilter,
        ]);

        useEffect(() => {
            if (shouldClearWeb5Checkboxes) {
                // We only set the value to false, because we don't have to clear
                // checked when using filters in this table
                setShouldClearWeb5Checkboxes(COMPANIES, false);
            }
        }, [setShouldClearWeb5Checkboxes, shouldClearWeb5Checkboxes]);

        useEffect(() => {
            areAllChecked.current = allSelected;
        }, [allSelected]);

        useEffect(() => {
            setPreventSelectionResetOnChangeFilter(COMPANIES, true);
            return () => setPreventSelectionResetOnChangeFilter(COMPANIES, false);
        }, [setPreventSelectionResetOnChangeFilter]);

        const emptyViewProps = useMemo(
            () => ({
                canCreate: false,
                icon: (
                    <div className="fm-entity-list__empty-first-icon">
                        <AccountsIcon color={colors.grey[100]} />
                    </div>
                ),
                iconType: 'icon',
            }),
            [],
        );

        const getFieldSelectorProps = useCallback(() => {
            if (!config?.groupedFields) return null;
            let actions = [];

            actions.push({
                label: getLiteral('action_select_visible_columns'),
                icon: 'columns',
                id: 'columns',
                onClick: () => {
                    setShowModalFields(true);
                    logEvent({
                        event: COMPANIES.trueName,
                        functionality: 'visibleFields',
                        checkDuplicate: true,
                    });
                },
            });

            return {
                actions,
                title: getLiteral('label_showHideColumns'),
                groupedColumns: config.groupedFields,
            };
        }, [config]);

        const getRowNodeId = useCallback((data) => data.Id, []);

        const getCheckedRows = useCallback(
            (data) => {
                const columnName = config?.columnDefs?.[0].colId || 'name';
                const checkedRows = data.reduce((obj, current) => {
                    if (!obj?.[columnName]) obj[columnName] = {};
                    if (!obj?.[columnName]?.rows) obj[columnName].rows = {};
                    if (current) {
                        obj[columnName].rows[current.Id] = current.selected === '1';
                    }
                    return obj;
                }, {});

                if (checkedRows?.[columnName]) {
                    checkedRows[columnName].areAllChecked = areAllChecked.current;
                    checkedRows[columnName].lazyAreAllChecked = areAllChecked.current;
                }

                return checkedRows;
            },
            [config],
        );

        const modalOptionsProps = useCallback(
            () => ({
                advice: getLiteral('label_visible_fields_explanation'),
                isOpen: showModalFields,
                confirmText: getLiteral('action_save'),
                cancelText: getLiteral('action_cancel'),
                placeholderSearch: getLiteral('label_search_field'),
                title: getLiteral('label_visible_fields'),
                onCancel: setShowModalFields,
            }),
            [showModalFields],
        );

        return (
            <div className="campaigns-companies-table">
                {config && (
                    <AccountListLayout config={config}>
                        <NewEntityList
                            id="campaigns-companies-table-id"
                            entity={COMPANIES}
                            config={config}
                            initOnReady={true}
                            useLazyLoad={useLazyLoad}
                            useCache={true}
                            useWeb3Cache={true}
                            useSort={true}
                            useDragColumns={true}
                            useFieldSelector={true}
                            fieldSelectorProps={getFieldSelectorProps()}
                            getRowNodeId={getRowNodeId}
                            getCheckedRows={getCheckedRows}
                            emptyViewProps={emptyViewProps}
                            useSelectColumns={true}
                            preventInitEntitySelect={!!selectionToken}
                            modalOptionsProps={modalOptionsProps()}
                            discardCount={true}
                        />
                    </AccountListLayout>
                )}
            </div>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(LinkFromWeb3Decorator(Content));
