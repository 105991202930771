import React, { memo } from 'react';

const Favourites = (props) => (
    <svg width={168} height={144} fill="none" {...props}>
        <g mask="url(#a)">
            <path
                fill="#474747"
                d="M26.675 133.826a4.222 4.222 0 0 1-3.999-5.593L40.05 77.594a12.3 12.3 0 0 1 4.515-6.054l9.081-6.481-1.607 64.648a4.224 4.224 0 0 1-4.33 4.116 4.223 4.223 0 0 1-4.12-4.326l.968-38.995-13.883 40.469a4.228 4.228 0 0 1-3.998 2.855Z"
            />
            <path
                fill="#B26E3D"
                d="M58.63 53.052a1.598 1.598 0 1 0 .002-3.197 1.598 1.598 0 0 0-.002 3.197Z"
            />
            <path
                fill="#474747"
                d="M32.793 133.835h-6.118a.876.876 0 1 1 0-1.753h6.118a.877.877 0 0 1 0 1.753ZM53.942 133.835h-6.128a.877.877 0 1 1 0-1.753h6.128a.877.877 0 0 1 0 1.753ZM130.782 49.444h-54.3a2.337 2.337 0 0 0-2.338 2.336v48.823a2.336 2.336 0 0 0 2.338 2.336h54.3a2.337 2.337 0 0 0 2.339-2.336V51.78a2.337 2.337 0 0 0-2.339-2.336ZM86.336 93.093a2.337 2.337 0 0 1-4.675 0v-6.754a2.337 2.337 0 0 1 4.675 0v6.754Zm0-18.935a2.336 2.336 0 0 1-3.99 1.653 2.329 2.329 0 0 1-.685-1.653v-1.343a2.336 2.336 0 0 1 2.336-2.336 2.336 2.336 0 0 1 2.339 2.336v1.343Zm0-13.524a2.336 2.336 0 0 1-3.99 1.65 2.326 2.326 0 0 1-.685-1.65v-1.345a2.336 2.336 0 0 1 2.336-2.334 2.336 2.336 0 0 1 2.339 2.334v1.345Zm13.538 32.459a2.338 2.338 0 0 1-2.338 2.336H96.19a2.336 2.336 0 0 1-2.338-2.336v-6.754a2.337 2.337 0 0 1 2.338-2.336h1.345a2.339 2.339 0 0 1 2.338 2.336v6.754Zm0-18.935a2.338 2.338 0 0 1-2.338 2.336H96.19a2.336 2.336 0 0 1-2.338-2.336v-1.343a2.336 2.336 0 0 1 2.338-2.336h1.345a2.338 2.338 0 0 1 2.338 2.336v1.343Zm0-13.524a2.337 2.337 0 0 1-2.338 2.334H96.19a2.336 2.336 0 0 1-2.338-2.334v-1.345a2.336 2.336 0 0 1 2.338-2.334h1.345a2.337 2.337 0 0 1 2.338 2.334v1.345Zm25.73 32.459a2.337 2.337 0 0 1-2.339 2.336h-13.537a2.336 2.336 0 0 1-2.338-2.336v-6.754a2.336 2.336 0 0 1 2.338-2.336h13.537a2.338 2.338 0 0 1 2.339 2.336v6.754Zm0-18.935a2.337 2.337 0 0 1-2.339 2.336h-13.537a2.336 2.336 0 0 1-2.338-2.336v-1.343a2.336 2.336 0 0 1 2.338-2.336h13.537a2.337 2.337 0 0 1 2.339 2.336v1.343Zm0-13.524a2.337 2.337 0 0 1-2.339 2.334h-13.537a2.336 2.336 0 0 1-2.338-2.334v-1.345a2.336 2.336 0 0 1 2.338-2.334h13.537a2.337 2.337 0 0 1 2.339 2.334v1.345Z"
            />
            <path
                fill="#5E5E5E"
                d="M61.71 10.447V133.82h83.844V10.447H61.71Zm71.41 90.156a2.336 2.336 0 0 1-2.338 2.336h-54.3a2.336 2.336 0 0 1-2.339-2.336V51.78a2.337 2.337 0 0 1 2.338-2.336h54.301a2.336 2.336 0 0 1 2.338 2.336v48.823Z"
            />
            <path
                fill="#474747"
                d="M130.782 27.804H76.48a2.337 2.337 0 0 0-2.337 2.336v2.838a2.337 2.337 0 0 0 2.338 2.336h54.301a2.337 2.337 0 0 0 2.338-2.336V30.14a2.337 2.337 0 0 0-2.338-2.336ZM130.782 108.954h-23.954a2.337 2.337 0 0 0-2.338 2.336v2.838a2.337 2.337 0 0 0 2.338 2.336h23.954a2.337 2.337 0 0 0 2.338-2.336v-2.838a2.337 2.337 0 0 0-2.338-2.336ZM101.293 38.624H76.48a2.337 2.337 0 0 0-2.337 2.336v2.838a2.337 2.337 0 0 0 2.338 2.336h24.812a2.336 2.336 0 0 0 2.338-2.336V40.96a2.336 2.336 0 0 0-2.338-2.336Z"
            />
            <path
                fill="#5E5E5E"
                d="M125.603 59.289v1.345a2.336 2.336 0 0 1-2.338 2.334h-13.537a2.336 2.336 0 0 1-2.338-2.334v-1.345a2.336 2.336 0 0 1 2.338-2.334h13.537a2.336 2.336 0 0 1 2.338 2.334ZM123.265 70.479h-13.537a2.337 2.337 0 0 0-2.338 2.336v1.343a2.337 2.337 0 0 0 2.338 2.337h13.537a2.337 2.337 0 0 0 2.338-2.337v-1.343a2.337 2.337 0 0 0-2.338-2.336ZM125.603 86.34v6.753a2.336 2.336 0 0 1-2.338 2.335h-13.537a2.336 2.336 0 0 1-2.338-2.335v-6.754a2.338 2.338 0 0 1 2.338-2.336h13.537a2.337 2.337 0 0 1 2.338 2.336ZM97.535 84.003h-1.344a2.337 2.337 0 0 0-2.338 2.336v6.754a2.337 2.337 0 0 0 2.338 2.335h1.344a2.337 2.337 0 0 0 2.339-2.335v-6.754a2.337 2.337 0 0 0-2.339-2.336ZM86.335 86.34v6.753a2.336 2.336 0 0 1-3.99 1.652 2.33 2.33 0 0 1-.685-1.652v-6.754a2.337 2.337 0 0 1 4.675 0ZM99.874 59.289v1.345a2.337 2.337 0 0 1-2.339 2.334h-1.344a2.336 2.336 0 0 1-2.338-2.334v-1.345a2.336 2.336 0 0 1 2.338-2.334h1.344a2.337 2.337 0 0 1 2.339 2.334ZM86.335 59.289v1.345a2.336 2.336 0 0 1-3.99 1.65 2.325 2.325 0 0 1-.685-1.65v-1.345a2.336 2.336 0 0 1 2.337-2.334 2.336 2.336 0 0 1 2.338 2.334ZM86.335 72.815v1.343a2.337 2.337 0 0 1-4.675 0v-1.343a2.337 2.337 0 0 1 4.675 0ZM97.535 70.479h-1.344a2.337 2.337 0 0 0-2.338 2.336v1.343a2.337 2.337 0 0 0 2.338 2.337h1.344a2.337 2.337 0 0 0 2.339-2.337v-1.343a2.337 2.337 0 0 0-2.339-2.336Z"
            />
            <path
                fill="#B26E3D"
                d="M56.434 91.803a1.592 1.592 0 0 1-1.086-2.761c1.146-1.063 1.613-3.282 1.696-4.046a1.597 1.597 0 0 1 1.754-1.415 1.592 1.592 0 0 1 1.419 1.746c-.042.402-.47 3.983-2.698 6.05a1.59 1.59 0 0 1-1.085.426Z"
            />
            <path
                fill="#00D639"
                d="M86.069 75.24a2.334 2.334 0 0 1-2.072 1.254 2.33 2.33 0 0 1-1.653-.683 2.329 2.329 0 0 1-.684-1.653v-1.343c0-.223.03-.438.09-.64l-7.173-5.093a2.309 2.309 0 0 0-3.427.901l2.993 19.62 2.338 15.336h48.603L86.069 75.24Zm.266 17.853a2.337 2.337 0 0 1-4.675 0v-6.754a2.337 2.337 0 0 1 4.675 0v6.754Zm11.2 2.336h-1.344a2.336 2.336 0 0 1-2.339-2.336v-6.754a2.337 2.337 0 0 1 2.339-2.336h1.344a2.339 2.339 0 0 1 2.339 2.336v6.754a2.338 2.338 0 0 1-2.339 2.336Z"
            />
            <path
                fill="#C6C6C6"
                d="m56.467 65.543-3.293 8.767-8.872-4.223 9.867-10.558 3.364 2.132-1.066 3.882Z"
            />
            <path
                fill="#C6C6C6"
                d="M58.516 86.044h-.001a3.189 3.189 0 0 1-3.19-3.188l.004-7.662-5.623.799c-1.943.327-3.894-.193-5.36-1.433-3.067-2.593-3.143-7.36-.176-11.09l4.678-5.878a7.338 7.338 0 0 1 9.806-1.576l.862.565a4.845 4.845 0 0 1 2.199 4.067l-.003 7.201 4.06-.577a3.188 3.188 0 1 1 .898 6.31l-4.96.706-.004 8.57a3.189 3.189 0 0 1-3.19 3.186Zm-3.902-24.86a.985.985 0 0 0-.772.375l-4.677 5.878c-.83 1.042-.893 2.092-.698 2.257.016.01.087.03.184.012l.11-.017 6.57-.933.003-7.293-.177-.116a.989.989 0 0 0-.543-.163Z"
            />
            <path
                fill="#B26E3D"
                d="M67.377 69.977a.645.645 0 0 1-.088-1.284c2.297-.319 3.399-1.358 3.41-1.369a.648.648 0 0 1 .91.012.642.642 0 0 1-.004.907c-.056.055-1.385 1.345-4.138 1.727a.636.636 0 0 1-.09.007Z"
            />
            <path
                fill="#474747"
                d="m70.683 67.451 1.145 4.972 4.545-4.066-3.416-2.426a1.464 1.464 0 0 0-2.274 1.52Z"
            />
            <path
                fill="#B26E3D"
                d="M68.37 71.678c-.294 0-.6-.007-.912-.024a1.594 1.594 0 1 1 .17-3.183 15.17 15.17 0 0 0 3.681-.265 1.593 1.593 0 0 1 .709 3.108c-.073.016-1.618.364-3.647.364Z"
            />
        </g>
    </svg>
);

export default memo(Favourites);
