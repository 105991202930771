import { memo } from 'react';

const SageCopilot = (props) => (
    <svg
        width="18"
        height="19"
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M3.75 11.75L2.8125 13.8125L0.75 14.75L2.8125 15.6875L3.75 17.75L4.6875 15.6875L6.75 14.75L4.6875 13.8125L3.75 11.75ZM9.375 11.375L11.25 15.5L13.125 11.375L17.25 9.5L13.125 7.625L11.25 3.5L9.375 7.625L5.25 9.5L9.375 11.375ZM3.75 7.25L4.6875 5.1875L6.75 4.25L4.6875 3.3125L3.75 1.25L2.8125 3.3125L0.75 4.25L2.8125 5.1875L3.75 7.25Z"
            fill="url(#paint0_linear_7295_367754)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_7295_367754"
                x1="1"
                y1="14.5001"
                x2="17"
                y2="5.50012"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#00D639" />
                <stop offset="0.395" stop-color="#00D6DE" />
                <stop offset="1" stop-color="#9D60FF" />
            </linearGradient>
        </defs>
    </svg>
);

export default memo(SageCopilot);
