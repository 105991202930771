import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { LookerService } from 'services';

import FolderForm from 'containers/components/modals/FolderForm';

const MetricsDashboardFolderForm = memo(({ onRef, onSave }) => {
    const canCustomizeVisibility = useSelector(
        (state) =>
            state.config?.permission?.analyticsPro &&
            state.config?.permission?.analyticsProVisibilityManager,
    );
    return (
        <FolderForm
            onRef={onRef}
            onSave={onSave}
            showEnvironments={canCustomizeVisibility}
            showLevels={canCustomizeVisibility}
            createFolder={LookerService.createFolder}
            updateFolder={LookerService.updateFolder}
            showPrivacy
        />
    );
});

export default MetricsDashboardFolderForm;
