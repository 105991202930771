import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { BadgeNotification, Popover, Checkbox } from 'hoi-poi-ui';
import { formatLargeAmount } from 'utils/amount';
import './styles.scss';

const propTypes = {
    pipelineItems: PropTypes.array,
    activeFilters: PropTypes.array,
    onCheck: PropTypes.func,
    title: PropTypes.string,
    type: PropTypes.string,
};

function mapStateToProps(state) {
    return {
        currencySymbol: state.config.userData.currencySymbol,
    };
}

@connect(mapStateToProps, null)
class StatusCloseCard extends PureComponent {
    state = {};

    onCheckItem = (item) => {
        return (event) => {
            const { onCheck } = this.props;
            event.stopPropagation();
            onCheck(item['-id'])();
        };
    };

    render() {
        let { states, pipelineStates, currencySymbol, activeFilters, title, type, avoidAmounts } =
            this.props;

        let count = 0;
        let amount = 0;
        pipelineStates.map((item) => {
            count += item.count;
            amount += item.total;
        });
        if (pipelineStates.length > 0) {
            amount = formatLargeAmount(amount, currencySymbol, 1);
        } else {
            amount = '-';
        }

        let statesToPrint = states.reduce((obj, state) => {
            if (activeFilters && activeFilters.includes(state['-id'])) {
                obj.push(state);
            }
            return obj;
        }, []);
        let empty = statesToPrint.length === 0 || avoidAmounts;

        let classes = ['fm-popover', 'pipeline-card-status-close'];
        if (empty) classes.push('pipeline-card-status-close-empty');

        let popoverButton = (
            <div className={classes.join(' ')}>
                <div className="fm-popover-btn">
                    <div>
                        <div className="container-text">
                            <div className="container-text__name">
                                <div className={`state-name ${type}`}>{title}</div>
                            </div>
                            <div className="container-arrow">
                                <div className="arrow-down" />
                            </div>
                        </div>

                        {!empty && (
                            <div className="container-amount">
                                <div className="amount">{amount}</div>
                                <div className="container-amount__count">
                                    <BadgeNotification
                                        size="small"
                                        className={`badge-root ${type}`}
                                        overrides={{
                                            Text: {
                                                className: `badge-root-text ${type}`,
                                            },
                                        }}
                                    >
                                        {count}
                                    </BadgeNotification>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );

        return (
            <Popover
                placement="bottom"
                content={
                    <div className="pipeline-card-status-close-popover">
                        {states.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className="item-menu"
                                    onClick={this.onCheckItem(item)}
                                >
                                    <Checkbox
                                        color="actionMinor"
                                        onChange={this.onCheckItem(item)}
                                        checked={
                                            activeFilters && activeFilters.includes(item['-id'])
                                        }
                                    />
                                    <span className="text">{item.descripcion}</span>
                                </div>
                            );
                        })}
                    </div>
                }
            >
                {popoverButton}
            </Popover>
        );
    }
}

StatusCloseCard.propTypes = propTypes;

export default StatusCloseCard;
