import React from 'react';
import colors from 'constants/colors';

const Reports = ({ color = colors.actionMinor[500] }) => (
    <svg viewBox={`0 0 18 18`} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.079 9.017l1.525.975a1.176 1.176 0 0 0-.033.277c0 .66.546 1.192 1.215 1.192S12 10.929 12 10.269a1.17 1.17 0 0 0-.21-.67l2.055-2.428c.156.071.33.111.512.111.67 0 1.214-.532 1.214-1.192 0-.66-.545-1.192-1.214-1.192-.669 0-1.214.532-1.214 1.192 0 .249.077.479.209.67l-2.055 2.427a1.23 1.23 0 0 0-1.376.244l-1.525-.975c.021-.089.033-.181.033-.277 0-.66-.546-1.192-1.215-1.192S6 7.52 6 8.18c0 .249.077.48.21.67l-2.056 2.427a1.23 1.23 0 0 0-.511-.11c-.67 0-1.214.532-1.214 1.192 0 .66.545 1.192 1.214 1.192.669 0 1.214-.532 1.214-1.192a1.17 1.17 0 0 0-.21-.67l2.056-2.427a1.23 1.23 0 0 0 1.376-.244zM0 5.002C0 3.897.893 3 1.995 3h14.01A2 2 0 0 1 18 5.002v9.086a1.998 1.998 0 0 1-1.995 2.003H1.995A2 2 0 0 1 0 14.088V5.002zM7.786 8.18a.56.56 0 0 1-.572.55.56.56 0 0 1-.571-.55.56.56 0 0 1 .571-.549.56.56 0 0 1 .572.55zm-3.572 4.179a.56.56 0 0 1-.571.55.56.56 0 0 1-.572-.55.56.56 0 0 1 .572-.55.56.56 0 0 1 .571.55zm7.143-2.09a.56.56 0 0 1-.571.55.56.56 0 0 1-.572-.55.56.56 0 0 1 .572-.548.56.56 0 0 1 .571.549zM14.93 6.09a.56.56 0 0 1-.572.55.56.56 0 0 1-.571-.55.56.56 0 0 1 .571-.549.56.56 0 0 1 .572.55z"
            fill={color || '#e1e4e7'}
            fillRule="evenodd"
        />
    </svg>
);

export default Reports;
