import React, { memo, useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ServerListActions } from 'actions';
import { EntitiesDb } from 'constants/Entities';
import { getLiteral } from 'utils/getLiteral';
import { isEmptyObject } from 'utils/objects';
import { UserChip } from 'containers/components/chips';
import { Icon } from 'hoi-poi-ui';
import PopoverHoi from 'components/PopoverHoi';
import colors from 'constants/colors';
import {
    getConditionsString,
    mapConditions,
    mapFieldsValues,
    mapToJsonLogic,
} from '@web/web5/dist/components/Conditions';
import { findFieldConfiguration } from 'utils/fm';
import Trunk8 from 'react-trunk8';

const propTypes = {
    data: PropTypes.object,
    loggedUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    tree: PropTypes.object,
    getList: PropTypes.func,
    locale: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {
        locale: state.config.userData.locale || null,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getList: bindActionCreators(ServerListActions, dispatch).getList,
    };
};

const TabInfo = memo(({ data, loggedUser, tree, getList, locale }) => {
    const [goalPeriods, setGoalPeriods] = useState({});
    const [conditionSimpleLabel, setConditionSimpleLabel] = useState('');
    const [finalConditionString, setFinalConditionString] = useState(null);

    const conditionStringHtml = useMemo(() => {
        return <Trunk8 lines={4}>{finalConditionString}</Trunk8>;
    }, [finalConditionString]);

    const renderTypeLiteral = useMemo(() => {
        if (data.condition && !isEmptyObject(data.condition)) {
            // custom condition
            return (
                <div className="goal-detail-content__info-row__type">
                    <span>{getLiteral('label_custom_type')}</span>
                    {finalConditionString && (
                        <PopoverHoi
                            className="goal-detail-content__info-row__type-popover"
                            placement="top"
                            content={conditionStringHtml}
                            trigger={['hover']}
                        >
                            <span className="goal-detail-content__info-row__type-popover">
                                <Icon name="info" size="small" color={colors.actionMinor[200]} />
                            </span>
                        </PopoverHoi>
                    )}
                </div>
            );
        } else if (data.conditionSimple && !isEmptyObject(data.conditionSimple)) {
            // simple condition
            return conditionSimpleLabel;
        }

        return '';
    }, [data, conditionSimpleLabel, finalConditionString, conditionStringHtml]);

    const renderIsVisibleLiteral = useMemo(() => {
        // Individual goal
        if (data.assignment === 'user') {
            if (data.idParent === -1) {
                return getLiteral('label_individual');
            } else {
                return getLiteral('label_individual_aggregate');
            }
        } else if (data.assignment === 'team') {
            if (data.teamVisible) return getLiteral('label_team');
            else return getLiteral('label_team_no_visible');
        } else if (data.assignment === 'teamaggregated') {
            if (data.teamVisible) return getLiteral('label_team_aggregate');
            else return getLiteral('label_team_aggregate_no_visible');
        }
        return;
    }, [data]);

    const renderPeriodicityLiteral = useMemo(() => {
        if (data.periodicityLiteral)
            return `${goalPeriods[data.frequency]} (${data.periodicityLiteral})`;
        return goalPeriods[data.frequency];
    }, [goalPeriods, data]);

    const renderRow = useCallback((label, value, index) => {
        if (!label || !value) return;

        let finalValue = value;
        if (value.id && value.name) {
            finalValue = <UserChip idUser={value.id} username={value.name} remotePopover={true} />;
        } else if (React.isValidElement(value)) {
            finalValue = value;
        }

        if (finalValue?.name && !finalValue?.id) return;
        return (
            <div className="goals-detail-content__info-row" key={index}>
                <div className="goals-detail-content__info-row-label">{label}</div>
                <div className="goals-detail-content__info-row-data">{finalValue}</div>
            </div>
        );
    }, []);

    const getListCallback = useCallback(
        (field, entity) => {
            if (field === 'null' || !entity) return Promise.resolve();
            const entityConstant = EntitiesDb[entity.toLowerCase()];
            if (!entityConstant) return Promise.resolve();
            const { configField, isExtra } = findFieldConfiguration(field, entityConstant);
            if (!configField || !configField.valueListName) return Promise.resolve();
            const fieldDescription = isExtra
                ? configField.description || ''
                : getLiteral(configField.description);

            return {
                promise: getList(configField.valueListName),
                mapField: { [field]: fieldDescription },
            };
        },
        [getList],
    );

    const rowsData = useMemo(() => {
        return [
            {
                label: getLiteral('label_description'),
                value: getLiteral(data.description),
            },
            {
                label: getLiteral('label_category'),
                value: getLiteral(data.category),
            },
            {
                label: getLiteral('label_type'),
                value: renderTypeLiteral,
            },
            {
                label: getLiteral('label_owner'),
                value: {
                    id: data.owner || '',
                    name: `${data.ownerName} ${data.ownerSurname}` || '',
                },
            },
            {
                label: getLiteral('label_periodicity'),
                value: renderPeriodicityLiteral || '',
            },
            {
                label: getLiteral('label_assignment'),
                value: renderIsVisibleLiteral,
            },
        ];
    }, [
        data.description,
        data.category,
        data.owner,
        data.ownerName,
        data.ownerSurname,
        renderTypeLiteral,
        renderPeriodicityLiteral,
        renderIsVisibleLiteral,
    ]);

    useEffect(() => {
        getList('goalPeriods')
            .then((data) => {
                const periods = data.reduce((obj, period) => {
                    obj[period.value] = period.label;
                    return obj;
                }, {});
                setGoalPeriods(periods);
            })
            .catch(() => {
                console.error('error getting goal periods');
            });

        const hasCustomCondition = data.condition && !isEmptyObject(data.condition);
        if (!hasCustomCondition && data.conditionSimple && !isEmptyObject(data.conditionSimple)) {
            const { entity, field, id } = data.conditionSimple;
            const entityConstant = EntitiesDb[entity.toLowerCase()];
            if (entityConstant) {
                const { configField } = findFieldConfiguration(field, entityConstant);
                if (configField) {
                    getList(configField.valueListName)
                        .then((data) => {
                            const selectedValue = data.find((e) => parseInt(e.value, 10) === id);
                            setConditionSimpleLabel(selectedValue.label || '');
                        })
                        .catch(console.error);
                }
            }
        } else if (hasCustomCondition) {
            const mapValues = mapFieldsValues(data.mappedCondition);
            getList('kpis')
                .then((kpisList) => {
                    const selectedKpi = kpisList.find((k) => k.id === data.kpi);
                    if (!selectedKpi?.entity) return;

                    const { mapValuesPromises, listMapFields } = mapValues.reduce(
                        (obj, field) => {
                            const { promise, mapField } = getListCallback(
                                Object.keys(field)[0],
                                selectedKpi.entity,
                            );

                            obj.mapValuesPromises.push(promise);
                            obj.listMapFields = { ...obj.listMapFields, ...mapField };
                            return obj;
                        },
                        { mapValuesPromises: [], listMapFields: {} },
                    );

                    Promise.all(mapValuesPromises)
                        .then((result) => {
                            const listMap = mapValues.reduce((obj, field, index) => {
                                const key = Object.keys(field)[0];
                                const value = field[key];
                                if (result[index]) {
                                    const valueListValue = result[index].find(
                                        (valueListValue) =>
                                            parseInt(valueListValue.value, 10) === value,
                                    );
                                    if (valueListValue) {
                                        return {
                                            ...obj,
                                            [key]: {
                                                ...obj[key],
                                                [value]: valueListValue.label,
                                            },
                                        };
                                    }
                                }
                                return obj;
                            }, {});
                            if (listMap && !isEmptyObject(listMap)) {
                                const newConditions = mapConditions(
                                    mapToJsonLogic(data.mappedCondition),
                                    listMap,
                                );
                                setFinalConditionString(
                                    getConditionsString(newConditions, locale, listMapFields) ||
                                        null,
                                );
                            } else {
                                setFinalConditionString(data.conditionsString);
                            }
                        })
                        .catch(console.error);
                })
                .catch(console.error);
        }
    }, [
        data.condition,
        data.conditionSimple,
        data.conditionsString,
        data.kpi,
        data.mappedCondition,
        getList,
        getListCallback,
        locale,
    ]);

    if (!data) return;

    return (
        <div className="goals-detail-content__info">
            {rowsData.map((current, index) => {
                return renderRow(current.label, current.value, index);
            })}
        </div>
    );
});

TabInfo.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(TabInfo);
