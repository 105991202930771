import { memo, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Popover, useTheme } from 'hoi-poi-ui';

import { SmartPhone } from 'components/SvgIcons';
import { logEvent } from 'utils/tracking';

import Badge from './Badge';
import PopoverContent from './PopoverContent';

import './styles.scss';

const DownloadAppSection = memo(() => {
    const theme = useTheme();
    const { entityActive, downloadAppLinkAvailable } = useSelector((state) => ({
        entityActive: state.entityList.active,
        downloadAppLinkAvailable: state.config?.userData?.downloadAppLinkAvailable,
    }));
    const hasBeenSeen = localStorage.getItem('downloadAppVisited');
    const [showBadge, setShowBadge] = useState(downloadAppLinkAvailable && !hasBeenSeen);

    const handleClick = useCallback(() => {
        let eventName;
        switch (entityActive) {
            case 'companies':
                eventName = 'accounts';
                break;
            case 'tasks':
                eventName = 'calendar';
                break;
            case 'salesOrders':
                eventName = 'salesorders';
                break;
            case 'billboard':
                eventName = 'newsmanager';
                break;
            default:
                eventName = entityActive;
        }

        logEvent({
            event: eventName,
            functionality: 'downloadApps',
        });
        if (!hasBeenSeen) localStorage.setItem('downloadAppVisited', true);
        setShowBadge(false);
    }, [entityActive, hasBeenSeen]);

    if (!downloadAppLinkAvailable) return null;

    return (
        <Popover content={<PopoverContent />}>
            <div onClick={handleClick} className="fm-download-app">
                <Badge showBadge={showBadge}>
                    <div className="fm-download-app__icon">
                        <SmartPhone color={theme.colors.grey[500]} />
                    </div>
                </Badge>
            </div>
        </Popover>
    );
});

export default DownloadAppSection;
