import { memo, useState, useCallback, useEffect, useRef } from 'react';
import { SectionForm, Checkbox, Text } from 'hoi-poi-ui';
import { errorToast } from 'utils/toast';
import { getLiteral } from 'utils/getLiteral';
import dot from 'utils/dot';
import { isEmptyObject } from 'utils/objects';

import { getAutomationVariables } from 'services/AutomationsService';
import SelectHoi from 'components/SelectHoi';

import AutomationBuilderSidePanel from './AutomationBuilderSidePanel';

const AutomationBuilderTriggerSidePanel = memo(
    ({ data, onChange, onClose, openDiscard, isTouched, setTouched }) => {
        const first = useRef(true);
        const [events, setEvents] = useState();
        const [actions, setActions] = useState();
        const [form, setForm] = useState({
            withCreation: data?.scene?.parameters?.withCreation,
            entity: data?.scene?.parameters?.entity
                ? {
                      label: getLiteral(data?.scene?.parameters?.entity?.description),
                      value: data?.scene?.parameters?.entity?.value,
                      iconType: data?.scene?.parameters?.entity?.value,
                      original: data?.scene?.parameters?.entity,
                  }
                : null,
            action: data?.scene?.parameters?.action
                ? {
                      label: getLiteral(data?.scene?.parameters?.action?.title),
                      subLabel: getLiteral(data?.scene?.parameters?.action?.description),
                      value: data?.scene?.parameters?.action?.value,
                      iconType: data?.scene?.parameters?.action?.icon,
                      original: data?.scene?.parameters?.action,
                  }
                : null,
        });

        useEffect(() => {
            if (!first.current) return;
            first.current = false;
            getAutomationVariables({ name: data.scene.name, type: data.scene.type })
                .then((events) => {
                    const newEvents = events?.map((event) => {
                        const newEvent = {
                            original: event.entity,
                            label: getLiteral(event.entity.description),
                            value: event.entity.value,
                            iconType: event.entity.value,
                            actions: event.action?.map((action) => ({
                                label: getLiteral(action.title),
                                subLabel: getLiteral(action.description),
                                value: action.value,
                                iconType: action.icon,
                                original: action,
                            })),
                        };

                        if (newEvent.value === data?.scene?.parameters?.entity?.value)
                            setActions(newEvent.actions);

                        return newEvent;
                    });
                    setEvents(newEvents);
                })
                .catch((e) => {
                    console.error(e);
                    errorToast({ text: getLiteral('error_generalerror') });
                });
        }, [data]);

        const changeForm = useCallback(
            (field) => (value, valueObject) => {
                setTouched(true);
                if (valueObject?.actions) setActions(valueObject?.actions);
                setForm((current) => ({
                    ...current,
                    [field]: value,
                    withCreation: field === 'action' ? false : current.withCreation,
                }));
            },
            [setTouched],
        );

        const onDone = useCallback(() => {
            const conditions = dot.get(data, 'scene.next.0.parameters');
            const action = dot.get(data, 'scene.next.0.next.0.parameters');
            const newData = {
                entity: form.entity.original,
                action: form.action.original,
                withCreation: form.withCreation,
            };
            if (!isEmptyObject(conditions) || !isEmptyObject(action)) {
                openDiscard(() => {
                    onChange('scene.parameters', newData);
                    onChange('scene.next.0.parameters', {});
                    onChange('scene.next.0.next.0.parameters', {});
                    setTouched(false);
                });
            } else {
                onChange('scene.parameters', newData);
                setTouched(false);
            }
        }, [
            data,
            form?.entity?.original,
            form?.action?.original,
            form?.withCreation,
            openDiscard,
            onChange,
            setTouched,
        ]);

        const creationOnChange = useCallback(() => {
            setTouched(true);
            setForm((current) => ({ ...current, withCreation: !current.withCreation }));
        }, [setTouched]);

        return (
            <AutomationBuilderSidePanel
                title={getLiteral('label_trigger')}
                subtitle={getLiteral('label_trigger_desc')}
                onClose={onClose}
                isDoneDisabled={!isTouched || !form?.action || !form?.entity}
                onDone={onDone}
            >
                <SectionForm title={getLiteral('label_triggering_entity')} isExpandable={false}>
                    <SelectHoi
                        placeholder={getLiteral('label_placeholder_entity')}
                        options={events}
                        onChange={changeForm('entity')}
                        value={form.entity}
                        useMenuPortal={false}
                        isFullWidth
                    />
                </SectionForm>
                <SectionForm title={getLiteral('label_triggering_event')} isExpandable={false}>
                    <SelectHoi
                        placeholder={getLiteral('label_placeholder_event')}
                        options={actions}
                        onChange={changeForm('action')}
                        value={form.action}
                        isFullWidth
                        useMenuPortal={false}
                        isReadOnly={!form.entity}
                    />
                    {form.action?.original?.canCreate && (
                        <div className="fm-automation-builder__checkbox" onClick={creationOnChange}>
                            <Checkbox checked={form.withCreation} />
                            <Text>{getLiteral('label_automation_event_creation_included')}</Text>
                        </div>
                    )}
                </SectionForm>
            </AutomationBuilderSidePanel>
        );
    },
);

export default AutomationBuilderTriggerSidePanel;
