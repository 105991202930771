import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal, Text } from 'hoi-poi-ui';
import moment from 'moment';
import { getLiteral } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';
import Context from '../../../../../../managers/Context';

class ExportDialog extends Component {
    constructor() {
        super();
        this.currentTimeout = null;
        this.numTries = 0;
        this.lapse = 1000;
    }
    state = { token: null, loading: false, error: null };

    cancelExport = () => {
        let { closeExportDialog } = this.props;
        if (this.currentTimeout !== null) {
            clearTimeout(this.currentTimeout);
            this.setState({ loading: false });
        }
        closeExportDialog && closeExportDialog();
    };

    exportFinished = () => {
        let { closeExportDialog } = this.props;
        closeExportDialog && closeExportDialog();
    };

    onExport = () => {
        let { entity, isDownload } = this.props;
        let { token } = this.state;
        this.setState({ loading: true });
        Context.settingsManager.startExcelExport(
            entity,
            token,
            isDownload,
            (success) => {
                this.checkExcelExportStatus(token);
            },
            (error) => {
                errorToast({ text: getLiteral('error_loading') });
            },
        );
    };

    checkExcelExportStatus = (token) => {
        this.currentTimeout = setTimeout(() => {
            Context.settingsManager.getExcelExportStatus(
                token,
                (success) => {
                    if (success.status === '3') {
                        this.numTries = 0;
                        this.lapse = 1000;
                        let urlDownloadDocument = Context.constants.getUrlDownloadDocument();

                        //added a filename otherwise on edge it saves an undefined file without extension
                        Context.utilsFormats.saveAs(
                            `${urlDownloadDocument}?token=${token}`,
                            `exportData_${moment().format('DD_MM_YYYY_HH_mm')}.xlsx`,
                        );
                        this.exportFinished();
                        this.setState({ loading: false });
                        successToast({
                            title: getLiteral('title_download_file_successfull'),
                            text: getLiteral('message_download_file_successfull'),
                        });
                        this.currentTimeout = null;
                    } else {
                        this.numTries++;
                        if (this.numTries >= 10) {
                            this.lapse = 10000;
                        }
                        this.checkExcelExportStatus(token);
                    }
                },
                (error) => {
                    errorToast({ text: getLiteral('error_generalerror') });
                },
            );
        }, this.lapse);
    };

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.showDialog && this.props.showDialog) {
            Context.settingsManager.getExcelExportToken(
                this.props.entity,
                (token) => {
                    this.setState({ token: token, loading: false, error: false });
                },
                (error) => {
                    this.setState({ token: null, loading: false, error: error });
                },
            );
        }
    }

    renderLoading = () => {
        return <Text>{getLiteral('help_file_generation')}</Text>;
    };

    renderStruct = () => {
        const { literalDesc } = this.props;
        return (
            <div>
                <div
                    style={{
                        textAlign: 'center',
                        boxSizing: 'border-box',
                        padding: '15px 15px 0px',
                        lineHeight: '20px',
                        fontWeight: 300,
                        fontSize: 14,
                        fontFamily: '"Sage Text", sans-serif',
                    }}
                >
                    <div
                        style={{
                            marginBottom: '15px',
                            lineHeight: '20px',
                            fontWeight: 300,
                            fontSize: 13,
                        }}
                    >
                        <Text>{getLiteral(literalDesc)}</Text>
                    </div>
                </div>
            </div>
        );
    };

    renderForm = () => {
        let { loading } = this.state;
        return loading ? this.renderLoading() : this.renderStruct();
    };

    render() {
        let { showDialog } = this.props;
        let { loading } = this.state;
        if (!showDialog) {
            return null;
        }

        let renderContent = this.renderForm();
        return (
            <Modal
                isOpen={true}
                onRequestClose={this.cancelExport}
                onCancel={this.cancelExport}
                onConfirm={this.onExport}
                title={getLiteral('title_excel_export_email')}
                confirmText={getLiteral('action_download')}
                cancelText={getLiteral('action_cancel')}
                isConfirmLoading={loading}
            >
                {renderContent}
            </Modal>
        );
    }
}

ExportDialog.propTypes = {
    showDialog: PropTypes.bool,
    onRequestClose: PropTypes.func,
};

export default ExportDialog;
