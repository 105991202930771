import React from 'react';
import colors from 'constants/colors';

const Clock = ({ color = colors.actionMinor[500] }) => (
    <svg viewBox={`0 0 10 10`}>
        <g fill="none" fillRule="evenodd">
            <circle stroke={color || '#335B70'} cx="5" cy="5" r="4.5" />
            <path
                d="M5.5 5V3.5a.5.5 0 0 0-1 0v2A.5.5 0 0 0 5 6h2a.5.5 0 0 0 0-1H5.5z"
                fill={color || '#335B70'}
            />
        </g>
    </svg>
);

export default Clock;
