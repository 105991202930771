import React, { useMemo } from 'react';
import Header from 'containers/components/layouts/Header';
import HeaderTitle from 'containers/components/layouts/HeaderTitle';
import HeaderSection from 'containers/components/layouts/HeaderSection';
import HeaderSectionDefault from 'containers/components/layouts/HeaderSectionDefault';
import ViewType from 'containers/components/ViewType';
import { getLiteral } from 'utils/getLiteral';
import { List, Map } from 'components/SvgIcons';
import { OPPORTUNITIES } from 'constants/Entities';
import colors from 'constants/colors';

const OpportunitiesHeader = React.memo(({ onChange, section }) => {
    const listIconColor = section === 0 ? colors.actionMinor[500] : colors.actionMinor[200];
    const mapIconColor = section === 1 ? colors.actionMinor[500] : colors.actionMinor[200];

    const tabs = useMemo(() => {
        return [
            {
                title: getLiteral('title_list'),
                icon: <List color={listIconColor} />,
            },
            {
                title: getLiteral('label_map'),
                icon: <Map color={mapIconColor} />,
            },
        ];
    }, [listIconColor, mapIconColor]);

    return (
        <Header>
            <HeaderSection>
                <HeaderTitle
                    entity={OPPORTUNITIES}
                    title="title_opportunities"
                    defaultTitleLabel={getLiteral(
                        'label_principal_filter_opportunities_all_title_web',
                    )}
                    defaultOptionLabel={getLiteral(
                        'label_principal_filter_opportunities_all_option_web',
                    )}
                />
            </HeaderSection>
            <HeaderSection>
                <ViewType onChange={onChange} section={section} tabs={tabs} />
            </HeaderSection>
            <HeaderSectionDefault />
        </Header>
    );
});

export default OpportunitiesHeader;
