import React, { memo } from 'react';
import { Text, Icon, useTheme } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';

const ProductLinesEmptyView = memo((props) => {
    const theme = useTheme();
    return (
        <div className="sales-order-product-lines__empty-view">
            <Icon name="unorderedList" />
            <Text
                className="sales-order-product-lines__empty-view-text"
                color={theme.colors.utility.textSecondary}
            >
                {getLiteral('warning_no_products_added_salesorders')}
            </Text>
        </div>
    );
});

export default ProductLinesEmptyView;
