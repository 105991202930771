import React, { memo } from 'react';

const Reports = (props) => (
    <svg width={168} height={144} fill="none" {...props}>
        <g mask="url(#a)">
            <path fill="#fff" d="M143.819 28.512H22.443v57.026H143.82V28.512Z" />
            <path
                fill="#E2E2E2"
                d="M141.583 35.32a38.158 38.158 0 0 1-4.058 3.602c-2.694 2.08-5.772 3.658-8.934 4.906-8.292 3.27-17.535 3.982-26.158 1.612-8.615-2.368-15.541-8.894-24.668-9.685-11.368-.986-19.544 8.979-30.73 9.368-6.03.21-12.118-3.22-16.753-6.798-2.033-1.57-3.774-3.403-5.624-5.17-.529-.505-1.384-1.536-2.26-1.69V76.03h121.438v-42.99c-.568.796-1.888 1.906-2.253 2.282v-.002Z"
            />
            <path
                fill="#00D639"
                d="M102.61 86.26 73.347 86l-28.75 25.586h-.634l-4.572 4.154 23.626.934 8.348.331L102.61 86.26Z"
            />
            <path
                fill="#006716"
                d="M68.962 47.32h-6.264v9.626h6.264V47.32ZM78.162 41.53h-6.264v15.417h6.264V41.529Z"
            />
            <path fill="#00D639" d="M87.363 34.108H81.1v22.839h6.263V34.108Z" />
            <path
                fill="#00D639"
                d="M87.425 57.007H81.04v-22.96h6.385v22.96Zm-6.264-.122h6.143V34.168H81.16v22.717Z"
            />
            <path
                fill="#C6C6C6"
                d="M22.42 55.873v29.374H143.78V56.715a53.943 53.943 0 0 1-9.438-3.239c-4.894-2.206-9.129-5.218-12.97-8.977-3.597-3.521-7.569-6.944-12.957-5.244-2.957.934-5.294 3.182-7.735 5.118-6.984 5.54-15.677 8.784-24.512 9.31-4.467.268-9.115-.1-13.424-1.378-4.155-1.233-7.71-3.918-11.862-5.119-5.878-1.699-14.875-1.2-28.46 8.687Z"
            />
            <path fill="#ABABAB" d="M145.113 61.288H22.955v25.494h122.158V61.288Z" />
            <path
                fill="#777"
                d="M141.681 88.994h-.014l-43.224-.303a1.95 1.95 0 0 1-1.934-1.967 1.95 1.95 0 0 1 1.948-1.939h.014l43.216.303a1.672 1.672 0 0 0 1.662-1.672V30.706c0-.922-.748-1.672-1.668-1.672H26.318c-.92 0-1.668.75-1.668 1.672v52.708c0 .923.748 1.673 1.668 1.673h50.15c1.076 0 1.949.875 1.949 1.953a1.951 1.951 0 0 1-1.948 1.953h-50.15c-3.07 0-5.566-2.502-5.566-5.579V30.707c0-3.076 2.496-5.578 5.565-5.578h115.363c3.069 0 5.565 2.502 5.565 5.578v52.708c0 3.076-2.496 5.579-5.565 5.579Z"
            />
            <path
                fill="#006716"
                d="M88.83 61.273h16.354l-10.57-12.496a16.342 16.342 0 0 0-5.785 12.496Z"
            />
            <path
                fill="#008A21"
                d="m118.834 52.333-13.651 8.941h16.302c0-3.299-.976-6.37-2.651-8.94Z"
            />
            <path
                fill="#00D639"
                d="m105.182 61.273 9.276-13.45a16.23 16.23 0 0 0-9.303-2.916 16.24 16.24 0 0 0-10.543 3.87l10.57 12.496Z"
            />
            <path
                fill="#00460C"
                d="m114.459 47.823-9.276 13.45 13.651-8.94a16.42 16.42 0 0 0-4.374-4.51h-.001Z"
            />
            <path
                fill="#00D639"
                d="m52.404 115.74-8.969-1.427 40.736-36.474 3.442 9.623-35.209 28.278Z"
            />
            <path
                fill="#00D639"
                d="M44.529 115.637c-.54 0-1.077-.224-1.462-.662a1.956 1.956 0 0 1 .173-2.756l25.764-22.765.044-.034c.325-.256.728-.603 1.156-.972 2-1.724 4.488-3.87 6.953-3.87h2.109l-.632 8.031a1.95 1.95 0 0 1-2.096 1.794 1.951 1.951 0 0 1-1.79-2.1l.213-2.696c-.83.609-1.67 1.332-2.215 1.802-.443.383-.864.744-1.245 1.048L45.818 115.15a1.94 1.94 0 0 1-1.288.488l-.001-.001Z"
            />
            <path
                fill="#A97046"
                d="M50.245 57.376a1.152 1.152 0 0 0 1.972-.509 1.155 1.155 0 0 0-.831-1.386l-1.14 1.895Z"
            />
            <path
                fill="#1B1B1B"
                d="M51.365 55.475a1.157 1.157 0 0 0-1.12 1.901l1.14-1.895-.02-.006ZM49.7 55.822a.474.474 0 1 0-.002-.948.474.474 0 0 0 .001.948Z"
            />
            <path
                fill="#fff"
                d="M48.538 82.84c-.939 0-1.84-.527-2.275-1.432-3.133-6.522-2.471-11.92-1.365-15.299 1.195-3.653 3.128-5.767 3.344-5.996l3.663 3.476.013-.013c-.23.252-5.606 6.28-1.11 15.64a2.531 2.531 0 0 1-1.178 3.374c-.352.17-.725.25-1.092.25Z"
            />
            <path
                fill="#474747"
                d="M42.328 118.871a3 3 0 0 1-2.95-3.537l7.029-38.956c.517-2.863 3.09-4.965 5.98-4.893 1.673.043 3.966.765 5.404 3.963a2097.614 2097.614 0 0 0 7.712 16.986 9.583 9.583 0 0 1 .448 6.862l-5.254 16.897a2.994 2.994 0 0 1-3.751 1.973 3.004 3.004 0 0 1-1.969-3.76l5.255-16.897a3.576 3.576 0 0 0-.169-2.56 2094.301 2094.301 0 0 1-7.735-17.034 3.1 3.1 0 0 0-.081-.17l-6.976 38.658a2.997 2.997 0 0 1-2.944 2.468h.001Zm9.973-41.425-.002.01c0-.005.002-.007.003-.01h-.001Z"
            />
            <path
                fill="#474747"
                d="M45.957 116.405h-2.404a.54.54 0 0 0-.538.54v.001c0 .298.24.54.538.54h2.404a.54.54 0 0 0 .539-.54v-.001a.54.54 0 0 0-.539-.54ZM61.888 115.71h-2.404a.538.538 0 0 0-.539.539v.001c0 .298.241.54.538.54h2.405a.54.54 0 0 0 .538-.54v-.001a.539.539 0 0 0-.538-.539Z"
            />
            <path
                fill="#fff"
                d="M46.886 74.167c-1.808-2.755-2.846-4.082-2.426-6.655.853-5.234 2.83-8.67 6.447-9.018 2.025-.195 4.03 1.184 4.706 3.152l.011.034c1.066 3.109 1.051 6.175.122 9.328L55.04 73.4c-.662 2.248-2.977 3.62-5.24 3.03-1.233-.322-2.189-1.154-2.916-2.262Z"
            />
            <path fill="#E84D4F" d="M60.361 81.426s.914-.61 1.75.915l-1.75-.915Z" />
            <path
                fill="#B26E3D"
                d="M62.113 83.447c-.39 0-.767-.206-.968-.572-.15-.272-.269-.392-.33-.44a1.103 1.103 0 0 1-1.373-.395 1.107 1.107 0 0 1 .307-1.534 2.013 2.013 0 0 1 1.556-.247c.708.174 1.304.695 1.773 1.55a1.107 1.107 0 0 1-.966 1.638h.001Zm-1.14-1.101a.413.413 0 0 0 0 0Z"
            />
            <path
                fill="#fff"
                d="M59.2 82.348c-.574 0-1.152-.196-1.625-.595-5.52-4.666-7.12-9.864-7.49-13.401-.398-3.825.502-6.546.605-6.843l4.762 1.67.006-.017c-.108.325-2.553 8.03 5.37 14.726 1.064.9 1.2 2.495.301 3.563a2.516 2.516 0 0 1-1.93.898Z"
            />
            <path
                fill="#00D639"
                d="M112.819 77.861c1.448-1.144.775-3.478-1.057-3.667l-37.597-4.266c-1.936-.2-2.529-2.748-.882-3.792l9.177-4.862-7.142-.018-14.243 7.307c-1.755.8-1.885 3.555.101 4.063l17.934 4.488c1.44.47 1.918 2.866.48 3.951l-8.564 6.668 27.934 2.116L112.82 77.86v.001Z"
            />
        </g>
    </svg>
);

export default memo(Reports);
