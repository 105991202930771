import { memo, useCallback, useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Text, Icon, Popover, useTheme } from 'hoi-poi-ui';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { EntityListActions } from 'actions';

import { KPIS } from 'constants/Entities';
import { EntityModalActions } from 'actions';
import { EntityListService } from 'services';

import GoalKpiModel from 'models/GoalKpiModel';
import { REFRESH_TABLE } from 'lib/events';
import { subscribe } from 'lib/EventBuser';
import { getLiteral } from 'utils/getLiteral';
import { getRoute } from 'utils/routes';
import { logEvent } from 'utils/tracking';
import DashboardWidget from '../components/DashboardWidget';

const EMPTY_SVG = (
    <svg xmlns="http://www.w3.org/2000/svg" width={116} height={100} fill="none">
        <mask
            id="mask0_2421_18180"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="24"
            y="5"
            width="120"
            height="134"
        >
            <path d="M143.293 5.92941H24.7051V138.071H143.293V5.92941Z" fill="white" />
        </mask>
        <g mask="url(#mask0_2421_18180)">
            <path
                d="M87.4611 133.877C85.3327 133.877 83.4999 132.276 83.2622 130.112L75.877 62.7877C75.7587 61.7255 74.8774 60.9575 73.872 61.0442C73.0006 61.1195 72.2931 61.8449 72.1906 62.7693L64.7438 129.437C64.4849 131.755 62.3932 133.426 60.0723 133.166C57.7516 132.907 56.0798 130.818 56.339 128.5L63.7858 61.8319C64.3352 56.9187 68.2721 53.0476 73.1475 52.6283C75.8583 52.3951 78.5054 53.2312 80.6027 54.9846C82.6772 56.7182 83.9842 59.16 84.2846 61.8619L91.6701 129.193C91.9248 131.51 90.249 133.597 87.9267 133.851C87.7702 133.868 87.6149 133.877 87.4611 133.877Z"
                fill="#303030"
            />
            <path
                d="M91.5975 133.412L88.4238 131.987C88.0429 131.816 87.5954 131.985 87.424 132.366C87.2529 132.746 87.4229 133.193 87.8038 133.364L90.9774 134.789C91.3583 134.96 91.8059 134.791 91.977 134.41C92.1483 134.03 91.9784 133.583 91.5975 133.412Z"
                fill="#303030"
            />
            <path
                d="M59.8995 131.212L56.6775 132.525C56.291 132.683 56.1055 133.124 56.2633 133.51C56.4209 133.896 56.8622 134.081 57.249 133.924L60.4707 132.61C60.8575 132.453 61.043 132.012 60.8852 131.626C60.7273 131.24 60.286 131.055 59.8995 131.212Z"
                fill="#303030"
            />
            <path
                d="M58.2087 40.4778C60.2349 43.1757 62.3234 45.8265 64.3411 48.5311C66.0968 50.8834 67.4764 53.5426 69.2064 55.8494C71.8334 59.3548 77.0047 58.3934 78.6494 54.4941L83.0219 44.1086C86.3593 36.1804 79.811 27.6321 71.2766 28.7754C63.3602 29.8362 54.1222 33.6234 58.2073 40.4778H58.2087Z"
                fill="#C6C6C6"
            />
            <path
                d="M31.2051 64.8158C31.2051 64.8158 32.5535 63.0794 34.3973 63.3609L31.2051 64.8158Z"
                fill="#B26E3D"
            />
            <path
                d="M31.2027 66.9501C30.7457 66.9501 30.2843 66.8039 29.8956 66.5023C28.963 65.7798 28.7936 64.4403 29.5169 63.5088C29.7333 63.2301 31.721 60.7954 34.7196 61.252C35.8857 61.4296 36.6859 62.5175 36.5079 63.6822C36.3299 64.8469 35.2407 65.6462 34.0746 65.4683C33.5919 65.3944 32.964 66.0288 32.8928 66.1225C32.4714 66.6644 31.8406 66.9487 31.2042 66.9487L31.2027 66.9501Z"
                fill="#B26E3D"
            />
            <path
                d="M36.5857 65.7143C35.663 65.7143 34.7347 65.3958 33.9815 64.7472C32.3127 63.3095 32.1276 60.794 33.5657 59.1273L60.906 27.4728C61.8203 26.4148 63.2213 25.9113 64.5995 26.1474L82.9869 29.2888C84.0463 29.4695 84.9874 30.0709 85.5984 30.9541L92.1351 40.4225L110.803 15.6921C112.129 13.9359 114.63 13.5846 116.39 14.91C118.15 16.2354 118.5 18.7326 117.173 20.4903L95.1763 49.6303C94.4047 50.653 93.1843 51.2403 91.9073 51.2146C90.6259 51.1878 89.4355 50.5477 88.7079 49.4925L79.9855 36.8601L65.4768 34.3813L39.6084 64.3322C38.8197 65.245 37.7062 65.7143 36.5857 65.7143Z"
                fill="#C6C6C6"
            />
            <path
                d="M117.145 16.1245C117.145 16.1245 117.156 16.0263 117.188 15.8955C117.211 15.7974 117.246 15.6779 117.29 15.5613C117.357 15.385 117.448 15.2172 117.535 15.1091C117.579 15.0551 117.621 15.0138 117.659 14.9811C117.699 14.9498 117.735 14.9271 117.781 14.9057C118.856 14.4208 119.335 13.158 118.849 12.0843C118.364 11.0106 117.1 10.5328 116.024 11.0177C115.644 11.1884 115.303 11.4031 115.005 11.6392C114.482 12.0545 114.1 12.5308 113.816 12.9888C113.604 13.3329 113.446 13.6671 113.323 13.9771C113.141 14.4407 113.039 14.8488 112.978 15.1575C112.916 15.466 112.896 15.6751 112.891 15.7391C112.791 16.9138 113.664 17.9448 114.84 18.0443C116.016 18.1439 117.048 17.2721 117.148 16.0975"
                fill="#B26E3D"
            />
            <path
                d="M117.145 16.1251C117.145 16.1251 117.145 16.1149 117.147 16.0974L117.145 16.1251Z"
                fill="#B26E3D"
            />
            <path
                d="M71.1375 20.7349C70.8828 19.7906 70.0199 19.0952 68.9933 19.0952C67.773 19.0952 66.7819 20.0793 66.7734 21.2952C66.7734 21.3009 66.7734 21.3066 66.7734 21.3123C66.7734 22.5367 67.7673 23.5307 68.9947 23.5307C70.2221 23.5307 71.216 22.5381 71.216 21.3123C71.216 21.1118 71.1889 20.9183 71.1389 20.7335L71.1375 20.7349Z"
                fill="#B26E3D"
            />
            <path
                d="M46.3788 40.3384C46.2066 39.9945 45.896 39.7313 45.5188 39.6246C45.3991 39.5904 45.274 39.5721 45.143 39.5721C44.3812 39.5721 43.7617 40.1907 43.7617 40.9513C43.7617 41.1419 43.8001 41.3226 43.8699 41.4875C44.0209 41.843 44.3185 42.1217 44.6844 42.2513C44.8282 42.3024 44.982 42.3309 45.1416 42.3309C45.9031 42.3309 46.5226 41.7123 46.5226 40.9513C46.5226 40.7308 46.4698 40.5233 46.3774 40.3384H46.3788ZM45.1983 41.6711C45.18 41.6725 45.1613 41.6767 45.143 41.6767C44.7429 41.6767 44.4168 41.3509 44.4168 40.9513C44.4168 40.92 44.4224 40.8904 44.4252 40.8604C44.4636 40.5631 44.6802 40.3228 44.9662 40.2503C45.0233 40.2361 45.0817 40.226 45.143 40.226C45.5315 40.226 45.8477 40.5332 45.8661 40.9172C45.8661 40.9288 45.8689 40.9401 45.8689 40.9513C45.8689 41.3325 45.573 41.6425 45.1983 41.6711Z"
                fill="#00D639"
            />
            <path
                d="M59.8419 23.4468L45.5195 39.6246C45.8968 39.7313 46.2071 39.9945 46.3796 40.3384L60.504 24.3826C60.1635 24.1849 59.9159 23.8465 59.8405 23.4468H59.8419Z"
                fill="#00D639"
            />
            <path
                d="M62.3836 22.4883C62.1427 22.0845 61.7014 21.8114 61.1974 21.8114C60.4356 21.8114 59.8164 22.43 59.8164 23.1909C59.8164 23.2776 59.8249 23.3629 59.8404 23.4468C59.9161 23.8464 60.1637 24.1835 60.5039 24.3826C60.7075 24.502 60.9438 24.5703 61.1974 24.5703C61.8025 24.5703 62.3166 24.1792 62.503 23.6388C62.5516 23.498 62.5784 23.3487 62.5784 23.1923C62.5784 22.9349 62.5058 22.696 62.3836 22.4898V22.4883ZM61.1974 23.9161C61.1135 23.9161 61.0336 23.8991 60.9582 23.872C60.6748 23.7725 60.4698 23.5066 60.4698 23.1894C60.4698 23.0017 60.5437 22.8311 60.6621 22.7031C60.7945 22.558 60.9853 22.4642 61.1974 22.4642C61.2203 22.4642 61.24 22.4684 61.2629 22.4713C61.6331 22.5054 61.9236 22.8126 61.9236 23.1909C61.9236 23.316 61.888 23.4326 61.8324 23.535C61.7085 23.7611 61.4721 23.9161 61.1974 23.9161Z"
                fill="#00D639"
            />
            <path
                d="M74.2312 24.4238L62.3809 22.4897C62.5031 22.6959 62.5757 22.9363 62.5757 23.1923C62.5757 23.3487 62.5489 23.4994 62.5003 23.6388L73.9822 25.5131C73.965 25.4264 73.9551 25.3382 73.9551 25.2472C73.9551 24.9386 74.059 24.6542 74.2312 24.4238Z"
                fill="#00D639"
            />
            <path
                d="M76.6476 24.8177C76.4666 24.2659 75.9468 23.8677 75.3361 23.8677C74.8835 23.8677 74.4834 24.0867 74.2312 24.4238C74.059 24.6541 73.9551 24.9386 73.9551 25.2472C73.9551 25.3382 73.965 25.4278 73.9822 25.5131C74.1073 26.1459 74.6669 26.6252 75.3361 26.6252C75.8488 26.6252 76.2958 26.3436 76.5335 25.9298C76.649 25.7278 76.7159 25.4946 76.7159 25.2472C76.7159 25.0978 76.6916 24.9542 76.6459 24.8191L76.6476 24.8177ZM75.3361 25.9725C75.0783 25.9725 74.8535 25.8373 74.7239 25.634C74.6528 25.5216 74.6084 25.3894 74.6084 25.2458C74.6084 24.93 74.8134 24.6627 75.0955 24.5646C75.1709 24.5375 75.2508 24.5205 75.3347 24.5205C75.5027 24.5205 75.6551 24.5802 75.7788 24.6755C75.9499 24.8077 76.0623 25.0139 76.0623 25.2458C76.0623 25.4775 75.9541 25.6738 75.7904 25.8075C75.665 25.9084 75.5083 25.971 75.3361 25.971V25.9725Z"
                fill="#00D639"
            />
            <path
                d="M85.2203 26.2171L76.6514 24.8177C76.6958 24.9528 76.7215 25.0965 76.7215 25.2458C76.7215 25.4946 76.6545 25.7279 76.5391 25.9284L84.6705 27.2552C84.6892 26.83 84.9026 26.4545 85.2231 26.2142L85.2203 26.2171Z"
                fill="#00D639"
            />
            <path
                d="M86.0473 25.9412C85.737 25.9412 85.4521 26.045 85.2214 26.2171C84.9009 26.4574 84.6889 26.8314 84.6688 27.2581C84.6688 27.2794 84.666 27.2993 84.666 27.3206C84.666 28.0815 85.2855 28.7001 86.0473 28.7001C86.1868 28.7001 86.322 28.6786 86.4502 28.6402C86.826 28.525 87.1335 28.2551 87.2973 27.9037C87.38 27.7259 87.4283 27.5297 87.4283 27.322C87.4283 26.5612 86.8091 25.9426 86.0473 25.9426V25.9412ZM86.0473 28.0459C86.0473 28.0459 86.0402 28.0459 86.036 28.0459C85.6627 28.0402 85.3595 27.7515 85.3267 27.386C85.3253 27.3647 85.3197 27.3434 85.3197 27.3206C85.3197 26.921 85.6458 26.5953 86.0459 26.5953C86.1184 26.5953 86.1882 26.6096 86.2537 26.6295C86.483 26.6992 86.6622 26.8755 86.7348 27.103C86.7563 27.1727 86.7721 27.2438 86.7721 27.3206C86.7721 27.7202 86.446 28.0459 86.0459 28.0459H86.0473Z"
                fill="#00D639"
            />
            <path
                d="M114.639 5.92948C113.877 5.92948 113.258 6.54809 113.258 7.30889C113.258 7.47101 113.288 7.62603 113.339 7.76966L113.987 6.96049L114 6.97043C114.122 6.74147 114.361 6.58363 114.639 6.58363C115.039 6.58363 115.365 6.9093 115.365 7.30889C115.365 7.7085 115.039 8.03417 114.639 8.03417C114.613 8.03417 114.589 8.0299 114.563 8.02705L114.116 8.58453C114.277 8.64995 114.454 8.68691 114.637 8.68691C115.399 8.68691 116.019 8.0683 116.019 7.30747C116.019 6.54665 115.399 5.92804 114.637 5.92804L114.639 5.92948Z"
                fill="#00D639"
            />
            <path
                d="M113.34 7.76959L104.977 18.2192C105.362 18.3031 105.687 18.5477 105.878 18.879L114.117 8.58446C113.756 8.43657 113.471 8.13933 113.34 7.76959Z"
                fill="#00D639"
            />
            <path
                d="M113.912 7.30887C113.912 7.18656 113.946 7.07139 114.001 6.97042L113.988 6.96045L113.34 7.76962C113.472 8.13936 113.756 8.43659 114.117 8.58449L114.564 8.02704C114.2 7.98864 113.912 7.68288 113.912 7.30887Z"
                fill="#00D639"
            />
            <path
                d="M103.434 20.1476L92.9316 33.268C93.2518 33.5167 93.4599 33.9021 93.4656 34.3372C93.5537 34.2576 93.6378 34.1723 93.7146 34.0769L104.277 20.8828C103.904 20.7676 103.599 20.496 103.435 20.1476H103.434Z"
                fill="#00D639"
            />
            <path
                d="M105.878 18.879C105.686 18.5477 105.362 18.3031 104.977 18.2192C104.882 18.1993 104.785 18.1865 104.684 18.1865C103.922 18.1865 103.303 18.8051 103.303 19.5659C103.303 19.7735 103.35 19.9712 103.434 20.1475C103.598 20.4974 103.902 20.7676 104.275 20.8828C104.405 20.9226 104.541 20.9439 104.684 20.9439C105.446 20.9439 106.065 20.3253 106.065 19.5645C106.065 19.3142 105.997 19.0795 105.88 18.8776L105.878 18.879ZM104.754 20.2841C104.731 20.2869 104.708 20.2912 104.684 20.2912C104.284 20.2912 103.958 19.9655 103.958 19.5659C103.958 19.5375 103.963 19.5104 103.966 19.4834C103.999 19.2047 104.188 18.9743 104.446 18.8847C104.522 18.8577 104.601 18.8406 104.685 18.8406C105.056 18.8406 105.357 19.1194 105.403 19.4763C105.407 19.5062 105.411 19.536 105.411 19.5659C105.411 19.9413 105.122 20.2485 104.755 20.2841H104.754Z"
                fill="#00D639"
            />
            <path
                d="M91.1075 33.3801C91.0931 33.3617 91.0776 33.3431 91.0632 33.3233L87.2943 27.9022C87.1291 28.2534 86.8231 28.525 86.4473 28.6388L90.1464 33.9589C90.3031 34.1851 90.4965 34.3742 90.7145 34.5236C90.7074 34.468 90.7032 34.4112 90.7032 34.3542C90.7032 33.9733 90.8582 33.6291 91.1089 33.3787L91.1075 33.3801Z"
                fill="#00D639"
            />
            <path
                d="M93.4634 34.3372C93.4578 33.9035 93.25 33.5167 92.9295 33.268C92.696 33.0858 92.4026 32.9763 92.0824 32.9763C91.7021 32.9763 91.3576 33.1313 91.1072 33.3801C90.8565 33.6305 90.7012 33.9747 90.7012 34.3559C90.7012 34.4126 90.7054 34.4694 90.7127 34.525C90.7966 35.206 91.3777 35.7352 92.0824 35.7352C92.8442 35.7352 93.4634 35.1165 93.4634 34.3559C93.4634 34.3499 93.4634 34.3443 93.4634 34.3372ZM92.0839 35.0809C91.8887 35.0809 91.7123 35.0027 91.5813 34.8777C91.4446 34.7455 91.3576 34.5606 91.3576 34.3559C91.3576 34.1082 91.483 33.8877 91.6739 33.757C91.7905 33.6774 91.9314 33.6291 92.0839 33.6291C92.2005 33.6291 92.3103 33.659 92.4071 33.7087C92.6449 33.8281 92.8101 34.0713 92.8101 34.3542C92.8101 34.5264 92.7474 34.6842 92.6449 34.8079C92.5111 34.9714 92.3103 35.0795 92.0824 35.0795L92.0839 35.0809Z"
                fill="#00D639"
            />
            <path
                d="M54.8146 52.9112L61.3785 46.1449L60.5769 45.3685L53.9746 52.1746C54.3476 52.2912 54.6523 52.5628 54.8146 52.9127V52.9112Z"
                fill="#00D639"
            />
            <path
                d="M52.259 53.9424L40.3672 66.2008L41.2001 66.9459L53.0222 54.7601C52.6662 54.6079 52.3872 54.3106 52.2605 53.9424H52.259Z"
                fill="#00D639"
            />
            <path
                d="M54.8146 52.9113C54.6522 52.5614 54.3476 52.2898 53.9743 52.1732C53.8447 52.132 53.7066 52.1105 53.5629 52.1105C52.8011 52.1105 52.1816 52.7291 52.1816 53.4901C52.1816 53.6479 52.2102 53.8001 52.2587 53.9407C52.3869 54.3092 52.6645 54.6065 53.0205 54.7584C53.1871 54.8296 53.3692 54.8694 53.5615 54.8694C54.3233 54.8694 54.9425 54.2507 54.9425 53.4901C54.9425 53.2826 54.8956 53.0874 54.8129 52.9113H54.8146ZM53.5629 54.2169C53.5629 54.2169 53.5544 54.2169 53.5502 54.2169C53.1558 54.2095 52.8367 53.8882 52.8367 53.4929C52.8367 53.4359 52.8452 53.3805 52.8582 53.328C52.9194 53.0691 53.1188 52.8658 53.3749 52.7961C53.4361 52.7791 53.4988 52.7675 53.5643 52.7675C53.9531 52.7675 54.2691 53.0747 54.2877 53.4587C54.2877 53.47 54.2905 53.4816 54.2905 53.4929C54.2905 53.8924 53.9644 54.2183 53.5643 54.2183L53.5629 54.2169Z"
                fill="#00D639"
            />
            <path
                d="M29.5733 59.3817L32.3953 56.1708L31.561 55.4383L28.6934 58.702C29.0735 58.7958 29.391 59.0462 29.5747 59.3803L29.5733 59.3817Z"
                fill="#00D639"
            />
            <path
                d="M29.5514 59.3478L44.6852 42.2513C44.3178 42.1217 44.0216 41.843 43.8709 41.4875L28.6387 58.6936C28.6558 58.6978 28.6743 58.6992 28.6914 58.7035C29.0573 58.7944 29.3648 59.0304 29.5514 59.3478Z"
                fill="#00D639"
            />
            <path
                d="M29.55 59.3478L29.0644 59.8967C29.0673 59.9136 29.073 59.9308 29.0758 59.9478C29.0801 59.9791 29.0858 60.009 29.0858 60.0418C29.0858 60.4413 28.7597 60.7669 28.3596 60.7669C28.3596 60.7669 28.3582 60.7669 28.3568 60.7669C27.9581 60.7655 27.6335 60.4413 27.6335 60.0418C27.6335 59.989 27.6392 59.9393 27.6506 59.891C27.6947 59.686 27.8257 59.5169 28.0008 59.4158L28.6387 58.695C28.5476 58.6763 28.455 58.6636 28.3596 58.6636C27.5979 58.6636 26.9785 59.2823 26.9785 60.0432C26.9785 60.2208 27.0127 60.3902 27.0753 60.545C27.2163 60.905 27.5039 61.1893 27.8641 61.3288C28.0179 61.3883 28.1845 61.4225 28.3596 61.4225C29.1214 61.4225 29.7407 60.8039 29.7407 60.0432C29.7407 59.8041 29.6795 59.581 29.5727 59.3848C29.5656 59.3718 29.5585 59.3619 29.5514 59.3492L29.55 59.3478Z"
                fill="#00D639"
            />
            <path
                d="M28.1119 59.3605C28.1888 59.332 28.2714 59.315 28.3568 59.315C28.7071 59.315 28.9947 59.5652 29.0631 59.8967L29.5486 59.3478C29.3635 59.0304 29.0545 58.7944 28.6886 58.7035C28.6715 58.6992 28.653 58.6978 28.6359 58.6936L27.998 59.4144C28.0351 59.3932 28.0721 59.3746 28.1119 59.3605Z"
                fill="#00D639"
            />
            <path
                d="M27.4374 70.2494C25.3216 67.9144 25.2476 64.3036 27.3762 61.8819L27.8646 61.3271C27.5044 61.1879 27.2167 60.9035 27.0758 60.5435L26.5418 61.1509C24.0416 63.9964 24.1256 68.2329 26.6002 70.9804C26.764 70.6348 27.0658 70.366 27.436 70.2508L27.4374 70.2494Z"
                fill="#00D639"
            />
            <path
                d="M27.8479 72.2917C27.4478 72.2917 27.1217 71.9659 27.1217 71.5663C27.1217 71.5463 27.126 71.5279 27.1274 71.5079C26.9409 71.3388 26.7658 71.1626 26.6006 70.979C26.5166 71.1566 26.4668 71.3557 26.4668 71.5663C26.4668 72.327 27.0862 72.9456 27.8479 72.9456C28.1854 72.9456 28.4958 72.8234 28.7364 72.6215C28.5157 72.5091 28.2993 72.3854 28.0885 72.2488C28.0131 72.2759 27.9333 72.2931 27.8493 72.2931L27.8479 72.2917Z"
                fill="#00D639"
            />
            <path
                d="M27.9558 70.7613C28.007 70.8068 28.0597 70.848 28.1124 70.8923C28.2434 70.9434 28.3544 71.0301 28.4356 71.1425C28.6905 71.326 28.9553 71.4867 29.2273 71.6259C29.2273 71.6061 29.2301 71.5861 29.2301 71.5663C29.2301 70.8054 28.6107 70.1867 27.849 70.1867C27.7052 70.1867 27.5671 70.2082 27.4375 70.2494C27.5984 70.4273 27.7707 70.5993 27.9558 70.7613Z"
                fill="#00D639"
            />
            <path
                d="M27.1284 71.5079C27.1583 71.1354 27.4687 70.8409 27.8488 70.8409C27.9414 70.8409 28.0311 70.8596 28.1122 70.8923C28.0595 70.8494 28.0069 70.8068 27.9556 70.7613C27.7705 70.5993 27.5982 70.4273 27.4373 70.2494C27.0671 70.3646 26.7653 70.6334 26.6016 70.979C26.7667 71.1625 26.9419 71.3387 27.1284 71.5079Z"
                fill="#00D639"
            />
            <path
                d="M29.225 71.6262C28.953 71.4853 28.6882 71.3246 28.4333 71.1425C28.5202 71.262 28.5729 71.4085 28.5729 71.5677C28.5729 71.8834 28.3693 72.1494 28.0859 72.2491C28.2967 72.3854 28.5131 72.5091 28.7338 72.6215C29.02 72.3798 29.2065 72.0257 29.2236 71.6262H29.225Z"
                fill="#00D639"
            />
            <path
                d="M38.7952 68.1219L36.8588 70.1684L36.8445 70.1839C34.8996 72.3967 31.7344 72.9174 29.2242 71.6259C29.2071 72.0243 29.0206 72.3798 28.7344 72.6215C29.7937 73.1619 30.9499 73.4321 32.1046 73.4307C34.162 73.4307 36.2095 72.5817 37.6718 70.922L39.6424 68.8388C39.2693 68.7292 38.9618 68.4647 38.7938 68.1205L38.7952 68.1219Z"
                fill="#00D639"
            />
            <path
                d="M40.0335 66.1381C39.2717 66.1381 38.6523 66.7567 38.6523 67.5177C38.6523 67.7351 38.7036 67.9387 38.7933 68.1219C38.9628 68.4661 39.2689 68.7292 39.6419 68.8402C39.7658 68.8772 39.8968 68.897 40.0321 68.897C40.7939 68.897 41.4131 68.2783 41.4131 67.5177C41.4131 66.7567 40.7939 66.1381 40.0321 66.1381H40.0335ZM40.2385 68.2103C40.173 68.23 40.1047 68.2427 40.0335 68.2427C39.6519 68.2427 39.3415 67.9471 39.313 67.573C39.3116 67.5547 39.3073 67.536 39.3073 67.5163C39.3073 67.1164 39.6334 66.7909 40.0335 66.7909C40.4336 66.7909 40.7284 67.0896 40.7555 67.4649C40.7555 67.4821 40.7611 67.499 40.7611 67.5163C40.7611 67.573 40.7524 67.6272 40.7411 67.6797C40.6826 67.9341 40.4892 68.1349 40.2399 68.2089L40.2385 68.2103Z"
                fill="#00D639"
            />
            <path
                d="M138.781 138.071C136.987 138.071 135.291 136.995 134.587 135.229L108.999 71.0956C108.931 70.9562 108.71 70.8141 108.416 70.8141C108.085 70.8141 107.857 70.9988 107.82 71.1312L101.373 94.4821C101.367 94.5004 101.367 94.5174 101.369 94.5346C101.374 94.573 101.379 94.61 101.383 94.6484L105.76 132.129C106.049 134.602 104.275 136.84 101.798 137.129C99.3224 137.418 97.0814 135.646 96.7923 133.173L92.4211 95.7405C92.2546 94.5117 92.3384 93.2818 92.6688 92.0843L99.116 68.7335C100.244 64.6492 104.068 61.7966 108.415 61.7966C112.348 61.7966 115.846 64.0845 117.331 67.6272C117.341 67.6512 117.351 67.6755 117.361 67.6981L142.971 131.89C143.894 134.203 142.765 136.826 140.449 137.748C139.9 137.965 139.335 138.069 138.778 138.069L138.781 138.071Z"
                fill="#474747"
            />
            <path
                d="M101.129 134.84H96.3105C95.8576 134.84 95.4902 135.206 95.4902 135.659C95.4902 136.111 95.8576 136.478 96.3105 136.478H101.129C101.582 136.478 101.949 136.111 101.949 135.659C101.949 135.206 101.582 134.84 101.129 134.84Z"
                fill="#474747"
            />
            <path
                d="M137.41 135.262H132.592C132.139 135.262 131.771 135.629 131.771 136.081C131.771 136.533 132.139 136.9 132.592 136.9H137.41C137.863 136.9 138.23 136.533 138.23 136.081C138.23 135.629 137.863 135.262 137.41 135.262Z"
                fill="#474747"
            />
            <path
                d="M79.5153 71.5293C78.8433 71.5293 78.1671 71.4853 77.4891 71.3958L52.1934 68.0595C49.6604 67.7252 47.8776 65.4028 48.2122 62.8715C48.5468 60.3417 50.872 58.5597 53.4064 58.8954L78.7024 62.2317C80.5377 62.4748 82.3459 61.8661 83.6616 60.565C84.9771 59.2636 85.6037 57.4648 85.3786 55.6289L84.5883 49.1596C84.2794 46.6255 86.0848 44.3218 88.622 44.0118C91.1595 43.7032 93.4661 45.5063 93.7764 48.0407L94.5667 54.5097C95.1404 59.2041 93.5386 63.8058 90.1741 67.1337C87.2952 69.9806 83.4951 71.5293 79.5181 71.5293H79.5153Z"
                fill="#909090"
            />
            <path
                d="M47.1202 60.7087C46.095 60.1243 44.7908 60.4811 44.2072 61.505C43.6218 62.5288 43.9793 63.8315 45.0045 64.4146C45.0842 64.4601 45.4159 64.6436 45.94 64.8314C46.202 64.9251 46.5137 65.0188 46.8712 65.0914C47.2283 65.164 47.6313 65.2168 48.0757 65.2168C48.4687 65.2168 48.8942 65.1753 49.3372 65.0646C49.78 64.955 50.2385 64.7743 50.6756 64.5083C51.685 63.8985 52.0069 62.5872 51.3959 61.5789C50.7851 60.5707 49.4725 60.2493 48.4628 60.8592C48.4103 60.8906 48.3645 60.9092 48.3035 60.9247C48.2423 60.9403 48.1669 60.9504 48.0743 60.9504C47.9718 60.9504 47.8492 60.9374 47.7253 60.912C47.5403 60.875 47.3565 60.8109 47.2371 60.7598C47.1772 60.7341 47.1329 60.713 47.1103 60.7028L47.0987 60.6971H47.0959"
                fill="#E84D4F"
            />
            <path
                d="M47.1206 60.7087C47.103 60.6986 47.0957 60.6957 47.0957 60.6957L47.1206 60.7087Z"
                fill="#E84D4F"
            />
            <path
                d="M58.7642 49.7201C58.5634 49.7201 58.3599 49.6916 58.1574 49.632C57.0272 49.2977 56.3806 48.1115 56.7152 46.981C57.2036 45.3329 58.9762 44.7555 60.4671 44.4356C61.5663 44.2009 62.6098 44.11 62.6539 44.1055C63.8288 44.0047 64.8639 44.8749 64.965 46.0481C65.066 47.2216 64.1961 48.2538 63.0212 48.3563C62.0787 48.4388 60.9711 48.6579 60.484 48.8482C60.0839 49.3928 59.4433 49.7201 58.7628 49.7201H58.7642Z"
                fill="#E84D4F"
            />
            <path
                d="M113.112 68.0095C111.402 68.0095 109.759 67.0597 108.954 65.4243C103.596 54.5269 94.6563 48.5737 82.3812 47.732C72.7121 47.068 64.4852 49.8949 64.4024 49.9234C61.9948 50.7767 59.3537 49.5154 58.4993 47.1106C57.6449 44.7044 58.9107 42.0607 61.3186 41.2088C61.7158 41.068 71.173 37.7746 82.6803 38.4884C98.4308 39.4667 110.388 47.3721 117.261 61.3499C118.387 63.6409 117.44 66.4111 115.147 67.536C114.49 67.8576 113.795 68.0095 113.111 68.0095H113.112Z"
                fill="#909090"
            />
            <path
                d="M86.7012 45.1722L88.4811 56.2518L95.4408 62.7436C100.027 67.0213 109.512 69.8626 115.259 67.3468L115.186 64.4586L104.778 50.9176L86.7012 45.1722Z"
                fill="#909090"
            />
            <path
                d="M82.6172 36.6169C82.0847 37.0122 81.7402 37.645 81.7402 38.3588C81.7402 39.5577 82.7141 40.5304 83.9144 40.5304C85.1146 40.5304 86.0888 39.5577 86.0888 38.3588C86.0888 38.1072 86.0444 37.8641 85.9662 37.6393L82.6186 36.6169H82.6172Z"
                fill="#E84D4F"
            />
            <path
                d="M83.9163 36.1889C83.4309 36.1889 82.9808 36.3482 82.6191 36.617L85.9667 37.6394C85.6691 36.7946 84.8633 36.1875 83.9149 36.1875L83.9163 36.1889Z"
                fill="#303030"
            />
        </g>
    </svg>
);

const GoalsDashboard = memo(() => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const idUser = useSelector((state) => state?.config?.userData?.idUsuario);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [list, setList] = useState();

    const getList = useCallback(() => {
        setLoading(true);
        setError(false);

        EntityListService.get(KPIS, {
            userId: idUser,
            total: 3,
        })
            .then((data) => {
                const goals = data.slice(0, 3);
                setList(goals?.length ? GoalKpiModel.toList({ data: goals }) : goals);
            })
            .catch((error) => {
                console.error(error);
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [idUser]);

    useEffect(() => {
        dispatch(EntityListActions.setUseLazyLoad(KPIS, true));
        return subscribe(`${REFRESH_TABLE}_${KPIS.entity}`, () => {
            getList();
        });
    }, [dispatch, getList]);

    useEffect(() => {
        getList();
    }, [getList]);

    const onViewMore = useCallback(() => {
        logEvent({
            event: 'dashboard',
            submodule: KPIS.trueName,
            functionality: 'list',
        });
        window.open(getRoute(KPIS.route), '_blank');
    }, []);

    const onAdd = useCallback(() => {
        dispatch(
            EntityModalActions.init({
                entity: KPIS,
                data: {},
                labels: {
                    title: getLiteral('title_kpi_new_goal'),
                    success: getLiteral('succes_entitycreatedsuccessfully'),
                    error: getLiteral('error_insert_goal'),
                },
                hideDelete: true,
            }),
        );

        logEvent({
            event: 'dashboard',
            submodule: KPIS.trueName,
            functionality: 'create',
        });
    }, [dispatch]);

    const emptyActions = useMemo(() => {
        return [
            <Button type="secondary" size="small" onClick={onAdd}>
                {getLiteral('action_add_goal')}
            </Button>,
        ];
    }, [onAdd]);

    const goal = useMemo(() => {
        if (!list) return null;
        return list.map((goal) => {
            const percentage = goal.progressPercentage;

            let pathColor = theme.colors.semantic.negative500;
            if (percentage >= 25 && percentage < 75) pathColor = theme.colors.semantic.focus500;
            else if (percentage >= 75) pathColor = theme.colors.semantic.positive500;

            const styles = buildStyles({
                rotation: -0.25,
                strokeLinecap: 'round',
                pathTransition: 'none',
                pathColor: pathColor,
                trailColor: theme.colors.grey[50],
            });

            return (
                <div className="fm-goals-dashboard-widget__goal" key={goal.id}>
                    <Text type="captionMedium" className="fm-goals-dashboard-widget__goal__name">
                        {goal.name}
                    </Text>
                    <CircularProgressbarWithChildren styles={styles} value={percentage}>
                        <Text type="h6" color={theme.colors.utility.textPrimary}>
                            {percentage}%
                        </Text>
                        <div className="fm-goals-dashboard-widget__goal__divider"></div>
                        <Text type="subtitle" color={theme.colors.utility.textSecondary}>
                            {goal.progressValueText}
                        </Text>
                        <Popover
                            trigger={['hover']}
                            placement="bottom"
                            content={
                                <div className="fm-goals-dashboard-widget__goal__popover">
                                    <div className="fm-goals-dashboard-widget__goal__popover-row">
                                        <Text color={theme.colors.utility.textSecondary}>
                                            {getLiteral('label_current_target')}
                                        </Text>
                                        <Text
                                            color={theme.colors.utility.textPrimary}
                                            isTruncated
                                            useTooltip
                                        >
                                            {goal.progressValueText}
                                        </Text>
                                    </div>
                                    <div className="fm-goals-dashboard-widget__goal__popover-row">
                                        <Text color={theme.colors.utility.textSecondary}>
                                            {getLiteral('label_overall_target')}
                                        </Text>
                                        <Text
                                            color={theme.colors.utility.textPrimary}
                                            isTruncated
                                            useTooltip
                                        >
                                            {goal.goal}
                                        </Text>
                                    </div>
                                </div>
                            }
                        >
                            <span>
                                <Icon name="info" size="small" />
                            </span>
                        </Popover>
                    </CircularProgressbarWithChildren>
                    <div className="fm-goals-dashboard-widget__goal__freq">
                        <Text color={theme.colors.utility.textPrimary} type="caption">
                            {goal.frequency}
                        </Text>
                        <Text color={theme.colors.utility.textSecondary} type="caption">
                            {goal.assignmentLabel}
                        </Text>
                    </div>
                </div>
            );
        });
    }, [list, theme]);

    const showList = !error && !loading;

    return (
        <DashboardWidget
            title={getLiteral('label_goals')}
            // Actions
            onViewMore={onViewMore}
            onAdd={onAdd}
            addTooltip={getLiteral('action_add_goal')}
            // State
            isLoading={loading}
            isEmpty={list?.length === 0 && !error}
            isError={error}
            // Empty view
            emptyImage={EMPTY_SVG}
            emptyTitle={getLiteral('label_empty_widget_goals')}
            emptySubtitle={getLiteral('label_empty_widget_goals_desc')}
            emptyActions={emptyActions}
        >
            {showList && (
                <div className="fm-goals-dashboard-widget__container">
                    <div className="fm-goals-dashboard-widget__goals">{goal}</div>
                </div>
            )}
        </DashboardWidget>
    );
});

export default GoalsDashboard;
