export const getVar = (cssVar) => {
    if (typeof cssVar !== 'string' || !cssVar.startsWith('var(') || !cssVar.endsWith(')')) {
        throw new TypeError('Expected a CSS variable in the format "var(--name)"');
    }
    try {
        cssVar = cssVar.replace(/^var\(|\)$/g, '');
        const rootStyles = getComputedStyle(document.documentElement);
        return rootStyles.getPropertyValue(cssVar).trim();
    } catch (error) {
        console.error('Error accessing computed styles:', error);
        return '';
    }
};

export const addAlpha = (color, opacity) => {
    if (color.startsWith('var(')) color = getVar(color);
    const finalOpacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + finalOpacity.toString(16).toUpperCase();
};

export const hex2rgba = (hex = '#FFFFFF', alpha = 100) => {
    if (hex.startsWith('var(')) hex = getVar(hex);

    // Expand shorthand (e.g., #fff to #ffffff)
    if (/^#([a-f\d]{3})$/i.test(hex)) {
        hex = hex.replace(
            /^#([a-f\d])([a-f\d])([a-f\d])$/i,
            (_, r, g, b) => `#${r}${r}${g}${g}${b}${b}`,
        );
    }

    const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha / 100})`;
};
