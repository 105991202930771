import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { rrulestr } from 'rrule';
import Context from 'managers/Context';
import { getLiteral } from 'utils/getLiteral';
import { FuzzyMap } from 'utils/fuzzy';
import { AGENDA, TASKS } from 'constants/Entities';
import { getMonthShortLiteral, formatDateToBackendUTC, getDateToTimezone } from 'utils/dates';
import { UsersService } from 'services';
import { MAP_PIN } from 'constants/Images';
import { AGENDA_EVENT, AGENDA_TASK, ATTENDEE_STATUS } from 'constants/Constants';
import { ensureRoute, getCurrentPath } from 'utils/routes';
import { getBackendBoolean } from 'utils/fm';
import colors from 'constants/colors';
import { AGENDA_TASK_FLAG, AGENDA_TASKS_COMPLETED_FLAG } from 'constants/Constants';
import model from 'models/AgendaModel';
import { PAGINATION_TABLE_AGENDA } from 'constants/Environment';
import { getViewOptions } from 'containers/agenda/utils';
import { getCrudFieldConfig } from 'utils/fm/fields';
import { getGroupByParentValue } from 'utils/getGroupByParentValue';
import { getActiveCrud, getCustomizeCrudAction } from 'utils/crud';
import { getNewStrRuleWithOffset } from 'utils/rrule';

let savedVideoCallProviderId = '';

export default class AgendaManager {
    getSchema = (defaultInputSchema, crudTab) => {
        const state = Context.store.getState();
        const currentUser = `${state.config.userData.nombre} ${state.config.userData.apellidos}`;

        const eventInfoFields = [
            'asunto',
            'eventDate',
            'minutosAviso',
            'user',
            'idTipoGestion',
            'videoCallProviderId',
            'account',
            'idExpediente',
            'comment',
        ];
        const taskInfoFields = [
            'asunto',
            'taskDate',
            'minutosAviso',
            'user',
            'idTipoGestion',
            'account',
            'idExpediente',
            'contact',
            'comment',
        ];

        let infoFields = [];

        if (crudTab) {
            infoFields = crudTab === AGENDA_EVENT ? eventInfoFields : taskInfoFields;
        }

        const attendeesFieldsSchema = [
            {
                id: 'contact',
                serverId: 'idContacto',
                fieldConfiguration: 'idcontacto',
                type: 'fuzzySearchMultiple',
                mandatory: false,
                label: getLiteral('label_contact'),
                inputAttrs: {
                    ...FuzzyMap.contactos,
                    parentField: 'idempresa',
                    parentFieldForBackend: 'idempresa',
                    parentFieldForContext: 'account',
                    shouldRenderField: this.shouldRenderField,
                    chipWithLabel: true,
                    getChipProps: this.getChipProps('contact'),
                },
                spaced: true,
            },
            {
                id: 'usersAttendees',
                serverId: 'usersAttendees',
                fieldConfiguration: 'usersAttendees',
                type: 'usersAttendeesField',
                mandatory: false,
                label: getLiteral('label_invite_users'),
                hint: getLiteral('label_invite_users'),
                inputAttrs: {
                    ...FuzzyMap.fullUsuarios,
                    customOnChange: this.customOnChange,
                    getAdviceContent: this.getAdviceContent,
                    chipWithLabel: true,
                    getChipProps: this.getChipProps('usersAttendees'),
                },
                isCustom: true,
            },
            {
                id: 'availableTimes',
                type: 'selectCustom',
                mandatory: false,
                label: getLiteral('title_find_time'),
                hint: getLiteral('label_selectone'),
                inputAttrs: {
                    dataPath: ['agenda', 'crud', 'availableTimes'],
                    crudDependant: true,
                    crudDependantKey: 'usersAttendees',
                    shouldRenderField: this.shouldRenderField,
                },
            },
            {
                id: 'externalAttendees',
                type: 'textarea',
                mandatory: false,
                // readOnly: true,
                label: getLiteral('label_invite_by_email'),
                hint: 'email1@email.com;email2@email.com',
                inputAttrs: {
                    rows: 4,
                    rowsMax: 10,
                    maxLength: 8000,
                },
            },
            {
                id: 'notifyAllParticipants',
                type: 'notifyAllParticipants',
                label: getLiteral('label_calendar_send_invites'),
                inputAttrs: {
                    shouldRenderField: this.shouldRenderField,
                },
                isCustom: true,
            },
        ];

        const infoFieldsSchema = [
            {
                ...getCrudFieldConfig(AGENDA, 'asunto'),
                type: 'text',
                mandatory: true,
                label: getLiteral('label_subject'),
                hint: getLiteral('label_subject'),
                spaced: true,
            },
            {
                id: 'eventDate',
                type: 'dateTimeGroup',
                label: getLiteral('common_date'),
                customProcessField: this.customProcessField,
                fieldIds: {
                    startDate: 'fini',
                    startTime: 'hini',
                    endTime: 'hfin',
                    isAllDay: 'isTodoDia',
                    idTimezone: 'idTimezone',
                },
                inputAttrs: {
                    interval: 30,
                    intervalChange: 60,
                    afterOnChange: this.afterOnChange,
                    getCurrentTimezone: this.getCurrentTimezone,
                    getTimezoneDate: this.getTimezoneDate,
                },
                inputs: [
                    {
                        ...defaultInputSchema,
                        id: 'fini',
                        serverId: 'Fini',
                        fieldConfiguration: 'fini',
                        type: 'date',
                        inputAttrs: {
                            // classSufix: 'time',
                        },
                    },
                    {
                        id: 'times',
                        inputs: [
                            {
                                ...defaultInputSchema,
                                id: 'hini',
                                serverId: 'Hini',
                                fieldConfiguration: 'hini',
                                type: 'time',
                                mandatory: true,
                                inputAttrs: {
                                    dateType: 'time',
                                },
                            },
                            {
                                ...defaultInputSchema,
                                id: 'hfin',
                                serverId: 'Hfin',
                                fieldConfiguration: 'hfin',
                                type: 'time',
                                mandatory: true,
                                inputAttrs: {
                                    dateType: 'time',
                                    // shouldRenderField: this.shouldRenderField,
                                },
                            },
                        ],
                    },
                    {
                        ...defaultInputSchema,
                        id: 'idTimezone',
                        serverId: 'idTimeZone',
                        type: 'timezone',
                    },
                    {
                        ...defaultInputSchema,
                        id: 'isTodoDia',
                        serverId: 'isTodoDia',
                        fieldConfiguration: 'blntodoeldia',
                        type: 'checkbox',
                        // inputAttrs: {
                        //     shouldRenderField: this.shouldRenderField,
                        // },
                    },
                ],
            },
            {
                id: 'taskDate',
                type: 'dateTimeGroup',
                label: getLiteral('common_date'),
                customProcessField: this.customProcessField,
                fieldIds: {
                    startDate: 'fini',
                    startTime: 'hini',
                    idTimezone: 'idTimezone',
                },
                inputAttrs: {
                    getCurrentTimezone: this.getCurrentTimezone,
                    getTimezoneDate: this.getTimezoneDate,
                },
                inputs: [
                    {
                        ...defaultInputSchema,
                        id: 'fini',
                        serverId: 'Fini',
                        fieldConfiguration: 'fini',
                        type: 'date',
                        inputAttrs: {
                            // classSufix: 'time',
                        },
                    },
                    {
                        id: 'times',
                        inputs: [
                            {
                                ...defaultInputSchema,
                                id: 'hini',
                                serverId: 'Hini',
                                fieldConfiguration: 'hini',
                                type: 'time',
                                mandatory: true,
                                inputAttrs: {
                                    dateType: 'time',
                                },
                            },
                        ],
                    },
                    {
                        ...defaultInputSchema,
                        id: 'idTimezone',
                        serverId: 'idTimeZone',
                        type: 'timezone',
                    },
                ],
            },
            {
                id: 'minutosAviso',
                serverId: 'MinutosAviso',
                fieldConfiguration: 'minutosaviso',
                type: 'singleValueList',
                mandatory: false,
                label: getLiteral('title_event_reminder'),
                inputAttrs: {
                    options: [
                        { value: '1', label: getLiteral('label_reminder_start_hour') },
                        { value: '5', label: getLiteral('label_reminder_five_minutes') },
                        {
                            value: '15',
                            label: getLiteral('label_reminder_fifteen_minutes'),
                        },
                        { value: '30', label: getLiteral('label_reminder_thirty_minutes') },
                        { value: '60', label: getLiteral('label_reminder_one_hour') },
                        { value: '1440', label: getLiteral('label_reminder_day') },
                        { value: '10080', label: getLiteral('label_reminder_week') },
                    ],
                },
            },
            {
                id: 'user',
                serverId: 'IdUsuario',
                fieldConfiguration: 'idusuario',
                type: 'fuzzySearchSingle',
                mandatory: true,
                label: getLiteral('label_owner'),
                hint: getLiteral('label_owner'),
                inputAttrs: {
                    ...FuzzyMap.viewusuariosfullname,
                    defaultSearch: currentUser,
                },
                hasInviteUsers: true,
            },
            {
                id: 'idTipoGestion',
                serverId: 'idTipoGestion',
                fieldConfiguration: 'idtipogestion',
                type: 'singleValueList',
                mandatory: false,
                label: getLiteral('label_activity_type'),
                inputAttrs: {
                    list: 'tbltiposgestion',
                    customFilterOptions: this.filterActivityTypeOptions,
                    actions: getCustomizeCrudAction(
                        AGENDA,
                        '/settings/values-list',
                        `?entity=${AGENDA.trueName}&list=tblTiposGestion`,
                    ),
                },
            },
            {
                id: 'videoCallProviderId',
                serverId: 'videoCallProviderId',
                type: 'videocall',
                mandatory: false,
                label: getLiteral('label_online_meeting'),
                inputAttrs: {
                    list: 'tblVideoCallProviders',
                    shouldRenderField: this.shouldRenderField,
                },
                isCustom: true,
            },
            {
                id: 'account',
                serverId: 'idEmpresa',
                fieldConfiguration: 'idempresa',
                type: 'fuzzySearchSingle',
                mandatory: false,
                label: getLiteral('label_account'),
                hint: getLiteral('label_account'),
                inputAttrs: {
                    ...FuzzyMap.empresas,
                },
            },
            {
                id: 'idExpediente',
                serverId: 'idExpediente',
                fieldConfiguration: 'idexpediente',
                type: 'fuzzySearchSingle',
                mandatory: false,
                label: getLiteral('title_opportunity'),
                hint: getLiteral('title_opportunity'),
                inputAttrs: {
                    ...FuzzyMap.expedientes,
                    parentField: 'idempresa',
                    parentFieldForBackend: 'idinstalador',
                    parentFieldForContext: 'account',
                    parentFieldNoRemove: true,
                    groupFunction: getGroupByParentValue,
                    mainGroupLabel: getLiteral('label_related_opportunities'),
                    otherGroupLabel: getLiteral('label_other_opportunities'),
                },
            },
            attendeesFieldsSchema[0],
            {
                ...getCrudFieldConfig(AGENDA, 'comment'),
                type: 'textarea',
                mandatory: false,
                label: getLiteral('label_observations'),
                inputAttrs: {
                    rows: 4,
                    rowsMax: 10,
                    maxLength: 8000,
                },
            },
        ];

        const finalInfoFieldsSchema = infoFieldsSchema.reduce((arr, current) => {
            if (infoFields.includes(current.id)) arr.push(current);
            return arr;
        }, []);

        const notGeolocatedLabel =
            crudTab === AGENDA_EVENT
                ? getLiteral('error_eventnotgeolocalized')
                : getLiteral('error_tasknotgeolocalized');

        let schema = [
            {
                title: getLiteral('label_info'),
                show: true,
                fields: [...finalInfoFieldsSchema],
            },
        ];

        if (crudTab === AGENDA_EVENT)
            schema.push({
                title: getLiteral('label_attendees'),
                section: 'attendees',
                show: true,
                fields: [...attendeesFieldsSchema],
            });

        schema.push({
            title: getLiteral('label_address_detail'),
            show: true,
            fields: [
                {
                    ...defaultInputSchema,
                    id: 'geolocation',
                    fieldConfiguration: '',
                    type: 'geolocation',
                    label: '',
                    hiddenForBulk: true,
                    inputAttrs: {
                        latitude: 'lat',
                        longitude: 'lon',
                        isGeoLocated: 'isGeoLocated', // custom field created in model
                        address1: 'address',
                        address2: 'address2',
                        city: 'city',
                        province: 'province',
                        cp: 'cp',
                        country: 'country',
                        idCountry: 'idCountry',
                        mapMarkerIcon: MAP_PIN,
                        showAddress: true,
                        notGeolocatedLabel,
                    },
                    //This inputs despide don't being used directly in the render
                    //(only to consider it as a multiple field), are necessary to parse values.
                    isMultipleField: true,
                    inputs: [
                        {
                            id: 'lat',
                            serverId: 'Lat',
                            // fieldConfiguration: 'geocodelat',
                            type: 'decimal',
                            label: getLiteral('label_latitude'),
                        },
                        {
                            id: 'lon',
                            serverId: 'Lon',
                            // fieldConfiguration: 'geocodelon',
                            type: 'decimal',
                            label: getLiteral('label_longitude'),
                        },
                    ],
                },
            ],
        });
        return schema;
    };

    getCalendarComing(entity, entityId, success, error) {
        const startDate = moment().format('YYYY-MM-DDT00:00:00');
        const endDate = moment(startDate).add(10, 'years').format('YYYY-MM-DDT00:00:00');

        this.getCalendarByPeriod(
            entity,
            entityId,
            startDate,
            endDate,
            1,
            0,
            2,
            true,
            false,
            success,
            error,
        );
    }

    getCalendarByPeriod(
        entity,
        entityId,
        startDate,
        endDate,
        order,
        taskFlag,
        completedFlag,
        useUTCDates,
        newAttendees,
        success,
        error,
    ) {
        this.context.domainManager.getCalendarByPeriod(
            entity,
            entityId,
            startDate,
            endDate,
            order,
            taskFlag,
            completedFlag,
            useUTCDates,
            newAttendees,
            (data) => {
                if (data) {
                    const model = this.context.entityManager.getModel(AGENDA);
                    success && success(model.toWidget(data));
                } else {
                    error && error();
                }
            },
            error,
        );
    }

    getEntity(id, success, error) {
        this.context.domainManager.getEntity(
            id,
            AGENDA.entity,
            true,
            (data) => {
                success(data);
            },
            error,
        );
    }

    getFilterSchema = () => {
        return [
            // Alternative filters
            // Schema needed to parse correctly the filters from cache in loadFiltersCache
            // -->
            {
                id: 'fini',
                hideForCount: true,
                excludeFromCache: true,
            },
            {
                id: 'ffin',
                hideForCount: true,
                excludeFromCache: true,
            },
            {
                id: 'taskFlag',
                hideForCount: true,
            },
            {
                id: 'completed',
                hideForCount: true,
            },
            {
                id: 'useUTCDates',
                hideForCount: true,
            },
            {
                id: 'newAttendees',
                hideForCount: true,
            },
            // <--
            {
                id: 'idusuario',
                dataType: 'singleValueList',
                description: getLiteral('label_responsible'),
                isRemovable: false,
                inputAttrs: {
                    ...FuzzyMap.users,
                    defaultSearch: '',
                    minSelection: 1,
                    backupSelection: this.getDefaultSelection('idusuario'),
                    overrides: {
                        chip: {
                            CloseIcon: {
                                getClassName: () => {
                                    return 'agenda-quickfilter-chip-idusuario-close-disabled';
                                },
                            },
                            DividerIcon: {
                                getClassName: () => {
                                    return 'agenda-quickfilter-chip-idusuario-divider-disabled';
                                },
                            },
                        },
                        multiValue: {
                            getStyles: ({ data }) => {
                                const state = Context.store.getState();
                                const filters = state[AGENDA.entity]?.filtersColor?.filters || {};

                                if (
                                    !filters['idusuario'] ||
                                    !filters['idusuario']?.colors?.[data.value]
                                )
                                    return {};

                                const color = filters['idusuario']?.colors?.[data.value];

                                return {
                                    backgroundColor: color.default,
                                    '&:hover': {
                                        backgroundColor: color.hover,
                                    },
                                };
                            },
                        },
                        multiValueLabel: {
                            getStyles: () => {
                                return {
                                    color: 'white',
                                };
                            },
                        },
                        multiValueRemove: {
                            getStyles: ({ data }) => {
                                const state = Context.store.getState();
                                const filters =
                                    state.entityFilters[AGENDA.entity]?.filters?.['idusuario']
                                        ?.value || [];
                                if (!filters.length) return {};

                                let showClose = true;

                                if (filters.length > 1) showClose = true;
                                else if (filters.length > 0 && filters[0] === data.value) {
                                    showClose = false;
                                }
                                return {
                                    display: showClose ? 'flex' : 'none',
                                    '& svg path': {
                                        fill: 'white',
                                    },
                                    '&:hover': {
                                        backgroundColor: 'initial',
                                        '& svg path': {
                                            fill: 'white',
                                        },
                                    },
                                };
                            },
                        },
                        clearIndicator: {
                            getStyles: () => {
                                return {
                                    display: 'none',
                                };
                            },
                        },
                        indicatorSeparator: {
                            getStyles: () => {
                                return {
                                    display: 'none',
                                };
                            },
                        },
                    },
                },
                hint: getLiteral('action_searchowner'),
            },
            {
                id: 'idsucursal',
                dataType: 'singleValueList',
                description: getLiteral('label_account'),
                inputAttrs: {
                    ...FuzzyMap.empresas,
                },
                hint: getLiteral('action_searchcompany'),
                isRemovable: false,
            },
            {
                id: 'idexpediente',
                dataType: 'singleValueList',
                description: getLiteral('title_opportunity'),
                inputAttrs: {
                    ...FuzzyMap.expedientes,
                },
                hint: getLiteral('action_searchopportunity'),
                isRemovable: false,
            },
        ];
    };

    shouldRenderField = (field) => {
        const state = Context.store.getState();

        const activeCrud = getActiveCrud(state);
        const crudData = activeCrud?.data;
        const crudTab = activeCrud?.crudTab;

        if (crudTab && crudTab === AGENDA_TASK && (field === 'hfin' || field === 'isTodoDia')) {
            return false;
        }

        if (field === 'availableTimes') {
            if (!crudData?.usersAttendees?.length) {
                return false;
            }
        }

        if (field === 'videoCallProviderId') {
            if (crudData?.idTipoGestion) return true;
            return false;
        }

        if (field === 'contact') {
            if (!crudData?.account) return false;
        }

        return true;
    };

    customOnChange = (fieldId, values) => {
        if (fieldId === 'usersAttendees') {
            this.checkAvailability(values);
        }
    };

    changeFields = (fields) => {
        const state = Context.store.getState();
        const entityCrud = getActiveCrud(state);
        const crudData = entityCrud.data;

        let newFields = { ...fields };

        if (fields.fini || fields.hini || fields.hfin || fields.isTodoDia) {
            if (crudData?.usersAttendees?.length) {
                this.checkAvailability(crudData.usersAttendees);
            }
        }

        if (fields.fini || fields.hini) {
            newFields.availableTimes = null;
        }

        if (fields.hasOwnProperty('fini') && !fields.fini && !savedVideoCallProviderId) {
            newFields.videoCallProviderId = '';
            newFields.videoCallMeetingDetails = null;
        }

        if (fields.availableTimes) {
            const dateMoment = moment(fields.availableTimes.value);
            newFields.fini = dateMoment.toDate();
            newFields.hini = dateMoment.toDate();
            newFields.hfin = dateMoment.add(30, 'minutes').toDate();

            this.checkAvailability(crudData.usersAttendees);
        }

        if (fields.hasOwnProperty('idTipoGestion')) {
            const enableVideoCalls = state.config.permission.enableVideoCalls || false;
            if (
                enableVideoCalls &&
                !crudData.id &&
                !savedVideoCallProviderId &&
                !fields?.idTipoGestion
            ) {
                newFields.videoCallProviderId = '';
                newFields.videoCallMeetingDetails = null;
            }
        }

        if (fields.externalAttendees) {
            newFields.externalAttendees = fields.externalAttendees.replace(
                /(?:\r+|\n+|\s+|,+|;+){1,}/gm,
                ';',
            );
        }

        return newFields;
    };

    afterOnChange = (id) => {
        if (id === 'eventDate') {
            this.checkAvailability();
        }
    };

    formatAvailableTimes = (times) => {
        if (!times || times.length === 0) return [];
        return times.map((current) => {
            const date = moment(current.StartDate.time);
            const d = date.clone();
            const year = d.format('YYYY');
            const month = parseInt(d.format('M'), 10);
            const day = d.format('D');
            const hoursMinutes = d.format('LT');
            const monthLiteral = getMonthShortLiteral(month);

            let dateLabel = `${day} ${monthLiteral} ${year} ${hoursMinutes}`;

            return {
                label: dateLabel,
                value: date.format(),
            };
        });
    };

    checkAvailability = (values) => {
        const state = Context.store.getState();
        const entityCrud = getActiveCrud(state);
        const usersAttendees = entityCrud?.data?.usersAttendees;

        if (entityCrud?.data.isTodoDia) return;

        if (!values) values = usersAttendees;
        if ((!values || values.length === 0) && (!usersAttendees || usersAttendees.length === 0))
            return;
        if (values && values.length === 0) {
            Context.store.dispatch(
                Context.actions.EntityCrudActions.changeFields({ usersAttendees: [] }),
            );
            Context.store.dispatch(Context.actions.AgendaActions.resetAvailableTimes());
            return;
        }

        const attendeesValues = values.map((current) => {
            return parseInt(current.value, 10);
        });

        const hini = entityCrud?.data?.hini;
        const hfin = entityCrud?.data?.hfin;

        const startDate = moment(hini);
        const endDate = moment(hfin);
        const endISODatePlusWeek = endDate.clone().add(7, 'days').format();
        const startISODate = startDate.clone().format();
        const endISODate = endDate.clone().format();

        const params = {
            UserIds: attendeesValues,
            StartDate: startISODate,
            EndDate: endISODate,
        };

        const duration = endDate.diff(startDate, 'minutes');

        const params2 = {
            UserIds: attendeesValues,
            StartDate: startISODate,
            EndDate: endISODatePlusWeek,
            Duration: duration,
        };

        const availableList = UsersService.getAreAvailable(params)
            .then((data) => {
                if (data && data.length > 0) {
                    let mappedData = data.reduce((obj, current) => {
                        obj[current.user_id] = current;
                        return obj;
                    }, {});
                    let newAttendees = values.map((current) => {
                        const isAvailable = mappedData[current.value].available;
                        return {
                            ...current,
                            isAvailable: isAvailable,
                        };
                    });
                    return newAttendees;
                } else return [];
            })
            .catch(() => {
                return [];
            });
        const availableTimes = UsersService.getAvailableTimes(params2)
            .then((data) => {
                return data;
            })
            .catch(() => {
                return [];
            });

        Promise.all([availableList, availableTimes]).then((data) => {
            const finalAttendees = data[0] || [];

            Context.store.dispatch(
                Context.actions.EntityCrudActions.changeFields({
                    usersAttendees: finalAttendees,
                }),
            );

            if (!data[1] || data[1].length === 0) return;

            const finalAvailableTimes = this.formatAvailableTimes(data[1]);

            Context.store.dispatch(
                Context.actions.AgendaActions.setAvailableTimes(finalAvailableTimes),
            );
        });
    };

    customProcessField(field, data) {
        const state = Context.store.getState();
        const userTimezone = state.config.userData?.idTimeZone || '';
        const timezones = state.serverList?.fm_iana_time_zone?.data || [];
        const selectedTimezoneArr = timezones.filter((current) => {
            if (data['idTimezone']) return current.value === data['idTimezone'];
            else return current.value === userTimezone;
        });
        const timezone = selectedTimezoneArr?.[0]?.idianazone || '';

        let offset = '';

        if (field.id === 'eventDate') {
            if (field.inputs) {
                field.inputs.forEach((input) => {
                    if (input.inputs?.length) {
                        input.inputs.forEach((current) => {
                            if (data[current.id]) {
                                data[current.serverId] = moment(data[current.id]).format('HH:mm');

                                if (current.id === 'hfin') {
                                    // Calculate offset for Daylight Saving Time
                                    if (timezone) {
                                        offset = momentTimezone
                                            .tz(data[current.id], timezone)
                                            .format('Z');
                                    } else {
                                        offset = moment(data[current.id]).format('Z');
                                    }

                                    let ffinToTimezone =
                                        moment(data[current.id]).format('YYYY-MM-DDTHH:mm:ss') +
                                        offset;
                                    let ffin = moment(ffinToTimezone, 'YYYY-MM-DDTHH:mm:ssZ')
                                        .utc()
                                        .format('YYYY-MM-DDTHH:mm:ss');
                                    data['Ffin'] = ffin;
                                }
                                delete data[current.id];
                            }
                        });
                    } else {
                        if (input.id === 'fini') {
                            // Calculate offset for Daylight Saving Time
                            if (timezone) {
                                offset = momentTimezone.tz(data[input.id], timezone).format('Z');
                            } else {
                                offset = moment(data[input.id]).format('Z');
                            }

                            let finiToTimezone =
                                moment(data[input.id]).format('YYYY-MM-DDTHH:mm:ss') + offset;
                            let fini = moment(finiToTimezone, 'YYYY-MM-DDTHH:mm:ssZ')
                                .utc()
                                .format('YYYY-MM-DDTHH:mm:ss');
                            data[input.serverId] = fini;
                            const time = moment(data[input.id]).format('HH:mm');
                            const hoursToMinutes = moment.duration(time).asMinutes();
                            // We set utc offset just in case there is no timezone selected
                            const utcOffset = moment(data[input.id])
                                .add(hoursToMinutes, 'minutes')
                                .format('Z');
                            data.utcOffset = utcOffset;
                            delete data[input.id];
                        }

                        if (input.id === 'isTodoDia') {
                            if (getBackendBoolean(data[input.id])) {
                                data['Hini'] = '00:00';
                                data['Hfin'] = '23:59';
                            }
                            data[input.serverId] = getBackendBoolean(data[input.id]) ? '1' : '0';
                        }

                        if (input.id === 'idTimezone') {
                            if (timezone) {
                                data.utcOffset = momentTimezone.tz(data.fini, timezone).format('Z');
                            }

                            data[input.serverId] = data[input.id];
                            delete data[input.id];
                            delete data.IdTimeZone;
                        }
                    }
                });
            }

            delete data.eventDate;
        }
        if (field.id === 'taskDate') {
            if (field.inputs) {
                field.inputs.forEach((input) => {
                    if (input.inputs?.length) {
                        input.inputs.forEach((current) => {
                            if (data[current.id]) {
                                data[current.serverId] = moment(data[current.id]).format('HH:mm');

                                if (current.id === 'hfin') {
                                    // Calculate offset for Daylight Saving Time
                                    if (timezone) {
                                        offset = momentTimezone
                                            .tz(data[current.id], timezone)
                                            .format('Z');
                                    } else {
                                        offset = moment(data[current.id]).format('Z');
                                    }

                                    let ffinToTimezone =
                                        moment(data[current.id]).format('YYYY-MM-DDTHH:mm:ss') +
                                        offset;
                                    let ffin = moment(ffinToTimezone, 'YYYY-MM-DDTHH:mm:ssZ')
                                        .utc()
                                        .format('YYYY-MM-DDTHH:mm:ss');
                                    data['Ffin'] = ffin;
                                }
                                delete data[current.id];
                            }
                        });
                    } else {
                        if (input.id === 'fini') {
                            // Calculate offset for Daylight Saving Time
                            if (timezone) {
                                offset = momentTimezone.tz(data[input.id], timezone).format('Z');
                            } else {
                                offset = moment(data[input.id]).format('Z');
                            }

                            let finiToTimezone =
                                moment(data[input.id]).format('YYYY-MM-DDTHH:mm:ss') + offset;
                            let fini = moment(finiToTimezone, 'YYYY-MM-DDTHH:mm:ssZ')
                                .utc()
                                .format('YYYY-MM-DDTHH:mm:ss');
                            data[input.serverId] = fini;
                            const time = moment(data[input.id]).format('HH:mm');
                            const hoursToMinutes = moment.duration(time).asMinutes();
                            // We set utc offset just in case there is no timezone selected
                            const utcOffset = moment(data[input.id])
                                .add(hoursToMinutes, 'minutes')
                                .format('Z');
                            data.utcOffset = utcOffset;
                            delete data[input.id];
                        }

                        if (input.id === 'idTimezone') {
                            if (timezone) {
                                data.utcOffset = momentTimezone.tz(data.fini, timezone).format('Z');
                            }
                            data[input.serverId] = data[input.id];
                            delete data[input.id];
                            delete data.IdTimeZone;
                        }
                    }
                });
            }

            delete data.taskDate;
        }

        return data;
    }

    getCurrentTimezone = () => {
        const state = Context.store.getState();
        const agendaTimezone = state.agenda?.timezone || null;

        const timezoneRegion = window.Intl.DateTimeFormat().resolvedOptions().timeZone;
        const offset = new Date().getTimezoneOffset();
        const o = Math.abs(offset);
        const utcOffset =
            (offset < 0 ? '+' : '-') +
            ('00' + Math.floor(o / 60)).slice(-2) +
            ':' +
            ('00' + (o % 60)).slice(-2);

        let timezoneString = `(GMT${utcOffset}) ${timezoneRegion}`;

        if (agendaTimezone && agendaTimezone.label) {
            timezoneString = agendaTimezone.label;
        }

        return timezoneString;
    };

    getTimezoneDate = (date) => {
        date = moment(date).format('DD-MM-YYYYTHH:mm');
        const state = Context.store.getState();
        const timezone = state.agenda?.timezone?.idianazone || null;

        const timezoneDate = getDateToTimezone({
            date: date,
            inputFormat: 'DD-MM-YYYYTHH:mm',
            outputFormat: 'DD-MM-YYYYTHH:mm',
            timezone,
        });

        return moment(timezoneDate, 'DD-MM-YYYYTHH:mm').toDate();
    };

    getAdviceContent = (fieldId) => {
        const state = Context.store.getState();
        const entityCrud = getActiveCrud(state);

        if (fieldId !== 'usersAttendees') return null;

        const isTodoDia = entityCrud?.data?.isTodoDia;
        if (isTodoDia) return null;

        const usersAttendees = entityCrud?.data?.usersAttendees;
        const availableAttendees = usersAttendees.filter((current) => {
            return current.isAvailable === true;
        });

        if (usersAttendees.length > 0 && availableAttendees.length === 0) {
            return {
                content: getLiteral('label_no_invitees_available'),
                color: 'error',
            };
        } else if (usersAttendees.length === availableAttendees.length) {
            return {
                content: getLiteral('label_invitees_status_available'),
                color: 'success',
            };
        } else {
            return {
                content: getLiteral('label_invitees_status_busy'),
                color: 'warning',
            };
        }
    };

    getChipProps = (field) => {
        return (data) => {
            let chipProps = {
                color: '',
                text: '',
            };
            const statusLabel = {
                [ATTENDEE_STATUS.accepted]: getLiteral('label_rsvp_status_accepted'),
                [ATTENDEE_STATUS.rejected]: getLiteral('label_rsvp_status_denied'),
                [ATTENDEE_STATUS.tentative]: getLiteral('label_rsvp_status_tentative'),
                [ATTENDEE_STATUS.noResponse]: getLiteral('label_rsvp_status_no_response'),
            };
            const statusColor = {
                [ATTENDEE_STATUS.accepted]: 'success',
                [ATTENDEE_STATUS.rejected]: 'error',
                [ATTENDEE_STATUS.tentative]: 'warning',
                [ATTENDEE_STATUS.noResponse]: '',
            };

            const status = data.Status;
            if (field === 'usersAttendees') {
                if (status) {
                    chipProps.color = statusColor[status];
                    chipProps.text = statusLabel[status];
                } else if (data.hasOwnProperty('isAvailable')) {
                    chipProps.color = data['isAvailable'] ? 'success' : 'error';
                    chipProps.text = data['isAvailable']
                        ? getLiteral('label_invitee_available')
                        : getLiteral('label_invitee_busy');
                }
            }
            if (field === 'contact') {
                if (status) {
                    chipProps.color = statusColor[status];
                    chipProps.text = statusLabel[status];
                }
            }

            return chipProps;
        };
    };

    backRoute = (entityCrud) => {
        const id = entityCrud.id || '';
        const regexToMatch = new RegExp(`/(event/new|task/new).*`);
        const regexToMatchWithId = new RegExp(`/(event/${id}/edit|task/${id}/edit).*`);
        const currentRoute = getCurrentPath();

        if (id) {
            return ensureRoute(currentRoute.replace(regexToMatchWithId, ``));
        }
        ensureRoute(currentRoute.replace(regexToMatch, ``));
    };

    canAsyncExtraFields() {
        return true;
    }

    afterGetSchema = ({ schema, dependencyMap, dynamicMap }) => {
        return new Promise((resolve) => {
            resolve({
                schema,
                dependencyMap,
                dynamicMap,
            });
        });
    };

    beforeCrud = (id) => {
        const manager = Context.entityManager.getEntitiesManager(AGENDA);
        if (!id) return;
        return Context.entityDetailManager.getExtraFields(AGENDA, manager, id);
    };

    beforeSave = (schema, data) => {
        return model.toSave(data);
    };

    afterSave = () => {
        if (savedVideoCallProviderId) savedVideoCallProviderId = '';
        if (getCurrentPath().split('/').includes('map')) {
            Context.store.dispatch(Context.actions.EntityMapActions.initMap(AGENDA, true));
        }
    };

    afterCrudClose = () => {
        if (savedVideoCallProviderId) savedVideoCallProviderId = '';
    };

    getCustomPageSize = () => PAGINATION_TABLE_AGENDA;

    shouldOpenDetail = () => {
        return false;
    };

    whileInitCrud = (data) => {
        return new Promise((resolve) => {
            if (data.videoCallProviderId) savedVideoCallProviderId = data.videoCallProviderId;
            const state = Context.store.getState();
            const activeCrud = getActiveCrud(state);
            const timezoneSelected = state.agenda?.timezone || null;
            const browserTimezone = window.Intl.DateTimeFormat().resolvedOptions().timeZone;
            let matchingTimezone = null;

            Context.store
                .dispatch(Context.actions.ServerListActions.getList('fm_iana_time_zone'))
                .then((timezones) => {
                    if (data.id || data.Id) {
                        matchingTimezone = timezones.reduce((obj, current) => {
                            if (data.idTimezone && data.idTimezone !== '-1') {
                                if (data.idTimezone === current.value) {
                                    obj = current;
                                }
                            } else {
                                if (current.ianazonenames?.includes(browserTimezone)) obj = current;
                            }
                            return obj;
                        }, {});

                        if (matchingTimezone) {
                            const userOffset = moment().utcOffset();
                            const timezoneOffset = momentTimezone
                                .tz(matchingTimezone.idianazone)
                                .utcOffset();

                            let additionalOffset = 0;
                            if (userOffset && timezoneOffset)
                                additionalOffset = timezoneOffset - userOffset;

                            let finalFini = moment(data.fini)
                                .add(additionalOffset, 'minutes')
                                .toDate();
                            let finalHfin = moment(data.hfin)
                                .add(additionalOffset, 'minutes')
                                .toDate();
                            let finalHini = moment(data.hini)
                                .add(additionalOffset, 'minutes')
                                .toDate();

                            data.fini = finalFini;
                            data.hfin = finalHfin;
                            data.hini = finalHini;
                        }
                    } else {
                        if (timezoneSelected?.value) {
                            data.idTimezone = timezoneSelected?.value || '-1';
                        } else {
                            matchingTimezone = timezones.reduce((obj, current) => {
                                if (current.ianazonenames?.includes(browserTimezone)) obj = current;
                                return obj;
                            }, {});
                            data.idTimezone = matchingTimezone.value || '-1';
                        }
                    }
                });

            const usersAttendees = data.usersAttendees;

            if ((data.id || data.Id) && usersAttendees.length > 0) {
                const startDate = moment(data.hini);
                const endDate = moment(data.hfin);
                const endISODatePlusWeek = endDate.clone().add(7, 'days').format();
                const startISODate = startDate.clone().format();
                const duration = endDate.diff(startDate, 'minutes');

                const attendeesValues = usersAttendees.map((current) => {
                    return parseInt(current.value, 10);
                });

                const params = {
                    UserIds: attendeesValues,
                    StartDate: startISODate,
                    EndDate: endISODatePlusWeek,
                    Duration: duration,
                };

                UsersService.getAvailableTimes(params)
                    .then((times) => {
                        if (!times || times.length === 0) return;

                        const finalAvailableTimes = this.formatAvailableTimes(times);

                        Context.store.dispatch(
                            Context.actions.AgendaActions.setAvailableTimes(finalAvailableTimes),
                        );
                    })
                    .catch(() => {
                        console.error(`Couldn't find available times`);
                    });
            }

            if (!data.id && !data.Id && !data.minutosAviso) {
                data.minutosAviso = '15';
            }

            // By default notifyAllParticipants is false
            if ((!data.id || !data.Id) && activeCrud?.entity === AGENDA.entity) {
                data.notifyAllParticipants = false;
            }

            resolve(data);
        });
    };

    getCrudPreComponents = () => {
        const state = Context.store.getState();
        const nylas = state?.config?.nylas;

        const info = {
            component: 'advice',
            componentProps: {
                className: 'agenda__crud-nylas-advice',
                type: 'info',
                showIcon: true,
                showCollapse: false,
            },
            content: getLiteral('label_attendees_sincro_disabled'),
        };

        if (nylas?.account?.status === 4) {
            return Promise.resolve([]);
        } else return Promise.resolve([info]);
    };

    initFiltersColor = () => {
        Context.store.dispatch(Context.actions.AgendaActions.initFiltersColor());
    };

    setFiltersColor = (filter, value) => {
        Context.store.dispatch(Context.actions.AgendaActions.setFiltersColor(filter, value));
    };

    transformDataForEntityList = (backendData) => {
        if (!backendData || (backendData && !Array.isArray(backendData))) return null;
        return {
            Data: backendData,
            MetaData: { totalRows: 0 },
        };
    };

    getDefaultSelection(field) {
        const state = Context.store.getState();
        if (field === 'idusuario') {
            const userData = state.config.userData;
            let name = userData.nombre;
            if (userData.apellidos) name = `${name} ${userData.apellidos}`;
            return {
                value: [userData.idUsuario],
                completeValues: [{ idParent: undefined, label: name, value: userData.idUsuario }],
            };
        }
        return null;
    }

    customInitMap() {
        return new Promise((resolve, reject) => {
            const state = Context.store.getState();
            let filters = state.entityFilters[AGENDA.entity];
            filters = JSON.parse(JSON.stringify(filters ? filters.filters || {} : {}));
            let selectionToken = state.entityListSelect[AGENDA.entity];
            selectionToken = selectionToken ? selectionToken.token : null;

            const agendaState = state[AGENDA.entity];
            const timezone = agendaState.timezone;

            // Only events for map request
            if (filters?.taskFlag?.id) filters.taskFlag = { ...filters.taskFlag, value: '2' };

            Context.entityListManager.getEntityList(
                AGENDA,
                0,
                500,
                filters,
                null,
                null,
                selectionToken,
                null,
                (entities, total, offset) => {
                    let geolocatedEntities = entities.filter(
                        (current) => current.Lat && current.Lon,
                    );

                    const fini = filters?.fini?.value || null;
                    const ffin = filters?.ffin?.value || null;
                    const startDate = moment
                        .utc(fini, 'YYYY-MM-DDTHH:mm:ss')
                        .milliseconds(0)
                        .toDate();
                    const endDate = moment
                        .utc(ffin, 'YYYY-MM-DDTHH:mm:ss')
                        .milliseconds(0)
                        .toDate();

                    const events = geolocatedEntities.reduce((arr, item) => {
                        if (!item.g_recurrence || item.g_recurrence === '') {
                            arr.push(item);
                        } else {
                            let initDateString = item.Fini;
                            let initDateFormat = 'YYYY-MM-DD';
                            if (item.Hini) {
                                initDateString = initDateString + ' ' + item.Hini;
                                initDateFormat = initDateFormat + ' ' + 'HH:mm';
                            }
                            const initDate = moment.utc(initDateString, initDateFormat).toDate();
                            let g_recurrence =
                                item.g_recurrence?.replace('RRULE:RRULE:FREQ', 'FREQ') ||
                                item.g_recurrence;
                            g_recurrence = g_recurrence?.replace('RRULE:FREQ', 'FREQ');
                            const newGRecurrence = getNewStrRuleWithOffset(g_recurrence);
                            let rule = rrulestr(newGRecurrence, {
                                dtstart: initDate,
                            });

                            if (startDate && endDate) {
                                const dates = rule.between(startDate, endDate);
                                for (let i = 0; i < dates.length; i++) {
                                    let dateYearMonthDay = moment(dates[i]).format('YYYY-MM-DD');
                                    let startTime = moment(item.fini).format('HH:mm');
                                    let endTime = moment(item.hfin).format('HH:mm');
                                    const startMoment = getDateToTimezone({
                                        date: `${dateYearMonthDay} ${startTime}`,
                                        inputFormat: 'YYYY-MM-DD HH:mm',
                                        timezone: timezone.idianazone,
                                        returnMoment: true,
                                    });

                                    const endMoment = getDateToTimezone({
                                        date: `${dateYearMonthDay} ${endTime}`,
                                        inputFormat: 'YYYY-MM-DD HH:mm',
                                        timezone: timezone.idianazone,
                                        returnMoment: true,
                                    });

                                    const dateFormat = moment(dates[i]).format('DD/MM/YYYY');
                                    let newDateIni = moment(dateFormat, 'DD/MM/YYYY');
                                    let newDateFin = moment(dateFormat, 'DD/MM/YYYY');

                                    arr.push({
                                        ...item,
                                        Asunto: item.Asunto + i,
                                        fini: startMoment.toDate(),
                                        hini: startMoment.toDate(),
                                        hfin: endMoment.toDate(),
                                        Fini: newDateIni.format('YYYY-MM-DD'),
                                        Ffin: newDateFin.format('YYYY-MM-DD'),
                                    });
                                }
                            }
                        }
                        return arr;
                    }, []);

                    geolocatedEntities = model.toMap({ data: events, filters });
                    resolve({
                        entities: geolocatedEntities,
                        total: geolocatedEntities.length,
                        offset,
                    });
                },
                reject,
            );
        });
    }

    shouldPreventRefreshListFromMap() {
        const state = Context.store.getState();
        const section = state.agenda?.section;
        if (section === 1 || section === '1') {
            return true;
        }
        return false;
    }

    shouldPreventRefreshMapFromChangeFilter() {
        const state = Context.store.getState();
        const section = state.agenda?.section;
        if (section === 0 || section === '0') {
            return true;
        }
        return false;
    }

    preloadFilters(filters) {
        return new Promise((resolve, reject) => {
            const filterItems = filters?.filters || null;
            let withUser = true;
            let filtersToLoad = [];

            // Remove user if a view is set
            if (filters && filterItems?.idView) {
                if (filterItems?.idusuario) {
                    filterItems.idusuario.completeValues = [];
                    filterItems.idusuario.value = [];
                }
                withUser = false;
            }

            const state = Context.store.getState();
            const userData = state.config.userData;

            let name = userData.nombre;
            if (userData.apellidos) name = `${name} ${userData.apellidos}`;
            const completeValues = [
                {
                    value: userData.idUsuario,
                    label: name,
                },
            ];

            const user = {
                entity: AGENDA,
                filter: {
                    id: 'idusuario',
                    dataType: 'singleValueList',
                    inputAttrs: {
                        ...FuzzyMap.users,
                    },
                },
                values: [userData.idUsuario],
                completeValues,
            };

            if (
                filters &&
                !filters.isFirstLoad &&
                filterItems?.idusuario &&
                filterItems?.fini &&
                filterItems?.ffin &&
                filterItems?.taskFlag &&
                filterItems?.completed &&
                filterItems?.useUTCDates &&
                filterItems?.newAttendees
            ) {
                // we must have one user always
                if (
                    filters &&
                    !filters.isFirstLoad &&
                    !filterItems?.idView &&
                    !filterItems?.idusuario?.value?.length
                ) {
                    filtersToLoad.push(user);
                    return resolve(filtersToLoad);
                }

                return resolve();
            }

            const dateRange = Context.store.dispatch(
                Context.actions.AgendaActions.getDateRange({
                    date: new Date(),
                }),
            );
            let fini = null;
            let ffin = null;

            if (!dateRange?.startDate || !dateRange?.endDate) {
                console.error('dateRange is needed');
                reject();
            } else {
                fini = {
                    entity: AGENDA,
                    filter: {
                        id: 'fini',
                        hideForCount: true,
                        excludeFromCache: true,
                    },
                    values: formatDateToBackendUTC(dateRange.startDate),
                };

                ffin = {
                    entity: AGENDA,
                    filter: {
                        id: 'ffin',
                        hideForCount: true,
                        excludeFromCache: true,
                    },
                    values: formatDateToBackendUTC(dateRange.endDate),
                };
            }

            const useUTCDates = {
                entity: AGENDA,
                filter: {
                    id: 'useUTCDates',
                    hideForCount: true,
                },
                values: true,
            };

            const newAttendees = {
                entity: AGENDA,
                filter: {
                    id: 'newAttendees',
                    hideForCount: true,
                },
                values: true,
            };

            const taskFlag = {
                entity: AGENDA,
                filter: {
                    id: 'taskFlag',
                    hideForCount: true,
                },
                values: AGENDA_TASK_FLAG.all,
            };

            const completed = {
                entity: AGENDA,
                filter: {
                    id: 'completed',
                    hideForCount: true,
                },
                values: AGENDA_TASKS_COMPLETED_FLAG.all,
            };

            if (fini && !filterItems?.fini) filtersToLoad.push(fini);
            if (ffin && !filterItems?.ffin) filtersToLoad.push(ffin);
            if (!filterItems?.useUTCDates) filtersToLoad.push(useUTCDates);
            if (!filterItems?.newAttendees) filtersToLoad.push(newAttendees);
            if (!filterItems?.taskFlag) filtersToLoad.push(taskFlag);
            if (!filterItems?.completed) filtersToLoad.push(completed);
            if (!filterItems?.idusuario && withUser) filtersToLoad.push(user);

            resolve(filtersToLoad);
        });
    }

    preloadOutlierFilters(filters) {
        return new Promise((resolve) => {
            const outlierFilters = filters?.outlierFilters || null;

            if (filters && outlierFilters?.view?.value) return resolve();

            let outlierFiltersToLoad = [];

            const viewOptions = getViewOptions();
            const workWeek = viewOptions[2];

            let view = {
                entity: AGENDA,
                filter: {
                    id: 'view',
                },
                value: workWeek,
            };

            if (!outlierFilters?.view) outlierFiltersToLoad.push(view);

            resolve(outlierFiltersToLoad);
        });
    }

    afterOnChangeFilter({ refresh, filter, isClear }) {
        // Next cycle for avoid overwritting store
        setTimeout(() => {
            if (!filter && isClear) {
                Context.store.dispatch(
                    Context.actions.EntityFiltersActions.clearFilters({
                        entity: TASKS,
                        isAPurge: true,
                        refresh: true,
                    }),
                );
            } else if (filter) {
                const filterId = filter?.filter?.id || '';

                if (
                    filterId === 'fini' ||
                    filterId === 'ffin' ||
                    filterId === 'taskFlag' ||
                    filterId === 'completed' ||
                    filterId === 'useUTCDates' ||
                    filterId === 'newAttendees'
                ) {
                    return;
                }

                Context.store.dispatch(
                    Context.actions.EntityFiltersActions.changeFilter({
                        entity: TASKS,
                        filter: filter.filter,
                        value: filter.value,
                        refresh,
                        completeValues: filter.completeValues,
                    }),
                );
            }
        }, 500);
    }

    checkCustomErrors(errors, schema, data) {
        const fini = data.fini || null;
        const hini = data.hini || null;
        const hfin = data.hfin || null;
        const errorName = 'mandatory';
        let firstError = '';

        if (data.isTarea && data.isTarea === '1') {
            if (!fini) {
                errors.fini = errorName;
                firstError = 'fini';
            }
            if (!hini) {
                errors.hini = errorName;
                if (!firstError) firstError = 'hini';
            }
        } else {
            if (!fini) {
                errors.fini = errorName;
                firstError = 'fini';
            }
            if (!hini) {
                errors.hini = errorName;
                if (!firstError) firstError = 'hini';
            }

            if (!hfin) {
                errors.hfin = errorName;
                if (!firstError) firstError = 'hfin';
            }
        }

        if (!errors.asunto) {
            errors.firstErrorField = firstError;
        }

        return errors;
    }

    filterActivityTypeOptions(finalOptions) {
        return finalOptions.filter((option) => {
            return (
                option.hasOwnProperty('blnisvisibleinagenda') &&
                getBackendBoolean(option.blnisvisibleinagenda) &&
                !getBackendBoolean(option.blnhide)
            );
        });
    }
}
