import { memo, useMemo, useState } from 'react';
import { useTheme, Icon, Text, Advice } from 'hoi-poi-ui';
import { getNumberAsLocaleNumber } from 'utils/numbers';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';

import './styles.scss';

function formatSecondsToHHMM(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const formattedMinutes = minutes.toString().padStart(2, '0');
    return `${hours}:${formattedMinutes}`;
}

function computeRouteTotals(result) {
    let totalDist = 0;
    let totalTime = 0;

    const routes = result.routes[0];
    routes?.legs.forEach((leg) => {
        totalDist += leg.distance.value;
        totalTime += leg.duration.value;
    });

    totalDist = getNumberAsLocaleNumber(totalDist / 1000, {
        useGrouping: false,
        maximumFractionDigits: 2,
    });

    totalTime = getNumberAsLocaleNumber(formatSecondsToHHMM(totalTime), {
        useGrouping: false,
        maximumFractionDigits: 2,
    });

    return {
        totalDist,
        totalTime,
    };
}

const InfoMapRoute = ({ googleMapRoute, maxError }) => {
    const theme = useTheme();
    const [hide, setHide] = useState(false);
    const totals = useMemo(
        () => (googleMapRoute ? computeRouteTotals(googleMapRoute) : {}),
        [googleMapRoute],
    );

    if (maxError)
        return (
            <Advice
                className="fm-routemap__info--error"
                title={getLiteral('label_route_not_available')}
                type="warning"
                showIcon
                defaultCollapsed={false}
            >
                {getLiteral('label_route_not_available_max_items_exceed')}
            </Advice>
        );

    if (!googleMapRoute || hide) return null;

    return (
        <div className="fm-routemap__info">
            <div className="fm-routemap__info__content">
                <Icon name="infoOutlined" color={theme.colors.semantic.info600} />
                <Text type="captionMedium">
                    {getLiteralWithParameters('label_route_day_checkin_km_duration', [
                        googleMapRoute?.request?.waypoints?.length,
                        `${totals?.totalDist}km`,
                        `${totals?.totalTime}h`,
                    ])}
                </Text>
                <Icon
                    name="closeRaw"
                    color={theme.colors.semantic.info600}
                    size="small"
                    className="fm-activities__info__dismiss"
                    onClick={() => setHide(true)}
                />
            </div>
        </div>
    );
};

export default memo(InfoMapRoute);
