import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { EntityActions, EntityDetailActions } from 'actions';
import { Icon, Text } from 'hoi-poi-ui';
import { ensureRoute, getCurrentPath } from 'utils/routes';
import { getLiteral } from 'utils/getLiteral';
import { successToast } from 'utils/toast';
import EditFilledIcon from 'components/SvgIcons/EditFilled';
import MergeFilledIcon from 'components/SvgIcons/MergeFilled';
import CloseIcon from 'components/SvgIcons/Close';
import ExportIcon from 'components/SvgIcons/Export';
import StarFilled from 'components/SvgIcons/StarFilled';
import StarOutline from 'components/SvgIcons/StarOutline';
import TooltipHoi from 'components/TooltipHoi';
import EntityCrudDeleteConfirmModal from 'containers/components/EntityCrud/EntityCrudDeleteConfirmModal';
import { logEvent } from 'utils/tracking';
import EntityDetailTabs from './EntityDetailTabs';
import { withEntityDetail } from './hooks/useEntityDetail';
import colors from 'constants/colors';

const propTypes = {
    entity: PropTypes.object,
    title: PropTypes.string,
    loading: PropTypes.bool,
    options: PropTypes.array,
    optionsParams: PropTypes.array,
    closeDetail: PropTypes.func,
    close: PropTypes.func,
    data: PropTypes.object,
    refreshDetail: PropTypes.func,
};

function mapStateToProps(state) {
    const active = state.entityDetail.active;
    return {
        active,
        activeDetailEntity: state.entityDetail[active]?.entity,
        activeListEntity: state.entityList.active,
        tabs: state.entityDetail.tabs || {},
    };
}

function mapDispatchToProps(dispatch) {
    return {
        followEntity: bindActionCreators(EntityActions, dispatch).followEntity,
        duplicateEntity: bindActionCreators(EntityActions, dispatch).duplicateEntity,
        refreshDetail: bindActionCreators(EntityDetailActions, dispatch).open,
        closeDetail: bindActionCreators(EntityDetailActions, dispatch).close,
        deleteEntity: bindActionCreators(EntityDetailActions, dispatch).deleteEntity,
        openMergeEntityDialog: bindActionCreators(EntityActions, dispatch).openMergeEntityDialog,
    };
}

@connect(mapStateToProps, mapDispatchToProps)
class EntityDetailHeader extends PureComponent {
    state = {
        isUpdatingFollow: false,
        isUpdatingFavorite: false,
        isConfirmModalOpen: false,
    };

    static getDerivedStateFromProps() {
        return {
            isUpdatingFollow: false,
        };
    }

    onClose = () => {
        const { afterCloseTab, close, primaryDetail } = this.props;
        close && close({ purge: true, purgeTabs: true, primaryDetail });
        afterCloseTab && afterCloseTab();
    };

    editEntity = () => {
        const { closeDetail, hasTabs, openCrud, tabs } = this.props;
        const currentRoute = getCurrentPath();

        if (hasTabs) {
            closeDetail({ tabs, isFromTab: true });
            openCrud();
        } else {
            closeDetail({ tabs });
            ensureRoute(`${currentRoute}/edit`);
        }
    };

    confirmDelete = () => {
        this.setState({ isConfirmModalOpen: true });
    };

    onCloseModal = () => {
        this.setState({ isConfirmModalOpen: false });
    };

    onDeleteEntity = () => {
        const { deleteEntity, entity, onDelete, onErrorDelete } = this.props;
        return new Promise((resolve, reject) => {
            deleteEntity(entity)
                .then(() => {
                    logEvent({
                        event: entity.trueName,
                        functionality: 'delete',
                    });
                    onDelete && onDelete();
                    resolve();
                })
                .catch(({ serverError }) => {
                    console.error('error deleting entity from detail');
                    serverError && onErrorDelete && onErrorDelete();
                    reject();
                });
        });
    };

    mergeEntity = () => {
        const { data, openMergeEntityDialog } = this.props;

        openMergeEntityDialog(data.id);
    };

    followEntity = (isFollowing) => {
        const { entity, followEntity, data, onFollow, onErrorFollow } = this.props;

        this.setState({ isUpdatingFollow: true });
        followEntity({ entityType: entity, entityId: data.id, follow: !isFollowing })
            .then((bool) => {
                onFollow && onFollow(bool);
            })
            .catch(() => {
                console.error('error following an entity');
                onErrorFollow && onErrorFollow();
            });
    };

    favoriteEntity = (isFavorite) => {
        const { entity, followEntity, data, onFavorite, onErrorFavorite } = this.props;

        this.setState({ isUpdatingFavorite: true });
        followEntity({ entityType: entity, entityId: data.id, follow: !isFavorite })
            .then((bool) => {
                onFavorite && onFavorite(bool);
                this.setState({ isUpdatingFavorite: false });
                if (bool) {
                    successToast({ text: getLiteral('label_marked_favorite') });
                } else {
                    successToast({ text: getLiteral('label_unmarked_favorite') });
                }
            })
            .catch((error) => {
                console.error('error following an entity:', error);
                onErrorFavorite && onErrorFavorite();
            });
    };

    duplicateEntity = () => {
        const {
            active,
            entity,
            duplicateEntity,
            data,
            hasTabsEnabled,
            activeListEntity,
            activeDetailEntity,
            primaryDetail,
            customDuplicate,
        } = this.props;

        const inTab =
            hasTabsEnabled &&
            (primaryDetail === active ||
                (activeListEntity !== activeDetailEntity && primaryDetail !== active));

        if (customDuplicate) customDuplicate(entity, data.id, data, inTab);
        else duplicateEntity(entity, data.id, data, inTab);
    };

    renderFollow = (isFollowing) => {
        const { isUpdatingFollow } = this.state;

        let classes = ['fm-entity-detail__follow'];
        if (isUpdatingFollow) classes.push('fm-entity-detail__follow-disabled');
        else if (isFollowing) classes.push('fm-entity-detail__follow-active');

        return (
            <div
                className={classes.join(' ')}
                onClick={() => !isUpdatingFollow && this.followEntity(isFollowing)}
            >
                <Icon name="flag" />
            </div>
        );
    };

    renderFavorite = (isFavorite) => {
        const { isUpdatingFavorite } = this.state;

        let classes = ['fm-entity-detail__favorite'];
        if (isUpdatingFavorite) classes.push('fm-entity-detail__favorite-disabled');
        else if (isFavorite) classes.push('fm-entity-detail__favorite-active');

        return (
            <div
                className={classes.join(' ')}
                onClick={() => !isUpdatingFavorite && this.favoriteEntity(isFavorite)}
            >
                {!isFavorite && <StarOutline />}
                {isFavorite && <StarFilled color={colors.semantic.focus500} />}
            </div>
        );
    };

    renderOptions = () => {
        const { data, options, optionsParams, customOptions } = this.props;

        return (
            customOptions || (
                <div className="fm-entity-detail__options">
                    {options.map((option, i) => {
                        let optionEl = null;
                        let action;
                        let tooltip;
                        let optionClassName = '';

                        switch (option) {
                            case 'follow':
                                optionEl = this.renderFollow(data[optionsParams[i]]);
                                tooltip = getLiteral('action_follow');
                                optionClassName = 'fm-entity-detail__option-follow';
                                break;
                            case 'merge':
                                action = this.mergeEntity;
                                optionEl = <MergeFilledIcon />;
                                tooltip = getLiteral('action_merge');
                                optionClassName = 'fm-entity-detail__option-merge';
                                break;
                            case 'edit':
                                action = this.editEntity;
                                optionEl = <EditFilledIcon />;
                                tooltip = getLiteral('action_edit');
                                optionClassName = 'fm-entity-detail__option-edit';
                                break;
                            case 'delete':
                                action = this.confirmDelete;
                                optionEl = <Icon name="deleteRaw" />;
                                tooltip = getLiteral('action_delete');
                                optionClassName = 'fm-entity-detail__option-delete';
                                break;
                            case 'export':
                                action = this.exportEntity;
                                optionEl = <ExportIcon />;
                                tooltip = getLiteral('action_exporttoexcel');
                                optionClassName = 'fm-entity-detail__option-export';
                                break;
                            case 'merge':
                                action = this.mergeEntity;
                                optionEl = <MergeFilledIcon />;
                                tooltip = getLiteral('action_merge');
                                optionClassName = 'fm-entity-detail__option-merge';
                            case 'favorite':
                                optionEl = this.renderFavorite(data[optionsParams[i]]);
                                optionClassName = 'fm-entity-detail__option-favorite';
                                tooltip = null;
                                break;
                            case 'duplicate':
                                action = this.duplicateEntity;
                                optionEl = <Icon name="duplicate" />;
                                tooltip = getLiteral('action_duplicate');
                                optionClassName = 'fm-entity-detail__option-duplicate';
                                break;
                            default:
                                optionEl = option.el;
                                action = option.action;
                                tooltip = option.tooltip;
                                optionClassName = option.className ? option.className : '';
                        }

                        if (!tooltip) {
                            return (
                                <div
                                    key={i}
                                    onClick={action}
                                    className={`fm-entity-detail__option ${optionClassName}`}
                                >
                                    {optionEl}
                                </div>
                            );
                        }

                        return (
                            <TooltipHoi key={i} placement="bottomRight" content={tooltip}>
                                <div
                                    onClick={action}
                                    className={`fm-entity-detail__option ${optionClassName}`}
                                >
                                    {optionEl}
                                </div>
                            </TooltipHoi>
                        );
                    })}
                </div>
            )
        );
    };

    render() {
        const {
            title,
            options,
            modalTitle,
            modalContent,
            modalCancelText,
            modalConfirmText,
            hasTabs,
            onCloseTab,
            customOptions,
            modalContentElement,
        } = this.props;

        const { isConfirmModalOpen } = this.state;

        return (
            <div
                className={classNames('fm-entity-detail__header', {
                    'fm-entity-detail__header--withTabs': hasTabs,
                })}
            >
                {hasTabs ? (
                    <EntityDetailTabs
                        className="fm-entity-detail__tabs"
                        onClose={this.onClose}
                        onCloseTab={onCloseTab}
                        postComponent={(options || customOptions) && this.renderOptions()}
                        modalContentElement={modalContentElement}
                    />
                ) : (
                    <Fragment>
                        <div className="fm-entity-detail__close" onClick={this.onClose}>
                            <CloseIcon />
                        </div>
                        <div className="fm-entity-detail__title">{title}</div>
                        <div className="fm-entity-detail__options">
                            {options && this.renderOptions()}
                        </div>
                    </Fragment>
                )}
                <EntityCrudDeleteConfirmModal
                    isOpen={isConfirmModalOpen}
                    onDelete={this.onDeleteEntity}
                    onClose={this.onCloseModal}
                    modalTitle={modalTitle}
                    modalCancelText={modalCancelText}
                    modalConfirmText={modalConfirmText}
                >
                    <Text>{modalContent || null}</Text>
                </EntityCrudDeleteConfirmModal>
            </div>
        );
    }
}

EntityDetailHeader.propTypes = propTypes;

export default withEntityDetail(EntityDetailHeader);
