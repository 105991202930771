import Cookies from 'js-cookie';
import { GOOGLE_MAPS_API_KEY } from '../../constants/Environment';

const COOKIE_KEY = 'DEV_ENVIRONMENT';
export const DEV_ENVIRONMENTS = [
    {
        name: 'PRODUCTION',
        config: {
            NEW_API_URL: 'https://be-services.forcemanager.net/api',
            API_URL: 'https://proxy-pro.forcemanager.com',
            B2_URL: 'https://be-pro.forcemanager.net',
            FORCE_BI_URL: 'https://fmbusinessintelligence.forcemanager.net/api',
            GOOGLE_MAPS_API_KEY: 'AIzaSyCPAkvsFL1NBHJnuHrO23YzpJDSCVBZYdw',
        },
    },
    {
        name: 'PREPRODUCTION',
        config: {
            NEW_API_URL: 'https://be-servicespre.forcemanager.net/api',
            API_URL: 'https://proxy-pre.forcemanager.com',
            B2_URL: 'https://be-pre.forcemanager.net',
            FORCE_BI_URL: 'https://fmbusinessintelligence-pre.forcemanager.net/api',
            GOOGLE_MAPS_API_KEY: 'AIzaSyBG0LMspkEUdSvlLK0dcKw4Efnv1Y9Pk8I',
        },
    },
    {
        name: 'STAGING',
        config: {
            NEW_API_URL: 'https://be-servicessta.forcemanager.net/api',
            API_URL: 'https://proxy-sta.forcemanager.com',
            B2_URL: 'https://be-sta.forcemanager.net',
            FORCE_BI_URL: 'https://fmbusinessintelligence-sta.forcemanager.net/api',
            GOOGLE_MAPS_API_KEY: 'AIzaSyDI7JCJSoTHZWvqha1Y7fIXSwAMiO6UMlI',
        },
    },
    {
        name: 'LOCAL',
        config: {
            NEW_API_URL: 'https://be-servicespre.forcemanager.net/api',
            API_URL: 'http://localhost:8080',
            B2_URL: 'https://be-pre.forcemanager.net',
            FORCE_BI_URL: 'https://fmbusinessintelligence-pre.forcemanager.net/api',
            GOOGLE_MAPS_API_KEY: 'AIzaSyBG0LMspkEUdSvlLK0dcKw4Efnv1Y9Pk8I',
        },
    },
];

export const loadDevEnvironment = (key) => {
    const configKey = key || Cookies.get(COOKIE_KEY) || 'PREPRODUCTION';
    window.FM_CONFIG = {
        ...(window.FM_CONFIG || {}),
        ...(DEV_ENVIRONMENTS.find((item) => configKey === item.name)?.config || {}),
    };
};

export const getDevEnvironment = () => {
    return Cookies.get(COOKIE_KEY);
};

export const setDevEnvironment = (key = '') => {
    Cookies.set(COOKIE_KEY, key);
};
