import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'fixed-data-table-2';
import debounce from 'utils/debounce';

export default class ResponsiveFixedDataTable extends React.Component {
    constructor(props) {
        super(props);
        this.divRef = React.createRef();
    }

    static propTypes = {
        containerStyle: PropTypes.object,
        refreshRate: PropTypes.number,
    };

    state = {
        gridWidth: 1,
        gridHeight: 1,
    };

    componentDidMount() {
        const { refreshRate = 150 } = this.props;
        this._setDimensionsOnState = debounce(this._setDimensionsOnState, refreshRate);
        this.__isMounted = true;
        this._setDimensionsOnState();
        this._attachResizeEvent();
    }

    componentWillUnmount() {
        this.__isMounted = false;
        window.removeEventListener('resize', this._setDimensionsOnState);
    }

    _attachResizeEvent() {
        if (window.addEventListener) {
            window.addEventListener('resize', this._setDimensionsOnState, false);
        } else if (window.attachEvent) {
            window.attachEvent('resize', this._setDimensionsOnState);
        } else {
            window.onresize = this._setDimensionsOnState;
        }
    }

    _setDimensionsOnState = () => {
        if (this.__isMounted && this.divRef.current) {
            const { offsetWidth, offsetHeight } = this.divRef.current;
            this.setState({
                gridWidth: offsetWidth,
                gridHeight: offsetHeight,
            });
        }
    };

    _getStyle() {
        return {
            ...(this.props.containerStyle || {}),
            width: '100%',
            height: '100%',
        };
    }

    render() {
        const { gridWidth, gridHeight } = this.state;

        return (
            <div ref={this.divRef} style={this._getStyle()}>
                <Table
                    {...this.props}
                    ref={(e) => (this.table = e)}
                    width={gridWidth}
                    height={gridHeight}
                />
            </div>
        );
    }
}
