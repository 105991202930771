import React, { memo } from 'react';

const Recents = (props) => (
    <svg width={168} height={144} fill="none" {...props}>
        <g mask="url(#a)">
            <path
                fill="#303030"
                d="M142.63 34.439h-40.035a2.637 2.637 0 0 0-2.638 2.636v87.63a2.637 2.637 0 0 0 2.638 2.636h40.035a2.638 2.638 0 0 0 2.639-2.636v-87.63a2.638 2.638 0 0 0-2.639-2.636Z"
            />
            <path
                fill="#C6C6C6"
                d="M52.569 51.782c-.63 0-3.806-.21-4.528-4.37-.17-.975-.217-2.092-.271-3.384-.065-1.535-.139-3.273-.437-4.704-.318-1.521-.717-1.852-.721-1.855-.09-.036-.61-.087-1.631.235a3.863 3.863 0 1 1-2.324-7.368c3.315-1.045 6.14-.772 8.395.809a8.067 8.067 0 0 1 1.598 1.479c1.096-2.874 2.644-5.403 4.974-6.845a3.862 3.862 0 1 1 4.07 6.568c-.177.11-1.114.814-2.132 3.912-.783 2.383-1.307 5.233-1.729 7.523-.27 1.475-.506 2.75-.775 3.758-.199.746-.413 1.322-.669 1.81-.8 1.522-2.229 2.431-3.822 2.431l.002.001Z"
            />
            <path
                fill="#5E5E5E"
                d="M56.153 126.64a5.117 5.117 0 0 1-5.103-4.783l-3.105-47.616a224.76 224.76 0 0 1-11.248 37.444l-3.807 9.553a5.12 5.12 0 0 1-6.65 2.861 5.114 5.114 0 0 1-2.862-6.645l3.806-9.553a214.42 214.42 0 0 0 11.171-37.894l3.406-17.325.047-.174c.63-2.324 2.754-4.79 6.823-4.726 1.446.02 2.863.356 3.897.601.246.06.582.139.698.158-.005 0-.106-.017-.271-.027l-.005.75a7.02 7.02 0 0 1 3.894 4.888l.077.363 4.349 66.675a5.118 5.118 0 0 1-5.114 5.448l-.003.002Z"
            />
            <path
                fill="#B26E3D"
                d="M63.678 23.56c-.036-.002-.086-.005-.149-.005-.155 0-.403.011-.724.071a4.22 4.22 0 0 0-1.532.606 4.188 4.188 0 0 0-1.043.96 4.779 4.779 0 0 0-.756 1.448 1.883 1.883 0 0 0 3.578 1.17.93.93 0 0 1 .178-.343.445.445 0 0 1 .251-.142c.024-.006.045-.008.056-.01h.006l-.015-.305v.306h.015l-.015-.306v.306-1.314l-.08 1.312.08.003v-1.314l-.08 1.313a1.882 1.882 0 1 0 .229-3.756h.001Z"
            />
            <path
                fill="#C6C6C6"
                d="M54.702 42.797c3.368 8.224.69 9.326-5.943 9.326-5.658 0-8.407.243-8.407-8.643 0-8.886 3.563-12.84 7.915-12.84 4.351 0 3.636 5.318 6.435 12.157Z"
            />
            <path
                fill="#E2E2E2"
                d="M90.103 33.346c-4.652.488-8.703 2.086-11.874 4.296-3.412-4.7-8.888-8.69-15.919-10.677 3.648-4.076 8.417-7.582 14.1-10.023 6.654 4.44 11.288 10.337 13.693 16.406v-.002Z"
            />
            <path
                fill="#1B1B1B"
                d="M140.613 34.439h-40.035a2.637 2.637 0 0 0-2.639 2.636v87.63a2.637 2.637 0 0 0 2.639 2.636h40.035a2.637 2.637 0 0 0 2.638-2.636v-87.63a2.637 2.637 0 0 0-2.638-2.636Z"
            />
            <path
                fill="#1B1B1B"
                d="M137.592 120.384H103.6a2.024 2.024 0 0 1-2.024-2.023V38.387h36.37c.922 0 1.668.746 1.668 1.667v78.307a2.023 2.023 0 0 1-2.023 2.023h.001Z"
            />
            <path
                fill="#ABABAB"
                d="M87.9 38.387h49.939c.98 0 1.777.796 1.777 1.775v37.45c0 .979-.797 1.775-1.777 1.775h-49.94v-41Z"
            />
            <path
                fill="#303030"
                d="M137.881 81.793H103.31c-.958 0-1.734.775-1.734 1.731v36.031c0 .956.776 1.732 1.734 1.732h34.571c.958 0 1.733-.776 1.733-1.732v-36.03c0-.957-.775-1.732-1.733-1.732Z"
            />
            <path
                fill="#00D639"
                d="M89.143 38.387H53.81c-.747 0-1.352.605-1.352 1.351v38.296c0 .746.605 1.351 1.352 1.351h35.334c.747 0 1.352-.605 1.352-1.35V39.737c0-.746-.605-1.351-1.352-1.351Z"
            />
            <path
                fill="#303030"
                d="M120.595 125.548a1.39 1.39 0 1 0 .001-2.779 1.39 1.39 0 0 0-.001 2.779Z"
            />
            <path fill="#fff" d="M77.33 53.584H65.272v3.468H77.33v-3.468Z" />
            <path fill="#303030" d="M77.33 53.584H65.272v3.468H77.33v-3.468Z" />
            <path fill="#1B1B1B" d="M126.625 97.32h-12.058v3.467h12.058v-3.468Z" />
            <path
                fill="#5E5E5E"
                d="M76.426 60.328h-7.083a.963.963 0 1 0 0 1.925h7.083a.963.963 0 1 0 0-1.925Z"
            />
            <path
                fill="#303030"
                d="M125.721 104.141h-7.084a.963.963 0 1 0 0 1.926h7.084a.964.964 0 1 0 0-1.926Z"
            />
            <path
                fill="#1B1B1B"
                d="M122.554 104.141h-7.083a.963.963 0 1 0 0 1.926h7.083a.964.964 0 1 0 0-1.926Z"
            />
            <path
                fill="#1B1B1B"
                d="M121.215 104.141h-7.083a.963.963 0 1 0 0 1.926h7.083a.963.963 0 1 0 0-1.926Z"
            />
            <path
                fill="#5E5E5E"
                d="M34.59 126.208a.928.928 0 0 1-.22-.027l-7.461-1.858a.902.902 0 1 1 .436-1.752l7.462 1.859a.902.902 0 0 1-.218 1.778h.001ZM63.24 126.673H56.37a.904.904 0 0 1 0-1.806h6.868a.904.904 0 0 1 0 1.806Z"
            />
            <path
                fill="#B26E3D"
                d="M63.923 65.767c-2.286 0-4.456-.761-6.099-1.556a1.882 1.882 0 1 1 1.64-3.387c5.927 2.866 8.147-.376 8.17-.41a1.883 1.883 0 0 1 2.606-.54 1.88 1.88 0 0 1 .54 2.604c-.11.168-1.145 1.676-3.315 2.592a9.045 9.045 0 0 1-3.542.695v.002ZM47.417 25.139a1.944 1.944 0 0 0 3.615 1.429 1.932 1.932 0 0 0-.156-1.736 1.925 1.925 0 0 0-.937-.783 1.95 1.95 0 0 0-2.073.416 1.91 1.91 0 0 0-.45.675v-.001Z"
            />
            <path
                fill="#474747"
                d="M47.997 22.486a.82.82 0 1 0 1.065-.46.823.823 0 0 0-1.065.461v-.001ZM49.94 24.048a1.934 1.934 0 0 1 1.186 1.405l-3.74-.224.03-.09a1.944 1.944 0 0 1 2.522-1.09Z"
            />
            <path
                fill="#303030"
                d="M71.502 60.328H64.42a.964.964 0 1 0 0 1.925h7.083a.963.963 0 1 0 0-1.925Z"
            />
            <path
                fill="#303030"
                d="M73.258 60.328h-7.083a.963.963 0 1 0 0 1.925h7.083a.963.963 0 1 0 0-1.925Z"
            />
            <path
                fill="#B26E3D"
                d="M66.035 64.085a2.96 2.96 0 0 1-.794-.106.828.828 0 1 1 .452-1.591c.702.198 1.51-.234 2.16-1.157a.828.828 0 0 1 1.355.952c-1.113 1.578-2.35 1.902-3.175 1.902h.002ZM61.87 28.858a.827.827 0 0 1-.816-.972c.097-.54.621-2.338 3.35-2.784a.827.827 0 1 1 .266 1.633c-1.116.182-1.856.72-1.984 1.438a.827.827 0 0 1-.815.683v.002Z"
            />
            <path
                fill="#C6C6C6"
                d="M60.967 66.347c-.282 0-.568-.031-.854-.094a88.495 88.495 0 0 1-7.802-2.205c-8.115-2.721-10.344-5.129-11.315-6.703-1.918-3.108-3.247-7.207-3.744-11.54-.466-4.083-.141-7.989.892-10.716 1.197-3.162 3.135-4.337 4.55-4.767a3.862 3.862 0 0 1 2.819 7.168c-.364.707-.904 2.844-.69 6.185.233 3.64 1.231 7.134 2.739 9.591.096.114 1.38 1.524 7.404 3.524 3.542 1.175 6.814 1.918 6.847 1.926a3.862 3.862 0 0 1-.848 7.631h.002Zm-16.02-28.633h-.004.003Z"
            />
        </g>
    </svg>
);

export default memo(Recents);
