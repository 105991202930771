import React, { memo, Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { ModalOptions } from '@web/web5';
import { Input } from 'hoi-poi-ui';

import { ConfigActions, EntityExportActions, EntityListActions } from 'actions';
import { ACTIVITIES } from 'constants/Entities';
import { REG_EX_EMAIL } from 'constants/Constants';
import { logEvent } from 'utils/tracking';
import { getLiteral } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';
import { transformWeb3ActivitiesFilters } from 'utils/fm';
import { isEqual } from 'utils/arrays';
import Context from 'managers/Context';
import ProgressExportModal from './ProgressExportModal';
import { getSFMTypesFromSchema } from 'containers/Activities/utils/activities';

import './styles.scss';

// TO-DO: Get this values from config when available
const exportLimit = {
    fields: 50,
    data: 50000,
};

// Fields that should be included in any export
const fixedFields = ['id', 'extid'];

const mapStateToProps = (state, ownProps) => {
    const { isOpen, overrideEntityName, config } = ownProps;
    const name =
        overrideEntityName || config?.entity?.entity || config?.entity || ownProps?.entity?.entity;

    return {
        entityTrueName: state.entityExport.entityTrueName || overrideEntityName,
        isOpen: isOpen || state.entityExport.isOpen || false,
        userId: state.config.userData.idUsuario,
        userEmail: state.config.userData.email,
        userName: `${state.config.userData.nombre} ${state.config.userData.apellidos}`,
        options: state.entityExport.options,
        lastExport: state.config.components[`${name}_export`] || null,
        exportOverrides: state.entityExport.overrides,
        bulkAllEntities: state.config?.permission?.bulkAllEntities,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    const {
        overrideEntityName,
        config,
        checkStatus: checkStatusProp,
        download: downloadProp,
        sendEmails: sendEmailsProp,
    } = ownProps;
    const name =
        overrideEntityName || config?.entity?.entity || config?.entity || ownProps?.entity?.entity;
    const { setConfigWeb } = bindActionCreators(ConfigActions, dispatch);
    const {
        download,
        sendEmails,
        checkStatus,
        close,
        getExportEmailOrDownload,
        setExportOverrides,
    } = bindActionCreators(EntityExportActions, dispatch);
    const entityListActions = bindActionCreators(EntityListActions, dispatch);

    return {
        close,
        download: downloadProp || download,
        saveCache: (config) => setConfigWeb(`${name}_export`, config),
        sendEmails: sendEmailsProp || sendEmails,
        checkStatus: checkStatusProp || checkStatus,
        getEntityCounts: entityListActions.getEntityCounts,
        getExportEmailOrDownload,
        rawGetEntityCounts: entityListActions.rawGetEntityCounts,
        setExportOverrides,
    };
};

const EntityExport = memo((props) => {
    const {
        isOpen,
        customs = {},
        fields = {},
        options,
        close,
        download,
        sendEmails,
        getExportEmailOrDownload,
        checkStatus,
        config,
        defaultFields: defaultFieldsProp,
        visibleFields,
        entity,
        getEntityCounts,
        rawGetEntityCounts,
        saveCache,
        lastExport,
        selectorsSchema,
        multiSchema,
        onClose,
        multiSchemaSelected,
        relatedGroups,
        groupNonRelated,
        entityTrueName,
        total,
        action,
        entityCustomFilters,
        customEntity,
        activityType,
        setExportOverrides,
        onChangeSelectedSchema,
        exportOverrides,
        bulkAllEntities,
    } = props;

    const currentTimeout = useRef(null);
    const isFirstRender = useRef(true);
    const isFirstExport = useRef(true);

    const getInitialState = useCallback(
        (props) => {
            return {
                users: [
                    {
                        ExtraInfoId: props.userEmail,
                        label: props.userName,
                        value: props.userId,
                    },
                ],
                action,
                contacts: [],
                email: props.userEmail,
                emails: '',
                emailsLoading: true,
                errors: {
                    errorEmail: '',
                    errorEmails: '',
                },
                reinitializingExport: false,
            };
        },
        [action],
    );

    const [state, setState] = useState(getInitialState(props));
    const [numTries, setNumTries] = useState(0);
    const [lapse, setLapse] = useState(1000);
    const [mappedSchema, setMappedSchema] = useState([]);
    const [selectedFields, setSelectedFields] = useState({});
    const [strFieldsToExport, setStrFieldsToExport] = useState(null);
    const [totalData, setTotalData] = useState(total || null);
    const [fieldsNumber, setFieldsNumber] = useState(0);
    const [defaultFields, setDefaultFields] = useState(
        lastExport || defaultFieldsProp || visibleFields || {},
    );

    const [progress, setProgress] = useState(null);
    const oldSelectedFields = useRef({});
    const oldStrFieldsToExport = useRef(null);
    const oldFieldsNumber = useRef(0);
    const isGettingTotal = useRef(false);
    const progressModal = useRef();
    const oldMultiSchemaSelected = useRef(multiSchemaSelected || []);
    const activityTypeRef = useRef(null);
    const touchedSelected = useRef(false);

    const selectedSchemas = useMemo(() => {
        if (!multiSchema) return {};
        return Object.entries(selectedFields).reduce((obj, [key, value]) => {
            const selectedSchema = multiSchema.filter(
                (schema) => schema.id === key && value.isSelected,
            )[0];
            return selectedSchema ? { ...obj, [selectedSchema.id]: selectedSchema.label } : obj;
        }, {});
    }, [multiSchema, selectedFields]);

    useEffect(() => {
        // When exporting Activities when changing the ActivityType filter
        // in the export modal we need to reinitialize the export
        const newActivityType = getSFMTypesFromSchema(selectedSchemas);

        if (
            entity?.entity !== ACTIVITIES.entity ||
            (isFirstExport.current && !touchedSelected.current) ||
            activityTypeRef.current === newActivityType
        )
            return;

        if (isOpen && newActivityType !== '-1' && activityType !== newActivityType) {
            setExportOverrides({ activityType: newActivityType });
            if (onChangeSelectedSchema) {
                activityTypeRef.current = newActivityType;
                setState((state) => ({ ...state, reinitializingExport: true }));
                onChangeSelectedSchema().then(() => {
                    getEntityCounts(entity)
                        .then((value) => {
                            setTotalData(!value || value < 0 ? 0 : value);
                        })
                        .catch((error) => {
                            console.error(error);
                        })
                        .finally(() => {
                            setState((state) => ({ ...state, reinitializingExport: false }));
                        });
                });
            }
        }
    }, [
        activityType,
        multiSchema,
        lastExport,
        multiSchemaSelected,
        selectedSchemas,
        isOpen,
        setExportOverrides,
        onChangeSelectedSchema,
        getEntityCounts,
        state.reinitializingExport,
        entity,
    ]);

    useEffect(() => {
        // Sync external total for entities like campaigns
        if (total && totalData !== total) setTotalData(total);
    }, [total, totalData]);

    useEffect(() => {
        if (state.reinitializingExport) return;

        if (isFirstRender.current) {
            isFirstRender.current = false;
            setState((state) => ({
                ...state,
                emailsLoading: false,
            }));
        }

        if (
            total === undefined &&
            getEntityCounts &&
            !state.emailsLoading &&
            totalData === null &&
            !isGettingTotal.current &&
            isOpen
        ) {
            isGettingTotal.current = true;
            let pr;

            if (entityCustomFilters) {
                pr = rawGetEntityCounts(ACTIVITIES, {
                    filter: transformWeb3ActivitiesFilters(entityCustomFilters),
                });
            } else if (entity) pr = getEntityCounts(entity);
            pr.then((value) => {
                setTotalData(!value || value < 0 ? 0 : value);
            })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => (isGettingTotal.current = false));
        }
    }, [
        entity,
        entityCustomFilters,
        getEntityCounts,
        isOpen,
        rawGetEntityCounts,
        state.emailsLoading,
        state.reinitializingExport,
        total,
        totalData,
    ]);

    useEffect(() => {
        // When has multiSchemaSelected (Activities for example) when changing the ActivityType filter
        // we need to get new schemas for the export modal. Here we force getting those schemas, otherwise
        // the schemas are not updated and we may try to print the schema of an ActivityType is no longer
        // chosen to do the export
        if (!isFirstRender.current && multiSchemaSelected && oldMultiSchemaSelected.current) {
            const multiSchemaItems = Object.entries(multiSchemaSelected).reduce((arr, [key]) => {
                arr.push(key);
                return arr;
            }, []);
            const oldMultiSchemaItems = Object.entries(oldMultiSchemaSelected.current).reduce(
                (arr, [key]) => {
                    arr.push(key);
                    return arr;
                },
                [],
            );
            const areEqual = isEqual(multiSchemaItems, oldMultiSchemaItems);
            if (!areEqual) {
                const newDefaultFields = lastExport || defaultFieldsProp || visibleFields || {};
                const selected =
                    Object.entries(newDefaultFields).length > 0
                        ? getMultiSchemaSelected(newDefaultFields, multiSchemaSelected)
                        : multiSchemaSelected;

                const { fieldsToExport, newStrFieldsToExport } = processSelectedFields(selected);

                setDefaultFields(newDefaultFields);
                setSelectedFields({ ...selected });
                setFieldsNumber(fieldsToExport.length);
                setStrFieldsToExport({ ...newStrFieldsToExport });

                oldStrFieldsToExport.current = newStrFieldsToExport;
                oldFieldsNumber.current = fieldsToExport.length;
            }
        }
    }, [
        multiSchemaSelected,
        getMultiSchemaSelected,
        processSelectedFields,
        defaultFieldsProp,
        lastExport,
        visibleFields,
    ]);

    useEffect(() => {
        if (!entity && action) {
            return setState((state) => ({
                ...state,
                action: action || 'excel',
            }));
        }
        if (!strFieldsToExport || totalData === null || !isOpen || state.reinitializingExport)
            return;

        const { newFilteredStrFieldsToExport, newFilteredStrFieldsToExportRelated } =
            extractRelatedGroups(strFieldsToExport);

        if (totalData && totalData > exportLimit.data && !bulkAllEntities) {
            return setState((state) => ({
                ...state,
                action: 'excel',
            }));
        }

        let finalActivityType = activityType;

        if (exportOverrides?.activityType) {
            finalActivityType = exportOverrides.activityType;
        }

        getExportEmailOrDownload(
            newFilteredStrFieldsToExport,
            newFilteredStrFieldsToExportRelated,
            totalData,
            customEntity,
            finalActivityType,
        )
            .then((data) => {
                if (data)
                    setState((state) => ({
                        ...state,
                        action: data.Result ? 'email' : 'excel',
                    }));
            })
            .catch((err) => {
                console.error(err);
                errorToast({
                    text: getLiteral('error_generalerror'),
                });
            });
    }, [
        action,
        activityType,
        customEntity,
        entity,
        entityTrueName,
        exportOverrides,
        extractRelatedGroups,
        getExportEmailOrDownload,
        isOpen,
        strFieldsToExport,
        totalData,
        state.reinitializingExport,
        bulkAllEntities,
    ]);

    const handleSaveCache = useCallback(
        (strFieldsToExport) => {
            if (!strFieldsToExport) return;

            let payload;

            if (!multiSchema) {
                if (!config) return;
                const columnDefs = config.columnDefs;
                const arrFieldsToExport = strFieldsToExport.split(',');
                payload = columnDefs.reduce((obj, { colId }) => {
                    return { ...obj, [colId]: arrFieldsToExport.includes(colId) };
                }, {});
            } else {
                payload = {
                    ...strFieldsToExport,
                };
            }

            setDefaultFields(payload);
            saveCache(payload);
        },
        [config, multiSchema, saveCache],
    );

    const onChange = useCallback(
        (field) => (value) => {
            setState({ ...state, [field]: value });
        },
        [state],
    );

    const handleOnClose = useCallback(
        (isSuccess) => {
            if (currentTimeout.current !== null) {
                clearTimeout(currentTimeout.current);
                setState({ ...getInitialState(props) });
                if (activityType === '-1') activityTypeRef.current = null;
            }
            if (!isSuccess) {
                setSelectedFields(JSON.parse(JSON.stringify(oldSelectedFields.current)));
            }

            close();
            onClose && onClose();

            // Avoid animation blinking
            setTimeout(() => {
                setStrFieldsToExport(oldStrFieldsToExport.current);
                setFieldsNumber(oldFieldsNumber.current);
                setState({
                    ...state,
                    action: null,
                    emailsLoading: false,
                    reinitializingExport: false,
                });
                setTotalData(null);
                setProgress(null);
                if (activityType === '-1') activityTypeRef.current = null;
            }, 1000);
        },
        [close, getInitialState, onClose, props, state, activityType],
    );

    const onCancelProgress = useCallback(() => {
        handleOnClose();
        logEvent({
            event: entityTrueName,
            functionality: 'cancelMidExport',
        });
    }, [entityTrueName, handleOnClose]);

    const resetStatus = useCallback(() => {
        setNumTries(0);
        setLapse(1000);
        clearTimeout(currentTimeout.current);
        currentTimeout.current = null;
        setState({ ...state });
    }, [state]);

    const checkExportStatus = useCallback(() => {
        currentTimeout.current = setTimeout(() => {
            checkStatus().then(
                (data) => {
                    if (data && data.status === '3') {
                        setProgress(data.progress);
                        resetStatus();
                        let urlDownloadDocument;

                        urlDownloadDocument = Context.constants.getUrlDownloadDocument();
                        urlDownloadDocument = `${urlDownloadDocument}?token=${data.tokenId}`;

                        Context.utilsFormats.saveAs(
                            urlDownloadDocument,
                            `exportData_${moment().format('DD_MM_YYYY_HH_mm')}.xlsx`,
                        );
                        progressModal.current.close();

                        // When is success we update the oldSelectedFields, so when opening the modal again we keep
                        // the selection of fields from the last export
                        oldSelectedFields.current = selectedFields;
                        handleOnClose(true);
                    } else if (data && data.status === '4') {
                        progressModal.current.close();
                        resetStatus();
                        errorToast({
                            text: getLiteral('warning_export_interruption'),
                        });
                        handleOnClose();
                    } else if (data && data.status === '5') {
                        progressModal.current.close();
                        resetStatus();
                        errorToast({
                            title: getLiteral('error_export_not_allowed'),
                            text: getLiteral('error_export_not_allowed_desc'),
                        });
                        handleOnClose();
                    } else {
                        setProgress(data.progress);
                        setNumTries((numTries) => numTries++);
                        if (numTries >= 10) {
                            setLapse(10000);
                        }
                        if (!isOpen) return;
                        checkExportStatus();
                    }
                },
                (err) => {
                    console.error(err);
                    errorToast({
                        text: getLiteral('warning_export_interruption'),
                    });
                    handleOnClose();
                    progressModal.current.close();
                },
            );
        }, lapse);
    }, [lapse, checkStatus, resetStatus, handleOnClose, numTries, isOpen, selectedFields]);

    const extractRelatedGroups = useCallback(
        (strFieldsToExport) => {
            if (!relatedGroups || relatedGroups.length === 0) {
                return {
                    newFilteredStrFieldsToExport: strFieldsToExport,
                    newFilteredStrFieldsToExportRelated: null,
                };
            }

            if (strFieldsToExport && Object.keys(strFieldsToExport).length > 0) {
                let newFilteredStrFieldsToExport = groupNonRelated ? {} : '';
                const newFilteredStrFieldsToExportRelated = {};

                Object.keys(strFieldsToExport).forEach((key) => {
                    if (!relatedGroups.includes(key)) {
                        if (groupNonRelated) {
                            newFilteredStrFieldsToExport[key] = strFieldsToExport[key];
                        } else newFilteredStrFieldsToExport = strFieldsToExport[key];
                    } else newFilteredStrFieldsToExportRelated[key] = strFieldsToExport[key];
                });

                return {
                    newFilteredStrFieldsToExport,
                    newFilteredStrFieldsToExportRelated,
                };
            } else
                return {
                    newFilteredStrFieldsToExport: '',
                    newFilteredStrFieldsToExportRelated: null,
                };
        },
        [relatedGroups, groupNonRelated],
    );

    const onDownload = useCallback(() => {
        handleSaveCache(strFieldsToExport);
        let { newFilteredStrFieldsToExport, newFilteredStrFieldsToExportRelated } =
            extractRelatedGroups(strFieldsToExport);

        // Using current schema order
        if (typeof newFilteredStrFieldsToExport === 'string') {
            const strFieldsArray = newFilteredStrFieldsToExport?.split(',');
            const schemaOrder = [];
            if (strFieldsArray?.length > 0) {
                mappedSchema?.forEach((section) =>
                    section.options?.forEach((option) => {
                        if (strFieldsArray.includes(option.id)) schemaOrder.push(option.id);
                    }),
                );

                const diff =
                    strFieldsArray?.filter((element) => !schemaOrder.includes(element)) || [];
                newFilteredStrFieldsToExport = [...new Set([...diff, ...schemaOrder])]?.join(',');
            }
        }

        download(newFilteredStrFieldsToExport, newFilteredStrFieldsToExportRelated).then(
            () => {
                close();
                setProgress(0);
                checkExportStatus();
                setTimeout(() => {
                    progressModal.current.open();
                });
            },
            (err) => {
                console.error(err);
                errorToast({
                    text: getLiteral('warning_export_interruption'),
                });
                handleOnClose();
            },
        );

        isFirstExport.current = false;
    }, [
        handleSaveCache,
        strFieldsToExport,
        extractRelatedGroups,
        download,
        mappedSchema,
        close,
        checkExportStatus,
        handleOnClose,
    ]);

    const validateEmail = useCallback(() => {
        const { email, emails } = state;

        let isValidEmail = true;
        let errors = {
            errorEmail: '',
            errorEmails: '',
        };

        if (fields.freeSingleEmail) {
            isValidEmail = !!email.trim().match(REG_EX_EMAIL);
            errors.errorEmail = !isValidEmail ? getLiteral('label_invalid_email') : '';
        }
        if (fields.freeEmails) {
            const listEmails = emails.replace(/\s/g, '').split(',');
            for (let i = 0; i < listEmails.length; i++) {
                if (!!!listEmails[i].match(REG_EX_EMAIL)) {
                    isValidEmail = false;
                    break;
                }
            }
            errors.errorEmails = !isValidEmail ? getLiteral('label_invalid_email') : '';
        }
        setState({ ...state, errors: errors });
        return isValidEmail;
    }, [fields.freeEmails, fields.freeSingleEmail, state]);

    const onSend = useCallback(() => {
        const { users, email, emails, emailsLoading, action } = state;

        if (emailsLoading) return;

        const isValidEmail = validateEmail();
        if (!isValidEmail) return;

        setState({ ...state, emailsLoading: true });

        let cleanedEmails = [];

        if (fields.freeSingleEmail) cleanedEmails.push(email.trim());
        if (fields.freeEmails) {
            cleanedEmails = [
                ...cleanedEmails,
                ...(emails || '').split(',').map((current) => current.trim()),
            ];
        }
        if (fields.users) {
            const usersEmails = users.map((user) => {
                return user.ExtraInfoId;
            });
            cleanedEmails = [...cleanedEmails, ...usersEmails];
        }

        const cleanedEmailsList = cleanedEmails.filter(Boolean);

        const emailsToSend = cleanedEmailsList.toString();

        handleSaveCache(strFieldsToExport);

        const { newFilteredStrFieldsToExport, newFilteredStrFieldsToExportRelated } =
            extractRelatedGroups(strFieldsToExport);

        sendEmails(
            emailsToSend,
            newFilteredStrFieldsToExport,
            newFilteredStrFieldsToExportRelated,
        ).then(
            () => {
                setState({ ...state, emailsLoading: false });
                successToast({
                    text: getLiteral('label_toast_export_email_incoming'),
                });

                const functionalityName = {
                    email: 'exportToMail',
                    excel: 'exportToExcel',
                };

                const functionality = functionalityName[action] || '';

                if (functionality) {
                    logEvent({
                        event: entityTrueName,
                        functionality,
                    });
                }

                handleOnClose(true);
            },
            (err) => {
                console.error(err);
                setState({ ...state, emailsLoading: false });
                errorToast({
                    text: getLiteral('error_generalerror'),
                });
                handleOnClose();
            },
        );
    }, [
        state,
        validateEmail,
        fields.freeSingleEmail,
        fields.freeEmails,
        fields.users,
        handleSaveCache,
        strFieldsToExport,
        sendEmails,
        handleOnClose,
        extractRelatedGroups,
        entityTrueName,
    ]);

    // Get fixed columns (Not in config.groupedFields object)
    const fixedColumns = useMemo(() => {
        if (!config) return [];
        return config.columnDefs.reduce((fields, column) => {
            if ((column.suppressFromFieldSelector && !column.forceExport) || column.pinned)
                fields.push(column.colId);
            return fields;
        }, []);
    }, [config]);

    const processSelectedFieldsGroup = useCallback(
        (newSelectedFields, entity) => {
            const fieldsToExport = Object.values(newSelectedFields).reduce((fields, group) => {
                return [...fields, ...group.options];
            }, []);

            let forceExport = [];
            // Get fields that should always be included in the export of each activity type or related entity
            if (entity && multiSchema) {
                multiSchema.forEach((schema) => {
                    if (schema.id === entity && schema.forceExport?.length) {
                        forceExport = schema.forceExport;
                    }
                });
            }

            const newFieldsToExport = new Set([
                ...fixedFields,
                ...fixedColumns,
                ...(forceExport || []),
                ...fieldsToExport,
            ]);

            const uniqueFieldsToExport = [...newFieldsToExport];
            const newStrFieldsToExport =
                uniqueFieldsToExport.length > 0 ? uniqueFieldsToExport.join(',') : null;
            return {
                fieldsToExport,
                newStrFieldsToExport,
            };
        },
        [fixedColumns, multiSchema],
    );

    const processSelectedFields = useCallback(
        (newSelectedFields) => {
            if (multiSchema) {
                let newFieldsToExport = [];
                const newStrFieldsToExportObj = Object.entries(newSelectedFields).reduce(
                    (obj, [key, value]) => {
                        if (value.isSelected && value.options) {
                            const { fieldsToExport, newStrFieldsToExport } =
                                processSelectedFieldsGroup(value.options, key);
                            newFieldsToExport = [
                                ...new Set([...newFieldsToExport, ...fieldsToExport]),
                            ];
                            return {
                                ...obj,
                                [key]: newStrFieldsToExport,
                            };
                        } else {
                            return obj;
                        }
                    },
                    {},
                );

                return {
                    fieldsToExport: newFieldsToExport,
                    newStrFieldsToExport: newStrFieldsToExportObj,
                };
            } else {
                return processSelectedFieldsGroup(newSelectedFields);
            }
        },
        [multiSchema, processSelectedFieldsGroup],
    );

    const onChangeSelectedFields = useCallback(
        (newSelectedFields, updatedModalOptions) => {
            if (!isFirstRender.current) touchedSelected.current = true;

            const selected =
                multiSchema && isFirstRender.current && Object.entries(defaultFields).length > 0
                    ? getMultiSchemaSelected(defaultFields, newSelectedFields)
                    : newSelectedFields;

            const { fieldsToExport, newStrFieldsToExport } = processSelectedFields(selected);

            setSelectedFields({ ...selected });
            setFieldsNumber(fieldsToExport.length);
            setStrFieldsToExport(newStrFieldsToExport);

            if (
                !updatedModalOptions ||
                (updatedModalOptions &&
                    (updatedModalOptions?.action === 'select' ||
                        updatedModalOptions?.action === 'unselect'))
            ) {
                oldStrFieldsToExport.current = newStrFieldsToExport;
                oldFieldsNumber.current = fieldsToExport.length;
            }
        },
        [defaultFields, getMultiSchemaSelected, multiSchema, processSelectedFields],
    );

    const getMultiSchemaSelected = useCallback(
        (defaultFields, selectedFields) => {
            const newMultiSelected = multiSchema.reduce((obj, item) => {
                const defaultFieldsArr = defaultFields[item.id]
                    ? defaultFields[item.id].split(',')
                    : [];
                const schema = item.schema;

                const newSchema = schema.reduce((schemaObj, schemaItem) => {
                    const options = schemaItem.options.reduce((fields, field) => {
                        if (defaultFieldsArr.includes(field.id)) fields.push(field.id);
                        return fields;
                    }, []);

                    return {
                        ...schemaObj,
                        [schemaItem.id]: {
                            isSelected:
                                (options.length > 0 &&
                                    options.length === schemaItem.options.length) ||
                                false,
                            options,
                        },
                    };
                }, {});

                return {
                    ...obj,
                    [item.id]: {
                        ...selectedFields[item.id],
                        options: newSchema,
                    },
                };
            }, {});

            if (Object.keys(oldSelectedFields?.current).length === 0) {
                oldSelectedFields.current = newMultiSelected;
            }
            return newMultiSelected;
        },
        [multiSchema],
    );

    const getModalOptionsSchema = useCallback(
        (groupedColumns, fieldsToExport) => {
            const newMappedSchema = groupedColumns.map((item) => {
                const options = item.fields
                    .filter((option) => !option.suppressFromFieldSelector || option.forceExport)
                    .map((option) => ({
                        id: option.colId,
                        label: option.headerName,
                        hide: option.hide,
                    }));

                return {
                    label: item.label || getLiteral('label_fields'),
                    id: item.label || getLiteral('label_fields'),
                    options: options,
                };
            });

            const newSelectedFields = newMappedSchema.reduce((obj, group) => {
                const options = group.options.reduce((fields, field) => {
                    if (fieldsToExport?.[field.id]) fields.push(field.id);
                    else if (!fieldsToExport?.hasOwnProperty(field.id) && !field.hide)
                        fields.push(field.id);
                    return fields;
                }, []);

                obj[group.id] = {
                    isSelected:
                        (options.length > 0 && options.length === group.options.length) || false,
                    options,
                };

                return obj;
            }, {});

            oldSelectedFields.current = JSON.parse(JSON.stringify(newSelectedFields));
            setMappedSchema([...newMappedSchema]);
            onChangeSelectedFields(newSelectedFields);
        },
        [onChangeSelectedFields],
    );

    useEffect(() => {
        if (!multiSchema && config && config?.groupedFields?.length > 0) {
            getModalOptionsSchema(config.groupedFields, defaultFields);
        }
    }, [config, getModalOptionsSchema, defaultFields, multiSchema]);

    const InputEmail = useMemo(
        () => (
            <Input
                error={state.errors.errorEmail || state.errors.errorEmails}
                isFullWidth
                label={getLiteral('label_email')}
                labelMode="vertical"
                onChange={onChange('email')}
                placeholder={getLiteral('placeholder_email_adress')}
                type="email"
                value={state.email}
            />
        ),
        [onChange, state.email, state.errors.errorEmail, state.errors.errorEmails],
    );

    const getAdvice = useCallback(
        (action) => {
            let adviceType = 'warning';
            let advice = null;
            if (totalData && totalData > exportLimit.data && !bulkAllEntities)
                advice = getLiteral('label_export_too_many_registries');
            else if (action === 'email') advice = getLiteral('label_export_switched_to_email');
            else if (fieldsNumber > exportLimit.fields)
                advice = getLiteral('label_export_many_fields_selected');
            else if (fieldsNumber === 0) advice = getLiteral('label_export_at_least_one_field');
            else if (multiSchema && action === 'excel') {
                const selectedSchemasLabels = Object.values(selectedSchemas);

                if (selectedSchemasLabels.length > 0) {
                    // Concat the selected schemas like this: "Information, General & Contact"
                    const selectedSchemasStr =
                        selectedSchemasLabels.length > 1
                            ? `${selectedSchemasLabels
                                  .slice(0, -1)
                                  .join(', ')} & ${selectedSchemasLabels.slice(-1)}`
                            : selectedSchemasLabels[0];

                    // Necessary to override <strong> default styles when used in WEB3
                    const strongStyles = {
                        fontWeight: 500,
                        fontSize: 'inherit',
                        color: 'inherit',
                    };

                    advice = (
                        <Fragment>
                            <span>
                                {action === 'email'
                                    ? getLiteral('label_export_activities_desc_by_email')
                                    : getLiteral('label_export_activities_desc_by_excel')}
                            </span>
                            <span>&nbsp;</span>
                            <strong style={strongStyles}>{selectedSchemasStr}</strong>.
                        </Fragment>
                    );
                    adviceType = 'info';
                }
            }

            return {
                adviceType,
                advice,
            };
        },
        [bulkAllEntities, fieldsNumber, multiSchema, selectedSchemas, totalData],
    );

    const modalOptionsProps = useCallback(
        (action) => {
            const { adviceType, advice } = getAdvice(action);

            const commonProps = {
                adviceType: adviceType,
                cancelText: getLiteral('action_cancel'),
                isOpen: progress === null ? isOpen : false,
                onCancel: handleOnClose,
                onChangeSelected: onChangeSelectedFields,
                placeholderSearch: getLiteral('label_search_field'),
                isLoading: state.reinitializingExport || !action,
            };

            if (multiSchema) {
                if (selectorsSchema?.length) commonProps.selectorsSchema = selectorsSchema;
                commonProps.multiSchema = multiSchema;
                commonProps.selected = Object.entries(selectedFields).length
                    ? selectedFields
                    : multiSchemaSelected;
            } else {
                commonProps.schema = mappedSchema;
                commonProps.selected = selectedFields;
            }

            const { users, emails, emailsLoading } = state;

            const cantExport =
                (totalData > exportLimit.data && !bulkAllEntities) || fieldsNumber === 0;
            const cantEmail = users && !users.length && !emails;
            const cantDownload = options && options.cantDownload;

            let finalProps = {
                ...commonProps,
            };

            switch (action) {
                case 'email':
                    finalProps = {
                        ...commonProps,
                        advice: advice,
                        confirmText: getLiteral('action_send_email'),
                        onConfirm: onSend,
                        overrides: {
                            confirmButton: {
                                isLoading: emailsLoading,
                                isDisabled: cantEmail || cantExport,
                            },
                        },
                        postComponent: InputEmail,
                        title: getLiteral('label_export_share_email'),
                    };
                    break;
                case 'excel':
                    finalProps = {
                        ...commonProps,
                        advice: (cantDownload && customs.cantDownload) || advice,
                        confirmText: getLiteral('action_export_excel'),
                        onConfirm: onDownload,
                        overrides: {
                            confirmButton: {
                                isDisabled: cantDownload || cantExport,
                            },
                        },
                        title: getLiteral('label_export_excel'),
                    };
                    break;
                default:
                    break;
            }

            return finalProps;
        },
        [
            getAdvice,
            progress,
            isOpen,
            handleOnClose,
            onChangeSelectedFields,
            state,
            multiSchema,
            totalData,
            bulkAllEntities,
            fieldsNumber,
            options,
            selectorsSchema,
            selectedFields,
            multiSchemaSelected,
            mappedSchema,
            onSend,
            InputEmail,
            customs.cantDownload,
            onDownload,
        ],
    );

    const modalOptions = useMemo(
        () => modalOptionsProps(state.action),
        [modalOptionsProps, state.action],
    );

    return (
        <Fragment>
            <ModalOptions {...modalOptions} />
            <ProgressExportModal
                onRef={(ref) => (progressModal.current = ref)}
                onCancel={onCancelProgress}
                progress={progress}
            />
        </Fragment>
    );
});

EntityExport.propTypes = {
    action: PropTypes.oneOf(['email', 'excel']),
    customs: PropTypes.shape({
        cantDownload: PropTypes.string,
    }),
    fields: PropTypes.shape({
        download: PropTypes.bool,
        emails: PropTypes.bool,
        freeEmails: PropTypes.bool,
        freeSingleEmail: PropTypes.bool,
        users: PropTypes.bool,
    }),
    config: PropTypes.shape({
        columnDefs: PropTypes.arrayOf(
            PropTypes.shape({
                colId: PropTypes.string,
                suppressFromFieldSelector: PropTypes.bool,
                forceExport: PropTypes.bool,
            }),
        ),
        groupedFields: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                id: PropTypes.string,
                options: PropTypes.arrayOf(
                    PropTypes.shape({
                        label: PropTypes.string,
                        id: PropTypes.string,
                    }),
                ),
            }),
        ),
    }),
    // Ex:
    // visibleFields: {
    //     ['opportunities']: ['status', 'name', 'environment'],
    //     ['companies']: ['name', 'country'],
    // }
    visibleFields: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
    overrideEntityName: PropTypes.string,
    onClose: PropTypes.func,
    multiSchema: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            id: PropTypes.string,
            schema: PropTypes.arrayOf(
                PropTypes.shape({
                    label: PropTypes.string,
                    id: PropTypes.string,
                    options: PropTypes.arrayOf(
                        PropTypes.shape({
                            label: PropTypes.string,
                            id: PropTypes.string,
                        }),
                    ),
                }),
            ),
        }),
    ),
    multiSchemaSelected: PropTypes.shape({
        id: PropTypes.shape({
            isExpanded: PropTypes.bool,
            isSelected: PropTypes.bool,
            options: PropTypes.shape({
                id: PropTypes.shape({
                    isSelected: PropTypes.bool,
                    options: PropTypes.array,
                }),
            }),
        }),
    }),
    relatedGroups: PropTypes.arrayOf(PropTypes.string),
};

export default connect(mapStateToProps, mapDispatchToProps)(EntityExport);
