import { memo, useCallback, useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Button, Radio, Text, Link } from 'hoi-poi-ui';
import { EntityListActions } from 'actions';
import { PAGINATION_TABLE_TASKS } from 'constants/Environment';
import { TASKS, COMPANIES } from 'constants/Entities';
import { AGENDA_TASKS_COMPLETED_FLAG, AGENDA_TASK } from 'constants/Constants';

import Context from 'managers/Context';
import { EntityModalActions } from 'actions';
import AgendaModel from 'models/AgendaModel';

import { getBackendBoolean } from 'utils/fm';
import { getLiteral } from 'utils/getLiteral';
import { getRoute, ensureRoute } from 'utils/routes';
import { logEvent } from 'utils/tracking';
import {
    formatDateToBackendUTC,
    getActivityDateFormat,
    getPreviousAndNextDate,
    getDateToTimezone,
} from 'utils/dates';
import { successToast, errorToast } from 'utils/toast';

import { subscribe } from 'lib/EventBuser';
import { ENTITY_MODAL_UPDATE } from 'lib/events';
import useEntityDetail from 'containers/components/EntityDetail/hooks/useEntityDetail';
import DashboardWidget from '../components/DashboardWidget';
import DashboardWidgetRow from '../components/DashboardWidgetRow';

const EMPTY_SVG = (
    <svg width={168} height={144} fill="none">
        <g mask="url(#a)">
            <path
                fill="#5E5E5E"
                d="M76.808 119.435c30.278 0 54.824-7.749 54.824-17.308 0-9.559-24.546-17.308-54.824-17.308s-54.824 7.75-54.824 17.308c0 9.559 24.546 17.308 54.824 17.308Z"
            />
            <path
                fill="#919191"
                d="M26.895 46.253s1.51 20.541 5.005 28.36c3.495 7.82 8.84 18.725 19.117 24.692 10.277 5.967 24.523 6.379 25.725 6.379 1.201 0 16.946 0 26.485-6.996 10.276-7.537 15.212-14.199 19.528-24.692 4.317-10.494 5.759-27.573 5.759-27.573l-101.619-.17Z"
            />
            <path
                fill="#919191"
                d="m111.395 89.641-2.214-5.295c.141-.06 14.249-5.986 21.851-10.31 3.1-1.762 5.58-4.073 7.171-6.682 1.206-1.978 1.898-4.136 1.898-5.92 0-4.645-2.298-5.58-4.301-5.875-1.74-.257-4.29.614-7.175 2.452-2.47 1.575-4.303 3.295-4.322 3.312l-3.939-4.171c.904-.857 9.04-8.336 16.271-7.273 5.847.86 9.2 5.071 9.2 11.554 0 4.975-3.135 12.57-11.97 17.593-7.903 4.496-21.879 10.367-22.47 10.614v.001Z"
            />
            <path
                fill="#777"
                d="M128.716 45.577c0 6.307-22.794 10.206-50.91 10.206-28.117 0-50.91-3.9-50.91-10.206 0-6.307 22.793-11.42 50.91-11.42 28.116 0 50.91 5.113 50.91 11.42Z"
            />
            <path
                fill="#777"
                d="M128.716 45.577c0-6.307-22.794-11.42-50.91-11.42-28.117 0-50.91 5.113-50.91 11.42 0 1.029.61 2.026 1.748 2.975 1.302-4.251 22.883-7.632 49.314-7.632 26.43 0 47.341 3.278 49.238 7.435.991-.89 1.52-1.82 1.52-2.778Z"
            />
            <path
                fill="#1B1B1B"
                d="M124.032 49.395c-4.12-3.963-22.063-8.475-46.073-8.475s-40.84 4.137-45.778 8.017c-.705.554-1.15-.311-1.313.22 5.723 4.772 22.656 6.488 45.589 6.621h.063c.294.002.588.002.883.003.156 0-22.705-1.212.469 0 23.173 1.213 46.162-6.387 46.162-6.387l-.002.001Z"
            />
            <path
                fill="#777"
                d="M77.805 36.179c13.455 0 26.083 1.17 35.557 3.296 10.938 2.454 13.334 5.236 13.334 6.102 0 .324-.31.789-.849 1.273-2.39 2.144-8.515 4.219-16.809 5.692-8.935 1.589-19.714 2.43-31.168 2.433H77.314c-.262 0-.523 0-.787-.003h-.061c-11.033-.065-21.414-.911-30.015-2.45-8.002-1.43-14.02-3.444-16.515-5.523-.65-.541-1.02-1.059-1.02-1.42 0-.866 2.395-3.65 13.333-6.103 9.474-2.125 22.103-3.296 35.556-3.296m0-2.022c-28.116 0-50.91 5.113-50.91 11.42 0 1.03.611 2.026 1.75 2.975 5.722 4.772 24.878 8.308 47.81 8.441h.063c.294.002.588.002.883.003h.47c23.816-.007 43.799-3.683 49.325-8.642.991-.89 1.52-1.82 1.52-2.778 0-6.307-22.794-11.42-50.91-11.42v.001Z"
            />
            <path
                fill="#474747"
                d="M35.849 81.743a.688.688 0 0 1-.04-1.375l3.108-.184a.687.687 0 1 1 .081 1.373l-3.108.184h-.041v.002ZM22.57 75.928a.688.688 0 0 1-.04-1.375l3.107-.183a.687.687 0 1 1 .082 1.373l-3.108.183h-.042v.002Z"
            />
            <path
                fill="#474747"
                d="M40.295 82.066a4.129 4.129 0 0 1-3.844-5.62l7.92-20.468-.828-.009-12.314 19.659a4.12 4.12 0 0 1-5.683 1.304 4.129 4.129 0 0 1-1.303-5.688L37.78 49.626a4.12 4.12 0 0 1 3.534-1.934l18.068.176c.209.002.418.02.624.055l2.774.453a4.127 4.127 0 0 1 .167 8.116l-10.794 2.224-8.013 20.712a4.126 4.126 0 0 1-3.846 2.638ZM58.501 31.751a2.773 2.773 0 0 1-2.402-1.384l-.945-1.63a2.78 2.78 0 0 1 1.008-3.796 2.773 2.773 0 0 1 3.791 1.01l.945 1.63a2.78 2.78 0 0 1-1.008 3.796 2.767 2.767 0 0 1-1.389.374Z"
            />
            <path
                fill="#B26E3D"
                d="M57.594 29.027a1.403 1.403 0 1 0 0-2.805 1.403 1.403 0 0 0 0 2.805Z"
            />
            <path
                fill="#474747"
                d="M56.19 26.565c1.416.571 3.084.686 3.084.686l-.867-1.893-2.217 1.207Z"
            />
            <path
                fill="#00D639"
                d="m55.775 53.346-8.112 2.518a.652.652 0 0 1-.526.068l-8.118-4.318a.654.654 0 0 1-.183-1.163l6.26-2.423a.653.653 0 0 1 .535-.095l9.969 4.222c.557.145.666.89.175 1.19Z"
            />
            <path
                fill="#008A21"
                d="m53.947 52.692-8.068 2.571-7.754-3.902 6.405-2.41 9.417 3.74Z"
            />
            <path
                fill="#919191"
                d="m47.102 48.048.725 6.994c.052.5-.42.89-.9.746l-9.944-4.768a.706.706 0 0 1-.498-.602l-.725-6.987a.702.702 0 0 1 .899-.746l9.943 4.763a.702.702 0 0 1 .497.601l.003-.001Z"
            />
            <path
                fill="#fff"
                d="M55.44 54.185c-.996 0-1.658-.06-1.71-.064a2.367 2.367 0 0 1 .433-4.714c2.433.218 7.661-.14 8.302-2.636.462-1.794-.22-7.398-1.754-11.664-.16-.444-.528-1.015-.864-.935-.225.165-.632.91-.873 1.36-.35.896-1.652 5.99-2.726 10.474a2.37 2.37 0 0 1-1.465 1.663l-5.912 2.23a2.367 2.367 0 0 1-1.667-4.43l4.74-1.788c1.477-6.105 2.429-9.576 2.833-10.33.685-1.275 1.624-3.023 3.561-3.666 2.821-.937 5.691.669 6.825 3.817 1.571 4.364 2.74 11.13 1.887 14.45-.56 2.182-2.369 4.982-7.799 5.925-1.42.247-2.788.31-3.806.31l-.004-.002Zm3.506-18.602-.005.008.005-.008Z"
            />
            <path
                fill="#B26E3D"
                d="M47.31 46.729c-.202 0-.394.031-.564.08-.3.086-.538.221-.73.358a3.228 3.228 0 0 0-.624.59c-.139.17-.218.296-.238.33a1.203 1.203 0 0 0 2.043 1.267l-.568-.352.561.363.007-.011-.568-.352.561.363s.092-.128.17-.197a.393.393 0 0 1 .05-.04l.02-.013-.118-.245v.265a.27.27 0 0 0 .119-.021l-.12-.245v.265a1.202 1.202 0 0 0 0-2.405Z"
            />
            <path
                fill="#00D639"
                d="m47.102 48.048.725 6.994c.052.5-.42.89-.9.746l-9.944-4.768a.706.706 0 0 1-.498-.601l-.725-6.988a.703.703 0 0 1 .899-.746l9.943 4.763a.702.702 0 0 1 .497.601l.003-.001Z"
            />
            <path
                fill="#fff"
                d="m63.644 36.075.215 14.678s-7.4.277-7.7-2.658c-.3-2.935.317-12.9.317-12.9l3.584-3.491 3.584 4.373v-.002Z"
            />
            <path
                fill="#B26E3D"
                d="M50.186 53.405a1.205 1.205 0 0 1-.564-2.267c.88-.468 2.05-.447 2.274-.438a1.2 1.2 0 0 1 1.153 1.25 1.202 1.202 0 0 1-1.245 1.155c-.298-.01-.832.04-1.056.158-.18.094-.373.14-.562.14v.002Z"
            />
            <path
                fill="#fff"
                d="M64.757 49.727a2.368 2.368 0 0 1-2.292-2.958c.461-1.794-.22-7.397-1.755-11.664-.16-.443-.528-1.015-.863-.934-.236.174-.672.983-.907 1.422a2.363 2.363 0 0 1-3.203.963 2.368 2.368 0 0 1-.962-3.206c.685-1.276 1.624-3.024 3.561-3.667 2.821-.937 5.691.67 6.825 3.818 1.571 4.364 2.74 11.13 1.885 14.45a2.368 2.368 0 0 1-2.288 1.777v-.001Z"
            />
        </g>
    </svg>
);

const TaskDashboardWidget = memo(() => {
    const { openTab } = useEntityDetail();
    const dispatch = useDispatch();
    const agendaPermission = useSelector(
        (state) => state?.config?.permission?.crud_permission?.AGENDA,
    );
    const nylasConfig = useSelector((state) => state.config?.nylas);
    const syncCalendarWithNylas = useSelector(
        (state) => state.config?.userData?.syncCalendarWithNylas || false,
    );
    const idUser = useSelector((state) => state?.config?.userData?.idUsuario);
    const idTimezone = useSelector((state) => state?.config?.userData?.idTimeZone);
    const userName = useSelector(
        (state) => `${state?.config?.userData?.nombre} ${state?.config?.userData?.apellidos}`,
    );

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [list, setList] = useState([]);
    const [filter, setFilter] = useState();
    const [checkeds, setCheckeds] = useState({});
    const [timezone, setTimezone] = useState();

    const getList = useCallback(() => {
        setLoading(true);
        setError(false);
        setCheckeds({});

        let filters = {
            fini: {
                id: 'fini',
                value: '',
            },
            fini: {
                id: 'ffin',
                value: '',
            },
            taskFlag: {
                id: 'taskFlag',
                value: '1',
            },
            completed: {
                id: 'completed',
                value: AGENDA_TASKS_COMPLETED_FLAG.incompleted,
            },
            useUTCDates: {
                id: 'useUTCDates',
                value: true,
            },
            newAttendees: {
                id: 'newAttendees',
                value: true,
            },
            filterTaskByDate: {
                value: true,
                id: 'filterTaskByDate',
            },
            idusuario: {
                value: [idUser],
                id: 'idusuario',
            },
        };

        let startDate = moment();
        let endDate = moment();
        switch (filter?.value) {
            case 'today':
                startDate.startOf('day');
                endDate.add(1, 'days').hours(0).minutes(0).seconds(0).milliseconds(0);
                filters.completed = {
                    id: 'completed',
                    value: AGENDA_TASKS_COMPLETED_FLAG.incompleted,
                };
                filters.fini = {
                    id: 'fini',
                    value: formatDateToBackendUTC(startDate.toDate()),
                };
                filters.ffin = {
                    id: 'ffin',
                    value: formatDateToBackendUTC(endDate.toDate()),
                };
                break;
            case 'upcoming':
                const futureDate = startDate.clone();
                futureDate.endOf('day');
                futureDate.add(50, 'years');
                filters.completed = {
                    id: 'completed',
                    value: AGENDA_TASKS_COMPLETED_FLAG.incompleted,
                };
                filters.fini = {
                    id: 'fini',
                    value: formatDateToBackendUTC(startDate.toDate()),
                };
                filters.ffin = {
                    id: 'ffin',
                    value: formatDateToBackendUTC(futureDate.toDate()),
                };
                break;
            case 'overdue':
                endDate.subtract(1, 'minutes');
                filters.completed = {
                    id: 'completed',
                    value: AGENDA_TASKS_COMPLETED_FLAG.incompleted,
                };
                filters.fini = {
                    id: 'fini',
                    value: formatDateToBackendUTC(new Date(null)),
                };
                filters.ffin = {
                    id: 'ffin',
                    value: formatDateToBackendUTC(endDate.toDate()),
                };
                break;
            case 'completed':
                filters.completed = {
                    id: 'completed',
                    value: AGENDA_TASKS_COMPLETED_FLAG.completed,
                };
                filters.fini = {
                    id: 'fini',
                    value: '',
                };
                filters.ffin = {
                    id: 'ffin',
                    value: '',
                };
                break;
            default:
                filters.completed = {
                    id: 'completed',
                    value: AGENDA_TASKS_COMPLETED_FLAG.incompleted,
                };
                filters.fini = {
                    id: 'fini',
                    value: '',
                };
                filters.ffin = {
                    id: 'ffin',
                    value: '',
                };
                break;
        }

        Context.domainManager.getEntityList(
            TASKS.entity,
            0,
            PAGINATION_TABLE_TASKS,
            filters,
            '',
            '',
            '',
            false,
            (result) => {
                setLoading(false);
                setList(result.map((item) => AgendaModel.standardToCrud(item)));
            },
            (error) => {
                console.error(error);
                setLoading(false);
                setError(true);
            },
        );
    }, [filter, idUser]);

    useEffect(() => {
        dispatch(EntityListActions.setUseLazyLoad(TASKS, true));
        return subscribe(`${ENTITY_MODAL_UPDATE}--${TASKS.entity}`, () => getList());
    }, [dispatch, getList]);

    useEffect(() => {
        Context.serverListManager.getList('fm_iana_time_zone').then((data) => {
            const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const userTimezone = data.reduce((obj, current) => {
                if (current.ianazonenames?.includes(browserTimezone)) obj = current;
                return obj;
            }, {});
            if (Object.keys(userTimezone).length) {
                setTimezone(userTimezone);
            }
        });
    }, []);

    useEffect(() => {
        if (!timezone) return;
        getList();
    }, [getList, timezone]);

    const onViewMore = useCallback(() => {
        logEvent({
            event: 'dashboard',
            submodule: TASKS.trueName,
            functionality: 'list',
        });
        window.open(getRoute(TASKS.route), '_blank');
    }, []);

    const onAdd = useCallback(() => {
        const defaultRange = getPreviousAndNextDate(60);
        const hini = defaultRange.previous;
        const fini = new Date(hini);

        dispatch(
            EntityModalActions.init({
                entity: TASKS,
                data: {
                    user: {
                        label: userName,
                        value: idUser,
                    },
                    fini,
                    hini,
                    idTimezone,
                    isCompletado: '0',
                    isTarea: '1',
                },
                labels: {
                    title: getLiteral('action_create_task'),
                    success: getLiteral('succes_entitycreatedsuccessfully'),
                    error: getLiteral('label_failed_create'),
                },
                hideDelete: true,
                crudTab: AGENDA_TASK,
            }),
        );

        logEvent({
            event: 'dashboard',
            submodule: TASKS.trueName,
            functionality: 'create',
        });
    }, [dispatch, idTimezone, idUser, userName]);

    const onClick = useCallback(
        (item) => {
            dispatch(
                EntityModalActions.init({
                    entity: TASKS,
                    id: item.Id,
                    labels: {
                        title: getLiteral('title_edit_task'),
                        success: getLiteral('succes_entityupdatedsuccessfully'),
                        error: getLiteral('error_generalerror'),
                        deleteModalTitle: getLiteral('title_delete_task'),
                        successDelete: getLiteral('succes_entitydeletedsuccessfully'),
                    },
                    hideDelete: !agendaPermission?.delete,
                    disableConfirm: !agendaPermission?.update,
                    crudTab: AGENDA_TASK,
                }),
            );

            logEvent({
                event: 'dashboard',
                submodule: TASKS.trueName,
                functionality: 'detailView',
            });
        },
        [agendaPermission?.delete, agendaPermission?.update, dispatch],
    );

    const onChangeFilter = useCallback((filter) => {
        setFilter(filter);
        logEvent({
            event: 'dashboard',
            submodule: TASKS.trueName,
            functionality: 'filter',
        });
    }, []);

    const onChangeTask = useCallback(
        (task) => {
            const taskChecked = checkeds.hasOwnProperty(task.Id)
                ? !checkeds?.[task.Id]
                : !getBackendBoolean(task.finalizada);

            setCheckeds((current) => {
                return {
                    ...current,
                    [task.Id]: taskChecked,
                };
            });

            Context.entityManager.getEntitiesManager(TASKS).setTaskCompleted(
                task.Id,
                taskChecked,
                () => {
                    if (taskChecked) {
                        successToast({ text: getLiteral('success_task_completed') });
                    }
                },
                (error) => {
                    console.error(error);
                    errorToast({ text: getLiteral('error_task_completed') });
                    setCheckeds((current) => {
                        return {
                            ...current,
                            [task.Id]: !taskChecked,
                        };
                    });
                },
            );
        },
        [checkeds],
    );

    const openCompany = useCallback(
        (id) => {
            openTab({
                entity: COMPANIES,
                id: id,
                hasCrudInDetail: true,
                toTab: true,
                avoidRedirects: true,
            });
        },
        [openTab],
    );

    const taskTypes = useMemo(() => {
        return [
            {
                label: getLiteral('label_today'),
                value: 'today',
            },
            {
                label: getLiteral('label_upcoming'),
                value: 'upcoming',
            },
            {
                label: getLiteral('label_overdue'),
                value: 'overdue',
            },
            {
                label: getLiteral('label_tasks_completed'),
                value: 'completed',
            },
        ];
    }, []);

    const emptyActions = useMemo(() => {
        let actions = [];
        if (agendaPermission?.create)
            actions.push(
                <Button type="secondary" size="small" onClick={onAdd}>
                    {getLiteral('action_create_task')}
                </Button>,
            );

        const nylasStatus = nylasConfig?.account?.status || 0;
        if (syncCalendarWithNylas && nylasStatus <= 0) {
            actions.push(
                <Button
                    type="secondary"
                    size="small"
                    onClick={() => {
                        logEvent({
                            event: 'dashboard',
                            submodule: TASKS.trueName,
                            functionality: 'syncEmailCal',
                        });
                        ensureRoute('/settings/nylas');
                    }}
                >
                    {getLiteral('action_sync_cal')}
                </Button>,
            );
        }

        return actions;
    }, [agendaPermission, onAdd, syncCalendarWithNylas, nylasConfig]);

    const rowList = useMemo(() => {
        if (!timezone) return null;
        return list?.map((i) => {
            const initialDate = i.fini || null;
            const dateMoment = getDateToTimezone({
                date: initialDate,
                timezone: timezone.idianazone,
                returnMoment: true,
            });

            const nowMoment = getDateToTimezone({
                timezone: timezone.idianazone,
                returnMoment: true,
            });

            const isOver = checkeds.hasOwnProperty(i.Id)
                ? checkeds?.[i.Id]
                : getBackendBoolean(i.finalizada);

            const isTaskOverdue = !isOver && nowMoment.toDate() > dateMoment.toDate();

            return (
                <DashboardWidgetRow
                    item={i}
                    onClick={(e) => onClick(i)}
                    leftColumn={
                        <Radio
                            onChange={(e) => {
                                e.stopPropagation();
                                onChangeTask(i);
                            }}
                            checked={isOver}
                        />
                    }
                    title={i.Asunto || '-'}
                    subtitle={
                        <div className="fm-task-dashboard-widget__row-subtitle">
                            {i.empresa && (
                                <Link
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        openCompany(i.IdCompany);
                                    }}
                                    isTruncated
                                    useTooltip
                                >
                                    {i.empresa}
                                </Link>
                            )}
                            {!i.empresa && <Text>-</Text>}
                            <Text
                                className={
                                    isTaskOverdue
                                        ? 'fm-task-dashboard-widget__row--overdue'
                                        : undefined
                                }
                            >
                                {getActivityDateFormat(dateMoment)}
                            </Text>
                        </div>
                    }
                />
            );
        });
    }, [checkeds, list, onChangeTask, onClick, openCompany, timezone]);

    return (
        <DashboardWidget
            title={getLiteral('title_tasks')}
            // Actions
            onViewMore={onViewMore}
            onAdd={agendaPermission?.create ? onAdd : undefined}
            addTooltip={getLiteral('action_create_task')}
            // Filter
            filterLabel={getLiteral('label_task_type')}
            filterOptions={taskTypes}
            onChangeFilter={onChangeFilter}
            // State
            isLoading={loading}
            isEmpty={list?.length === 0}
            isError={error}
            // Empty view
            emptyTitle={getLiteral('label_empty_screen_tasks')}
            emptySubtitle={getLiteral('label_empty_screen_tasks_desc')}
            emptyImage={EMPTY_SVG}
            emptyActions={emptyActions}
        >
            {rowList}
        </DashboardWidget>
    );
});

export default TaskDashboardWidget;
