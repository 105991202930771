import React, { memo, useCallback, useReducer, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import { Modal, toast, Text } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';

import './styles.scss';

const initialState = {
    id: null,
    errors: {},
    isOpen: false,
    isLoading: true,
};

function reducer(state, action) {
    switch (action.type) {
        case 'init':
            return {
                ...state,
                id: action.id,
                errors: {},
                isOpen: true,
                isLoading: false,
            };
        case 'close':
            return { ...state, isLoading: false, isOpen: false };
        case 'setErrors':
            return { ...state, isLoading: false, errors: action.errors };
        case 'setLoading':
            return {
                ...state,
                isLoading: action.isLoading,
            };
        default:
            throw new Error('No action provided');
    }
}

const DeleteModal = memo(
    ({ onRef, onDelete, onCancel, title, body, confirmText, overlayClassName }) => {
        const [state, dispatch] = useReducer(reducer, initialState);

        useEffect(() => {
            onRef &&
                onRef({
                    open(item) {
                        dispatch({
                            type: 'init',
                            id: item?.id,
                        });
                    },
                });
        }, [onRef]);

        const onInnerCancel = useCallback(() => {
            dispatch({ type: 'close' });
            onCancel?.();
        }, [onCancel]);

        const onInnerSave = useCallback(() => {
            dispatch({ type: 'setLoading', isLoading: true });
            onDelete(state.id)
                .then((item) => {
                    dispatch({ type: 'setErrors', errors: {} });
                    dispatch({ type: 'close' });
                    toast({
                        type: 'success',
                        text: getLiteral('succes_entitydeletedsuccessfully'),
                        useDefaultCloseButton: true,
                    });
                    return item;
                })
                .catch((e) => {
                    console.error(e);
                    toast({
                        type: 'error',
                        text: getLiteral('label_failed_delete'),
                        useDefaultCloseButton: true,
                    });
                    dispatch({ type: 'setLoading', isLoading: false });
                });
        }, [onDelete, state.id]);

        const overridesModal = useMemo(
            () => ({
                confirmButton: {
                    type: 'primary-error',
                },
            }),
            [],
        );

        return (
            <Modal
                className="fm-delete-modal"
                overlayClassName={classnames(overlayClassName, 'fm-delete-modal__overlay')}
                overrides={overridesModal}
                useCornerClose={false}
                isOpen={state.isOpen}
                onCancel={onInnerCancel}
                onConfirm={onInnerSave}
                title={title || getLiteral('helptext_confirm')}
                confirmText={confirmText || getLiteral('action_confirm_bn')}
                cancelText={getLiteral('action_cancel')}
                isConfirmLoading={state.isLoading}
                size="small"
            >
                <Text>{body || getLiteral('confirm_delete')}</Text>
            </Modal>
        );
    },
);

export default DeleteModal;
