import { memo } from 'react';
import { ReactFlowProvider } from '@xyflow/react';
import { AutomationBuilderCanvasProvider } from './AutomationBuilderCanvasContext';
import AutomationBuilderToolbar from './AutomationBuilderToolbar';
import AutomationBuilderCanvasSidePanel from './AutomationBuilderCanvasSidePanel';
import NodeLayouter from './components/NodesLayouter';
import AutomationBuilderCanvasFlow from './AutomationBuilderCanvasFlow';

import '@xyflow/react/dist/style.css';

const AutomationBuilderCanvas = memo(({ id, data, onChange }) => {
    return (
        <AutomationBuilderCanvasProvider
            defaultNode={!id ? data.scene : undefined}
            data={data}
            onChange={onChange}
        >
            <div className="fm-automation-builder__canvas">
                <div className="fm-automation-builder__canvas-inner">
                    <AutomationBuilderToolbar
                        enabled={data?.enabled || false}
                        onChange={onChange}
                    />

                    <ReactFlowProvider>
                        <AutomationBuilderCanvasFlow data={data} onChange={onChange} />
                        <NodeLayouter />
                    </ReactFlowProvider>
                </div>
                <AutomationBuilderCanvasSidePanel onChange={onChange} data={data} />
            </div>
        </AutomationBuilderCanvasProvider>
    );
});

export default AutomationBuilderCanvas;
