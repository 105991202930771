import { memo, useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { SectionForm, Input, Slider, DatePicker } from 'hoi-poi-ui';
import { useSelector } from 'react-redux';
import SelectHoi from 'components/SelectHoi';
import { errorToast } from 'utils/toast';
import { getLiteral } from 'utils/getLiteral';
import { FuzzyMap } from 'utils/fuzzy';
import { formatDate } from 'utils/dates';
import { getAutomationVariables } from 'services/AutomationsService';

import Select from 'containers/components/Fields/Select';
import SelectFuzzy from 'containers/components/Fields/SelectFuzzy';
import MultiSelect from 'containers/components/Fields/MultiSelect';
import MultiSelectFuzzy from 'containers/components/Fields/MultiSelectFuzzy';

const UpdateEntityRelatedAction = memo(({ onChange, form, data }) => {
    const lang = useSelector((state) => state.config?.userData?.langISOInterface);
    const variables = useRef({});
    const [entities, setEntities] = useState([]);
    const [fields, setFields] = useState([]);
    const boolOptions = useMemo(
        () => [
            {
                label: getLiteral('cfm_label_yes'),
                value: true,
            },
            {
                label: getLiteral('cfm_label_no'),
                value: false,
            },
        ],
        [],
    );

    useEffect(() => {
        if (!variables.current) return;
        getAutomationVariables(data?.scene)
            .then((result) => {
                setEntities(
                    result.entity.map((entity) => ({
                        label: getLiteral(entity.name),
                        options: entity.fields.map((field) => ({
                            label: getLiteral(field.literal),
                            value: field.value,
                            iconType: field?.entity,
                            original: field,
                        })),
                    })),
                );
                variables.current = result;
                let fields =
                    variables.current?.values?.fields[form.parameters?.entity?.original?.entity];
                setFields(
                    fields?.map((item) => {
                        return {
                            label: item.name,
                            options:
                                item.fields?.map?.((field) => ({
                                    ...field,
                                    label: getLiteral(field.literal),
                                    value: field.key,
                                    original: field,
                                })) || [],
                        };
                    }) || [],
                );
            })
            .catch((e) => {
                console.error(e);
                errorToast({ text: getLiteral('error_generalerror') });
            });
    }, [form.parameters?.entity?.original?.entity, data?.scene]);

    const changeEntity = useCallback(
        (value) => {
            onChange((current) => {
                const newObj = {
                    ...current,
                    parameters: {
                        entity: value,
                    },
                    fields: [],
                    values: {},
                };
                return newObj;
            });

            if (!value) return;

            let fields = variables.current.values.fields[value.original.entity];

            setFields(
                fields.map((item) => {
                    return {
                        label: item.name,
                        options:
                            item.fields.map?.((field) => ({
                                ...field,
                                label: getLiteral(field.literal),
                                value: field.key,
                                original: field,
                            })) || [],
                    };
                }),
            );
        },
        [onChange],
    );

    const changeFields = useCallback(
        (value) => {
            onChange((current) => {
                return {
                    ...current,
                    fields: value,
                };
            });
        },
        [onChange],
    );

    const changeValues = useCallback(
        (field) => (value) => {
            onChange((current) => {
                return {
                    ...current,
                    values: {
                        ...(current?.values || {}),
                        [field]: value,
                    },
                };
            });
        },
        [onChange],
    );

    const getFieldByType = useCallback(
        (field) => {
            let list, listProps, Component;
            switch (field.type) {
                case 'text':
                    return (
                        <Input
                            key={field.key}
                            onChange={changeValues(field.value)}
                            value={form?.values?.[field.value]}
                            placeholder={getLiteral('placeholder_text_field')}
                            isFullWidth
                        />
                    );
                case 'int':
                    return (
                        <Input
                            key={field.key}
                            onChange={changeValues(field.value)}
                            value={form?.values?.[field.value]}
                            type="integer"
                            placeholder={getLiteral('placeholder_numeric')}
                            isFullWidth
                        />
                    );
                case 'currency':
                case 'decimal':
                    return (
                        <Input
                            key={field.key}
                            onChange={changeValues(field.value)}
                            value={form?.values?.[field.value]}
                            type="decimal"
                            placeholder={getLiteral('placeholder_numeric')}
                            isFullWidth
                        />
                    );
                case 'percentage':
                    return (
                        <Slider
                            key={field.key}
                            onChange={changeValues(field.value)}
                            value={form?.values?.[field.value]}
                            isPercentage
                            isFullWidth
                        />
                    );
                case 'bit':
                    return (
                        <SelectHoi
                            key={field.key}
                            onChange={changeValues(field.value)}
                            value={form?.values?.[field.value]}
                            options={boolOptions}
                            usePlainValue
                            isFullWidth
                            useMenuPortal={false}
                        />
                    );
                case 'datetime':
                    return (
                        <DatePicker
                            key={field.key}
                            onChange={changeValues(field.value)}
                            value={form?.values?.[field.value]}
                            lang={lang}
                            calendarButtonLabel={getLiteral('label_today')}
                            placeholder={getLiteral('label_selectone')}
                            formatDate={(date) => formatDate(date, 'L')}
                            isFullWidth
                        />
                    );
                case 'list':
                    list = field.table?.table;
                    listProps = FuzzyMap[list?.toLowerCase()] || list || {};
                    Component = listProps?.list ? SelectFuzzy : Select;
                    return (
                        <Component
                            key={field.key}
                            onChange={changeValues(field.value)}
                            value={form?.values?.[field.value]}
                            cacheOptions={false}
                            list={field?.table?.table}
                            {...listProps}
                            useMenuPortal={false}
                            usePlainValue
                        />
                    );
                case 'multivalue':
                    list = field.table?.table;
                    listProps = FuzzyMap[list?.toLowerCase()] || list || {};
                    Component = listProps?.list ? MultiSelectFuzzy : MultiSelect;
                    return (
                        <Component
                            key={field.key}
                            onChange={changeValues(field.value)}
                            value={form?.values?.[field.value]}
                            list={field?.table?.table}
                            {...listProps}
                            useMenuPortal={false}
                            usePlainValue
                        />
                    );
                default:
                    return null;
            }
        },
        [boolOptions, changeValues, form?.values, lang],
    );

    const fieldsForm = useMemo(() => {
        // TODO Full types field form
        // TODO Default operator
        return form?.fields?.map((field) => (
            <SectionForm title={getLiteral(field.literal)} isExpandable>
                <SelectHoi
                    value={{
                        label: getLiteral('label_automation_operator_equal_to'),
                        value: 'equalTo',
                    }}
                    isReadOnly
                    isFullWidth
                />
                {getFieldByType(field)}
            </SectionForm>
        ));
    }, [form?.fields, getFieldByType]);

    const showFields = form?.parameters?.entity && fields?.length > 0;

    return (
        <>
            {form?.name && (
                <SectionForm title={getLiteral('label_action_entity')} isExpandable={false}>
                    <SelectHoi
                        placeholder={getLiteral('label_placeholder_entity')}
                        options={entities}
                        onChange={changeEntity}
                        value={form.parameters?.entity}
                        useMenuPortal={false}
                        isReadOnly={!form.name}
                        isFullWidth
                    />
                </SectionForm>
            )}
            {showFields && (
                <SectionForm title={getLiteral('label_fields')} isExpandable={false}>
                    <SelectHoi
                        placeholder={getLiteral('label_placeholder_field')}
                        options={fields}
                        onChange={changeFields}
                        value={form.fields}
                        useMenuPortal={false}
                        selectAllLabel={getLiteral('label_selectall')}
                        isMulti
                        isFullWidth
                    />
                    {fieldsForm}
                </SectionForm>
            )}
        </>
    );
});

UpdateEntityRelatedAction.getInitialData = (node) => {
    return {
        ...node,
        name: node?.parameters?.entity ? node.name : undefined,
        parameters: {
            ...node.parameters,
            entity: node?.parameters?.entity
                ? {
                      label: getLiteral(node.parameters.entity?.literal),
                      value: node.parameters.entity?.value,
                      iconType: node.parameters.entity?.entity,
                      original: node.parameters.entity,
                  }
                : undefined,
        },
        fields: node.parameters?.values?.map((value) => ({
            ...value?.field,
            label: getLiteral(value?.field?.literal),
            value: value?.field?.key,
            original: value?.field,
        })),
        values: node.parameters?.values?.reduce((obj, value) => {
            obj[value.field?.key] = value?.value?.value;
            return obj;
        }, {}),
    };
};

UpdateEntityRelatedAction.getSaveData = (form) => {
    let values = [];
    if (form.fields?.length > 0) {
        values = form.fields.map((field) => ({
            field: field.original,
            value: {
                type: 'field',
                value: form.values[field.value],
            },
        }));
    }
    return { entity: form.parameters.entity.original, values };
};

export default UpdateEntityRelatedAction;
