import React, { memo, useCallback, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon } from 'hoi-poi-ui';
import classnames from 'classnames';
import { EntityModalActions } from 'actions';
import AnimatedHeight from 'components/AnimatedHeight';
import { ChevronUp } from 'components/SvgIcons';
import {
    COMPANIES,
    OPPORTUNITIES,
    CONTACTS,
    ACTIVITIES,
    TASKS,
    AGENDA,
    SALESORDERS,
} from 'constants/Entities';
import { getActiveCrudName } from 'utils/crud';
import { getLiteral } from 'utils/getLiteral';
import { getError } from 'utils/Errors';
import { getFieldWorkFlow } from 'utils/fm';
import { getSmartFieldProps } from 'utils/fm/fields';
import { logEvent } from 'utils/tracking';
import MultipleFuzzySingle from 'components/Fields/MultipleFuzzySingle';
import MultipleFuzzySingleV2 from 'components/Fields/MultipleFuzzySingleV2';
import GeoLocationField from 'components/Fields/GeoLocationField';
import DateTimeGroup from 'components/Fields/DateTimeGroup';
import DateTimeGroupV2 from 'components/Fields/DateTimeGroupV2';
import colors from 'constants/colors';
import getRenderCustomField from './utils/getRenderCustomField';
import isTouched from './utils/isTouched';
import CrudField from './CrudField';
import MultipleCrudField from './MultipleCrudField';
import MultipleCrudFieldV2 from './MultipleCrudFieldV2';
import WarningChangeModal from './WarningChangeModal';

const LIST_WITH_CREATE_ACTION = ['empresas', 'expedientes', 'contactos'];

const LIST_TO_ENTITY = {
    empresas: COMPANIES,
    expedientes: OPPORTUNITIES,
    contactos: CONTACTS,
};

const mapStateToProps = (state) => {
    const activeCrud = getActiveCrudName(state);
    const isModal = (activeCrud && state.entityCrud?.[activeCrud]?.isModal) || false;
    const crudPermissions = state.config?.permission?.crud_permission || null;
    return {
        isModal,
        activeCrudName: activeCrud,
        crudPermissions,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        modalInit: bindActionCreators(EntityModalActions, dispatch).init,
    };
};

const EntityCrudTab = memo(
    ({
        data,
        changeField,
        errors,
        firstErrorField,
        isBulkAction,
        duplicates,
        dynamicHiddenMap,
        version,
        userInviteFieldActions,
        configMap,
        workFlow,
        idUserType,
        isNewEntity,
        entity,
        firstFieldToFocus,
        isModal,
        modalInit,
        tab,
        indexTab,
        postComponentHeader,
        innerRef,
        activeCrudName,
        crudPermissions,
        originalData,
    }) => {
        const openWarningModalRef = useRef();
        const [collapsed, setCollapsed] = useState(false);

        const onRefModal = useCallback(({ open }) => {
            openWarningModalRef.current = open;
        }, []);

        const innerChangeField = useCallback(
            (field) => {
                return (...props) => {
                    const warningChangeModal = field.inputAttrs?.warningChangeModal;
                    if (warningChangeModal && warningChangeModal?.canShow(data)) {
                        openWarningModalRef.current({
                            warningText: field.inputAttrs?.warningChangeModal?.warningText,
                            onConfirm: () => {
                                changeField(field.id)(...props);
                            },
                        });
                    } else {
                        changeField(field.id)(...props);
                    }
                };
            },
            [changeField, data],
        );

        const getFieldsActions = useCallback(
            ({ list, fieldId, type, parentFieldForContext, parentFieldForContext2 }) => {
                if (!list) return [];
                if (!isModal && list && LIST_WITH_CREATE_ACTION.includes(list)) {
                    const entityFromList = LIST_TO_ENTITY[list] || null;

                    if (!crudPermissions?.[entityFromList?.permission]?.create) {
                        return [];
                    }

                    // For those fields that have parentField, we need to be able to pick their
                    // values in order to populate their equivalent field in the context modal crud.
                    // For this reason we need to do the matching between the field of the initial crud,
                    // and the field of the context modal crud.To do it we use the parentFieldForContext
                    // and parentFieldForContext2 (to populate a second field).

                    // This object is made to allow do the matching between two equivalent fields
                    // of 2 different cruds.
                    // The companyTranslation object structure works in the following way:
                    // structureIs = {
                    //     [initialCrud]: {
                    //         [contextModalCrud]: {
                    //             [idOfParentFieldInInitialCrud]: fieldIdInContextModalCrud
                    //         }
                    //     }
                    // }

                    const companyTranslation = {
                        [AGENDA.entity]: {
                            [CONTACTS.entity]: {
                                [parentFieldForContext]: 'IdCompany',
                            },
                            [OPPORTUNITIES.entity]: {
                                [parentFieldForContext]: 'idEmpresa1',
                            },
                        },
                        [TASKS.entity]: {
                            [CONTACTS.entity]: {
                                [parentFieldForContext]: 'IdCompany',
                            },
                            [OPPORTUNITIES.entity]: {
                                [parentFieldForContext]: 'idEmpresa1',
                            },
                        },
                        [OPPORTUNITIES.entity]: {
                            [CONTACTS.entity]: {
                                [parentFieldForContext]: 'IdCompany',
                            },
                        },
                        [ACTIVITIES.entity]: {
                            [OPPORTUNITIES.entity]: {
                                [parentFieldForContext]: 'idEmpresa1',
                                [parentFieldForContext2]: 'idContact',
                            },
                            [CONTACTS.entity]: {
                                [parentFieldForContext]: 'IdCompany',
                            },
                        },
                        [SALESORDERS.entity]: {
                            [OPPORTUNITIES.entity]: {
                                [parentFieldForContext]: 'idEmpresa1',
                                [parentFieldForContext2]: 'idContact',
                            },
                            [CONTACTS.entity]: {
                                [parentFieldForContext]: 'IdCompany',
                            },
                        },
                    };

                    let prefilledData = {};

                    if (
                        parentFieldForContext &&
                        data?.[parentFieldForContext] &&
                        entity &&
                        entityFromList
                    ) {
                        const fieldName =
                            companyTranslation?.[entity.entity]?.[entityFromList.entity]?.[
                                parentFieldForContext
                            ] || '';
                        if (fieldName) prefilledData[fieldName] = data?.[parentFieldForContext];
                    }

                    if (
                        parentFieldForContext2 &&
                        data?.[parentFieldForContext2] &&
                        entity &&
                        entityFromList
                    ) {
                        const fieldName =
                            companyTranslation?.[entity.entity]?.[entityFromList.entity]?.[
                                parentFieldForContext2
                            ] || '';
                        if (fieldName) prefilledData[fieldName] = data?.[parentFieldForContext2];
                    }

                    const parentCrudInfo = {
                        id: activeCrudName,
                        fieldToChange: fieldId,
                        list,
                        type,
                    };

                    let defaultActionProps = {
                        icon: 'addPlus',
                    };

                    if (version === 2) {
                        defaultActionProps.iconType = 'plus';
                        delete defaultActionProps.icon;
                    }

                    switch (entityFromList) {
                        case COMPANIES:
                            return [
                                {
                                    ...defaultActionProps,
                                    label: getLiteral('page_title_accounts_create'),
                                    onClick: () => {
                                        logEvent({
                                            event: COMPANIES.trueName,
                                            submodule: 'crud',
                                            functionality: 'buttonCreateInContext',
                                        });
                                        modalInit({
                                            entity: COMPANIES,
                                            labels: {
                                                title: getLiteral('page_title_accounts_create'),
                                                success: getLiteral(
                                                    'succes_entitycreatedsuccessfully',
                                                ),
                                                error: getLiteral('label_failed_create'),
                                                middleButtonText:
                                                    getLiteral('action_save_and_detail'),
                                            },
                                            showDelete: false,
                                            parentCrudInfo,
                                        });
                                    },
                                },
                            ];
                        case CONTACTS:
                            return [
                                {
                                    ...defaultActionProps,
                                    label: getLiteral('page_title_contacts_create'),
                                    onClick: () => {
                                        logEvent({
                                            event: CONTACTS.trueName,
                                            submodule: 'crud',
                                            functionality: 'buttonCreateInContext',
                                        });
                                        modalInit({
                                            entity: CONTACTS,
                                            data: prefilledData,
                                            labels: {
                                                title: getLiteral('page_title_contacts_create'),
                                                success: getLiteral(
                                                    'succes_entitycreatedsuccessfully',
                                                ),
                                                error: getLiteral('label_failed_create'),
                                                middleButtonText:
                                                    getLiteral('action_save_and_detail'),
                                            },
                                            showDelete: false,
                                            parentCrudInfo,
                                        });
                                    },
                                },
                            ];
                        case OPPORTUNITIES:
                            return [
                                {
                                    ...defaultActionProps,
                                    label: getLiteral('page_title_opportunities_create'),
                                    onClick: () => {
                                        logEvent({
                                            event: OPPORTUNITIES.trueName,
                                            submodule: 'crud',
                                            functionality: 'buttonCreateInContext',
                                        });
                                        modalInit({
                                            entity: OPPORTUNITIES,
                                            data: prefilledData,
                                            labels: {
                                                title: getLiteral(
                                                    'page_title_opportunities_create',
                                                ),
                                                success: getLiteral(
                                                    'succes_entitycreatedsuccessfully',
                                                ),
                                                error: getLiteral('label_failed_create'),
                                                middleButtonText:
                                                    getLiteral('action_save_and_detail'),
                                            },
                                            showDelete: false,
                                            parentCrudInfo,
                                        });
                                    },
                                },
                            ];
                    }
                }

                return [];
            },
            [isModal, modalInit, activeCrudName, data, entity, crudPermissions, version],
        );

        const renderMultipleFields = useCallback(
            (field, parentValue) => {
                let newFirstErrorField = firstErrorField;
                if (newFirstErrorField) newFirstErrorField = newFirstErrorField.toLowerCase();
                let fieldToRender = null;
                let parentField = null;

                //Sometimes backend expects a special parentField
                if (field.inputAttrs) {
                    parentField =
                        field.inputAttrs.parentFieldForBackend || field.inputAttrs.parentField;
                }

                let menuProps = field.hasInviteUsers
                    ? { actions: userInviteFieldActions }
                    : undefined;

                switch (field.type) {
                    case 'multipleFuzzySearchSingle':
                        const multipleFuzzySearchSingleProps = {
                            key: field.id,
                            fieldId: field.id,
                            label: field.label,
                            inputs: field.inputs,
                            labelMode: field.labelMode || 'horizontal',
                            data,
                            onChange: changeField,
                            getError: getError,
                            errors,
                            firstErrorField: newFirstErrorField,
                            isBulkAction,
                            mandatory: field.mandatory,
                            hidden: field.hidden,
                            readOnly: field.readOnly,
                            ...field.inputAttrs,
                            parentField,
                            parentValue: parentValue ? parentValue.value || parentValue.Id : null,
                            menuProps,
                            fakeError: field.fakeError,
                            getFieldsActions,
                        };

                        if (version === 2) {
                            fieldToRender = (
                                <MultipleFuzzySingleV2 {...multipleFuzzySearchSingleProps} />
                            );
                        } else {
                            fieldToRender = (
                                <MultipleFuzzySingle {...multipleFuzzySearchSingleProps} />
                            );
                        }
                        break;
                    case 'multipleField':
                        const multipleFieldProps = {
                            key: field.id,
                            fieldId: field.id,
                            label: field.label,
                            inputs: field.inputs,
                            displayBlock: field.displayBlock,
                            labelMode: field.labelMode || 'horizontal',
                            data: data,
                            onChange: changeField,
                            getError: getError,
                            errors: errors,
                            firstErrorField: newFirstErrorField,
                            isBulkAction: isBulkAction,
                            duplicates: duplicates,
                            dynamicHiddenMap: dynamicHiddenMap,
                        };
                        if (version === 2) {
                            fieldToRender = <MultipleCrudFieldV2 {...multipleFieldProps} />;
                        } else {
                            fieldToRender = <MultipleCrudField {...multipleFieldProps} />;
                        }

                        break;
                    case 'geolocation':
                        fieldToRender = (
                            <GeoLocationField
                                key={field.id}
                                label={field.label}
                                mandatory={field.mandatory}
                                readOnly={field.readOnly}
                                hidden={field.hidden}
                                labelMode={'horizontal'}
                                data={data}
                                onChange={changeField}
                                {...field.inputAttrs}
                            />
                        );
                        break;
                    case 'dateTimeGroup': {
                        const dateTimeGroupProps = {
                            key: field.id,
                            label: field.label,
                            field,
                            mandatory: field.mandatory,
                            readOnly: field.readOnly,
                            hidden: field.hidden,
                            data: data,
                            onChange: changeField,
                            fieldId: field.id,
                            errors,
                            description: field.description,
                            ...field.inputAttrs,
                        };

                        if (version === 2) {
                            fieldToRender = <DateTimeGroupV2 {...dateTimeGroupProps} />;
                        } else {
                            fieldToRender = <DateTimeGroup {...dateTimeGroupProps} />;
                        }
                    }
                }
                return fieldToRender;
            },
            [
                changeField,
                data,
                duplicates,
                dynamicHiddenMap,
                errors,
                firstErrorField,
                isBulkAction,
                userInviteFieldActions,
                version,
                getFieldsActions,
            ],
        );

        const renderTabFields = useCallback(
            (fields, columns) => {
                let classes = classnames('fm-crud-form-tab-fields', {
                    [`fm-crud-form-tab-fields__${columns}-columns`]: columns && columns > 0,
                });

                const finalFields =
                    fields?.filter(
                        (field) =>
                            !(
                                field.hidden ||
                                (dynamicHiddenMap && dynamicHiddenMap[field.fieldConfiguration])
                            ),
                    ) || [];

                const getFieldComponent = (field) => {
                    let inputAttrs = field.inputAttrs;

                    let newFirstErrorField = firstErrorField;
                    if (newFirstErrorField) newFirstErrorField = newFirstErrorField.toLowerCase();

                    // Get parentValue dependency
                    let parentValue = null;

                    if (inputAttrs && inputAttrs.parentField) {
                        const id = configMap[inputAttrs.parentField];
                        parentValue = data[id];
                    }

                    let fieldWorkFlow = getFieldWorkFlow(
                        field.fieldConfiguration,
                        workFlow,
                        idUserType,
                    );
                    const autoFocus = firstFieldToFocus === field.id;

                    const mandatory = isBulkAction ? false : field.mandatory;

                    if (inputAttrs && inputAttrs.beforeRenderCrudField) {
                        field = inputAttrs.beforeRenderCrudField(field);
                    }

                    const dependenceFilter =
                        field && field.dependenceFilter && field.dependenceFilter(field);

                    if (
                        !field.hasOwnProperty('inputs') &&
                        !field.isCustom &&
                        !field.isMultipleField
                    ) {
                        if (parentValue && (parentValue.value || parentValue.Id)) {
                            parentValue = parentValue.value || parentValue.Id;
                        }

                        let menuProps = field.hasInviteUsers
                            ? { actions: userInviteFieldActions }
                            : undefined;

                        let actions = [];

                        if (inputAttrs?.list) {
                            const newActions = getFieldsActions({
                                list: inputAttrs.list,
                                fieldId: field.id,
                                type: field.type,
                                parentFieldForContext: inputAttrs.parentFieldForContext || '',
                                parentFieldForContext2: inputAttrs.parentFieldForContext2 || '',
                            });
                            if (!menuProps) menuProps = {};
                            menuProps.actions = [
                                ...(menuProps?.actions || []),
                                ...(newActions || []),
                            ];

                            // Actions are for crudV2... and for value list actions from crudV1
                            if (version === 2) {
                                actions = menuProps.actions;
                            } else {
                                actions = inputAttrs?.actions || [];
                            }
                        }

                        const baseProps = {
                            key: field.id,
                            fieldId: field.id,
                            type: field.type,
                            label: field.label,
                            labelMode: field.labelMode || 'horizontal',
                            hint: field.hint,
                            description: field.description,
                            mandatory: mandatory,
                            maxLength: field.dataLength,
                            limitInput: field.limitInput,
                            readOnly: field.readOnly,
                            hidden: field.hidden,
                            inputAttrs: inputAttrs,
                            value: data[field.id],
                            parentValue: parentValue,
                            dependenceFilter: dependenceFilter,
                            onChange: innerChangeField(field),
                            error: getError(errors[field.id], [field.dataLength]),
                            firstErrorField:
                                newFirstErrorField && field.id.toLowerCase() === newFirstErrorField,
                            className: field.className,
                            isBulkAction: isBulkAction,
                            spaced: field.spaced,
                            workFlow: fieldWorkFlow,
                            isNewEntity: isNewEntity,
                            duplicates: duplicates,
                            includeTime: field.includeTime,
                            component: field.component,
                            version: version,
                            autoFocus: autoFocus,
                            menuProps: menuProps,
                            actions: actions,
                        };

                        const smartFieldOverrides = field.smartField
                            ? getSmartFieldProps({
                                  configMap,
                                  data,
                                  field,
                                  isNewEntity,
                              })
                            : {};

                        const { defaultValue, ...otherOverrides } = smartFieldOverrides;

                        const finalValue =
                            !isTouched({ data, originalData, field }) && !!defaultValue
                                ? defaultValue
                                : data[field.id];

                        const crudFieldProps = {
                            ...baseProps,
                            value: finalValue,
                            ...otherOverrides,
                        };

                        return <CrudField {...crudFieldProps} />;
                    } else if (field.isCustom) {
                        const getCustomField = getRenderCustomField(entity) || null;
                        if (!getCustomField) return null;
                        return getCustomField({
                            field,
                            data,
                            changeField,
                            errors,
                            getError,
                            firstErrorField: newFirstErrorField,
                            isBulkAction,
                            version,
                        });
                    } else {
                        return renderMultipleFields(field, parentValue);
                    }
                };

                if (columns) {
                    return (
                        <div className={classes}>
                            {Array.from(Array(columns)).map((_, column) => (
                                <div className="fm-crud-form-tab-fields__column">
                                    {finalFields
                                        .filter((field) => field.column === column + 1)
                                        .map(getFieldComponent)}
                                </div>
                            ))}
                        </div>
                    );
                } else {
                    return <div className={classes}>{finalFields.map(getFieldComponent)}</div>;
                }
            },
            [
                dynamicHiddenMap,
                firstErrorField,
                workFlow,
                idUserType,
                firstFieldToFocus,
                isBulkAction,
                configMap,
                data,
                userInviteFieldActions,
                innerChangeField,
                errors,
                isNewEntity,
                duplicates,
                version,
                originalData,
                getFieldsActions,
                entity,
                changeField,
                renderMultipleFields,
            ],
        );

        const chevronClick = useCallback(() => {
            setCollapsed(!collapsed);
        }, [collapsed]);

        let collapsedClass = collapsed ? 'chevron-collapsed' : '';

        if (!tab?.show || !tab?.fields || tab?.fields.length === 0) return null;
        let fieldsToPrint = tab.fields.filter((field) => {
            if (field.hidden) return false;
            if (dynamicHiddenMap && dynamicHiddenMap[field.fieldConfiguration]) return false;
            return true;
        });
        if (fieldsToPrint.length === 0) return null;

        if (!tab.title) {
            return (
                <div className="fm-crud-form-tab" ref={innerRef}>
                    {renderTabFields(tab.fields, tab.columns)}
                    <WarningChangeModal onRef={onRefModal} />
                </div>
            );
        } else {
            return (
                <div className="fm-crud-form-tab" ref={innerRef}>
                    <div className="fm-crud-form-tab-title">
                        <div className="tab-title-text">{tab.title}</div>
                        {!postComponentHeader && (
                            <div
                                className={`tab-title-chevron ${collapsedClass}`}
                                onClick={chevronClick}
                            >
                                {version !== 2 && <ChevronUp />}
                                {version === 2 && (
                                    <Icon
                                        name="expandLess"
                                        size="large"
                                        color={colors.utility.textSecondary}
                                    />
                                )}
                            </div>
                        )}
                        {postComponentHeader}
                    </div>
                    <AnimatedHeight open={!collapsed}>
                        {renderTabFields(tab.fields, tab.columns)}
                    </AnimatedHeight>
                    <WarningChangeModal onRef={onRefModal} />
                </div>
            );
        }
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(EntityCrudTab);
