const SyncPicError = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={168} height={144} fill="none" {...props}>
        <mask
            id="a"
            width={136}
            height={80}
            x={16}
            y={32}
            maskUnits="userSpaceOnUse"
            style={{
                maskType: 'luminance',
            }}
        >
            <path fill="#fff" d="M151.482 32.753H16.518v78.494h134.964V32.753Z" />
        </mask>
        <g mask="url(#a)">
            <path
                fill="#C6C6C6"
                d="M56.44 53.856c-.99 0-1.985-.35-2.778-1.062L44.97 45.01a4.11 4.11 0 0 1-.305-5.829 4.17 4.17 0 0 1 5.864-.303l8.693 7.785a4.11 4.11 0 0 1 .304 5.828 4.152 4.152 0 0 1-3.085 1.365Z"
            />
            <path
                fill="#00D639"
                d="m90.652 73.673-2.553-1.6a.725.725 0 0 1-.34-.64l.118-2.999a.722.722 0 0 1 .388-.61l2.672-1.398c.23-.12.507-.11.728.028l2.553 1.6c.107.068.191.16.25.266a5.986 5.986 0 0 0-.688-2.694c-1.517-2.888-5.132-4.059-8.069-2.612l-.047.024c-.594.3-1.3.284-1.871-.056L62.197 50.128a1.84 1.84 0 0 1-.898-1.522 5.964 5.964 0 0 0-.792-2.77c-1.554-2.72-4.999-3.808-7.847-2.478-3.151 1.473-4.399 5.241-2.794 8.27 1.56 2.947 5.23 4.078 8.194 2.527.003-.001.006-.002.009-.005a1.65 1.65 0 0 1 1.611.032L81.282 67.04a2.158 2.158 0 0 1 1.062 1.722c.05.902.307 1.81.791 2.652 1.574 2.739 5.06 3.81 7.914 2.432l.147-.075a.73.73 0 0 1-.544-.095v-.003Zm-33.986-21.51-3.017-.116a.728.728 0 0 1-.615-.386l-1.406-2.656a.72.72 0 0 1 .028-.723l1.61-2.538a.731.731 0 0 1 .644-.337l3.017.117c.26.01.494.157.615.385l1.406 2.656a.719.719 0 0 1-.028.723l-1.61 2.538a.732.732 0 0 1-.644.337Z"
            />
            <path
                fill="#303030"
                d="M41.75 111.247c-2.294 0-4.153-1.848-4.153-4.126V74.627L24.546 108.3c-.825 2.126-3.228 3.186-5.367 2.368-2.14-.82-3.207-3.208-2.383-5.334L35.313 57.56c.962-2.481 3.478-3.874 6.12-3.386 2.632.486 4.47 2.676 4.47 5.326v47.621c0 2.279-1.86 4.126-4.152 4.126h-.002Z"
            />
            <path
                fill="#303030"
                d="M25.663 108.524h-4.979a.795.795 0 0 0-.797.792c0 .438.357.793.797.793h4.98c.44 0 .797-.355.797-.793a.795.795 0 0 0-.798-.792ZM46.847 108.878h-5.014a.795.795 0 0 0-.798.792c0 .438.357.793.798.793h5.014c.44 0 .798-.355.798-.793a.795.795 0 0 0-.798-.792Z"
            />
            <path
                fill="#C6C6C6"
                d="M36.553 54.253a7.747 7.747 0 0 1 .018-6.952l2.816-5.506c1.441-2.819 4.73-4.555 7.888-4.205 4.32.48 6.774 5.127 5.765 9.1l-1.72 6.776c-1.093 4.304-5.343 6.119-9.441 4.81-2.162-.691-4.291-1.973-5.326-4.023Z"
            />
            <path
                fill="#E84D4F"
                d="m64.616 58.915-.172-.001a1.774 1.774 0 0 1-1.736-1.812 1.775 1.775 0 0 1 1.82-1.725c1.191.026 2.65-.22 3.08-.507a1.782 1.782 0 0 1 2.09-1.144 1.77 1.77 0 0 1 1.325 2.127c-.442 1.872-2.413 2.59-3.988 2.863-1.037.179-2.028.199-2.418.199h-.001Z"
            />
            <path
                fill="#C6C6C6"
                d="M63.974 61.697a4.18 4.18 0 0 1-.789-.076l-8.569-1.637a4.149 4.149 0 0 1-2.9-2.15l-6.591-12.61a4.114 4.114 0 0 1 1.77-5.565c2.035-1.05 4.541-.263 5.599 1.76l5.665 10.838 6.595 1.26c2.252.43 3.726 2.594 3.293 4.832-.382 1.975-2.122 3.348-4.073 3.348Z"
            />
            <path
                fill="#474747"
                d="m122.604 86.799-.013-.003a1.79 1.79 0 0 1-1.465-1.615 16.127 16.127 0 0 0-.891-4.089 1.776 1.776 0 0 1 .66-2.069l.007-.004a1.767 1.767 0 0 0 .45-2.472l-1.328-1.902a1.792 1.792 0 0 0-2.487-.447l-.007.004c-.668.46-1.56.41-2.181-.11a16.324 16.324 0 0 0-3.542-2.265 1.781 1.781 0 0 1-1.009-1.93v-.006a1.776 1.776 0 0 0-1.439-2.065l-2.293-.413a1.788 1.788 0 0 0-2.078 1.432v.007a1.795 1.795 0 0 1-1.627 1.455c-1.39.117-2.774.41-4.118.887a1.8 1.8 0 0 1-2.081-.656 1.793 1.793 0 0 0-2.488-.449l-1.914 1.32a1.768 1.768 0 0 0-.45 2.471 1.775 1.775 0 0 1-.111 2.17 16.211 16.211 0 0 0-2.28 3.524 1.8 1.8 0 0 1-1.941 1.004h-.001a1.788 1.788 0 0 0-2.078 1.431l-.415 2.279a1.778 1.778 0 0 0 1.44 2.065h.004c.8.145 1.395.81 1.464 1.616.118 1.382.413 2.758.894 4.094.274.76.008 1.609-.66 2.07a1.768 1.768 0 0 0-.45 2.472l1.328 1.902a1.793 1.793 0 0 0 2.487.447l.004-.002c.668-.46 1.56-.41 2.18.11a16.342 16.342 0 0 0 3.546 2.265 1.782 1.782 0 0 1 1.01 1.929v.005a1.777 1.777 0 0 0 1.44 2.065l2.292.413a1.788 1.788 0 0 0 2.078-1.432v-.009a1.797 1.797 0 0 1 1.627-1.456 16.35 16.35 0 0 0 4.115-.888 1.8 1.8 0 0 1 2.083.656l.003.006a1.793 1.793 0 0 0 2.487.447l1.914-1.32a1.767 1.767 0 0 0 .45-2.472l-.006-.01a1.773 1.773 0 0 1 .112-2.167 16.278 16.278 0 0 0 2.276-3.519 1.798 1.798 0 0 1 1.941-1.004l.011.003a1.786 1.786 0 0 0 2.077-1.432l.416-2.278a1.779 1.779 0 0 0-1.441-2.065h-.002Zm-17.073 8.984c-5.416 0-9.805-4.362-9.805-9.745 0-5.382 4.389-9.745 9.805-9.745 5.416 0 9.805 4.363 9.805 9.745 0 5.383-4.389 9.745-9.805 9.745Z"
            />
            <path
                fill="#474747"
                d="M105.53 76.295c-5.416 0-9.805 4.362-9.805 9.744 0 5.383 4.389 9.745 9.805 9.745 5.416 0 9.805-4.362 9.805-9.745 0-5.382-4.389-9.744-9.805-9.744Zm.677 13.824a3.855 3.855 0 0 1-5.01-2.108 3.815 3.815 0 0 1 2.121-4.979 3.854 3.854 0 0 1 5.01 2.108 3.815 3.815 0 0 1-2.121 4.979Z"
            />
            <path
                fill="#303030"
                d="m134.873 50.18-.269-1.353a1.065 1.065 0 0 0-1.251-.834h-.005a1.068 1.068 0 0 1-1.165-.576 9.716 9.716 0 0 0-1.392-2.076 1.057 1.057 0 0 1-.089-1.293l.002-.003a1.055 1.055 0 0 0-.292-1.47l-1.153-.768a1.07 1.07 0 0 0-1.479.29l-.002.003a1.073 1.073 0 0 1-1.237.41 9.775 9.775 0 0 0-2.462-.492 1.068 1.068 0 0 1-.98-.852 1.066 1.066 0 0 0-1.252-.835l-1.362.267a1.06 1.06 0 0 0-.839 1.244c.094.473-.146.946-.582 1.158a9.754 9.754 0 0 0-2.091 1.385 1.074 1.074 0 0 1-1.3.088 1.07 1.07 0 0 0-1.478.289l-.774 1.146a1.055 1.055 0 0 0 .292 1.47h.001c.403.27.568.773.412 1.23a9.631 9.631 0 0 0-.494 2.448c-.033.48-.381.88-.857.974-.576.113-.952.67-.839 1.244l.269 1.354c.113.573.674.947 1.252.834h.002a1.07 1.07 0 0 1 1.164.577 9.653 9.653 0 0 0 1.393 2.076c.319.364.359.893.089 1.293v.002a1.053 1.053 0 0 0 .29 1.47l1.153.769a1.07 1.07 0 0 0 1.478-.29l.004-.005a1.073 1.073 0 0 1 1.237-.41 9.767 9.767 0 0 0 2.461.491c.483.033.886.379.98.852v.003c.115.573.675.947 1.252.834l1.362-.267a1.06 1.06 0 0 0 .839-1.244v-.006a1.058 1.058 0 0 1 .579-1.157 9.704 9.704 0 0 0 2.087-1.383 1.075 1.075 0 0 1 1.301-.088l.006.003a1.069 1.069 0 0 0 1.478-.29l.774-1.146a1.056 1.056 0 0 0-.292-1.47l-.006-.004a1.059 1.059 0 0 1-.412-1.23 9.605 9.605 0 0 0 .494-2.444c.033-.48.382-.88.857-.974h.004a1.06 1.06 0 0 0 .839-1.244h.003Zm-11.468 9.174c-4.237 0-7.673-3.414-7.673-7.626 0-4.211 3.436-7.625 7.673-7.625s7.673 3.414 7.673 7.625c0 4.212-3.436 7.626-7.673 7.626Z"
            />
            <path
                fill="#1B1B1B"
                d="m150.554 77.272-.01-.003a1.385 1.385 0 0 1-.923-1.414c.08-1.071.021-2.16-.182-3.239a1.38 1.38 0 0 1 .758-1.505l.006-.003c.689-.33.979-1.153.648-1.839l-.785-1.618a1.391 1.391 0 0 0-1.85-.644l-.006.004a1.397 1.397 0 0 1-1.656-.35 12.653 12.653 0 0 0-2.435-2.16 1.378 1.378 0 0 1-.536-1.6l.002-.006a1.375 1.375 0 0 0-.851-1.755l-1.705-.593a1.388 1.388 0 0 0-1.766.846v.004c-.21.592-.795.964-1.425.918a12.766 12.766 0 0 0-3.262.18 1.394 1.394 0 0 1-1.514-.753 1.39 1.39 0 0 0-1.85-.645l-1.628.78a1.375 1.375 0 0 0-.648 1.838 1.378 1.378 0 0 1-.351 1.647 12.617 12.617 0 0 0-2.178 2.424 1.4 1.4 0 0 1-1.609.534 1.388 1.388 0 0 0-1.766.844l-.596 1.695a1.375 1.375 0 0 0 .851 1.755h.002c.596.208.969.789.923 1.414a12.488 12.488 0 0 0 .183 3.244 1.38 1.38 0 0 1-.759 1.505 1.374 1.374 0 0 0-.647 1.839l.784 1.618a1.39 1.39 0 0 0 1.85.644h.002a1.396 1.396 0 0 1 1.657.346 12.661 12.661 0 0 0 2.437 2.162c.522.354.747 1.007.538 1.599v.004a1.374 1.374 0 0 0 .849 1.754l1.705.594a1.388 1.388 0 0 0 1.766-.846l.003-.007a1.391 1.391 0 0 1 1.421-.918c1.079.078 2.174.02 3.26-.182a1.393 1.393 0 0 1 1.514.753l.002.005a1.391 1.391 0 0 0 1.85.644l1.628-.78c.69-.33.979-1.153.648-1.838l-.003-.007a1.378 1.378 0 0 1 .35-1.647 12.618 12.618 0 0 0 2.174-2.42 1.4 1.4 0 0 1 1.609-.534l.008.003a1.388 1.388 0 0 0 1.766-.846l.597-1.695a1.376 1.376 0 0 0-.851-1.754l.001.004Zm-11.814 4.242c-3.651 1-7.425-1.133-8.43-4.762-1.005-3.628 1.14-7.379 4.792-8.378 3.651-.999 7.424 1.134 8.43 4.762 1.004 3.63-1.141 7.38-4.792 8.378Z"
            />
            <path
                fill="#454545"
                d="m49.249 34.2-1.124 1.7 1.092.713a2.666 2.666 0 0 1 .03-2.413h.002ZM51.644 38.12a1.92 1.92 0 0 1-.122-.004l1.092.714 1.123-1.698a2.698 2.698 0 0 1-2.093.988Z"
            />
            <path
                fill="#454545"
                d="M51.644 32.753a2.7 2.7 0 0 0-2.396 1.447l.002-.004 4.49 2.931-.002.005a2.66 2.66 0 0 0 .608-1.695 2.692 2.692 0 0 0-2.701-2.684h-.001Z"
            />
            <path
                fill="#454545"
                d="m53.737 37.132.003-.005-4.49-2.93-.003.003a2.662 2.662 0 0 0-.03 2.413l2.306 1.505a2.701 2.701 0 0 0 2.216-.985l-.002-.001Z"
            />
            <path
                fill="#E84D4F"
                d="M52.781 36.624c.61-.564.642-1.513.074-2.118a1.515 1.515 0 0 0-2.131-.074 1.492 1.492 0 0 0-.074 2.119 1.515 1.515 0 0 0 2.131.073Z"
            />
            <path
                fill="#454545"
                d="M49.88 34.607c1.374 1.008 3.288-.076 3.288-.076l-1.635-1.203-1.654 1.279h.001Z"
            />
            <path
                fill="#454545"
                d="M52.824 34.014c-.346 1.099.78 2.079.78 2.079l.412-1.31-1.192-.77Z"
            />
        </g>
    </svg>
);

export default SyncPicError;
