import React from 'react';
import colors from 'constants/colors';

const Trash = ({ color = colors.actionMinor[500] }) => (
    <svg viewBox={`0 0 18 18`} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.6 1.433C5.638.633 6.31 0 7.128 0h3.501c.819 0 1.491.633 1.527 1.433h3.605a.956.956 0 0 1 0 1.912h-.798l-.973 13.209c-.096.817-.711 1.445-1.525 1.446H5.46c-.82-.001-1.421-.602-1.526-1.467L2.963 3.345h-.507c-.264 0-.956-.428-.956-.956s.428-.956.956-.956H5.6zm5.597 0a.564.564 0 0 0-.569-.477h-3.5a.564.564 0 0 0-.569.477h4.638zM5 5.025l.5 10 .999-.05-.5-10-.999.05zm7-.05l-.5 10 .999.05.5-10-.999-.05zm-3.455-.238V14.98h1V4.737h-1z"
            fill={color || '#335B70'}
            fillRule="evenodd"
        />
    </svg>
);

export default Trash;
