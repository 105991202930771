import { createTheme } from '@material-ui/core/styles'; // v1.x
import colors from 'constants/colors';
import { getVar } from 'utils/color';

export default createTheme({
    overrides: {
        MuiPickersCalendarHeader: {
            iconButton: {
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            },
        },
    },
    props: {
        MuiButtonBase: {
            disableRipple: true,
        },
    },
    palette: {
        primary: {
            light: getVar(colors.actionMinor[500]),
            main: getVar(colors.actionMinor[500]),
            dark: getVar(colors.actionMinor[500]),
        },
    },
    typography: {
        useNextVariants: true,
        fontSize: 13,
        fontFamily: '"Sage Text", sans-serif',
    },
});
