import React, { Fragment, useMemo } from 'react';
import ContentLoader from 'react-content-loader';

import './styles.scss';

const CalendarPlaceholder = ({ width = 512, height = 120, theme, ...props }) => {
    const rowHeight = 50;
    const numRows = Math.floor(height / rowHeight);
    const titleWidth = width * 0.23;
    const borderWidth = width * 0.94;
    const nameWidth = width * 0.225;
    const surnameWidth = width * 0.13;
    const dateWidth = width * 0.11;

    const circleRadius = width <= 512 ? width * 0.0265 : 14;
    const circlePosition = width * 0.0265;
    const namePosition = width * 0.071;
    const datePosition = width * 0.83;

    const renderRows = useMemo(() => {
        let rows = [];
        let baseTextY = 38;
        let baseCircleY = 50;

        for (let i = 0; i < numRows; i++) {
            rows.push(
                <Fragment key={i}>
                    <circle cx={circlePosition} cy={baseCircleY} r={circleRadius} opacity="0.2" />
                    <rect
                        x={namePosition}
                        y={baseTextY}
                        rx="3"
                        ry="3"
                        width={nameWidth}
                        height="8"
                    />
                    <rect
                        x={namePosition}
                        y={baseTextY + 15}
                        rx="3"
                        ry="3"
                        width={surnameWidth}
                        height="8"
                    />
                    <rect
                        x={datePosition}
                        y={baseTextY + 15}
                        rx="3"
                        ry="3"
                        width={dateWidth}
                        height="8"
                    />
                </Fragment>,
            );
            baseCircleY = baseCircleY + rowHeight;
            baseTextY = baseTextY + rowHeight;
        }
        return rows;
    }, [
        circlePosition,
        circleRadius,
        datePosition,
        dateWidth,
        namePosition,
        nameWidth,
        numRows,
        surnameWidth,
    ]);

    return (
        <div className="fm-calendar__placeholder">
            <ContentLoader
                height={height}
                width={width}
                speed={2}
                backgroundColor={theme.colors.grey[50]}
                foregroundColor={theme.colors.grey[100]}
                {...props}
            >
                <rect x="0" y="0" rx="3" ry="3" width={titleWidth} height="8" />
                <rect x="0" y="22" rx="3" ry="3" width={borderWidth} height="1" />
                {renderRows}
            </ContentLoader>
        </div>
    );
};

export default CalendarPlaceholder;
