import { memo, useCallback, useMemo } from 'react';
import { SectionForm, Link } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import dot from 'utils/dot';

import AutomationBuilderConditionLogicSelector from './AutomationBuilderConditionLogicSelector';
import AutomationBuilderCondition from './AutomationBuilderCondition';

const AutomationBuilderConditionGroup = memo(
    ({ index, conditionGroup, onChange, onRemove, fields, operators }) => {
        const onAddCondition = useCallback(() => {
            const boolLogic = Object.keys(conditionGroup)?.[0];
            const newConditionGroup = {
                [boolLogic]: [...conditionGroup[boolLogic], {}],
            };
            onChange(newConditionGroup);
        }, [conditionGroup, onChange]);

        const onRemoveCondition = useCallback(
            (index) => {
                const boolLogic = Object.keys(conditionGroup)?.[0];
                const newConditionGroup = {
                    [boolLogic]: dot.delete(conditionGroup[boolLogic], index),
                };
                onChange(newConditionGroup);
            },
            [conditionGroup, onChange],
        );

        const onChangeBool = useCallback(
            (value) => {
                const boolLogic = Object.keys(conditionGroup)?.[0];
                onChange({ [value]: conditionGroup[boolLogic] });
            },
            [conditionGroup, onChange],
        );

        const onInnerChange = useCallback(
            (idx, value) => {
                const boolLogic = Object.keys(conditionGroup)?.[0];
                onChange({
                    [boolLogic]: dot.set(conditionGroup[boolLogic], idx, value),
                });
            },
            [conditionGroup, onChange],
        );

        const conditions = useMemo(() => {
            const boolLogic = Object.keys(conditionGroup)?.[0];
            const checkLast = (idx) => idx < conditionGroup[boolLogic].length - 1;
            return conditionGroup[boolLogic].map((condition, idx) => (
                <>
                    <AutomationBuilderCondition
                        key={idx}
                        condition={condition}
                        onChange={(value) => onInnerChange(idx, value)}
                        onRemove={() => onRemoveCondition(idx)}
                        fields={fields}
                        operators={operators}
                    />
                    {checkLast(idx) && (
                        <AutomationBuilderConditionLogicSelector
                            onChange={onChangeBool}
                            value={boolLogic}
                        />
                    )}
                </>
            ));
        }, [conditionGroup, fields, onChangeBool, onInnerChange, onRemoveCondition, operators]);

        return (
            <div className="fm-automation-builder__condition-group">
                <SectionForm
                    title={`${getLiteral('label_conditions_group')} ${index + 1}`}
                    isExpandable={false}
                    onRemove={onRemove}
                >
                    {conditions}
                    <div className="fm-automation-builder__add-condition-group">
                        <Link
                            className="fm-automation-builder__add-condition-group"
                            variation="primary"
                            onClick={onAddCondition}
                        >
                            + {getLiteral('cfm_action_add_condition')}
                        </Link>
                    </div>
                </SectionForm>
            </div>
        );
    },
);

export default AutomationBuilderConditionGroup;
