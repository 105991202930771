import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getLiteral } from 'utils/getLiteral';
import { Text, Icon, Tooltip, useTheme } from 'hoi-poi-ui';

import './styles.scss';

const BaseV2 = memo(
    ({
        label,
        mandatory,
        children,
        className,
        parentClassName,
        description,
        labelMode,
        isBulkAction,
        isHighlighted,
        isBulkFieldsWrapper,
        isDuplicate,
        canToggle,
        fieldExtraValue,
        onClickBulkEditable,
        onReset,
    }) => {
        const [bulkEditable, setBulkEditable] = useState(false);
        const [isToggled, setIsToggled] = useState(canToggle);
        const theme = useTheme();
        const rootClassName = classnames('fm-field-container--v2', {
            [parentClassName]: !!parentClassName,
            [labelMode]: !!labelMode,
            horizontal: !labelMode,
            'fm-field-container--highlighted': isHighlighted,
        });

        const toggleClassName = classnames('fm-field-toggle', {
            'fm-field-toggle--collapsed': isToggled,
        });

        const fieldInputClassName = classnames('fm-field-input', {
            [className]: !!className,
            'fm-field-input__duplicate': isDuplicate,
            'fm-field-input__can-toggle': canToggle,
            'fm-field-input__collapsed': canToggle && isToggled,
        });

        const openBulkFieldsWrapper = useCallback(() => {
            setBulkEditable(true);
            onClickBulkEditable && onClickBulkEditable(); // custom onClick for fields with additional stuff
        }, [onClickBulkEditable]);

        const handleReset = useCallback(() => {
            onReset && onReset(null);
            setBulkEditable(false);
        }, [onReset]);

        const onToggleField = useCallback(() => {
            setIsToggled(!isToggled);
        }, [isToggled]);

        return (
            <div className={rootClassName}>
                {(label || label === '') && (
                    <div className="fm-field-label">
                        <Text>{label}</Text>
                        {!isBulkAction && mandatory && (
                            <span className="fm-v2-asterisk-mandatory">*</span>
                        )}
                        {description && (
                            <span className="fm-field-description">
                                <Tooltip
                                    className="fm-field-description-tooltip"
                                    content={description}
                                    position="top"
                                >
                                    <Icon
                                        className="fm-field-description-icon"
                                        name="info"
                                        color={theme.colors.grey[50]}
                                    />
                                </Tooltip>
                            </span>
                        )}
                        {fieldExtraValue && (
                            <span className="fm-field-extra-value">
                                <Tooltip
                                    className="fm-field-extra-value-tooltip"
                                    content={fieldExtraValue}
                                    placement="right"
                                >
                                    <Icon
                                        className="fm-field-extra-value-icon"
                                        name="info"
                                        color={theme.colors.semantic.negative450}
                                    />
                                </Tooltip>
                            </span>
                        )}
                        {!isBulkAction && canToggle && (
                            <Icon
                                className={toggleClassName}
                                name="expandLess"
                                onClick={onToggleField}
                            />
                        )}
                    </div>
                )}
                <div className={fieldInputClassName}>
                    {isBulkAction && !bulkEditable && (
                        <Text
                            className="fm-v2-field-bulk"
                            color={theme.colors.utility.textDisabled}
                            onClick={openBulkFieldsWrapper}
                        >
                            {' '}
                            {getLiteral('placeholder_click_to_edit')}
                        </Text>
                    )}
                    {(!isBulkAction || bulkEditable) && children}
                    {isBulkAction && bulkEditable && !isBulkFieldsWrapper && (
                        <Text
                            className="fm-v2-field-input-bulk-cancel"
                            color={theme.colors.semantic.info500}
                            onClick={handleReset}
                        >
                            {getLiteral('action_cancel')}
                        </Text>
                    )}
                </div>
            </div>
        );
    },
);

const propTypes = {
    label: PropTypes.any,
    mandatory: PropTypes.bool,
    className: PropTypes.string,
    parentClassName: PropTypes.string,
    description: PropTypes.string,
    labelMode: PropTypes.string,
    isBulkAction: PropTypes.bool,
};

BaseV2.propTypes = propTypes;

export default BaseV2;
