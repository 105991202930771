import React, { memo, useMemo, Fragment } from 'react';
import { Icon, Text, Tooltip, useTheme } from 'hoi-poi-ui';

import './styles.scss';

const EmailAttachment = memo(({ onClick, iconName, name, size }) => {
    const theme = useTheme();

    const attachmentBox = useMemo(() => {
        return (
            <div className="email-row__attachment-box" onClick={onClick}>
                <Icon className="email_row__attachment-icon" name={iconName} />
                <div className="email-row__attachment-info">
                    <Text className="email-row__attachment-name" type="caption" isTruncated>
                        {name}
                    </Text>
                    <Text
                        className="email-row__attachment-size"
                        type="caption"
                        color={theme.colors.utility.textSecondary}
                    >
                        {`${size}`}
                    </Text>
                </div>
            </div>
        );
    }, [iconName, name, onClick, size, theme]);

    return (
        <Fragment>
            {name && <Tooltip content={name}>{attachmentBox}</Tooltip>} {!name && attachmentBox}
        </Fragment>
    );
});

export default EmailAttachment;
