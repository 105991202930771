import React, { memo, useCallback, useMemo, useState, useRef } from 'react';
import { Text, Link, Popover, Avatar, Icon, useTheme } from 'hoi-poi-ui';
import { CONTACTS, COMPANIES, USERS } from 'constants/Entities';
import useEntityDetail from 'containers/components/EntityDetail/hooks/useEntityDetail';
import {
    getSrcContactCircleAvatar,
    getSrcUserCircleAvatar,
    getSrcCompanyCircleAvatar,
} from 'utils/getSrcAvatar';
import { getLiteral } from 'utils/getLiteral';

import './styles.scss';

const RECIPIENT_TYPES = {
    ACCOUNT: 1,
    CONTACT: 2,
    USER: 3,
    EXTERNAL: 4,
};

const SubtitleContentMultiple = memo(
    ({ recipients, contacts, users, accounts, entity, isFromWidgetList }) => {
        const [visible, setVisible] = useState(false);
        const { hasTabsEnabled, openTab } = useEntityDetail();
        const timeoutRef = useRef();
        const theme = useTheme();

        const showPopover = useCallback(() => {
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
            setVisible(true);
        }, []);

        const hidePopover = useCallback(() => {
            timeoutRef.current = setTimeout(() => setVisible(false), 200);
        }, []);

        const getFullName = useCallback((name, surname) => {
            let fullName = name;
            if (surname) fullName = `${fullName} ${surname}`;
            return fullName;
        }, []);

        const getContactsUsersNames = useCallback(() => {
            if (!contacts?.length && !users?.length) return '';
            const contactNames = contacts?.map((current) =>
                getFullName(current.Name, current.Surname),
            );
            const userNames = users?.map((current) => getFullName(current.Name, current.Surname));

            let final = [...(contactNames || []), ...(userNames || [])];

            if (final.length === 2) return { name: final.join(', '), number: 0 };
            const sliced = final.slice(0, 2);
            const number = final.length - sliced.length;
            return { name: sliced.join(', '), number: number > 0 ? number : 0 };
        }, [contacts, users, getFullName]);

        const getAccountsNames = useCallback(() => {
            if (!accounts?.length) return '';
            const accountsNames = accounts.map((current) => current.Name);
            const sliced = accountsNames.slice(0, 2);
            const number = accountsNames.length - sliced.length;
            return { name: sliced.join(', '), number: number > 0 ? number : 0 };
        }, [accounts]);

        const getAvatar = useCallback(({ entity, name, id }) => {
            let srcObj = null;

            if (entity === CONTACTS) srcObj = getSrcContactCircleAvatar(id);
            else if (entity === USERS) srcObj = getSrcUserCircleAvatar(id);
            else srcObj = getSrcCompanyCircleAvatar(id);

            return (
                <Avatar
                    alt={name}
                    src={srcObj.src}
                    placeholder={srcObj.fallbackSrc}
                    size="medium"
                />
            );
        }, []);

        const onClickEmail = useCallback((email) => {
            window.location.href = `mailto:${email}`;
        }, []);

        const getRow = useCallback(
            ({ entity, value }) => {
                const fullName = getFullName(value.Name, value.Surname);

                return (
                    <div key={value.Id} className="popover-multiple__contact-row">
                        <div className="popover-multiple__contact-row__avatar">
                            {getAvatar({ entity, name: fullName, id: value.Id })}
                        </div>
                        <div className="popover-multiple__contact-row__info">
                            <Link
                                className="popover-multiple__contacts-row__name"
                                type="body"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    if (!hasTabsEnabled) return;

                                    hidePopover();

                                    openTab({
                                        entity,
                                        id: value.Id,
                                        hasCrudInDetail: true,
                                        toTab: isFromWidgetList,
                                    });
                                }}
                            >
                                {fullName}
                            </Link>
                            <div className="popover-multiple__contacts-row__email-container">
                                <Icon name="email" />
                                <Link
                                    className="popover-multiple__contacts-row__email"
                                    type="caption"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onClickEmail(value.Email);
                                    }}
                                >
                                    {value.Email}
                                </Link>
                            </div>
                        </div>
                    </div>
                );
            },
            [
                getFullName,
                getAvatar,
                hasTabsEnabled,
                hidePopover,
                openTab,
                isFromWidgetList,
                onClickEmail,
            ],
        );

        const getExternalsRow = useCallback(
            (value, index) => {
                return (
                    <div className="popover-multiple__external-row">
                        <Icon name="email" />
                        <Link
                            className="popover-multiple__external-row__email"
                            type="caption"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onClickEmail(value.Email);
                            }}
                        >
                            {value.Email}
                        </Link>
                    </div>
                );
            },
            [onClickEmail],
        );

        const getAccountsRow = useCallback(
            (value) => {
                return (
                    <div key={value.Id} className="popover-multiple__account-row">
                        <div className="popover-multiple__account-row__avatar">
                            {getAvatar({ entity: COMPANIES, name: value.Name, id: value.Id })}
                        </div>
                        <Link
                            className="popover-multiple__account-row__name"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                if (!hasTabsEnabled) return;

                                hidePopover();

                                openTab({
                                    entity: COMPANIES,
                                    id: value.Id,
                                    hasCrudInDetail: true,
                                    toTab: isFromWidgetList,
                                });
                            }}
                        >
                            {value.Name}
                        </Link>
                    </div>
                );
            },
            [getAvatar, hasTabsEnabled, hidePopover, openTab, isFromWidgetList],
        );

        const getContacts = useCallback(() => {
            if (!contacts?.length) return null;

            const contactsByCompany = contacts.reduce((obj, current) => {
                if (!obj[current.AccountId]) obj[current.AccountId] = [];
                obj[current.AccountId].push(current);
                return obj;
            }, {});

            const contactsItems = Object.entries(contactsByCompany).map(([key, value]) => {
                const companyName = value[0].Account;
                const items = value.map((current) => {
                    return getRow({ entity: CONTACTS, value: current });
                });

                return (
                    <div key={key}>
                        <Text
                            className="popover-multiple__subtitle"
                            type="subtitle"
                            color={theme.colors.utility.textSecondary}
                        >
                            {companyName}
                        </Text>
                        <div className="popover-multiple__rows-container">{items}</div>
                    </div>
                );
            });

            return (
                <div>
                    <Text className="popover-multiple__title" type="subtitle">
                        {getLiteral('label_contacts')}
                    </Text>
                    {contactsItems}
                </div>
            );
        }, [contacts, getRow, theme]);

        const getExternals = useCallback(() => {
            if (!recipients?.length) return null;
            const items = recipients.reduce((arr, current) => {
                if (current.RecipientType !== RECIPIENT_TYPES.EXTERNAL) return arr;
                arr.push(getExternalsRow(current));
                return arr;
            }, []);

            if (!items?.length) return null;

            return (
                <div>
                    <Text className="popover-multiple__title" type="subtitle">
                        {getLiteral('label_unknown_email')}
                    </Text>
                    <div className="popover-multiple__rows-container">{items}</div>
                </div>
            );
        }, [getExternalsRow, recipients]);

        const getUsers = useCallback(() => {
            if (!users?.length) return null;
            const items = users.map((current) => {
                return getRow({ entity: USERS, value: current });
            });
            return (
                <div>
                    <Text className="popover-multiple__title" type="subtitle">
                        {getLiteral('label_users')}
                    </Text>
                    <div className="popover-multiple__rows-container">{items}</div>
                </div>
            );
        }, [users, getRow]);

        const getAccounts = useCallback(() => {
            if (!accounts?.length) return null;
            const items = accounts.map((current) => getAccountsRow(current));
            return (
                <div>
                    <Text className="popover-multiple__title" type="subtitle">
                        {getLiteral('label_accounts')}
                    </Text>
                    <div className="popover-multiple__rows-container">{items}</div>
                </div>
            );
        }, [accounts, getAccountsRow]);

        const getNameWithNumber = useCallback((name, number) => {
            if (!number) return name;
            return `${name}, +${number}`;
        }, []);

        const popoverMultiple = useMemo(() => {
            let popoverContent = null;
            let name = null;
            let number = 0;

            const containerProps = {
                className: 'popover-multiple__container',
                onMouseEnter: showPopover,
                onMouseLeave: hidePopover,
            };

            if (accounts?.length) {
                popoverContent = <div {...containerProps}>{getAccounts()}</div>;
                const accountsNames = getAccountsNames();
                name = accountsNames.name;
                number = accountsNames.number;
            } else if (contacts?.length || users?.length) {
                let contactsContent = getContacts();
                let usersContent = getUsers();
                let externalsContent = getExternals();
                popoverContent = (
                    <div {...containerProps}>
                        {contactsContent}
                        {usersContent && <div className="popover-multiple__divider" />}
                        {usersContent}
                        {externalsContent && <div className="popover-multiple__divider" />}
                        {externalsContent}
                    </div>
                );

                const contactsNames = getContactsUsersNames();
                name = contactsNames.name;
                number = contactsNames.number;
            }

            return (
                <Popover
                    className="popover-multiple__root"
                    placement="bottom"
                    content={popoverContent}
                    overrides={{ root: { visible } }}
                >
                    <Link
                        className="popover-multiple__text"
                        onMouseEnter={showPopover}
                        onMouseLeave={hidePopover}
                    >
                        {getNameWithNumber(name, number)}
                    </Link>
                </Popover>
            );
        }, [
            accounts?.length,
            contacts?.length,
            users?.length,
            visible,
            showPopover,
            hidePopover,
            getNameWithNumber,
            getAccounts,
            getAccountsNames,
            getContacts,
            getUsers,
            getExternals,
            getContactsUsersNames,
        ]);

        if (!entity) return null;

        return popoverMultiple;
    },
);

export default SubtitleContentMultiple;
