import React from 'react';
import { getLiteral } from 'utils/getLiteral';
import { useTheme, Text } from 'hoi-poi-ui';

const ProfileSettingsHeader = () => {
    const theme = useTheme();
    return (
        <div className="profile-settings-header">
            <Text type="h5">{getLiteral('title_myprofile')}</Text>
            <Text type="body" color={theme.colors.utility.textSecondary}>
                {getLiteral('label_subtitle_my_profile')}
            </Text>
        </div>
    );
};

export default ProfileSettingsHeader;
