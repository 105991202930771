import React, { memo } from 'react';
import { getLiteral } from 'utils/getLiteral';
import { Loader, Text, useTheme } from 'hoi-poi-ui';

import './styles.scss';

const DuplicateLoadingOverlay = memo(({}) => {
    const theme = useTheme();
    return (
        <div className="duplicate-loading-overlay">
            <div className="duplicate-loading-overlay__blank"></div>
            <div className="duplicate-loading-overlay__spinner">
                <Loader size="small" />
                <Text className="fm-t-4" type="caption" color={theme.colors.actionMajor[500]}>
                    {getLiteral('label_checking_duplicates_web')}
                </Text>
            </div>
        </div>
    );
});

export default DuplicateLoadingOverlay;
