import React, { memo } from 'react';

const NoData = (props) => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 168 144"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <mask
            id="mask0_2421_18199"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x={22}
            y={17}
            width={125}
            height={110}
        >
            <path d="M146.118 17.2235H22.165V126.776H146.118V17.2235Z" fill="white" />
        </mask>
        <g mask="url(#mask0_2421_18199)">
            <path
                d="M76.5895 66.5752C75.998 66.5752 75.4047 66.3603 74.9372 65.9243L69.6476 60.9945C68.6678 60.0808 68.6122 58.5465 69.525 57.5642C70.4379 56.5835 71.9711 56.5279 72.9522 57.4416L78.2418 62.3715C79.2216 63.2852 79.2772 64.8195 78.3644 65.8018C77.8872 66.3143 77.2383 66.5752 76.5895 66.5752Z"
                fill="#B26E3D"
            />
            <path
                d="M134.403 115.33C133.198 115.33 132.153 114.431 132.001 113.202L131.188 106.653C131.023 105.322 131.966 104.111 133.295 103.945C134.623 103.78 135.835 104.724 136 106.055L136.813 112.603C136.978 113.934 136.035 115.145 134.706 115.311C134.604 115.323 134.504 115.33 134.403 115.33Z"
                fill="#B26E3D"
            />
            <path
                d="M115.616 111.911L116.694 122.737H128.615L126.411 100.616L126.392 100.418L126.359 100.222C125.451 94.8136 120.815 90.8883 115.334 90.8883C109.402 90.8883 104.499 95.533 104.173 101.462V101.504L103.295 122.738H115.169L115.616 111.912V111.911Z"
                fill="#474747"
            />
            <path
                d="M133.592 110.806C131.143 110.806 129.153 108.824 129.144 106.37C129.117 99.2849 128.665 94.4426 127.725 91.13C126.912 88.2681 125.771 86.5361 124.042 83.9131L123.683 83.367C122.52 81.5986 121.416 80.4333 120.487 79.9971C119.915 79.728 119.344 79.7153 118.302 79.7187H118.094C115.637 79.7187 113.646 77.7256 113.646 75.2663C113.646 72.807 115.637 70.8141 118.094 70.8141H118.288C119.713 70.811 121.869 70.8076 124.272 71.9379C126.755 73.1063 128.994 75.244 131.118 78.4738L131.47 79.0103C135.747 85.4968 137.98 89.6578 138.045 106.336C138.055 108.796 136.07 110.796 133.613 110.806H133.595H133.592Z"
                fill="#ABABAB"
            />
            <path
                d="M116.413 96.0692C119.357 96.0692 121.744 93.6805 121.744 90.7336C121.744 87.7869 119.357 85.3979 116.413 85.3979C113.469 85.3979 111.082 87.7869 111.082 90.7336C111.082 93.6805 113.469 96.0692 116.413 96.0692Z"
                fill="#ABABAB"
            />
            <path
                d="M109.995 78.0695C109.792 81.9869 112.801 85.3262 116.715 85.5301C120.628 85.734 123.965 82.7221 124.169 78.8047C124.372 74.8874 121.121 75.5893 117.208 75.3871C113.294 75.1836 110.197 74.1521 109.995 78.0695Z"
                fill="#ABABAB"
            />
            <path
                d="M111.109 91.2731L121.653 91.7252L124.098 79.5115L109.994 78.7711L111.109 91.2731Z"
                fill="#ABABAB"
            />
            <path
                d="M120.253 67.8661C119.447 68.904 117.929 69.0664 116.919 68.2021C116.019 67.4301 115.854 66.0627 116.544 65.0982C117.008 64.4485 117.742 64.1145 118.476 64.1303C118.957 64.1399 119.437 64.2988 119.846 64.6173C120.588 65.195 120.883 66.1392 120.673 66.997C120.598 67.3042 120.458 67.6004 120.251 67.8661H120.253Z"
                fill="#B26E3D"
            />
            <path
                d="M117.825 64.2051C117.967 64.5504 118.231 65.1792 118.935 66.1691C119.276 66.6469 119.835 67.4078 120.317 67.7833C120.489 67.5397 120.61 67.2771 120.677 67.0018C120.887 66.1406 120.593 65.195 119.848 64.6156C119.439 64.2974 118.956 64.1365 118.476 64.1286C118.256 64.1238 118.038 64.1492 117.827 64.2065L117.825 64.2051Z"
                fill="#474747"
            />
            <path
                d="M124.95 66.1646C124.053 67.3186 122.364 67.4968 121.244 66.5275C120.298 65.7078 120.085 64.2861 120.751 63.2276C121.259 62.4223 122.117 62.0053 122.981 62.0242C123.512 62.0338 124.047 62.212 124.498 62.5638C125.322 63.2053 125.646 64.2497 125.416 65.2015C125.333 65.542 125.179 65.8701 124.95 66.1646Z"
                fill="#474747"
            />
            <path
                d="M83.1224 64.4025C82.1505 63.6621 81.559 63.1655 81.5209 63.1353C79.6441 61.5484 76.8386 61.7839 75.2515 63.6607C73.6644 65.5389 73.8996 68.3484 75.7747 69.9355C75.913 70.0532 77.4431 71.3393 79.757 72.9789L81.6431 72.39L83.1207 64.4025H83.1224Z"
                fill="#ABABAB"
            />
            <path
                d="M117.326 70.8141C116.003 70.8141 114.322 71.0146 112.193 71.271C107.278 71.8583 99.9463 72.7352 94.8349 71.1275L94.6855 80.2676C97.1395 80.7676 99.7063 80.9506 102.224 80.9506C106.26 80.9506 110.165 80.4824 113.25 80.1131C114.939 79.911 116.534 79.7201 117.326 79.7201C119.783 79.7201 121.774 77.7272 121.774 75.268C121.774 72.8087 119.783 70.8155 117.326 70.8155V70.8141Z"
                fill="#ABABAB"
            />
            <path
                d="M140.281 126.776H97.8468C94.6294 126.776 92.0117 124.156 92.0117 120.936V59.2035C92.0117 55.9835 94.6294 53.3633 97.8468 53.3633H140.283C143.5 53.3633 146.118 55.9835 146.118 59.2035V120.936C146.118 124.156 143.5 126.776 140.283 126.776H140.281ZM97.8468 57.4032C96.8544 57.4032 96.0465 58.2119 96.0465 59.2052V120.938C96.0465 121.931 96.8544 122.74 97.8468 122.74H140.283C141.276 122.74 142.083 121.931 142.083 120.938V59.2035C142.083 58.2105 141.276 57.4018 140.283 57.4018H97.8468V57.4032Z"
                fill="#00D639"
            />
            <path
                d="M135.5 114.501C135.442 113.974 134.969 113.592 134.443 113.654C133.916 113.713 133.538 114.187 133.597 114.712L134.487 122.738H136.417L135.504 114.502L135.5 114.501Z"
                fill="#303030"
            />
            <path
                d="M77.2956 75.0133H30.5511C25.9263 75.0133 22.165 71.247 22.165 66.6198V34.902C22.165 30.2731 25.9279 26.5084 30.5511 26.5084H77.2956C81.9205 26.5084 85.6817 30.2745 85.6817 34.902V66.6198C85.6817 71.2487 81.9188 75.0133 77.2956 75.0133ZM30.5511 30.5484C28.1528 30.5484 26.2014 32.5014 26.2014 34.902V66.6198C26.2014 69.0201 28.1528 70.9734 30.5511 70.9734H77.2956C79.6939 70.9734 81.6455 69.0201 81.6455 66.6198V34.902C81.6455 32.5014 79.6939 30.5484 77.2956 30.5484H30.5511Z"
                fill="#00D639"
            />
            <path
                d="M49.3135 59.8323C42.6926 59.0905 37.9088 53.0942 38.6499 46.4677C39.3911 39.8411 45.382 35.053 52.0029 35.7947C58.6235 36.5365 63.4074 42.5328 62.6662 49.1593C61.9253 55.7859 55.9344 60.574 49.3135 59.8309V59.8323ZM51.8072 37.5459C46.1503 36.9123 41.0323 41.0016 40.3993 46.6636C39.7664 52.3254 43.8522 57.4478 49.5091 58.0814C55.1661 58.715 60.284 54.6257 60.917 48.9637C61.5498 43.3017 57.4641 38.1792 51.8072 37.5459Z"
                fill="#474747"
            />
            <path
                d="M62.1053 52.1839C61.4378 51.7553 60.5496 51.9493 60.1212 52.6173C59.6929 53.2857 59.8866 54.1748 60.5541 54.6034L72.2686 62.1275C72.9361 62.5564 73.8244 62.3625 74.2527 61.6941C74.681 61.0261 74.4873 60.137 73.8199 59.7083L62.1053 52.1839Z"
                fill="#474747"
            />
            <path
                d="M137.55 47.8972H101.302C97.4561 47.8972 94.3262 44.7662 94.3262 40.9158V24.2051C94.3262 20.3562 97.4546 17.2235 101.302 17.2235H137.55C141.395 17.2235 144.525 20.3546 144.525 24.2051V40.9158C144.525 44.7645 141.397 47.8972 137.55 47.8972ZM101.302 21.2635C99.681 21.2635 98.3627 22.5831 98.3627 24.2051V40.9158C98.3627 42.5376 99.681 43.8573 101.302 43.8573H137.55C139.17 43.8573 140.489 42.5376 140.489 40.9158V24.2051C140.489 22.5831 139.17 21.2635 137.55 21.2635H101.302Z"
                fill="#00D639"
            />
            <path d="M119.414 36.8581H128.004V28.261H119.414V36.8581Z" fill="#919191" />
            <path d="M110.824 36.8581H119.414V28.261H110.824V36.8581Z" fill="#ABABAB" />
            <path d="M102.236 36.8581H110.826V28.261H102.236V36.8581Z" fill="#C6C6C6" />
            <path d="M128.003 36.8581H136.593V28.261H128.003V36.8581Z" fill="#777777" />
            <path
                d="M78.2115 118.886H34.8576C32.3464 118.886 30.3027 116.84 30.3027 114.327V87.7347C30.3027 85.2212 32.3464 83.1758 34.8576 83.1758H78.2115C80.7227 83.1758 82.7664 85.2212 82.7664 87.7347V114.327C82.7664 116.84 80.7227 118.886 78.2115 118.886ZM34.8576 87.2157C34.5713 87.2157 34.3391 87.4481 34.3391 87.7347V114.327C34.3391 114.614 34.5713 114.846 34.8576 114.846H78.2115C78.4978 114.846 78.7299 114.614 78.7299 114.327V87.7347C78.7299 87.4481 78.4978 87.2157 78.2115 87.2157H34.8576Z"
                fill="#00D639"
            />
            <path
                d="M49.1602 96.0297V105.792L56.8704 110.516V91.4629L49.1602 96.0297Z"
                fill="#5E5E5E"
            />
            <path
                d="M63.3807 108.422C63.2042 108.422 63.0277 108.368 62.8733 108.256C62.4884 107.976 62.4026 107.436 62.681 107.05C62.7078 107.012 64.6468 104.275 64.7182 100.832C64.7896 97.3827 62.9148 94.5112 62.8973 94.4824C62.6333 94.0845 62.7414 93.5481 63.139 93.2855C63.5349 93.0212 64.071 93.1279 64.335 93.5244C64.424 93.6579 66.5298 96.8606 66.447 100.869C66.3643 104.875 64.1744 107.938 64.0804 108.065C63.9118 108.298 63.6478 108.422 63.3807 108.422Z"
                fill="#5E5E5E"
            />
            <path
                d="M60.5504 105.924C60.4087 105.924 60.2641 105.889 60.1305 105.814C59.7155 105.582 59.5644 105.057 59.7965 104.641C59.8075 104.621 60.6727 103.024 60.7142 101.015C60.7554 99.0014 59.9301 97.3271 59.9222 97.311C59.709 96.8844 59.8807 96.3654 60.3053 96.1505C60.73 95.9356 61.2487 96.1059 61.4632 96.5311C61.5045 96.6139 62.4907 98.5923 62.4396 101.05C62.3888 103.508 61.3486 105.4 61.3057 105.48C61.1484 105.764 60.8542 105.924 60.5504 105.924Z"
                fill="#5E5E5E"
            />
            <path
                d="M66.304 111.319C66.1145 111.319 65.9254 111.257 65.7647 111.129C65.3926 110.831 65.3321 110.289 65.628 109.915C65.6645 109.869 68.7071 105.975 68.8088 101.069C68.9107 96.1584 65.9652 92.0773 65.9367 92.0358C65.6552 91.6506 65.7376 91.1111 66.121 90.8276C66.5059 90.5458 67.0449 90.6271 67.3264 91.0105C67.4617 91.1952 70.6489 95.5903 70.5342 101.104C70.4199 106.616 67.1166 110.816 66.9766 110.992C66.8066 111.206 66.5553 111.317 66.3006 111.317L66.304 111.319Z"
                fill="#5E5E5E"
            />
            <path
                d="M43.0904 95.8114H46.6639V105.357H43.0904C41.9421 105.357 41.0117 104.425 41.0117 103.277V97.8918C41.0117 96.7426 41.9438 95.8114 43.0904 95.8114Z"
                fill="#5E5E5E"
            />
            <path
                d="M72.3314 62.3235C72.3139 62.3235 72.2963 62.3235 72.2803 62.3235C71.8271 62.2947 71.4835 61.9048 71.5106 61.4527C71.5456 60.8623 71.5281 59.1081 71.0303 58.0783C70.8332 57.6692 71.0032 57.1773 71.412 56.98C71.8209 56.7826 72.3122 56.9529 72.5092 57.362C73.2981 58.992 73.1567 61.4496 73.1502 61.553C73.1234 61.9892 72.7622 62.3252 72.3314 62.3252V62.3235Z"
                fill="#B26E3D"
            />
            <path
                d="M73.9326 63.6398C73.9154 63.6398 73.8979 63.6398 73.8818 63.6398C73.4286 63.6113 73.085 63.2211 73.1121 62.769C73.1471 62.1786 73.1296 60.4244 72.6318 59.3946C72.4348 58.9855 72.6047 58.4937 73.0136 58.2963C73.4221 58.0989 73.9137 58.2692 74.1108 58.6783C74.8997 60.3083 74.7582 62.7659 74.7517 62.8696C74.7249 63.3055 74.3638 63.6415 73.9326 63.6415V63.6398Z"
                fill="#B26E3D"
            />
            <path
                d="M75.0167 61.3923L72.5515 60.9258L72.3115 62.93L73.6583 63.2118L75.0167 61.3923Z"
                fill="#B26E3D"
            />
        </g>
    </svg>
);

export default memo(NoData);
