import React, { memo, useCallback, useMemo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Loader } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import { getEnvironmentRoute } from 'utils/routes';
import { EntityModalActions } from 'actions';
import Context from 'managers/Context';
import ScrollBar from 'components/ScrollBar';
import SizeComponent from 'components/SizeComponent';
import {
    WidgetLayout,
    WidgetHeaderLayout,
    WidgetContentLayout,
    WidgetEmpty,
} from 'containers/components/widgets/Layouts';
import ContactsIcon from 'components/SvgIcons/entities/Contacts';
import { EntityChip } from 'containers/components/chips';
import { CONTACTS } from 'constants/Entities';
import { getCrudRoute, checkIfEntityIsWeb4 } from 'utils/fm';
import { subscribe } from 'lib/EventBuser';
import { ENTITY_MODAL_UPDATE } from 'lib/events';
import { logEvent } from 'utils/tracking';
import useEntityDetail from 'containers/components/EntityDetail/hooks/useEntityDetail';
import colors from 'constants/colors';

import './styles.scss';

const mapStateToProps = (state) => {
    const permissions = state.config.permission.crud_permission[CONTACTS.permission];

    return {
        canCreate: permissions ? permissions.create : false,
        canUpdate: permissions ? permissions.update : false,
    };
};

const mapDispatchToProps = (dispatch) => {
    const EntityModalAction = bindActionCreators(EntityModalActions, dispatch);
    return {
        modalInit: EntityModalAction.init,
    };
};

const RelatedContacts = memo(
    ({ entityType, setSize, entityId, entity, canCreate, canUpdate, modalInit }) => {
        const { openTab, hasTabsEnabled } = useEntityDetail();

        const alphaOrder = (a, b) => {
            let prevContant = a.Name.toUpperCase();
            let nextContant = b.Name.toUpperCase();
            if (prevContant < nextContant) return -1;
            if (prevContant > nextContant) return 1;
            return 0;
        };

        const [contacts, setContacts] = useState([]);
        const [isLoading, setIsLoading] = useState(true);

        useEffect(() => {
            function loadContacts() {
                setIsLoading(true);
                Context.domainManager
                    .getContacts({ companies: entityId })
                    .then((contacts) => setContacts(contacts.sort(alphaOrder)))
                    .catch((err) => {
                        console.error(err);
                        setContacts([]);
                    })
                    .then(() => setIsLoading(false));
            }
            if (entityId) loadContacts();
            return subscribe(`${ENTITY_MODAL_UPDATE}--${CONTACTS.entity}`, loadContacts);
        }, [entityId]);

        const memoizedSetSize = useCallback(
            (size) => {
                setSize && setSize(size, true);
            },
            [setSize],
        );

        const onAdd = useCallback(() => {
            logEvent({
                event: entityType.trueName,
                submodule: 'contactsWidget',
                functionality: 'create',
            });
            if (checkIfEntityIsWeb4(CONTACTS)) {
                modalInit({
                    entity: CONTACTS,
                    data: {
                        IdCompany: {
                            label: entity.name,
                            value: entity.id,
                        },
                    },
                    labels: {
                        title: getLiteral('page_title_contacts_create'),
                        success: getLiteral('succes_entitycreatedsuccessfully'),
                        error: getLiteral('label_failed_insert_contact_salesforce'),
                    },
                    showDelete: false,
                });
            } else {
                const backUrl = window.btoa(getEnvironmentRoute(`/companies/${entityId}`));
                let params = {
                    back: backUrl,
                    ic: entityId,
                    entityfrom: entityType.entity,
                    idfrom: entity.id,
                    namefrom: entity.name,
                };
                const queryString = Object.keys(params)
                    .map((key) => key + '=' + params[key])
                    .join('&');

                window.location.href = getCrudRoute(CONTACTS, null, `?${queryString}`);
            }
        }, [entity, entityId, entityType, modalInit]);

        const onClickContact = useCallback(
            (id) => {
                if (!hasTabsEnabled) return true;

                openTab({
                    entity: CONTACTS,
                    id,
                    hasCrudInDetail: true,
                    toTab: true,
                });
            },
            [hasTabsEnabled, openTab],
        );

        const tabsConfig = useMemo(
            () => [
                {
                    title: getLiteral('label_relatedcontacts'),
                    count: contacts.length || '0',
                },
            ],
            [contacts],
        );

        const isEmpty = contacts.length === 0;

        const even = contacts.length % 2 === 0;

        const odd = contacts.length % 2 !== 0;

        const showDivider = (length, id) => {
            if ((even && id < length - 2) || (odd && id < length - 1)) {
                return <div className="fm-widget__contact--divider" />;
            }
        };

        const canOpenModal = checkIfEntityIsWeb4(CONTACTS) && canUpdate;

        return (
            <WidgetLayout
                loading={isLoading}
                data={entityId}
                type="contacts"
                className="fm-widget__related-contacts"
            >
                <WidgetHeaderLayout
                    content={tabsConfig}
                    onClickAdd={canCreate ? onAdd : undefined}
                />
                <WidgetContentLayout>
                    {!isEmpty && (
                        <ScrollBar
                            autoHide={true}
                            hideTracksWhenNotNeeded={true}
                            style={{ height: '100%' }}
                        >
                            <SizeComponent setSize={memoizedSetSize}>
                                <div className="fm-widget__related-contacts__content">
                                    {contacts.map((contact, idx) => (
                                        <div key={contact.Id} className="fm-widget__contact">
                                            <EntityChip
                                                className="fm-widget__contact-chip"
                                                classNamePopper="fm-widget__contact-popper"
                                                disableClick
                                                entity="contacts"
                                                id={contact.Id}
                                                idIconField={contact.Id}
                                                name={`${contact.Name} ${contact.Surnames}`}
                                                subtitle={contact.ContactType || contact.Comments}
                                                remotePopover
                                                noCache
                                                onClickPopover={
                                                    canOpenModal
                                                        ? () => onClickContact(contact.Id)
                                                        : undefined
                                                }
                                                onClick={
                                                    canOpenModal
                                                        ? () => onClickContact(contact.Id)
                                                        : undefined
                                                }
                                            />
                                            {showDivider(contacts.length, idx)}
                                        </div>
                                    ))}
                                </div>
                            </SizeComponent>
                        </ScrollBar>
                    )}
                    {isEmpty && (
                        <SizeComponent setSize={memoizedSetSize}>
                            {isLoading && <WidgetEmpty icon={<Loader />} />}
                            {!isLoading && (
                                <WidgetEmpty
                                    icon={<ContactsIcon color={colors.grey[100]} />}
                                    text={getLiteral('error_norelatedcontacts')}
                                />
                            )}
                        </SizeComponent>
                    )}
                </WidgetContentLayout>
            </WidgetLayout>
        );
    },
);

RelatedContacts.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(RelatedContacts);
