import React, { memo } from 'react';
import { Text, useTheme } from 'hoi-poi-ui';
import classnames from 'classnames';
import { getLiteral } from 'utils/getLiteral';

import './styles.scss';

const OpportunityInfoWindowRow = memo(({ className, label, children }) => {
    const theme = useTheme();
    const classNames = classnames('opportunity-info-window-row', className);

    return (
        <div className={classNames}>
            <Text
                type="caption"
                className="opportunity-info-window__label"
                color={theme.colors.utility.textSecondary}
            >
                {getLiteral(label)}
            </Text>
            <div className="opportunity-info-window__value">{children}</div>
        </div>
    );
});

export default OpportunityInfoWindowRow;
