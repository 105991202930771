import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Handle, Position } from '@xyflow/react';
import { Chip, SectionForm, Text } from 'hoi-poi-ui';
import classnames from 'classnames';
import { getLiteral } from 'utils/getLiteral';
import { isEmptyObject } from 'utils/objects';
import dot from 'utils/dot';
import { errorToast } from 'utils/toast';

import { getAutomationVariables } from 'services/AutomationsService';
import {
    findNodeByType,
    CONDITION_BOOL_LOGIC_LOCALES,
    CONDITION_OPERATOR_LOCALES,
    buildPathTree,
    getOperatorConditionValues,
} from '../../utils';
import { AutomationBuilderCanvasContext } from '../../AutomationBuilderCanvasContext';

import DeleteModal from 'components/DeleteModal';
import AutomationBuilderBox from '../../components/AutomationBuilderBox';
import ConditionBoxValue from './ConditionBoxValue';

const AutomationBuilderConditionBox = memo((props) => {
    const { data, onChange, dispatch } = useContext(AutomationBuilderCanvasContext);
    const deleteModalRef = useRef();
    const nodeData = useMemo(() => findNodeByType(data?.scene, props.id), [data?.scene, props.id]);
    const [fieldsMap, setFieldsMap] = useState();

    const onDeleteRef = useCallback((ref) => {
        deleteModalRef.current = ref;
    }, []);

    const onDelete = useCallback(() => {
        deleteModalRef.current.open();
    }, []);

    const onDeleteConfirm = useCallback(() => {
        dispatch({ type: 'close' });
        const nodePath = dot.findPathByAttr(data, 'name', props.id);
        onChange(nodePath, {
            name: 'conditions_logic',
            parameters: {
                conditions: { and: [] },
            },
            type: 'conditions',
        });
        return Promise.resolve();
    }, [data, dispatch, onChange, props.id]);

    const hasData = useMemo(() => {
        const boolLogic = Object.keys(nodeData?.parameters?.conditions || {})?.[0];
        return (
            !isEmptyObject(nodeData?.parameters?.conditions) &&
            nodeData?.parameters?.conditions?.[boolLogic]?.length !== 0
        );
    }, [nodeData?.parameters]);

    useEffect(() => {
        if (!hasData) return;
        const nodePath = dot.findPathByAttr(data, 'name', props.id);
        const nodeBranch = buildPathTree(data, nodePath);
        getAutomationVariables(nodeBranch?.scene)
            .then((result) => {
                const fieldsMap = {};
                result?.variables?.event?.data?.sections?.forEach((section) => {
                    section.fields.forEach?.((field) => {
                        fieldsMap[field.value] = field;
                    }) || [];
                });

                result?.variables?.related?.forEach((related) => {
                    related.sections?.forEach((section) => {
                        section.fields.forEach?.((field) => {
                            fieldsMap[field.value] = field;
                        }) || [];
                    });
                });

                setFieldsMap(fieldsMap);
            })
            .catch((e) => {
                console.error(e);
                errorToast({ text: getLiteral('error_generalerror') });
            });
    }, [data, hasData, props.id]);

    const conditions = useMemo(() => {
        if (!hasData || !fieldsMap) return null;
        const conditions = nodeData?.parameters?.conditions;
        const groupsBoolLogic = Object.keys(conditions)?.[0];
        const checkLast = (idx, arr) => idx < arr.length - 1;

        return conditions[groupsBoolLogic].map((group, index) => {
            const conditionBoolLogic = Object.keys(group)?.[0];

            const hasConditions = !!group[conditionBoolLogic]?.length;

            return (
                <>
                    <SectionForm
                        className="fm-automation-builder__condition-box__groups"
                        title={`${getLiteral('label_conditions_group')} ${index + 1}`}
                        isExpandable={false}
                    >
                        {!hasConditions && (
                            <Text type="subtitle">
                                {getLiteral('action_automation_add_condition')}
                            </Text>
                        )}
                        {hasConditions &&
                            group[conditionBoolLogic].map((condition, index2) => {
                                const operator = Object.keys(condition)?.[0];
                                const { variable } = getOperatorConditionValues(condition);

                                if (!condition[operator]) {
                                    return (
                                        <Text type="subtitle">
                                            {getLiteral('label_placeholder_field')}
                                        </Text>
                                    );
                                } else {
                                    return (
                                        <>
                                            <Text type="subtitle">
                                                {getLiteral(fieldsMap[variable?.var]?.literal)}
                                            </Text>
                                            <div className="fm-automation-builder__condition-box__row-value">
                                                <Text color="neutral700">
                                                    {getLiteral(
                                                        CONDITION_OPERATOR_LOCALES[operator],
                                                    )}
                                                    :
                                                </Text>
                                                <ConditionBoxValue
                                                    field={fieldsMap[variable?.var]}
                                                    condition={condition}
                                                    operator={operator}
                                                />
                                            </div>
                                            {checkLast(index2, group[conditionBoolLogic]) && (
                                                <div className="fm-automation-builder__condition-box__row-logic">
                                                    <Chip isFilled>
                                                        {getLiteral(
                                                            CONDITION_BOOL_LOGIC_LOCALES[
                                                                conditionBoolLogic
                                                            ],
                                                        )}
                                                    </Chip>
                                                </div>
                                            )}
                                        </>
                                    );
                                }
                            })}
                    </SectionForm>
                    {checkLast(index, conditions[groupsBoolLogic]) && (
                        <div className="fm-automation-builder__condition-box__row-logic">
                            <Chip isFilled>
                                {getLiteral(CONDITION_BOOL_LOGIC_LOCALES[groupsBoolLogic])}
                            </Chip>
                        </div>
                    )}
                </>
            );
        });
    }, [fieldsMap, hasData, nodeData?.parameters?.conditions]);

    return (
        <>
            <Handle type="target" position={Position.Top} id="top" isConnectable={false} />
            <AutomationBuilderBox
                {...props}
                className={classnames('fm-automation-builder__condition-box', {
                    'fm-automation-builder__condition-box--filled': hasData,
                })}
                title={getLiteral('label_condition')}
                icon="deviceHub"
                onDelete={hasData ? onDelete : undefined}
                deleteTooltip={getLiteral('action_delete_conditions')}
                emptyPlaceholder={getLiteral('action_automation_add_condition')}
            >
                {conditions}
            </AutomationBuilderBox>
            <Handle type="source" position={Position.Bottom} id="bottom" isConnectable={false} />
            <DeleteModal
                overlayClassName="fm-automation-builder__modal"
                title={getLiteral('label_delete_all_conditions')}
                body={getLiteral('label_delete_all_conditions_desc')}
                confirmText={getLiteral('action_delete_all_conditions')}
                onRef={onDeleteRef}
                onDelete={onDeleteConfirm}
            />
        </>
    );
});

export default AutomationBuilderConditionBox;
