import React, { memo, useMemo, useCallback, useState, Fragment } from 'react';
import { Button, Text, useTheme } from 'hoi-poi-ui';
import { RichText } from '@web/web5';
import { getLiteral } from 'utils/getLiteral';
import { getEditorToolbar, defaultFrequentlyUsedEmoji } from '../../../utils/timeline';

const MessageEdit = memo(
    ({
        onEsc,
        onSubmit,
        value: valueProp,
        isDisabled,
        canSubmit,
        fetchSuggestions,
        emojiCache,
        setConfigWeb,
    }) => {
        const [value, setValue] = useState(() => valueProp || {});
        const [disabled, setDisabled] = useState(() => !canSubmit(valueProp));
        const theme = useTheme();

        const onChange = useCallback(
            (newValue) => {
                setValue(newValue || {});
                setDisabled(!canSubmit(newValue || {}));
            },
            [setValue, canSubmit],
        );

        const onEnter = useCallback(() => {
            let trimmedValue = value?.text?.trim?.();
            if (!trimmedValue) return;
            onSubmit && onSubmit({ text: value.text, html: value.html });
        }, [value, onSubmit]);

        const onCancel = useCallback(() => {
            onEsc && onEsc();
        }, [onEsc]);

        const buttonProps = useMemo(
            () => ({
                size: 'small',
                className: 'edit',
                isDisabled: isDisabled || disabled,
                onClick: onEnter,
            }),
            [isDisabled, onEnter, disabled],
        );

        const buttonCancelProps = useMemo(
            () => ({
                size: 'small',
                className: 'cancel',
                type: 'secondary',
                isDisabled: false,
                onClick: onCancel,
            }),
            [onCancel],
        );

        const editorProps = useMemo(
            () => ({
                toolbarStyle: 'fixed',
                mention: {
                    fetchSuggestions,
                    tooltip: getLiteral('label_mention'),
                    texts: {
                        advice: getLiteral('label_conversation_mention_advice'),
                        noResults: getLiteral('label_no_results'),
                    },
                    maxVisibleItems: 7,
                    hideNoResultsPopover: true,
                },
                emoji: {
                    tooltip: getLiteral('label_emoji'),
                    texts: {
                        noResults: getLiteral('label_no_results'),
                        search_placeholder: getLiteral('label_search'),
                        frequently_used_emoji: getLiteral('label_frequently_used'),
                    },
                    maxVisibleItems: 7,
                    cache: emojiCache,
                    saveCache: (payload) => setConfigWeb('emoji', payload),
                    defaultFrequentlyUsed: defaultFrequentlyUsedEmoji,
                },
                autofocus: true,
                isFullWidth: true,
                placeholder: getLiteral('placeholder_add_message'),
                onChange,
                onSubmit: onEnter,
                onEsc: onCancel,
                value: value?.html,
                customActions: (
                    <Fragment>
                        <Button {...buttonCancelProps}>{getLiteral('action_cancel')}</Button>
                        <Button {...buttonProps}>{getLiteral('action_save')}</Button>
                    </Fragment>
                ),
                overrides: {
                    editorWrapper: {
                        style: {
                            backgroundColor: theme.colors.primary.white,
                        },
                    },
                },
                toolbar: getEditorToolbar(),
                hideClear: true,
                chatbox: true,
            }),
            [
                buttonCancelProps,
                buttonProps,
                onCancel,
                onChange,
                onEnter,
                value,
                theme,
                fetchSuggestions,
                emojiCache,
                setConfigWeb,
            ],
        );

        return (
            <Fragment>
                <div className="fm-timeline-list__edit">
                    <RichText {...editorProps} />
                </div>
                {value?.text?.length > 3 && (
                    <div className="fm-timeline-list__caption">
                        <Text color={theme.colors.utility.textSecondary} type="caption" bold>
                            {`${getLiteral('label_shift_plus_enter')} `}
                        </Text>
                        <Text
                            className="fm-timeline-textarea__caption__text"
                            color={theme.colors.utility.textSecondary}
                            type="caption"
                        >
                            {getLiteral('label_to_add_new_line')}
                        </Text>
                    </div>
                )}
            </Fragment>
        );
    },
);

export default MessageEdit;
