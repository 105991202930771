import { memo, useMemo, useState, useCallback, useRef, useEffect } from 'react';
import { ReactFlow, Background, BackgroundVariant, Panel, useReactFlow } from '@xyflow/react';
import { useTheme } from 'hoi-poi-ui';

import AutomationBuilderTriggerBox from './types/AutomationBuilderTriggerBox';
import AutomationBuilderConditionsBox from './types/AutomationBuilderConditionsBox';
import AutomationBuilderActionBox from './types/AutomationBuilderActionBox';
import AutomationBuilderCanvasPanel from './AutomationBuilderCanvasPanel';

import dot from '../../../../../utils/dot';

const nodeTypes = {
    trigger: AutomationBuilderTriggerBox,
    conditions: AutomationBuilderConditionsBox,
    action: AutomationBuilderActionBox,
};

const AutomationBuilderCanvasFlow = memo(({ data, onChange }) => {
    const reactFlow = useReactFlow();
    const theme = useTheme();
    const proOptions = useMemo(() => ({ hideAttribution: true }), []);
    const defaultEdgeOptions = useMemo(() => ({ className: 'fm-automation-builder__edge' }), []);
    const dimensions = useRef();

    const [nodes, setNodes] = useState([
        {
            id: 'entity_crud',
            position: { x: 0, y: 0 },
            data: {},
            type: 'trigger',
        },
        {
            id: 'conditions_logic',
            position: { x: 0, y: 220 },
            data: {},
            type: 'conditions',
        },
        {
            id: 'update_entity_related',
            position: { x: 0, y: 440 },
            data: {},
            type: 'action',
        },
    ]);

    const [edges, _] = useState([
        {
            id: 'e-trigger-condition',
            source: 'entity_crud',
            target: 'conditions_logic',
        },
        {
            id: 'e-condition-action',
            source: 'conditions_logic',
            target: 'update_entity_related',
        },
    ]);

    const onNodesChange = useCallback(
        (changes) => {
            if (!dimensions.current && changes.length > 0) {
                dimensions.current = changes;
                changes.forEach((change, index) => {
                    const heightDiff = change?.dimensions?.height - 130;
                    // Updating next node box position
                    if (nodes[index + 1]) {
                        setNodes(
                            dot.set(
                                nodes,
                                `${index + 1}.position.y`,
                                nodes[index + 1].position.y + heightDiff,
                            ),
                        );
                    }
                });
            } else if (dimensions.current) {
                changes
                    .filter((change) => change?.dimensions)
                    .forEach((change) => {
                        const index = dimensions.current.findIndex(
                            (d) => d.id === change.id && d.type === 'dimensions',
                        );

                        const heightDiff =
                            change?.dimensions?.height -
                            dimensions.current[index]?.dimensions?.height;

                        if (index !== -1) dimensions.current[index] = change;

                        // Updating next node box position
                        if (nodes[index + 1] && heightDiff !== 0) {
                            setNodes(
                                dot.set(
                                    nodes,
                                    `${index + 1}.position.y`,
                                    nodes[index + 1].position.y + heightDiff,
                                ),
                            );
                        }
                    });
            }
        },
        [nodes],
    );

    useEffect(() => {
        setTimeout(() => {
            reactFlow.fitView({
                maxZoom: 1,
            });
        }, 100);
    }, [reactFlow]);

    return (
        <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            proOptions={proOptions}
            defaultEdgeOptions={defaultEdgeOptions}
            nodeTypes={nodeTypes}
            nodesDraggable={false}
            nodesConnectable={false}
            nodesFocusable={false}
            edgesFocusable={false}
            elementsSelectable={true}
            maxZoom={3}
            panOnScroll={true}
            panOnScrollSpeed={1}
        >
            <Panel position="bottom-right">
                <AutomationBuilderCanvasPanel
                    enabled={data?.enabled || false}
                    onChange={onChange}
                />
            </Panel>
            <Background color={theme.colors.grey[100]} size={3} variant={BackgroundVariant.Dots} />
        </ReactFlow>
    );
});

export default AutomationBuilderCanvasFlow;
