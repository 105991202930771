import React, { memo, useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { Chip, useTheme } from 'hoi-poi-ui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Context from 'managers/Context';
import { AGENDA, TASKS } from 'constants/Entities';
import { EntityFiltersActions } from 'actions';
import classnames from 'classnames';

import './styles.scss';

const mapStateToProps = (state) => {
    const agendaFilters = state.entityFilters?.[AGENDA.entity]?.filters || {};
    const agendaColors = state?.[AGENDA.entity]?.filtersColor?.filters || {};
    return {
        agendaFilters,
        agendaColors,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeFilter: bindActionCreators(EntityFiltersActions, dispatch).changeFilter,
    };
};

const FiltersRow = memo(({ agendaFilters, agendaColors, changeFilter }) => {
    const isFirstRenderRef = useRef(true);
    const [field, setField] = useState(null);
    const theme = useTheme();
    const [hoveredChip, setHoveredChip] = useState(null);

    useEffect(() => {
        if (isFirstRenderRef.current) {
            isFirstRenderRef.current = false;
            Context.entityListManager.getFilterSchema(AGENDA, (schema) => {
                const field = schema.filter((current) => current.id === 'idusuario')[0] || null;
                if (field) setField(field);
            });
        }
    });

    const onRemove = useCallback(
        (option) => {
            return () => {
                const filter = { ...agendaFilters?.['idusuario'] };
                const { value, completeValues, ...newFilter } = filter;
                const newValues = value.filter((current) => current !== option.value);
                const newCompleteValues = completeValues.filter(
                    (current) => current.value !== option.value,
                );
                const info = {
                    action: 'remove-value',
                    option,
                };

                changeFilter({
                    entity: AGENDA,
                    filter: newFilter,
                    value: newValues,
                    refresh: undefined,
                    completeValues: newCompleteValues,
                    isEntityList: null,
                    info,
                });

                changeFilter({
                    entity: TASKS,
                    filter: newFilter,
                    value: newValues,
                    refresh: undefined,
                    completeValues: newCompleteValues,
                    isEntityList: null,
                    info,
                });
            };
        },
        [agendaFilters, changeFilter],
    );

    const renderIdUsuarioChips = useMemo(() => {
        const idUsuarioValues = agendaFilters?.['idusuario']?.completeValues || [];
        const idUsuarioColors = agendaColors?.['idusuario']?.colors || null;

        if (!idUsuarioValues.length || !idUsuarioColors) {
            return null;
        }

        const chips = idUsuarioValues.map((current) => {
            const defaultColor = idUsuarioColors?.[current.value]?.default || '';
            const hoverColor = idUsuarioColors?.[current.value]?.hover || '';

            let chipStyles = null;

            if (defaultColor && hoverColor) {
                chipStyles = {
                    root: {
                        style: {
                            backgroundColor:
                                hoveredChip === current.value ? hoverColor : defaultColor,
                        },
                    },
                    Text: {
                        overrides: {
                            root: {
                                style: {
                                    color: theme.colors.primary.white,
                                },
                            },
                        },
                    },
                    CloseIcon: {
                        overrides: {
                            root: {
                                className: 'agenda-fiters-row__item-chip',
                            },
                        },
                    },
                };
            }

            return (
                <Chip
                    key={current.value}
                    className="agenda-filters-row__item"
                    size="small"
                    isFilled
                    overrides={chipStyles}
                    onRemove={idUsuarioValues.length === 1 ? null : onRemove(current)}
                    onMouseEnter={() => setHoveredChip(current.value)}
                    onMouseLeave={() => setHoveredChip(null)}
                >
                    {current.label}
                </Chip>
            );
        });

        return chips;
    }, [agendaFilters, agendaColors, onRemove, hoveredChip, theme.colors.primary.white]);

    const shouldShowFilters = useMemo(() => {
        const filtersLength = agendaFilters?.['idusuario']?.value?.length || 0;
        return filtersLength > 1 ? true : false;
    }, [agendaFilters]);

    const className = classnames('agenda-filters-row', {
        'agenda-filters-row__collapsed': !shouldShowFilters,
    });

    return <div className={className}>{field && shouldShowFilters && renderIdUsuarioChips}</div>;
});

export default connect(mapStateToProps, mapDispatchToProps)(FiltersRow);
