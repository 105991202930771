import React from 'react';
import colors from 'constants/colors';

const SalesRep = ({ color = colors.actionMinor[500] }) => (
    <svg viewBox={'0 0 18 18'} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.289 11.876H2.464c-.278 0-.464-.14-.464.093 0 1.206.789 3.758 1.392 6.031H15.36c.696-2.274 1.392-4.732 1.392-6.03 0-.233-.186-.094-.464-.094zm-7.98 5.382l.371-3.851h1.253l.371 3.85H8.31zm1.067-7.376c2.69 0 4.917-2.227 4.917-4.918C14.293 2.32 12.067 0 9.376 0c-2.69 0-4.918 2.227-4.918 4.918 0 2.69 2.227 4.964 4.918 4.964z"
            fill={color || '#335B70'}
            fillRule="evenodd"
        />
    </svg>
);

export default SalesRep;
