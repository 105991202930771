import { memo, useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { Link } from 'hoi-poi-ui';

import { getAutomationVariables } from 'services/AutomationsService';

import dot from 'utils/dot';
import { getLiteral } from 'utils/getLiteral';
import { errorToast } from 'utils/toast';
import { buildPathTree } from '../../utils';

import DeleteModal from 'components/DeleteModal';
import AutomationBuilderConditionLogicSelector from './AutomationBuilderConditionLogicSelector';
import AutomationBuilderSidePanel from '../AutomationBuilderSidePanel';
import AutomationBuilderConditionGroup from './AutomationBuilderConditionGroup';

import './styles.scss';

const AutomationBuilderConditionsSidePanel = memo(
    ({ data, node, nodePath, onChange, onClose, isTouched, setTouched }) => {
        const variables = useRef();
        const [fields, setFields] = useState([]);
        const deleteModalRef = useRef();
        const [conditionGroups, setConditionGroups] = useState(
            node?.parameters?.conditions || {
                and: [
                    {
                        and: [{}],
                    },
                ],
            },
        );

        useEffect(() => {
            const nodeBranch = buildPathTree(data, nodePath);
            getAutomationVariables(nodeBranch?.scene)
                .then((result) => {
                    let newFields = [];
                    if (result?.variables?.event?.data) {
                        newFields = result?.variables?.event?.data?.sections?.map((section) => ({
                            label: `${result?.variables?.event?.data?.description} - ${section.name}`,
                            options:
                                section.fields.map?.((field) => ({
                                    ...field,
                                    label: getLiteral(field.literal),
                                    value: field.value,
                                    original: field,
                                })) || [],
                        }));
                    }

                    if (result?.variables?.related) {
                        result?.variables?.related?.map((related) => {
                            newFields.push(
                                ...related.sections?.map((section) => ({
                                    label: `${result?.variables?.event?.data?.description} - ${section.name}`,
                                    options:
                                        section.fields.map?.((field) => ({
                                            ...field,
                                            label: getLiteral(field.literal),
                                            value: field.value,
                                            original: field,
                                        })) || [],
                                })),
                            );
                        });
                    }

                    variables.current = result;
                    setFields(newFields);
                })
                .catch((e) => {
                    console.error(e);
                    errorToast({ text: getLiteral('error_generalerror') });
                });
        }, [data, nodePath]);

        const onDone = useCallback(() => {
            onChange(nodePath, {
                ...node,
                name: 'conditions_logic',
                parameters: {
                    conditions: conditionGroups,
                },
                type: 'conditions',
            });
            setTouched(false);
        }, [onChange, nodePath, node, conditionGroups, setTouched]);

        const addConditionGroup = useCallback(() => {
            setTouched(true);
            setConditionGroups((current) => {
                let boolLogic = Object.keys(current)?.[0];
                return dot.set(current, boolLogic, (groups) => [...groups, { [boolLogic]: [{}] }]);
            });
        }, [setTouched]);

        const onRemoveConditionGroup = useCallback(
            (index) => {
                setTouched(true);
                setConditionGroups((current) => {
                    const boolLogic = Object.keys(current)?.[0];
                    return {
                        [boolLogic]: dot.delete(current[boolLogic], index),
                    };
                });
            },
            [setTouched],
        );

        const onChangeGroup = useCallback(
            (index, value) => {
                setTouched(true);
                setConditionGroups((current) => {
                    let boolLogic = Object.keys(current)?.[0];
                    return dot.set(current, `${boolLogic}.${index}`, value);
                });
            },
            [setTouched],
        );

        const onChangeBool = useCallback(
            (value) => {
                setTouched(true);
                setConditionGroups((current) => {
                    let boolLogic = Object.keys(current)?.[0];
                    return {
                        [value]: current[boolLogic],
                    };
                });
            },
            [setTouched],
        );

        const onDeleteRef = useCallback((ref) => {
            deleteModalRef.current = ref;
        }, []);

        const onDelete = useCallback(() => {
            deleteModalRef.current.open();
        }, []);

        const onDeleteConfirm = useCallback(() => {
            setTouched(false);
            setConditionGroups({ and: [{ and: [{}] }] });
            return Promise.resolve();
        }, [setTouched]);

        const conditionGroupsMap = useMemo(() => {
            const boolLogic = Object.keys(conditionGroups)?.[0];
            const checkLast = (idx) => idx < conditionGroups[boolLogic].length - 1;
            return conditionGroups[boolLogic].map((conditionGroup, idx) => (
                <>
                    <AutomationBuilderConditionGroup
                        key={idx}
                        index={idx}
                        conditionGroup={conditionGroup}
                        onChange={(value) => onChangeGroup(idx, value)}
                        onRemove={() => onRemoveConditionGroup(idx)}
                        fields={fields}
                        operators={variables.current?.operators}
                    />
                    {checkLast(idx) && (
                        <AutomationBuilderConditionLogicSelector
                            onChange={onChangeBool}
                            value={boolLogic}
                        />
                    )}
                </>
            ));
        }, [conditionGroups, fields, onChangeBool, onChangeGroup, onRemoveConditionGroup]);

        return (
            <AutomationBuilderSidePanel
                title={getLiteral('label_condition')}
                subtitle={getLiteral('label_conition_desc')}
                onClose={onClose}
                isDoneDisabled={!isTouched}
                onDone={onDone}
                onDelete={onDelete}
                deleteBtnText={getLiteral('action_delete_conditions')}
            >
                <div className="fm-automation-builder__condition-side-panel">
                    {conditionGroupsMap}
                    <Link variation="primary" onClick={addConditionGroup}>
                        + {getLiteral('cfm_action_add_group')}
                    </Link>
                </div>
                <DeleteModal
                    overlayClassName="fm-automation-builder__modal"
                    title={getLiteral('label_delete_all_conditions')}
                    body={getLiteral('label_delete_all_conditions_desc')}
                    confirmText={getLiteral('action_delete_all_conditions')}
                    onRef={onDeleteRef}
                    onDelete={onDeleteConfirm}
                />
            </AutomationBuilderSidePanel>
        );
    },
);

export default AutomationBuilderConditionsSidePanel;
