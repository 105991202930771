import React, { memo } from 'react';
import { Text, useTheme } from 'hoi-poi-ui';

import './styles.scss';

const Box = memo(({ title, value }) => {
    const theme = useTheme();
    return (
        <div className="box">
            <Text color={theme.colors.utility.textSecondary} type="overline">
                {title}
            </Text>
            <Text color={theme.colors.utility.textPrimary} type="subtitle" className="test">
                {value}
            </Text>
        </div>
    );
});
export default Box;
