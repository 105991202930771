import React from 'react';
import colors from 'constants/colors';

const OpportunityLost = ({
    color = colors.actionMinor[500],
    color2 = colors.semantic.negative450,
}) => (
    <svg viewBox={`0 0 32 32`}>
        <g fill="none" fillRule="evenodd">
            <path
                d="M19.544 27H6.5a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5H9v1.58c0 .232.224.42.5.42s.5-.188.5-.42V18h12v.365l-.347.144c-.996.393-2.13.585-4.277.585h-1v7.901h2.99c.06 0 .119.002.178.005zM22 17H10v-1.58c0-.232-.224-.42-.5-.42s-.5.188-.5.42V17H5.5a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5h21a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5H23v-1.58c0-.232-.224-.42-.5-.42s-.5.188-.5.42V17zm-9-8.987a.5.5 0 1 1-1 0C12 6.901 12.901 6 14.013 6h3.891c1.112 0 2.014.901 2.014 2.013a.5.5 0 1 1-1 0c0-.56-.454-1.013-1.014-1.013h-3.89C13.453 7 13 7.454 13 8.013z"
                fill={color || '#335B70'}
            />
            <path
                d="M30.646 21.734a.989.989 0 0 0-.995-.984h-.664a.99.99 0 0 0 .995-.984.989.989 0 0 0-.995-.983h-4.312c-2.655 0-1.991 1.312-7.299 1.312v5.9h1.99c2.323 0 4.977 2.112 4.977 5.245 0 1.037 1.99 1.168 1.99-.798 0-1.312-.663-3.791-.663-3.791h5.308a.99.99 0 0 0 .995-.983.99.99 0 0 0-.995-.984h-.664a.99.99 0 0 0 .995-.983.99.99 0 0 0-.995-.984h-.663c.55 0 .995-.44.995-.983z"
                fill={color2 || '#f04540'}
            />
        </g>
    </svg>
);

export default OpportunityLost;
