import React from 'react';
import ContentLoader from 'react-content-loader';

import './styles.scss';

const LocationPlaceholder = ({ height = 120, width = 512, theme, ...props }) => {
    const squareWidth = width * 0.25;
    const squareHeight = height * 0.72;
    const titleWidth = width * 0.63;
    const subtitleWidth = width * 0.34;

    const titlePosition = width * 0.3;

    return (
        <div className="fm-entity-location__placeholder">
            <ContentLoader
                height={height}
                width={width}
                speed={2}
                backgroundColor={theme.colors.grey[50]}
                foregroundColor={theme.colors.grey[100]}
                {...props}
            >
                <rect x="0" y="0" rx="5" ry="5" width={squareWidth} height={squareHeight} />
                <rect x={titlePosition} y="23" rx="4" ry="4" width={titleWidth} height="8" />
                <rect x={titlePosition} y="45" rx="3" ry="3" width={subtitleWidth} height="8" />
            </ContentLoader>
        </div>
    );
};

export default LocationPlaceholder;
