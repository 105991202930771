import React from 'react';
import colors from 'constants/colors';

const Opportunities = ({ color = colors.actionMinor[200] }) => (
    <svg viewBox={`0 0 32 32`}>
        <path
            d="M23 18h2.5a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-19a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5H9v1.58c0 .232.224.42.5.42s.5-.188.5-.42V18h12v1.58c0 .232.224.42.5.42s.5-.188.5-.42V18zm-1-1H10v-1.58c0-.232-.224-.42-.5-.42s-.5.188-.5.42V17H5.5a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5h21a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5H23v-1.58c0-.232-.224-.42-.5-.42s-.5.188-.5.42V17zm-9-8.987a.5.5 0 1 1-1 0C12 6.901 12.901 6 14.013 6h3.891c1.112 0 2.014.901 2.014 2.013a.5.5 0 1 1-1 0c0-.56-.454-1.013-1.014-1.013h-3.89C13.453 7 13 7.454 13 8.013z"
            fill={color || '#335B70'}
            fillRule="evenodd"
        />
    </svg>
);

export default Opportunities;
