import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getLiteral } from 'utils/getLiteral';
import { useTheme, Text } from 'hoi-poi-ui';

import EmptyImage from './EmptyImage';

import './styles.scss';

const propTypes = {
    img: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
};

const AbsoluteEmpty = memo(
    ({
        title = getLiteral('label_page_error_title'),
        subtitle = getLiteral('error_generalerror'),
        img,
        svg,
        size,
        actions,
    }) => {
        const className = classnames('fm-absolute-empty', {
            'fm-absolute-empty--popover': size === 'popover',
        });
        const theme = useTheme();

        const showDefaultSvg = !svg && !img;
        return (
            <div className={className}>
                {!svg && img && <img src={img} />}
                {svg}
                {showDefaultSvg && <EmptyImage />}
                <Text type="subtitle1">{title}</Text>
                <Text type="caption" color={theme.colors.utility.textSecondary}>
                    {subtitle}
                </Text>
                {actions?.length > 0 && <div className="fm-absolute-empty__actions">{actions}</div>}
            </div>
        );
    },
);

AbsoluteEmpty.propTypes = propTypes;

export default AbsoluteEmpty;
