import React, { memo, useState, useMemo, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { ReportActions } from 'actions';

import Literal from 'components/Literal'; // FIXME Please use getLiteral here
import Loader from 'components/Loader';
import { OPPORTUNITIES, SALESORDERS } from 'constants/Entities';
import { Reports } from 'components/SvgIcons';

import { WidgetEmpty } from 'containers/components/widgets/Layouts';
import ShareModal from 'containers/components/ShareModal';
import ErrorReportDialog from 'containers/components/dialog/ErrorReportDialog';
import ReportsParametersDialog from 'containers/reports/components/ReportsParametersDialog';
import ReportsTableModal from 'containers/reports/components/ReportsTableModal';
import SignatureForm from './components/SignatureForm';
import Row from './components/Row';
import AnalyticsReportModal from './components/AnalyticsReportModal';

import { getLiteral } from 'utils/getLiteral';
import ViewerModal from 'components/ViewerModal';
import { getDocumentToBlob } from 'services/DocumentsService';
import colors from 'constants/colors';

const propTypes = {
    entity: PropTypes.object,
    idEntity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    showTable: PropTypes.bool,
    hideTableReport: PropTypes.bool,
    showTableReport: PropTypes.func,
    showLoadingTableReportLink: PropTypes.func,
    detailId: PropTypes.string,
};

const mapStateToProps = (state) => {
    const modals = state.reports.modals;
    return {
        showTable: modals.showTable,
        report: state.reports.report,
        reportData: modals.reportData,
        openForm: modals.openForm,
        errorLink: modals.errorLink,
        errorForm: modals.errorForm,
        loadingForm: modals.loadingForm,
        loadingLink: modals.loadingLink,
        loadingParametersDialog: modals.loadingParametersDialog,
        showParametersDialog: modals.showParametersDialog,
        showShareDialog: modals.showShareDialog,
        showGetTableReportError: modals.showGetTableReportError,
        showSignDialog: modals.showSignDialog,
        link: modals.link,
        fieldsForm: modals.fieldsForm,
        parametersSchema: modals.parametersSchema,
        action: modals.action,
        reportParams: modals.reportParams,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        hideGetTableReportError: bindActionCreators(ReportActions, dispatch)
            .hideGetTableReportError,
        openSignatureErrorDialog: bindActionCreators(ReportActions, dispatch)
            .openSignatureErrorDialog,
        downloadReport: bindActionCreators(ReportActions, dispatch).downloadReport,
        openDialogTableReports: bindActionCreators(ReportActions, dispatch).openDialogTableReports,
        exportTableReport: bindActionCreators(ReportActions, dispatch).exportTableReport,
        loadSignDialog: bindActionCreators(ReportActions, dispatch).loadSignDialog,
        addSigner: bindActionCreators(ReportActions, dispatch).addSigner,
        removeSigner: bindActionCreators(ReportActions, dispatch).removeSigner,
        sendForm: bindActionCreators(ReportActions, dispatch).sendForm,
        closeForm: bindActionCreators(ReportActions, dispatch).closeForm,
        closeShareReport: bindActionCreators(ReportActions, dispatch).closeShareReport,
        closeDownloadReport: bindActionCreators(ReportActions, dispatch).closeDownloadReport,
        closeReportTable: bindActionCreators(ReportActions, dispatch).closeReportTable,
        closeReportParametersDialog: bindActionCreators(ReportActions, dispatch)
            .closeReportParametersDialog,
        openSignReport: bindActionCreators(ReportActions, dispatch).openSignReport,
        closeSignReport: bindActionCreators(ReportActions, dispatch).closeSignReport,
        shareReport: bindActionCreators(ReportActions, dispatch).shareReport,
        getReportLink: bindActionCreators(ReportActions, dispatch).getReportLink,
        finishSendForm: bindActionCreators(ReportActions, dispatch).finishSendForm,
        openShareReport: bindActionCreators(ReportActions, dispatch).openShareReport,
    };
};

const ReportsWidgetContent = memo(
    ({
        entity,
        entityName,
        idEntity,
        showTable,
        report,
        reportData,
        errorLink,
        loadingLink,
        loadingParametersDialog,
        showParametersDialog,
        showShareDialog,
        showSignDialog,
        link,
        parametersSchema,
        action,
        reportParams,
        openDialogTableReports,
        closeReportParametersDialog,
        closeReportTable,
        downloadReport,
        exportTableReport,
        closeShareReport,
        closeDownloadReport,
        closeSignReport,
        shareReport,
        getReportLink,
        openSignReport,
        openShareReport,
        loading,
        data,
        error,
    }) => {
        const [viewerData, setViewerData] = useState(null);
        const [strParametersValues, setStrParametersValues] = useState(null);
        const analyticsReportModal = useRef();

        const renderShareDialog = useMemo(() => {
            if (showShareDialog) {
                if (!errorLink) {
                    return (
                        <ShareModal
                            subject={getLiteral('label_share_report')}
                            link={link}
                            isOpen={showShareDialog}
                            isLoading={loadingLink}
                            onClose={closeShareReport}
                        />
                    );
                } else {
                    return <ErrorReportDialog show={true} onClose={closeShareReport} />;
                }
            }
        }, [closeShareReport, errorLink, link, loadingLink, showShareDialog]);

        const onShareReport = useCallback(
            (report, parameters) => {
                const sendMail = action === 'email';
                shareReport(report, parameters, entity, idEntity, sendMail);
            },
            [action, shareReport, entity, idEntity],
        );

        const onDownloadReport = useCallback(
            (report, parameters) => {
                downloadReport(report, parameters, entity, idEntity);
            },
            [downloadReport, entity, idEntity],
        );

        const openExternalReport = useCallback(
            (report) => {
                try {
                    const externalReport = JSON.parse(report.providerAttributes);
                    analyticsReportModal.current.open({
                        id: externalReport.id,
                        entityName,
                        title: report.name,
                        idProvider: report.idProvider,
                    });
                } catch (e) {
                    console.error(e);
                }
            },
            [entityName],
        );

        const onCloseViewer = useCallback(() => {
            setViewerData(null);
        }, []);

        const handleOpenPreview = useCallback(
            (data, parameters) => {
                if (parameters?.documentFormat?.toLowerCase() !== 'pdf') {
                    onDownloadReport(data, parameters);
                    return;
                }
                closeReportParametersDialog();
                setViewerData({ data, parameters });

                let fieldName = 'IdEmpresa';

                switch (entity.trueName) {
                    case OPPORTUNITIES.trueName:
                        fieldName = 'IdExpediente';
                        break;
                    case SALESORDERS.trueName:
                        fieldName = 'IdSalesOrder';
                    default:
                        break;
                }

                setStrParametersValues({ ...parameters, [fieldName]: idEntity });
            },
            [closeReportParametersDialog, entity, idEntity, onDownloadReport],
        );

        const handleDownloadPreview = useCallback(() => {
            onDownloadReport(viewerData.data, viewerData.parameters, entity, idEntity);
            onCloseViewer();
        }, [onDownloadReport, viewerData, onCloseViewer, entity, idEntity]);

        const handleSign = useCallback(() => {
            openSignReport(report);
            onCloseViewer();
        }, [onCloseViewer, openSignReport, report]);

        const getFileUrl = useCallback(() => {
            return new Promise((resolve, reject) => {
                getReportLink(viewerData.data, viewerData.parameters, entity, idEntity)
                    .then((url) => getDocumentToBlob(url, viewerData?.parameters?.documentFormat))
                    .then((blob) => {
                        resolve(blob);
                    })
                    .catch((error) => {
                        console.error(`Couldn't get the file url`);
                        reject(error);
                    });
            });
        }, [getReportLink, viewerData, entity, idEntity]);

        const renderParametersDialog = useMemo(() => {
            if (showParametersDialog) {
                const isShare = ['share', 'email'].includes(action);

                if (!errorLink) {
                    let completeMethod = isShare ? onShareReport : handleOpenPreview;

                    if (report.isCrystal !== '1')
                        completeMethod = (...args) =>
                            openDialogTableReports(...args, entity, idEntity);

                    return (
                        <ReportsParametersDialog
                            show={showParametersDialog}
                            onClose={closeReportParametersDialog}
                            schema={parametersSchema}
                            onCompleteReport={completeMethod}
                            report={report}
                            action={action}
                            loading={loadingParametersDialog}
                        />
                    );
                } else {
                    const closeAction = isShare ? closeReportParametersDialog : closeDownloadReport;
                    return <ErrorReportDialog show={true} onClose={closeAction} />;
                }
            }
        }, [
            showParametersDialog,
            errorLink,
            action,
            onShareReport,
            handleOpenPreview,
            report,
            closeReportParametersDialog,
            parametersSchema,
            loadingParametersDialog,
            openDialogTableReports,
            entity,
            idEntity,
            closeDownloadReport,
        ]);

        const renderReportsTableModal = useMemo(() => {
            if (showTable) {
                if (!reportData.error) {
                    return (
                        <ReportsTableModal
                            showTable={showTable}
                            reportData={reportData}
                            report={report}
                            reportParams={reportParams}
                            exportTableReport={exportTableReport}
                            closeReportTable={closeReportTable}
                        />
                    );
                } else {
                    return (
                        <ErrorReportDialog
                            show={true}
                            onClose={closeReportTable}
                            error={reportData.errorMessage}
                        />
                    );
                }
            }
        }, [closeReportTable, exportTableReport, report, reportData, reportParams, showTable]);

        const renderSignDialog = useMemo(() => {
            if (showSignDialog) {
                return (
                    <SignatureForm
                        open={showSignDialog}
                        report={report}
                        onClose={closeSignReport}
                        entity={entity}
                        idEntity={idEntity}
                        strParametersValues={strParametersValues}
                    />
                );
            }
        }, [showSignDialog, report, closeSignReport, entity, idEntity, strParametersValues]);

        const renderLoading = useMemo(() => {
            return (
                <div className="widget-loader">
                    <Loader type="small" />
                </div>
            );
        }, []);

        const fileDate = useMemo(() => {
            const data = viewerData?.data || null;
            if (data?.fmodificado) return data?.fmodificado.split(' ')[0];
            return data?.fcreado?.split(' ')[0] || '';
        }, [viewerData]);

        const fullScreenEventPayload = useMemo(
            () => ({
                event: entity.trueName,
                submodule: 'reportsWidget',
                functionality: 'fullScreenView',
            }),
            [entity.trueName],
        );

        const renderData = useMemo(() => {
            const viewerModalProps = {
                useHeader: true,
                isOpen: !!viewerData?.data,
                onRequestClose: onCloseViewer,
                size: 'large',
                fileFormat: viewerData?.parameters?.documentFormat,
                fileName: viewerData?.data?.name,
                fileDate,
                getFileUrl,
                onConfirm: action === 'sign' ? handleSign : handleDownloadPreview,
                onConfirmError: onCloseViewer,
                confirmText: getLiteral(action === 'sign' ? 'action_sign' : 'action_download'),
                confirmErrorText: getLiteral('label_accept'),
                isReport: true,
                fullScreenEventPayload,
            };
            return (
                <div className="fm-widget-content">
                    <div className="report-row-header">
                        <div className="report-row-header__column">
                            <Literal literal="label_report_name" />
                        </div>
                        <div className="report-row-header__column">
                            <Literal literal="label_date_created" />
                        </div>
                    </div>
                    <div className="widget-content-table">
                        {data.map((current, index) => {
                            return (
                                <Row
                                    key={index}
                                    report={current}
                                    openShareReport={openShareReport}
                                    openSignReport={openSignReport}
                                    openExternalReport={openExternalReport}
                                    entity={entity}
                                    idEntity={idEntity}
                                />
                            );
                        })}
                        {renderShareDialog}
                        {renderParametersDialog}
                        {renderReportsTableModal}
                        {renderSignDialog}
                        {viewerData?.data && <ViewerModal {...viewerModalProps} />}
                    </div>
                    <AnalyticsReportModal
                        onRef={(ref) => (analyticsReportModal.current = ref)}
                        entity={entity.trueName}
                        entityId={idEntity}
                    />
                </div>
            );
        }, [
            viewerData?.data,
            viewerData?.parameters?.documentFormat,
            onCloseViewer,
            fileDate,
            getFileUrl,
            action,
            handleSign,
            handleDownloadPreview,
            fullScreenEventPayload,
            data,
            renderShareDialog,
            renderParametersDialog,
            renderReportsTableModal,
            renderSignDialog,
            entity,
            idEntity,
            openShareReport,
            openSignReport,
            openExternalReport,
        ]);

        const renderEmpty = useMemo(() => {
            return (
                <WidgetEmpty
                    icon={<Reports color={colors.grey[100]} />}
                    text={<Literal literal="error_there_are_no_reports" />}
                />
            );
        }, []);

        let content;
        if (loading) {
            content = renderLoading;
        } else if (!error && data && data.length > 0) {
            content = renderData;
        } else {
            content = renderEmpty;
        }

        return content;
    },
);

ReportsWidgetContent.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(ReportsWidgetContent);
