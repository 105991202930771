import React, { memo, useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Icon, useTheme, Tooltip } from 'hoi-poi-ui';
import classnames from 'classnames';
import { getLiteral } from 'utils/getLiteral';

import './styles.scss';

const mapStateToProps = (state) => {
    return { locale: state?.config?.userData?.locale || 'es-ES' };
};

const SpeechButton = memo(({ editor, locale, isDisabled, setIsListening, isListening }) => {
    const [isOver, setIsOver] = useState(false);
    const recognitionRef = useRef(null);
    const isFirstRender = useRef(true);

    const theme = useTheme();

    const isSpeechRecognitionSupported =
        'SpeechRecognition' in window || 'webkitSpeechRecognition' in window;

    useEffect(() => {
        if (isFirstRender?.current) {
            isFirstRender.current = false;
            if (!isSpeechRecognitionSupported) return;
            recognitionRef.current =
                new window.webkitSpeechRecognition() || new SpeechRecognition();
            recognitionRef.current.lang = locale || 'es-Es'; // Set language
            recognitionRef.current.interimResults = true; // Returns partial results so you see the writting effect
        }
    }, [locale, isSpeechRecognitionSupported]);

    const startListening = useCallback(() => {
        recognitionRef.current.onstart = () => {
            setIsListening(true);
        };

        recognitionRef.current.onresult = (event) => {
            const transcript = event.results[0][0].transcript;
            const textCapitalized = transcript.charAt(0).toUpperCase() + transcript.slice(1);
            if (editor?.commands) {
                editor.commands.setContent(textCapitalized);
                const onUpdate = editor?.callbacks?.update?.[0] || null;
                if (onUpdate) onUpdate({ editor });
            }
        };

        recognitionRef.current.onend = (props) => {
            setIsListening(false);
            if (editor?.commands) editor.commands.focus();
        };

        recognitionRef.current.start();
    }, [editor, setIsListening]);

    const stopListening = useCallback(() => {
        setIsListening(false);
        recognitionRef.current.abort();
    }, [setIsListening]);

    const handleOnClick = useCallback(() => {
        if (isDisabled) return null;
        if (isListening) return stopListening();
        return startListening();
    }, [isDisabled, isListening, startListening, stopListening]);

    const displayMicOutline = useMemo(() => {
        if (isListening) return false;
        if (isDisabled) return true;
        if (!isOver) return true;
    }, [isListening, isDisabled, isOver]);

    const displayMic = useMemo(() => {
        if (!displayMicOutline && !isListening) return true;
        return false;
    }, [displayMicOutline, isListening]);

    const onMouseOver = useCallback((e) => {
        e.stopPropagation();
        setIsOver(true);
    }, []);

    const onMouseOut = useCallback((e) => {
        e.stopPropagation();
        setIsOver(false);
    }, []);

    if (!isSpeechRecognitionSupported) return null;

    return (
        <Tooltip placement="top" content={getLiteral('label_speak')}>
            <div
                className={classnames('dana-chat-speech', {
                    'dana-chat-speech--disabled': isDisabled,
                })}
                onClick={handleOnClick}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
            >
                {displayMicOutline && (
                    <Icon
                        name="micOutline"
                        size="large"
                        color={
                            !isDisabled
                                ? theme.colors.actionMajor[500]
                                : theme.colors.actionMinor[500]
                        }
                    />
                )}
                {displayMic && (
                    <Icon
                        name="mic"
                        size="large"
                        color={
                            !isDisabled
                                ? theme.colors.actionMajor[500]
                                : theme.colors.actionMinor[500]
                        }
                    />
                )}
                {isListening && (
                    <Icon name="stopRecording" size="large" color={theme.colors.semantic.info500} />
                )}
            </div>
        </Tooltip>
    );
});

export default connect(mapStateToProps)(SpeechButton);
