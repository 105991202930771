import { memo, useCallback, useRef, useState, useEffect, useMemo } from 'react';
import { Text, SearchBar, Spacer, Tabs, Icon, useTheme, Tooltip } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import { stripDiacritics } from 'utils/strings';

import AbsoluteEmpty from 'components/AbsoluteEmpty';
import AbsoluteError from 'components/AbsoluteError';
import AbsoluteLoader from 'components/AbsoluteLoader';
import { getVariables } from 'services/MailingService';

const ENTITY_MAP = {
    companies: {
        label: 'label_account_fields',
        icon: 'accounts',
    },
    contacts: { label: 'label_contact_fields', icon: 'emptyAvatar' },
};

const VariablesPopover = ({ onApply }) => {
    const theme = useTheme();
    const [searchInput, setSearchInput] = useState();
    const [variables, setVariables] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState();
    const [tab, setTab] = useState();
    const debounce = useRef(null);
    const cachedData = useRef({});

    const getVariablesList = useCallback(() => {
        setLoading(true);
        getVariables()
            .then(({ data }) => {
                cachedData.current = data || [];
                setVariables(data || []);
                if (data && Object.keys(data)?.length) {
                    setTab(Object.keys(data)?.[0]);
                }
            })
            .catch((e) => {
                console.error(e);
                setError(true);
            })
            .finally(() => setLoading(false));
    }, []);

    const searchByText = useCallback((text) => {
        clearTimeout(debounce.current);
        if (!text) return setVariables(cachedData.current);
        const rawText = stripDiacritics(text?.toLowerCase()?.trim());
        debounce.current = setTimeout(() => {
            setVariables(
                Object.keys(cachedData.current).reduce((obj, entityKey) => {
                    if (!cachedData.current[entityKey]) return obj;
                    obj[entityKey] = cachedData.current[entityKey].filter((variable) =>
                        stripDiacritics(
                            (getLiteral(variable?.label) || '')?.toLowerCase()?.trim(),
                        ).includes(rawText),
                    );
                    return obj;
                }, {}),
            );
        }, 250);
    }, []);

    const onChangeSearch = useCallback(
        (e) => {
            setSearchInput(e?.target?.value || '');
            searchByText(e?.target?.value || '');
        },
        [searchByText],
    );

    const tabs = useMemo(() => {
        if (!variables) return null;
        return (
            <Tabs
                onChange={setTab}
                activeKey={tab}
                tabs={Object.keys(variables).map((entityKey) => ({
                    key: entityKey,
                    title: (
                        <Tooltip placement="top" content={getLiteral(ENTITY_MAP[entityKey].label)}>
                            <span>
                                <Icon
                                    className="fm-ee__variables-popover__tab-icon"
                                    name={ENTITY_MAP[entityKey].icon}
                                    color={
                                        tab === entityKey
                                            ? theme.colors.textLight.primary
                                            : theme.colors.grey[500]
                                    }
                                />
                            </span>
                        </Tooltip>
                    ),
                }))}
            />
        );
    }, [tab, theme.colors.grey, theme.colors.textLight.primary, variables]);

    const list = useMemo(() => {
        if (!variables) return null;
        if (!variables?.[tab]?.length)
            return (
                <AbsoluteEmpty
                    title={getLiteral('title_variables_not_found')}
                    subtitle={getLiteral('label_search_variables_not_found')}
                    size="popover"
                />
            );
        return (
            <div className="fm-ee__variables-popover__list">
                {variables?.[tab]?.map((item) => (
                    <Text onClick={() => onApply(`{{ .${tab}.${item.id} }} `)}>
                        {getLiteral(item.label)}
                    </Text>
                ))}
            </div>
        );
    }, [onApply, tab, variables]);

    useEffect(() => {
        getVariablesList();
    }, [getVariablesList]);

    const showEmptyView = !loading && !error && !variables;
    const showErrorView = !loading && error && !variables;

    return (
        <div className="fm-ee__variables-popover__inner">
            <div className="fm-ee__variables-popover__header">
                <Text type="subtitle1">{getLiteral('title_insert_fields')}</Text>
            </div>
            <div className="fm-ee__variables-popover__search-bar">
                <Spacer y={1} />
                <SearchBar
                    placeholder={getLiteral('placeholder_search_field')}
                    isFullWidth
                    inputValue={searchInput}
                    onChange={onChangeSearch}
                    useAsSimpleSearch
                />
            </div>
            <div className="fm-ee__variables-popover__variables">
                {tabs}
                {list}
                {loading && <AbsoluteLoader label={getLiteral('label_loading_variables')} />}
                {showEmptyView && (
                    <AbsoluteEmpty
                        title={getLiteral('title_variables_not_found')}
                        subtitle={getLiteral('label_no_variables_found')}
                        size="popover"
                    />
                )}
                {showErrorView && (
                    <AbsoluteError
                        title={getLiteral('label_page_error_title')}
                        subtitle={getLiteral('label_no_variables_found')}
                        size="popover"
                    />
                )}
            </div>
        </div>
    );
};

export default memo(VariablesPopover);
