import React from 'react';
import colors from 'constants/colors';

const Link = ({ color = colors.actionMinor[500] }) => (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
                d="M6.08427097,10.2157149 L10.6056883,5.22505348 C10.8226201,4.98560792 11.1520208,4.98560792 11.3687068,5.22493539 C11.6117601,5.49304186 11.61171,5.94615943 11.3688138,6.21426402 L5.26101379,12.9770835 C4.62916357,13.6745078 3.62624013,13.6745078 2.99361164,12.976447 C2.33543606,12.2497317 2.33543606,11.0532189 2.99366511,10.3266772 L9.37646004,3.2817532 C10.3205574,2.23943246 11.8286738,2.23943246 12.7727254,3.28170261 C13.7424119,4.35202569 13.7424119,6.10624419 12.7727642,7.17652436 L8.25161289,12.1668922 C8.06620876,12.3715381 8.08180725,12.687736 8.28645309,12.8731402 C8.49109893,13.0585443 8.80729691,13.0429458 8.99270104,12.8383 L13.5138524,7.84793211 C14.8287159,6.39660876 14.8287159,4.06166112 13.5138524,2.61033777 C12.1728997,1.1298731 9.97633153,1.1298731 8.63533998,2.61038069 L2.25258392,9.65526175 C1.24914992,10.7628347 1.24914992,12.539994 2.25252351,13.6478548 C3.28218994,14.7840189 4.9733408,14.7840189 6.0026243,13.6479138 L12.1104243,6.88509428 C12.6980175,6.23652009 12.6981319,5.20250821 12.109795,4.55352774 C11.4963987,3.8760378 10.4784963,3.8760378 9.86460012,4.55364573 L5.34318283,9.54430713 C5.1577787,9.74895297 5.17337719,10.0651509 5.37802303,10.2505551 C5.58266887,10.4359592 5.89886684,10.4203607 6.08427097,10.2157149 Z"
                fill={color || '#335B70'}
            />
        </g>
    </svg>
);

export default Link;
