import React from 'react';
import colors from 'constants/colors';

const ProgressCancel = ({ color = colors.actionMinor[500] }) => (
    <svg viewBox={`0 0 32 32`} xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
            <rect
                width="26"
                height="26"
                rx="13"
                transform="translate(3 3)"
                stroke={color || '#335B70'}
                strokeWidth="1.586"
            />
            <path
                d="M16 15.116l3.933-3.933a.625.625 0 0 1 .884.884L16.884 16l3.933 3.933a.625.625 0 0 1-.884.884L16 16.884l-3.933 3.933a.625.625 0 0 1-.884-.884L15.116 16l-3.933-3.933a.625.625 0 0 1 .884-.884L16 15.116z"
                fill={color || '#335B70'}
                fillRule="nonzero"
            />
        </g>
    </svg>
);

export default ProgressCancel;
