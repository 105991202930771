import React from 'react';
import {
    Opportunities,
    OpportunityExpiring,
    OpportunityExpired,
    OpportunityWon,
    OpportunityLost,
} from 'components/SvgIcons';
import colors from 'constants/colors';

const OpportunityIcon = ({ won, lost, expirationDays, className }) => {
    let icon = <Opportunities />;

    if (won && (won === true || parseInt(won, 10) === 1)) {
        icon = <OpportunityWon color={colors.actionMinor[200]} />;
    } else if (lost && (lost === true || parseInt(lost, 10) === 1)) {
        icon = <OpportunityLost color={colors.actionMinor[200]} />;
    } else {
        if (expirationDays !== null && expirationDays <= 7) {
            if (expirationDays <= -100 || expirationDays <= -1) {
                icon = <OpportunityExpired />;
            } else if (expirationDays >= 0) {
                icon = <OpportunityExpiring />;
            }
        }
    }

    return <div className={[className, 'entity-icon'].join(' ')}>{icon}</div>;
};

export default OpportunityIcon;
