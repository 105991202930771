import React, { memo } from 'react';
import { Text, Icon, useTheme } from 'hoi-poi-ui';

const TotalTitle = memo(({ label, icon, count = 0 }) => {
    const theme = useTheme();
    return (
        <div className="fm-activity-detail__total-title">
            <Text type="subtitle">{label}</Text>
            <div className="fm-activity-detail__total-title-count">
                <Icon
                    name={icon}
                    color={count > 0 ? theme.colors.semantic.info400 : theme.colors.grey[200]}
                />
                <Text type="badges">{count}</Text>
            </div>
        </div>
    );
});

export default TotalTitle;
