import React, { useState, memo, useCallback, useMemo } from 'react';
import { useTheme } from 'hoi-poi-ui';
import CONSTANTS from '../constants';
import Header from './SageSalesGraph/Header';
import LineChart from 'components/charts/LineChart';
import { nFormatter } from 'utils/numbers';

import './styles.scss';

const WIDTH_CHART = 100;
const HEIGHT_CHART = 20;

const SageSalesGraph = memo(({ sales, sageSolution }) => {
    const [period, setPeriod] = useState(CONSTANTS.PERIODOS.MENSUAL());
    const theme = useTheme();

    // Period functions
    const onChangePeriod = useCallback(
        (value) => setPeriod(value || CONSTANTS.PERIODOS.MENSUAL()),
        [],
    );

    // Chart functions
    const setLabelsChart = useCallback(() => {
        let labels = [];
        switch (period?.value) {
            case CONSTANTS.PERIODOS.TRIMESTRAL()?.value:
                // QUARTERLY (current Q - 11 before)
                let quarters_inv = ['Q4', 'Q3', 'Q2', 'Q1'];
                let actualMonth = new Date().getMonth();
                let actualQuarter;
                if (actualMonth === 0 || actualMonth === 1 || actualMonth === 2)
                    actualQuarter = 'Q1';
                else if (actualMonth === 3 || actualMonth === 4 || actualMonth === 5)
                    actualQuarter = 'Q2';
                else if (actualMonth === 6 || actualMonth === 7 || actualMonth === 8)
                    actualQuarter = 'Q3';
                else if (actualMonth === 9 || actualMonth === 10 || actualMonth === 11)
                    actualQuarter = 'Q4';

                let indexQ = quarters_inv.indexOf(actualQuarter);
                for (let i = 11 + indexQ; i >= indexQ; i--) {
                    labels.push(
                        quarters_inv[i % 4] +
                            '-' +
                            (new Date().getFullYear().toString().slice(-2) - Math.floor(i / 4)),
                    );
                }
                return labels;
            case CONSTANTS.PERIODOS.ANUAL()?.value:
                // YEARLY (current year - 4 before)
                for (let i = 4; i >= 0; i--) {
                    let today = new Date();
                    let newYear = new Date(today.setFullYear(today.getFullYear() - i));
                    labels.push(newYear.getFullYear());
                }
                return labels;
            default:
                // MONTHLY (current month - 11 before)
                for (let i = 11; i >= 0; i--) {
                    let today = new Date();
                    let newMonth = new Date(today.setMonth(today.getMonth() - i));
                    labels.push(
                        CONSTANTS.MESES()[newMonth.getMonth()] +
                            ' - ' +
                            newMonth.getFullYear().toString().slice(-2),
                    );
                }
                return labels;
        }
    }, [period]);

    const setDataChart = useCallback(() => {
        let data = [];
        switch (period?.value) {
            case CONSTANTS.PERIODOS.TRIMESTRAL()?.value:
                // QUARTERLY (current Q - 11 before)
                let quarters_inv = ['Q4', 'Q3', 'Q2', 'Q1'];
                let actualMonth = new Date().getMonth();
                let actualQuarter;
                if (actualMonth === 0 || actualMonth === 1 || actualMonth === 2)
                    actualQuarter = 'Q1';
                else if (actualMonth === 3 || actualMonth === 4 || actualMonth === 5)
                    actualQuarter = 'Q2';
                else if (actualMonth === 6 || actualMonth === 7 || actualMonth === 8)
                    actualQuarter = 'Q3';
                else if (actualMonth === 9 || actualMonth === 10 || actualMonth === 11)
                    actualQuarter = 'Q4';

                let indexQ = quarters_inv.indexOf(actualQuarter);
                for (let i = 11 + indexQ; i >= indexQ; i--) {
                    let totalAmount = 0;
                    let salesFiltered = sales?.filter(
                        (item) =>
                            CONSTANTS.QUARTER[quarters_inv[i % 4]].includes(
                                new Date(item.date).getMonth(),
                            ) &&
                            new Date(item.date).getFullYear() ===
                                new Date().getFullYear() - Math.floor(i / 4),
                    );
                    salesFiltered?.forEach((item) => {
                        totalAmount += item.amount;
                    });
                    data.push(totalAmount);
                }
                return data;
            case CONSTANTS.PERIODOS.ANUAL()?.value:
                // YEARLY (current year - 4 before)
                for (let i = 4; i >= 0; i--) {
                    let today = new Date();
                    let newDate = new Date(today.setFullYear(today.getFullYear() - i));
                    let salesMonth = sales?.filter(
                        (item) => new Date(item.date).getFullYear() === newDate.getFullYear(),
                    );
                    let totalAmount = 0;
                    salesMonth?.forEach((item) => {
                        totalAmount += item.amount;
                    });
                    data.push(totalAmount);
                }
                return data;
            default:
                // MONTHLY (current month - 11 before)
                for (let i = 11; i >= 0; i--) {
                    let today = new Date();
                    let newDate = new Date(today.setMonth(today.getMonth() - i));
                    let salesMonth = sales?.filter(
                        (item) =>
                            new Date(item.date).getMonth() === newDate.getMonth() &&
                            new Date(item.date).getFullYear() === newDate.getFullYear(),
                    );
                    let totalAmount = 0;
                    salesMonth?.forEach((item) => {
                        totalAmount += item.amount;
                    });
                    data.push(totalAmount);
                }
                return data;
        }
    }, [period, sales]);

    const labels = setLabelsChart();
    const data = setDataChart();

    const dataChart = useMemo(() => {
        return {
            labels,
            datasets: [
                {
                    label: 'Amount',
                    data: data,
                    borderColor: theme.colors.semantic.info600,
                    backgroundColor: 'rgba(0,0,0,0)',
                },
            ],
        };
    }, [data, labels, theme.colors.semantic.info600]);

    const optionsChart = useMemo(() => {
        return {
            plugins: { legend: { display: false } },
            scales: {
                y: {
                    ticks: {
                        padding: 8,
                        callback: function (value) {
                            let res = value % 1 === 0 ? value : ''; // display integer values only
                            return nFormatter(res, 3); // 1000 to 1k
                        },
                    },
                },
            },
        };
    }, []);

    return (
        <div className="fm-widgets__sage-content__sales">
            <Header period={period} onChangePeriod={onChangePeriod} sageSolution={sageSolution} />
            <LineChart
                width={WIDTH_CHART}
                height={HEIGHT_CHART}
                data={dataChart}
                options={optionsChart}
            />
        </div>
    );
});
export default SageSalesGraph;
