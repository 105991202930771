import React, { memo, useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Marker } from '@react-google-maps/api';
import { InfoWindow } from '@react-google-maps/api';

import { COMPANIES } from 'constants/Entities';
import { EVENT_SVG_TEXT } from 'constants/Images';
import { getSrcCompanyCircleAvatar } from 'utils/getSrcAvatar';
import { getRoute } from 'utils/routes';

import { Link, Avatar, BadgeNotification, Text, useTheme } from 'hoi-poi-ui';

const MapAccountMarkerWithPopover = ({
    position,
    index,
    id,
    name,
    environment,
    type,
    options,
    onClick,
    noRedraw = true,
    onLoad,
}) => {
    const theme = useTheme();
    const [showPopover, setShowPopover] = useState(false);

    const handleOnLoad = useCallback(
        (marker) => {
            onLoad?.(marker);
        },
        [onLoad],
    );

    const onClickPopover = useCallback(() => {
        setShowPopover((currentShow) => !currentShow);
        onClick?.();
    }, [onClick]);

    const onClose = useCallback(() => {
        setShowPopover(false);
    }, []);

    const { src, fallbackSrc } = useMemo(() => getSrcCompanyCircleAvatar(id), [id]);

    return (
        <>
            <Marker
                position={position}
                icon={EVENT_SVG_TEXT(index)}
                options={options}
                onClick={onClickPopover}
                noRedraw={noRedraw}
                onLoad={handleOnLoad}
            />
            {showPopover && (
                <InfoWindow
                    position={position}
                    onCloseClick={onClose}
                    options={{ pixelOffset: { width: 0, height: -65 } }}
                >
                    <div className="fm-marker-popover">
                        <Avatar
                            src={src}
                            placeholder={fallbackSrc}
                            size="big"
                            className="fm-marker-popover__avatar"
                        />
                        <div className="fm-marker-popover__content">
                            <div className="fm-marker-popover__title">
                                {index && (
                                    <BadgeNotification
                                        className="fm-marker-popover__badge"
                                        size="small"
                                    >
                                        {index}
                                    </BadgeNotification>
                                )}
                                <Link
                                    type="subtitle"
                                    href={getRoute(COMPANIES.route + `/${id}`)}
                                    target="_blank"
                                    isTruncated
                                    useTooltip
                                >
                                    {name}
                                </Link>
                            </div>
                            <Text color={theme.colors.utility.textSecondary}>{environment}</Text>
                            <Text color={theme.colors.utility.textPrimary}>{type}</Text>
                        </div>
                    </div>
                </InfoWindow>
            )}
        </>
    );
};

MapAccountMarkerWithPopover.propTypes = {
    position: PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number }),
    icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    clusterer: PropTypes.object,
    options: PropTypes.object,
    onClick: PropTypes.func,
    noRedraw: PropTypes.bool,
    draggable: PropTypes.bool,
    onDragEnd: PropTypes.func,
    onLoad: PropTypes.func,
};

export default memo(MapAccountMarkerWithPopover);
