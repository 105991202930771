import { memo, useCallback } from 'react';
import { useTheme, Button, Icon, Markdown } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import { ensureRoute } from 'utils/routes';
import { logEvent } from 'utils/tracking';
import useEmailEditor from 'containers/components/EmailEditor/hooks/useEmailEditor';

import './styles.scss';

const EmailEditorConsent = () => {
    const theme = useTheme();
    const { dismissConsentBanner, showTrackingConsentBanner } = useEmailEditor();

    const handleClose = useCallback(() => {
        logEvent({
            event: 'banner',
            submodule: 'consent',
            functionality: 'close',
        });
        dismissConsentBanner();
    }, [dismissConsentBanner]);

    const handleManagePermissions = useCallback(() => {
        logEvent({
            event: 'banner',
            submodule: 'consent',
            functionality: 'managePermisions',
        });
        dismissConsentBanner();
        ensureRoute('/settings/addons');
    }, [dismissConsentBanner]);

    if (!showTrackingConsentBanner) return null;

    return (
        <div className="fm-eec">
            <div className="fm-eec__content">
                <Icon name="warningRounded" color={theme.colors.semantic.focusCustom600} />
                <Markdown
                    content={getLiteral('label_banner_consent')}
                    overrides={{
                        a: {
                            style: {
                                color: theme.colors.semantic.focusCustom600,
                                textDecoration: 'underline',
                            },
                        },
                        p: { style: { color: theme.colors.semantic.focusCustom600 } },
                    }}
                />
            </div>
            <div className="fm-eec__actions">
                <Button size="small" type="secondary" onClick={handleClose}>
                    {getLiteral('action_close')}
                </Button>
                <Button size="small" onClick={handleManagePermissions}>
                    {getLiteral('action_manage_permissions')}
                </Button>
            </div>
        </div>
    );
};

export default memo(EmailEditorConsent);
