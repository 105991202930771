import { memo, useMemo, Fragment, useCallback, useRef } from 'react';
import classNames from 'classnames';
import { Button, Icon, Tooltip } from 'hoi-poi-ui';
import MenuHoi from 'components/MenuHoi';
import { getLiteral } from 'utils/getLiteral';
import { logEvent } from 'utils/tracking';
import SignatureMailingModal from './SignatureMailingModal';
import useEmailEditor from '../hooks/useEmailEditor';
import { TRACKING_EMAIL_ACTIONS_BEHAVIOUR } from '../constants';

const Footer = ({
    actions,
    confirmText,
    onConfirm,
    isConfirmDisabled,
    isConfirmLoading,
    isSending,
    middleButtonText,
    onMiddleButton,
    showActions,
    getInputProps,
    onClickAttach,
    onSaveSignature,
    signature,
}) => {
    const signatureModalRef = useRef();

    const { trackingActionsBehaviour } = useEmailEditor();

    const insertSignature = useCallback(() => {
        onSaveSignature(signature, true);
        logEvent({
            event: 'emails',
            submodule: 'signature',
            functionality: 'insert',
        });
    }, [onSaveSignature, signature]);

    const manageSignature = useCallback(() => {
        signatureModalRef.current?.open();
        logEvent({
            event: 'emails',
            submodule: 'signature',
            functionality: 'manage',
        });
    }, []);

    const handleRef = useCallback((ref) => (signatureModalRef.current = ref), []);

    const renderActions = useMemo(() => {
        if (!showActions || actions?.length === 0) return null;

        const isPending = trackingActionsBehaviour === TRACKING_EMAIL_ACTIONS_BEHAVIOUR.NOT_ACTIVE;
        const isDisabled = trackingActionsBehaviour === TRACKING_EMAIL_ACTIONS_BEHAVIOUR.DISABLED;

        return actions.map((action) => {
            let tooltip = action.tooltip;
            let onClick = action.onClick;

            if (isDisabled) {
                tooltip = action.tooltipDisabled;
                onClick = null;
            } else if (isPending) {
                tooltip = action.tooltipPending;
                onClick = action.onClickPending;
            } else if (isSending) {
                onClick = null;
            }

            const actionClasses = classNames('fm-ee__footerAction', {
                'fm-ee__footerAction--active': action.active,
                'fm-ee__footerAction--sending': isSending,
                'fm-ee__footerAction--disabled': isPending || isDisabled || isSending,
            });

            return (
                <Tooltip key={action.key} placement="top" content={tooltip}>
                    <span className={actionClasses} onClick={onClick}>
                        <Icon name={action.icon} color="currentColor" />
                    </span>
                </Tooltip>
            );
        });
    }, [actions, isSending, showActions, trackingActionsBehaviour]);

    const renderSignatureMenu = useMemo(
        () =>
            signature ? (
                <span
                    className={classNames('fm-ee__footerAction', 'fm-ee__footerAction__signature', {
                        'fm-ee__footerAction--disabled': isSending,
                    })}
                >
                    <MenuHoi
                        icon={<Icon name="signature" color="currentColor" />}
                        items={[
                            {
                                title: getLiteral('action_insert_signature'),
                                icon: 'edit',
                                onClick: insertSignature,
                            },
                            {
                                title: getLiteral('action_manage_signature'),
                                icon: 'signature',
                                onClick: manageSignature,
                            },
                        ]}
                    >
                        <Icon name="arrowDropDown" color="currentColor" />
                    </MenuHoi>
                </span>
            ) : (
                <Tooltip placement="top" content={getLiteral('label_manage_signature')}>
                    <span
                        className={classNames('fm-ee__footerAction', {
                            'fm-ee__footerAction--disabled': isSending,
                        })}
                        onClick={manageSignature}
                    >
                        <Icon name="signature" color="currentColor" />
                    </span>
                </Tooltip>
            ),
        [signature, insertSignature, isSending, manageSignature],
    );

    return (
        <div className="fm-ee__footer">
            {getInputProps && <input {...getInputProps()} />}
            <div className="fm-ee__footerActions">
                {showActions && (
                    <Fragment>
                        <Tooltip placement="top" content={getLiteral('label_hover_attach')}>
                            <span
                                className={classNames('fm-ee__footerAction', {
                                    'fm-ee__footerAction--disabled': isSending,
                                })}
                                onClick={onClickAttach}
                            >
                                <Icon name="attachFileVertical" color="currentColor" />
                            </span>
                        </Tooltip>
                        {renderSignatureMenu}
                        {renderActions?.length && (
                            <span
                                className={classNames('fm-ee__footerDivider', {
                                    'fm-ee__footerDivider--disabled': isSending,
                                })}
                            ></span>
                        )}
                        {renderActions}
                    </Fragment>
                )}
            </div>
            <Button type="secondary" onClick={onMiddleButton} isDisabled={isSending}>
                {middleButtonText}
            </Button>
            <Button
                isDisabled={isConfirmDisabled || isSending}
                isLoading={isConfirmLoading}
                onClick={onConfirm}
            >
                {confirmText}
            </Button>
            <SignatureMailingModal onRef={handleRef} value={signature} onSave={onSaveSignature} />
        </div>
    );
};

export default memo(Footer);
