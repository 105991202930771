import React from 'react';
import colors from 'constants/colors';

const Calendar = ({ color = colors.actionMinor[500] }) => (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17 11.0001H7V13.0001H17V11.0001ZM19 4.00012H18V2.00012H16V4.00012H8V2.00012H6V4.00012H5C3.89 4.00012 3.01 4.90012 3.01 6.00012L3 20.0001C3 21.1001 3.89 22.0001 5 22.0001H19C20.1 22.0001 21 21.1001 21 20.0001V6.00012C21 4.90012 20.1 4.00012 19 4.00012ZM19 20.0001H5V9.00012H19V20.0001ZM17 15.0001H7V17.0001H17V15.0001Z"
            fill={color || '#E1E4E7'}
        />
    </svg>
);

export default Calendar;
