import { memo } from 'react';
import { Text, Icon } from 'hoi-poi-ui';
import { BaseReactCell } from '@web/web5';

const IconCell = memo(({ data, value, iconValue, ...props }) => {
    return (
        <BaseReactCell data={data} {...props}>
            <div className="fm-automation-settings__icon-cell">
                <Icon name={data[iconValue]} />
                <Text>{value || '-'}</Text>
            </div>
        </BaseReactCell>
    );
});

export default IconCell;
