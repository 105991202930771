import React, { memo, useRef, useState, useMemo, useContext, useCallback, useEffect } from 'react';
import { Icon, Text, Spacer, Slider, useTheme } from 'hoi-poi-ui';
import classnames from 'classnames';
import { getLiteral } from 'utils/getLiteral';
import { DashboardContext } from '../../DashboardContext';

import SidePanel from 'components/SidePanel';
import SidePanelSection from 'components/SidePanel/SidePanelSection';

import './styles.scss';

const DashboardCustomizePanel = memo(({ onRef, onClose }) => {
    const timer = useRef();
    const { activeDashboard, updateConfig } = useContext(DashboardContext);
    const theme = useTheme();
    const [color, setColor] = useState();
    const [brightness, setBrightness] = useState();

    useEffect(() => {
        if (activeDashboard?.config?.background?.color) {
            setColor(activeDashboard?.config?.background?.color || theme.colors.primary.white);
            setBrightness(activeDashboard?.config?.background?.brightness || 30);
        } else if (activeDashboard) {
            setColor(theme.colors.primary.white);
            setBrightness(30);
        }
    }, [
        activeDashboard,
        activeDashboard?.config?.background?.brightness,
        activeDashboard?.config?.background?.color,
        theme.colors.primary.white,
    ]);

    const onChangeBrightness = useCallback(
        (value) => {
            clearTimeout(timer.current);
            timer.current = setTimeout(() => {
                setBrightness(value);
                updateConfig({
                    ...activeDashboard?.config,
                    background: {
                        color,
                        brightness: value,
                    },
                });
            }, 250);
        },
        [activeDashboard?.config, color, updateConfig],
    );

    const ColorBoxes = useMemo(() => {
        const BACKGROUND_COLORS = [
            theme.colors.background.jadeT7,
            theme.colors.actionMajor[150],
            theme.colors.semantic.info150,
            theme.colors.semantic.positiveCustom200,
            theme.colors.semantic.focusCustom200,
            theme.colors.semantic.negativeCustom200,
            theme.colors.temp.purple200,
            theme.colors.grey[200],
            theme.colors.primary.black,
            theme.colors.primary.white,
        ];

        return BACKGROUND_COLORS.map((bcolor) => (
            <div
                className={classnames('fm-dashboard-customize-panel__color', {
                    'fm-dashboard-customize-panel__color--active': bcolor === color,
                })}
                onClick={() => {
                    setColor(bcolor);
                    updateConfig({
                        ...activeDashboard?.config,
                        background: {
                            color: bcolor,
                            brightness,
                        },
                    });
                }}
                style={{ backgroundColor: bcolor }}
            >
                {bcolor === color ? (
                    <Icon
                        name="check"
                        size="big"
                        color={
                            color === theme.colors.actionMinor[500]
                                ? theme.colors.grey[100]
                                : theme.colors.actionMinor[500]
                        }
                    />
                ) : undefined}
            </div>
        ));
    }, [activeDashboard?.config, brightness, color, theme, updateConfig]);

    return (
        <SidePanel onRef={onRef}>
            <div className="fm-dashboard-customize-panel">
                <div className="fm-dashboard-customize-panel__header">
                    <div className="fm-dashboard-customize-panel__titles">
                        <Text type="h6">{getLiteral('title_customise_dashboard')}</Text>
                        <Spacer y={1} />
                        <Text color={theme.colors.utility.textSecondary}>
                            {getLiteral('label_customise_dashboard_desc')}
                        </Text>
                    </div>
                    <Icon name="close" onClick={onClose} size="large" />
                </div>
                <SidePanelSection text={getLiteral('label_background')} />
                <Spacer y={6} />
                <Text>{getLiteral('label_colour')} </Text>
                <Spacer y={4} />
                <div className="fm-dashboard-customize-panel__colors-box">{ColorBoxes}</div>
                <Spacer y={6} />
                <Text>{getLiteral('label_brightness')} </Text>
                <Spacer y={4} />
                <Slider
                    className="fm-dashboard-customize-panel__brightness"
                    value={brightness}
                    onChange={onChangeBrightness}
                    min={20}
                    max={100}
                    isFullWidth
                />
            </div>
        </SidePanel>
    );
});

export default DashboardCustomizePanel;
