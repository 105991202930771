import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as FmBridgeBackend from '@web/fm-bridge-backend';
import { FiltersProvider } from '@web/web5';

import { EntityCrudActions, EntityDetailActions, CompaniesActions, EntityActions } from 'actions';
import { ensureRoute, RouteOnMount } from 'utils/routes';
import { logEvent } from 'utils/tracking';

import { COMPANIES } from 'constants/Entities';
import { ENTITY_LIST_SECTION } from 'constants/ActionTypes';
import { COMPANY_ICON } from 'constants/Images';
import colors from 'constants/colors';

import useEntityDetail from 'containers/components/EntityDetail/hooks/useEntityDetail';
import ContentLayout from 'containers/components/layouts/ContentLayout';
import DetailLayout from 'containers/components/layouts/ContentDetailLayout';
import FiltersLayout from 'containers/components/layouts/FiltersLayout';
import EntityMap from 'containers/components/EntityMap';
import CompaniesHeader from './CompaniesHeader';
import Menu from './Menu';
import Content from './Content';
import CONFIG from './EntityConfig';
import CompaniesCrud from './CompaniesCrud';
import CompaniesDetail from './CompaniesDetail';
import CompanyInfoWindow from './components/map/CompanyInfoWindow';

import './styles.scss';

const propTypes = {
    login: PropTypes.bool.isRequired,
    section: PropTypes.number,
};

const Main = React.memo(
    ({
        login,
        openDetail,
        openCrud,
        permissions,
        section,
        changeSection,
        initWorkFlowCrud,
        initWorkFlowDetail,
        isPageForbidden,
        hideAccountSearch,
    }) => {
        const { hasTabsEnabled } = useEntityDetail();

        const onChangeTab = useCallback((event, value) => {
            if (value === ENTITY_LIST_SECTION.MAP) {
                logEvent({
                    event: COMPANIES.trueName,
                    functionality: 'mapView',
                    checkDuplicate: true,
                });
                ensureRoute('/companies/map');
            } else {
                logEvent({
                    event: COMPANIES.trueName,
                    functionality: 'listView',
                    checkDuplicate: true,
                });
                ensureRoute('/companies');
            }
        }, []);

        useEffect(() => {
            if (!login) return;
            if (isPageForbidden(['empresas'])) return;
            FmBridgeBackend.setContext({
                entityType: {
                    name: COMPANIES.entity,
                    id: COMPANIES.objectTypeId,
                },
            });
        }, [isPageForbidden, login]);

        const renderSection = useMemo(() => {
            if (section === ENTITY_LIST_SECTION.LIST) return <Content />;
            return (
                <EntityMap
                    entity={COMPANIES}
                    markerIcon={COMPANY_ICON}
                    markerLabelEntityField={'calification'}
                    markerLabelPosition={{ x: 34, y: 15 }}
                    markerLabelStyles={{
                        color: colors.actionMajor[500],
                        fontSize: '12px',
                        lineHeight: '14px',
                        fontWeight: '400',
                    }}
                    infoWindow={<CompanyInfoWindow />}
                    infoWindowOptions={{ pixelOffset: { width: 0, height: -40 } }}
                />
            );
        }, [section]);

        const filtersConfig = useMemo(() => {
            return { ...CONFIG(), hiddenFilters: ['onlySelected'] };
        }, []);

        if (!login) return null;
        if (isPageForbidden(['empresas'])) return null;

        const config = CONFIG();

        return (
            <FiltersProvider>
                <ContentLayout
                    header={<CompaniesHeader onChange={onChangeTab} section={section} />}
                >
                    <DetailLayout header={<Menu config={config} hideSearch={hideAccountSearch} />}>
                        <FiltersLayout config={filtersConfig}>{renderSection}</FiltersLayout>
                    </DetailLayout>
                    <CompaniesCrud />
                    <CompaniesDetail />
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <RouteOnMount
                                    onMount={() => {
                                        changeSection(ENTITY_LIST_SECTION.LIST);
                                    }}
                                />
                            }
                        />
                        <Route
                            path="/map"
                            element={
                                <RouteOnMount
                                    onMount={() => {
                                        changeSection(ENTITY_LIST_SECTION.MAP);
                                    }}
                                />
                            }
                        />
                        <Route
                            path="/map/:id/edit"
                            element={
                                <RouteOnMount
                                    title="page_title_accounts_edit"
                                    onMount={(params) => {
                                        if (!params.id || !permissions || !permissions.update)
                                            return;
                                        openCrud({ entity: COMPANIES, id: params.id });
                                        if (section === ENTITY_LIST_SECTION.LIST)
                                            changeSection(ENTITY_LIST_SECTION.MAP);
                                    }}
                                />
                            }
                        />
                        <Route
                            path="/map/:id"
                            element={
                                <RouteOnMount
                                    title="page_title_accounts_details"
                                    onMount={(params) => {
                                        if (!params.id) return;
                                        openDetail(COMPANIES, params.id, true);
                                        if (section === ENTITY_LIST_SECTION.LIST)
                                            changeSection(ENTITY_LIST_SECTION.MAP);
                                    }}
                                />
                            }
                        />
                        <Route
                            path="/new"
                            element={
                                <RouteOnMount
                                    title="page_title_accounts_create"
                                    onMount={() => {
                                        if (!permissions || !permissions.create) return;
                                        openCrud({ entity: COMPANIES });
                                    }}
                                />
                            }
                        />
                        <Route
                            path="/map/new"
                            element={
                                <RouteOnMount
                                    title="page_title_accounts_create"
                                    onMount={() => {
                                        if (!permissions || !permissions.create) return;
                                        openCrud({ entity: COMPANIES });
                                    }}
                                />
                            }
                        />
                        <Route
                            path="/:id/edit/workflow"
                            element={
                                <RouteOnMount
                                    title="page_title_accounts_edit"
                                    onMount={(params) => {
                                        if (!params.id || !permissions || !permissions.update)
                                            return;
                                        initWorkFlowCrud();
                                    }}
                                />
                            }
                        />
                        <Route
                            path="/:id/edit"
                            element={
                                <RouteOnMount
                                    title="page_title_accounts_edit"
                                    onMount={(params) => {
                                        if (!params.id || !permissions || !permissions.update)
                                            return;
                                        openCrud({
                                            entity: COMPANIES,
                                            id: params.id,
                                            isFromTab: hasTabsEnabled,
                                        });
                                    }}
                                />
                            }
                        />
                        <Route
                            path="/:id/workflow"
                            element={
                                <RouteOnMount
                                    title="page_title_accounts_details"
                                    onMount={(params) => {
                                        if (!params.id) return;
                                        initWorkFlowDetail();
                                    }}
                                />
                            }
                        />
                        <Route
                            path="/:id"
                            element={
                                <RouteOnMount
                                    title="page_title_accounts_details"
                                    onMount={(params) => {
                                        if (!params.id) return;
                                        openDetail(COMPANIES, params.id, true);
                                    }}
                                />
                            }
                        />
                    </Routes>
                </ContentLayout>
            </FiltersProvider>
        );
    },
);

function mapStateToProps(state) {
    return {
        login: state.config.login,
        section: state.companies.section,
        permissions: state.config?.permission?.crud_permission?.[COMPANIES.permission],
        hideAccountSearch: state.config.permission.hideAccountSearch,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeSection: bindActionCreators(CompaniesActions, dispatch).changeSection,
        openCrud: bindActionCreators(EntityCrudActions, dispatch).init,
        closeCrud: bindActionCreators(EntityCrudActions, dispatch).close,
        openDetail: bindActionCreators(EntityDetailActions, dispatch).open,
        closeDetail: bindActionCreators(EntityDetailActions, dispatch).close,
        initWorkFlowCrud: bindActionCreators(EntityCrudActions, dispatch).initWorkFlow,
        initWorkFlowDetail: bindActionCreators(EntityDetailActions, dispatch).initWorkFlow,
        isPageForbidden: bindActionCreators(EntityActions, dispatch).isPageForbidden,
    };
};

Main.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(Main);
