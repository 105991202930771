import React, { memo, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { Text, useTheme } from 'hoi-poi-ui';

import { ensureRoute } from 'utils/routes';
import { getLiteral } from 'utils/getLiteral';

import './styles.scss';

const mapStateToProps = (state) => {
    const userData = state.config.userData;
    let name = userData.nombre;
    if (userData.apellidos) name = `${name} ${userData.apellidos}`;
    return {
        idUsuario: userData.idUsuario,
        email: userData.email,
        name,
    };
};

const MenuSampleData = memo(({ isExpanded }) => {
    const theme = useTheme();
    const classes = useMemo(() => {
        const newClasses = ['fm-menu-sample-data'];
        if (isExpanded) newClasses.push('fm-menu-sample-data--expanded');
        if (!isExpanded) newClasses.push('fm-menu-sample-data--collapsed');
        return newClasses;
    }, [isExpanded]);

    const onClick = useCallback(() => {
        ensureRoute('/settings/delete-sample-data');
    }, []);

    return (
        <div className={classes.join(' ')} onClick={onClick}>
            <div className="fm-menu-sample-data__container">
                <div className="fm-menu-sample-data__icon">
                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M12 3C16.42 3 20 4.79 20 7C20 9.21 16.42 11 12 11C7.58 11 4 9.21 4 7C4 4.79 7.58 3 12 3ZM4 9C4 11.21 7.58 13 12 13C16.42 13 20 11.21 20 9V12.08L19 12C16.41 12 14.2 13.64 13.36 15.94L12 16C7.58 16 4 14.21 4 12V9ZM4 14C4 16.21 7.58 18 12 18H13C13 19.05 13.27 20.04 13.75 20.9L12 21C7.58 21 4 19.21 4 17V14Z"
                            fill={theme.colors.actionMinor[500]}
                        />
                        <path
                            d="M16.88 14.46L15.46 15.88L17.59 18L15.47 20.12L16.88 21.53L19 19.41L21.12 21.54L22.54 20.12L20.41 18L22.53 15.88L21.12 14.47L19 16.59L16.88 14.46Z"
                            fill={theme.colors.actionMajor[500]}
                        />
                    </svg>
                </div>
            </div>
            <div className="fm-menu-sample-data__label-container">
                <Text
                    className="fm-menu-sample-data__label"
                    color={theme.colors.primary.white}
                    type="captionMedium"
                >
                    {getLiteral('label_delete_sample_data')}
                </Text>
                <Text
                    className="fm-menu-sample-data__subtitle"
                    color={theme.colors.primary.white}
                    type="caption"
                >
                    {getLiteral('label_delete_sample_data_explanation_3')}
                </Text>
            </div>
        </div>
    );
});

export default connect(mapStateToProps)(MenuSampleData);
