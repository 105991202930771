import React, { memo } from 'react';

const FeedbackIcon = memo((props) => {
    return (
        <svg width={168} height={144} fill="none" {...props}>
            <g mask="url(#a)">
                <path
                    fill="#474747"
                    d="M55.187 18.11a3.126 3.126 0 1 1 .102-6.25 3.117 3.117 0 0 1 2.489 1.3c.38.526.598 1.174.588 1.873a3.13 3.13 0 0 1-3.177 3.075l-.002.001Z"
                />
                <path fill="#474747" d="m52.073 19.995.035-4.982 6.257.103-.037 4.98-6.255-.1Z" />
                <path
                    fill="#E84D4F"
                    d="M57.286 14.988a1.924 1.924 0 1 1-3.073-1.542 1.926 1.926 0 0 1 3.072 1.542Z"
                />
                <path
                    fill="#474747"
                    d="M57.715 14.647c-2.087-.142-3.892-.883-3.892-.883l.792-1.063 2.388.722.972.871-.26.355v-.002Z"
                />
                <path
                    fill="#00D639"
                    d="M76.218 19.221H51.566a15.112 15.112 0 0 0-12.543 6.661 15.105 15.105 0 0 0-1.907 3.963 15.126 15.126 0 0 0-.681 4.492c0 2.073.422 4.075 1.191 5.886a15.16 15.16 0 0 0 9.442 8.552c1.425.443 2.943.68 4.497.68h69.387a3.285 3.285 0 0 1 2.746 1.461 3.264 3.264 0 0 1 .562 1.845c0 .472-.093.892-.257 1.285a3.35 3.35 0 0 1-1.205 1.459 3.29 3.29 0 0 1-1.846.561H74.355a5.703 5.703 0 0 0-5.705 5.7c0 3.147 2.555 5.7 5.705 5.7h46.598c2.016 0 3.966-.413 5.731-1.159a14.754 14.754 0 0 0 6.471-5.321 14.654 14.654 0 0 0 2.516-8.225c0-2.015-.413-3.963-1.159-5.726a14.74 14.74 0 0 0-9.185-8.317 14.733 14.733 0 0 0-4.374-.662H51.566c-.529 0-1.004-.103-1.446-.289a3.75 3.75 0 0 1-2.108-2.32 3.77 3.77 0 0 1-.167-1.108c0-.529.104-1.003.29-1.444a3.753 3.753 0 0 1 2.322-2.106 3.777 3.777 0 0 1 1.11-.167h24.65a5.703 5.703 0 0 0 5.705-5.7c0-3.147-2.554-5.7-5.704-5.7Z"
                />
                <path
                    fill="#00D639"
                    d="M61.203 15.683s2.044 1.354 2.099 4.032h3.434s-1.507-3.741-5.535-4.032h.002Z"
                />
                <path
                    fill="#E84D4F"
                    d="M78 34.04c.107-.01.672-.057 1.461-.31a7.646 7.646 0 0 0 1.32-.564 7.063 7.063 0 0 0 1.445-1.04 1.847 1.847 0 0 0-2.525-2.694 3.325 3.325 0 0 1-.69.495 4.056 4.056 0 0 1-1.248.427c-.03.005-.048.006-.053.008l.106 1.17-.087-1.172h-.02l.107 1.173-.087-1.173A1.846 1.846 0 1 0 78 34.04Z"
                />
                <path
                    fill="#919191"
                    d="M53.908 26.067c.71-.222 1.217-.282 1.529-.282.232 0 .359.03.43.05.063.02.088.034.12.055a.559.559 0 0 1 .101.088c.078.081.199.244.324.513.095.2.19.456.274.755.126.447.224.986.285 1.556.062.57.087 1.172.087 1.749a22.85 22.85 0 0 1-.213 3.038 4.854 4.854 0 0 1-.03.198l.455.078-.454-.077 2.007.341-2.007-.344v.003l2.007.341-2.007-.344 2.875.494-2.875-.495 3.6.619-3.6-.622v.003l3.6.619-3.6-.622a3.79 3.79 0 0 0 4.724 4.304l-.344-1.275.203 1.306c.019-.003.066-.01.141-.031l-.344-1.275.203 1.306-.05-.322.04.323h.01l-.05-.323.04.323s.184-.016.484-.015c.262 0 .614.008 1.04.015.425.007.925.017 1.494.017 1.197 0 2.698-.038 4.535-.185a75.628 75.628 0 0 0 6.598-.842 3.79 3.79 0 1 0-1.266-7.473 67.79 67.79 0 0 1-5.94.759c-1.614.13-2.886.16-3.929.16a73.5 73.5 0 0 1-1.355-.015c-.414-.008-.795-.017-1.177-.017-.335 0-.671.006-1.042.034-.371.03-.784.08-1.264.209l.987 3.658 3.737.645c.012-.065.098-.573.184-1.373.084-.802.17-1.895.17-3.148 0-1.058-.06-2.231-.249-3.456a15.221 15.221 0 0 0-.705-2.828c-.26-.72-.595-1.444-1.046-2.15a8.935 8.935 0 0 0-1.224-1.529 8.056 8.056 0 0 0-3.458-2.035 9.095 9.095 0 0 0-2.53-.347c-1.237 0-2.492.223-3.785.625a3.788 3.788 0 0 0-2.492 4.745 3.793 3.793 0 0 0 4.75 2.49l.002.006Z"
                />
                <path
                    fill="#474747"
                    d="m54.72 45.478 5.123 63.223a5.057 5.057 0 0 0 5.449 4.629 5.055 5.055 0 0 0 4.633-5.444l-5.122-63.223a5.057 5.057 0 0 0-5.45-4.629 5.055 5.055 0 0 0-4.632 5.444Z"
                />
                <path
                    fill="#474747"
                    d="m42.086 108.026 3.734-9.372a219.97 219.97 0 0 0 11.486-38.96l3.309-16.823a5.052 5.052 0 0 0-3.987-5.931 5.056 5.056 0 0 0-5.937 3.983l-3.309 16.822a209.733 209.733 0 0 1-10.958 37.17l-3.734 9.373a5.051 5.051 0 0 0 2.828 6.563 5.059 5.059 0 0 0 6.57-2.825h-.002Z"
                />
                <path
                    fill="#919191"
                    d="M63.455 31.06c1.428 9.15-3.694 10.315-5.831 10.332-5.551.044-8.249-.944-8.249-9.663 0-8.718 3.496-12.595 7.765-12.595 4.27 0 5.198 4.763 6.315 11.925v.001Z"
                />
                <path
                    fill="#474747"
                    d="m43.338 110.986-5.736-1.428a.885.885 0 1 0-.428 1.719l5.735 1.428a.887.887 0 0 0 .429-1.719ZM71.832 111.574h-6.738a.887.887 0 0 0 0 1.772h6.738a.887.887 0 0 0 0-1.772Z"
                />
                <path
                    fill="#E84D4F"
                    d="M63.242 52.197c.055.005.235.022.51.022a7.56 7.56 0 0 0 1.672-.19 7.207 7.207 0 0 0 2.315-.964 1.844 1.844 0 0 0 .551-2.55 1.847 1.847 0 0 0-2.553-.551 3.51 3.51 0 0 1-1.128.464 3.962 3.962 0 0 1-.984.096h-.017l-.075.923.098-.922c-.014 0-.021-.002-.023-.002l-.075.925.098-.921a1.845 1.845 0 1 0-.388 3.668l-.001.002Z"
                />
                <path
                    fill="#00D639"
                    d="M36.435 34.428c0 2.07.423 4.075 1.192 5.885a15.158 15.158 0 0 0 9.442 8.552c1.425.443 2.943.68 4.497.68h69.386c.473 0 .893.094 1.287.258a3.35 3.35 0 0 1 1.46 1.203c.179.265.318.553.414.858.095.308.148.631.148.985 0 3.148 2.554 5.7 5.704 5.7a5.702 5.702 0 0 0 5.705-5.7c0-2.014-.412-3.962-1.159-5.725a14.74 14.74 0 0 0-9.184-8.317 14.75 14.75 0 0 0-4.375-.662H51.566c-.53 0-1.004-.103-1.446-.29a3.75 3.75 0 0 1-2.108-2.32 3.766 3.766 0 0 1-.167-1.107c0-3.147-2.555-5.7-5.705-5.7a5.703 5.703 0 0 0-5.705 5.7Z"
                />
                <path
                    fill="#919191"
                    d="M51.677 18.823c-.42.127-.802.292-1.153.475-.658.347-1.21.76-1.712 1.211-.439.396-.836.821-1.21 1.275a16.936 16.936 0 0 0-1.755 2.632 22.19 22.19 0 0 0-1.906 4.778 21.073 21.073 0 0 0-.76 5.498c0 1.625.21 3.297.73 4.945.258.822.595 1.64 1.02 2.43.427.79.94 1.555 1.544 2.27.544.645 1.133 1.242 1.749 1.809 1.078.99 2.244 1.895 3.399 2.717a60.825 60.825 0 0 0 4.721 3.018 55.469 55.469 0 0 0 2.2 1.217 3.795 3.795 0 0 0 5.109-1.632 3.788 3.788 0 0 0-1.634-5.105l-1.515 2.936 1.518-2.934h-.003l-1.515 2.934 1.518-2.934-1.218 2.357 1.22-2.357-1.218 2.357 1.219-2.357h-.001c-.017-.01-.48-.248-1.21-.659a56.462 56.462 0 0 1-4.436-2.765 32.444 32.444 0 0 1-2.352-1.787c-.714-.6-1.327-1.198-1.744-1.694a5.756 5.756 0 0 1-.664-.977 6.758 6.758 0 0 1-.633-1.733 9.47 9.47 0 0 1-.214-2.053c0-1.057.154-2.181.429-3.264.205-.81.477-1.598.788-2.31.232-.534.484-1.028.74-1.456a9.49 9.49 0 0 1 .565-.848c.27-.36.52-.614.667-.732a1.303 1.303 0 0 1 .11-.083l-.436-.755.252.829c.124-.039.179-.071.185-.074l-.437-.755.252.829a3.789 3.789 0 0 0 2.526-4.728 3.793 3.793 0 0 0-4.732-2.524l-.003-.002Z"
                />
                <path
                    fill="#777"
                    d="M115.898 37.908c-1.294-.402-2.549-.625-3.787-.625-.91 0-1.809.124-2.657.385a8.086 8.086 0 0 0-2.064.97 8.073 8.073 0 0 0-1.447 1.216 9.495 9.495 0 0 0-1.674 2.493c-.301.643-.53 1.288-.709 1.925a17.185 17.185 0 0 0-.525 2.794 23.943 23.943 0 0 0-.132 2.562c0 1.253.085 2.347.17 3.148.086.8.172 1.308.184 1.373l3.737-.645.986-3.66a10.197 10.197 0 0 0-1.032-.216 24.37 24.37 0 0 0-1.966-.21c-1.115-.085-2.519-.16-4.461-.271-1.942-.11-4.413-.257-7.618-.479a3.792 3.792 0 0 0-4.046 3.519 3.79 3.79 0 0 0 3.521 4.042c2.47.171 4.518.298 6.216.397 1.275.075 2.354.135 3.266.186a105.8 105.8 0 0 1 3.051.201c.342.031.605.062.787.089.183.026.282.05.31.059a3.798 3.798 0 0 0 3.542-.86 3.795 3.795 0 0 0 1.181-3.446l-2.237.388 2.238-.38v-.008l-2.238.388 2.238-.38c-.001-.01-.063-.384-.124-.975-.06-.59-.118-1.398-.118-2.262 0-.766.047-1.575.16-2.296.082-.54.204-1.03.343-1.41.103-.285.214-.507.31-.656a1.29 1.29 0 0 1 .179-.229.432.432 0 0 1 .216-.127c.074-.02.199-.047.418-.047.312 0 .819.059 1.529.282 2 .623 4.125-.492 4.749-2.49a3.788 3.788 0 0 0-2.492-4.745h-.004Z"
                />
                <path
                    fill="#474747"
                    d="m102.745 63.739-5.122 63.223a5.056 5.056 0 0 0 4.633 5.444 5.057 5.057 0 0 0 5.449-4.629l5.122-63.223a5.058 5.058 0 0 0-10.082-.816Z"
                />
                <path
                    fill="#474747"
                    d="m134.858 123.366-3.734-9.373a209.825 209.825 0 0 1-10.959-37.17l-3.308-16.822a5.056 5.056 0 0 0-5.937-3.983 5.05 5.05 0 0 0-3.987 5.931l3.309 16.822a219.74 219.74 0 0 0 11.486 38.96l3.734 9.373a5.058 5.058 0 0 0 9.397-3.738h-.001Z"
                />
                <path
                    fill="#777"
                    d="M104.09 50.137c-1.428 9.15 3.694 10.315 5.832 10.33 5.551.045 8.249-.943 8.249-9.661 0-8.719-3.496-12.596-7.765-12.596-4.27 0-5.198 4.763-6.316 11.925v.002Z"
                />
                <path
                    fill="#474747"
                    d="m124.638 131.783 5.736-1.429a.886.886 0 1 0-.429-1.719l-5.735 1.428a.886.886 0 1 0 .428 1.72ZM95.716 132.422h6.738a.886.886 0 1 0 0-1.772h-6.738a.887.887 0 0 0 0 1.772Z"
                />
                <path
                    fill="#B26E3D"
                    d="M113.603 33.373a1.906 1.906 0 1 1-3.545 1.406 1.906 1.906 0 0 1 3.545-1.407v.001Z"
                />
                <path
                    fill="#474747"
                    d="M113.034 30.77a.804.804 0 1 1-1.499.592.804.804 0 0 1 1.501-.591h-.002ZM111.128 32.304a1.89 1.89 0 0 0-.918.768 1.921 1.921 0 0 0-.245.611l3.669-.22c-.01-.03-.018-.059-.03-.088a1.91 1.91 0 0 0-2.474-1.07l-.002-.001Z"
                />
                <path
                    fill="#B26E3D"
                    d="m103.919 67.602.098.924-.075-.926-.023.001.098.923-.075-.926h-.01a1.768 1.768 0 0 1-.135.004c-.176 0-.497-.016-.856-.099a3.504 3.504 0 0 1-1.128-.463c-.858-.553-2-.306-2.553.55a1.844 1.844 0 0 0 .551 2.551c.807.52 1.62.805 2.315.963a7.766 7.766 0 0 0 1.672.19c.275 0 .455-.015.51-.021a1.844 1.844 0 1 0-.387-3.669l-.002-.002Z"
                />
                <path
                    fill="#00D639"
                    d="M120.953 56.065H74.355a5.703 5.703 0 0 0-5.705 5.7c0 3.147 2.555 5.699 5.705 5.699h46.598a5.703 5.703 0 0 0 5.705-5.7c0-3.147-2.555-5.7-5.705-5.7Z"
                />
                <path
                    fill="#777"
                    d="m113.665 45.15.288-.948-.464.88c.021.01.075.039.174.069l.289-.949-.465.88.132-.248-.141.244.009.005.132-.249-.141.244.026-.044-.027.043.025-.043-.026.043s.102.066.242.2c.123.119.278.287.447.501.294.372.625.878.937 1.467.47.884.902 1.963 1.201 3.095.302 1.133.474 2.32.474 3.431 0 .968-.127 1.875-.379 2.67-.125.396-.281.768-.467 1.115-.188.348-.405.671-.664.978-.282.336-.653.718-1.085 1.114-.755.696-1.692 1.431-2.661 2.12a53.701 53.701 0 0 1-4.124 2.633 53.059 53.059 0 0 1-1.758.976l-.091.049-.019.01h-.004l.175.338-.175-.336 1.538 2.974-1.534-2.974h-.004l1.538 2.974-1.534-2.974a3.787 3.787 0 0 0-1.634 5.104 3.794 3.794 0 0 0 5.109 1.632 63.741 63.741 0 0 0 6.427-3.887 40.213 40.213 0 0 0 2.948-2.237c.959-.807 1.876-1.67 2.692-2.636a13.357 13.357 0 0 0 1.543-2.271 14.304 14.304 0 0 0 1.354-3.67c.278-1.241.397-2.485.397-3.705 0-1.776-.25-3.503-.663-5.126a22.792 22.792 0 0 0-1.182-3.465 20.814 20.814 0 0 0-1.177-2.31 16.516 16.516 0 0 0-1.033-1.543 12.02 12.02 0 0 0-1.857-1.982 8.423 8.423 0 0 0-1.157-.817 6.774 6.774 0 0 0-1.427-.628 3.789 3.789 0 1 0-2.206 7.252l.002.002Z"
                />
                <path
                    fill="#B26E3D"
                    d="M89.856 49.55v.76l.003-.76h-.003v.76l.003-.76s-.047 0-.13-.003a9.833 9.833 0 0 1-1.487-.164 5.556 5.556 0 0 1-1.101-.316 2.2 2.2 0 0 1-.358-.189.704.704 0 0 1-.15-.126 1.848 1.848 0 0 0-2.603-.216 1.844 1.844 0 0 0-.217 2.6c.288.341.614.609.942.826.576.378 1.17.614 1.735.787.852.257 1.657.37 2.275.431.617.06 1.04.061 1.093.061a1.845 1.845 0 1 0 0-3.69h-.002Z"
                />
                <path fill="#00D639" d="M90.131 43.845H75.285v5.671h14.846v-5.671Z" />
            </g>
        </svg>
    );
});

export default FeedbackIcon;
