import React, { memo, useCallback, useRef } from 'react';
import classnames from 'classnames';
import { Icon, useTheme, Link } from 'hoi-poi-ui';
import Base from '../Base';
import { getLiteral } from 'utils/getLiteral';
import TextInput from 'components/TextInput';
import TranslationsModal from './TranslationsModal';

const Translations = memo(
    ({
        label,
        hint,
        error,
        hidden,
        onChange,
        value,
        mandatory,
        description,
        labelMode,
        fieldId,
        shouldRenderField,
        maxLength,
        limitInput,
        autoFocus,
        readOnly,
        rows,
        rowsMax,
        multiLine,
        languages,
    }) => {
        const theme = useTheme();
        const modalRef = useRef(null);

        const onChangeMain = useCallback(
            (text) => {
                let newValue = value || {};
                onChange({ ...newValue, [languages[0]]: text });
            },
            [languages, onChange, value],
        );

        const showModal = useCallback((e) => {
            modalRef.current.open();
        }, []);

        const onModalRef = useCallback((ref) => {
            modalRef.current = ref;
        }, []);

        if (hidden) return null;
        if (shouldRenderField && !shouldRenderField(fieldId)) {
            return null;
        }

        const actionClass = classnames('fm-field__translations__action', {
            'fm-field__translations__action__textarea': multiLine,
        });

        return (
            <div className="fm-field__translations">
                <Base
                    label={label}
                    mandatory={mandatory}
                    description={description}
                    labelMode={labelMode}
                >
                    <TextInput
                        placeholder={hint}
                        inputType="text"
                        onChange={onChangeMain}
                        value={value?.[languages?.[0]] || ''}
                        error={error?.[languages?.[0]] || ''}
                        maxLength={maxLength}
                        limitInput={limitInput}
                        disabled={readOnly}
                        blurOnEnter={true}
                        autoFocus={autoFocus}
                        rows={rows}
                        rowsMax={rowsMax}
                        multiline={multiLine}
                    />
                    <div className={actionClass} onClick={showModal}>
                        <Link type="caption" isDisabled={readOnly}>
                            {getLiteral('action_show_languages')}
                            <Icon name="chevronRight" color={theme.colors.semantic.info500} />
                        </Link>
                    </div>
                </Base>
                <TranslationsModal
                    onRef={onModalRef}
                    title={label}
                    onChange={onChange}
                    value={value}
                    hint={hint}
                    maxLength={maxLength}
                    limitInput={limitInput}
                    rows={rows}
                    rowsMax={rowsMax}
                    multiline={multiLine}
                    languages={languages}
                />
            </div>
        );
    },
);

export default Translations;
