import React, { memo } from 'react';
import { Text, useTheme } from 'hoi-poi-ui';
import { getLocalDate } from 'utils/dates';

const EntityMergeDatesRow = ({ label, date }) => {
    const theme = useTheme();
    if (!label || !date) return null;
    return (
        <div className="fm-entity-merge-dates-row">
            <Text type="caption" color={theme.colors.utility.textSecondary}>
                {label}
            </Text>
            <Text type="caption" color={theme.colors.utility.textPrimary}>
                {getLocalDate(date, 'DD/MM/YYYY HH:mm:ss', 'L H:mm A')}
            </Text>
        </div>
    );
};

export default memo(EntityMergeDatesRow);
