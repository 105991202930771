import React, { memo } from 'react';
import { getLiteral } from 'utils/getLiteral';
import { Calendar } from 'components/SvgIcons';
import colors from 'constants/colors';

const CalendarNotificationsEmpty = memo(() => {
    return (
        <div className="fm-calendar-notifications__popover__content--empty">
            <div className="fm-calendar-notifications__popover__content--empty__icon">
                <Calendar color={colors.grey[200]} />
            </div>
            <div className="fm-calendar-notifications__popover__content--empty__text">
                {getLiteral('label_notifications_events_no_events')}
            </div>
        </div>
    );
});

export default CalendarNotificationsEmpty;
