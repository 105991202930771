import { memo, useCallback, useMemo, Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Text, useTheme } from 'hoi-poi-ui';

import './styles.scss';

const SelectionToggle = memo(({ className, value, options, onChange }) => {
    const theme = useTheme();

    const handleChange = useCallback(
        (value) => {
            onChange && onChange(value);
        },
        [onChange],
    );

    const renderOptions = useMemo(
        () =>
            options?.length
                ? options.map((option, index) => {
                      const active = option.value === value;
                      const textProps = active
                          ? { color: theme.colors.utility.textPrimary, type: 'captionMedium' }
                          : { color: theme.colors.utility.textSecondary, type: 'caption' };
                      return (
                          <Fragment key={`option-${option.value}`}>
                              <div
                                  className={classNames('fm-selectionToggle__option', {
                                      active,
                                  })}
                                  onClick={() => handleChange(option.value)}
                              >
                                  <Text
                                      className="fm-selectionToggle__option__label"
                                      {...textProps}
                                  >
                                      {option.label}
                                      {option.badge && (
                                          <Text
                                              type="badges"
                                              color={theme.colors.textLight.jadeAccessible}
                                              className="fm-selectionToggle__option__badge"
                                          >
                                              {option.badge}
                                          </Text>
                                      )}
                                  </Text>
                                  {option.subLabel && <Text {...textProps}>{option.subLabel}</Text>}
                              </div>
                              {options.length - 1 > index && (
                                  <span className="fm-selectionToggle__divider"></span>
                              )}
                          </Fragment>
                      );
                  })
                : null,
        [options, value, theme, handleChange],
    );

    return <div className={classNames('fm-selectionToggle', className)}>{renderOptions}</div>;
});

SelectionToggle.propTypes = {
    className: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
                .isRequired,
            label: PropTypes.string.isRequired,
            subLabel: PropTypes.string,
            badge: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
    ).isRequired,
    onChange: PropTypes.func,
};

export default SelectionToggle;
