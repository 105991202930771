import { memo, useMemo } from 'react';
import { Text, Button, Icon, useTheme } from 'hoi-poi-ui';

import CardLogo from 'containers/settings/PurchaseModal/components/CardAccount/components/CardLogo';
import { VALIDCARDS } from 'containers/settings/PurchaseModal/constants';
import { formatExpiryDate } from 'containers/settings/PurchaseModal/utils';
import { getLiteral } from 'utils/getLiteral';

const CardContent = memo(
    ({ editUrl, cardExpirationMonth, cardExpirationYear, cardLast4, provider, openModal }) => {
        const theme = useTheme();

        const cardLogo = useMemo(
            () =>
                VALIDCARDS[provider] ? (
                    <CardLogo name={provider} width={40} height={20} />
                ) : (
                    <Icon name="creditCard" size="big" color={theme.colors.grey[500]} />
                ),
            [provider, theme],
        );
        const cardNumber = useMemo(() => cardLast4 || '0000', [cardLast4]);
        const expiringDate = useMemo(
            () =>
                cardExpirationMonth
                    ? formatExpiryDate(cardExpirationMonth, cardExpirationYear)
                    : 'MM/YYYY',
            [cardExpirationMonth, cardExpirationYear],
        );

        return (
            <div className="billing-payment__content">
                <div className="billing-payment__content__info-section">
                    <div className="billing-payment__content__info-section--logo">{cardLogo}</div>
                    <div className="billing-payment__content__info-section--rows">
                        <div className="billing-payment__row">
                            <Text bold>{provider || '-'}</Text>
                            <div className="fm-cardnumber-row">
                                <Text color={theme.colors.utility.textSecondary}>●●●●</Text>
                                <Text color={theme.colors.utility.textSecondary}>●●●●</Text>
                                <Text color={theme.colors.utility.textSecondary}>●●●●</Text>
                                <Text>{cardNumber}</Text>
                            </div>
                        </div>
                        <div className="billing-payment__row">
                            <Text color={theme.colors.utility.textSecondary}>
                                {getLiteral('label_expired')}
                            </Text>
                            <Text color={theme.colors.utility.textSecondary}>{expiringDate}</Text>
                        </div>
                    </div>
                </div>
                {editUrl && (
                    <div className="billing-payment__content__edit-section">
                        <Button
                            className="billing-payment__content--button"
                            type="secondary"
                            onClick={() => openModal(true)}
                        >
                            {getLiteral('action_edit_card')}
                        </Button>
                    </div>
                )}
            </div>
        );
    },
);

export default CardContent;
