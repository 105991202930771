import { memo } from 'react';
import classnames from 'classnames';
import { Text, useTheme } from 'hoi-poi-ui';

const DashboardWidgetRow = memo(({ className, leftColumn, title, subtitle, onClick, children }) => {
    const theme = useTheme();

    return (
        <div className={classnames('fm-dashboard-widget__row', className)} onClick={onClick}>
            <div className="fm-dashboard-widget__row-inner">
                {leftColumn}
                <div className="fm-dashboard-widget__row-content">
                    {!children && (
                        <>
                            <Text
                                className="fm-dashboard-widget__row-title"
                                isTruncated
                                useTooltip
                                color={theme.colors.utility.textPrimary}
                            >
                                {title}
                            </Text>
                            <Text
                                className="fm-dashboard-widget__row-subtitle"
                                type="caption"
                                color={theme.colors.utility.textSecondary}
                                isTruncated
                                useTooltip
                            >
                                {subtitle}
                            </Text>
                        </>
                    )}
                    {children}
                </div>
            </div>
        </div>
    );
});

export default DashboardWidgetRow;
