import React, { memo, useMemo, useCallback, Fragment } from 'react';
import { Text, useTheme } from 'hoi-poi-ui';
import CircularPercentage from 'components/CircularPercentage';
import Row from './Row';

import './styles.scss';

const TabPreview = memo(({ rows, footer }) => {
    const theme = useTheme();

    const renderTop = useMemo(() => {
        if (!rows?.length) return null;
        return rows.map((current) => {
            if (!current?.value) return null;
            return <Row label={current.label} value={current.value} />;
        });
    }, [rows]);

    const calculatePercentage = useCallback((percentageValue) => {
        let percentageClass = 'circle-red';
        if (percentageValue >= 25 && percentageValue < 75) {
            percentageClass = 'circle-yellow';
        } else if (percentageValue >= 75) {
            percentageClass = 'circle-green';
        }

        return {
            value: percentageValue,
            class: percentageClass,
        };
    }, []);

    const renderPercentage = useMemo(() => {
        if (!footer?.hasOwnProperty('percentage')) return null;

        const percentageInfo = calculatePercentage(footer.percentage);
        return (
            <div className="fm-entity-detail_tab__preview__footer-percentage">
                <CircularPercentage
                    rootClassName="preview__row__circular-percentage"
                    percentage={percentageInfo.value}
                    strokeWidth={10}
                    classForPercentage={percentageInfo.class}
                    textForPercentage={`${percentageInfo.value}%`}
                >
                    <Text
                        className="preview__row__percentage-text"
                        type="badges"
                        color={theme.colors.utility.textPrimary}
                    >{`${percentageInfo.value}%`}</Text>
                </CircularPercentage>
            </div>
        );
    }, [footer, calculatePercentage, theme]);

    const renderFooter = useMemo(() => {
        if (!footer?.value) return null;
        return (
            <Fragment>
                {footer?.hasOwnProperty('icon') && (
                    <div className="fm-entity-detail__tab__preview__footer-icon">{footer.icon}</div>
                )}
                {footer?.hasOwnProperty('percentage') && renderPercentage}
                <Text
                    className="fm-entity-detail__tab__preview__footer-text"
                    color={theme.colors.utility.textPrimary}
                >
                    {footer.value}
                </Text>
            </Fragment>
        );
    }, [footer, renderPercentage, theme]);

    if (!rows?.length && !footer?.value) return null;

    return (
        <div className="fm-entity-detail__tab__preview">
            {rows?.length > 0 && (
                <div className="fm-entity-detail__tab__preview__rows">{renderTop}</div>
            )}
            <div className="fm-entity-detail__tab__preview__footer">{renderFooter}</div>
        </div>
    );
});

export default TabPreview;
