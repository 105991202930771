import { memo, useCallback, useMemo, Fragment } from 'react';
import classNames from 'classnames';
import { useTheme, Button, Icon, Text } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import { BILLING_PLATFORM_RECURLY, BILLING_PLATFORM_NETSUITE, VALIDCARDS } from '../../constants';
import Chip from './components/Chip';
import CardLogo from './components/CardLogo';
import NoCard from './components/NoCard';

import './styles.scss';

const CardAccount = memo(({ billingPlatform, cardInfo, accountInfo, editCardUrl }) => {
    const theme = useTheme();

    const availableCards = useMemo(() => {
        return billingPlatform === BILLING_PLATFORM_RECURLY ? (
            <div className="fm-cardAccount__validCards">
                {Object.entries(VALIDCARDS).map(([key, card]) => (
                    <span key={key} className="fm-cardAccount__validCards__item" title={card.name}>
                        <CardLogo name={key} className="cardIcon" width={40} height={20} />
                    </span>
                ))}
            </div>
        ) : null;
    }, [billingPlatform]);

    const cardContent = useMemo(() => {
        let content;

        switch (true) {
            case billingPlatform === BILLING_PLATFORM_RECURLY && !!cardInfo:
                const { type, holder, expiryDate, lastDigits } = cardInfo;
                content = (
                    <Fragment>
                        <div className="fm-cardAccount__card__row">
                            <Chip className="chip" width={36} height={26} />
                            {!!VALIDCARDS[type] && (
                                <CardLogo name={type} className="cardIcon" width={40} height={20} />
                            )}
                        </div>
                        <div className="fm-cardAccount__card__row">
                            <Text type="h6" color={theme.colors.primary.white}>
                                ●●●●
                            </Text>
                            <Text type="h6" color={theme.colors.primary.white}>
                                ●●●●
                            </Text>
                            <Text type="h6" color={theme.colors.primary.white}>
                                ●●●●
                            </Text>
                            <Text type="h6" color={theme.colors.primary.white}>
                                {lastDigits}
                            </Text>
                        </div>
                        <div className="fm-cardAccount__card__row">
                            <Text type="body" color={theme.colors.primary.white}>
                                {`${getLiteral('label_cvv')} •••`}
                            </Text>
                        </div>
                        <div className="fm-cardAccount__card__row">
                            <div className="fm-cardAccount__card__column">
                                <Text type="badges" color={theme.colors.primary.white}>
                                    {getLiteral('label_card_holder')}
                                </Text>
                                <Text type="caption" color={theme.colors.primary.white}>
                                    {holder}
                                </Text>
                            </div>
                            <div className="fm-cardAccount__card__column fm-cardAccount__card__column--alignRight">
                                <Text type="badges" color={theme.colors.primary.white}>
                                    {getLiteral('label_expiry_date')}
                                </Text>
                                <Text type="caption" color={theme.colors.primary.white}>
                                    {expiryDate}
                                </Text>
                            </div>
                        </div>
                    </Fragment>
                );
                break;
            case billingPlatform === BILLING_PLATFORM_NETSUITE && !!accountInfo:
                content = (
                    <Fragment>
                        <div className="fm-cardAccount__card__row">
                            <div className="fm-cardAccount__card__column">
                                <Text type="body" color={theme.colors.primary.white}>
                                    {getLiteral('label_bank_transfer')}
                                </Text>
                                <Text type="h6" color={theme.colors.primary.white}>
                                    {accountInfo.holder}
                                </Text>
                            </div>
                        </div>
                        <div className="fm-cardAccount__card__row">
                            <div className="fm-cardAccount__card__column">
                                <Text type="body" color={theme.colors.primary.white}>
                                    {getLiteral('label_account_number')}
                                </Text>
                                <Text type="body" color={theme.colors.primary.white}>
                                    {`XXXX XXXX XXXX XXXX ${accountInfo.lastDigits}`}
                                </Text>
                            </div>
                        </div>
                    </Fragment>
                );
                break;
            default:
                break;
        }

        return content;
    }, [accountInfo, billingPlatform, cardInfo, theme]);

    const handleEdit = useCallback(() => {
        window.open(editCardUrl, '_newtab');
    }, [editCardUrl]);

    const showEditCard = useMemo(
        () => billingPlatform === BILLING_PLATFORM_RECURLY && editCardUrl,
        [billingPlatform, editCardUrl],
    );

    return (
        <div className="fm-cardAccount">
            <div
                className={classNames('fm-cardAccount__card', {
                    'fm-cardAccount__card--account':
                        cardContent && billingPlatform === BILLING_PLATFORM_NETSUITE,
                    'fm-cardAccount__card--noCard': !cardContent,
                })}
            >
                {showEditCard && (
                    <Button
                        type="primary-soft"
                        className="fm-cardAccount__edit"
                        size="small"
                        icon="edit"
                        iconPosition="right"
                        onClick={handleEdit}
                    >
                        {getLiteral('action_edit_card')}
                    </Button>
                )}
                {!!cardContent ? cardContent : <NoCard />}
            </div>
            <div className="fm-cardAccount__footer">
                {availableCards}
                <span className="fm-cardAccount__securePayment">
                    <Icon name="securePayment" color={theme.colors.grey[500]} />
                    <Text type="caption" color={theme.colors.utility.textSecondary}>
                        {getLiteral('label_secure_payments')}
                    </Text>
                </span>
            </div>
        </div>
    );
});

export default CardAccount;
