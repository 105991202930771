import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs as TabsComponent } from 'hoi-poi-ui';

import './styles.scss';

const propTypes = {
    content: PropTypes.array,
    tabControlled: PropTypes.number,
    onChange: PropTypes.func,
    className: PropTypes.string,
    showScrollButtons: PropTypes.string, //'on' or 'off'
    tabsHeaderClass: PropTypes.string,
    tabsClassesObj: PropTypes.object,
    tabClassesObj: PropTypes.object,
    isWidget: PropTypes.bool,
    withScroll: PropTypes.bool,
};

const Tabs = ({
    content,
    className,
    onChange,
    tabControlled,
    tabsHeaderClass,
    tabsClassesObj,
    tabClassesObj,
    isWidget,
    withScroll, //for scroll padding
}) => {
    const [state, setState] = useState({
        tabOpened: 0,
        finalContent: [],
    });

    const renderTitle = useCallback((tab) => {
        if (!tab.count) return tab.title;
        return (
            <span className="fm-tabs__tab__title">
                {tab.title}
                <span className="fm-tabs__counter">({tab.count})</span>
            </span>
        );
    }, []);

    useEffect(() => {
        // Add the key prop to the tabs schema for compatibility with the hoi-poi component
        const finalContent = content.map((item, index) => {
            const finalItem = { ...item };
            finalItem.title = renderTitle(item);
            if (!finalItem?.key) finalItem.key = index.toString();
            return finalItem;
        });
        setState((state) => ({ ...state, finalContent }));
    }, [content, renderTitle]);

    const selectTab = useCallback((value) => {
        setState((state) => ({ ...state, tabOpened: value }));
    }, []);

    const handleOnChange = useCallback(
        (value) => {
            const finalValue = state.finalContent.findIndex((obj) => obj.key === value.toString());
            (onChange && onChange(null, finalValue)) || selectTab(finalValue);
        },
        [onChange, selectTab, state.finalContent],
    );

    const renderTabs = useMemo(() => {
        const { tabOpened, finalContent } = state;
        const value = onChange ? tabControlled : tabOpened;

        let tabsClasses = {
            root: ['fm-tabs-root'],
            flexContainer: ['fm-tabs-flex-container'],
            indicator: ['fm-tabs-indicator'],
            scrollButton: ['fm-tabs-scroll-buttons'],
        };

        if (content.length === 1) {
            // only 1 tab. "Hide" the tabs
            tabsClasses.root.push('fm-tabs-single-tab');
        }

        if (tabsClassesObj) {
            tabsClassesObj.root && tabsClasses.root.push(tabsClassesObj.root);
            tabsClassesObj.flexContainer &&
                tabsClasses.flexContainer.push(tabsClassesObj.flexContainer);
            tabsClassesObj.indicator && tabsClasses.indicator.push(tabsClassesObj.indicator);
            tabsClassesObj.scrollButton &&
                tabsClasses.scrollButton.push(tabsClassesObj.scrollButton);
        }

        let tabClasses = {
            root: ['fm-tab-root'],
            selected: ['fm-tab-selected'],
        };

        if (tabClassesObj) {
            tabClassesObj.root && tabClasses.root.push(tabClasses.root);
            tabClassesObj.selected && tabClasses.selected.push(tabClasses.selected);
        }

        let tabsHeaderClassNames = ['fm-tabs-header'];
        if (tabsHeaderClass) tabsHeaderClassNames.push(tabsHeaderClass);

        if (!withScroll && isWidget) tabsHeaderClassNames.push('is-widget no-scroll');
        if (isWidget) tabsHeaderClassNames.push('is-widget');
        if (withScroll) tabsHeaderClassNames.push('with-scroll');

        return (
            <div className={tabsHeaderClassNames.join(' ')}>
                <TabsComponent
                    className={tabsClasses.root.join(' ')}
                    activeKey={finalContent[value]?.key}
                    onChange={handleOnChange}
                    tabs={finalContent}
                />
            </div>
        );
    }, [
        content.length,
        handleOnChange,
        isWidget,
        onChange,
        state,
        tabClassesObj,
        tabControlled,
        tabsClassesObj,
        tabsHeaderClass,
        withScroll,
    ]);

    return <div className={`fm-tabs ${className || ''}`}>{renderTabs}</div>;
};

Tabs.propTypes = propTypes;

export default memo(Tabs);
