import React, { Fragment, useMemo } from 'react';
import ContentLoader from 'react-content-loader';

import './styles.scss';

const CommentsPlaceholder = ({ height = 120, width = 512, theme, ...props }) => {
    const rowHeight = 50;
    const numRows = height > 210 ? Math.floor((height - 100) / rowHeight) : 0;

    const titleWidth = width * 0.27;
    const borderWidth = width * 0.94;
    const longTextWidth = width * 0.94;
    const shortTextWidth = width * 0.2;
    const mediumTextWidth = width * 0.5;

    const renderRows = useMemo(() => {
        let rows = [];
        let baseY = 110;

        for (let i = 0; i < numRows; i++) {
            rows.push(
                <Fragment key={i}>
                    <rect x="0" y={baseY + 15} rx="3" ry="3" width={mediumTextWidth} height="8" />
                    <rect x="0" y={baseY + 30} rx="3" ry="3" width={longTextWidth} height="8" />
                </Fragment>,
            );
            baseY = baseY + rowHeight;
        }
        return rows;
    }, [longTextWidth, mediumTextWidth, numRows]);

    return (
        <div className="fm-comments__placeholder">
            <ContentLoader
                height={height}
                width={width}
                speed={2}
                backgroundColor={theme.colors.grey[50]}
                foregroundColor={theme.colors.grey[100]}
                {...props}
            >
                <rect x="0" y="0" rx="3" ry="3" width={titleWidth} height="8" />
                <rect x="0" y="22" rx="3" ry="3" width={borderWidth} height="1" />
                <rect x="0" y="40" rx="3" ry="3" width={longTextWidth} height="8" />
                <rect x="0" y="65" rx="3" ry="3" width={shortTextWidth} height="8" />
                <rect x="0" y="80" rx="3" ry="3" width={longTextWidth} height="8" />
                <rect x="0" y="95" rx="3" ry="3" width={mediumTextWidth} height="8" />
                <rect x="0" y="110" rx="3" ry="3" width={longTextWidth} height="8" />
                {renderRows}
            </ContentLoader>
        </div>
    );
};

export default CommentsPlaceholder;
