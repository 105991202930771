import React from 'react';
import colors from 'constants/colors';

const EventPending = ({ color = colors.actionMinor[500] }) => (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g fillRule="evenodd" clipRule="evenodd">
            <path
                d="M17 10H7v2h10v-2zm2-7h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19a2 2 0 002 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zm-2-5H7v2h10v-2z"
                fill={color || '#FF8C00'}
            />
            <path
                d="M18 24c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z"
                fill="#fff"
            />
            <path d="M17.9 22.4a4.5 4.5 0 100-9 4.5 4.5 0 000 9z" fill={color || '#FF8C00'} />
        </g>
    </svg>
);

export default EventPending;
