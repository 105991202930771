import { memo, useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { Chip, Icon, SelectWrapper, useTheme } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';

const FontSizeSelector = ({ editor }) => {
    const theme = useTheme();
    const options = useMemo(
        () => [
            { label: getLiteral('label_default'), value: 'default' },
            {
                label: '10',
                value: 10,
            },
            {
                label: '12',
                value: 12,
            },
            {
                label: '14',
                value: 14,
            },
            {
                label: '16',
                value: 16,
            },
            {
                label: '18',
                value: 18,
            },
        ],
        [],
    );

    const popoverRef = useRef();
    const [size, setSize] = useState(options[0]);
    const [showPopover, setShowPopover] = useState(false);

    const onChange = useCallback(
        (payload) => {
            if (payload.value === 'default') {
                editor.commands.unsetFontSize();
            } else {
                editor.commands.setFontSize(`${payload.value}px`);
            }
            setSize(payload);
            setShowPopover(false);
        },
        [editor],
    );

    const handleClickOutside = useCallback((event) => {
        if (!popoverRef.current?.contains?.(event.target)) {
            setShowPopover(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    const chipProps = useMemo(
        () => ({
            isUnfolded: true,
            className: 'fm-ee__editorToolbarChip',
            onClick: () => setShowPopover(true),
        }),
        [],
    );

    const selectProps = useMemo(
        () => ({
            options,
            onChange,
            overrides: { Popover: { root: { visible: showPopover } } },
            getPopoverContentRef: (ref) => {
                if (ref) popoverRef.current = ref;
            },
        }),
        [onChange, options, showPopover],
    );

    return (
        <SelectWrapper {...selectProps}>
            <Chip {...chipProps}>
                <Icon name="fontSize" color={theme.colors.actionMinor[500]} />
                {size.value !== 'default' && size.value}
            </Chip>
        </SelectWrapper>
    );
};

export default memo(FontSizeSelector);
