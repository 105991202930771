import React from 'react';
import { Text, useTheme } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';

import './styles.scss';

const Header = () => {
    const theme = useTheme();
    return (
        <div className="company-settings-header">
            <Text className="company-settings-header__title" type="h5">
                {getLiteral('title_my_account')}
            </Text>
            <Text
                className="company-settings-header__subtitle"
                type="body"
                color={theme.colors.utility.textSecondary}
            >
                {getLiteral('helptext_my_account')}
            </Text>
        </div>
    );
};

export default Header;
