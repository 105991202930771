import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import ContentLoader from 'react-content-loader';
import { Button, Icon, Text, useTheme, ToastContainer } from 'hoi-poi-ui';
import {
    useOnboarding,
    OnboardingModal as Modal,
    OnboardingAddForm as AddForm,
    OnboardingEditForm as EditForm,
    SectionList,
} from '@web/web5';
import {
    activateUser,
    getAvatar,
    getUsers,
    setUsers,
    getRoles,
    checkEmail,
    sendInvitations,
    revokeInvitation,
    getConfig,
} from 'services/Onboarding';
import {
    getLiteral,
    getLiteralWithParameters,
    getLiteralWithParametersHtml,
} from 'utils/getLiteral';
import { successToast, errorToast, USER_MANAGEMENT_TOAST } from 'utils/toast';
import { logEvent } from 'utils/tracking';
import { PRODUCT_MODE_CODES } from 'constants/Constants';
import { USERS } from 'constants/Entities';
import UsageLimitInfo from 'containers/components/UsageLimitInfo';
import CancelAccount from 'containers/settings/Billing/components/CancelAccount';
import useSettings from '../hooks/useSettings';
import { PURCHASE_SEATS } from '../PurchaseModal/constants';
import './styles.scss';

const mapStateToProps = (state) => {
    const config = state.config || {};
    const { permission, productModeCode, billingPlatformPlanCode, userData } = config;
    const { manageUsersFromWeb = 0 } = userData;

    return {
        billingPlatformPlanCode,
        manageUsersFromWeb,
        permission,
        productModeCode,
        userData,
    };
};

const UsersManagement = ({
    billingPlatformPlanCode,
    manageUsersFromWeb,
    licenseName,
    permission,
    productModeCode,
    userData,
    withDeleteAccount,
}) => {
    const isFirstLoad = useRef(true);
    const modalInputRef = useRef(null);
    const theme = useTheme();

    const [config, setConfig] = useState({});
    const [loading, setLoading] = useState(true);
    const [userRoles, setUserRoles] = useState([]);

    const {
        handleModalPurchase,
        handleUpgradePlan,
        planName,
        renderPurchaseModal,
        renderUpgradeModal,
        showComparePlans,
        renderSupportChat,
    } = useSettings({
        licenseName,
    });

    const {
        state,
        types,
        dispatch,
        isRequired,
        isEmail,
        isDuplicatedEmail,
        formHasErrors,
        handleDelete,
        handleEdit,
        handleEditFormChange,
        handleGetData,
        userManagement,
    } = useOnboarding({
        customEmailValidation: checkEmail,
        userRoles,
        getData: () => getUsers(productModeCode),
        getDataCallback: (users) =>
            users.reduce((userArr, { id, email, role, status, name, surname }) => {
                return [
                    ...userArr,
                    {
                        id,
                        fullname: name && surname ? `${name} ${surname}` : name ? name : email,
                        name,
                        surname,
                        email,
                        roleId: role?.id,
                        role: role?.value,
                        status,
                    },
                ];
            }, []),
        setData: (data) => setUsers(productModeCode, data),
        parseData: (users) =>
            users.reduce((arr, user) => {
                const { email, id, name, roleId, surname, status } = user;
                return [
                    ...arr,
                    {
                        id,
                        email: email,
                        role: roleId,
                        name,
                        surname,
                        status,
                    },
                ];
            }, []),
        settings: {
            billingPlatformPlanCode,
            config,
            licenses: PRODUCT_MODE_CODES,
            permission,
            userData,
        },
        canEditUsersWithSpecialRoles: true,
        isUserManagement: true,
    });

    const {
        parsedRoles,
        roleOptions,
        roleTypes,
        roleDefaultValue,
        handleAddFormChange,
        handleAddFormBlur,
        handleAddFormSave,
        handleEditFormBlur,
        handleEditFormSave,
        getAdvice,
        permissions,
        usersPerSpecialRole,
    } = userManagement;

    const loader = useMemo(
        () => (
            <ContentLoader
                height={450}
                width={2000}
                speed={2}
                backgroundColor={theme.colors.grey[50]}
                foregroundColor={theme.colors.grey[100]}
            >
                <rect x="10" y="20" rx="3" ry="3" width={250} height="8" />
                <rect x="10" y="60" rx="3" ry="3" width={600} height="8" />
                <rect x="10" y="95" rx="3" ry="3" width={2000} height="1" />
                <rect x="10" y="130" rx="3" ry="3" width={150} height="8" />
                <rect x="10" y="165" rx="3" ry="3" width={150} height="8" />
                <rect x="750" y="130" rx="3" ry="3" width={150} height="8" />
                <rect x="10" y="205" rx="3" ry="3" width={900} height="250" />
            </ContentLoader>
        ),
        [theme.colors.grey],
    );

    const handleGetConfig = useCallback(
        (doGetRoles = true) => {
            getConfig(productModeCode)
                .then((config = {}) => {
                    setConfig(config);
                    if (config.allowRoles && doGetRoles) {
                        getRoles(productModeCode)
                            .then((roles) => {
                                setUserRoles(roles);
                            })
                            .catch((error) => console.error(error))
                            .finally(() => {
                                setLoading(false);
                            });
                    }
                })
                .catch((error) => console.error(error))
                .finally(() => {
                    setLoading(false);
                });
        },
        [productModeCode],
    );

    useEffect(() => {
        if (!manageUsersFromWeb) {
            setLoading(false);
        } else {
            handleGetConfig();
        }

        if (isFirstLoad.current) {
            isFirstLoad.current = false;
            logEvent({ event: 'settings', submodule: 'users', functionality: 'listView' });
        }
    }, [handleGetConfig, manageUsersFromWeb, productModeCode]);

    const formSchema = useMemo(() => {
        const commonFields = [
            {
                label: getLiteral('label_email'),
                placeholder: getLiteral('placeholder_text_field'),
                labelMode: 'vertical',
                name: 'email',
                type: 'text',
                isRequired: true,
                isFullWidth: !permissions.canManageRoles,
                ref: (ref) => (modalInputRef.current = ref),
            },
        ];

        if (permissions.canManageRoles) {
            const currentAdminUserId = usersPerSpecialRole[parsedRoles[roleTypes.ADMIN]?.id]?.id;

            const isEditingAdmin =
                Object.entries(parsedRoles) &&
                state.modal.type === types.MODAL_EDIT &&
                currentAdminUserId === state.modal[types.MODAL_EDIT].values?.id;

            commonFields.push({
                label: getLiteral('label_invite_members_role'),
                placeholder: getLiteral('label_selectone'),
                labelMode: 'vertical',
                name: 'role',
                type: 'select',
                isRequired: true,
                isReadOnly: isEditingAdmin,
                attrs: {
                    options: roleOptions,
                },
            });
        }

        const editFields = [
            {
                label: getLiteral('label_name'),
                placeholder: getLiteral('placeholder_text_field'),
                labelMode: 'vertical',
                name: 'name',
                type: 'text',
            },
            {
                label: getLiteral('label_surname'),
                placeholder: getLiteral('placeholder_text_field'),
                labelMode: 'vertical',
                name: 'surname',
                type: 'text',
            },
        ];

        return state.modal.type === types.MODAL_ADD
            ? commonFields
            : [...editFields, ...commonFields];
    }, [
        parsedRoles,
        permissions.canManageRoles,
        roleOptions,
        roleTypes,
        state.modal,
        types.MODAL_ADD,
        types.MODAL_EDIT,
        usersPerSpecialRole,
    ]);

    const validateField = useCallback(
        (field, value, index) => {
            let error = null;

            switch (field) {
                case 'email':
                    error = isRequired(value) || isEmail(value) || isDuplicatedEmail(value, index);
                    break;
                case 'role':
                    error = isRequired(value);
                    break;
                default:
                    break;
            }

            return error;
        },
        [isRequired, isEmail, isDuplicatedEmail],
    );

    const handleInviteUsers = useCallback(() => {
        dispatch({ type: types.OPEN_MODAL, value: types.MODAL_ADD });
    }, [dispatch, types]);

    const handleAddSeats = useCallback(() => {
        handleModalPurchase &&
            handleModalPurchase({
                type: PURCHASE_SEATS,
                payload: {
                    onSuccess: () => {
                        setLoading(true);
                        handleGetData();
                        handleGetConfig(false);
                    },
                },
            });

        logEvent({
            event: 'settings',
            submodule: 'users',
            functionality: 'buyLicences',
        });
    }, [handleGetConfig, handleGetData, handleModalPurchase]);

    const getHeader = useMemo(
        () => (
            <div className="fm-um__header">
                <div className="fm-um__header__content">
                    <Text type="h5">{getLiteral('title_user_management')}</Text>
                    <Text
                        type="body"
                        color={theme.colors.utility.textSecondary}
                        className="fm-um__headerDescription"
                    >
                        {getLiteralWithParametersHtml(
                            'title_user_management_desc',
                            [planName],
                            (text) => (
                                <strong>{text}</strong>
                            ),
                        )}
                    </Text>
                    {renderSupportChat}
                </div>
                {showComparePlans && (
                    <Button type="primary" onClick={handleUpgradePlan}>
                        {getLiteral('action_compare_your_plan')}
                    </Button>
                )}
            </div>
        ),
        [planName, showComparePlans, handleUpgradePlan, renderSupportChat, theme],
    );

    const getSubHeader = useMemo(() => {
        const buttonProps = {
            type: 'secondary',
            onClick: permissions.canInviteUser ? () => handleInviteUsers() : () => handleAddSeats(),
            isDisabled: !permissions.canInviteUser && !permissions.canAddLicenses,
        };

        const buttonLiteral = getLiteral(
            permissions.canInviteUser ? 'action_invite_members' : 'action_add_licenses',
        );

        const showAddBtn =
            (billingPlatformPlanCode !== 'free' && !permissions.canInviteUser) ||
            permissions.canInviteUser;

        return (
            <div className="fm-um__subheader">
                <div className="fm-um__subheaderContent">
                    <Text type="h5">{getLiteralWithParameters('label_plan_name', [planName])}</Text>
                    {!!(config.totalUser && config.totalPurchased) && (
                        <div className="fm-um__subheaderContent__subtitle">
                            <Text type="body" color={theme.colors.utility.textSecondary}>
                                {getLiteralWithParameters('label_active_users', [
                                    config.totalUser,
                                    config.totalPurchased,
                                ])}
                            </Text>
                            <UsageLimitInfo entity={USERS} hideCount />
                        </div>
                    )}
                </div>
                {showAddBtn && <Button {...buttonProps}>{buttonLiteral}</Button>}
            </div>
        );
    }, [
        billingPlatformPlanCode,
        config,
        handleInviteUsers,
        permissions.canAddLicenses,
        permissions.canInviteUser,
        planName,
        handleAddSeats,
        theme,
    ]);

    const getInfoForTag = useCallback((data) => {
        if (!data) return null;
        const { status } = data;
        let type = undefined;
        let literal = undefined;

        switch (status) {
            case -1:
                type = 'error';
                literal = 'label_deactivated';
                break;
            case 0:
                literal = 'label_pending';
                break;
            case 1:
                type = 'success';
                literal = 'label_active';
                break;
            default:
                break;
        }

        return {
            type,
            value: `${getLiteral(literal)}`,
        };
    }, []);

    const handleSendInvitations = useCallback(() => {
        sendInvitations(productModeCode)
            .then(() => {
                successToast({
                    text: getLiteral('cfm_success_invite_users'),
                    containerId: USER_MANAGEMENT_TOAST,
                });
                state.modal[types.MODAL_ADD].values.forEach(() => {
                    logEvent({
                        event: 'settings',
                        submodule: 'users',
                        functionality: 'sendInvite',
                    });
                });
            })
            .catch(() => {
                errorToast({
                    title: getLiteral('cfm_label_error'),
                    text: getLiteral('cfm_error_invite_users'),
                    containerId: USER_MANAGEMENT_TOAST,
                });
            });
    }, [productModeCode, state.modal, types.MODAL_ADD]);

    const handleResendInvitation = useCallback(
        (row) => {
            sendInvitations(productModeCode, row.id)
                .then(() => {
                    successToast({
                        text: getLiteral('cfm_success_resend_invitation'),
                        containerId: USER_MANAGEMENT_TOAST,
                    });
                    logEvent({
                        event: 'settings',
                        submodule: 'users',
                        functionality: 'reSendInvite',
                    });
                })
                .catch(() => {
                    errorToast({
                        title: getLiteral('cfm_label_error'),
                        text: getLiteral('cfm_error_resend_invitation'),
                        containerId: USER_MANAGEMENT_TOAST,
                    });
                });
        },
        [productModeCode],
    );

    const handleRevokeUser = useCallback(
        (row) => {
            revokeInvitation(productModeCode, row.id)
                .then(() => {
                    handleDelete(row);
                    successToast({
                        text: getLiteral('cfm_success_revoke_invitation'),
                        containerId: USER_MANAGEMENT_TOAST,
                    });
                    logEvent({
                        event: 'settings',
                        submodule: 'users',
                        functionality: 'revokeInvite',
                    });
                })
                .catch(() => {
                    errorToast({
                        title: getLiteral('cfm_label_error'),
                        text: getLiteral('cfm_error_revoke_invitation'),
                        containerId: USER_MANAGEMENT_TOAST,
                    });
                });
        },
        [handleDelete, productModeCode],
    );

    const handleActivateUser = useCallback(
        (row, status) => {
            const isUserActive = !!status;
            activateUser(row.id, { isUserActive })
                .then(() => {
                    handleGetData();
                    handleGetConfig(false);
                    successToast({
                        text: getLiteral(
                            isUserActive
                                ? 'cfm_success_activated_user'
                                : 'cfm_success_deactivated_user',
                        ),
                        containerId: USER_MANAGEMENT_TOAST,
                    });
                    logEvent({
                        event: 'settings',
                        submodule: 'users',
                        functionality: isUserActive ? 'activate' : 'deactivate',
                    });
                })
                .catch((error) => {
                    console.error(error);
                    errorToast({
                        title: getLiteral('cfm_label_error'),
                        text: getLiteral(
                            isUserActive ? 'cfm_error_activated_user' : 'cfm_error_deactivate_user',
                        ),
                        containerId: USER_MANAGEMENT_TOAST,
                    });
                });
        },
        [handleGetData, handleGetConfig],
    );

    const tableProps = useMemo(() => {
        const columnDefs = [
            {
                colId: 'name',
                headerName: getLiteral('label_members'),
                field: 'fullname',
                cellRenderer: 'avatarCell',
                suppressSizeToFit: false,
                pinned: 'left',
                minWidth: 250,
                resizable: true,
                cellRendererParams: {
                    bold: true,
                    innerMethods: {
                        getPng: getAvatar,
                    },
                    otherParams: {
                        initials: (data) =>
                            data.name && data.surname
                                ? data.fullname
                                : data.email.substring(0, 2).split('').join(' '),
                    },
                },
            },
            {
                colId: 'email',
                headerName: getLiteral('label_email'),
                field: 'email',
                cellRenderer: 'textCell',
                suppressSizeToFit: false,
                minWidth: 250,
                resizable: true,
            },
            {
                colId: 'role',
                headerName: getLiteral('label_invite_members_role'),
                field: 'role',
                cellRenderer: 'textCell',
                suppressSizeToFit: true,
                resizable: true,
                width: 150,
            },
            {
                colId: 'status',
                headerName: getLiteral('label_status'),
                field: 'status',
                cellRenderer: 'tagCell',
                suppressSizeToFit: true,
                resizable: true,
                width: 200,
                cellRendererParams: {
                    innerMethods: {
                        getInfoForTag,
                    },
                },
            },
        ];

        // Remove "Roles" column from table if canManageRoles = false
        if (!permissions.canManageRoles) {
            columnDefs.splice(2, 1);
        }

        return {
            className: classNames({ 'fm-um__sectionList': state.loading }),
            columnDefs,
            rowData: state.data,
            domLayout: 'autoHeight',
            useLoaderPlaceholder: true,
            isLoading: state.loading,
            forceActionsRefresh: true,
            actions: [
                (row) => {
                    return row.status === 0
                        ? {
                              icon: (
                                  <Icon name="send" onClick={() => handleResendInvitation(row)} />
                              ),
                              tooltip: { content: getLiteral('action_resend_invitation') },
                          }
                        : null;
                },
                (row) => {
                    return row.status === 0
                        ? {
                              icon: <Icon name="cancel" onClick={() => handleRevokeUser(row)} />,
                              tooltip: { content: getLiteral('action_revoke_invitation') },
                          }
                        : null;
                },
                (row) => {
                    return row.status === 1
                        ? {
                              icon: (
                                  <Icon
                                      name="edit"
                                      onClick={() =>
                                          handleEdit({
                                              name: row.name,
                                              surname: row.surname,
                                              email: row.email,
                                              role: {
                                                  label: row.role,
                                                  value: row.roleId,
                                              },
                                              id: row.id,
                                          })
                                      }
                                  />
                              ),
                              tooltip: { content: getLiteral('cfm_action_edit_user') },
                          }
                        : null;
                },
                (row) => {
                    // Show if the user is active and is not the current user and is not admin
                    return row.status === 1 &&
                        parseInt(userData.idUsuario, 10) !== row.id &&
                        (row.roleId !== parsedRoles[roleTypes.ADMIN]?.id ||
                            !permissions.canManageRoles)
                        ? {
                              icon: (
                                  <Icon
                                      name="personRemove"
                                      onClick={() => handleActivateUser(row, false)}
                                  />
                              ),
                              tooltip: { content: getLiteral('action_deactivate_user') },
                          }
                        : null;
                },
                (row) => {
                    return row.status === -1
                        ? {
                              icon: (
                                  <Icon
                                      name="personAdd"
                                      className={classNames({
                                          'fm-um__notAllowed': !permissions.canActivateUser,
                                      })}
                                      onClick={() =>
                                          permissions.canActivateUser &&
                                          handleActivateUser(row, true)
                                      }
                                  />
                              ),
                              tooltip: {
                                  content: getLiteral(
                                      permissions.canActivateUser
                                          ? 'action_activate_user'
                                          : permissions.activateUserTooltip ||
                                                'label_necessary_to_add_licences',
                                  ),
                              },
                          }
                        : null;
                },
            ],
            rowClassRules: {
                'ag-row--disabled': ({ data }) => data.status === -1,
            },
        };
    }, [
        getInfoForTag,
        handleActivateUser,
        handleEdit,
        handleResendInvitation,
        handleRevokeUser,
        parsedRoles,
        permissions.activateUserTooltip,
        permissions.canActivateUser,
        permissions.canManageRoles,
        roleTypes.ADMIN,
        state.data,
        state.loading,
        userData.idUsuario,
    ]);

    const modalProps = useMemo(() => {
        let customProps = {};
        let form;
        let preComponent = null;

        const commonProps = {
            isOpen: state.modal.isOpen,
            onRequestClose: () => dispatch({ type: types.CLOSE_MODAL }),
            onCancel: () => dispatch({ type: types.CLOSE_MODAL }),
            modalInputRef: modalInputRef,
        };

        switch (state.modal.type) {
            case types.MODAL_EDIT:
                form = state.modal[types.MODAL_EDIT].values;
                preComponent = getAdvice(form);
                customProps = {
                    title: getLiteral('label_edit_member'),
                    onConfirm: () => {
                        handleEditFormSave();
                        logEvent({
                            event: 'settings',
                            submodule: 'users',
                            functionality: 'update',
                        });
                    },
                    isConfirmDisabled: formHasErrors(types.MODAL_EDIT),
                    preComponent,
                };
                break;
            case types.MODAL_ADD:
                form = state.modal[types.MODAL_ADD].values;
                form.every((value) => {
                    preComponent = getAdvice(value);
                    return !preComponent;
                });
                customProps = {
                    title: getLiteral('label_invite_members'),
                    description: getLiteral('label_invite_members_desc'),
                    confirmText: getLiteral('action_send_invitations'),
                    onConfirm: () =>
                        handleAddFormSave(validateField, formSchema, handleSendInvitations),
                    isConfirmDisabled: formHasErrors(types.MODAL_ADD),
                    preComponent,
                };
                break;
            default:
                break;
        }

        return {
            ...commonProps,
            ...customProps,
        };
    }, [
        state.modal,
        dispatch,
        types.CLOSE_MODAL,
        types.MODAL_EDIT,
        types.MODAL_ADD,
        getAdvice,
        handleEditFormSave,
        formHasErrors,
        handleAddFormSave,
        validateField,
        formSchema,
        handleSendInvitations,
    ]);

    const modalContent = useMemo(() => {
        let modalContentProps = null;
        let ModalContent = null;

        switch (state.modal.type) {
            case types.MODAL_EDIT:
                modalContentProps = {
                    form: state.modal[types.MODAL_EDIT],
                    onChange: (payload) => handleEditFormChange(payload, validateField),
                    onBlur: handleEditFormBlur,
                    schema: formSchema,
                    modalInputRef,
                };
                ModalContent = EditForm;
                break;
            case types.MODAL_ADD:
                // Set initial default form values
                const formValues =
                    state.modal[types.MODAL_ADD].values.length === 1 &&
                    Object.entries(state.modal[types.MODAL_ADD].values[0]).length === 0
                        ? [{ role: roleDefaultValue }]
                        : state.modal[types.MODAL_ADD].values;

                modalContentProps = {
                    form: {
                        ...state.modal[types.MODAL_ADD],
                        values: formValues,
                    },
                    onChange: (payload) => handleAddFormChange(payload, validateField, formSchema),
                    onBlur: handleAddFormBlur,
                    schema: formSchema,
                    modalInputRef,
                };
                ModalContent = AddForm;
                break;
            default:
                break;
        }

        if (!ModalContent) return null;

        return <ModalContent {...modalContentProps} />;
    }, [
        formSchema,
        handleAddFormBlur,
        handleAddFormChange,
        handleEditFormBlur,
        handleEditFormChange,
        roleDefaultValue,
        state.modal,
        types.MODAL_ADD,
        types.MODAL_EDIT,
        validateField,
    ]);

    return !loading ? (
        <div className="fm-um">
            {getHeader}
            {getSubHeader}
            <SectionList {...tableProps} />
            {withDeleteAccount && <CancelAccount />}
            <Modal {...modalProps}>{modalContent}</Modal>
            {renderUpgradeModal}
            {renderPurchaseModal}
            <ToastContainer
                position="bottomRight"
                transition="slide"
                useDefaultCloseButton={true}
                autoClose={6000}
                overrides={{
                    ToastGroup: { style: { zIndex: 2147483002 } }, // Intercom z-index is 2147483001...
                }}
                containerId={USER_MANAGEMENT_TOAST}
            />
        </div>
    ) : (
        <div className="fm-um-loader">{loader}</div>
    );
};

export default memo(connect(mapStateToProps)(UsersManagement));
