import React, { memo, Fragment } from 'react';
import { Label, Text, useTheme } from 'hoi-poi-ui';

import './styles.scss';

const Row = memo(({ title, subtitle, tooltipHint }) => {
    const theme = useTheme();
    return (
        <Fragment>
            <div>
                <Label
                    className="kpis-widget__info-container--row-label"
                    hint={tooltipHint}
                    overrides={{
                        Popover: {
                            className: 'kpis-widget__tooltip-label',
                            overrides: {
                                root: { placement: 'left' },
                            },
                        },
                    }}
                >
                    <Text type="caption" color={theme.colors.utility.textSecondary}>
                        {title}
                    </Text>
                </Label>
            </div>
            <Text type="caption" color={theme.colors.utility.textPrimary} bold>
                {subtitle}
            </Text>
        </Fragment>
    );
});

export default Row;
