import { memo, useCallback, useContext, Fragment } from 'react';
import { Chip, Tooltip } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import { logEvent } from 'utils/tracking';
import { getPartner } from 'lib/partners';
import useDana from 'hooks/useDana';

import { EmailEditorContext } from '../../../EmailEditorContext';
import './styles.scss';

const DanaSelector = ({ editor }) => {
    const partner = getPartner();
    const { hasContent, setDanaActive } = useContext(EmailEditorContext);

    const handleOnClick = useCallback(() => {
        logEvent({
            event: 'emails',
            submodule: 'sendEmail',
            functionality: 'danaAI',
        });

        setDanaActive(true, editor);
    }, [editor, setDanaActive]);

    const {
        danaActivated,
        danaActivatedDisclaimer,
        renderDanaDisclaimer,
        setDanaModalAfterDisclaimer,
    } = useDana({
        onConfirmDisclaimerSuccess: handleOnClick,
        overrideModalOverlayClassName: 'fm-ee__dana-disclaimer',
    });

    const onClick = useCallback(() => {
        if (danaActivatedDisclaimer) {
            handleOnClick();
        } else {
            setDanaModalAfterDisclaimer(true);
        }
    }, [danaActivatedDisclaimer, handleOnClick, setDanaModalAfterDisclaimer]);

    if (!danaActivated) return null;

    return (
        <Fragment>
            <Tooltip content={getLiteral('label_enhance_email_hover')}>
                <Chip
                    className="fm-ee__dana-selector"
                    onClick={onClick}
                    size="large"
                    isDisabled={!hasContent}
                >
                    <div className="fm-ee__dana-selector__icon">{partner.SageCopilotIcon}</div>
                    <span>{getLiteral('label_enhance_email')}</span>
                </Chip>
            </Tooltip>
            {renderDanaDisclaimer}
        </Fragment>
    );
};

export default memo(DanaSelector);
