import React, { memo, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import { Icon, Text, Avatar, useTheme, Link } from 'hoi-poi-ui';
import { ensureRoute } from 'utils/routes';
import { getSrcUserCircleAvatar } from 'utils/getSrcAvatar';
import { getDateToTimezone } from 'utils/dates';
import { getBackendBoolean } from 'utils/fm';
import { TasksActions } from 'actions';
import { TASKS, AGENDA, COMPANIES } from 'constants/Entities';
import TaskCheckbox from 'components/TaskCheckbox';
import TruncatedText from 'components/TruncatedText';
import { getCompanyDetailUrl } from 'utils/getUrl';
import useEntityDetail from 'containers/components/EntityDetail/hooks/useEntityDetail';

import './styles.scss';

const mapStateToProps = (state) => {
    const agendaState = state.agenda;
    const filtersColor = agendaState?.filtersColor || null;
    const idUserColors = filtersColor?.filters?.idusuario?.colors || null;

    return {
        idUserColors,
        timezone: agendaState.timezone || {},
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeTaskCompleted: bindActionCreators(TasksActions, dispatch).changeTaskCompleted,
    };
};

const Task = memo(({ data, otherFields, value, changeTaskCompleted, idUserColors, timezone }) => {
    const theme = useTheme();
    const { hasTabsEnabled, openTab } = useEntityDetail();
    const colors = theme.colors;

    const onTaskClick = useCallback(() => {
        ensureRoute(`${AGENDA.route}/task/${data?.[otherFields?.id]}/edit`);
    }, [data, otherFields]);

    const onCompanyClick = useCallback(
        (e) => {
            if (!hasTabsEnabled || e.ctrlKey) return true;
            e.preventDefault();
            e.stopPropagation();
            openTab({
                entity: COMPANIES,
                id: data?.[otherFields?.idCompany],
                hasCrudInDetail: true,
                toTab: false,
            });
        },
        [data, hasTabsEnabled, openTab, otherFields?.idCompany],
    );

    const onCheckTask = useCallback(
        (isCompleted) => {
            changeTaskCompleted(TASKS, data, isCompleted);
        },
        [data, changeTaskCompleted],
    );

    const dateAndTime = useMemo(() => {
        const initialDate = data?.[otherFields?.fini] || null;

        const dateMoment = getDateToTimezone({
            date: initialDate,
            timezone: timezone.idianazone,
            returnMoment: true,
        });

        const date = dateMoment.format('L');
        const time = dateMoment.format('LT').toLowerCase();
        return { date, time };
    }, [data, otherFields, timezone]);

    const isCompleted = getBackendBoolean(data?.[otherFields?.finalizada]);

    const isTaskOverdue = useMemo(() => {
        const initialDate = data?.[otherFields?.fini] || null;
        const dateMoment = getDateToTimezone({
            date: initialDate,
            timezone: timezone.idianazone,
            returnMoment: true,
        });

        const nowMoment = getDateToTimezone({
            timezone: timezone.idianazone,
            returnMoment: true,
        });

        return !isCompleted && nowMoment.toDate() > dateMoment.toDate();
    }, [isCompleted, data, otherFields, timezone]);

    const itemClass = classnames('agenda-task-list__item', {
        'agenda-task-list__item-title--overdue': isTaskOverdue,
    });

    const titleClass = classnames('agenda-task-list__item-title', {
        'agenda-task-list__item-title--done': getBackendBoolean(data?.[otherFields?.finalizada]),
    });

    const ownerColor = useMemo(() => {
        return idUserColors?.[data?.[otherFields?.idUsuario]]?.default || '';
    }, [idUserColors, data, otherFields]);

    const userAvatarInfo = getSrcUserCircleAvatar(data?.[otherFields?.idUsuario]);

    if (!data) return null;

    return (
        <div className={itemClass} onClick={onTaskClick}>
            {ownerColor && (
                <div
                    className="agenda-task-list__owner-mark"
                    style={{ backgroundColor: ownerColor }}
                />
            )}

            <div className="agenda-task-list__item-status">
                <TaskCheckbox
                    onChange={(isCompleted) => onCheckTask(isCompleted)}
                    uncheckedColor={colors.grey[500]}
                    isChecked={isCompleted}
                    color={colors.utility.textSecondary}
                    size="large"
                />
            </div>
            <div className="agenda-task-list__item-data">
                <div className="agenda-task-list__row">
                    <div className={titleClass}>
                        <TruncatedText type="caption" withTooltip>
                            {value || '-'}
                        </TruncatedText>
                    </div>
                    <Avatar
                        className="agenda-task-list__item__avatar"
                        size="small"
                        src={userAvatarInfo.src}
                        placeholder={userAvatarInfo.fallbackSrc}
                        alt="image"
                    />
                </div>
                <div className="agenda-task-list__row">
                    <div className="agenda-task-list__item-company">
                        <Link
                            isTruncated
                            type="caption"
                            href={getCompanyDetailUrl(data?.[otherFields?.idCompany])}
                            target="_blank"
                            onClick={(e) => onCompanyClick(e)}
                        >
                            {data?.[otherFields?.empresa] || '-'}
                        </Link>
                    </div>
                    <div className="agenda-task-list__item-date">
                        <Text
                            type="caption"
                            isTruncated
                        >{`${dateAndTime.date}, ${dateAndTime.time}`}</Text>
                        {isTaskOverdue && (
                            <Icon
                                name="warning"
                                color={colors.semantic.negative500}
                                size="medium"
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
});

export default connect(mapStateToProps, mapDispatchToProps)(Task);
