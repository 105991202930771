const SyncPic = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={108}
        height={126}
        viewBox="0 0 126 108"
        fill="none"
        {...props}
    >
        <mask
            id="a"
            width={78}
            height={95}
            x={15}
            y={16}
            maskUnits="userSpaceOnUse"
            style={{
                maskType: 'luminance',
            }}
        >
            <path fill="#fff" d="M92.118 16.2H15.883v93.812h76.235V16.2Z" />
        </mask>
        <g mask="url(#a)">
            <path
                fill="#474747"
                d="M54.035 49.017c-.92 0-1.663.744-1.663 1.661v1.431H55.7v-1.43c0-.919-.745-1.662-1.663-1.662h-.001Z"
            />
            <path
                fill="#E84D4F"
                d="M54.888 50.019a1.018 1.018 0 1 1-1.691 1.134 1.018 1.018 0 0 1 1.691-1.134Z"
            />
            <path
                fill="#474747"
                d="M53.026 50.493s.885-.174 1.393-.856l.12-.31-.86-.07-.838.564-.123.796.31-.123-.002-.001Z"
            />
            <path
                fill="#454545"
                d="m58.777 108.957 2.41 1.009a.579.579 0 1 0 .448-1.069l-2.41-1.009a.578.578 0 1 0-.448 1.069ZM47.914 108.56l2.41 1.01a.579.579 0 1 0 .448-1.069l-2.41-1.01a.579.579 0 1 0-.448 1.069Z"
            />
            <path
                fill="#454545"
                d="m62.346 106.126-3.104-43.468a2.8 2.8 0 0 0-5.043-1.465 2.8 2.8 0 0 0-4.317 2.082l-3.98 42.392a2.798 2.798 0 0 0 2.527 3.047 2.799 2.799 0 0 0 3.05-2.525l2.986-31.812 2.295 32.147a2.8 2.8 0 0 0 5.587-.398Z"
            />
            <path
                fill="#919191"
                d="M62.951 41.318a1.999 1.999 0 0 0-2.462 1.388c-.005.015-.594 2.129-1.578 5.196-.343 1.07-.734 2.257-1.165 3.51-.425.66-1.444 1.765-3.503 1.765-2.19 0-3.598-1.34-4.01-1.791-.82-2.004-1.605-4.06-2.22-5.754a102.57 102.57 0 0 1-.887-2.534c-.11-.327-.196-.6-.255-.796a6.416 6.416 0 0 1-.063-.225l-.012-.045a1.998 1.998 0 0 0-3.934.705c.021.123.042.208.065.298.044.17.097.35.163.565.123.4.293.915.504 1.527a155.444 155.444 0 0 0 3.289 8.614 77.122 77.122 0 0 0 1.975 4.388c.324.653.64 1.253.952 1.787.157.268.313.52.47.756.158.237.318.46.489.67.291.358.617.624.941.835.244.158.487.284.729.389a5.637 5.637 0 0 0 1.575.423c.171.02.34.03.517.03h.061a4.822 4.822 0 0 0 1.801-.378 4.26 4.26 0 0 0 1.854-1.51c.17-.25.317-.523.437-.814 1.633-3.975 3.042-8.096 4.047-11.234 1.005-3.136 1.605-5.287 1.61-5.304a1.997 1.997 0 0 0-1.388-2.46h-.002Z"
            />
            <path
                fill="#E84D4F"
                d="M64.133 37.75a5.852 5.852 0 0 0-1.181 1.352 9.489 9.489 0 0 0-.963 2.01 8.693 8.693 0 0 0-.288.983.998.998 0 1 0 1.953.425c.006-.034.145-.623.442-1.314.147-.346.335-.715.557-1.052.221-.338.476-.639.748-.86a.999.999 0 1 0-1.267-1.544h-.001ZM42.617 38.54c.048.08.107.197.168.334.108.24.223.545.329.858a22.859 22.859 0 0 1 .522 1.791l.031.125.008.032.003.007a.999.999 0 1 0 1.943-.464 28.418 28.418 0 0 0-.38-1.392c-.113-.38-.248-.795-.396-1.193a9.799 9.799 0 0 0-.237-.58 5.448 5.448 0 0 0-.271-.535 1 1 0 0 0-1.721 1.017h.001Z"
            />
            <path
                fill="#474747"
                d="M90.81 35.833h-9.675a8.483 8.483 0 0 1-3.314-.668 8.543 8.543 0 0 1-3.748-3.085 8.46 8.46 0 0 1-1.454-4.757c0-1.178.238-2.295.669-3.311a8.545 8.545 0 0 1 3.086-3.746 8.484 8.484 0 0 1 4.761-1.452h9.675a1.308 1.308 0 0 0 0-2.614h-9.675c-1.533 0-3 .31-4.333.874-1.999.845-3.695 2.254-4.896 4.028a11.07 11.07 0 0 0-1.902 6.22c0 1.532.311 2.998.875 4.33a11.169 11.169 0 0 0 4.03 4.892 11.099 11.099 0 0 0 6.225 1.9h9.675a1.308 1.308 0 0 0 0-2.613l.001.002ZM17.19 18.814h8.804a8.48 8.48 0 0 1 3.314.667 8.55 8.55 0 0 1 3.748 3.085 8.46 8.46 0 0 1 1.454 4.757 8.465 8.465 0 0 1-.669 3.311 8.544 8.544 0 0 1-3.086 3.746 8.486 8.486 0 0 1-4.761 1.453H17.19a1.308 1.308 0 0 0 0 2.613h8.803c1.533 0 3-.31 4.333-.874 1.999-.846 3.695-2.254 4.896-4.029a11.078 11.078 0 0 0 1.902-6.219c0-1.532-.31-2.998-.875-4.33a11.163 11.163 0 0 0-4.03-4.892 11.1 11.1 0 0 0-6.226-1.902H17.19a1.308 1.308 0 0 0 0 2.614Z"
            />
            <path
                fill="#303030"
                d="M45.825 35.833h-5.867a8.484 8.484 0 0 1-3.314-.668 8.547 8.547 0 0 1-3.748-3.085 8.469 8.469 0 0 1-1.454-4.757c0-1.178.238-2.295.668-3.311a8.547 8.547 0 0 1 3.087-3.746 8.485 8.485 0 0 1 4.76-1.452h27.288c1.18 0 2.296.237 3.314.667a8.55 8.55 0 0 1 3.748 3.085 8.46 8.46 0 0 1 1.454 4.757 8.468 8.468 0 0 1-.668 3.311 8.546 8.546 0 0 1-3.087 3.746 8.485 8.485 0 0 1-4.76 1.453h-4.613v2.613h4.612a11.11 11.11 0 0 0 9.23-4.902 11.068 11.068 0 0 0 1.901-6.22c0-1.532-.31-2.998-.875-4.33a11.17 11.17 0 0 0-4.03-4.892 11.1 11.1 0 0 0-6.227-1.902H39.956c-1.532 0-3 .31-4.332.874-2 .845-3.696 2.254-4.897 4.028a11.078 11.078 0 0 0-1.902 6.22c0 1.532.311 2.998.875 4.33a11.163 11.163 0 0 0 4.031 4.892 11.1 11.1 0 0 0 6.224 1.9h5.868v-2.613l.002.002Z"
            />
            <path
                fill="#00D639"
                d="M47.357 40.113a3.062 3.062 0 1 1 0-6.122v6.122ZM60.883 33.991a3.062 3.062 0 1 1 0 6.122v-6.122Z"
            />
            <path
                fill="#008A21"
                d="M47.357 40.113c.446 0 .807-1.37.807-3.06 0-1.691-.361-3.062-.807-3.062-.445 0-.806 1.37-.806 3.061 0 1.69.36 3.061.806 3.061Z"
            />
            <path
                fill="#000C17"
                d="M47.498 38.171c0 .194-.063.35-.14.35-.078 0-.141-.156-.141-.35 0-.194.063-.35.14-.35.078 0 .14.156.14.35ZM47.498 36.042c0 .194-.063.35-.14.35-.078 0-.141-.156-.141-.35 0-.193.063-.35.14-.35.078 0 .14.157.14.35Z"
            />
            <path
                fill="#008A21"
                d="M60.883 40.113c.445 0 .806-1.37.806-3.06 0-1.691-.36-3.062-.806-3.062-.446 0-.807 1.37-.807 3.061 0 1.69.361 3.061.807 3.061Z"
            />
            <path
                fill="#000"
                d="M60.559 36.392h-1.981a.35.35 0 1 1 0-.7h1.98a.35.35 0 1 1 0 .7ZM60.559 38.521h-1.981a.35.35 0 1 1 0-.7h1.98a.35.35 0 1 1 0 .7Z"
            />
            <path
                fill="#474747"
                d="M17.19 18.814h8.804a8.48 8.48 0 0 1 3.314.667 8.55 8.55 0 0 1 3.748 3.085 8.46 8.46 0 0 1 1.454 4.757 1.308 1.308 0 0 0 2.615 0c0-1.532-.31-2.998-.875-4.33a11.163 11.163 0 0 0-4.03-4.893 11.108 11.108 0 0 0-6.226-1.9H17.19a1.308 1.308 0 0 0 0 2.614ZM72.62 27.323c0-1.178.237-2.295.668-3.311a8.545 8.545 0 0 1 3.086-3.746 8.484 8.484 0 0 1 4.761-1.452h9.675a1.308 1.308 0 0 0 0-2.614h-9.675c-1.533 0-3 .31-4.333.874-1.999.845-3.695 2.254-4.896 4.028a11.07 11.07 0 0 0-1.902 6.22 1.308 1.308 0 0 0 2.615 0v.001Z"
            />
            <path
                fill="#E84D4F"
                d="M43.678 38.176a3.437 3.437 0 0 1 .476 1.664v.055l.223.012-.223-.014a.406.406 0 0 0 .81.05c0-.008.003-.043.003-.103 0-.146-.012-.434-.092-.802a4.288 4.288 0 0 0-.498-1.275.405.405 0 1 0-.698.413h-.001ZM64.072 37.795a4.275 4.275 0 0 0-.978 1.248c-.187.36-.255.618-.262.64a.406.406 0 0 0 .786.204l-.15-.04.15.041s.06-.212.212-.5c.151-.287.399-.652.777-.983a.407.407 0 0 0-.535-.61Z"
            />
        </g>
    </svg>
);

export default SyncPic;
