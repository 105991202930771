import React, { memo } from 'react';
import { Text, Button, Spacer } from 'hoi-poi-ui';

import { getLiteral } from 'utils/getLiteral';

const RelatedProductEmpty = memo(({ emptyViewProps }) => {
    const { onClick } = emptyViewProps;
    return (
        <div className="related-products-empty">
            <svg width={168} height={144} fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M84.7449 11.2941H39.0137C37.3493 11.2941 36 12.6458 36 14.3131V114.671C36 116.338 37.3493 117.69 39.0137 117.69H84.7449C86.4093 117.69 87.7587 116.338 87.7587 114.671V14.3131C87.7587 12.6458 86.4093 11.2941 84.7449 11.2941Z"
                    fill="#777777"
                ></path>
                <path
                    d="M83.6048 109.722H40.1543V15.8156L83.6048 16.8495V109.722Z"
                    fill="#5E5E5E"
                ></path>
                <path
                    d="M61.8797 115.119C62.7567 115.119 63.4673 114.407 63.4673 113.528C63.4673 112.65 62.7567 111.938 61.8797 111.938C61.003 111.938 60.292 112.65 60.292 113.528C60.292 114.407 61.003 115.119 61.8797 115.119Z"
                    fill="#474747"
                ></path>
                <path
                    d="M51.5781 31.3209V34.9429C51.5781 36.9436 53.1969 38.5649 55.1939 38.5649C57.1907 38.5649 58.8095 36.9436 58.8095 34.9429V31.3209H51.5798H51.5781Z"
                    fill="#474747"
                ></path>
                <path
                    d="M58.8086 31.3209V34.9429C58.8086 36.9436 60.4273 38.5649 62.4244 38.5649C64.4215 38.5649 66.0399 36.9436 66.0399 34.9429V31.3209H58.8103H58.8086Z"
                    fill="#C6C6C6"
                ></path>
                <path
                    d="M66.0527 31.3209V34.9429C66.0527 36.9436 67.6712 38.5649 69.6683 38.5649C71.6653 38.5649 73.2841 36.9436 73.2841 34.9429V31.3209H66.0544H66.0527Z"
                    fill="#474747"
                ></path>
                <path
                    d="M73.2852 31.3209V34.9429C73.2852 36.9436 74.9036 38.5649 76.9007 38.5649C78.8978 38.5649 80.5165 36.9436 80.5165 34.9429V31.3209H73.2869H73.2852Z"
                    fill="#C6C6C6"
                ></path>
                <path
                    d="M80.5134 31.3209H73.2838L61.8604 15.8156H69.09L80.5134 31.3209Z"
                    fill="#ABABAB"
                ></path>
                <path
                    d="M73.2856 31.3209H66.056L54.6309 15.8156H61.8625L73.2856 31.3209Z"
                    fill="#303030"
                ></path>
                <path
                    d="M66.0522 31.3209H58.8226L47.3975 15.8156H54.6288L66.0522 31.3209Z"
                    fill="#ABABAB"
                ></path>
                <path
                    d="M58.8091 31.3209H51.5778L40.1543 15.8156H47.384L58.8091 31.3209Z"
                    fill="#303030"
                ></path>
                <path
                    d="M94.9936 31.3209H87.764L76.3389 15.8156H83.5685L94.9936 31.3209Z"
                    fill="#ABABAB"
                ></path>
                <path
                    d="M87.7488 31.3209H80.5191L69.0957 15.8156H76.3253L87.7488 31.3209Z"
                    fill="#303030"
                ></path>
                <path
                    d="M80.5186 31.3209V34.9429C80.5186 36.9436 82.1373 38.5649 84.1344 38.5649C86.1312 38.5649 87.7499 36.9436 87.7499 34.9429V31.3209H80.5202H80.5186Z"
                    fill="#474747"
                ></path>
                <path
                    d="M87.748 31.3209V34.9429C87.748 36.9436 89.3668 38.5649 91.3639 38.5649C93.3609 38.5649 94.9797 36.9436 94.9797 34.9429V31.3209H87.7497H87.748Z"
                    fill="#C6C6C6"
                ></path>
                <path
                    d="M67.817 58.7746H56.3611C56.0977 58.7746 55.8828 58.5609 55.8828 58.2954V46.8192C55.8828 46.5538 56.096 46.3401 56.3611 46.3401H67.817C68.0802 46.3401 68.2953 46.5538 68.2953 46.8192V58.2954C68.2953 58.5609 68.0819 58.7746 67.817 58.7746ZM56.8391 57.8166H67.3407V47.2981H56.8391V57.8166Z"
                    fill="#474747"
                ></path>
                <path
                    d="M67.817 78.2843H56.3611C56.0977 78.2843 55.8828 78.0709 55.8828 77.8055V66.3289C55.8828 66.0638 56.096 65.8501 56.3611 65.8501H67.817C68.0802 65.8501 68.2953 66.0638 68.2953 66.3289V77.8055C68.2953 78.0709 68.0819 78.2843 67.817 78.2843ZM56.8391 77.3263H67.3407V66.8081H56.8391V77.3263Z"
                    fill="#474747"
                ></path>
                <path
                    d="M67.817 99.7138H56.3611C56.0977 99.7138 55.8828 99.5001 55.8828 99.2346V87.7584C55.8828 87.493 56.096 87.2793 56.3611 87.2793H67.817C68.0802 87.2793 68.2953 87.493 68.2953 87.7584V99.2346C68.2953 99.5001 68.0819 99.7138 67.817 99.7138ZM56.8391 98.7558H67.3407V88.2373H56.8391V98.7558Z"
                    fill="#474747"
                ></path>
                <path
                    d="M60.2535 56.8636C59.858 56.8636 59.4641 56.6965 59.1871 56.3726L55.2943 51.8304C54.7886 51.2409 54.8558 50.3517 55.4459 49.8452C56.034 49.3386 56.9218 49.4058 57.4275 49.9968L60.4393 53.5121L76.2971 40.9276C76.9061 40.4454 77.7902 40.5487 78.2719 41.1569C78.7536 41.7668 78.6519 42.6525 78.0432 43.1351L61.1274 56.5604C60.8693 56.7654 60.5613 56.8653 60.2552 56.8653L60.2535 56.8636Z"
                    fill="#00D041"
                ></path>
                <path
                    d="M105.279 127.567H61.8789L88.8814 132.706H132.282L105.279 127.567Z"
                    fill="#00D639"
                ></path>
                <path
                    d="M69.9291 60.0825C69.5575 60.0825 69.1842 59.9464 68.8917 59.6742L65.5684 56.5742C64.9526 56.0002 64.9182 55.0354 65.4911 54.4184C66.064 53.8015 67.0288 53.7671 67.6429 54.3408L70.9662 57.441C71.582 58.0148 71.6164 58.9796 71.0435 59.5965C70.7442 59.919 70.3365 60.0825 69.9291 60.0825Z"
                    fill="#B26E3D"
                ></path>
                <path
                    d="M86.0299 69.1206C83.6801 69.1206 81.2564 68.869 79.1046 68.0798C74.3328 66.3323 69.6147 62.3622 69.4168 62.195C68.2385 61.1975 68.0906 59.4311 69.0881 58.2508C70.0843 57.0703 71.8473 56.9221 73.0255 57.9199C73.067 57.9544 77.197 61.4214 81.0243 62.8224C84.2426 64.001 89.0863 63.4202 92.2927 63.0359C93.6294 62.8758 94.6854 62.7482 95.5181 62.7482C97.0608 62.7482 98.3131 64.001 98.3131 65.5485C98.3131 67.0958 97.0628 68.3486 95.5181 68.3486C95.0191 68.3486 94.0179 68.4692 92.9568 68.5948C91.0182 68.8275 88.5671 69.1206 86.0316 69.1206H86.0299Z"
                    fill="#C6C6C6"
                ></path>
                <path
                    d="M98.2945 79.2666C97.8162 79.2666 97.3449 79.041 97.049 78.6206C96.5639 77.9314 96.7291 76.9801 97.4155 76.4942L101.282 73.7647C101.97 73.2788 102.92 73.4442 103.405 74.1318C103.89 74.8193 103.725 75.7722 103.039 76.2582L99.1717 78.9877C98.9049 79.1754 98.5988 79.2666 98.2962 79.2666H98.2945Z"
                    fill="#B26E3D"
                ></path>
                <path
                    d="M102.161 77.8105C101.203 77.8105 100.269 77.3161 99.7498 76.4287C98.9688 75.0983 99.4127 73.3855 100.737 72.5997C101.164 72.3465 101.645 71.9862 101.979 71.6866C101.68 71.2125 101.195 70.5801 100.742 70.0873C99.3867 68.7106 97.0491 68.3469 95.9965 68.3469C94.4534 68.3469 93.2012 67.0941 93.2012 65.5468C93.2012 63.9993 94.4517 62.7467 95.9965 62.7467C96.9011 62.7467 101.576 62.9139 104.771 66.205C104.784 66.2188 104.797 66.2327 104.81 66.2465C106.272 67.8161 107.977 70.1305 107.797 72.2431C107.678 73.6199 106.868 74.9105 105.318 76.189C104.473 76.887 103.663 77.3695 103.573 77.4212C103.13 77.6832 102.641 77.8072 102.161 77.8072V77.8105Z"
                    fill="#C6C6C6"
                ></path>
                <path
                    d="M103.219 130.805C101.37 130.805 99.7651 129.427 99.5277 127.54L94.486 87.5811L92.852 127.226C92.7676 129.285 91.0356 130.886 88.9747 130.802C86.9192 130.718 85.3214 128.979 85.4055 126.918L87.2563 82.017C87.461 78.2897 90.5401 75.369 94.2677 75.369C97.7096 75.369 100.622 77.8365 101.193 81.238L101.215 81.388L106.921 126.604C107.179 128.65 105.734 130.518 103.692 130.776C103.534 130.797 103.376 130.805 103.221 130.805H103.219Z"
                    fill="#4E4E4E"
                ></path>
                <path
                    d="M94.943 78.6257C96.7927 78.6257 98.292 77.1236 98.292 75.2708C98.292 73.4177 96.7927 71.9156 94.943 71.9156C93.0933 71.9156 91.5938 73.4177 91.5938 75.2708C91.5938 77.1236 93.0933 78.6257 94.943 78.6257Z"
                    fill="#C6C6C6"
                ></path>
                <path
                    d="M90.9133 67.3096C90.7862 69.772 92.6749 71.8724 95.1345 72C97.5926 72.1276 99.6894 70.2356 99.8167 67.7712C99.9441 65.3088 97.9024 65.7501 95.4442 65.6225C92.986 65.4949 91.0406 64.8455 90.9133 67.3096Z"
                    fill="#C6C6C6"
                ></path>
                <path
                    d="M91.6121 75.6119L98.2346 75.8945L99.7706 68.2159L90.9121 67.7506L91.6121 75.6119Z"
                    fill="#C6C6C6"
                ></path>
                <path
                    d="M102.56 130.722C102.491 130.722 102.423 130.714 102.354 130.693L98.8635 129.682C98.4679 129.566 98.2409 129.152 98.3544 128.756C98.4679 128.36 98.8824 128.132 99.2763 128.246L102.766 129.258C103.162 129.373 103.389 129.787 103.276 130.183C103.181 130.511 102.883 130.722 102.56 130.722Z"
                    fill="#4E4E4E"
                ></path>
                <path
                    d="M88.4651 130.722C88.4015 130.722 88.3377 130.714 88.2725 130.697L84.4881 129.685C84.0909 129.578 83.8534 129.17 83.9601 128.772C84.0669 128.374 84.4743 128.136 84.8718 128.243L88.6559 129.254C89.0535 129.361 89.2907 129.769 89.1842 130.168C89.0947 130.502 88.7937 130.722 88.4651 130.722Z"
                    fill="#4E4E4E"
                ></path>
                <path
                    d="M97.5805 76.7526C97.4342 76.7526 97.2947 76.6648 97.2346 76.5199C97.1572 76.3285 97.2484 76.1114 97.4376 76.0323L98.8911 75.436C99.082 75.3586 99.2989 75.4498 99.3779 75.6393C99.4553 75.8307 99.3641 76.0478 99.1749 76.1269L97.7214 76.7232C97.6751 76.7421 97.6268 76.7509 97.5805 76.7509V76.7526Z"
                    fill="#B26E3D"
                ></path>
                <path
                    d="M97.3554 60.8939C96.8481 61.547 95.8968 61.6487 95.2621 61.1043C94.6962 60.62 94.5929 59.7603 95.0266 59.1535C95.3188 58.7452 95.7782 58.5349 96.241 58.5453C96.542 58.5504 96.8447 58.652 97.101 58.8503C97.5672 59.2139 97.751 59.8066 97.6203 60.3462C97.574 60.539 97.4862 60.7251 97.3554 60.8922V60.8939Z"
                    fill="#B26E3D"
                ></path>
                <path
                    d="M95.8311 58.5936C95.9189 58.8107 96.0857 59.2069 96.5279 59.8292C96.7411 60.129 97.0937 60.6079 97.3964 60.844C97.5048 60.6906 97.5805 60.5252 97.6217 60.3529C97.7542 59.812 97.5684 59.2173 97.1005 58.8537C96.8444 58.6537 96.5398 58.5521 96.2388 58.547C96.1013 58.5436 95.9635 58.5609 95.8311 58.5953V58.5936Z"
                    fill="#474747"
                ></path>
                <path
                    d="M100.307 59.8238C99.743 60.5495 98.6834 60.6613 97.98 60.0514C97.3865 59.5361 97.2524 58.6419 97.6703 57.9767C97.9885 57.4701 98.5286 57.2081 99.0705 57.2203C99.4042 57.227 99.7396 57.3374 100.023 57.5596C100.541 57.9628 100.744 58.6193 100.6 59.2173C100.548 59.4311 100.45 59.6378 100.307 59.8238Z"
                    fill="#474747"
                ></path>
            </svg>
            <Spacer y={3} />
            <Text type="body1">{getLiteral('title_add_related_product')}</Text>
            <Spacer y={1} />
            <Text type="caption">{getLiteral('title_add_all_related_products')}</Text>
            <Spacer y={3} />
            <Button type="secondary" onClick={onClick}>
                {getLiteral('action_add_product')}
            </Button>
        </div>
    );
});

export default RelatedProductEmpty;
