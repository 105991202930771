import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import AgendaIcon from 'components/SvgIcons/Agenda';
import { WidgetEmpty } from 'containers/components/widgets/Layouts';
import Scrollbar from 'components/ScrollBar';
import TaskItem from './TaskItem';
import EventItem from './EventItem';
import colors from 'constants/colors';

const AgendaList = memo(({ entityType, list, isLoading }) => {
    let rows = list.map((agenda) => {
        return (
            <div key={agenda.id}>
                {agenda.isTarea ? (
                    <TaskItem agenda={agenda} entityType={entityType} />
                ) : (
                    <EventItem agenda={agenda} entityType={entityType} />
                )}
                <div className="fm-widget-agenda__divider" />
            </div>
        );
    });

    let emptyComponent = !isLoading ? (
        <WidgetEmpty
            icon={<AgendaIcon color={colors.grey[100]} />}
            text={getLiteral('error_noincomingcalendarevents')}
        />
    ) : null;

    let renderList =
        rows.length > 0 ? (
            <Scrollbar autoHide={true} hideTracksWhenNotNeeded={true} style={{ height: '100%' }}>
                {rows}
            </Scrollbar>
        ) : (
            emptyComponent
        );

    return (
        <div className="fm-widget-agenda__content">
            {isLoading && <WidgetEmpty icon={<Loader />} />}
            {!isLoading && renderList}
        </div>
    );
});

AgendaList.propTypes = {
    entityType: PropTypes.object.isRequired,
};

export default AgendaList;
