import React, { memo } from 'react';

const Documents = (props) => (
    <svg width={168} height={144} fill="none" {...props}>
        <g mask="url(#a)">
            <path
                fill="#C6C6C6"
                d="M127.653 47.579c-.201.549-.489 1.069-.777 1.588l.23.173-.23-.144v-.029l-.029.03c-.086.057-.144.143-.23.2a6.885 6.885 0 0 1-.979.607c-.288.145-.605.289-.921.462-.461.202-.922.404-1.44.578-.662.26-1.353.49-2.015.693-.864.288-1.699.52-2.361.693-.806.202-1.353.346-1.44.346a2.55 2.55 0 0 0-1.929 3.06 2.543 2.543 0 0 0 3.052 1.935c.029 0 .662-.145 1.612-.404.951-.26 2.246-.636 3.628-1.098a36.331 36.331 0 0 0 2.764-1.068c.518-.23 1.037-.462 1.526-.721a11.32 11.32 0 0 0 1.583-1.011 4.45 4.45 0 0 0 .778-.693c.288-.289.547-.607.748-.953.461-.78.864-1.559 1.181-2.396.316-.837.604-1.675.806-2.54.317-1.271.489-2.541.576-3.84l-5.902 3.84c-.087.23-.144.461-.231.692ZM52.393 102.694h50.989c.806 0 1.382-1.126 1.209-2.31l-5.96-38.63c-.143-.836-.633-1.443-1.209-1.443H43.871c-.806 0-1.382 1.126-1.21 2.31l8.523 38.629c.144.838.633 1.444 1.21 1.444Z"
            />
            <path
                fill="#fff"
                d="M57.806 68.338c.287 3.06-1.843 5.6-4.78 5.63-2.937.057-5.643-2.426-6.075-5.544-.403-3.118 1.728-5.63 4.78-5.63 3.051 0 5.786 2.483 6.075 5.544Z"
            />
            <path
                fill="#C6C6C6"
                d="M53.544 66.692c.086.78-.46 1.386-1.21 1.414-.748 0-1.439-.606-1.525-1.385-.087-.78.46-1.415 1.209-1.415.749 0 1.44.635 1.526 1.386ZM57.748 73.044c.201 2.194-1.756 4.013-4.377 4.07-2.62.058-4.98-1.703-5.297-3.897-.288-2.223 1.67-4.042 4.376-4.071 2.706-.029 5.096 1.732 5.298 3.898Z"
            />
            <path
                fill="#E2E2E2"
                d="M58.094 92.33h53.58c.806 0 1.382-1.184 1.209-2.397l-5.959-40.42c-.144-.894-.634-1.5-1.21-1.5H52.163c-.806 0-1.382 1.183-1.21 2.396l5.96 40.419c.116.866.634 1.501 1.18 1.501Z"
            />
            <path
                fill="#fff"
                d="M66.097 56.067c.288 3.06-1.842 5.601-4.779 5.63-2.937.058-5.643-2.425-6.075-5.543-.403-3.118 1.728-5.63 4.78-5.63 3.051 0 5.786 2.483 6.074 5.543Z"
            />
            <path
                fill="#E2E2E2"
                d="M61.836 54.422c.086.78-.46 1.385-1.21 1.414-.748 0-1.439-.606-1.525-1.386-.087-.78.46-1.414 1.21-1.414.748 0 1.467.606 1.525 1.386ZM66.04 60.773c.201 2.194-1.757 4.014-4.377 4.071-2.62.058-4.98-1.703-5.297-3.897-.288-2.224 1.67-4.042 4.376-4.071 2.735-.029 5.096 1.703 5.298 3.897Z"
            />
            <path
                fill="#fff"
                d="m119.706 55.634-4.607 18.044c-.259.982-1.209 1.762-2.159 1.762H61.634c-.95 0-1.554-.78-1.324-1.762l6.507-28.784c.23-.982 1.18-1.76 2.159-1.76h52.198c.979 0 1.555.778 1.296 1.76l-2.764 10.74Z"
            />
            <path
                fill="#E2E2E2"
                d="M103.554 51.303H90.858c-.548 0-.893-.461-.75-1.01.145-.549.692-1.01 1.239-1.01h12.726c.547 0 .892.461.748 1.01-.144.577-.72 1.01-1.267 1.01ZM113.661 54.508H89.62c-.288 0-.46-.231-.403-.52a.675.675 0 0 1 .633-.52h24.07c.288 0 .46.232.374.52-.029.26-.346.52-.633.52ZM107.038 57.684H88.842c-.288 0-.46-.231-.403-.52a.675.675 0 0 1 .633-.52h18.196c.288 0 .461.232.374.52-.028.289-.316.52-.604.52ZM81.673 53.498c-.72 3.06-3.743 5.543-6.795 5.543s-4.923-2.483-4.204-5.543c.691-3.06 3.743-5.543 6.795-5.543s4.923 2.482 4.204 5.543Z"
            />
            <path
                fill="#fff"
                d="M77.93 51.823c-.172.78-.95 1.386-1.698 1.386-.749 0-1.238-.635-1.065-1.386.172-.78.95-1.386 1.698-1.386.749 0 1.238.635 1.066 1.386ZM80.031 58.204c-.518 2.194-3.138 3.955-5.873 3.955-2.735 0-4.55-1.761-4.06-3.955.49-2.194 3.138-3.956 5.874-3.956 2.735 0 4.577 1.762 4.06 3.956Z"
            />
            <path
                fill="#B26E3D"
                d="M131.566 40.91c.843 0 1.526-.685 1.526-1.53s-.683-1.53-1.526-1.53-1.526.685-1.526 1.53.683 1.53 1.526 1.53Z"
            />
            <path
                fill="#474747"
                d="M131.367 37.85a1.545 1.545 0 0 0-.864.433c.317.144.72.288 1.209.317.374.03.778 0 1.152-.057a1.528 1.528 0 0 0-1.497-.693Z"
            />
            <path
                fill="#00D639"
                d="M19.34 103.964c0-1.241.98-2.223 2.218-2.223h69.645c1.21 0 2.217.982 2.217 2.223a2.22 2.22 0 0 1-2.217 2.223H21.558c-1.238-.029-2.217-1.01-2.217-2.223Z"
            />
            <path
                fill="#00D639"
                d="M115.013 63.371H62.095c-1.957 0-3.627 1.386-4.03 3.32l-7.716 39.496h57.87c1.957 0 3.628-1.386 4.03-3.32l6.795-34.616a4.092 4.092 0 0 0-4.031-4.88Z"
            />
            <path
                fill="#00D639"
                d="m118.928 68.742 1.699-8.344a1.461 1.461 0 0 0-1.44-1.732l-12.466-.145c-.749 0-1.382.549-1.469 1.27l-.748 5.197 14.424 3.754Z"
            />
            <path
                fill="#E2E2E2"
                d="M76.319 62.42c4.388 0 7.946-3.568 7.946-7.97 0-4.4-3.558-7.968-7.946-7.968-4.39 0-7.947 3.568-7.947 7.969 0 4.4 3.558 7.968 7.947 7.968Z"
            />
            <path
                fill="#474747"
                d="M80.233 55.403c.03.174.116.578.173 1.126a1.654 1.654 0 0 0 1.324-2.425l-2.044-3.753c0-.029-.028-.029-.028-.058a1.608 1.608 0 0 0-2.217-.606c-.778.462-1.066 1.443-.605 2.223l.173.289c.374.144.748.288 1.036.404 1.641.895 1.987 1.876 2.188 2.8ZM77.73 51.65c-.087.058-.144.116-.26.145l-.057-.895.835-.52s0 .087-.029.202c.058 0 .086-.029.144-.029.403 0 .749.347.749.75 0 .405-.346.751-.749.751-.26-.029-.518-.173-.633-.404Z"
            />
            <path
                fill="#fff"
                d="M80.407 56.53c-.057-.55-.144-.953-.172-1.127v-.029c-.23-.923-.576-1.905-2.218-2.742a5.184 5.184 0 0 0-1.036-.405c-.317-.115-.633-.201-.893-.288-.057-.029-.345-.116-2.159-.75a.567.567 0 0 1-.374-.607l.086-.693a1.603 1.603 0 0 0-.633-1.444l-.115.607a.65.65 0 0 1-.633.52.64.64 0 0 1-.634-.636v-.115l.115-.636a1.574 1.574 0 0 0-1.18 1.329l-.087.693c-.201 1.703.778 3.32 2.39 3.868 2.015.722 2.188.78 2.274.809h.03l-1.728 5.89 3.282.201-.086 1.27-2.793-.172a7.57 7.57 0 0 0 2.418.375c.288 0 .605-.03.893-.058.201-.029.432-.058.633-.087a8.021 8.021 0 0 0 1.67-.52c.317-.26.576-.634.749-1.096.431-1.155.345-2.974.201-4.158Z"
            />
            <path
                fill="#DDB89E"
                d="M79.14 51.275a.763.763 0 0 0-.748-.751c-.058 0-.087 0-.144.029-.03.289-.144.808-.519 1.097.116.23.375.404.663.404.403-.029.748-.346.748-.78Z"
            />
            <path
                fill="#474747"
                d="M78.22 50.553c.028-.115.028-.202.028-.202l-.835.52.058.895c.086-.03.172-.087.259-.145a.77.77 0 0 1-.087-.346c0-.347.26-.664.576-.722Z"
            />
            <path
                fill="#474747"
                d="M78.218 50.553a.74.74 0 0 0-.605.722c0 .115.03.26.087.346.374-.26.489-.78.518-1.068Z"
            />
            <path
                fill="#DDB89E"
                d="M73.525 41.545a.992.992 0 0 0-1.123.78l-.892 5.052-.087.549-.172.953c0 .057-.03.115-.03.173 0 .52.433.952.95.952.462 0 .864-.346.95-.779l.174-.924.374-2.079.633-3.55c.087-.55-.259-1.04-.777-1.127ZM72.748 60.572c-.058 0-.144-.03-.202-.03a2.244 2.244 0 0 0-.979-.028.87.87 0 0 0-.288.115c1.382 1.126 3.139 1.819 5.039 1.819.432 0 .864-.029 1.295-.115l.03-.26c.028-.664-.461-1.242-1.124-1.27l-3.426-.203-.345-.029Z"
            />
            <path
                fill="#A87146"
                d="M118.41 62.679a1.766 1.766 0 0 0-.432.75 1.515 1.515 0 0 0 1.152 1.848c5.009 1.213 7.226-1.53 7.773-2.396.461-.722-.259-1.213-.979-1.675-.719-.462-1.151-.722-1.612 0-.749 1.155-2.419 1.53-4.463 1.04a1.707 1.707 0 0 0-1.439.433Z"
            />
            <path
                fill="#C6C6C6"
                d="M127.538 55.779c.316.288.69.52 1.094.635.259.087.547.115.806.115.806 0 1.554-.317 2.073-.924.144-.144.259-.288.345-.461.115-.174.202-.347.288-.52.087-.202.144-.376.202-.578.057-.23.086-.433.115-.664.029-.288.058-.548.086-.808.029-.347.029-.722.058-1.097.029-.49.029-1.01.086-1.501a12.75 12.75 0 0 1 .23-1.79c.058-.26.116-.491.202-.722.058-.145.115-.289.202-.404l.028-.03c.058 0 .116-.028.173-.028.115 0 .23 0 .346.029.172.029.374.086.547.144a2.52 2.52 0 0 0 3.195-1.674c.432-1.357-.316-2.8-1.669-3.205-.404-.116-.807-.231-1.239-.289a8.495 8.495 0 0 0-1.209-.086c-.633 0-1.266.086-1.871.317a5.355 5.355 0 0 0-1.584.895 7.5 7.5 0 0 0-.806.837c-.288.376-.518.78-.72 1.184-.23.52-.431 1.068-.575 1.617a12.549 12.549 0 0 0-.346 2.079c-.086.895-.115 1.732-.144 2.425-.029.404-.029.75-.057 1.04 0 .143-.029.259-.029.374 0 .116-.029.174-.029.174l.691.173-.662-.231-.029.058.691.173-.662-.231 1.411.52-1.325-.722c-.029.057-.086.144-.086.23l1.411.52-1.325-.721 1.728.952-1.44-1.357c-.115.116-.23.26-.288.405l1.728.952-1.44-1.385 1.584 1.5-1.296-1.76c-.115.087-.201.173-.288.26l1.584 1.5-1.296-1.76 1.353 1.819-.95-2.05c-.144.058-.259.144-.403.23l1.353 1.82-.489-2.224c-.173.03-.317.087-.461.174l.95 2.05-.489-2.224.489 2.195v-2.252c-.173 0-.317.029-.489.058l.489 2.194v-2.252 2.396l.806-2.252a2.634 2.634 0 0 0-.806-.144v2.396l.806-2.252-.806 2.194 1.382-1.876c-.173-.145-.374-.231-.576-.318l-.806 2.194 1.382-1.876-1.324 1.819 1.583-1.588c-.086-.087-.173-.145-.259-.202l-1.324 1.818 1.583-1.587-1.468 1.472 1.67-1.241a1.439 1.439 0 0 0-.202-.231l-1.439 1.472 1.67-1.241-1.382 1.039 1.526-.837c-.029-.058-.087-.145-.116-.202l-1.381 1.039 1.525-.837-.863.49.921-.375c-.029-.029-.029-.058-.058-.087l-.863.491.921-.375v-.029c0-.019-.01-.038-.029-.058a2.993 2.993 0 0 1-.086-.346 4.327 4.327 0 0 1-.144-.75c-.087-.376-.144-.838-.259-1.329-.115-.664-.259-1.386-.403-2.136a26.361 26.361 0 0 0-.806-3.118"
            />
            <path
                fill="#5E5E5E"
                d="M126.847 106.158c-1.872-.115-3.283-1.732-3.168-3.609l2.88-44.201.057-.231c.317-1.415 1.267-2.627 2.563-3.233v-.491c-.058 0-.116 0-.173.029.086 0 .288-.058.461-.116.69-.173 1.612-.375 2.562-.404 2.677-.03 4.088 1.588 4.52 3.147l.029.115 2.245 11.491a141.196 141.196 0 0 0 7.371 25.118l2.505 6.323a3.397 3.397 0 0 1-1.9 4.417 3.382 3.382 0 0 1-4.405-1.906l-2.505-6.322a149.178 149.178 0 0 1-7.428-24.83l-2.044 31.556a3.378 3.378 0 0 1-3.369 3.176c-.058-.029-.115-.029-.201-.029Z"
            />
            <path
                fill="#C6C6C6"
                d="M132.288 42.527c2.88 0 5.24 2.627 5.24 8.517s-1.814 5.716-5.556 5.716c-4.377 0-6.162-.721-3.916-6.178 1.814-4.533 1.353-8.055 4.232-8.055Z"
            />
            <path
                fill="#303030"
                d="M141.329 106.158a.866.866 0 0 1-.211-1.706l4.924-1.242a.866.866 0 0 1 .42 1.681l-4.923 1.241a.856.856 0 0 1-.21.026ZM126.933 106.447h-4.549a.863.863 0 0 1-.863-.866c0-.479.385-.866.863-.866h4.549c.478 0 .864.387.864.866a.864.864 0 0 1-.864.866Z"
            />
            <path
                fill="#C6C6C6"
                d="M132.893 43.941a2.559 2.559 0 0 0 1.584 3.263l.316-1.068-.662.923a.98.98 0 0 0 .346.174l.316-1.068-.662.924.317-.434-.432.347a.414.414 0 0 0 .115.087l.317-.434-.432.347.029.029.086.173c.058.144.116.317.173.462.087.317.144.606.173.924.058.548.115 1.068.086 1.617 0 .981-.086 1.934-.259 2.887a14.1 14.1 0 0 1-.806 2.858c-.23.549-.49 1.097-.777 1.617l.23.144-.23-.173v.029l.23.144-.23-.173-.029.029c-.087.057-.144.144-.231.202a6.873 6.873 0 0 1-.978.606c-.288.144-.605.289-.922.462-.46.202-.921.404-1.439.577-.663.26-1.353.491-2.016.693-.863.289-1.698.52-2.36.693-.864.231-1.44.375-1.44.375a2.55 2.55 0 0 0-1.929 3.06 2.543 2.543 0 0 0 3.052 1.935c.029 0 .662-.144 1.612-.404a69.38 69.38 0 0 0 3.628-1.097 36.371 36.371 0 0 0 2.764-1.069c.518-.23 1.036-.461 1.526-.721a11.266 11.266 0 0 0 1.583-1.01c.288-.203.547-.434.778-.694.287-.288.546-.606.748-.952.461-.78.864-1.56 1.181-2.397.316-.837.604-1.674.806-2.54.403-1.675.604-3.378.604-5.081 0-.665-.029-1.329-.115-1.993-.058-.635-.202-1.24-.345-1.847a7.897 7.897 0 0 0-.403-1.155 7.64 7.64 0 0 0-.864-1.472 4.811 4.811 0 0 0-.778-.751 3.87 3.87 0 0 0-1.238-.636c-1.237-.375-2.562.347-3.023 1.588ZM127.077 49.312l-.23-.174v.029l.23.145ZM127.884 46.886c.115-.346.23-.693.317-1.039l-.317 1.04Z"
            />
            <path
                fill="#C6C6C6"
                d="m128.057 46.367-.173.52 5.902-3.84c.029-.347.029-.694.029-1.04-1.469.087-3.714.635-5.154 2.974-.288.433-.46.895-.604 1.386Z"
            />
            <path
                fill="#919191"
                d="M127.653 47.579c-.201.549-.489 1.069-.777 1.588l.23.173-.23-.144v-.029l-.029.03c-.086.057-.144.143-.23.2a6.885 6.885 0 0 1-.979.607c-.288.145-.605.289-.921.462-.461.202-.922.404-1.44.578-.662.26-1.353.49-2.015.693-.864.288-1.699.52-2.361.693-.806.202-1.353.346-1.44.346a2.55 2.55 0 0 0-1.929 3.06 2.543 2.543 0 0 0 3.052 1.935c.029 0 .662-.145 1.612-.404.951-.26 2.246-.636 3.628-1.098a36.331 36.331 0 0 0 2.764-1.068c.518-.23 1.037-.462 1.526-.721a11.32 11.32 0 0 0 1.583-1.011 4.45 4.45 0 0 0 .778-.693c.288-.289.547-.607.748-.953.461-.78.864-1.559 1.181-2.396.316-.837.604-1.675.806-2.54.317-1.271.489-2.541.576-3.84l-5.902 3.84c-.087.23-.144.461-.231.692ZM52.393 102.694h50.989c.806 0 1.382-1.126 1.209-2.31l-5.96-38.63c-.143-.836-.633-1.443-1.209-1.443H43.871c-.806 0-1.382 1.126-1.21 2.31l8.523 38.629c.144.838.633 1.444 1.21 1.444Z"
            />
            <path
                fill="#fff"
                d="M57.806 68.338c.287 3.06-1.843 5.6-4.78 5.63-2.937.057-5.643-2.426-6.075-5.544-.403-3.118 1.728-5.63 4.78-5.63 3.051 0 5.786 2.483 6.075 5.544Z"
            />
            <path
                fill="#919191"
                d="M53.544 66.692c.086.78-.46 1.386-1.21 1.414-.748 0-1.439-.606-1.525-1.385-.087-.78.46-1.415 1.209-1.415.749 0 1.44.635 1.526 1.386ZM57.748 73.044c.201 2.194-1.756 4.013-4.377 4.07-2.62.058-4.98-1.703-5.297-3.897-.288-2.223 1.67-4.042 4.376-4.071 2.706-.029 5.096 1.732 5.298 3.898Z"
            />
            <path
                fill="#ABABAB"
                d="M58.094 92.33h53.58c.806 0 1.382-1.184 1.209-2.397l-5.959-40.42c-.144-.894-.634-1.5-1.21-1.5H52.163c-.806 0-1.382 1.183-1.21 2.396l5.96 40.419c.116.866.634 1.501 1.18 1.501Z"
            />
            <path
                fill="#fff"
                d="M66.097 56.067c.288 3.06-1.842 5.601-4.779 5.63-2.937.058-5.643-2.425-6.075-5.543-.403-3.118 1.728-5.63 4.78-5.63 3.051 0 5.786 2.483 6.074 5.543Z"
            />
            <path
                fill="#ABABAB"
                d="M61.836 54.422c.086.78-.46 1.385-1.21 1.414-.748 0-1.439-.606-1.525-1.386-.087-.78.46-1.414 1.21-1.414.748 0 1.467.606 1.525 1.386ZM66.04 60.773c.201 2.194-1.757 4.014-4.377 4.071-2.62.058-4.98-1.703-5.297-3.897-.288-2.224 1.67-4.042 4.376-4.071 2.735-.029 5.096 1.703 5.298 3.897Z"
            />
            <path
                fill="#C6C6C6"
                d="m119.706 55.634-4.607 18.044c-.259.982-1.209 1.762-2.159 1.762H61.634c-.95 0-1.554-.78-1.324-1.762l6.507-28.784c.23-.982 1.18-1.76 2.159-1.76h52.198c.979 0 1.555.778 1.296 1.76l-2.764 10.74Z"
            />
            <path
                fill="#fff"
                d="M103.554 51.303H90.858c-.548 0-.893-.461-.75-1.01.145-.549.692-1.01 1.239-1.01h12.726c.547 0 .892.461.748 1.01-.144.577-.72 1.01-1.267 1.01ZM113.661 54.508H89.62c-.288 0-.46-.231-.403-.52a.675.675 0 0 1 .633-.52h24.07c.288 0 .46.232.374.52-.029.26-.346.52-.633.52ZM107.038 57.684H88.842c-.288 0-.46-.231-.403-.52a.675.675 0 0 1 .633-.52h18.196c.288 0 .461.232.374.52-.028.289-.316.52-.604.52Z"
            />
            <path
                fill="#E2E2E2"
                d="M81.673 53.498c-.72 3.06-3.743 5.543-6.795 5.543s-4.923-2.483-4.204-5.543c.691-3.06 3.743-5.543 6.795-5.543s4.923 2.482 4.204 5.543Z"
            />
            <path
                fill="#fff"
                d="M77.93 51.823c-.172.78-.95 1.386-1.698 1.386-.749 0-1.238-.635-1.065-1.386.172-.78.95-1.386 1.698-1.386.749 0 1.238.635 1.066 1.386Z"
            />
            <path
                fill="#B26E3D"
                d="M131.566 40.91c.843 0 1.526-.685 1.526-1.53s-.683-1.53-1.526-1.53-1.526.685-1.526 1.53.683 1.53 1.526 1.53Z"
            />
            <path
                fill="#474747"
                d="M131.367 37.85a1.545 1.545 0 0 0-.864.433c.317.144.72.288 1.209.317.374.03.778 0 1.152-.057a1.528 1.528 0 0 0-1.497-.693Z"
            />
            <path
                fill="#00D639"
                d="M19.34 103.964c0-1.241.98-2.223 2.218-2.223h69.645c1.21 0 2.217.982 2.217 2.223a2.22 2.22 0 0 1-2.217 2.223H21.558c-1.238-.029-2.217-1.01-2.217-2.223Z"
            />
            <path
                fill="#00D639"
                d="M115.013 63.371H62.095c-1.957 0-3.627 1.386-4.03 3.32l-7.716 39.496h57.87c1.957 0 3.628-1.386 4.03-3.32l6.795-34.616a4.092 4.092 0 0 0-4.031-4.88Z"
            />
            <path
                fill="#00D639"
                d="m118.928 68.742 1.699-8.344a1.461 1.461 0 0 0-1.44-1.732l-12.466-.145c-.749 0-1.382.549-1.469 1.27l-.748 5.197 14.424 3.754Z"
            />
            <path
                fill="#E2E2E2"
                d="M76.319 62.42c4.388 0 7.946-3.568 7.946-7.97 0-4.4-3.558-7.968-7.946-7.968-4.39 0-7.947 3.568-7.947 7.969 0 4.4 3.558 7.968 7.947 7.968Z"
            />
            <path
                fill="#474747"
                d="M80.233 55.403c.03.174.116.578.173 1.126a1.654 1.654 0 0 0 1.324-2.425l-2.044-3.753c0-.029-.028-.029-.028-.058a1.608 1.608 0 0 0-2.217-.606c-.778.462-1.066 1.443-.605 2.223l.173.289c.374.144.748.288 1.036.404 1.641.895 1.987 1.876 2.188 2.8ZM77.73 51.65c-.087.058-.144.116-.26.145l-.057-.895.835-.52s0 .087-.029.202c.058 0 .086-.029.144-.029.403 0 .749.347.749.75 0 .405-.346.751-.749.751-.26-.029-.518-.173-.633-.404Z"
            />
            <path
                fill="#fff"
                d="M80.407 56.53c-.057-.55-.144-.953-.172-1.127v-.029c-.23-.923-.576-1.905-2.218-2.742a5.184 5.184 0 0 0-1.036-.405c-.317-.115-.633-.201-.893-.288-.057-.029-.345-.116-2.159-.75a.567.567 0 0 1-.374-.607l.086-.693a1.603 1.603 0 0 0-.633-1.444l-.115.607a.65.65 0 0 1-.633.52.64.64 0 0 1-.634-.636v-.115l.115-.636a1.574 1.574 0 0 0-1.18 1.329l-.087.693c-.201 1.703.778 3.32 2.39 3.868 2.015.722 2.188.78 2.274.809h.03l-1.728 5.89 3.282.201-.086 1.27-2.793-.172a7.57 7.57 0 0 0 2.418.375c.288 0 .605-.03.893-.058.201-.029.432-.058.633-.087a8.021 8.021 0 0 0 1.67-.52c.317-.26.576-.634.749-1.096.431-1.155.345-2.974.201-4.158Z"
            />
            <path
                fill="#DDB89E"
                d="M79.14 51.275a.763.763 0 0 0-.748-.751c-.058 0-.087 0-.144.029-.03.289-.144.808-.519 1.097.116.23.375.404.663.404.403-.029.748-.346.748-.78Z"
            />
            <path
                fill="#474747"
                d="M78.22 50.553c.028-.115.028-.202.028-.202l-.835.52.058.895c.086-.03.172-.087.259-.145a.77.77 0 0 1-.087-.346c0-.347.26-.664.576-.722Z"
            />
            <path
                fill="#474747"
                d="M78.218 50.553a.74.74 0 0 0-.605.722c0 .115.03.26.087.346.374-.26.489-.78.518-1.068Z"
            />
            <path
                fill="#DDB89E"
                d="M73.525 41.545a.992.992 0 0 0-1.123.78l-.892 5.052-.087.549-.172.953c0 .057-.03.115-.03.173 0 .52.433.952.95.952.462 0 .864-.346.95-.779l.174-.924.374-2.079.633-3.55c.087-.55-.259-1.04-.777-1.127ZM72.748 60.572c-.058 0-.144-.03-.202-.03a2.244 2.244 0 0 0-.979-.028.87.87 0 0 0-.288.115c1.382 1.126 3.139 1.819 5.039 1.819.432 0 .864-.029 1.295-.115l.03-.26c.028-.664-.461-1.242-1.124-1.27l-3.426-.203-.345-.029Z"
            />
            <path
                fill="#A87146"
                d="M118.41 62.679a1.766 1.766 0 0 0-.432.75 1.515 1.515 0 0 0 1.152 1.848c5.009 1.213 7.226-1.53 7.773-2.396.461-.722-.259-1.213-.979-1.675-.719-.462-1.151-.722-1.612 0-.749 1.155-2.419 1.53-4.463 1.04a1.707 1.707 0 0 0-1.439.433Z"
            />
            <path
                fill="#919191"
                d="M127.538 55.779c.316.288.69.52 1.094.635.259.087.547.115.806.115.806 0 1.554-.317 2.073-.924.144-.144.259-.288.345-.461.115-.174.202-.347.288-.52.087-.202.144-.376.202-.578.057-.23.086-.433.115-.664.029-.288.058-.548.086-.808.029-.347.029-.722.058-1.097.029-.49.029-1.01.086-1.501a12.75 12.75 0 0 1 .23-1.79c.058-.26.116-.491.202-.722.058-.145.115-.289.202-.404l.028-.03c.058 0 .116-.028.173-.028.115 0 .23 0 .346.029.172.029.374.086.547.144a2.52 2.52 0 0 0 3.195-1.674c.432-1.357-.316-2.8-1.669-3.205-.404-.116-.807-.231-1.239-.289a8.495 8.495 0 0 0-1.209-.086c-.633 0-1.266.086-1.871.317a5.355 5.355 0 0 0-1.584.895 7.5 7.5 0 0 0-.806.837c-.288.376-.518.78-.72 1.184-.23.52-.431 1.068-.575 1.617a12.549 12.549 0 0 0-.346 2.079c-.086.895-.115 1.732-.144 2.425-.029.404-.029.75-.057 1.04 0 .143-.029.259-.029.374 0 .116-.029.174-.029.174l.691.173-.662-.231-.029.058.691.173-.662-.231 1.411.52-1.325-.722c-.029.057-.086.144-.086.23l1.411.52-1.325-.721 1.728.952-1.44-1.357c-.115.116-.23.26-.288.405l1.728.952-1.44-1.385 1.584 1.5-1.296-1.76c-.115.087-.201.173-.288.26l1.584 1.5-1.296-1.76 1.353 1.819-.95-2.05c-.144.058-.259.144-.403.23l1.353 1.82-.489-2.224c-.173.03-.317.087-.461.174l.95 2.05-.489-2.224.489 2.195v-2.252c-.173 0-.317.029-.489.058l.489 2.194v-2.252 2.396l.806-2.252a2.634 2.634 0 0 0-.806-.144v2.396l.806-2.252-.806 2.194 1.382-1.876c-.173-.145-.374-.231-.576-.318l-.806 2.194 1.382-1.876-1.324 1.819 1.583-1.588c-.086-.087-.173-.145-.259-.202l-1.324 1.818 1.583-1.587-1.468 1.472 1.67-1.241a1.439 1.439 0 0 0-.202-.231l-1.439 1.472 1.67-1.241-1.382 1.039 1.526-.837c-.029-.058-.087-.145-.116-.202l-1.381 1.039 1.525-.837-.863.49.921-.375c-.029-.029-.029-.058-.058-.087l-.863.491.921-.375v-.029c0-.019-.01-.038-.029-.058a2.993 2.993 0 0 1-.086-.346 4.327 4.327 0 0 1-.144-.75c-.087-.376-.144-.838-.259-1.329-.115-.664-.259-1.386-.403-2.136a26.361 26.361 0 0 0-.806-3.118"
            />
            <path
                fill="#303030"
                d="M126.847 106.158c-1.872-.115-3.283-1.732-3.168-3.609l2.88-44.201.057-.231c.317-1.415 1.267-2.627 2.563-3.233v-.491c-.058 0-.116 0-.173.029.086 0 .288-.058.461-.116.69-.173 1.612-.375 2.562-.404 2.677-.03 4.088 1.588 4.52 3.147l.029.115 2.245 11.491a141.196 141.196 0 0 0 7.371 25.118l2.505 6.323a3.397 3.397 0 0 1-1.9 4.417 3.382 3.382 0 0 1-4.405-1.906l-2.505-6.322a149.178 149.178 0 0 1-7.428-24.83l-2.044 31.556a3.378 3.378 0 0 1-3.369 3.176c-.058-.029-.115-.029-.201-.029Z"
            />
            <path
                fill="#919191"
                d="M132.288 42.527c2.88 0 5.24 2.627 5.24 8.517s-1.814 5.716-5.556 5.716c-4.377 0-6.162-.721-3.916-6.178 1.814-4.533 1.353-8.055 4.232-8.055Z"
            />
            <path
                fill="#919191"
                d="M132.893 43.941a2.559 2.559 0 0 0 1.584 3.263l.316-1.068-.662.923a.98.98 0 0 0 .346.174l.316-1.068-.662.924.317-.434-.432.347a.414.414 0 0 0 .115.087l.317-.434-.432.347.029.029.086.173c.058.144.116.317.173.462.087.317.144.606.173.924.058.548.115 1.068.086 1.617 0 .981-.086 1.934-.259 2.887a14.1 14.1 0 0 1-.806 2.858c-.23.549-.49 1.097-.777 1.617l.23.144-.23-.173v.029l.23.144-.23-.173-.029.029c-.087.057-.144.144-.231.202a6.873 6.873 0 0 1-.978.606c-.288.144-.605.289-.922.462-.46.202-.921.404-1.439.577-.663.26-1.353.491-2.016.693-.863.289-1.698.52-2.36.693-.864.231-1.44.375-1.44.375a2.55 2.55 0 0 0-1.929 3.06 2.543 2.543 0 0 0 3.052 1.935c.029 0 .662-.144 1.612-.404a69.38 69.38 0 0 0 3.628-1.097 36.371 36.371 0 0 0 2.764-1.069c.518-.23 1.036-.461 1.526-.721a11.266 11.266 0 0 0 1.583-1.01c.288-.203.547-.434.778-.694.287-.288.546-.606.748-.952.461-.78.864-1.56 1.181-2.397.316-.837.604-1.674.806-2.54.403-1.675.604-3.378.604-5.081 0-.665-.029-1.329-.115-1.993-.058-.635-.202-1.24-.345-1.847a7.897 7.897 0 0 0-.403-1.155 7.64 7.64 0 0 0-.864-1.472 4.811 4.811 0 0 0-.778-.751 3.87 3.87 0 0 0-1.238-.636c-1.237-.375-2.562.347-3.023 1.588Z"
            />
            <path
                fill="#C6C6C6"
                d="m127.077 49.312-.23-.174v.029l.23.145ZM127.884 46.886c.115-.346.23-.693.317-1.039l-.317 1.04Z"
            />
            <path
                fill="#919191"
                d="m128.057 46.367-.173.52 5.902-3.84c.029-.347.029-.694.029-1.04-1.469.087-3.714.635-5.154 2.974-.288.433-.46.895-.604 1.386Z"
            />
        </g>
    </svg>
);

export default memo(Documents);
