import React, { memo } from 'react';

const Activities = memo((props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={168} height={144} fill="none" {...props}>
            <mask
                id="a"
                width={134}
                height={90}
                x={17}
                y={27}
                maskUnits="userSpaceOnUse"
                style={{
                    maskType: 'luminance',
                }}
            >
                <path fill="#fff" d="M150.353 27.388H17.647v89.224h132.706V27.388Z" />
            </mask>
            <g mask="url(#a)">
                <path
                    fill="#006716"
                    d="M101.108 54.91c-6.535 0-11.83-5.275-11.83-11.781s5.297-11.782 11.83-11.782v-3.959c-8.73 0-15.804 7.048-15.804 15.74 0 8.69 7.076 15.738 15.804 15.738V54.91v.001Z"
                />
                <path
                    fill="#00AF2D"
                    d="M101.108 54.91v3.958c8.729 0 15.805-7.047 15.805-15.74h-3.974c0 6.508-5.298 11.783-11.831 11.783Z"
                />
                <path
                    fill="#008A21"
                    d="M101.108 27.388v3.958c6.535 0 11.831 5.275 11.831 11.781h3.974c0-8.693-7.077-15.739-15.805-15.739Z"
                />
                <path
                    fill="#00D639"
                    d="M19.591 116.612a1.94 1.94 0 0 1-1.332-.527 1.932 1.932 0 0 1-.082-2.737l19.89-21.023c1.487-1.587 3.541-2.44 5.63-2.334 2.117.108 4.107 1.188 5.46 2.964l12.035 15.801c.735.965 1.776 1.488 2.857 1.437 1.112-.053 2.133-.699 2.802-1.773l22.031-36.275c.571-1.185 1.381-2.189 2.35-2.907a7.371 7.371 0 0 1 1.186-.718 6.786 6.786 0 0 1 5.382-.235c1.92.724 3.515 2.286 4.383 4.293l18.306 35.721c.294.517.651.945 1.037 1.238a3.163 3.163 0 0 0 2.841.536c1.109-.331 2.006-1.237 2.469-2.495l15.71-59.231a1.945 1.945 0 0 1 3.759.99l-15.732 59.311a1.815 1.815 0 0 1-.046.148c-.874 2.476-2.714 4.294-5.049 4.987-2.185.649-4.488.223-6.316-1.17-.823-.628-1.525-1.453-2.087-2.457a.54.54 0 0 1-.033-.063L98.688 74.278a1.983 1.983 0 0 1-.06-.13c-.45-1.067-1.254-1.884-2.204-2.242a2.881 2.881 0 0 0-2.315.1c-.193.093-.38.206-.557.337-.478.356-.892.885-1.194 1.533-.03.063-.063.126-.1.187l-22.087 36.369-.008.015c-1.353 2.182-3.513 3.5-5.928 3.615-2.383.113-4.62-.967-6.142-2.964l-12.035-15.8c-.667-.877-1.576-1.387-2.56-1.437-.954-.049-1.877.348-2.595 1.115l-19.896 21.027a1.94 1.94 0 0 1-1.416.609Z"
                />
                <path
                    fill="#00D639"
                    d="M148.972 52.465a1.529 1.529 0 0 1-1.447-.726l-2.622-4.323-4.974 2.354c-.757.36-1.666.04-2.027-.714a1.511 1.511 0 0 1 .716-2.018l6.213-2.946a1.522 1.522 0 0 1 1.959.58l3.343 5.483a1.522 1.522 0 0 1-.509 2.09c-.196.124-.42.2-.65.22h-.002Z"
                />
                <path
                    fill="#777"
                    d="M19.629 59.003h31.356s1.012-4.384-2.523-6.604c-3.059-1.921-5.263-.502-5.263-.502s-3.094-6.25-10.158-5.03c-7.064 1.221-7.516 8.544-7.516 8.544s-1.568-1.498-3.735-.427c-2.659 1.316-2.163 4.02-2.163 4.02l.002-.001ZM100.283 59.003h33.559s1.256-2.01-1.022-3.32c-1.371-.79-2.865.405-2.865.405s-1.68-7.791-9.311-8.174c-7.632-.381-9.887 5.975-9.887 5.975s-3.504-3.107-6.719-1.576c-3.216 1.529-4.631 5.305-3.755 6.691v-.001Z"
                />
                <path
                    fill="#303030"
                    d="M52.304 97.2c-.317 0-.641-.056-.956-.173a2.72 2.72 0 0 1-1.604-3.504L59.86 66.55c.5-1.733 1.568-2.473 2.386-2.789 2.038-.785 4.002.428 5.22 1.218l4.866 2.539c.103.053.2.113.296.18 1.375.956 3.923 2.725 4.557 5.892l3.31 12.633a2.721 2.721 0 0 1-1.954 3.322 2.732 2.732 0 0 1-3.335-1.948l-3.334-12.719a2.306 2.306 0 0 1-.043-.195c-.154-.836-.92-1.53-2.192-2.424l-4.824-2.517a2.728 2.728 0 0 1-.226-.132l-.032-.02-9.69 25.84a2.736 2.736 0 0 1-2.562 1.77Z"
                />
                <path
                    fill="#303030"
                    d="M82.14 85.822 79.8 88.847c-.182.234-.6.217-.933-.039-.333-.256-.457-.653-.275-.887l2.341-3.026c.141-.265.544-.296.9-.069.356.227.529.628.388.892a.37.37 0 0 1-.08.102v.002ZM52.763 98.934l-2.613-2.797c-.203-.217-.117-.625.192-.91.308-.286.724-.342.925-.125l2.613 2.797c.24.182.203.583-.08.896-.285.311-.71.416-.95.234a.336.336 0 0 1-.087-.095Z"
                />
                <path
                    fill="#C6C6C6"
                    d="M62.952 56.04a3.5 3.5 0 0 0-1.673 2.618l-.245 2.26c-.215 1.972 1.146 4.022 3.077 4.23 1.93.209 3.7-1.504 3.913-3.477l.245-2.259a3.49 3.49 0 0 0-1.073-2.913 3.527 3.527 0 0 0-4.247-.458h.003Z"
                />
                <path
                    fill="#E84D4F"
                    d="M66.54 53.54a1.187 1.187 0 0 0-.746-1.507c-.624-.21-1.3.122-1.513.743a1.186 1.186 0 0 0 .746 1.506c.624.211 1.3-.121 1.512-.742Z"
                />
                <path
                    fill="#C6C6C6"
                    d="M60.36 69.327c-.263 0-.53-.05-.788-.156-2.936-1.21-4.405-2.55-5.556-3.818-.993-1.096-1.294-2.65-.845-4.377.289-1.109.894-2.308 1.662-3.29.064-.083.13-.166.197-.247 1.46-1.772 3.556-2.828 5.903-2.975 2.784-.174 5.743.948 7.913 3.001.629.595 1.8 1.701 3.712 3.359a1.315 1.315 0 0 0 1.514.148l4.332-2.483a2.07 2.07 0 0 1 2.82.757c.57.985.23 2.243-.76 2.808l-4.331 2.483a5.461 5.461 0 0 1-6.287-.61 111.39 111.39 0 0 1-3.844-3.478c-1.353-1.28-3.15-1.983-4.81-1.878-.843.051-2.04.35-2.967 1.476-.045.053-.088.109-.133.165-.77.987-1.07 2.095-.987 2.41.975 1.07 1.998 1.904 4.046 2.748a2.055 2.055 0 0 1-.79 3.957Z"
                />
                <path
                    fill="#E84D4F"
                    d="M82.522 58.11a1.158 1.158 0 0 1-.268 1.618l-.989.703a1.165 1.165 0 0 1-1.622-.27 1.158 1.158 0 0 1 .268-1.62l.99-.703a1.166 1.166 0 0 1 1.621.272ZM60.494 67.188a1.162 1.162 0 0 1 1.532-.597l1.117.489a1.16 1.16 0 1 1-.932 2.124l-1.116-.489a1.16 1.16 0 0 1-.601-1.527Z"
                />
            </g>
        </svg>
    );
});

export default Activities;
