import React, { memo, useMemo, useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Context from 'managers/Context';
import { WidgetLayout, WidgetHeaderLayout, WidgetContentLayout, WidgetEmpty } from '../Layouts';
import { logEvent } from 'utils/tracking';

import SizeComponent from 'components/SizeComponent';
import Header from './Header';
import Content from './Content';
import { ExtraFieldsIcon } from 'components/SvgIcons';
import { getLiteral } from 'utils/getLiteral';
import colors from 'constants/colors';

import './styles.scss';

const propTypes = {
    entityType: Object,
    entityId: String,
    sections: Array,
};

const ExtraFieldsWidget = ({ entityType, entityId, entity, entityObject, setSize, config }) => {
    const [sectionSelected, setSectionSelected] = useState(0);
    const [sections, setSections] = useState([]);
    const [loading, setLoading] = useState(false);

    const setupWidgetData = useCallback(
        (data) => {
            let finalData = [...data];
            const realEntity = entity ? entity : entityObject.toJS().entity;

            // the filter for dynamism seems is only needed for salesorders,
            // in companies, i.e., we already have the filter from backend
            // Checking dynamic values
            finalData = finalData.filter((section) => {
                const fields = section.data.filter((field) => {
                    if (field.hiddenByDynamism) return false;

                    // field is dynamic of field.tiposEntity
                    const tiposEntity = field.schemaTiposEntity || field.tiposEntity;
                    if (tiposEntity && field.strTiposEntity) {
                        // Get value dinamic of field
                        let dynamicValue =
                            realEntity[tiposEntity] || realEntity[tiposEntity.toLowerCase()];
                        dynamicValue =
                            dynamicValue && dynamicValue.value ? dynamicValue.value : dynamicValue;

                        if (!dynamicValue) return false;
                        dynamicValue = String(dynamicValue);

                        // Check if we have a match (OR)
                        return field.strTiposEntity.includes(dynamicValue);
                    }
                    return true;
                });
                section.data = fields;
                return fields.length;
            });

            setSections(finalData);
            setLoading(false);
            setSize(
                {
                    height: finalData.length === 0 ? 120 : config.height,
                },
                true,
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [entity, entityObject],
    );

    useEffect(() => {
        if (!entityType) return;

        setLoading(true);

        const manager = Context.entityManager.getEntitiesManager(entityType);
        if (manager && manager.getExtraFieldsByEntity) {
            manager.getExtraFieldsByEntity(entityId).then((data) => {
                setupWidgetData(data);
                setLoading(false);
            });
        } else {
            if (entity?.extraFields?.length) {
                setSections(entity.extraFields);
                setSize({
                    height: config.height,
                });
            } else {
                setSize({
                    height: 120,
                });
            }
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entity, entityId, entityType, setupWidgetData]);

    const onClickSection = useCallback(
        (event, section) => {
            logEvent({
                event: entityType?.trueName,
                submodule: 'extraFieldsWidget',
                functionality: 'showExtraFields',
            });
            setSectionSelected(section);
        },
        [entityType?.trueName],
    );

    const content = useMemo(() => {
        if (sections.length === 0) return null;
        return sections[sectionSelected];
    }, [sections, sectionSelected]);

    const isEmpty = sections.length === 0;

    return (
        <WidgetLayout
            type="tabs"
            className="fm-widget-extra-fields"
            data={entityId && entityType}
            loading={loading}
        >
            <WidgetHeaderLayout>
                <Header
                    sections={sections}
                    onChange={onClickSection}
                    tabControlled={sectionSelected}
                />
            </WidgetHeaderLayout>
            <WidgetContentLayout>
                {!isEmpty && <Content entityType={entityType} section={content} data={entity} />}
                {isEmpty && (
                    <SizeComponent setSize={setSize}>
                        <WidgetEmpty
                            icon={<ExtraFieldsIcon color={colors.grey[100]} />}
                            text={getLiteral('error_noextrafieldsfound')}
                        />
                    </SizeComponent>
                )}
            </WidgetContentLayout>
        </WidgetLayout>
    );
};

ExtraFieldsWidget.Proptypes = propTypes;

function mapStateToProps(state) {
    let tab = state.detail.tabs.get(state.detail.select);
    // compatibility with salesorders...
    return {
        entityObject: tab ? tab.get('entityObject') : {},
    };
}

export default memo(connect(mapStateToProps)(ExtraFieldsWidget));
