import React, { memo, useMemo } from 'react';
import Header from 'containers/components/layouts/Header';
import HeaderTitle from 'containers/components/layouts/HeaderTitle';
import HeaderSection from 'containers/components/layouts/HeaderSection';
import HeaderSectionDefault from 'containers/components/layouts/HeaderSectionDefault';
import { getLiteral } from 'utils/getLiteral';
import { AGENDA } from 'constants/Entities';
import { Agenda, Map } from 'components/SvgIcons';
import ViewType from 'containers/components/ViewType';
import colors from 'constants/colors';
import './styles.scss';

const AgendaHeader = memo(({ onChange, section }) => {
    const agendaIconColor = section === 0 ? colors.actionMinor[500] : colors.actionMinor[200];
    const mapIconColor = section === 1 ? colors.actionMinor[500] : colors.actionMinor[200];

    const tabs = useMemo(() => {
        return [
            {
                title: getLiteral('title_agenda'),
                icon: <Agenda color={agendaIconColor} />,
            },
            {
                title: getLiteral('label_map'),
                icon: <Map color={mapIconColor} />,
            },
        ];
    }, [agendaIconColor, mapIconColor]);

    return (
        <Header>
            <HeaderSection>
                <HeaderTitle entity={AGENDA} title="title_agenda" />
            </HeaderSection>
            <ViewType onChange={onChange} section={section} tabs={tabs} />
            <HeaderSectionDefault />
        </Header>
    );
});

export default AgendaHeader;
