import { API_URL, WEB_VERSION } from 'constants/Environment';
import moment from 'moment';
import Cookies from 'js-cookie';
import Context from 'managers/Context';
import { refreshToken, persistTokens } from 'services';
import {
    FilterObjectToParams,
    ExtraFilterObjectToParams,
    formatOptions,
    formatFilters,
    getGeoParams,
    formatSelectionOperators,
    formatCrossFilters,
} from './FilterApiWrapper';
import { getQueryString, getNewQueryString } from 'utils/url';
import { goLogout, getCookieDomainName } from 'utils/routes';
import { ACTIVITIES, COMPANIES, OPPORTUNITIES, SALESORDERS } from 'constants/Entities';

export default class ForceManagerApi {
    setToken(token) {
        this.token = token;
    }

    getTokenFromConfig(username, hash, params, success, error) {
        this.request('GET', `${API_URL}/config${getQueryString(params)}`, {
            success,
            error,
            headers: {
                'x-fm-user': username,
                'x-fm-auth': hash,
            },
        });
    }

    getPartialConfig(params, success, error) {
        this.request('GET', `${API_URL}/partial-config${getQueryString(params)}`, {
            success,
            error,
        });
    }

    getExternalToken() {
        return new Promise((resolve, reject) => {
            this.request('GET', `${API_URL}/external-auth`, {
                success: resolve,
                error: reject,
            });
        });
    }

    getViews(success, error) {
        this.request('GET', `${API_URL}/views`, { success, error });
    }

    getEntities({
        init,
        count,
        entity,
        params,
        extra,
        standard,
        sortField,
        sortDir,
        selectionToken,
        discardCount = false,
        success,
        error,
        operatorInfo,
        extraOperatorInfo,
        isWidget,
        relatedEntity,
        crossFilterInfo,
    }) {
        let headers = {
            'x-fm-discard-count': discardCount,
            'x-fm-entity-count': count,
            'x-fm-entity-offset': init,
            'x-extra-field': extra,
            'x-sort-field': sortField || '',
            'x-sort-dir': [0, 1, '0', '1'].includes(sortDir) ? sortDir : 1,
            'x-selection-token': selectionToken || '',
            'x-operator-info': operatorInfo || '',
            'x-extra-operator-info': extraOperatorInfo || '',
            'x-cross-filter-info': crossFilterInfo,
        };

        if (isWidget) {
            headers['x-fm-entity-is-widget'] = isWidget;
        }

        if (standard) {
            headers['x-standard-field'] = standard;
        }

        if (relatedEntity) {
            headers['x-related-entity'] = relatedEntity.trueName;
        }

        return this.request('GET', `${API_URL}/${entity}${params}`, {
            success,
            error,
            headers,
        });
    }

    getEntityList(
        entity,
        init,
        count,
        filter,
        sortField,
        sortDir,
        selectionToken,
        discardCount,
        success,
        error,
        operators,
        isWidget,
        relatedEntity,
    ) {
        let finalFilter = filter;
        let finalParams;
        if (filter.params) {
            finalFilter = filter.filter;
            finalParams = filter.params;
        }

        const { params, extra, standard, operatorInfo, extraOperatorInfo, crossFilterInfo } =
            formatFilters({
                filters: finalFilter,
                params: finalParams,
                operators,
                entity,
                relatedEntity,
            });

        return this.getEntities({
            init,
            count,
            entity: `${entity}/sfm`,
            params,
            extra,
            standard,
            sortField,
            sortDir,
            selectionToken,
            discardCount,
            success,
            error,
            operatorInfo,
            extraOperatorInfo,
            isWidget,
            relatedEntity,
            crossFilterInfo: !isWidget ? crossFilterInfo : [],
        });
    }

    getGeolocatedEntities(entity, init, count, filter, bounds, operators, success, error) {
        const geoParams = getGeoParams(bounds);
        const { params, extra, operatorInfo, extraOperatorInfo, crossFilterInfo } = formatFilters({
            entity,
            filters: filter,
            params: geoParams,
            operators,
        });

        return this.getEntities({
            init,
            count,
            entity: `${entity}/map`,
            params,
            extra,
            sortField: null,
            sortDir: null,
            selectionToken: null,
            discardCount: false,
            success,
            error,
            operatorInfo,
            extraOperatorInfo,
            crossFilterInfo,
        });
    }

    getContacts(params = {}) {
        return new Promise((resolve, reject) => {
            this.request('GET', `${API_URL}/contacts/sfm${getQueryString(params)}`, {
                success: (data) => {
                    if (data.Success) resolve(data.Data);
                    else resolve([]);
                },
                error: reject,
                headers: {
                    'x-fm-entity-count': 99999,
                    'x-fm-entity-offset': 0,
                    'x-extra-field': [],
                    'x-sort-field': '',
                    'x-sort-dir': '-1',
                    'x-selection-token': '',
                },
            });
        });
    }

    getContactsByEntity(params = {}) {
        return new Promise((resolve, reject) => {
            this.request('GET', `${API_URL}/contacts/by-entity${getQueryString(params)}`, {
                success: (data) => {
                    if (data && data.length > 0) resolve(data);
                },
                error: reject,
            });
        });
    }

    getAccountsKpis(params) {
        return new Promise((resolve, reject) => {
            this.request('GET', `${API_URL}/account-kpis/${params}`, {
                success: (data) => {
                    if (data.success) resolve(data.data);
                    else resolve([]);
                },
                error: reject,
            });
        });
    }

    getCompanies(init, count, filter, sortField, sortDir, success, error) {
        const params = FilterObjectToParams(filter);
        const extra = ExtraFilterObjectToParams(filter);
        this.getEntities({
            init,
            count,
            entity: 'companies/sfm',
            params,
            extra,
            sortField,
            sortDir,
            selectionToken: null,
            discardCount: false,
            success,
            error,
        });
    }

    getOpportunities(init, count, filter, sortField, sortDir, success, error) {
        const params = FilterObjectToParams(filter);
        const extra = ExtraFilterObjectToParams(filter);
        this.getEntities({
            init,
            count,
            entity: 'opportunities/sfm',
            params,
            extra,
            sortField,
            sortDir,
            selectionToken: null,
            discardCount: false,
            success,
            error,
        });
    }

    getOpportunitiesPipeline(filter, operators, customParams, success, error) {
        const { params, extra, operatorInfo, extraOperatorInfo, crossFilterInfo } = formatFilters({
            entity: OPPORTUNITIES,
            filters: filter,
            params: customParams,
            operators,
        });
        return this.request('GET', `${API_URL}/opportunities/statistics${params}`, {
            success,
            error,
            headers: {
                'x-extra-field': extra,
                'x-operator-info': operatorInfo,
                'x-extra-operator-info': extraOperatorInfo,
                'x-cross-filter-info': crossFilterInfo,
            },
        });
    }

    getSalesRecordsStatistics(filter, operators, customParams) {
        const { params, extra, operatorInfo, extraOperatorInfo, crossFilterInfo } = formatFilters({
            entity: SALESORDERS,
            filters: filter,
            params: customParams,
            operators,
        });

        return new Promise((resolve, reject) =>
            this.request('GET', `${API_URL}/salesOrders/statistics${params}`, {
                success: resolve,
                error: reject,
                headers: {
                    'x-extra-field': extra,
                    'x-operator-info': operatorInfo,
                    'x-extra-operator-info': extraOperatorInfo,
                    'x-cross-filter-info': crossFilterInfo,
                },
            }),
        );
    }

    getNotificationsWeb(init, count, idUser) {
        return new Promise((resolve, reject) => {
            this.getEntities({
                init,
                count,
                entity: 'notifications',
                params: getQueryString({ user: idUser }),
                extra: '',
                sortField: '',
                sortDir: '',
                selectionToken: null,
                discardCount: false,
                success: (data) => {
                    resolve(data);
                },
                error: reject,
            });
        });
    }

    getUnreadNotifications(params) {
        return new Promise((resolve, reject) => {
            const url = `${API_URL}/notifications/unread${getQueryString(params)}`;
            this.request('GET', url, { success: resolve, error: reject });
        });
    }

    getOrders(init, count, filter, sortField, sortDir, success, error) {
        const params = FilterObjectToParams(filter);
        const extra = ExtraFilterObjectToParams(filter);
        this.getEntities({
            init,
            count,
            entity: 'salesOrders/sfm',
            params,
            extra,
            sortField,
            sortDir,
            selectionToken: null,
            discardCount: false,
            success,
            error,
        });
    }

    getOrderProducts(id, success, error) {
        this.request('GET', `${API_URL}/salesOrders/products/${id}`, {
            success,
            error,
        });
    }

    getOrdersCards(init, count, filter, success, error) {
        // const params = FilterObjectToParams(filter);
        // const extra = ExtraFilterObjectToParams(filter);
        const { params, extra } = formatFilters({ filters: filter });
        this.getEntities({
            init,
            count,
            entity: 'salesOrders/cards',
            params,
            extra,
            sortField: '',
            sortDir: '',
            selectionToken: null,
            discardCount: false,
            success,
            error,
        });
    }

    setAsPrincipal(id, principal, success, error) {
        this.request('PUT', `${API_URL}/salesOrders/setAsPrincipal/${id}`, {
            success,
            error,
            body: {
                principal,
            },
        });
    }

    getCalendarByPeriod(
        entity,
        idEntity,
        startDate,
        endDate,
        order,
        taskFlag,
        completedFlag,
        useUTCDates,
        newAttendees,
        success,
        error,
    ) {
        const params = {
            entity,
            idEntity,
            startDate,
            endDate,
            order,
            taskFlag,
            completedFlag,
            useUTCDates,
            newAttendees,
        };
        this.request('GET', `${API_URL}/agenda/by-period${getQueryString(params)}`, {
            success,
            error,
        });
    }

    setTaskCompleted(calendarId, completed, success, error) {
        const url = `${API_URL}/agenda/${calendarId}/complete`;
        const body = {
            isCompletado: completed,
        };
        this.request('PUT', url, { success, error, body });
    }

    getDocuments(init, count, idFolder, sortField, sortDir, search, success, error) {
        const params = {
            folder: idFolder,
            search,
        };
        this.getEntities({
            init,
            count,
            entity: 'documents/sfm',
            params: getQueryString(params),
            extra: '',
            sortField,
            sortDir,
            selectionToken: null,
            discardCount: false,
            success,
            error,
        });
    }

    getFoldersDocuments(success, error) {
        this.getEntities({
            init: 0,
            count: 0,
            entity: 'folder_documents',
            params: '',
            extra: '',
            sortField: '',
            sortDir: '',
            selectionTokent: null,
            discardCount: false,
            success,
            error,
        });
    }

    getProducts(idFolder, search, offset, count, success, error) {
        const params = {
            folder: idFolder,
            search,
        };
        this.getEntities({
            init: offset,
            count,
            entity: 'products/sfm',
            params: getQueryString(params),
            extra: '',
            sortField: '',
            sortDir: '',
            selectionToken: null,
            discardCount: false,
            success,
            error,
        });
    }

    getSalesOrderProducts(
        init,
        count,
        idFolder,
        search,
        idRate,
        productIds,
        idCompany,
        idEntorno,
        success,
        error,
    ) {
        const params = {
            folder: idFolder,
            search,
            idRate,
            idCompany,
            idEntorno,
        };
        if (productIds.length > 0) {
            params.products = productIds.join();
        }
        this.getEntities({
            init,
            count,
            entity: 'salesOrders/products',
            params: getQueryString(params),
            extra: '',
            sortField: '',
            sortDir: '',
            selectionToken: null,
            discardCount: false,
            success,
            error,
        });
    }

    // Candidate for Deprecated
    getReports(init, count, idFolder, success, error) {
        const params = {
            folder: idFolder,
        };
        this.getEntities({
            init,
            count,
            entity: 'reports/sfm',
            params: getQueryString(params),
            extra: '',
            sortField: '',
            sortDir: '',
            selectionToken: null,
            discardCount: false,
            success,
            error,
        });
    }

    getReportsFolders(success, error) {
        this.getEntities({
            init: 0,
            count: 0,
            entity: 'reports/folders',
            params: '',
            extra: '',
            sortField: '',
            sortDir: '',
            selectionToken: null,
            discardCount: false,
            success,
            error,
        });
    }

    getReportsForm(idReport, nameReport, entity, idEntity, success, error, isShared) {
        const params = {
            id: idReport,
            name: nameReport,
            entity,
            idEntity,
            isShared,
        };
        this.request('GET', `${API_URL}/reports/form/${getQueryString(params)}`, {
            success,
            error,
        });
    }

    getDocumentSignForm(id, success, error) {
        const params = {
            idFile: id,
            isShared: 1,
        };
        this.request('GET', `${API_URL}/documents/form/${getQueryString(params)}`, {
            success,
            error,
        });
    }

    getFollowDocuments(init, count, sortField, sortDir, success, error) {
        this.getEntities({
            init,
            count,
            entity: 'documents/follow',
            params: '',
            extra: '',
            sortField,
            sortDir,
            selectionToken: null,
            discardCount: false,
            success,
            error,
        });
    }

    getRecentDocuments(init, count, success, error) {
        this.getEntities({
            init,
            count,
            entity: 'documents/recent',
            params: '',
            extra: '',
            sortField: '',
            sortDir: '',
            selectionToken: null,
            discardCount: false,
            success,
            error,
        });
    }

    getRates(offset, pageSize, filterSearch, success, error) {
        const params = {
            search: filterSearch,
        };
        this.getEntities({
            init: offset,
            count: pageSize,
            entity: 'rates/sfm',
            params: getQueryString(params),
            extra: '',
            sortField: '',
            sortDir: '',
            selectionToken: null,
            discardCount: false,
            success,
            error,
        });
    }

    getProductsRates(id, success, error) {
        this.request('GET', `${API_URL}/rates/products/${id}`, {
            success,
            error,
            headers: {
                'x-fm-entity-count': 200,
                'x-fm-entity-offset': 0,
            },
        });
    }

    getFamilyRates(id, success, error) {
        this.request('GET', `${API_URL}/rates/families/${id}`, {
            success,
            error,
            headers: {
                'x-fm-entity-count': 200,
                'x-fm-entity-offset': 0,
            },
        });
    }

    getCompanyRates(id, success, error) {
        this.request('GET', `${API_URL}/rates/companies/${id}`, {
            success,
            error,
            headers: {
                'x-fm-entity-count': 200,
                'x-fm-entity-offset': 0,
            },
        });
    }

    createEntity(entity, entityCrud, success, error) {
        const url = `${API_URL}/${entity}`;
        return this.request('POST', url, { success, error, body: entityCrud });
    }

    updateEntity(entity, id, entityCrud, success, error) {
        const url = `${API_URL}/${entity}/${id}`;
        this.request('PUT', url, { success, error, body: entityCrud });
    }

    deleteEntity(entity, id, success, error) {
        const url = `${API_URL}/${entity}/${id}`;
        this.request('DELETE', url, { success, error });
    }

    setFollow(entity, id, follow, isShared, success, error) {
        const url = `${API_URL}/${entity}/follow/${id}`;
        const params = {
            follow,
            isShared,
        };
        this.request('PUT', url, { success, error, body: params });
    }

    getMap(entity, bounds, filter, success, error) {
        const params = {
            dblGeoLat: bounds.nw.lat,
            dblGeoLon: bounds.nw.lng,
            dblGeoLatTo: bounds.se.lat,
            dblGeoLonTo: bounds.se.lng,
        };
        this.request('GET', `${API_URL}/${entity}/map${getQueryString(params)}`, {
            success,
            error,
            headers: {
                'x-fm-entity-count': 200,
                'x-fm-entity-offset': 0,
            },
        });
    }

    getValueList(entity, success, error) {
        const params = {
            resourcename: entity,
        };
        this.request('GET', `${API_URL}/values${getQueryString(params)}`, {
            success,
            error,
            headers: {
                'x-fm-entity-count': 2000,
                'x-fm-entity-offset': 0,
            },
        });
    }

    getFuzzySearch(
        entity,
        field,
        text,
        entityDependence,
        entityDependenceId,
        usersFromService,
        success,
        error,
    ) {
        const params = {
            field,
            text,
            usersFromService: usersFromService || '',
        };
        let url = `${API_URL}/${entity}/search`;
        url =
            entityDependence && `${entityDependenceId}` !== '-1'
                ? `${url}/${entityDependence}/${entityDependenceId}`
                : url;
        this.request('GET', url + getQueryString(params), {
            success,
            error,
            headers: {
                'x-fm-entity-count': 30,
                'x-fm-entity-offset': 0,
            },
        });
    }

    autoCompleteSearch(
        entity,
        field,
        search,
        feature,
        parentField,
        parentValue,
        idParent,
        success,
        error,
    ) {
        const params = {
            entity,
            search,
            maxResults: 30,
        };
        if (field) {
            params.field = field;
        }
        if (feature) {
            params.feature = feature;
        }
        if (parentField) {
            params.parentField = parentField;
        }
        if (parentValue) {
            params.parentValue = parentValue;
        }
        if (idParent) {
            params.idParent = idParent;
        }

        this.request('GET', `${API_URL}/auto-complete-search${getNewQueryString(params)}`, {
            success,
            error,
        });
    }

    autoCompleteSearchCompaniesCampaign(search, idCampaign, success, error) {
        const params = {
            entity: 'empresas',
            search,
            maxResults: 30,
            field: 'nombre',
            idCampaign,
        };

        this.request('GET', `${API_URL}/auto-complete-search${getNewQueryString(params)}`, {
            success,
            error,
        });
    }

    getEntity(id, type, withExtraFields, success, error) {
        return this.request('GET', `${API_URL}/${type}/${id}?withExtraFields=${withExtraFields}`, {
            success,
            error,
        });
    }

    getEntityExists(id, type, success, error) {
        this.request('GET', `${API_URL}/entityExists/${type}/${id}`, {
            success,
            error,
        });
    }

    setConfigTable(entity, configTable, success, error) {
        const url = `${API_URL}/${entity}/headerConfig`;
        this.request('POST', url, {
            success,
            error,
            body: { headers: configTable.join(';') },
        });
    }

    getGeoCode(address, success, error) {
        const params = {
            address,
        };
        this.request('GET', `${API_URL}/map/geocode${getQueryString(params)}`, {
            success,
            error,
        });
    }

    getGeoCoding(data) {
        return new Promise((success, error) => {
            this.request('POST', `${API_URL}/geolocalization/geocoding`, {
                success,
                error,
                body: data,
            });
        });
    }

    autocomplete(body) {
        return new Promise((success, error) => {
            this.request('POST', `${API_URL}/geolocalization/autocomplete`, {
                success,
                error,
                body,
            });
        });
    }

    getPlaceDetails(body) {
        return new Promise((success, error) => {
            this.request('POST', `${API_URL}/geolocalization/place-details`, {
                success,
                error,
                body,
            });
        });
    }

    getDocumentLink(type, id, success, error) {
        this.request('GET', `${API_URL}/${type}/link/${id}`, {
            success,
            error,
        });
    }

    acceptContract(type, success, error) {
        const url = `${API_URL}/acceptContract`;
        this.request('POST', url, {
            success,
            error,
            body: { contractType: type },
        });
    }

    uploadFile(entity, file, parameters, success, error) {
        // TODO TEST IT
        const { idUser, idImplementation } = parameters;
        delete parameters.idUser;
        delete parameters.idImplementation;
        const formData = new FormData();
        formData.append('file', file);
        formData.append('parameters', JSON.stringify(parameters));
        formData.append('idUser', idUser);
        formData.append('idImplementation', idImplementation);

        return this.request('POST', `${API_URL}/documents`, {
            success,
            error,
            body: formData,
        });
    }

    getReports2(init, count, type, idFolder, getFolders, extraParams = {}, success, error) {
        const params = {
            ...extraParams,
        };

        if (!extraParams?.search && !extraParams?.following) {
            if (type === 'salesOrders') {
                params[type] = 17;
            } else {
                params.getfolders = getFolders ? 'True' : '0';
                params.folder = idFolder || -1;
            }
        }
        this.getEntities({
            init,
            count,
            entity: 'reports/sfm',
            params: getQueryString(params),
            extra: '',
            sortField: '',
            sortDir: '',
            selectionToken: null,
            discardCount: false,
            success,
            error,
        });
    }

    getFavoriteReports(init, count, success, error) {
        this.getEntities({
            init,
            count,
            entity: 'reports/follow',
            params: '',
            extra: '',
            sortField: '',
            sortDir: '',
            selectionToken: null,
            discardCount: false,
            success,
            error,
        });
    }

    getReportsSearch(init, count, search, success, error) {
        const params = {};
        params.text = search;
        this.getEntities({
            init,
            count,
            entity: 'reports/search',
            params: getQueryString(params),
            extra: '',
            sortField: '',
            sortDir: '',
            selectionToken: null,
            discardCount: false,
            success,
            error,
        });
    }

    getReports2Entity(init, count, type, idType, success, error) {
        // in a near future this function and getReports2 must be a unique function
        const params = {};
        params[type] = idType;
        this.getEntities({
            init,
            count,
            entity: 'reports/sfm',
            params: getQueryString(params),
            extra: '',
            sortField: '',
            sortDir: '',
            selectionToken: null,
            discardCount: false,
            success,
            error,
        });
    }

    getReportLink(idReport, parameters, documentFormat, entityType, entityId, success, error) {
        const url = `${API_URL}/reports/${idReport}/${getQueryString({
            format: documentFormat,
        })}`;

        this.request('POST', url, {
            success,
            error,
            body: parameters,
        });
    }

    getTableReportLink(idReport, parameters, locale, success, error) {
        const url = `${API_URL}/reports/${idReport}/${locale}/tablereportlink`;

        this.request('POST', url, {
            success,
            error,
            body: parameters,
        });
    }

    getReportTable(idReport, parameters, success, error) {
        const url = `${API_URL}/reports/${idReport}/tablereport`;

        this.request('POST', url, {
            success,
            error,
            body: parameters,
        });
    }

    getReportParameters(idReport, isShared, success, error) {
        const params = {
            isShared,
        };
        const url = `${API_URL}/reports/${idReport}/parameters${getQueryString(params)}`;
        this.request('GET', url, {
            success,
            error,
        });
    }

    getCompanyAddress(id, success, error) {
        this.request('GET', `${API_URL}/companies/address/${id}`, {
            success,
            error,
        });
    }

    addCompanyAddress(entity, success, error) {
        const url = `${API_URL}/companies/address`;
        this.request('POST', url, {
            success,
            error,
            body: entity,
        });
    }

    updateCompanyAddress(entity, id, success, error) {
        const url = `${API_URL}/companies/address/${id}`;
        this.request('PUT', url, {
            success,
            error,
            body: entity,
        });
    }

    deleteCompanyAddress(id, success, error) {
        const url = `${API_URL}/companies/address/${id}`;
        this.request('DELETE', url, {
            success,
            error,
        });
    }

    getDocumentsEntity(entityType, entityId, idFolder, success, error) {
        const params = {
            folder: idFolder,
        };
        const url = `${API_URL}/documents/${entityType}/${entityId}${getQueryString(params)}`;
        this.request('GET', url, {
            success,
            error,
        });
    }

    setReportsForm(entityType, signedFormModel, success, error) {
        const url = `${API_URL}/${entityType}/sign`;
        this.request('POST', url, {
            success,
            error,
            body: signedFormModel,
        });
    }

    documentSignStatus(id, success, error) {
        const params = {
            providerIdentifierFile: id,
        };
        const url = `${API_URL}/documents/signStatus/${getQueryString(params)}`;
        this.request('GET', url, {
            success,
            error,
        });
    }

    getCompanySettings(success, error) {
        const url = `${API_URL}/settings/company`;
        this.request('GET', url, {
            success,
            error,
        });
    }

    getCompanySettingsEnvironment(idEnvironment, success, error) {
        const url = `${API_URL}/settings/companyEnvironment/${idEnvironment}`;
        this.request('GET', url, {
            success,
            error,
        });
    }

    setCompanySettings(entity, success, error) {
        const url = `${API_URL}/settings/company`;
        this.request('PUT', url, {
            success,
            error,
            body: entity,
        });
    }

    setCompanySettingsEnvironment(idEnvironment, entity, success, error) {
        const url = `${API_URL}/settings/companyEnvironment/${idEnvironment}`;
        this.request('PUT', url, { success, error, body: entity, handle0Status: true });
    }

    setProfileSettings(id, entity, success, error) {
        const url = `${API_URL}/settings/profile/${id}`;
        this.request('PUT', url, {
            success,
            error,
            body: entity,
        });
    }

    savePassword(data, success, error) {
        const url = `${API_URL}/users/change-user-password`;
        this.request('POST', url, {
            success,
            error,
            body: data,
        });
    }

    deleteDocumentProvider(id, success, error) {
        const url = `${API_URL}/documents/${id}/provider`;
        this.request('DELETE', url, {
            success,
            error,
        });
    }

    setConfiguration(configuration, success, error) {
        const url = `${API_URL}/settings/cache`;
        this.request('POST', url, {
            success,
            error,
            body: configuration,
        });
    }

    getConfiguration(success, error) {
        const url = `${API_URL}/settings/cache`;
        this.request('GET', url, {
            success,
            error,
        });
    }

    getIntegrations(success, error) {
        this.request('GET', `${API_URL}/integrations`, {
            success,
            error,
        });
    }

    setIntegrations(id, model, success, error) {
        const url = `${API_URL}/integrations/${id}`;
        this.request('PUT', url, {
            success,
            error,
            body: model,
        });
    }

    sendMailSimple(data, success, error) {
        const url = `${API_URL}/mailer`;
        this.request('POST', url, {
            success,
            error,
            body: data,
        });
    }

    sendSignatureReminder(data, success, error) {
        const url = `${API_URL}/sendSignatureReminder`;
        this.request('POST', url, {
            success,
            error,
            body: data,
        });
    }

    getStartExcelExport(entity, success, error) {
        this.request('GET', `${API_URL}/exports/${entity}`, {
            success,
            error,
        });
    }

    getExportDataIDWithPagination(entity, filter, operators, options, success, error) {
        const { params, extra, standard, operatorInfo, extraOperatorInfo, crossFilterInfo } =
            formatFilters({
                filters: filter,
                forceListAsObject: true,
                operators,
                entity,
                fixForSubfiltersExport: entity === ACTIVITIES.exportEntity,
            });

        // Query string length patch
        let { strFieldsToExport, strFieldsToExportRelated, ...restOptions } = options;
        const option = formatOptions(getQueryString(restOptions), params);

        this.request('POST', `${API_URL}/exports/${entity}/params${params + option}`, {
            success,
            error,
            body: {
                strFieldsToExportRelated,
                strFieldsToExport,
            },
            headers: {
                'x-extra-field': extra,
                'x-standard-field': standard,
                'x-operator-info': operatorInfo,
                'x-extra-operator-info': extraOperatorInfo,
                'x-cross-filter-info': crossFilterInfo,
            },
        });
    }

    getExcelExcelExportStatusByTokenId(entity, filter, options, success, error) {
        const { params, extra, standard } = formatFilters({
            filters: filter,
            forceListAsObject: true,
        });
        const option = formatOptions(getQueryString(options), params);
        this.request('GET', `${API_URL}/exports/status/${entity}/params${params + option}`, {
            success,
            error,
            headers: {
                'x-extra-field': extra,
                'x-standard-field': standard,
            },
        });
    }

    getApiKey(success, error) {
        this.request('GET', `${API_URL}/integrations/getApiKey`, {
            success,
            error,
        });
    }

    createApiKey(success, error) {
        this.request('POST', `${API_URL}/integrations/generateApiKey`, {
            success,
            error,
        });
    }

    checkIntegrationEnabled(provider, success) {
        success(true);
    }

    mismatchedTickets(success, error) {
        this.request('GET', `${API_URL}/integrations/zendesk/tickets`, {
            success,
            error,
        });
    }

    getUsersTree(idUser, success, error) {
        this.request('GET', `${API_URL}/users/hierarchyjson/${idUser}`, {
            success,
            error,
        });
    }

    getIntegrationProperties(idprovider, properties, success, error) {
        this.request('POST', `${API_URL}/integrations/getIntegrationProperties/${idprovider}`, {
            success,
            error,
            body: properties,
        });
    }

    setIntegrationProperties(idprovider, properties, success, error) {
        this.request('POST', `${API_URL}/integrations/setIntegrationProperties/${idprovider}`, {
            success,
            error,
            body: properties,
        });
    }

    syncroStats(properties, success, error) {
        this.request('POST', `${API_URL}/integrations/salesforce/syncroStats`, {
            success,
            error,
            body: properties,
        });
    }

    sageSyncroStats(properties, success, error) {
        this.request('POST', `${API_URL}/integrations/sage/syncroStats`, {
            success,
            error,
            body: properties,
        });
    }

    getSyncroStats(provider, properties, success, error) {
        this.request('POST', `${API_URL}/integrations/${provider}/syncroStats`, {
            success,
            error,
            body: properties,
        });
    }

    refreshWebTemplates(success, error) {
        this.request('GET', `${API_URL}/webTemplates`, {
            success,
            error,
        });
    }

    getSalesforceUsers(success, error) {
        this.request('GET', `${API_URL}/integrations/salesforce/salesforceUsers`, {
            success,
            error,
        });
    }

    insertMatchedEntities(properties, success, error) {
        this.request('POST', `${API_URL}/integrations/salesforce/insertMatchedEntities`, {
            success,
            error,
            body: properties,
        });
    }

    integrationJobActions(idprovider, properties, success, error) {
        this.request('POST', `${API_URL}/integrations/integrationJobActions/${idprovider}`, {
            success,
            error,
            body: properties,
        });
    }

    setProviderUserConfiguration(idprovider, properties, success, error) {
        this.request('POST', `${API_URL}/integrations/setProviderUserConfiguration/${idprovider}`, {
            success,
            error,
            body: properties,
        });
    }

    getSyncroLogs(provider, success, error) {
        this.request('GET', `${API_URL}/integrations/${provider}/syncroLogs`, {
            success,
            error,
        });
    }

    getSalesforceSyncLog(success, error) {
        this.request('GET', `${API_URL}/integrations/salesforce/syncroLogs`, {
            success,
            error,
        });
    }

    getSageSyncLog(success, error) {
        this.request('GET', `${API_URL}/integrations/sage/syncroLogs`, {
            success,
            error,
        });
    }

    setUsersProviderRoles(providerName, properties, success, error) {
        this.request('POST', `${API_URL}/integrations/assignUsersToProviderRole/${providerName}`, {
            success,
            error,
            body: properties,
        });
    }

    getUsersProviderRoles(providerName, offset, maxResults, success, error) {
        this.request(
            'GET',
            `${API_URL}/integrations/usersProviderRole/${providerName}?offset=${offset}&maxResults=${maxResults}`,
            {
                success,
                error,
            },
        );
    }

    getBlackListedEmails(offset, maxResults, success, error) {
        this.request(
            'GET',
            `${API_URL}/integrations/blackListedEmails?offset=${offset}&maxResults=${maxResults}`,
            {
                success,
                error,
            },
        );
    }

    setConfigItemStatus(properties, success, error) {
        this.request('POST', `${API_URL}/integrations/setConfigItemStatus`, {
            success,
            error,
            body: properties,
        });
    }

    mergeEntities(data, entity, success, error) {
        this.request('POST', `${API_URL}/${entity}/merge`, {
            success,
            error,
            body: data,
        });
    }

    getMergeCandidates(sourceId, entity, success, error) {
        this.request('GET', `${API_URL}/${entity}/mergeCandidates/${sourceId}`, {
            success,
            error,
        });
    }

    startBulk(params, success, error) {
        const { crossFilters, operators, ...otherParams } = params;
        const { operatorInfo, extraOperatorInfo } = operators;
        this.request('POST', `${API_URL}/bulkOperations`, {
            success,
            error,
            headers: {
                'x-operator-info': JSON.stringify(operatorInfo),
                'x-extra-operator-info': JSON.stringify(extraOperatorInfo),
                'x-cross-filter-info': crossFilters ? JSON.stringify(crossFilters) : '[]',
            },
            body: otherParams,
        });
    }

    getEntityCounts(
        entity,
        params,
        success,
        error,
        operatorInfo,
        extraOperatorInfo,
        crossFilterInfo,
    ) {
        const extraFieldsFilter = params?.filter?.extraFieldsFilter;

        // Remove the ValueFrom or ValueTo keys of the extra fields object array
        // when the operator is set to match the ValueFrom/ValueTo keys set in the corresponding operator.
        if (extraOperatorInfo && extraOperatorInfo.length > 0 && extraFieldsFilter) {
            params.filter.extraFieldsFilter = extraFieldsFilter.reduce((arr, item) => {
                const operator = extraOperatorInfo.filter((op) => op.Field === item.Field)[0];
                if (operator) {
                    if (!operator.ValueFrom) delete item.ValueFrom;
                    if (!operator.ValueTo) delete item.ValueTo;
                }
                return [...arr, item];
            }, []);
        }

        operatorInfo = operatorInfo ? JSON.stringify(operatorInfo) : '{}';
        extraOperatorInfo = extraOperatorInfo ? JSON.stringify(extraOperatorInfo) : '[]';
        crossFilterInfo = crossFilterInfo ? JSON.stringify(crossFilterInfo) : '[]';
        return this.request('POST', `${API_URL}/entities/${entity}/count`, {
            success,
            error,
            body: params,
            headers: {
                'x-operator-info': operatorInfo,
                'x-extra-operator-info': extraOperatorInfo,
                'x-cross-filter-info': crossFilterInfo,
            },
        });
    }

    getCompanyDiscounts(id, success, error) {
        this.request('GET', `${API_URL}/companydiscounts/${id}`, {
            success,
            error,
        });
    }

    deleteCompanyDiscounts(id, success, error) {
        this.request('DELETE', `${API_URL}/companydiscounts/${id}`, {
            success,
            error,
        });
    }

    setCompanyDiscounts(data, success, error) {
        this.request('POST', `${API_URL}/companydiscounts`, {
            success,
            error,
            body: data,
        });
    }

    getEntityDocuments(id, entity, success, error) {
        this.request('GET', `${API_URL}/entityDocuments/${entity}/${id}`, {
            success: (data) => {
                if (!data) return error?.();
                try {
                    success(JSON.parse(data));
                } catch (e) {
                    console.error(e);
                    error?.(e);
                }
            },
            error,
        });
    }

    getProductsDocuments(id, entity, success, error) {
        this.request(
            'GET',
            `${API_URL}/entityDocuments/${entity}/${id}?getDocumentsForProducts=true`,
            {
                success: (data) => success(JSON.parse(data)),
                error,
            },
        );
    }

    getBillboardMenu(success, error) {
        this.request('GET', `${API_URL}/billboard/menu`, {
            success,
            error,
        });
    }

    getBillboardList(offset, pageSize, parameters, success, error) {
        this.getEntities({
            init: offset,
            count: pageSize,
            entity: 'billboard/list',
            params: getQueryString(parameters),
            extra: '',
            sortField: '',
            sortDir: '',
            selectionTokent: null,
            discardCount: false,
            success,
            error,
        });
    }

    getBillboard(id, isAdmin, success, error) {
        const params = {
            isadmin: isAdmin,
        };
        this.request('GET', `${API_URL}/billboard/${id}${getQueryString(params)}`, {
            success,
            error,
        });
    }

    createBillboard(entity, success, error) {
        this.request('POST', `${API_URL}/billboard`, {
            success,
            error,
            body: entity,
        });
    }

    getPendingAgreements() {
        return new Promise((resolve, reject) => {
            const url = `${API_URL}/get-pending-agreements`;
            this.request('POST', url, {
                success: (data) => {
                    resolve(JSON.parse(data));
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    }

    setAgreements(data) {
        return new Promise((resolve, reject) => {
            this.request('POST', `${API_URL}/set-agreements`, {
                success: () => resolve(),
                error: (err) => reject(err),
                body: data,
            });
        });
    }

    resendBillboard(entity, recompute, success, error) {
        const params = {
            recompute,
        };
        this.request('PUT', `${API_URL}/billboard${getQueryString(params)}`, {
            success,
            error,
            body: entity,
        });
    }

    markBillboardAsOpened(id, success, error) {
        const params = {
            type: 'Opened',
        };
        this.request('PUT', `${API_URL}/billboard/action/${id}`, {
            success,
            error,
            body: params,
        });
    }

    markBillboardAsConfirmed(id, success, error) {
        const params = {
            type: 'Confirmed',
        };
        this.request('PUT', `${API_URL}/billboard/action/${id}`, {
            success,
            error,
            body: params,
        });
    }

    getBillboardActionDetails(id, status, success, error) {
        const params = {
            status,
        };
        this.request('GET', `${API_URL}/billboard/actionDetails/${id}${getQueryString(params)}`, {
            success,
            error,
        });
    }

    getBillboardEnvironmentsWithGroups() {
        return new Promise((resolve, reject) => {
            const url = `${API_URL}/billboard/environments-groups`;
            this.request('GET', url, {
                success: (data) => {
                    if (data.State !== '1') return reject(data);
                    resolve(data.Result);
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    }

    getDataForMailChimp(filters, operators, success, error) {
        const { operatorInfo, extraOperatorInfo } = formatSelectionOperators(operators);
        const crossFilterInfo = formatCrossFilters({ entity: COMPANIES });
        this.request('POST', `${API_URL}/mailchimp/data`, {
            success,
            error,
            headers: {
                'x-operator-info': JSON.stringify(operatorInfo),
                'x-extra-operator-info': JSON.stringify(extraOperatorInfo),
                'x-cross-filter-info': JSON.stringify(crossFilterInfo),
            },
            body: filters,
        });
    }

    getMailChimpLists(success, error) {
        this.request('GET', `${API_URL}/mailchimp/list`, {
            success,
            error,
        });
    }

    createMailChimpList(list, success, error) {
        // TODO TEST IT
        this.request('POST', `${API_URL}/mailchimp/list`, {
            success,
            error,
            body: list,
        });
    }

    exportToMailchimp(data, success, error) {
        this.request('POST', `${API_URL}/mailchimp/export`, {
            success,
            error,
            body: data,
        });
    }

    getIntegrationsFmFields(success, error) {
        this.request('GET', `${API_URL}/fullFieldsSchema`, {
            success,
            error,
        });
    }

    getIntegrationsFmUsers(success, error) {
        this.request('GET', `${API_URL}/jsonUsers`, {
            success,
            error,
        });
    }

    getIntegrationsExtFields(provider, entity, success, error) {
        this.request('GET', `${API_URL}/integrations/fieldSchema/${provider}?entity=${entity}`, {
            success,
            error,
        });
    }

    getIntegrationsExtUsers(provider, success, error) {
        this.request('GET', `${API_URL}/integrations/users/${provider}`, {
            success,
            error,
        });
    }

    getIntegrationsFieldsConfig(provider, success, error) {
        this.request('GET', `${API_URL}/integrations/getProviderConfiguration/${provider}`, {
            success,
            error,
        });
    }

    setIntegrationsFieldsConfig(provider, data, success, error) {
        this.request('POST', `${API_URL}/integrations/mappedFields/${provider}`, {
            success,
            error,
            body: data,
        });
    }

    getIntegrationsUsersConfig(provider, success, error) {
        this.request('GET', `${API_URL}/integrations/mappedUsers/${provider}`, {
            success,
            error,
        });
    }

    setIntegrationsUsersConfig(provider, data, success, error) {
        this.request('POST', `${API_URL}/integrations/mappedUsers/${provider}`, {
            success,
            error,
            body: data,
        });
    }

    getExcelExportToken(entity, success, error) {
        this.request('GET', `${API_URL}/excelExports/token/${entity}`, {
            success,
            error,
        });
    }

    startExcelExport(entity, token, isDownload, success, error) {
        this.request(
            'GET',
            `${API_URL}/excelExports/start/${entity}?tokenId=${token}&isDownload=${isDownload}`,
            {
                success,
                error,
            },
        );
    }

    getExcelExportStatus(token, success, error) {
        this.request('GET', `${API_URL}/excelExports/status?tokenId=${token}`, {
            success,
            error,
        });
    }

    startProviderExport(providerName, success, error) {
        this.request('GET', `${API_URL}/exports/provider/${providerName}`, {
            success,
            error,
        });
    }

    providerExport(providerName, data, success, error) {
        const { operators, ...payload } = data;
        const { operatorInfo, extraOperatorInfo } = formatSelectionOperators(operators);
        const crossFilterInfo = formatCrossFilters({ entity: COMPANIES });
        this.request('POST', `${API_URL}/exports/provider/${providerName}/params`, {
            success,
            error,
            headers: {
                'x-operator-info': JSON.stringify(operatorInfo),
                'x-extra-operator-info': JSON.stringify(extraOperatorInfo),
                'x-cross-filter-info': JSON.stringify(crossFilterInfo),
            },
            body: payload,
        });
    }

    getProviderExportDataByTokenId(providerName, tokenId, success, error) {
        const params = {
            tokenid: tokenId,
        };
        this.request(
            'GET',
            `${API_URL}/exports/provider/${providerName}/status${getQueryString(params)}`,
            {
                success,
                error,
            },
        );
    }

    getExportEmailOrDownload(entity, options, success, error) {
        this.request('POST', `${API_URL}/exports/${entity}/emailOrDownload`, {
            success,
            error,
            body: options,
        });
    }

    getCancelSubscriptionReasons(success, error) {
        this.request('GET', `${API_URL}/settings/cancelSubscriptionReasons`, {
            success,
            error,
        });
    }

    setCompleteTour(success, error) {
        this.request('PUT', `${API_URL}/complete-tour`, {
            success,
            error,
        });
    }

    getExtrafieldsByEntity(entityType, entityId, setDynamicAsHidden, success, error) {
        const params = {
            entityType,
            entityId,
            setDynamicAsHidden,
        };
        this.request('GET', `${API_URL}/extrafields-by-entity${getQueryString(params)}`, {
            success: (response) => {
                if (response) return success(JSON.parse(response));
                error();
            },
            error,
        });
    }

    setEndWalkThroughDate(dateNow, success, error) {
        this.request('PUT', `${API_URL}/set-end-walk-through-date`, {
            success,
            error,
            body: dateNow,
        });
    }

    getPasswordPolicy(success, error, hash) {
        let url = `${API_URL}/settings/password-policy`;
        if (hash) url += `/${hash}`;
        this.request('GET', url, {
            success,
            error,
        });
    }

    getPasswordUserPolicy(success, error) {
        let url = `${API_URL}/settings/password-user-policy`;
        this.request('GET', url, {
            success,
            error,
        });
    }

    getPasswordPolicyAuth(authData, success, error) {
        let url = `${API_URL}/settings/password-policy-auth`;
        this.rawRequest('POST', url, {
            success,
            error,
            headers: {
                'x-fm-user': authData.user,
                'x-fm-auth': authData.userKey,
            },
            body: authData,
        });
    }

    saveCancelSubscription(reason, success, error) {
        this.request('POST', `${API_URL}/settings/saveCancelSubscription`, {
            success,
            error,
            body: reason,
        });
    }
    sendEntityListSelection(selection, onConnection) {
        const { operators = {}, crossFilterInfo = [], ...payload } = selection;
        const { operatorInfo = {}, extraOperatorInfo = [] } = operators;

        return new Promise((resolve, reject) => {
            const url = `${API_URL}/entities-selection`;
            const request = this.request('POST', url, {
                success: (data) => {
                    if (data.State !== '1') return reject(data);
                    resolve(data.Result);
                },
                error: (err) => {
                    reject(err);
                },
                headers: {
                    'x-operator-info': JSON.stringify(operatorInfo),
                    'x-extra-operator-info': JSON.stringify(extraOperatorInfo),
                    'x-cross-filter-info': JSON.stringify(crossFilterInfo),
                },
                body: payload,
            });

            if (onConnection) onConnection(request);
        });
    }

    sendEntityListSelectionInfo(selection) {
        const params = getQueryString(selection);
        return new Promise((resolve, reject) => {
            const url = `${API_URL}/entities-selection/info${params}`;
            this.request('GET', url, {
                success: (data) => {
                    if (data.State !== '1') return reject(data);
                    resolve(data.Result);
                },
                error: (err) => {
                    reject(err);
                },
                body: selection,
            });
        });
    }

    selectColumns(entityName, headers) {
        return new Promise((success, error) => {
            const url = `${API_URL}/entities/${entityName}/columns-configuration`;
            this.request('POST', url, { success, error, body: { headers } });
        });
    }

    deleteWalkthroughData() {
        return new Promise((resolve, error) => {
            const url = `${API_URL}/delete-walk-through-data`;
            this.request('DELETE', url, {
                success: (data) => {
                    if (data && data.State === '1' && data.Result === 'OK') {
                        resolve();
                    } else {
                        error();
                    }
                },
                error,
            });
        });
    }

    removeView(id) {
        return new Promise((success, error) => {
            const url = `${API_URL}/views/${id}`;
            this.request('DELETE', url, { success, error });
        });
    }

    createView(view = {}) {
        return new Promise((resolve, error) => {
            const url = `${API_URL}/views`;

            const {
                operatorInfo = {},
                extraOperatorInfo = [],
                crossFilterInfo = [],
                ...payload
            } = view;

            this.request('POST', url, {
                success: (data) => {
                    if (data.state !== '1') return error(data);
                    resolve({
                        entity: payload.entity,
                        name: payload.name,
                        id: data.id,
                        isPublic: payload.isPublic ? 'True' : 'False',
                    });
                },
                error,
                headers: {
                    'x-operator-info': JSON.stringify(operatorInfo),
                    'x-extra-operator-info': JSON.stringify(extraOperatorInfo),
                    'x-cross-filter-info': JSON.stringify(crossFilterInfo),
                },
                body: payload,
            });
        });
    }

    getServerList(list) {
        return new Promise((resolve, error) => {
            const params = { resourcename: list };
            this.request('GET', `${API_URL}/values${getQueryString(params)}`, {
                success: (data) => {
                    let finalData = [];
                    if (data && data.data && data.data[list] && data.data[list]) {
                        let dataList = data.data[list];
                        if (!Array.isArray(dataList)) dataList = [dataList];
                        finalData = dataList.map((item) => ({
                            ...item,
                            value: item['-id'],
                            label: item.descripcion || item.strname || item.nombre,
                            blndefault: item.blndefault === 'True',
                            blnendstate: item.blnendstate === 'True',
                            blnsystem: item.blnsystem === 'True',
                        }));
                    }
                    resolve(finalData);
                },
                error,
                headers: {
                    'x-fm-entity-count': 2000,
                    'x-fm-entity-offset': 0,
                },
            });
        });
    }

    getRatesServerList() {
        return new Promise((resolve, error) => {
            this.request('GET', `${API_URL}/rates/sfm`, {
                success: (data) => {
                    if (data.State === '1') {
                        resolve(data.Result);
                    } else {
                        error();
                    }
                },
                error,
                headers: { 'x-fm-entity-count': 2000, 'x-fm-entity-offset': 0 },
            });
        });
    }

    getDataForEntityWidget(params) {
        return new Promise((resolve, error) => {
            const url = `${API_URL}/widget-data${getQueryString(params)}`;
            this.request('GET', url, {
                success: (data) => {
                    if (data.State === '1') {
                        resolve(data.Result);
                    } else {
                        error();
                    }
                },
                error,
            });
        });
    }

    getDataForChip(chipType, entityId) {
        return new Promise((resolve, error) => {
            const url = `${API_URL}/chip-data?chipType=${chipType}&entityId=${entityId}`;
            this.request('GET', url, {
                success: (data) => {
                    if (data.State === '1') resolve(data.Result[0]);
                    else error();
                },
                error,
            });
        });
    }

    getDuplicateForFields(entity, field, value) {
        const finalValue = encodeURIComponent(value);
        return new Promise((resolve, error) => {
            const url = `${API_URL}/check-duplicate/?entity=${entity}&field=${field}&value=${finalValue}`;
            this.request('GET', url, {
                success: (data) => {
                    if (data.State === '1') resolve(data.Result[0]);
                    else error();
                },
                error,
            });
        });
    }

    checkDuplicateFields(entity, fieldValues, entityId) {
        return new Promise((resolve, error) => {
            let url = `${API_URL}/check-duplicate-fields/${entity}`;
            if (entityId) url += `/${entityId}`;
            this.request('POST', url, {
                success: (data) => {
                    if (data.State === '1') resolve(data.Result);
                    else error();
                },
                error,
                body: fieldValues,
            });
        });
    }

    getRelatedCompanies(id, offset, limit) {
        return new Promise((success, error) => {
            const url = `${API_URL}/companies/${id}/related?offset=${offset}&limit=${limit}`;
            this.request('GET', url, { success, error });
        });
    }

    createRelation(relation) {
        return new Promise((success, error) => {
            const url = `${API_URL}/relation`;
            this.request('POST', url, { success, error, body: relation });
        });
    }

    updateRelation(id, relation) {
        return new Promise((success, error) => {
            const url = `${API_URL}/relation/${id}`;
            this.request('PUT', url, { success, error, body: relation });
        });
    }

    deleteRelation(id, data) {
        return new Promise((success, error) => {
            const url = `${API_URL}/relation/${id}`;
            this.request('DELETE', url, { success, error, body: data });
        });
    }

    getDocumentStatus(id) {
        return new Promise((resolve, error) => {
            const url = `${API_URL}/documents/${id}/status`;
            this.request('GET', url, {
                success: (data) => {
                    try {
                        resolve(JSON.parse(data));
                    } catch (e) {
                        console.error(e);
                        error(e);
                    }
                },
                error,
            });
        });
    }

    massiveUpdateDocumentsAndFolders(body) {
        return new Promise((success, error) => {
            this.request('POST', `${API_URL}/documents/massive`, {
                success,
                error,
                body,
            });
        });
    }

    setTrackingEvents({ entityId, idObjectType, idEventType }) {
        return new Promise((resolve, error) => {
            const params = { entityId, idObjectType, idEventType };
            const url = `${API_URL}/tracking-events/${getQueryString(params)}`;
            this.request('POST', url, {
                success: (data) => {
                    if (data.State !== '1') return error(data);
                    resolve(data.Result);
                },
                error,
            });
        });
    }

    setRelevantFields({ extraFieldNames, fieldsEntity }) {
        return new Promise((resolve, error) => {
            const params = { extraFieldNames, fieldsEntity };
            const url = `${API_URL}/relevant-fields/${getQueryString(params)}`;
            this.request('POST', url, {
                success: (data) => {
                    if (data.State !== '1') return error(data);
                    resolve(data.Result);
                },
                error,
            });
        });
    }

    analyticsProNewUserMail() {
        return new Promise((success, error) => {
            const url = `${API_URL}/analytics-pro-promotion-contact`;
            this.request('POST', url, { success, error });
        });
    }

    startUpgrade(body) {
        return new Promise((success, error) => {
            const url = `${API_URL}/settings/start-upgrade`;
            this.request('POST', url, { success, error, body });
        });
    }

    upgradeAccount(body) {
        return new Promise((success, error) => {
            const url = `${API_URL}/settings/upgrade-account`;
            this.request('POST', url, { success, error, body });
        });
    }

    sendEmailType(body) {
        return new Promise((success, error) => {
            const url = `${API_URL}/email-type`;
            this.request('POST', url, { success, error, body });
        });
    }

    recoverPassword(body) {
        return new Promise((success, error) => {
            const url = `${API_URL}/users/recover-password`;
            this.rawRequest('POST', url, {
                success,
                error,
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                noParse: true,
                body,
            });
        });
    }

    changePassword(body) {
        return new Promise((success, error) => {
            const url = `${API_URL}/users/change-password`;
            this.rawRequest('POST', url, {
                success,
                error,
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                noParse: true,
                body,
            });
        });
    }

    changeExpiredPassword(body) {
        return new Promise((resolve, reject) => {
            const url = `${API_URL}/users/change-expired-password`;
            this.rawRequest('POST', url, {
                success: (data) => {
                    resolve(JSON.parse(data));
                },
                reject,
                body,
            });
        });
    }

    checkUserExists(body) {
        return new Promise((success, error) => {
            const url = `${API_URL}/users/exists`;
            this.rawRequest('POST', url, {
                success,
                error,
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                body,
            });
        });
    }

    setDisclaimer(body) {
        return new Promise((resolve, error) => {
            const dateFormatted = moment().format('dddd, MMMM D, YYYY');
            const data = `User: ${
                Context.config?.userData?.email || ''
            } TimeStamp: ${dateFormatted} Device: 101`;

            this.request('POST', `${API_URL}/set-disclaimer`, {
                success: (data) => {
                    resolve(JSON.parse(data.result));
                },
                error,
                body: { ...body, data },
            });
        });
    }

    tryTokenRefreshing(token) {
        return refreshToken(token).then(persistTokens);
    }

    request(...payload) {
        const [type, url, props = {}] = payload;
        const { success, error, contentType, handle0Status, isRetry, body, headers = {} } = props;
        const xhr = this.createCORSRequest();
        xhr.withCredentials = false;
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                try {
                    if (
                        xhr.responseText.includes('auth-error') ||
                        xhr.responseText.includes('auth-blocked') ||
                        xhr.responseText.includes('Error -9 error_login_blocked') ||
                        xhr.responseText.includes('Error -9 error_login') ||
                        xhr.status === 401
                    ) {
                        const refreshToken =
                            Context?.config?.refreshToken ||
                            Cookies.get('refreshToken', {
                                domain: getCookieDomainName(window.location.hostname),
                                expires: 31,
                            });
                        if (refreshToken && !isRetry) {
                            return this.tryTokenRefreshing(refreshToken)
                                .then(() => this.request(type, url, { ...props, isRetry: true }))
                                .catch((e) => {
                                    goLogout();
                                    console.error(e);
                                    error?.(xhr.responseText);
                                });
                        } else {
                            goLogout();
                            return error?.(xhr.responseText);
                        }
                    }
                    if (xhr.status === 200 && xhr.responseText !== 'No result') {
                        const response =
                            xhr.responseText !== ''
                                ? JSON.parse(xhr.responseText)
                                : xhr.responseText;
                        success(response);
                    } else if (xhr.status !== 0) {
                        error?.(xhr.responseText);
                    } else if (handle0Status && xhr.status === 0) {
                        error?.(xhr.responseText);
                    }
                } catch (err) {
                    console.error(err);
                    error?.(err);
                }
            }
        };

        xhr.open(type, url);

        xhr.setRequestHeader('x-fm-token', this.token);
        xhr.setRequestHeader('x-fm-device-type', '101');
        xhr.setRequestHeader('x-fm-version', WEB_VERSION);
        xhr.setRequestHeader(
            'x-fm-db-server',
            Context?.config?.serverAssignment?.XFMServer !== '-1'
                ? Context?.config?.serverAssignment?.XFMServer || 'default'
                : 'default',
        );
        xhr.setRequestHeader(
            'Cache-Control',
            'max-age=0, no-cache, must-revalidate, proxy-revalidate',
        );
        xhr.setRequestHeader('Content-Type', contentType || 'application/json;charset=UTF-8');
        Object.keys(headers).forEach((key) => xhr.setRequestHeader(key, headers[key]));
        if (body) xhr.send(JSON.stringify(body));
        else xhr.send();
        return xhr;
    }

    rawRequest(...payload) {
        const [type, url, props = {}] = payload;
        const { success, error, body, headers = {}, noParse = false } = props;

        const xhr = this.createCORSRequest();
        xhr.withCredentials = false;
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                try {
                    if (xhr.status === 200 && xhr.responseText !== 'No result') {
                        const response =
                            xhr.responseText !== ''
                                ? noParse
                                    ? xhr.responseText
                                    : JSON.parse(xhr.responseText)
                                : xhr.responseText;
                        success(response);
                    } else if (xhr.status !== 0) {
                        error(xhr.responseText);
                    }
                } catch (err) {
                    console.error(err);
                    error && error(err);
                }
            }
        };

        xhr.open(type, url);
        xhr.setRequestHeader('x-fm-device-type', '101');
        xhr.setRequestHeader('x-fm-version', WEB_VERSION);
        xhr.setRequestHeader(
            'Cache-Control',
            'max-age=0, no-cache, must-revalidate, proxy-revalidate',
        );
        Object.keys(headers).forEach((key) => xhr.setRequestHeader(key, headers[key]));
        if (body) xhr.send(JSON.stringify(body));
        else xhr.send();
        return xhr;
    }

    createCORSRequest() {
        let xhr = new XMLHttpRequest();
        if ('withCredentials' in xhr) {
            return xhr;
        } else if (typeof window.XDomainRequest !== 'undefined') {
            xhr = new window.XDomainRequest();
        } else {
            xhr = null;
        }
        return xhr;
    }
}
