import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Scrollbar from 'components/ScrollBar';

import SalesOrdersWidgetRow from './SalesOrdersWidgetRow';
import { WidgetEmpty } from 'containers/components/widgets/Layouts';
import Literal from 'components/Literal'; // FIXME Please use getLiteral here
import { Salesorders } from 'components/SvgIcons';
import colors from 'constants/colors';

const propTypes = {
    list: PropTypes.array,
    loading: PropTypes.bool,
    isVisibble: PropTypes.bool,
    entityType: PropTypes.object,
    refreshSalesOrders: PropTypes.func,
};

class SalesOrdersWidgetTable extends PureComponent {
    state = {
        scrollTop: 0,
    };

    handleScrollFrame = (values) => {
        const { list, counter, getSalesOrders } = this.props;
        if (values.top === 1 && list.length < counter) {
            this.setState({ scrollTop: values.scrollTop });
            getSalesOrders();
        }
    };

    renderEmpty = () => {
        const { tabSelected } = this.props;

        const emptyWidgetLiteral =
            tabSelected === 0
                ? 'error_norelatedopensalesorders'
                : 'error_norelatedclosedsalesorders';

        return (
            <WidgetEmpty
                icon={<Salesorders color={colors.grey[100]} />}
                text={<Literal literal={emptyWidgetLiteral} />}
            />
        );
    };

    render() {
        const { list, isVisible, entityType, refreshSalesOrders, counter } = this.props;

        if (!isVisible) return null;
        const shouldRenderEmpty = !!(counter && (counter === 0 || counter === -1));

        const rows = list.map((current) => {
            return (
                <SalesOrdersWidgetRow
                    key={current.id}
                    data={current}
                    entityType={entityType}
                    refreshSalesOrders={refreshSalesOrders}
                />
            );
        });

        return (
            <div className="sales-orders-widget-table">
                {!shouldRenderEmpty && (
                    <Scrollbar onScrollFrame={this.handleScrollFrame}>
                        <div className="sales-orders-widget-table-container">{rows}</div>
                    </Scrollbar>
                )}
                {shouldRenderEmpty && this.renderEmpty()}
            </div>
        );
    }
}

SalesOrdersWidgetTable.propTypes = propTypes;

export default SalesOrdersWidgetTable;
