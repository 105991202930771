import React, { memo, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { useTheme, Icon, Text } from 'hoi-poi-ui';
import { bindActionCreators } from 'redux';
import { EntityActions } from 'actions';
import { getLocalDate } from 'utils/dates';
import {
    getCompanyDetailUrl,
    getOpportunityDetailUrl,
    getCalendarDetailUrl,
    getContactFormUrl,
    getActivityDetailUrl,
} from 'utils/getUrl';
import { ensureRoute } from 'utils/routes';
import { logEvent } from 'utils/tracking';
import { COMPANIES, OPPORTUNITIES, AGENDA, CONTACTS, ACTIVITIES } from 'constants/Entities';
import {
    ACTIVITY_TYPE_ANNOTATION,
    ACTIVITY_TYPE_CHECKIN,
    ACTIVITY_TYPE_OPPORTUNITY_CHECKIN,
    ACTIVITY_TYPE_FAST_CHECKIN,
    ACTIVITY_TYPE_VIDEO_CALL,
} from 'models/ActivityModel';
import { getBackendBoolean } from 'utils/fm';

const mapDispatchToProps = (dispatch) => {
    return {
        checkEntityExist: bindActionCreators(EntityActions, dispatch).checkEntityExist,
    };
};

const mapStateToProps = (state) => {
    return {
        entityActive: state.entityList.active,
    };
};

const BellNotificationsRow = memo(
    ({ entityActive, data, checkEntityExist, onSelectNotification }) => {
        const isComment = data.subType !== '-1' && !!data.IDMessage;
        const tab = isComment ? 'timeline' : null;
        const className = useMemo(() => {
            let classes = ['fm-bell-notifications__popover__row'];
            if (getBackendBoolean(data.Actionable))
                classes.push('fm-bell-notifications__popover__row-clickable');
            return classes.join(' ');
        }, [data]);

        const theme = useTheme();

        const onClick = useCallback(() => {
            if (!getBackendBoolean(data.Actionable)) return;
            setTracking();

            if (data.IDEmpresa && data.IDEmpresa !== '-1') {
                checkEntityExist(COMPANIES, data.IDEmpresa)
                    .then(() => {
                        window.location = getCompanyDetailUrl(data.IDEmpresa);
                        onSelectNotification && onSelectNotification();
                    })
                    .catch((err) => {
                        console.error('entity does not exist:', err);
                    });
            } else if (data.IDExpediente && data.IDExpediente !== '-1') {
                checkEntityExist(OPPORTUNITIES, data.IDExpediente)
                    .then(() => {
                        window.location = getOpportunityDetailUrl(data.IDExpediente);
                        onSelectNotification && onSelectNotification();
                    })
                    .catch((err) => {
                        console.error('entity does not exist:', err);
                    });
            } else if (data.IDDocumento && data.IDDocumento !== '-1') {
                ensureRoute('/documents');
            } else if (data.IDAgenda && data.IDAgenda !== '-1') {
                checkEntityExist(AGENDA, data.IDAgenda)
                    .then(() => {
                        window.location = getCalendarDetailUrl(data.IDAgenda);
                        onSelectNotification && onSelectNotification();
                    })
                    .catch((err) => {
                        console.error('entity does not exist:', err);
                    });
            } else if (data.IDContacto && data.IDContacto !== '-1') {
                checkEntityExist(CONTACTS, data.IDContacto)
                    .then(() => {
                        window.location = getContactFormUrl(data.IDContacto);
                        onSelectNotification && onSelectNotification();
                    })
                    .catch((err) => {
                        console.error('entity does not exist:', err);
                    });
            } else if (data.IDGestion && data.IDGestion !== '-1') {
                checkEntityExist(ACTIVITIES, data.IDGestion)
                    .then(() => {
                        window.location = getActivityDetailUrl(data.IDGestion, data.subType, tab);
                        onSelectNotification && onSelectNotification();
                    })
                    .catch((err) => {
                        console.error('entity does not exist:', err);
                    });
            } else if (data.idCustomActivity && data.idCustomActivity !== '-1') {
                checkEntityExist(ACTIVITIES, data.idCustomActivity)
                    .then(() => {
                        window.location = getActivityDetailUrl(
                            data.idCustomActivity,
                            data.subType,
                            tab,
                        );
                        onSelectNotification && onSelectNotification();
                    })
                    .catch((err) => {
                        console.error('entity does not exist:', err);
                    });
            } else if (data.idInternetEmail && data.idInternetEmail !== '-1') {
                checkEntityExist(ACTIVITIES, data.idInternetEmail)
                    .then(() => {
                        window.location = getActivityDetailUrl(
                            data.idInternetEmail,
                            data.subType,
                            tab,
                        );
                        onSelectNotification && onSelectNotification();
                    })
                    .catch((err) => {
                        console.error('entity does not exist:', err);
                    });
            } else if (data.idPhoneCallLog && data.idPhoneCallLog !== '-1') {
                checkEntityExist(ACTIVITIES, data.idPhoneCallLog)
                    .then(() => {
                        window.location = getActivityDetailUrl(
                            data.idPhoneCallLog,
                            data.subType,
                            tab,
                        );
                        onSelectNotification && onSelectNotification();
                    })
                    .catch((err) => {
                        console.error('entity does not exist:', err);
                    });
            }
        }, [data, checkEntityExist, onSelectNotification, setTracking, tab]);

        const setTracking = useCallback(() => {
            if (data.idInternetEmail && data.idInternetEmail !== '-1' && !tab) {
                // Email opened notification
                logEvent({ event: 'notifications', submodule: 'emails' });
            } else if (data.TimeLineUnreadMessage != null) {
                // Timeline conversations notification
                logEvent({
                    event: 'notifications',
                    submodule: 'timelineConversations',
                    functionality: 'bellLink',
                });
            } else {
                // Other notifications
                let eventName = '';
                switch (entityActive) {
                    case 'companies':
                        eventName = 'accounts';
                        break;
                    case 'tasks':
                        eventName = 'calendar';
                        break;
                    case 'salesOrders':
                        eventName = 'salesorders';
                        break;
                    case 'billboard':
                        eventName = 'newsmanager';
                        break;
                    default:
                        eventName = entityActive;
                }

                logEvent({
                    event: eventName,
                    functionality: 'bellNotification',
                });
            }
        }, [data.TimeLineUnreadMessage, data.idInternetEmail, entityActive, tab]);

        const getIcon = useMemo(() => {
            if (data.IDEmpresa !== '') return 'accounts';
            else if (data.IDExpediente !== '') return 'opportunities';
            else if (data.IDAgenda !== '') return 'calendar';
            else if (data.IDGestion !== '') {
                switch (parseInt(data.subType)) {
                    case -1:
                        return 'activities';
                    case ACTIVITY_TYPE_ANNOTATION:
                        return 'activities';
                    case ACTIVITY_TYPE_CHECKIN:
                        return 'accountCheckin';
                    case ACTIVITY_TYPE_VIDEO_CALL:
                        return 'videoCheckin';
                    case ACTIVITY_TYPE_FAST_CHECKIN:
                        return 'fastCheckin';
                    case ACTIVITY_TYPE_OPPORTUNITY_CHECKIN:
                        return 'opportunityCheckin';
                    default:
                        return 'activities';
                }
            } else if (data.idCustomActivity !== '') return 'activities';
            else if (data.idInternetEmail !== '') return 'email';
            else if (data.idPhoneCallLog !== '') return 'call';
            else return '';
        }, [data]);

        const dateText = useMemo(() => {
            if (!data.ActionDate) return '';
            const date = getLocalDate(data.ActionDate, 'DD/MM/YYYY HH:mm:ss', 'ddd L LT');
            return date[0].toUpperCase() + date.slice(1);
        }, [data.ActionDate]);

        return (
            <div className={className} onClick={onClick}>
                <div className="fm-bell-notifications__popover__row">
                    <div className="fm-bell-notifications__popover__row__left">
                        <div className="fm-bell-notifications__popover__row__left-icon">
                            <Icon name={getIcon} color={theme.colors.grey[500]} />
                        </div>
                    </div>

                    <div className="fm-bell-notifications__popover__row__content">
                        <div className="fm-bell-notifications__popover__row__content-header">
                            <Text
                                className="fm-bell-notifications__popover__row__content-header__name-text"
                                type="subtitle"
                                color={theme.colors.utility.textPrimary}
                            >
                                {data.Subject}
                            </Text>
                            <Text
                                className="fm-bell-notifications__popover__row__content-header__time"
                                color={theme.colors.utility.textSecondary}
                                type="captionMedium"
                            >
                                {dateText}
                            </Text>
                        </div>
                        <div className="fm-bell-notifications__popover__row__content-body">
                            <Text
                                className="fm-bell-notifications__popover__row__content-body__text"
                                color={theme.colors.utility.textPrimary}
                                type="caption"
                            >
                                {data.Body}
                            </Text>
                            {isComment && (
                                <Icon
                                    className="fm-bell-notifications__popover__row__content-body__comments"
                                    name="chat"
                                    color={
                                        !getBackendBoolean(data.Read)
                                            ? theme.colors.semantic.notification600
                                            : null
                                    }
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(BellNotificationsRow);
