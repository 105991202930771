import React, { memo } from 'react';
import { getLiteral } from 'utils/getLiteral';

import DashboardEmptyViewBox from './DashboardEmptyViewBox';

import './styles.scss';

const propTypes = {};

const DashboardEmptyView = ({ emptyViewProps, headerHeight }) => {
    const { others } = emptyViewProps();
    const style = {
        height: `calc(100% - ${headerHeight}px)`,
        marginTop: `${headerHeight}px`,
    };

    const folderBox = (
        <DashboardEmptyViewBox
            title={getLiteral('label_analytics_pro_empty_page_create_folder')}
            subtitle={getLiteral('label_analytics_pro_empty_page_desc_folder')}
            icon={
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 12H14V10H16V12H18V14H16V16H14V14H12V12M22 8V18C22 19.11 21.11 20 20 20H4C2.89 20 2 19.11 2 18V6C2 4.89 2.89 4 4 4H10L12 6H20C21.11 6 22 6.89 22 8M20 8H4V18H20V8Z" />
                </svg>
            }
            onClick={others.onCreateFolder}
            isDisabled={!others.analyticsProCreateFolder}
        />
    );
    return (
        <div className="fm-grid-dashboard__empty-view" style={style}>
            <div className="fm-grid-dashboard__empty-view__title fm-b-9">
                {getLiteral('label_analytics_pro_title_empty_page')}
            </div>

            <div className="fm-grid-dashboard__empty-view__boxes">{folderBox}</div>
        </div>
    );
};

DashboardEmptyView.propTypes = propTypes;

export default memo(DashboardEmptyView);
