import React, { memo } from 'react';
import { Icon, useTheme } from 'hoi-poi-ui';

import TextWithLinks from 'components/TextWithLinks';

const RowDetailWidgetContent = memo(({ icon, iconName, value }) => {
    const theme = useTheme();
    const renderTextValue = (value) => {
        if (!value) return '-';
        else if (typeof value === 'string') return <TextWithLinks text={value} />;
        else return value;
    };

    return (
        <div className="widget-detail-row__value__with-icon">
            {icon && value && <div className="widget-detail-row__value__icon">{icon}</div>}
            {iconName && value && (
                <div className="widget-detail-row__value__icon">
                    <Icon name={iconName} color={theme.colors.grey[500]} />
                </div>
            )}

            <div className="widget-detail-row__value__content">{renderTextValue(value)}</div>
        </div>
    );
});

export default RowDetailWidgetContent;
