import React, { memo, useRef, useCallback, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Text, useTheme } from 'hoi-poi-ui';
import { ProductsActions } from 'actions';

import { PRODUCTS } from 'constants/Entities';
import { PAGINATION_GRID_PRODUCTS } from 'constants/Environment';
import { subscribe } from 'lib/EventBuser';
import { REFRESH_TABLE } from 'lib/events';

import { getLiteral } from 'utils/getLiteral';

import { EntityListActions } from 'actions';

import VirtualizedGrid from 'components/VirtualizedGrid';
import ProductCard from './components/ProductCard';
import ProductGridEmptyView from './components/ProductGridEmptyView';

const ProductsGrid = ({ onChangeFamily }) => {
    const dispatch = useDispatch();
    const { products, loading, offset, count } = useSelector((state) => {
        const entityList = state?.entityList;
        const productList = entityList[PRODUCTS.trueName] || null;
        return {
            products: productList?.data,
            offset: productList?.offset || 0,
            loading: productList?.loading || state.products?.isLoadingCount || false,
            count: state.products?.count,
        };
    });

    const entityFilters = useSelector((state) => state.entityFilters[PRODUCTS.entity]);
    const entityFiltersRef = useRef();
    const domContent = useRef(null);
    const isFirstRender = useRef(true);
    const theme = useTheme();

    useEffect(() => {
        if (entityFilters !== entityFiltersRef.current || isFirstRender.current) {
            dispatch(ProductsActions.getCount());
            entityFiltersRef.current = entityFilters;
        }
    }, [dispatch, entityFilters]);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
        }
        return () => {
            domContent.current = null;
        };
    }, [dispatch]);

    useEffect(() => {
        return subscribe(`${REFRESH_TABLE}_${PRODUCTS.entity}`, () => {
            getProducts();
        });
    }, [getProducts]);

    const getProducts = useCallback(
        (props) => {
            let newOffset = offset;
            if (props?.resetOffset) newOffset = 0;

            return Promise.all([
                dispatch(
                    EntityListActions.init(
                        PRODUCTS,
                        true,
                        PAGINATION_GRID_PRODUCTS,
                        newOffset,
                        null,
                        null,
                        null,
                        null,
                        true,
                    ),
                ),
            ])
                .then(([{ entities }]) => {
                    if (entities.length) {
                        dispatch(
                            EntityListActions.setOffset(
                                PRODUCTS,
                                newOffset + PAGINATION_GRID_PRODUCTS,
                            ),
                        );
                    }
                    return entities;
                })
                .catch((e) => {
                    console.error('error: ', e);
                });
        },
        [dispatch, offset],
    );

    const renderRow = useCallback(
        ({ index, data }) => {
            if (!data?.product) return null;
            return <ProductCard key={index} data={data?.product} onChangeFamily={onChangeFamily} />;
        },
        [onChangeFamily],
    );

    const loaderBox = useMemo(() => {
        return (
            <div className="fm-grid-products__loader-box">
                <Text
                    className="fm-grid-products__loader-box-text"
                    type="subtitle1"
                    color={theme.colors.primary.white}
                >
                    {getLiteral('wait_loading')}
                </Text>
            </div>
        );
    }, [theme]);

    return (
        <div className="fm-grid-products" ref={domContent}>
            {loading && loaderBox}
            <ProductGridEmptyView
                loading={loading}
                count={products?.length}
                filters={entityFilters?.filters}
            />
            <VirtualizedGrid
                entity={PRODUCTS}
                containerKey="fm-grid-products"
                renderRow={renderRow}
                total={count}
                onLoad={getProducts}
                data={products}
                hasNextPage={products?.length < count}
            />
        </div>
    );
};

export default memo(ProductsGrid);
