import React, { memo, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link, Text, useTheme } from 'hoi-poi-ui';
import { CONTACTS, COMPANIES } from 'constants/Entities';
import { getLiteral } from 'utils/getLiteral';
import { getLocalDate } from 'utils/dates';
import { getSrcContactCircleAvatar } from 'utils/getSrcAvatar';
import { getCompanyDetailUrl } from 'utils/getUrl';
import WidgetDetailHeader from 'containers/components/widgets/components/WidgetDetailHeader';
import useEntityDetail from 'containers/components/EntityDetail/hooks/useEntityDetail';
import CompanyChip from 'containers/components/chips/CompanyChip';
import useCrudModal from 'containers/components/EntityCrud/hooks/useCrudModal';

const propTypes = {
    contact: PropTypes.object,
};

const ContactDetailWidgetHeader = memo(({ contact }) => {
    const theme = useTheme();
    const { openTab, hasTabsEnabled } = useEntityDetail();
    const { getCreateActions } = useCrudModal();

    const onClickCompany = useCallback(
        (e) => {
            if (!hasTabsEnabled || e.ctrlKey) return true;

            e.stopPropagation();
            e.preventDefault();

            openTab({
                entity: COMPANIES,
                id: contact.idCompany,
                hasCrudInDetail: false,
                toTab: true,
            });
        },
        [contact, hasTabsEnabled, openTab],
    );
    const renderSubtitle = useMemo(() => {
        if (contact.contactType && contact.idCompany && contact.company) {
            return (
                <div className="fm-widget-detail__header__subtitle__content">
                    <Text type="subtitle1" color={theme.colors.utility.textSecondary}>
                        {contact.contactType}
                    </Text>
                    <Text
                        type="subtitle1"
                        color={theme.colors.utility.textSecondary}
                    >{` ${getLiteral('action_at')} `}</Text>
                    <CompanyChip
                        idCompany={contact.idCompany}
                        remotePopover={true}
                        onClickPopover={onClickCompany}
                    >
                        <Link
                            type="subtitle1"
                            href={getCompanyDetailUrl(contact.idCompany)}
                            target="blank"
                            onClick={onClickCompany}
                        >
                            {contact.company}
                        </Link>
                    </CompanyChip>
                </div>
            );
        } else if (contact.contacType && !(contact.idCompany && contact.company)) {
            return contact.contactType;
        } else if (!contact.contacType && contact.idCompany && contact.company) {
            return (
                <CompanyChip
                    idCompany={contact.idCompany}
                    remotePopover={true}
                    onClickPopover={onClickCompany}
                >
                    <Link
                        type="subtitle1"
                        href={getCompanyDetailUrl(contact.idCompany)}
                        target="blank"
                        onClick={onClickCompany}
                    >
                        {contact.company}
                    </Link>
                </CompanyChip>
            );
        } else {
            return null;
        }
    }, [contact, onClickCompany, theme]);

    const urlLogo = useMemo(() => {
        return getSrcContactCircleAvatar(contact.id);
    }, [contact]);

    const modifiedDate = useMemo(() => {
        if (!contact.modified || contact.created === contact.modified) return null;
        return getLocalDate(contact.modified, 'DD/MM/YYYY HH:mm:ss', 'DD/MM/YYYY HH:mm') || '-';
    }, [contact]);

    const fullName = useMemo(() => {
        let name = contact.name;
        if (contact.surnames) name = `${name} ${contact.surnames}`;
        return name;
    }, [contact]);

    const crudActions = useMemo(() => {
        let actionsOrder = [
            {
                name: 'createEmail',
                iconName: 'email',
                label: getLiteral('placeholder_send_email'),
            },
            {
                name: 'createActivity',
                iconName: 'activities',
                label: getLiteral('label_activity'),
                crudProps: {
                    data: {
                        idContacto: { label: fullName, value: contact.id },
                        idEmpresa: { label: contact.company, value: contact.idCompany },
                    },
                    isDetailCreateGlobal: true,
                },
            },
            {
                name: 'createActivityWhatsApp',
                iconName: 'whatsapp',
                label: getLiteral('title_whatsapp_activity'),
                crudProps: {
                    data: {
                        idContacto: { label: fullName, value: contact.id },
                        idEmpresa: { label: contact.company, value: contact.idCompany },
                    },
                    isDetailCreateGlobal: true,
                },
            },
            {
                name: 'createEvent',
                iconName: 'calendar',
                label: getLiteral('label_event'),
                crudProps: {
                    data: {
                        contact: [{ label: fullName, value: contact.id }],
                        account: { label: contact.company, value: contact.idCompany },
                    },
                    isDetailCreateGlobal: true,
                },
            },
            {
                name: 'createTask',
                iconName: 'checkCircle',
                label: getLiteral('label_task'),
                crudProps: {
                    data: {
                        contact: [{ label: fullName, value: contact.id }],
                        account: { label: contact.company, value: contact.idCompany },
                    },
                    isDetailCreateGlobal: true,
                },
            },
        ];

        let finalActions = getCreateActions(actionsOrder);

        return finalActions;
    }, [getCreateActions, contact, fullName]);

    return (
        <WidgetDetailHeader
            entity={CONTACTS}
            leftItem={
                <div className="contact-info-widget-header__image">
                    <img
                        src={urlLogo.src || urlLogo.fallbackSrc}
                        onError={(e) => {
                            e.target.src = urlLogo.fallbackSrc;
                        }}
                    />
                </div>
            }
            title={fullName}
            subtitle={renderSubtitle}
            metadata={{
                id: contact.id,
                fCreado: getLocalDate(contact.created, 'DD/MM/YYYY HH:mm:ss', 'DD/MM/YYYY HH:mm'),
                fModificado: modifiedDate,
                userModificado: modifiedDate && contact.modifiedUser ? contact.modifiedUser : null,
            }}
            crudActions={crudActions}
        />
    );
});

ContactDetailWidgetHeader.propTypes = propTypes;

export default ContactDetailWidgetHeader;
