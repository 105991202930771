import React, { memo, useMemo, useState, useRef, useCallback, Fragment, useEffect } from 'react';
import { Text, useTheme } from 'hoi-poi-ui';
import classnames from 'classnames';

import TextWithLinks from 'components/TextWithLinks';
import AnimatedHeight from 'components/AnimatedHeight';
import { getLiteral } from 'utils/getLiteral';

const MIN_HEIGHT = 80;

const CommentRow = memo(({ text, noTabs = false, getIsCommentCollapsed }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const timeoutRef = useRef(null);
    const hasTextSelectionRef = useRef(false);
    const [commentHeight, setCommentHeight] = useState(0);
    const commentContainerRef = useRef(null);
    const theme = useTheme();

    useEffect(() => {
        if (!commentContainerRef.current) return;
        const observer = new ResizeObserver((entries) => {
            if (entries[0].target.offsetHeight) {
                setCommentHeight(entries[0].target.offsetHeight);
            }
        });

        observer.observe(commentContainerRef.current);
        return () => observer.disconnect();
    }, []);

    const commentClassNames = useMemo(
        () => classnames('comment-row', { 'no-tabs': noTabs }),
        [noTabs],
    );

    const getClosest = useCallback((element, selector) => {
        // It's 2 because in this case from the lowest children to the targeted parent there are no more than 2 steps;
        for (let i = 0; i <= 2; i++) {
            if (element.matches(selector)) return element;
            else element = element.parentNode;
        }
    }, []);

    const onToggleComment = useCallback(
        (e) => {
            let comment = text;

            if (comment?.length < 100) return null;
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
                timeoutRef.current = null;
            }

            const arrowWrapperElement = getClosest(e.target, '.comments-arrow-size');

            if (arrowWrapperElement) {
                hasTextSelectionRef.current = false;
                clearTimeout(timeoutRef.current);
                timeoutRef.current = null;
                setIsCollapsed(!isCollapsed);
                getIsCommentCollapsed(!isCollapsed);
                return;
            }

            const newTimeout = setTimeout(() => {
                if (window.getSelection().toString().length) {
                    hasTextSelectionRef.current = true;
                    return;
                } else if (
                    window.getSelection().toString().length === 0 &&
                    hasTextSelectionRef.current
                ) {
                    hasTextSelectionRef.current = false;
                    return;
                }
                setIsCollapsed(!isCollapsed);
                getIsCommentCollapsed(!isCollapsed);
            }, 150);

            timeoutRef.current = newTimeout;
        },
        [isCollapsed, text, getClosest, getIsCommentCollapsed],
    );
    const onCommentMouseDown = useCallback((e) => {
        if (window.getSelection().toString().length) {
            hasTextSelectionRef.current = true;
        } else {
            hasTextSelectionRef.current = false;
        }
    }, []);

    const renderCommentField = useMemo(() => {
        let comment = text;
        if (!comment) return null;

        let extraClass = '';
        if (commentHeight > MIN_HEIGHT) {
            extraClass = ['extra-large-comment'];
            if (isCollapsed) {
                extraClass.push('extra-large-comment__expanded');
            } else {
                extraClass.push('extra-large-comment__collapsed');
            }
            extraClass = extraClass.join(' ');
        }

        const showMoreOrLessLiteral = isCollapsed
            ? getLiteral('action_see_less')
            : getLiteral('action_dots_see_more');

        return (
            <div
                className={`comment-row__text comments-field ${extraClass}`}
                onClick={onToggleComment}
                onMouseDown={onCommentMouseDown}
            >
                <AnimatedHeight
                    open={isCollapsed}
                    minHeight={
                        commentHeight > 0 && commentHeight < MIN_HEIGHT ? commentHeight : MIN_HEIGHT
                    }
                >
                    <TextWithLinks ref={commentContainerRef} text={comment} />
                    {commentHeight > MIN_HEIGHT && (
                        <Fragment>
                            {isCollapsed && <div className="comments-field-toggle__extra-height" />}
                            <Text
                                className="comments-field-toggle__text"
                                color={theme.colors.actionMajor[500]}
                            >
                                {showMoreOrLessLiteral}
                            </Text>
                        </Fragment>
                    )}
                </AnimatedHeight>
            </div>
        );
    }, [text, onToggleComment, onCommentMouseDown, isCollapsed, commentHeight, theme]);

    return <div className={commentClassNames}>{renderCommentField}</div>;
});

export default CommentRow;
