import { axios } from './axios';

let INNER_VALUE_CACHE = {};

export function getEntityValue(entityName, ids) {
    if (!entityName || !ids) return Promise.reject('Entity and id is needed');
    INNER_VALUE_CACHE[entityName] = INNER_VALUE_CACHE[entityName] || {};
    const uncachedIds = ids.filter((id) => !INNER_VALUE_CACHE[entityName][id]);

    if (uncachedIds.length === 0) {
        const cachedResults = Object.fromEntries(
            ids.map((id) => [id, INNER_VALUE_CACHE[entityName][id]]),
        );
        return Promise.resolve({ data: cachedResults });
    }

    return axios
        .get(`/search/v1/fuzzySearch/${entityName}/values?ids[]=${uncachedIds.join(',')}`)
        .then((result) => {
            Object.entries(result.data).forEach(([id, item]) => {
                INNER_VALUE_CACHE[entityName][id] = item;
            });

            return {
                data: Object.fromEntries(ids.map((id) => [id, INNER_VALUE_CACHE[entityName][id]])),
            };
        });
}
