import React, { Fragment, memo, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Tabs } from 'hoi-poi-ui';
import { InfoAlert } from 'components/alerts';
import { getLiteral } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';
import { ProviderActive, ProviderDetail, NavigationTree } from '..';
import BarChartFM from '../Charts/BarChartFM';
import Context from 'managers/Context';

import '../style.scss';
import './styles.scss';

const mapStateToProps = (state) => {
    return {
        implementation: state.config.userData.idImplementacion,
        email: state.config.userData.email,
    };
};

const TABS = {
    info: '1',
    extraFields: '2',
};

const Docusign = memo(({ provider, onResetClick, email, implementation }) => {
    const [tabSelected, setTabSelected] = useState(TABS.info);
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [isSendingEmail, setIsSendingEmail] = useState(false);

    const sendMailSimple = useCallback(() => {
        const data = {
            subject: `${provider.name} - ${email} - ${implementation}`,
            email: getLiteral('label_contact_email_integrations'),
            body: `${getLiteral('placeholder_contact_us')}`,
        };

        setIsSendingEmail(true);

        Context.domainManager.sendMailSimple(
            data,
            () => {
                successToast({
                    title: getLiteral('label_integraciones_contact_us_title'),
                    text: getLiteral('label_integraciones_contact_us_body'),
                });
                setIsEmailSent(true);
                setIsSendingEmail(false);
            },
            () => {
                setIsSendingEmail(false);
                errorToast({ text: getLiteral('error_loading') });
            },
        );
    }, [provider, email, implementation]);

    const sendMailSimple2 = useCallback(
        (plan, context) => {
            const planName = getLiteral(plan);
            const data = {
                subject: `${provider.name} - ${planName} - ${email} - ${implementation}`,
                email: getLiteral('label_contact_email_integrations'),
                body: `${getLiteral('placeholder_contact_us')} ${planName}`,
            };
            Context.domainManager.sendMailSimple(
                data,
                () => {
                    successToast({
                        title: getLiteral('label_integraciones_contact_us_title'),
                        text: getLiteral('label_integraciones_contact_us_body'),
                    });
                },
                () => {
                    errorToast({ text: getLiteral('error_loading') });
                },
            );
            context.setState({ clicked: true });
        },
        [provider, email, implementation],
    );

    const parseHistory = useCallback((provider, literal) => {
        let data = provider.history.map((el) => {
            let obj = { name: el.MonthStartDate };
            if (literal) {
                obj[literal] = el.ConsumedCredit;
            }
            return obj;
        });
        return data;
    }, []);

    const onChangeTab = useCallback(() => {
        if (tabSelected === TABS.info) {
            setTabSelected(TABS.extraFields);
            return;
        }
        setTabSelected(TABS.info);
    }, [tabSelected]);

    const renderContent = useCallback(
        (provider, providerContent, history) => {
            let warning = provider.warning && (
                <div className="warning">
                    <InfoAlert iconDisplay={false} text={getLiteral(provider.warning)} />
                </div>
            );

            const tabs = [
                { key: TABS.info, title: getLiteral('label_info') },
                { key: TABS.extraFields, title: getLiteral('action_more_details') },
            ];
            return provider.active && provider.history ? (
                <div className="wrapper-warning">
                    {warning}
                    <Tabs
                        className="integrations-tabs"
                        onChange={onChangeTab}
                        activeKey={tabSelected}
                        tabs={tabs}
                    />
                    {tabSelected === TABS.info && providerContent}
                    {tabSelected === TABS.extraFields && (
                        <BarChartFM
                            data={history}
                            literal={getLiteral('label_credits_used_month')}
                        />
                    )}
                </div>
            ) : (
                <div>{providerContent}</div>
            );
        },
        [onChangeTab, tabSelected],
    );

    const renderDetailProvider = useMemo(() => {
        // eslint-disable-next-line no-undef
        const history = provider.history ? parseHistory(provider, literal3) : null;
        const providerContent =
            provider.active && provider.creditConfig ? (
                <Fragment>
                    <div className="docusign-contact-button__active">
                        <Button
                            isDisabled={isEmailSent}
                            isLoading={isSendingEmail}
                            onClick={sendMailSimple}
                        >
                            {getLiteral('label_contact')}
                        </Button>
                    </div>
                    <ProviderActive provider={provider} sendMailSimple={sendMailSimple2} />
                </Fragment>
            ) : (
                <div className="docusign-contact-button">
                    <Button
                        isDisabled={isEmailSent}
                        isLoading={isSendingEmail}
                        onClick={sendMailSimple}
                    >
                        {getLiteral('label_contact')}
                    </Button>
                </div>
            );

        return (
            <div className="detail-provider">
                <div className="top-panel">
                    <NavigationTree provider={provider} onReset={onResetClick} />
                    <ProviderDetail provider={provider} />
                    {renderContent(provider, providerContent, history)}
                </div>
            </div>
        );
    }, [
        provider,
        onResetClick,
        isEmailSent,
        isSendingEmail,
        parseHistory,
        sendMailSimple,
        sendMailSimple2,
        renderContent,
    ]);

    return <div>{renderDetailProvider}</div>;
});

export default connect(mapStateToProps)(Docusign);
